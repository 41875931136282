import { Component, OnInit, Input } from "@angular/core";
import { WelcomeBlock } from "acc-models";
import { DataService, SessionService } from 'acc-services';
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { LockFeaturesComponent } from "../../lock-features/lock-features.component";

@Component({
	selector: "acc-main-data-block",
	templateUrl: "./data-block.component.html",
	styleUrls: ["./data-block.component.scss"],
})
export class DataBlockComponent implements OnInit {
	@Input() block: WelcomeBlock;
	
	public recentActivityName = "Recent Activity";

	public apiCalls: Map<string, Array<any>> = new Map();
	constructor(private dataService: DataService, private sessionService: SessionService, private router: Router, private dialog: MatDialog) {}

	ngOnInit() {
		this.loadData();
	}

	loadData() {
		const userId = this.sessionService.getUser().userID;
		this.block.data.forEach(e => {
			this.apiCalls.set(e.api, null);
			this.dataService[e.api](userId, e.limit).subscribe(res => this.apiCalls.set(e.api, res))
		})
	}

	get isFetching(): boolean {
		return Array.from(this.apiCalls.values()).some(v => v == null);
	}

	navigateTo(url: string, e: any) {
		if(e.target.tagName === "DIV") {
			this.router.navigate([url]);
		}
	}

	showLockFeatureDialog() {
		this.dialog.open(LockFeaturesComponent, {
			panelClass: "lock-features",
		});
	}
}
