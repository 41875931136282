import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {
	static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (!control.value) {
				// if control is empty return no error
				return null;
			}

			// test the value of the control against the regexp supplied
			const valid = regex.test(control.value);

			// if true, return no error (no error), else return error passed in the second parameter
			return valid ? null : error;
		};
	}

	static passwordMatchValidator(control: AbstractControl) {
		const password: string = control.get("Password").value; // get password from our password form control
		const confirmPassword: string = control.get("ConfirmPassword").value; // get password from our confirmPassword form control
		// compare is the password math
		if (password !== confirmPassword) {
			// if they don't match, set an error in our confirmPassword form control
			control.get("ConfirmPassword").setErrors({ NoPassswordMatch: true });
		} else {
			control.get("ConfirmPassword").setErrors(null);
		}
	}

	static isDateValidator(control: AbstractControl) {
		const month: number = control.get("month").value;
		const day: number = control.get("day").value;
		const year: number = control.get("year").value;
		const date = new Date(+year, +month, +day);
		// compare is the password math
		if (String(date) == "Invalid Date") {
			control.get("dob").setErrors({ invalidDate: true });
			return;
		}
		if (date.getMonth() != month || date.getFullYear() != year) {
			control.get("dob").setErrors({ invalidDate: true });
			return;
		}
		control.get("dob").setErrors(null);
	}
}
