import { DtcActions, DtcActionTypes } from "../actions/dtc.actions";
import { IDtcState, initialDtcState } from "../state/dtc.state";


export function dtcReducer(state:IDtcState = initialDtcState, action:DtcActions): IDtcState {
	switch(action.type) {
		case DtcActionTypes.SET_BROKER: 
			return { ...state, broker: action.broker};

		case DtcActionTypes.UPDATE_APPLICATION:
			return { ...state, application: { ...state.application, ...action.application } };

		case DtcActionTypes.SET_PROGRESS:
			return { ...state, progress: action.progress };
		
		case DtcActionTypes.SET_SERVICE_ENABLED:
			return { ...state, serviceEnabled: action.enabled };
		
		case DtcActionTypes.SET_RESULTS:
			return { ...state, results: action.results };
			
		default:
			return state;
		
	}
}