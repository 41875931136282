import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Application } from "acc-models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SetProgress, UpdateApplication } from "../../store/actions/dtc.actions";
import { selectApplication } from "../../store/selectors/dtc.selectors";
import { IAppState } from "../../store/state/app.state";
import { DtcRouteService } from "../dtc.route.service";

@Component({
	selector: "acc-main-health",
	templateUrl: "./health.component.html",
	styleUrls: ["./health.component.scss"],
})
export class HealthComponent implements OnInit, OnDestroy {
	public rating: "A" | "B" | "C" | "D";
	public smoker: 'yes' | 'no' | '' = '';

	public ratingField: FormControl;
	public smokerField: FormControl;
	public form: FormGroup;
	private destroy$: Subject<boolean> = new Subject();

	constructor(private router: Router, private store: Store<IAppState>, private fb: FormBuilder, private dtcRouteService: DtcRouteService) {}

	ngOnInit() {
		this.ratingField = new FormControl('', [Validators.required]);
		this.smokerField = new FormControl('', [Validators.required]);
		this.form = this.fb.group({
			rating: this.ratingField,
			smoker: this.smokerField,
		});

		this.dtcRouteService.setPage('health');

		this.store.pipe(select(selectApplication), takeUntil(this.destroy$)).subscribe(app => {
			this.form.get('rating').setValue(app.healthRating);
			this.form.get('smoker').setValue(app.smoker);
			this.rating = app.healthRating;
			this.smoker = app.smoker === true? 'yes' : app.smoker === false ? 'no' : '';
		});

		this.store.dispatch(new SetProgress(30));
		window.scrollTo(0,0);
	}

	setRating(val) {
		this.ratingField.setValue(val);
		this.rating = val;
	}
	setSmoker(val) {
		this.smokerField.setValue(val);
		this.smoker = val;
	}

	next() {
		if(this.form.valid) {
			const app = new Application({ healthRating: this.form.controls['rating'].value, smoker: this.form.controls['smoker'].value === 'yes' });
			this.store.dispatch(new UpdateApplication(app));
			this.store.dispatch(new SetProgress(60));
			this.router.navigateByUrl('/dtc/coverage');
		} else {
			Object.keys(this.form.controls).forEach(c => this.form.get(c).markAsTouched())
		}
	
	}
	back() {
		this.router.navigateByUrl('/dtc/demographics');
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}
}
