import { Component, Input, Output, OnInit, EventEmitter, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { Subject } from "rxjs";

@Component({
	selector: "app-dialog",
	templateUrl: "./dialog.component.html",
	styleUrls: ["./dialog.component.scss"],
	styles: [
		`
			p {
				padding-bottom: 20px;
			}
		`,
	],
})
export class DialogComponent implements OnInit {
	@Input() public message: string;
	@Input() public messages: Array<string>;
	@Input() public title: string;
	@Input() public buttons: Array<any>;

	@Output() closeDialog: EventEmitter<number> = new EventEmitter();

	private modalSubject: Subject<number> = new Subject<number>();

	constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit(): void {}
	public modal(): Observable<number> {
		return this.modalSubject.asObservable();
	}
	close(idx): void {
		this.closeDialog.next(idx);
		this.modalSubject.next(idx);
		this.dialogRef.close();
	}
}
