<div id="landing">
	<div class="welcome">Welcome to</div>
	<div class="logo">
	</div>
	
	<div class="subhead">
		ORG has made the move to <span class="signup-acc-bold">Accelerate</span>, by ConsumerOptix, a platform designed to help brokers sell more personally and better convert quotes to policies.
	</div>
	
	<div class="signup-button-container">
	  <button mat-raised-button class="signup-button" color="primary" (click)="login()" >Get Started</button>
	</div>

	<div class="welcome-quote">
		<div class="signup-testimony">
			"With<span class="signup-testimony-bold"> Accelerate</span>, we have broken the code on helping agents and brokers convert prospects, to quotes, to applications... to policies."
		</div>
		<div class="testimony-container">
			<div class="signup-testimony-signature">
				<div>-Tony Wilson</div>
				<div class="signup-testimony-signature-title">
				Executive Vice President<br>Principal, Variable Business
				</div>
			</div>
			<div class="org-logo">
			</div>
		</div>
	</div>
</div>
