import { IAppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { IMediaState } from '../state/media.state';

const selectMediaCategoriesState = (state:IAppState) => state.adminMedia;

export const selectMediaCategories = createSelector(
	selectMediaCategoriesState,
	(state: IMediaState) => state.MediaCategories
)
export const selectActiveCategory = createSelector(
	selectMediaCategoriesState,
	(state: IMediaState) => state.ActiveMediaCategory
)
export const selectEditingCategory = createSelector(
	selectMediaCategoriesState,
	(state: IMediaState) => state.EditingMediaCategory
)
export const selectDeletingCategory = createSelector(
	selectMediaCategoriesState,
	(state: IMediaState) => state.DeletingMediaCategory
)
