import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acc-main-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

	public message: string;
  constructor() { }

  ngOnInit() {
	  this.message = 'Please wait while your document is being prepared.'
  }

}
