
<div class="container">

	<acc-main-date-selector [onDateSelected]="dateSelected" class="date1" [defaultTimePeriod]="defaultTimePeriod" [minDate]="minDate" [maxDate]="maxDate" #dateSelector></acc-main-date-selector>

	<table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="results">
		<ng-container matColumnDef="mktProgramDesc">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="mktProgramDesc">Program</th>
			<td mat-cell *matCellDef="let item">{{item.mktProgramDesc}}</td>		
		</ng-container>
		<ng-container matColumnDef="firstName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">First Name</th>
			<td mat-cell *matCellDef="let item">{{item.firstName}}</td>		
		</ng-container>
		<ng-container matColumnDef="lastName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="lastName">Last Name</th>
			<td mat-cell *matCellDef="let item">{{item.lastName}}</td>		
		</ng-container>
		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
			<td mat-cell *matCellDef="let item">{{item.email}}</td>		
		</ng-container>
		<ng-container matColumnDef="emailDate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="emailDate">Date</th>
			<td mat-cell *matCellDef="let item">{{item.emailDate | date:'M/d/yyyy'}}</td>		
		</ng-container>
		<ng-container matColumnDef="activity">
			<th mat-header-cell *matHeaderCellDef>Activity</th>
			<td mat-cell *matCellDef="let item">{{item.activity}}</td>		
		</ng-container>
		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef>&nbsp;</th>
			<td mat-cell *matCellDef="let item"><button mat-raised-button color="primary" (click)="cancel(item.contactID, item.firstName + ' ' + item.lastName)">Cancel</button></td>		
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>

</div>