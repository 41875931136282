import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Actions } from "@ngrx/effects";
import { CommListData } from "acc-models";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { CommunicationActionTypes, GetList, GetListSuccess } from "../../../store/actions/communication.actions";
import { IAppState } from "../../../store/state/app.state";

@Component({
	selector: "acc-main-list-preview",
	templateUrl: "./list-preview.component.html",
	styleUrls: ["./list-preview.component.scss"],
})
export class ListPreviewComponent implements OnInit, OnDestroy {
	public list = new Array<CommListData>();
	public destroy$: Subject<boolean> = new Subject();
	constructor(private store: Store<IAppState>, @Inject(MAT_DIALOG_DATA) public data: any, private actions$: Actions, public dialogRef: MatDialogRef<ListPreviewComponent>,) {
		this.actions$.pipe(
			map((a) => {
				switch (a.type) {
					case CommunicationActionTypes.GET_LIST_SUCCESS:
						this.list = (a as GetListSuccess).list.CommListDatas.slice(0, 100);
				}
			}),
			takeUntil(this.destroy$)
		).subscribe();
	} 

	close(){
		this.dialogRef.close();
	  }

	ngOnInit(): void {
		this.store.dispatch(new GetList(this.data.listID));
	}
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
