<p class="reporting-container">
   <mat-card class="action-panel">
      <mat-card-content style="width:100%;">
          <mat-grid-list cols="2" rowHeight="60px" [gutterSize]="'10px'">
              <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field [ngClass]="{faded: searchType == 'group'}">
                      <mat-select [(ngModel)]="campaignID" placeholder="Campaign" (selectionChange)="setSearchType($event, 'campaign')" (change)="setActiveCampaignId(campaignID)">
						<mat-option [value]=""></mat-option>
                          <mat-option *ngFor="let c of campaigns" [value]="c.campaignID">
                              <span *ngIf="c.groupName">{{c.groupName}} - </span>{{c.campaignName}}
                          </mat-option>
                      </mat-select>
					  <mat-hint *ngIf="searchType == 'group'">Search will be performed using group, not campaign</mat-hint>
                  </mat-form-field>
              </mat-grid-tile>
			  <mat-grid-tile [colspan]="1" [rowspan]="1" *ngIf="groups">
				<mat-form-field [ngClass]="{faded: searchType == 'campaign'}">
					<mat-select [(ngModel)]="groupID" placeholder="Group" (selectionChange)="setSearchType($event, 'group');setActiveGroup($event)" (change)="setActiveGroup($event)">
						<mat-option [value]=""></mat-option>
						<mat-option *ngFor="let group of groups" [value]="group.groupID">
							{{group.groupName}}
						</mat-option>
					</mat-select>
					<mat-hint *ngIf="searchType == 'campaign'">Search will be performed using campaign, not group</mat-hint>
				</mat-form-field>
			</mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="1">
				<mat-form-field>
					<mat-select [(ngModel)]="commTypeID" placeholder="Communication Type">
						<mat-option [value]=""></mat-option>
						<mat-option *ngFor="let ct of commTypes" [value]="ct.commTypeID">
							{{ct.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</mat-grid-tile>
			<mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Last Name" [(ngModel)]="campaignDetailData.lastName">
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="1">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Phone Number" [(ngModel)]="campaignDetailData.phone">
                  </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="1">
				<mat-form-field>
				  <input matInput type="text" placeholder="Email Address" [(ngModel)]="campaignDetailData.email">
				</mat-form-field>
			</mat-grid-tile>
			<mat-grid-tile [colspan]="1" [rowspan]="1">
				<mat-form-field>
					<input matInput type="text" list="eventTypeList" placeholder="Event" [(ngModel)]="campaignDetailData.statusCode" />
					<datalist id="eventTypeList">
						<option *ngFor="let c of commEventTypes" [value]="c">
					</datalist>
			  </mat-form-field>
			</mat-grid-tile>
		  </mat-grid-list>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="length > 0">
      <mat-card-content>
        <mat-table [dataSource]="records" class="mat-elevation-z8">
			<!--
          <ng-container matColumnDef="groupName">
            <mat-header-cell *matHeaderCellDef> Group Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.sid}} </mat-cell>
          </ng-container>
		  -->
          <ng-container matColumnDef="groupName">
            <mat-header-cell *matHeaderCellDef> Group </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.groupName}} </mat-cell>
          </ng-container>
		  <ng-container matColumnDef="campaignName">
            <mat-header-cell *matHeaderCellDef> Campaign </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.campaignName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="statusDateTime">
            <mat-header-cell *matHeaderCellDef> Date/Time </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.statusDateTime | date:'yyyy-MM-dd h:mm a'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.phone | phone}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> Email Address </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="commTypeDesc">
            <mat-header-cell *matHeaderCellDef> Media Type </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.commTypeDesc}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="statusCode">
            <mat-header-cell *matHeaderCellDef> Events </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.statusCode}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="url">
            <mat-header-cell *matHeaderCellDef> Link Clicked </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.url}} </mat-cell>
          </ng-container>
        
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator 
          (page)="pageEvent = handlePaging($event)"
          [length]="length"
          [pageSize]="pageSize"
          showFirstLastButtons>
        </mat-paginator>
      </mat-card-content>
    </mat-card>
</p>