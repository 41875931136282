import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { User, CommList } from 'acc-models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SubscriptionLike } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import * as CommunicationActions from '../../store/actions/communication.actions';
import { ConfirmComponent } from 'acc-common';
import { take } from 'rxjs/operators';
import { selectLists } from '../../store/selectors/communication.selectors';

@Component({
  selector: "app-lists",
  templateUrl: "./lists.component.html",
  styleUrls: ["./lists.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ListsComponent implements OnInit {
  public user: User;
  public lists: Array<CommList>;
  public crmLoggedIn: boolean = false;
  private getListsSubscription: SubscriptionLike;
  private newListSubscription: SubscriptionLike;
  private deleteDialogRef: MatDialogRef<ConfirmComponent>;

  //dataSource: MatTableDataSource<CommList>;
  dataSource: Array<any>;
  private rawData: Array<any>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  lists$ = this.store.pipe(
	  select(selectLists)
  )
  
  displayedColumns: string[] = ['listName', 'createDateTime', 'totalRecords', 'actions'];

  private sortField: string;
  private reducedGroups: Array<any> = [];
  public filterValue: string;

  private initialLoad: boolean = true;

  constructor(
    private sessionService: SessionService,
    private dataService: DataService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<IAppState>
  ) {
    this.user = this.sessionService.getUser();
    this.lists$.subscribe((list) => {
      //this.dataSource = new MatTableDataSource(list);
      //this.dataSource.sort = this.sort;
	  this.rawData = list;
	  this.buildDataSource();
    });
  }

  ngOnInit() {
    this.store.dispatch(new CommunicationActions.GetLists(this.user.orgID));
    this.store.dispatch(new CommunicationActions.SetTitle('Lists'));
  }

  buildDataSource() {
	  if(this.initialLoad) {
		this.reducedGroups = this.rawData.map(d => { return { groupName: d.groupName || "(No Group)", isGroup: true, reduced: true, value: d.groupID } }).filter((value, index, self) => self.indexOf(value) === index);
	  }
	  this.dataSource = this.groupBy(this.rawData, this.reducedGroups, this.sortField);
  }

  groupBy(data: Array<any>, reducedGroups: Array<any>, sort: string): Array<any> {
	let collapsedGroups = reducedGroups || [];
	const reducer = (accumulator, currentValue) => {
		let currentGroup = currentValue['groupID'];
		if(!accumulator[currentGroup]) {
			accumulator[currentGroup] = [{
				groupName: currentValue['groupName'] || '(No Group)',
				value: currentValue['groupID'],
				isGroup: true,
				reduced: collapsedGroups.some(group => group.value == currentValue['groupID'])
			}];
		}
		accumulator[currentGroup].push(currentValue);
		return accumulator;
	}
	let groups = data.reduce(reducer, []);
	let groupArray = Object.keys(groups).map(key => groups[key]);
	if(this.sort && this.sort.active) {
		const sortFn = (a,b) => {
			const field = this.sort.active;
			if(this.sort.direction == 'asc') {
				if(a[field] > b[field]) return 1;
				if(a[field] < b[field]) return -1;
				return 0;
			} else {
				if(a[field] > b[field]) return -1;
				if(a[field] < b[field]) return 1;
				return 0;
			}
		}
		groupArray.forEach(g => {
			g = g.sort(sortFn)
		})
	}

	if(this.filterValue) {
		groupArray = groupArray.map(arr => {
			if(arr[0].groupName.toLowerCase().includes(this.filterValue.toLocaleLowerCase())) {
				return arr;
			}
			return [ ...arr.filter(g => g.isGroup || g.listName.toLowerCase().includes(this.filterValue.toLowerCase()))]
		})

		groupArray = groupArray.filter(a => a.length > 1);
	}

	groupArray = groupArray.sort((a,b) => {
		if(a[0].value == null && b[0].value != null) return 1;
		if(a[0].value != null && b[0].value == null) return -1;
		if(a[0].groupName > b[0].groupName) return 1;
		if(a[0].groupName < b[0].groupName) return -1;
		return 0;
	});
	
	let flatList = groupArray.reduce((a,c) => { return a.concat(c); }, []);

	return flatList.filter(rawline => {
		return rawline.isGroup || collapsedGroups.every(group => rawline['groupID'] != group.value);
	})
  }

  isGroup(index, item): boolean {
	  return item.isGroup;
  }

  reduceGroup(row) {
	  row.reduced = !row.reduced;
	  if(row.reduced) {
		  this.reducedGroups.push(row);
	  } else {
		  this.reducedGroups = this.reducedGroups.filter(g => g.value != row.value);
	  }
	  this.initialLoad = false;
	  this.buildDataSource()
  }

  applyFilter(event: Event) {
	this.buildDataSource();
  }
  resort() {
	  this.buildDataSource();
  }

  showInfo(list) {
    this.router.navigate(["/communication/lists/list-detail", list.listID]);
  }

  import(list) {
	  this.router.navigate(["/communication/lists/list-import", list.listID]);
  }
  delete(list) {
    this.store.dispatch(new CommunicationActions.DeleteList(list.listID));
    this.deleteDialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Confirm',
        message: `Are you sure you want to delete this list?`
      }
    });
    this.deleteDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
      if(confirm) {
        this.store.dispatch(new CommunicationActions.DeleteListConfirm());
      } else {
        this.store.dispatch(new CommunicationActions.DeleteListCancel());
      }
    });		

  }
}
