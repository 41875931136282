import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { LibraryItem, Folder } from 'acc-models';
import { FileSelectorComponent } from '../../../../module/file-selector/file-selector/file-selector.component';
import { DataService } from 'acc-services';
import { take } from 'rxjs/operators';


@Component({
	selector: "acc-common-personalize-dialog",
	templateUrl: "./personalize-dialog.component.html",
	styleUrls: ["./personalize-dialog.component.scss"]
})
export class PersonalizeDialogComponent implements OnInit {
	public fileAdded: Subject<LibraryItem> = new Subject();
	private fileSelectorDialogRef: MatDialogRef<FileSelectorComponent>;

	constructor(private dataService: DataService, public dialogRef: MatDialogRef<PersonalizeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {}

	ngOnInit() {}

	close(cartSessionId?: string) {
		this.dialogRef.close(cartSessionId);
	}

	showSelectFile() {
		this.fileSelectorDialogRef = this.dialog.open(FileSelectorComponent, {
			data: {
				folders: [new Folder(1, 1, 'test', [])],
				//fileTypesToShow: ['.pdf', '.png', '.jpg', '.jpeg']
			},
			height: '75vh',
			width: '60vw'
		})
		this.fileSelectorDialogRef.afterClosed().pipe(take(1)).subscribe(file => {
			if(file) {
				this.dataService.addToCart(this.data.mainCartId, file.id, file.mediaSourceID).subscribe(resp => {
					this.fileAdded.next(file);
				})
			}
	
		})
	}
}
