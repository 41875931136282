<div class="selectDates">
	<mat-form-field>
		<mat-label>Date Range</mat-label>
		<mat-select (selectionChange)="updateDateRange($event)" [(value)]="dateRangeSelection">
			<mat-option *ngFor="let period of timePeriods" [value]="period">{{period.name}}</mat-option>
		</mat-select>
	</mat-form-field>
	
	<mat-form-field>
		<mat-label>Start</mat-label>
		<input matInput [matDatepicker]="startDatepicker" [min]="minDate" [(ngModel)]="startDate" (dateChange)="chooseDate()">
		<mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
		<mat-datepicker #startDatepicker></mat-datepicker>
	</mat-form-field>

	<mat-form-field>
		<mat-label>End</mat-label>
		<input matInput [matDatepicker]="endDatepicker" [max]="maxDate" [(ngModel)]="endDate" (dateChange)="chooseDate()">
		<mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
		<mat-datepicker #endDatepicker></mat-datepicker>
	</mat-form-field>

</div>
