import { Component, OnInit } from "@angular/core";
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { selectActiveUser } from '../../store/selectors/users.selectors';
import { RootReset } from '../../store/actions/root.actions';


@Component({
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
	public activeUser$ = this.store.pipe(
		select(selectActiveUser)
	)

	constructor(private store:Store<IAppState>) {}
	
	ngOnInit(): void {
		this.store.dispatch(new RootReset());
	}
}