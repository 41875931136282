import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingComponent } from "./components/landing/landing.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { TemplateComponent } from "./components/template/template.component";
import { LogoutComponent } from "projects/acc-common/src/public_api";
import { AuthComponent } from "./components/auth/auth/auth.component";
import { ContainerComponent } from "./components/module/container/container.component";
import { TeamPictureComponent } from "./components/team-picture/team-picture.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { LibraryComponent } from "./components/library/library.component";
import { EditOutlineComponent } from "./components/module/edit-outline/edit-outline.component";
import { AuthGuard } from "acc-services";
import { SalesforceOauthComponent } from "./components/shared/salesforce-oauth/salesforce-oauth.component";
import { CategorySortComponent } from "./components/settings/category-sort/category-sort.component";
import { ProfileEditComponent } from "./components/profile/edit/edit.component";
import { VideoComponent } from "./components/video/video.component";
import { SendComponent } from "./components/module/send/send.component";
import { VerifyComponent } from "./signup/verify/verify.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { LoadingErrorComponent } from "./components/loading/loading-error/loading-error.component";
import { SsoComponent } from "./sso/sso.component";
import { QuoteVideoComponent } from "./quote-engine/quote-video/quote-video.component";
import { SignUp1Component } from "./signup/signup-first/signup-first.component";
import { SignUpComponent } from "./signup/signup.component";
import { SignUp2Component } from "./signup/signup-second/signup-second.component";
import { SignUp3Component } from "./signup/signup-third/signup-third.component";
import { SubscriptionLevelGuard } from "projects/acc-services/src/lib/guards/level.guard";
import { SubscriptionComponent } from "./signup/subscription/subscription.component";
import { SentItemsComponent } from "./components/messaging/sent-items/sent-items.component";
import { SignUpWelcomeComponent } from "./signup/signup-welcome/signup-welcome.component";
import { ActivityComponent } from "./components/messaging/activity/activity.component";
import { SignUpGuard } from "projects/acc-services/src/lib/guards/signup.guard";
import { FeaturesTableComponent } from "./shared/features-table/features-table.component";
import { tooltips } from "acc-common";
import { DtcTemplateComponent } from "./dtc/dtc-template/dtc-template.component";
import { HealthComponent } from "./dtc/health/health.component";
import { CoverageComponent } from "./dtc/coverage/coverage.component";
import { ContactInfoComponent } from "./dtc/contact-info/contact-info.component";
import { FinishComponent } from "./dtc/finish/finish.component";
import { DemographicsComponent } from "./dtc/demographics/demographics.component";
import { YourFamilyComponent } from "./components/your-family/your-family.component";
import { UnsubscribeComponent } from "./components/unsubscribe/unsubscribe.component";

const routes: Routes = [
	{ path: "", component: LandingComponent, pathMatch: "full" },
	{ path: "logout", component: LogoutComponent },
	{
		path: "",
		component: TemplateComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: "welcome", component: WelcomeComponent, data: { pageTitle: "Welcome to Accelerate" } },
			{ path: "module/send/:quoteId", component: SendComponent, data: { pageTitle: "Document Editor", level: 2, tooltip: tooltips.message_send }, canActivate: [SubscriptionLevelGuard] },
			{ path: "module/send", component: SendComponent, data: { pageTitle: "Personalize and Send", level: 2, tooltip: tooltips.message_send }, canActivate: [SubscriptionLevelGuard] },
			{ path: "module/:moduleID/:documentID/:mode", component: ContainerComponent, data: { pageTitle: "Document Editor", level: 2 }, canActivate: [SubscriptionLevelGuard] }, //TODO: Activate/Deactivate guards
			{ path: "module/:moduleID/:documentID", component: ContainerComponent, data: { pageTitle: "Document Editor", level: 2 }, canActivate: [SubscriptionLevelGuard] },
			{ path: "module/:moduleID", component: ContainerComponent, data: { pageTitle: "Document Editor", level: 2 }, canActivate: [SubscriptionLevelGuard] },
			{ path: "edit/:moduleID/:documentID", component: EditOutlineComponent, data: { pageTitle: "Document Editor", level: 2 }, canActivate: [SubscriptionLevelGuard] },
			{ path: "team-picture", component: TeamPictureComponent, data: { pageTitle: "Team Picture" } },
			{ path: "profile/edit", component: ProfileEditComponent, data: { pageTitle: "Profile" } },
			{ path: "profile", component: ProfileComponent, data: { pageTitle: "Profile" } },
			{ path: "library", component: LibraryComponent, data: { pageTitle: "Asset Library", level: 2, tooltip: tooltips.assets_library }, canActivate: [SubscriptionLevelGuard] },
			{ path: "sent-items/:deliveryId", component: SentItemsComponent, data: { pageTitle: "Sent Items", level: 2, tooltip: tooltips.message_sent_items  }, canActivate: [SubscriptionLevelGuard] },
			{ path: "sent-items", component: SentItemsComponent, data: { pageTitle: "Sent Items", level: 2, tooltip: tooltips.message_sent_items  }, canActivate: [SubscriptionLevelGuard] },
			{ path: "recent-activity", component: ActivityComponent, data: { pageTitle: "Recent Activity", level: 2, tooltip: tooltips.message_recent }, canActivate: [SubscriptionLevelGuard] },
			{ path: "salesforce-oauth", component: SalesforceOauthComponent },
			{
				path: "settings",
				children: [{ path: "categories", component: CategorySortComponent, data: { pageTitle: "Category Settings" } }],
			},
		],
	},
	{ path: "loading/error", component: LoadingErrorComponent },
	{ path: "loading", component: LoadingComponent },
	{ path: "video", component: VideoComponent },
	{
		path: "signup",
		canActivate: [SignUpGuard],
		component: SignUpComponent,
		children: [
			{ path: "", component: SignUpWelcomeComponent },
			{ path: "1", component: SignUp1Component },
			{ path: "2", component: SignUp2Component },
			{ path: "3/:plan", component: SignUp3Component },
			{ path: "subscription", component: SubscriptionComponent },
		],
	},
	{ path: "dtc", component: DtcTemplateComponent, children: 
		[
			{ path: "demographics", component: DemographicsComponent },
			{ path: "health", component: HealthComponent },
			{ path: "coverage", component: CoverageComponent },
			{ path: "contact", component: ContactInfoComponent },
			{ path: "thank-you", component: FinishComponent },
			{ path: ":userId", component: DemographicsComponent }
		]
	},
	{ path: "features", component: FeaturesTableComponent },
	{ path: "sso/:token", component: SsoComponent },
	{ path: "verify/:signUpKey", component: VerifyComponent },
	{ path: "my-quote/:userId/:videoUrl/:profileId/:quoteId", component: QuoteVideoComponent },
	{ path: "your-family/:videoId", component: YourFamilyComponent },
	{ path: "unsubscribe/:email", component: UnsubscribeComponent },
	{ path: ":resource/:action/:code", component: AuthComponent }, //reset password
	{ path: ":resource/:action", component: AuthComponent }, //recover password
	{ path: ":resource", component: AuthComponent }, //login
]; 

@NgModule({
	imports: [RouterModule.forRoot(routes, { enableTracing: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
