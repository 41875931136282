import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map,take } from 'rxjs/operators';
import { interval } from "rxjs";
import * as StatusActions from '../actions/status.actions';

@Injectable()
export class StatusEffects {

	/*
	@Effect()
	showError$ = this.actions$.pipe(
		ofType(StatusActions.StatusActionTypes.SET_ERROR_MESSAGE),
		switchMap((action:StatusActions.SetErrorMessage) => interval(5000)
			.pipe(
				take(1),
				map(() => new StatusActions.ClearErrorMessage())
			)
		)
	)
	*/

	@Effect()
	showSuccess$ = this.actions$.pipe(
		ofType(StatusActions.StatusActionTypes.SET_SUCCESS_MESSAGE),
		switchMap((action:StatusActions.SetSuccessMessage) => interval(500000)
			.pipe(
				take(1),
				map(() => new StatusActions.ClearSuccessMessage())
			)
		)
	)


	constructor(private actions$: Actions, ) {}
}