import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmComponent } from 'acc-common';
import { take, tap } from 'rxjs/operators';
import { CommCampaign } from 'acc-models';
import { IAppState } from '../../../store/state/app.state';
import { Store, select } from '@ngrx/store';
import { DeleteCampaign, DeleteCampaignConfirm, DeleteCampaignCancel } from '../../../store/actions/communication.actions';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { selectActiveCampaignId } from '../../../store/selectors/communication.selectors';
import { Location } from '@angular/common';

@Component({
  selector: 'acc-main-campaign-form-actions',
  templateUrl: './campaign-form-actions.component.html',
  styleUrls: ['../../shared/actions.scss']
})
export class CampaignFormActionsComponent implements OnInit, OnDestroy {

	public campaignId: string;
	private deleteDialogRef: MatDialogRef<ConfirmComponent>;
	private paramsSubscription: SubscriptionLike;

	activeCategoryId$ = this.store.pipe(
		select(selectActiveCampaignId),
		tap(id => {
			if(!!id) {
				this.campaignId = id;
			}
		})
	).subscribe();
	
	constructor(private dialog:MatDialog, private store: Store<IAppState>, private route: ActivatedRoute, private router: Router, private location: Location) { }

	ngOnInit() {
		this.route.firstChild.params.subscribe(
			(params: any) => {
			  if (params.hasOwnProperty('campaignID') != '') {
				 this.campaignId = params.campaignID;
			   } 
			}
		 );
	}
	
	deleteCampaign(): void {
		this.store.dispatch(new DeleteCampaign(this.campaignId));
		this.deleteDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `Are you sure you want to delete this campaign?`
			}
		});
		this.deleteDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new DeleteCampaignConfirm());
				this.router.navigate(['/communication/campaigns/']);
			} else {
				this.store.dispatch(new DeleteCampaignCancel());
			}
		});
	}
	goBack() {
		this.location.back();
	}
	ngOnDestroy(): void {
		if(this.paramsSubscription) { this.paramsSubscription.unsubscribe(); }
	}

}
