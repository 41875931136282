
export class CommCampaignDetailData {
    public sid: string;
    public recordID: number;
    public firstName: string;
    public lastName: string;
    public phone: string;
    public email: string;
    public commTypeDesc: string;
    public statusCode: string;
    public statusDateTime: Date;
	public url: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}