import { IAppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { ICategoriesState } from '../state/categories.state';

const selectCategoriesState =  (state:IAppState) => state.adminCategories;

export const selectModules = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.Modules
)
export const selectCategories = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.Categories
)
export const selectEditingCategory = createSelector(
	selectCategoriesState,
	(state:ICategoriesState) => state.EditingCategory
)
export const selectCategoryToDelete = createSelector(
	selectCategoriesState,
	(state:ICategoriesState) => state.CategoryToDelete
)
export const selectActiveCategory = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.ActiveCategory
)
export const selectSubcategories = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.Subcategories
)
export const selectEditingSubcategory = createSelector(
	selectCategoriesState,
	(state:ICategoriesState) => state.EditingSubcategory
)
export const selectSubcategoryToDelete = createSelector(
	selectCategoriesState,
	(state:ICategoriesState) => state.SubcategoryToDelete
)
export const selectActiveSubcategory = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.ActiveSubcategory
)
export const selectSubcatTexts = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.Texts
)
export const selectActiveSubcatText = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.ActiveText
)
export const selectEditingSubcatText = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.EditingText
)
export const selectSubcatTextToDelete = createSelector(
	selectCategoriesState,
	(state: ICategoriesState) => state.TextToDelete
)