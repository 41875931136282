
<div id="moduleFormShadowDom">
<header>
	<h1 *ngIf="subCategory">
		<span *ngIf="!subCategory.isCustom">{{subCategory.subCategoryDesc}}</span> 
		<div class="renameSubcat" *ngIf="subCategory.isCustom" (click)="focusSubcatName()">
			<input value="{{subCategory.subCategoryDesc}}" #newSubcatNameField  (change)="changeSubcatName()"/>
		</div>
	</h1>
	<h3 *ngIf="isTemplate">LifeQuote Template</h3>
</header>

<form #form="ngForm">
	
<ol class="fieldList listType{{docOutline?.subCatTextListTypeID}}">
	<li *ngFor="let field of moduleFields">
		<mat-card>
			<acc-common-module-field-editor [textField]="field" [documentId]="document.docID"></acc-common-module-field-editor> 
		</mat-card>
	</li>
</ol>
</form>

<p *ngIf="errorMessage" class="errorMessage" style="padding-top:10px;font-size:15px;">{{errorMessage}}</p>


<app-dialog class="small" message="Do you want to save your changes?" title="Save" [buttons]="buttons" [hidden]="!showSavePrompt" (closeDialog)="closeDialog($event)"></app-dialog>
</div>

