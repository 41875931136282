import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { SessionService, DataService } from 'acc-services';
import { Organization } from 'acc-models';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { SubscriptionLike } from 'rxjs';

@Component({
	selector: 'acc-common-recover-password-form',
	templateUrl: './recover-password-form.component.html',
	styleUrls: ['./recover-password-form.component.scss', '../login-form/login-form.component.scss']
  })
export class RecoverPasswordFormComponent implements OnInit, OnDestroy {

	public org:Organization;
	public emailField = new FormControl('', [Validators.required, Validators.email]);
	public recoverForm: FormGroup;
	public linkSent: boolean = false;
	private sendLinkSubscription: SubscriptionLike;
	public btnOptions: MatProgressButtonOptions;

	@Output() onNavigateTo: EventEmitter<string> = new EventEmitter();
	@Output() onSentLink: EventEmitter<boolean> = new EventEmitter();

	constructor(private sessionService:SessionService, private dataService: DataService, private fb: FormBuilder) { }

	ngOnInit() {
		this.org = (this.sessionService.getItem('organization') as Organization);
		this.recoverForm = this.fb.group({
			'email': this.emailField
		})
		this.btnOptions = {
			active: false,
			text: 'Send Link', 
			raised: true,
			stroked: false,
			flat:false,
			buttonColor: 'primary',
			spinnerColor: 'primary', 
			mode: 'indeterminate',	
			fullWidth: true,
			spinnerSize: 20,
			disabled: true
		}
		this.recoverForm.statusChanges.subscribe(status => this.btnOptions.disabled = (status != 'VALID') );
	}

	sendLink() {
		this.btnOptions.active = true;
		this.sendLinkSubscription = this.dataService.sendRecoverLink(this.org.orgID, this.emailField.value, `${window.location.origin}/${this.org.resource}/reset-password` ).subscribe((resp:any) => {
			//if(resp == 'success') {
				this.linkSent = true;
				this.btnOptions.active = false;
				this.onSentLink.next(true);
			//}
		})
	}
	showLoginForm():void {
		this.onNavigateTo.next('login');
	}
	ngOnDestroy():void {
		if(this.sendLinkSubscription) { this.sendLinkSubscription.unsubscribe(); }
	}
}