<h1>Get your FREE quote in minutes. Tell us a little about yourself.</h1>

<div class="form">
	<div class="dob field">
		<div class="month">
			<mat-form-field appearance="outline">
				<mat-label>Birthday Month</mat-label>
				<select matNativeControl  [formControl]="monthField">
					<option></option>
					<option *ngFor="let month of months" [value]="month.number">{{month.name}}</option>
				</select>
				<mat-error *ngIf="monthField.hasError('required')">This field is required</mat-error>
			</mat-form-field>
		</div>
		<div class="day">
			<mat-form-field appearance="outline">
				<mat-label>Day</mat-label>
				<select matNativeControl  [formControl]="dayField">
					<option></option>
					<option *ngFor="let day of days" [value]="day">{{day}}</option>
				</select>
				<mat-error *ngIf="dayField.hasError('required')">This field is required</mat-error>
			</mat-form-field>
		</div>
		<div class="year">
			<mat-form-field appearance="outline">
				<mat-label>Year</mat-label>
				<select matNativeControl  [formControl]="yearField">
					<option></option>
					<option *ngFor="let year of years" [value]="year">{{year}}</option>
				</select>
				<mat-error *ngIf="yearField.hasError('required')">This field is required</mat-error>
			</mat-form-field>
		</div>
		<div class="error">
			<mat-error *ngIf="!monthField.pristine && !dayField.pristine  && dobField.hasError('invalidDate')">The date entered is not valid</mat-error>
		</div>
	</div>
	<div class="state field">
		<mat-form-field appearance="outline">
			<mat-label>State</mat-label>
			<select matNativeControl [formControl]="stateField">
				<option></option>
				<option value="AL">Alabama</option>
				<option value="AK">Alaska</option>
				<option value="AZ">Arizona</option>
				<option value="AR">Arkansas</option>
				<option value="CA">California</option>
				<option value="CO">Colorado</option>
				<option value="CT">Connecticut</option>
				<option value="DE">Delaware</option>
				<option value="FL">Florida</option>
				<option value="GA">Georgia</option>
				<option value="HI">Hawaii</option>
				<option value="ID">Idaho</option>
				<option value="IL">Illinois</option>
				<option value="IN">Indiana</option>
				<option value="IA">Iowa</option>
				<option value="KS">Kansas</option>
				<option value="KY">Kentucky</option>
				<option value="LA">Louisiana</option>
				<option value="ME">Maine</option>
				<option value="MD">Maryland</option>
				<option value="MA">Massachusetts</option>
				<option value="MI">Michigan</option>
				<option value="MN">Minnesota</option>
				<option value="MS">Mississippi</option>
				<option value="MO">Missouri</option>
				<option value="MT">Montana</option>
				<option value="NE">Nebraska</option>
				<option value="NV">Nevada</option>
				<option value="NH">New Hampshire</option>
				<option value="NJ">New Jersey</option>
				<option value="NM">New Mexico</option>
				<option value="NY">New York</option>
				<option value="NC">North Carolina</option>
				<option value="ND">North Dakota</option>
				<option value="OH">Ohio</option>
				<option value="OK">Oklahoma</option>
				<option value="OR">Oregon</option>
				<option value="PA">Pennsylvania</option>
				<option value="RI">Rhode Island</option>
				<option value="SC">South Carolina</option>
				<option value="SD">South Dakota</option>
				<option value="TN">Tennessee</option>
				<option value="TX">Texas</option>
				<option value="UT">Utah</option>
				<option value="VT">Vermont</option>
				<option value="VA">Virginia</option>
				<option value="WA">Washington</option>
				<option value="WV">West Virginia</option>
				<option value="WI">Wisconsin</option>
				<option value="WY">Wyoming</option>
				
			</select>
			<mat-error *ngIf="stateField.hasError('required')">This field is required</mat-error>
		</mat-form-field>
	</div>
	<div class="gender field" [ngClass]="{error: genderField.hasError('required') && genderField.touched}">
		<label>Gender</label>
		<button class="male" [ngClass]="{ active: gender == 'M' }" (click)="setGender('M')">Male</button>
		<button class="female" [ngClass]="{ active: gender == 'F' }" (click)="setGender('F')">Female</button>
	</div>
</div>
<div class="actions">
	<button mat-raised-button color="primary" (click)="next()">Next</button>
</div>
