import { Component, OnInit, Input, Output } from "@angular/core";
import { Module } from "acc-models";
import { Subject } from "rxjs";

@Component({
	selector: "acc-main-module-block",
	templateUrl: "./module-block.component.html",
	styleUrls: ["./module-block.component.scss"],
})
export class ModuleBlockComponent implements OnInit {
	@Input() module: Module;
	@Output() onShowDocuments: Subject<number> = new Subject();
	constructor() {}

	ngOnInit() {}

	showDocuments(moduleId: number) {
		this.onShowDocuments.next(moduleId);
	}
}
