import { QuoteRequest } from "./quoteRequest";
import { HealthProfile } from './healthProfile';
import { HealthProfileAlt } from './healthProfileAlt';

export class Quote {
    public quoteID: string;
	public clientInformation: ClientInformation;
	public productInformation: ProductInformation;
    public underwritingInformation: UnderwritingInformation;
	public healthProfileInformation: HealthProfile;
	public healthProfileAlt: HealthProfileAlt;

    constructor(quoteID:string, clientInfo: ClientInformation, productInfo: ProductInformation, underwritingInfo: UnderwritingInformation, healthProfileInfo: HealthProfile, healthProfileAlt: HealthProfileAlt) {
        this.quoteID = quoteID;
        this.clientInformation = clientInfo;
		this.productInformation = productInfo;
        this.underwritingInformation = underwritingInfo;
		this.healthProfileInformation = healthProfileInfo;
		this.healthProfileAlt = healthProfileAlt;
	}

	public getRequest(carrierProductsFilterList: Array<any>): QuoteRequest {
		return QuoteRequest.fromQuote(this, carrierProductsFilterList);
    }

}

export interface ClientInformation {
	clientName: string;
	quoteDesc: string;
	birthDate: string;
	age?: number;
	nearestAge?: number;
	gender: string;
	state: string;
}

export interface ProductInformation {
	quoteType: string;
	terms: Array<string>;
	faceAmounts: Array<string>;
	isAccidentalDeathBenefitRider: boolean;
	isReturnOfPremiumRider: boolean;
	isWaiverOfPremiumRider: boolean;
	isChildTermRider: boolean;
	childRiderUnit: number;
}

export interface UnderwritingInformation {
	healthClasses: Array<string>;
	tableRating: number;
	flatExtraAmount: number;
	flatExtraYearEnd: number;
}
