
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ModuleField, Attachment, PlacementType, LibraryText, SettingsModule, Folder } from 'acc-models';
import { QuillEditorComponent } from 'ngx-quill';
import { DataService, SessionService } from 'acc-services';
import { SubscriptionLike } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { TextLibrarySearchComponent } from '../text-library-search/text-library-search.component';
import { FileSelectorComponent } from '../file-selector/file-selector/file-selector.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'acc-common-module-field-editor',
	templateUrl: './module-field-editor.component.html',
	styleUrls: ['./module-field-editor.component.scss']
  })

export class ModuleFieldEditorComponent implements OnInit, AfterViewInit { 
	@Input() public textField:ModuleField;
	@Input() private documentId: string;
	@Input() private usePlaceholder: boolean;
	@ViewChild('quill') quill: QuillEditorComponent;
	@ViewChild('labelField') labelField: ElementRef;
	
	private subscriptions:Array<SubscriptionLike> = new Array<SubscriptionLike>();
	public showLibrarySearch: boolean = false;
	public showFileSelector:boolean = false;
	private searchDialogRef: MatDialogRef<TextLibrarySearchComponent>;
	private fileSelectorDialogRef: MatDialogRef<FileSelectorComponent>;

	public activeField:any; //not sure what this is

	public settings:SettingsModule;
	
	constructor(private dataService:DataService, private sessionService: SessionService, private sanitizer: DomSanitizer, private dialog: MatDialog) { }
	ngOnInit() {
		
		this.settings = this.sessionService.settings.module;
		const myTextField = new ModuleField(this.textField);
		if(this.usePlaceholder && myTextField.isDefault) {
			this.textField.text = '';
			this.usePlaceholder = true;
		}
	}
		
	ngAfterViewInit():void {
		if(this.settings.htmlEditor) {
			this.quill.onEditorCreated.subscribe(q => {
				this.addPasteHandler(q);
				
			});
			
		}
	}
	insertCharacter(event) {
		if(event.target.tagName.toLowerCase() != 'li') {
			return;
		}
		if(this.settings.htmlEditor) {
			let cursorLocation = this.quill.quillEditor.selection.savedRange.index;
			this.quill.quillEditor.insertText(cursorLocation,  String.fromCharCode(event.target.getAttribute('data-value')));
			this.setModelWithContents();
		} else {
			console.warn('need to implement insert character on non-html editors')
		}
		
	}

	addPasteHandler(q) {
		this.quill.quillEditor.root.onpaste = () => {
			q.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
				let ops = []
				delta.ops.forEach(op => {
				  if (op.insert && typeof op.insert === 'string') {
					ops.push({
					  insert: op.insert
					})
				  }
				})
				delta.ops = ops;
				return delta
			})
		}

	}

	deleteAttachment(attachId, field, type) {
		if(type == 'file') {
			this.subscriptions.push(this.dataService.deleteAttachment(attachId).subscribe((resp:any) => {
				if(resp.success) {
					field.attachments = field.attachments.filter(o => o.attachmentID != attachId);
				}
			}));
		} else {
			this.subscriptions.push(this.dataService.deleteAttachmentMedia(attachId).subscribe((resp:any) => {
				if(resp.success) {
					field.attachments = field.attachments.filter(o => o.attachmentID != attachId);
				}
			}));			
		}
	}

	changeAttachmentPlacement(att:Attachment, event) {
		if(att.type == 'file') {
			this.subscriptions.push(this.dataService.updateAttachmentPlacement(att.attachmentID, event.target.value, att.imgSizeID, att.attachmentLabel).subscribe(resp => {
				att.placementTypeID = event.target.value;
			}))
	
		} else {
			this.subscriptions.push(this.dataService.updateAttachmentPlacementMedia(att.attachmentID, event.target.value, att.imgSizeID, att.attachmentLabel).subscribe(resp => {
				att.placementTypeID = event.target.value;
			}))
	
		}

	}
	changeImageSize(att:Attachment, event) {
		if(att.type == 'file') {
			this.subscriptions.push(this.dataService.updateAttachmentPlacement(att.attachmentID, att.placementTypeID, event.target.value, att.attachmentLabel).subscribe(resp => {
				att.imgSizeID = event.target.value;
			}))
	
		} else {
			this.subscriptions.push(this.dataService.updateAttachmentPlacementMedia(att.attachmentID, att.placementTypeID, event.target.value, att.attachmentLabel).subscribe(resp => {
				att.imgSizeID = event.target.value;
			}))
	
		}

	}

	saveAttachmentLabel(att:Attachment) {
		if(att.type == 'file') {
			this.subscriptions.push(this.dataService.updateAttachmentPlacement(att.attachmentID, att.placementTypeID, att.imgSizeID, att.attachmentLabel).subscribe(resp => {
				this.textField.attachments.map(a => a.attachmentID == att.attachmentID ? att : a); //update this item in the list
			}))
	
		} else {
			this.subscriptions.push(this.dataService.updateAttachmentPlacementMedia(att.attachmentID, att.placementTypeID, att.imgSizeID, att.attachmentLabel).subscribe(resp => {
				this.textField.attachments.map(a => a.attachmentID == att.attachmentID ? att : a); //update this item in the list
			}))
	
		}

	}



	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.textField.attachments, event.previousIndex, event.currentIndex);
		const newOrder = this.textField.attachments.map((c,i) => { return { ID: c.attachmentID, sortOrder:  i + 1 }; });
		this.dataService.sortAttachments(newOrder).subscribe();
	}

	showSelectFile(textField) {
		this.activeField = textField;
		this.fileSelectorDialogRef = this.dialog.open(FileSelectorComponent, {
			data: {
				folders: [new Folder(1, 1, 'test', [])],
				documentId: this.documentId,
				textField: this.textField,
				fileTypesToShow: ['.pdf', '.png', '.jpg', '.jpeg']
			},
			height: '75vh',
			width: '60vw'
		})
		this.fileSelectorDialogRef.afterClosed().pipe(take(1)).subscribe(file => {
			if(file) {
				this.dataService.attachMedia(this.documentId, this.textField.textID, file.mediaItemID, file.mediaSourceID, file.mediaFileExt == '.pdf' ? PlacementType.END_OF_DOC : PlacementType.AFTER_TEXT ).subscribe((resp:any) => {
					this.textField.attachments.push(resp);
				});	
			}
	
		})
	}
	selectFile(action: { actionName: string, payload: any }) {

	}
	onFileChange(event) {
		let fileName:string = event.target.files[0].name;
		let ext:string = fileName.toLowerCase().split('.').reverse()[0];
		if(['jpg', 'jpeg', 'png', 'pdf'].indexOf(ext) > -1) {
			this.textField.hasInvalidAttachment = false;
			this.subscriptions.push(this.dataService.uploadAttachment(event.target.files[0], this.documentId, this.textField.textID, (ext == 'pdf') ? PlacementType.END_OF_DOC : PlacementType.AFTER_TEXT).subscribe((res:any) => {
				let att = new Attachment();
				att.attachmentOrigName = res.attachmentOrigName;
				att.attachmentID = res.attachmentID
				att.attachmentFilePathFull = res.attachmentFilePathFull;
				att.type = 'file';
				att.placementTypeID = res.placementTypeID;
				att.imgSizeID = res.imgSizeID;
				att.attachmentFileExt = '.'+ext;
				this.textField.attachments.push(att);
			}))
		} else {
			this.textField.hasInvalidAttachment = true;
		}
		event.target.value = ''; //clear out field in case you want to upload the same one again
	}

	searchLibrary() {
		this.searchDialogRef = this.dialog.open(TextLibrarySearchComponent);
		this.searchDialogRef.afterClosed().pipe(take(1)).subscribe(result => {
			if(result) {
				switch (result.action) {
					case 'replace':
						this.quill.quillEditor.setText(result.libText.textLibDesc + '\n');
						this.setModelWithContents();
						break;
					case 'append':
						this.quill.quillEditor.insertText(this.quill.content.length, result.libText.textLibDesc);
						this.setModelWithContents();
						break;
					case 'close':
						break;
				}
			}
		})
	}

	closeSearch(libText:LibraryText, action: string) {
		
		switch (action) {
			case 'replace':
				this.quill.quillEditor.setText(libText.textLibDesc + '\n');
				this.setModelWithContents();
				break;
			case 'append':
				this.quill.quillEditor.insertText(this.quill.content.length, libText.textLibDesc);
				this.setModelWithContents();
				break;
			case 'close':
				break;
		}

		this.showLibrarySearch = false;
		
	}
	doCloseForm(att:Attachment) {
		this.showFileSelector = false;
		if(typeof att == 'object') {
			//could be false if you cancel out without selecting something
			this.textField.attachments.push(att);
		}
	}
	private setModelWithContents() {
		this.textField.text = this.quill.quillEditor.container.firstChild.innerHTML;
		this.quill.editorElem.classList.remove('ng-pristine');
		this.quill.editorElem.classList.add('ng-dirty');
	}

	getSymbol(symbol:number): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(`&#${String(symbol)};`);
	}

}
