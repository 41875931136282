
<div id="self-service" class="container quote-container">
<p class="instructions">The Consumer Self-Quoting Platform allows your prospects and customers to generate term life quotes 24/7 on a site branded for your brokerage.</p>

<div class="toggler" [ngClass]="{enabled: enabled}">
	<div class="toggler-label disable" (click)="disable()">Disable{{enabled ? '' : 'd'}}</div>
	<div class="toggler-label enable" (click)="enable()">Enable{{enabled ? 'd' : '&nbsp;'}}</div>
	<div class="highlight"></div>
</div>

<div class="profile-select-container">
	<mat-form-field appearance="outline" *ngIf="enabled">
		<mat-label>Profile to use on your Self-Quoting page</mat-label>
		<select matNativeControl [(ngModel)]="profileId" (change)="setProfile($event.target.value)">
			<option *ngFor="let profile of profiles" [value]="profile.profileID">{{profile.profileName}}</option>
		</select>
	</mat-form-field>
</div>


<p *ngIf="enabled">The link to your custom, direct-to-consumer Term Life Quote site is <br/>
	<a class="dtc-link" href="{{environment.base_url}}dtc/{{userId}}" target="_blank">{{environment.base_url}}dtc/{{userId}}</a> 
	<button mat-raised-button color="primary" class="copy-button" (click)="copyToClipboard()" title="Copy to Clipboard">Copy</button>&nbsp;
	<button mat-raised-button color="primary" class="email-button" (click)="email()" title="Email a Link">Email</button>
</p>

<input value="{{environment.base_url}}dtc/{{userId}}" id="copy-to-clipboard">

<share-buttons [theme]="'material-dark'"
 	[include]="['facebook','twitter','whatsapp']"
 	[show]="6"
 	[size]="-1"
	[url]="sharedURL"
	[autoSetMeta]="true"
	[title]="sharedTitle"
	[description]="sharedDescription"
	[image]="sharedImage">
</share-buttons>


</div>