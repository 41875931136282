<div mat-dialog-header>
	<h2 mat-dialog-title>Connect Accelerate to SalesForce!</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<p>Click the Connect button below to authenticate with SalesForce.com.  Once complete, you will have full access to SalesForce Accounts, Contacts and Engagement tracking.</p>	
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button color="primary" (click)="login()">Connect</button>
	<button mat-stroked-button (click)="close()">Maybe Later</button>
</mat-dialog-actions>
	
	