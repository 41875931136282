import { Component, OnInit, Input, HostListener, ElementRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

@Component({
	selector: "acc-common-file-upload",
	templateUrl: "./file-upload.component.html",
	styleUrls: ["./file-upload.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: FileUploadComponent,
			multi: true,
		},
	],
})
export class FileUploadComponent implements ControlValueAccessor {
	onChange: Function;
	public file: File | null = null;

	@HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
		const file = event && event.item(0);
		const ext = file.name.substring(file.name.lastIndexOf("."));
		if (this.accept && !this.accept.some((e) => e == ext)) {
			this.displayError = true;
			this.errorMessage = this.errorMessage ||  "File must be one of these types: " + this.accept.join(", ")
		} else {
			this.displayError = false;
			this.onChange(file);
			this.file = file;
		}
	}
	@Input() accept: Array<string>;
	@Input() errorMessage: string;
	public displayError: boolean = false;

	constructor(private host: ElementRef<HTMLInputElement>) {}
	writeValue(value: null) {
		// clear file input
		this.host.nativeElement.value = "";
		this.file = null;
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {}
}
