<div mat-dialog-header>
	<h2 mat-dialog-title>New Folder</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<mat-form-field>
			<mat-label>Folder Name</mat-label>
			<input matInput [(ngModel)]="newFolderName" (keyup.enter)="newFolder()" />
		</mat-form-field>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="newFolder()">Create</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>