import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { User, CommCampaign, CommCampaignDetailData, CommGroup } from 'acc-models';
import { SubscriptionLike, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import * as CommunicationActions from '../../store/actions/communication.actions';
import { Actions } from '@ngrx/effects';
import { map, takeUntil, tap } from 'rxjs/operators';
import { selectReportResults } from '../../store/selectors/communication.selectors';
import * as CommunicationSelectors from "../../store/selectors/communication.selectors";

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit, OnDestroy {

  campaignID: string;
  public groupID: number;
  public user: User;
  public campaigns: Array<CommCampaign>;
  private getCampaignsSubscription: SubscriptionLike;

  public campaignDetail: Array<any> = [];
  public campaignDetailData = new CommCampaignDetailData();
  private getCampaignDetailSubscription: SubscriptionLike;
  private getCampaignDetailDataSubscription: SubscriptionLike;
  public records =  new MatTableDataSource<CommCampaignDetailData>();

  public searchType: 'campaign' | 'group' | 'neither' = 'neither';

  displayedColumns: string[] = ['groupName', 'campaignName', 'firstName', 'lastName', 'statusDateTime', 'phone', 'email', 'commTypeDesc', 'statusCode', 'url'];

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public groups: Array<CommGroup>;
  public groups$ = this.store.pipe(
	  select(CommunicationSelectors.selectGroups),
	  tap((g) => (this.groups = g)),
	  takeUntil(this.destroy$)
  ).subscribe();
  length = 0;
  pageSize = 50;

  	results$ = this.store.pipe(
		select(selectReportResults),
		takeUntil(this.destroy$),
		tap(r => {
			if(r === null) {
				this.records = null;
				return;
			}
			if(r) {
				this.length = r.totalRecords;
				this.records = new MatTableDataSource(r.CommCampaignDetailData);
				this.records.paginator = this.paginator;
				if(this.length == 0) {
					this.snackBar.open('No records were returned', 'OK', { duration: 3000 });
				}
			}
  		})
  	).subscribe();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  commTypeID: string = '';
  commTypes: Array<any> = [
    {
      commTypeID: '1',
      name: 'Phone'
    },
    {
      commTypeID: '2',
      name: 'SMS'
    },
    {
      commTypeID: '3',
      name: 'Email'
    }
  ];
  commEventTypes: Array<string> = [
	  'busy',
	'click',
	'completed',
	'deferred',
	'delivered',
	'error',
	'open',
	'processed',
	'sent',
	'undelivered'
]

  constructor(private dataService: DataService, private dialog: MatDialog, private actions$: Actions,
    private sessionService: SessionService, private snackBar: MatSnackBar, private store:Store<IAppState>) {
	  this.user = this.sessionService.getUser();
	  
	  actions$.pipe(
		map(a => {
			switch(a.type) {
				case CommunicationActions.CommunicationActionTypes.RUN_REPORT:
					this.store.dispatch(new CommunicationActions.RunReportConfirm({
						lastName: this.campaignDetailData.lastName, 
						phoneNumber: this.campaignDetailData.phone,
						email: this.campaignDetailData.email,
						communicationType: this.commTypeID,
						statusCode: this.campaignDetailData.statusCode,
						campaignID: this.searchType == 'campaign' ? this.campaignID : null,
						groupID: this.searchType == 'group' ? this.groupID : null
					}));
					break;
			}
		}),
		takeUntil(this.destroy$)
	).subscribe();
  }

	ngOnInit() {
		this.getCampaigns();
		this.store.dispatch(new CommunicationActions.SetTitle('Reporting'));
		this.store.dispatch(new CommunicationActions.GetGroups(this.sessionService.getUser().orgID));
		this.store.dispatch(new CommunicationActions.ClearReport());

		this.campaignDetailData.lastName = '';
		this.campaignDetailData.phone = '';
		this.campaignDetailData.email = '';
		this.campaignDetailData.statusCode = '';
  	}

  getCampaigns() {
    this.getCampaignsSubscription = this.dataService.getCampaigns(this.user.orgID).subscribe((lists:any) => {
      this.campaigns = lists.sort((a,b) => {
		if(a.groupName == null && b.groupName != null) return 1;
		if(a.groupName != null && b.groupName == null) return -1;
		if(a.groupName > b.groupName) return 1;
		if(a.groupName < b.groupName) return -1;
		if(a.campaignName > b.campaignName) return 1;
		if(a.campaignName < b.campaignName) return -1;
		return 0;
	});
	});
  }


  setSearchType(e, type) {
	  //if you've selected a value, then this is the search type
	  if(e.value) {
		  this.searchType = type;
		  //and clear out the other one
		  if(type == 'group') {
			  this.campaignID = null;
			  this.store.dispatch(new CommunicationActions.SetActiveCampaign(null));
		  } else {
			  this.groupID = null;
			  this.store.dispatch(new CommunicationActions.SetActiveGroup(null));
		  }
	  } else {
		  //but if you cleared it out, then this is no longer the search type
		  if(this.searchType == type) {
			  this.searchType = 'neither';
		  }
	  }
  }
  /*
  executeReport() {
    this.getCampaignsDetail();
  }
  */

  goToExportCSV() {
	  //not sure that this code is ever called - be sure to make changes in /reporting/reporting-actions/reporting-actions.component.ts
    this.dataService.getCampaignDetailCsv(this.campaignID, this.groupID).subscribe((csvUrl:any) => {
      let snackBarRef = this.snackBar.open(`Report created`, 'Close', {
        duration: 3000
      });

	  let link=document.createElement('a');
	  link.href = csvUrl;
	  link.download = csvUrl.substr(csvUrl.lastIndexOf('/') + 1);
	  link.click();


	})
  }

  setActiveCampaignId(campaignId) {
	  this.store.dispatch(new CommunicationActions.SetActiveCampaign(campaignId));
	  this.store.dispatch(new CommunicationActions.SetActiveGroup(null));
  }

  setActiveGroup(e) {
	  this.store.dispatch(new CommunicationActions.SetActiveGroup(e.value));
	  this.store.dispatch(new CommunicationActions.SetActiveCampaign(null));
  }
  ngOnDestroy() {
	  this.destroy$.next(true);
	  this.destroy$.unsubscribe();
  }

  public handlePaging(e: any) {
    this.getCampaignDetailDataSubscription = this.dataService.getCampaignDetailByPage(this.campaignID, this.groupID, this.campaignDetailData.lastName, this.campaignDetailData.phone, this.campaignDetailData.email, this.commTypeID, this.campaignDetailData.statusCode, e.pageIndex).subscribe((campaignDetail:any) => {
      this.campaignDetail = campaignDetail;
      this.records = new MatTableDataSource(this.campaignDetail[1]);
 	});
  }

}