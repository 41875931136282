import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CheckoutService, SessionService, DataService } from "acc-services";
import { SetDaysLeft, SetErrorMessage } from "../../store/actions/status.actions";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { IAppState } from "../../store/state/app.state";
import { Store } from "@ngrx/store";
import * as moment from "moment";
import { ConfirmComponent, DialogComponent } from "acc-common";
import { take } from "rxjs/operators";
import { loadStripe } from "@stripe/stripe-js";
import { Organization, User, Settings } from "acc-models";
import { environment } from "../../../../../acc-main/src/environments/environment";
import { Router } from "@angular/router";
import { SubscriptionLike } from "rxjs";
import { LockFeaturesComponent } from "../../components/lock-features/lock-features.component";
import { CurrencyPipe, DatePipe } from "@angular/common";

@Component({
	selector: "acc-main-subscription",
	templateUrl: "./subscription.component.html",
	styleUrls: ["./subscription.component.scss"],
})
export class SubscriptionComponent implements OnInit {
	subscription;
	startDate;
	currentPeriodStart;
	currentPeriodEnd;
	nextPeriodStart;
	nextPeriodEnd;
	nextBillingDate;
	canceledAt;
	upcomingInvoice;

	STRIPE_KEY = environment.stripe_key;
	@ViewChild("subscriptionCardElement") cardElement: ElementRef;
	stripe;
	card;
	cardErrors;
	loading = false;
	confirmation;

	selectedPlan = null;
	plans;
	org;
	user;
	subscriptionLoading = false;
	subscriptionAmount = 0;
	selectedSeats = 0;
	usersMinValue = 1;
	validateCard = false;
	subscriptionChanged = false;
	private deleteDialogRef: MatDialogRef<ConfirmComponent>;
	private getOrgSubscription: SubscriptionLike;
	public lockedFeaturesDialogRef: MatDialogRef<LockFeaturesComponent>;

	public trialEnds = null;
	public hasCCInformation = false;
	public subscriptionStatus;
	public isTrial = false;

	private successMessageText = "Your NEW subscription to Accelerate PRO is now active.";

	constructor(private snackBar: MatSnackBar, private checkoutService: CheckoutService, private store: Store<IAppState>, private dialog: MatDialog, private sessionService: SessionService, private router: Router, private dataService: DataService) {}

	ngOnInit() {
		this.getSubscriptionInformation();
	}

	getSubscriptionInformation() {
		this.subscriptionLoading = true;
		this.user = this.sessionService.getUser();
		this.org = this.sessionService.getItem<Organization>("organization");
		this.checkoutService.getSubscriptions(this.org.orgID).subscribe(
			(resp: any) => {
				this.subscriptionLoading = false;
				if (resp.subscription != null && resp.subscription.id != null && this.org.subscriptionLevelID != 1) {
					this.subscription = resp.subscription;
					this.upcomingInvoice = resp.upcomingInvoice;

					if(this.upcomingInvoice != null){
						this.nextBillingDate = moment.unix(this.upcomingInvoice.period_end);
						this.nextPeriodStart = moment.unix(this.upcomingInvoice.lines.data[0].period.start);
						this.nextPeriodEnd = moment.unix(this.upcomingInvoice.lines.data[0].period.end);
					}

					this.hasCCInformation = this.subscription.customer.default_source != null;
					this.startDate = moment.unix(this.subscription.start_date);
					this.currentPeriodStart = moment.unix(this.subscription.current_period_start);
					this.currentPeriodEnd = moment.unix(this.subscription.current_period_end);
					this.subscriptionStatus = (this.subscription.status == 'trialing')?'TRIAL': (this.subscription.status[0].toUpperCase() + this.subscription.status.substr(1).toLowerCase());

					if(this.subscription.status == 'trialing'){
						if(!!this.hasCCInformation){
							this.subscriptionStatus = "Active";
						}
						this.isTrial = true;
						var today = moment().startOf('day');
						var m = moment.unix(this.subscription.current_period_end).startOf('day');
						this.trialEnds = Math.round(moment.duration(m.diff(today)).asDays());
						this.store.dispatch(new SetDaysLeft(this.trialEnds));
					}
					
					if (!!this.subscription.canceled_at) {
						this.canceledAt = moment.unix(this.subscription.canceled_at);
					}
					this.selectedPlan = this.subscription.plan.id;
					this.subscriptionAmount = this.subscription.plan.tiers != null ? this.subscription.plan.tiers[0].unit_amount_decimal : this.subscription.plan.amount_decimal; //this.subscription.latest_invoice.amount_paid
					this.selectedSeats = this.subscription.quantity;
				} else {
					//Get the plans when no subscription is created.
					this.getPlans();
				}

				//Get Minimun users min value
				this.getMinimunOrgCount();

				setTimeout(() => {
					this.initStripe();
				}, 1000);
			},
			(err: any) => {
				this.subscriptionLoading = false;
				if (err.error) {
					this.snackBar.open(err.error.Message, "Close", { duration: 5000 });
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access to checkout service"));
				}
			}
		);
	}

	getMinimunOrgCount() {
		this.dataService.getOrgUsersCount(this.org.orgID).subscribe(
			(count: any) => {
				this.usersMinValue = count;
				if (this.selectedSeats == 0) {
					this.selectedSeats = this.usersMinValue;
				}
			},
			(err: any) => {
				if (err.error) {
					this.snackBar.open(err.error.Message, "Close", { duration: 5000 });
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access to data service"));
				}
			}
		);
	}

	async initStripe() {
		//this.stripe = Stripe(this.STRIPE_KEY);
		this.stripe = await loadStripe(this.STRIPE_KEY);
		const elements = this.stripe.elements();
		this.card = elements.create("card");
		this.card.mount(this.cardElement.nativeElement);

		this.card.addEventListener("change", ({ error }) => {
			this.cardErrors = error && error.message;
			this.validateCard = true;
			this.subscriptionChanged = true;
		});
	}

	getPlans() {
		this.checkoutService.getPlans().subscribe(
			(resp: any) => {
				this.plans = resp.data;
				this.selectedPlan = this.plans[0].id;
				this.subscriptionAmount = this.plans[0].tiers[0].unit_amount_decimal;
			},
			(err: any) => {
				if (err.error) {
					this.snackBar.open(err.error.Message, "Close", { duration: 5000 });
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access to SignUp service"));
				}
			}
		);
	}

	cancelSubscription(): void {
		this.deleteDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `Are you sure you want to cancel this subscription?`,
			},
		});
		this.deleteDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((confirm) => {
				if (confirm) {
					let subscription = {
						subscriptionId: this.subscription.id,
						orgId: this.org.orgID,
					};

					this.checkoutService.cancelSubscription(subscription).subscribe(
						(resp: any) => {
							this.subscription = resp;
							if (!!this.subscription.canceled_at) {
								this.canceledAt = moment.unix(this.subscription.canceled_at);
							}
							var datePipe = new DatePipe("en-US");
    						var endDate = datePipe.transform(this.currentPeriodEnd, 'MM/dd/yyyy');
							var dialog = this.showInformationDialog("Sorry to see you go!", "You will continue to enjoy all the premium features of Accelerate until " + endDate + ". After " + endDate + ", you will still have access to the basic features so you can continue to send Life Quotes. And of course, you can always renew your subscription at a later date.");

							dialog.afterClosed().subscribe(() => {
								this.performLogin();
							});
						},
						(err: any) => {
							this.store.dispatch(new SetErrorMessage("Could not access subscription api"));
						}
					);
				}
			});
	}

	async submitForm() {
		if (!!this.validateCard) {
			const { source, error } = await this.stripe.createSource(this.card);
			if (error) {
				this.cardErrors = error.message;
			}
			if (!this.cardErrors) {
				this.loading = true;

				//Create Token and Update
				this.stripe.createToken(this.card).then((cardToken) => {
					var t = {
						token: cardToken.token.id,
						orgId: this.org.orgID,
						subscriptionId: this.subscription.id,
						quantity: this.selectedSeats,
					};

					this.checkoutService.updateSubscription(t).subscribe(
						(resp:any) => {
							this.loading = false;

							this.subscription = resp.subscription;
							this.upcomingInvoice = resp.upcomingInvoice;
	
							if(this.upcomingInvoice != null){
								this.nextBillingDate = moment.unix(this.upcomingInvoice.period_end);
							}
							var datePipe = new DatePipe("en-US");
							var renewDate = datePipe.transform(this.nextBillingDate, 'MM/dd/yyyy');

							var successMessageTitile = "Thank you!";
							var successMessage = this.successMessageText;
							if(!!this.hasCCInformation){
								successMessage = "When your subscription renews on " + renewDate +", your new card will be charged.";
							}
							var dialog = this.showInformationDialog(successMessageTitile, successMessage );
							dialog.afterClosed().subscribe(() => {
								this.performLogin();
							});
							this.sessionService.setItem('featureShown', "true"); //just in case they haven't already seen this
						},
						(err) => {
							this.loading = false;
							this.store.dispatch(new SetErrorMessage("Your card could not be processed.  Please make sure you entered the number correctly."));
						}
					);
				});
			}
		} else {
			this.loading = true;
			var subscription = {
				token: null,
				orgID: this.org.orgID,
				subscriptionId: this.subscription.id,
				quantity: this.selectedSeats,
			};

			this.checkoutService.updateSubscription(subscription).subscribe(
				(resp:any) => {
					this.loading = false;

					this.subscription = resp.subscription;
					this.upcomingInvoice = resp.upcomingInvoice;

					if(this.upcomingInvoice != null){
						this.nextBillingDate = moment.unix(this.upcomingInvoice.period_end);
					}
					var datePipe = new DatePipe("en-US");
					var renewDate = datePipe.transform(this.nextBillingDate, 'MM/dd/yyyy');

					var currencyPipe = new CurrencyPipe("en-US");
					var charge = ((this.selectedSeats * this.subscriptionAmount)/100);
					var newCharge = currencyPipe.transform(charge);

					this.showInformationDialog("Thank you!", "When your subscription renews on " + renewDate + ", the new charge will be "+ newCharge +".");
				},
				(err) => {
					this.loading = false;
					this.store.dispatch(new SetErrorMessage("The subscription could not be updated, please contact the administrator."));
				}
			);
		}
	}

	async renewSubscription() {
		const { source, error } = await this.stripe.createSource(this.card);
		if (error) {
			this.cardErrors = error.message;
		}
		if (!this.cardErrors) {
			this.loading = true;

			//Create Token and Update
			this.stripe.createToken(this.card).then((cardToken) => {
				var subscriptionRenew = {
					token: cardToken.token.id,
					subscriptionId: this.subscription.id,
					plan: this.selectedPlan,
					orgId: this.org.orgID,
					quantity: this.selectedSeats,
				};

				this.checkoutService.renewSubscription(subscriptionRenew).subscribe(
					(resp:any) => {
						
						this.subscription = resp.subscription;
						this.upcomingInvoice = resp.upcomingInvoice;

						this.loading = false;

						if(this.upcomingInvoice != null){
							this.nextBillingDate = moment.unix(this.upcomingInvoice.period_end);
						}
						var datePipe = new DatePipe("en-US");
						var renewDate = datePipe.transform(this.nextBillingDate, 'MM/dd/yyyy');

						var dialog = this.showInformationDialog("Thank you!", "When your subscription renews on " + renewDate + ", your new card will be charged.");
						dialog.afterClosed().subscribe(() => {
							this.performLogin();
						});
					},
					(err) => {
						this.loading = false;
						this.store.dispatch(new SetErrorMessage("Your card could not be processed.  Please make sure you entered the number correctly."));
					}
				);
			});
		}
	}

	async purchaseSubscription() {
		const { source, error } = await this.stripe.createSource(this.card);
		if (error) {
			this.cardErrors = error.message;
		}
		if (!this.cardErrors) {
			this.loading = true;

			//Create Token and Update
			const result = await this.stripe
				.createPaymentMethod({
					type: "card",
					card: this.card,
					billing_details: {
						email: this.user.email,
					},
				})
				.then(async (result) => {
					if (result.error) {
						this.cardErrors = result.error;
						this.store.dispatch(new SetErrorMessage("Your card could not be processed.  Please make sure you entered the number correctly."));
					} else {
						// Otherwise send paymentMethod.id to your server
						let subscription = {
							orgId: this.org.orgID,
							plan: this.selectedPlan,
							email: this.user.email,
							name: this.user.firstName + " " + this.user.lastName,
							company: this.org.orgDesc,
							paymentMethod: result.paymentMethod.id,
							quantity: this.selectedSeats,
						};
						await this.checkoutService.createSubscription(subscription).subscribe(
							(resp: any) => {
								this.loading = false;

								var subscriptionEnd = moment.unix(resp.current_period_end);
								var datePipe = new DatePipe("en-US");
								var renewDate = datePipe.transform(subscriptionEnd, 'MM/dd/yyyy');

								var successMessageTitile = "Thank you!";
								var successMessage = this.successMessageText;
								if(!!this.hasCCInformation){
									successMessage = "When your subscription renews on "+ renewDate +", your new card will be charged.";
								}
								var dialog = this.showInformationDialog(successMessageTitile, successMessage );

								dialog.afterClosed().subscribe(() => {
									this.performLogin();
								});
							},
							(err: any) => {
								this.loading = false;
								this.store.dispatch(new SetErrorMessage("Your card could not be processed.  Please make sure you entered the number correctly."));
							}
						);
					}
				});
		}
	}

	performLogin() {
		var u = new User(this.user);
		this.sessionService.setUser(u);
		this.sessionService.authToken = u.token;

		this.getOrgSubscription = this.dataService.GetOrgFromID(u.orgID).subscribe((org) => {
			this.sessionService.removeItem("organization");
			if (org.orgID) {
				this.sessionService.setItem("organization", org);
			}

			//get settings
			this.dataService.getSettings(u.orgID).subscribe(
				(resp) => {
					this.sessionService.settings = new Settings(resp);

					this.router.navigate(["/welcome"]);
				},
				(error) => {
					//if a problem, set defaults
					this.sessionService.settings = new Settings();

					this.router.navigate(["/welcome"]);
				}
			);
		});
	}

	validateMinSeats(event) {
		this.subscriptionChanged = true;
		var value = Number.parseInt(event.target.value);
		if (value < this.usersMinValue) {
			this.selectedSeats = this.usersMinValue;
		}
	}

	showInformationDialog(title, subtitle) {
		var dialogContent = [subtitle];

		var dialog = this.dialog.open(DialogComponent, {
			data: {
				messages: dialogContent.filter((m) => !!m).map((m) => `<pre>${m}</pre>`),
				title: title,
			},
			panelClass: "subscription-admin",
		});

		return dialog;
	}

	showFeaturesDialog() {
		/*var dialogContent = ["- Send term quotes and proposals to clients.", "- Client/recipient engagement insight.", "- Send personalized and branded videos.", "- Access to rich, insurance-based media.", "- Click <a class='link' href='/comparison' >here</a> to compare Accelerate Pro & Basics."];

		this.dialog.open(DialogComponent, {
			data: {
				messages: dialogContent.filter((m) => !!m).map((m) => `<pre>${m}</pre>`),
				title: "Why ORG Brokers go Pro",
			},
		});*/

		this.lockedFeaturesDialogRef = this.dialog.open(LockFeaturesComponent, {
			panelClass: "lock-features",
		});
	}

}
