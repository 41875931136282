import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Subject, Observable } from 'rxjs';
import { SessionService } from './session.service';
import { CrmAccount } from 'acc-models';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CrmService {
	private accountSearches: Array<PriorSearches> = new Array<PriorSearches>();

	constructor(private dataService:DataService, private sessionService: SessionService) {}

	public searchContacts(term:string): Observable<Array<CrmAccount>> {
		term = term.toLowerCase();
		const prior = this.accountSearches.filter(s => term.indexOf(s.key) > -1);
		if(prior.length == 0) {
			//nope, haven't done this search before
			return this.getAccountList(term);
		} else {
			//find best match - ie, longest search term 
			const bestMatch = prior.sort((a,b) => {
				if(a.key.length > b.key.length) return -1;
				if(a.key.length < b.key.length) return 1;
				return 0;
			})[0];
			const newResults = bestMatch.results.filter(r => r.Name.toLowerCase().indexOf(term) > -1);
			if(bestMatch.key != term) {
				//only add to searches if it's not there already
				this.accountSearches.push(new PriorSearches(term, newResults));
			}
			return of<Array<CrmAccount>>(newResults);
		}
	}

	private getAccountList(term):Observable<Array<CrmAccount>> {
		let user = this.sessionService.getUser();
		if(!user.integrateCRM || user.skipCRM) { return of([]); }
		let subject = new Subject<Array<CrmAccount>>();
		let getAccountsSubscription = this.dataService.getAccounts(user.userID, term).subscribe((res:any) => {
			if(res.responseTooLarge) {
				//too many options - let them filter more
				subject.next(new Array<CrmAccount>());
			} else {
				this.accountSearches.push(new PriorSearches(term, res.account.slice()));
				subject.next(res.account);
			}
		}, (err:HttpErrorResponse) => {
			if(err.status == 401) {
				//need to log in to sales force
				this.sessionService.salesforceLogin().subscribe(result => {
					if(result == 'login') {
						this.getAccountList(term);
					}
				})
			}
		});
		return subject;

	}
}

class PriorSearches {
	key:string;
	results: Array<CrmAccount>

	constructor(key:string, results:Array<CrmAccount>) {
		Object.assign(this, {key, results});
	}
}