export class FamilyHistory {
  public isSelected: boolean;
  public father: FatherProfile = new FatherProfile();
  public mother: MotherProfile = new MotherProfile();
  public sister: SisterProfile = new SisterProfile();
  public brother: BrotherProfile = new BrotherProfile();

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class FatherProfile {
  public fatherHistory: boolean | null = null;
  public conditions: Array<string>;
  public deathConditions: Array<string>;
  public fatherCancer: boolean | null = null;
  public fatherCancerDiagnosedAge: number;
  public fatherCancerDeath: boolean | null = null;
  public fatherCancerDeathAge: number;
  public fatherCancerProstateCancer: boolean | null = null;
  public fatherCancerTesticularCancer: boolean | null = null;
  public fatherCancerColonCancer: boolean | null = null;
  public fatherCancerMelanomaCancer: boolean | null = null;
  public fatherCancerPancreaticCancer: boolean | null = null;
  public fatherCancerStomachCancer: boolean | null = null;
  public fatherCancerOtherCancer: boolean | null = null;
  public fatherCardiovascular: boolean | null = null;
  public fatherCardiovascularDiagnosedAge: number;
  public fatherCardiovascularDeath: boolean | null = null;
  public fatherCardiovascularDeathAge: number;
  public fatherDiabetes: boolean | null = null;
  public fatherDiabetesDiagnosedAge: number;
  public fatherDiabetesDeath: boolean | null = null;
  public fatherDiabetesDeathAge: number;
  public fatherStroke: boolean | null = null;
  public fatherStrokeDiagnosedAge: number;
  public fatherStrokeDeath: boolean | null = null;
  public fatherStrokeDeathAge: number;
  public fatherDie: boolean | null = null;
  public deathAge: number;

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class MotherProfile {
  public motherHistory: boolean | null = null;
  public conditions: Array<string>;
  public deathConditions: Array<string>;
  public motherCancer: boolean | null = null;
  public motherCancerDiagnosedAge: number;
  public motherCancerDeath: boolean | null = null;
  public motherCancerDeathAge: number;
  public motherCancerBreastCancer: boolean | null = null;
  public motherCancerCervicalCancer: boolean | null = null;
  public motherCancerEndometrialCancer: boolean | null = null;
  public motherCancerFallopianTubeCancer: boolean | null = null;
  public motherCancerOvarianTubeCancer: boolean | null = null;
  public motherCancerVaginalCancer: boolean | null = null;
  public motherCancerVulvarCancer: boolean | null = null;
  public motherCancerColonCancer: boolean | null = null;
  public motherCancerMelanomaCancer: boolean | null = null;
  public motherCancerPancreaticCancer: boolean | null = null;
  public motherCancerStomachCancer: boolean | null = null;
  public motherCancerOtherCancer: boolean | null = null;
  public motherCardiovascular: boolean | null = null;
  public motherCardiovascularDiagnosedAge: number;
  public motherCardiovascularDeath: boolean | null = null;
  public motherCardiovascularDeathAge: number;
  public motherDiabetes: boolean | null = null;
  public motherDiabetesDiagnosedAge: number;
  public motherDiabetesDeath: boolean | null = null;
  public motherDiabetesDeathAge: number;
  public motherStroke: boolean | null = null;
  public motherStrokeDiagnosedAge: number;
  public motherStrokeDeath: boolean | null = null;
  public motherStrokeDeathAge: number;
  public motherDie: boolean | null = null;
  public deathAge: number;

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class SisterProfile {
  public sisterHistory: boolean | null = null;
  public conditions: Array<string>;
  public deathConditions: Array<string>;
  public sisterCancer: boolean | null = null;
  public sisterCancerDiagnosedAge: number;
  public sisterCancerDeath: boolean | null = null;
  public sisterCancerDeathAge: number;
  public sisterCancerBreastCancer: boolean | null = null;
  public sisterCancerCervicalCancer: boolean | null = null;
  public sisterCancerEndometrialCancer: boolean | null = null;
  public sisterCancerFallopianTubeCancer: boolean | null = null;
  public sisterCancerOvarianTubeCancer: boolean | null = null;
  public sisterCancerVaginalCancer: boolean | null = null;
  public sisterCancerVulvarCancer: boolean | null = null;
  public sisterCancerColonCancer: boolean | null = null;
  public sisterCancerMelanomaCancer: boolean | null = null;
  public sisterCancerPancreaticCancer: boolean | null = null;
  public sisterCancerStomachCancer: boolean | null = null;
  public sisterCancerOtherCancer: boolean | null = null;
  public sisterCardiovascular: boolean | null = null;
  public sisterCardiovascularDiagnosedAge: number;
  public sisterCardiovascularDeath: boolean | null = null;
  public sisterCardiovascularDeathAge: number;
  public sisterDiabetes: boolean | null = null;
  public sisterDiabetesDiagnosedAge: number;
  public sisterDiabetesDeath: boolean | null = null;
  public sisterDiabetesDeathAge: number;
  public sisterStroke: boolean | null = null;
  public sisterStrokeDiagnosedAge: number;
  public sisterStrokeDeath: boolean | null = null;
  public sisterStrokeDeathAge: number;
  public sisterDie: boolean | null = null;
  public deathAge: number;

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class BrotherProfile {
  public brotherHistory: boolean | null = null;
  public conditions: Array<string>;
  public deathConditions: Array<string>;
  public brotherCancer: boolean | null = null;
  public brotherCancerDiagnosedAge: number;
  public brotherCancerDeath: boolean | null = null;
  public brotherCancerDeathAge: number;
  public brotherCancerProstateCancer: boolean | null = null;
  public brotherCancerTesticularCancer: boolean | null = null;
  public brotherCancerColonCancer: boolean | null = null;
  public brotherCancerMelanomaCancer: boolean | null = null;
  public brotherCancerPancreaticCancer: boolean | null = null;
  public brotherCancerStomachCancer: boolean | null = null;
  public brotherCancerOtherCancer: boolean | null = null;
  public brotherCardiovascular: boolean | null = null;
  public brotherCardiovascularDiagnosedAge: number;
  public brotherCardiovascularDeath: boolean | null = null;
  public brotherCardiovascularDeathAge: number;
  public brotherDiabetes: boolean | null = null;
  public brotherDiabetesDiagnosedAge: number;
  public brotherDiabetesDeath: boolean | null = null;
  public brotherDiabetesDeathAge: number;
  public brotherStroke: boolean | null = null;
  public brotherStrokeDiagnosedAge: number;
  public brotherStrokeDeath: boolean | null = null;
  public brotherStrokeDeathAge: number;
  public brotherDie: boolean | null = null;
  public deathAge: number;

  constructor(o?: any) {
    Object.assign(this, o);
	}
}
