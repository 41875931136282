import { CustVideoRequestParm } from './custVideoRequestParm';

export class CustVideoRequest {
	public emailTo : string;
    public emailSubject: string;
    public emailMessage: string;
    public CustVideoParameters: Array<CustVideoRequestParm>;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}
