import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import { GetSignUpData } from "../../store/actions/signup.actions";
import { MixpanelService } from "acc-services";

@Component({
	selector: "acc-main-signup-first",
	templateUrl: "./signup-first.component.html",
	styleUrls: ["./signup-first.component.scss", "../signup.component.scss"],
})
export class SignUp1Component implements OnInit {
	@ViewChild("videoPlayer", { static: true }) videoPlayer;
	//public activeVideoUrl: string = "/assets/videos/accelerate.mp4";
	public activeVideoUrl: string = "/assets/videos/acceleratemarketing.mp4";
	
	constructor(private router: Router, private store: Store<IAppState>, private mixpanelService: MixpanelService) {}

	ngOnInit() {
		this.store.dispatch(new GetSignUpData());
		window.scrollTo(0, 0);

		this.mixpanelService.trackSignup();
	}

	next() {
		this.router.navigateByUrl("/signup/3/0");
	}

	reloadVideo() {
		this.videoPlayer.nativeElement.load();
	}
}
