import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DataService } from 'acc-services';
import { IAppState } from '../state/app.state';
import * as CategoriesActions from '../actions/categories.actions';
import * as StatusActions from '../actions/status.actions';
import { catchError, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { SubCategory } from 'acc-models';

@Injectable()
export class CategoriesEffects {

	@Effect() 
	getModules$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.GET_MODULES),
		switchMap((action:CategoriesActions.GetModules) => this.dataService.getModules(action.orgId)
			.pipe(
				map((resp:any) => {
					return new CategoriesActions.GetModulesSuccess(resp)
				}),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	getCategories$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.GET_CATEGORIES),
		switchMap((action:CategoriesActions.GetCategories) => this.dataService.getCategories(action.moduleId)
			.pipe(
				map((resp: any) => new CategoriesActions.GetCategoriesSuccess(resp)),
				catchError(() => EMPTY)
			)
		)
	)
	@Effect()
	editCategory$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.EDIT_CATEGORY_SAVE),
		switchMap((action:CategoriesActions.EditCategorySave) => this.dataService.updateCategoryAdmin(action.category)
			.pipe(
				map((resp:any) => new CategoriesActions.EditCategorySuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	deleteCategory$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.DELETE_CATEGORY_CONFIRM),
		withLatestFrom(this.store$.select('adminCategories', 'CategoryToDelete')),
		switchMap(([action, category]) => {
			return this.dataService.deleteCategory(category.categoryID)
				.pipe(
					map((resp:any) => new CategoriesActions.DeleteCategorySuccess()),
					catchError(((resp:any) => {
						return of(new StatusActions.SetErrorMessage(resp.error.Message))
					}))
				)
			}
		)
	)
	@Effect()
	reorderCategories$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.SAVE_CATEGORIES),
		switchMap((action:CategoriesActions.SaveCategories) => this.dataService.updateCategories(action.categories)
			.pipe(
				map((resp:any) => new CategoriesActions.SaveCategoriesSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	addCategory$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.ADD_CATEGORY),
		switchMap((action:CategoriesActions.AddCategory) => this.dataService.insertCategory(action.moduleId, action.category)
			.pipe(
				map((resp: any) => new CategoriesActions.AddCategorySuccess( { ...resp, SubCategories: new Array<SubCategory>() } )),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	editSubategory$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.EDIT_SUBCATEGORY_SAVE),
		switchMap((action:CategoriesActions.EditSubcategory) => this.dataService.updateSubcategoryAdmin(action.subcategory)
			.pipe(
				map((resp:any) => new CategoriesActions.EditSubcategorySuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	deleteSubcategory$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.DELETE_SUBCATEGORY_CONFIRM),
		withLatestFrom(this.store$.select('adminCategories', 'SubcategoryToDelete')),
		switchMap(([action, subcategory]) => {
			return this.dataService.deleteSubcategory(subcategory.subCategoryID)
				.pipe(
					map((resp:any) => new CategoriesActions.DeleteSubcategorySuccess()),
					catchError(((resp:any) => {
						return of(new StatusActions.SetErrorMessage(resp.error.Message))
					}))
				)
			}
		)
	)
	@Effect()
	reorderSubcategories$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.SAVE_SUBCATEGORIES),
		switchMap((action:CategoriesActions.SaveSubcategories) => this.dataService.updateSubcategories(action.subcategories)
			.pipe(
				map((resp:any) => new CategoriesActions.SaveSubcategoriesSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	addSubcategory$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.ADD_SUBCATEGORY),
		switchMap((action:CategoriesActions.AddSubcategory) => this.dataService.insertSubcategory(action.subcategory.categoryID, action.subcategory)
			.pipe(
				map((resp: any) => new CategoriesActions.AddSubcategorySuccess( resp )),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	selectSubcategory$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.ACTIVATE_SUBCATEGORY),
		switchMap((action:CategoriesActions.ActivateSubcategory) => this.dataService.getSubcategoryTexts(action.subcategory.subCategoryID)
			.pipe(
				map((resp:any) => new CategoriesActions.GetSubcatTextsSuccess(resp)),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	editSubcatText$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.EDIT_SUBCAT_TEXT_CONFIRM),
		switchMap((action:CategoriesActions.EditSubcatTextConfirm) => this.dataService.updateSubcatText(action.text)
			.pipe(
				map((resp:any) => new CategoriesActions.EditSubcatTextSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	deleteSubcatText$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.DELETE_SUBCAT_TEXT_CONFIRM),
		withLatestFrom(this.store$.select('adminCategories', 'TextToDelete')),
		switchMap(([action, text]) => this.dataService.deleteSubcatText(text.textID)
			.pipe(
				map((resp:any) => new CategoriesActions.DeleteSubcatTextSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	reorderSubcatTexts$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.UPDATE_SUBCAT_TEXTS),
		switchMap((action:CategoriesActions.UpdateSubcatTexts) => this.dataService.updateSubcatTexts(action.texts)
			.pipe(
				map((resp:any) => new CategoriesActions.UpdateSubcatTextsSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	addText$ = this.actions$.pipe(
		ofType(CategoriesActions.CategoryActionTypes.ADD_SUBCAT_TEXT),
		switchMap((action:CategoriesActions.AddSubcatText) => this.dataService.insertSubcatText(action.text.subCategoryID, action.text.defaultText, action.text.revisionNeededDate)
			.pipe(
				map((resp: any) => new CategoriesActions.AddSubcatTextSuccess(resp)),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)

	constructor(private actions$: Actions, private store$:Store<IAppState>, private dataService:DataService) {}
}
