import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Folder, User, MediaContents } from 'acc-models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService, SessionService } from 'acc-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'acc-common-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  styles: [`
		.libraryFolders {
			width:400px;
		}
  `]
})


export class FileSelectorComponent implements OnInit  {

	@Output() onAction: EventEmitter<{actionName: string, payload: any}> = new EventEmitter();
	public sharedAssets: Array<Folder>;
	public myAssets: Array<Folder>;
	public activeFolder: Folder;
	public contents: MediaContents;


	constructor(public dataService: DataService, private sessionService: SessionService, public dialogRef: MatDialogRef<FileSelectorComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
	ngOnInit():void {

		const user = this.sessionService.getUser();
		let sharedLibFolders: Array<Folder>, sharedWithMeFolders: Array<Folder> = [], myLibFolders: Array<Folder> = [];
		this.sharedAssets = new Array<Folder>(); 
		this.myAssets = new Array<Folder>(); 

		let mbAssetFolder = new Folder(1, -1, "Shared Asset Library", new Array<Folder>(), null);
		this.dataService.getSharedLibCategories(user.orgID).subscribe((resp:any) => {
			
			sharedLibFolders = resp.map(f => {
				return new Folder(1, f.mediaCategoryID, f.mediaCategoryDesc, f.MediaCategories, mbAssetFolder);
			});
			mbAssetFolder.MediaCategories.push(...sharedLibFolders);
			this.sharedAssets.push(mbAssetFolder);
		})


		let myAssetLibraryMainFolder: Folder = null;
		if(!this.data.hideMyAssets) {
			myAssetLibraryMainFolder = new Folder(2, -1, "My Asset Library", new Array<Folder>(), null);
			this.myAssets.push(myAssetLibraryMainFolder);

			
			//my custom folders
			this.dataService.getUserLibCategories(user.userID).subscribe((resp:any) => {
				myLibFolders = resp.map(f => {
					return new Folder(2, f.mediaCategoryID, f.mediaCategoryDesc, f.MediaCategories, myAssetLibraryMainFolder);
				});
				myAssetLibraryMainFolder.MediaCategories = [...myLibFolders, ...sharedWithMeFolders]
				
			})

			//shared with me folders
			this.dataService.getSharedFolders(user.userID).subscribe((resp:any) => {
				sharedWithMeFolders = resp.map(f => {
					return new Folder(4, f.userSharedFrom, f.userShareName, f.MediaCategories, myAssetLibraryMainFolder);
				});
				myAssetLibraryMainFolder.MediaCategories = [...myLibFolders, ...sharedWithMeFolders]
			})
		}
	}

	folderAction(actionInfo) {
		this.loadFolderContents(actionInfo.payload.folder);
	}
	fileAction(actionInfo: { actionName: string, payload: any }) {
		let file = actionInfo.payload.file;
		this.dialogRef.close(file);
	}

	private loadFolderContents(folder: Folder) {
		let user:User = this.sessionService.getUser();
		let getContents:Observable<any>;
		switch (folder.type) {
			case 1:
				getContents = this.dataService.getSharedLibContents(user.orgID, folder.mediaCategoryID); break;
			case 2: 
				getContents = this.dataService.getUserLibContents(user.userID, folder.mediaCategoryID); break;
			case 3:
				getContents = this.dataService.getModuleContents(user.userID, folder.mediaCategoryID); break;
			case 4: 
				getContents = this.dataService.getSharedContents(user.userID, folder.mediaCategoryID); break;
			case 5: 
				getContents = this.dataService.getCart(folder.mediaCategoryID)

		}
		if(getContents) {
			getContents.subscribe((resp) => {
				const allContents = new MediaContents(resp.MediaCategories, (resp.Medias || resp.MediaUsers), resp.mediaCategoryID, folder.type);
				if(this.data.fileTypesToShow && this.data.fileTypesToShow.length > 0) {
					allContents.LibraryItems = allContents.LibraryItems.filter(m => this.data.fileTypesToShow.indexOf(m.mediaFileExt) > -1)
				}
				this.contents = allContents;
			})
		}

	}
	close(att) {
		this.dialogRef.close(att);
	}

	search(term: string) {
		this.dataService.search(this.sessionService.getUser().userID, term).subscribe(res => {
			this.contents = new MediaContents(null, res, null, 5);
			this.activeFolder = null;
		});
	}
}
