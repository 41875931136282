<mat-dialog-content id="features-dialog-content">
  <div class="dialogContent">
    <div class="signup-blocks-section">
      <div class="signup-div-section">
        <div class="signup-container">
          <div class="signup-image">
          </div>
          <div class="signup-info">
            <h1 class="signup-title"> <span class="signup-bold-only">Accelerate pro</span>fessional version</h1>
      
            <div class="signup-features-title">
              <span>Why ORG Brokers go Pro</span>
            </div>
            <div class="signup-features">
              <ul>
                <li> - Send term quotes and proposals to clients.</li>
                <li> - Client/recipient engagement insight.</li>
                <li> - Send personalized and branded videos.</li>
                <li> - Access to rich, insurance-based media.</li>
                <li> - Consumer Self-Quoting Platform allows your prospects and customers to generate term life quotes 24/7 on a site branded for your brokerage.</li>
              </ul>

            </div>
        
            <div class="signup-button-container">
              <button *ngIf="isAdmin" mat-raised-button class="signup-button" color="primary" (click)="upgrade()" >Upgrade today</button>
              <span *ngIf="!isAdmin" class="signup-features-title" >Contact an Administrator in your organization and ask them to Go Pro today!</span>
            </div>

          </div>
        </div>
        <div class="signup-features-container" [@.disabled]="disableAnimation">
          <mat-expansion-panel [expanded]="false" (opened)="scroll()">
            <mat-expansion-panel-header>
              <mat-panel-title>
                See all features
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
         
            <acc-main-features-table></acc-main-features-table>
    
          </mat-expansion-panel>
        </div>
      </div>
      <div class="close" (click)="close()"><mat-icon>highlight_off</mat-icon></div> 
    </div>

  </div>
</mat-dialog-content>
