<div class="container sso-container">
  <mat-card class="sso-container-card" >
    <mat-card-header>
      <mat-card-title>Logging in...</mat-card-title>
    </mat-card-header>

    <mat-card-content >
      <div *ngIf="loading" class="sso-spinner">
        <mat-spinner></mat-spinner>
      </div>

      <div *ngIf="!!orgs && orgs.length > 0">
        <h1 class="sso-orgs-title">Select an organization to login... </h1>
        <mat-card *ngFor="let o of orgs" class="sso-card">
          <mat-card-header>
            <img *ngIf="!!o.logo && o.logo.imageURLPath != ''" mat-card-avatar src="{{o.logo.imageURLPath}}" alt="{{o.orgDesc}}">
            <mat-card-title>{{o.orgDesc}}</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
          </mat-card-header>
          <mat-card-actions>
            <button mat-raised-button class="sso-button" color="primary" (click)="loginWithToken(o)">LOGIN</button>
          </mat-card-actions>
        </mat-card>

      </div>

    </mat-card-content>
  </mat-card>

</div>