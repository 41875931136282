<div class="error-container {{cssClass}}">

	<div class="error-content">
		<h1>{{title}}</h1>
		<p>{{message}}</p>
		<div class="close" (click)="close()"><mat-icon>highlight_off</mat-icon></div>
		<div class="dialog-ok-container">
			<button class="dialog-button" mat-stroked-button (click)="close()">OK</button>
		</div>
	</div>
	<div class="bottom-branding">
		<div class="branding-neg-slant"></div>
		<div class="branding-pos-slant"></div>
	</div>
</div>