import { Component, OnInit, Inject, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { CustVideoRequestParm } from "acc-models";
import { DataService } from "acc-services";
import { SetErrorMessage } from "projects/acc-main/src/app/store/actions/status.actions";
import { IAppState } from "projects/acc-main/src/app/store/state/app.state";
import { environment } from 'projects/acc-main/src/environments/environment';
import { timer, SubscriptionLike, Subject } from "rxjs";

export interface DialogData {
	programID: number;
	programName: string;
	custVideoRequestParams: Array<CustVideoRequestParm>;
	estBuildSeconds: number;
	userID: string; 
	orgID: number;
	process: boolean;
	videoURL: string;
	mediaItem: any;
	thumbnailURL:string;
	onVideoGenerated: Subject<{ mediaAsyncID: string, videoURL: string, thumbnailURL: string}>;
	isAdmin:boolean;
}

@Component({
	selector: "acc-main-preview-video",
	templateUrl: "./preview-video.component.html",
	styleUrls: ["./preview-video.component.scss"]
})
export class PreviewVideoComponent implements OnInit {
	public onError: Subject<string> = new Subject();
	private getVideoPreviewSubscription: SubscriptionLike;
	private getProgressBarSubscription: SubscriptionLike;
	videoUrl: string;
	thumbnailURL: string;
	showProgress: Boolean = true;
	counter: number = 0;
	sharedURL: string;
	public isAdmin = false;

	constructor(
		public dialogRef: MatDialogRef<PreviewVideoComponent>,
		private dataService: DataService,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private store: Store<IAppState>
	) {}

	ngOnInit() {
		 this.isAdmin = this.data.isAdmin;
		 if(this.data.mediaItem && this.data.mediaItem.mediaUrl) {
			const item = this.data.mediaItem;
			this.videoUrl = item.mediaUrl;
			this.showProgress = false;
			this.thumbnailURL = item.imageFile;
			this.sharedURL = encodeURI(environment.share_video_url + "?videoUrl=" + this.videoUrl + "&thumbnailURL=" + this.thumbnailURL +"&description=" + this.data.programName + "&title=" + this.data.programName);

		 } else {
			this.showPreview(this.data.programID, this.data.custVideoRequestParams, this.data.estBuildSeconds, this.data.userID, this.data.orgID, this.data.process, this.data.videoURL, this.data.mediaItem ? this.data.mediaItem.mediaAsyncID : '', this.data.programName, this.data.thumbnailURL);
		}
	}

	showPreview(programID, custVideoRequestParams, estBuildSeconds, userID, orgID, process, videoURL, mediaAsyncID, programName, thumbnailURL) {
		this.videoUrl = null;
		if(process){
			this.startCounter(estBuildSeconds);

			this.getVideoPreviewSubscription = this.dataService.previewVideo(programID, custVideoRequestParams, orgID, userID, mediaAsyncID, programName).subscribe(
				(video: any) => {
					this.videoUrl = video.videoURL;
					this.showProgress = false;
					this.thumbnailURL = video.thumbnailURL;
					this.sharedURL = encodeURI(environment.share_video_url + "?videoUrl=" + this.videoUrl + "&thumbnailURL=" + this.thumbnailURL +"&description=" + this.data.programName + "&title=" + this.data.programName);
					if(this.data.onVideoGenerated) {
						this.data.onVideoGenerated.next({mediaAsyncID, videoURL: video.videoURL, thumbnailURL: video.thumbnailURL})
					}
				},
				err => {
					this.showError({message:"Error getting video preview"});
					this.onNoClick();
				}
			);
		}else{
			this.videoUrl = videoURL;
			this.thumbnailURL = thumbnailURL;
			this.showProgress = false;
			this.sharedURL = encodeURI(environment.share_video_url + "?videoUrl=" + this.videoUrl + "&thumbnailURL=" + this.thumbnailURL +"&description=" + this.data.programName + "&title=" + this.data.programName);
		}
	}

	startCounter(estBuildSeconds) {
		var increment = 100 / estBuildSeconds;
		const source = timer(1000, 1000);
		this.getProgressBarSubscription = source.subscribe(val => {
			this.counter = this.counter + increment;
		});
	}

	showError(err) {
		if (Array.isArray(err.error)) {
			err.error.forEach((e) => {
				this.store.dispatch(new SetErrorMessage(e.error));
			});
		} else {
			this.store.dispatch(new SetErrorMessage(err.message || err.error.ExceptionMessage));
		}
	}

	onNoClick(): void {
		this.dialogRef.close(
			{
				videoUrl: this.videoUrl,
				thumbnailURL: this.thumbnailURL,
				saveToAssetLibrary: false,
			}
		);
		if(this.getVideoPreviewSubscription) { this.getVideoPreviewSubscription.unsubscribe(); }
		if(this.getProgressBarSubscription) { this.getProgressBarSubscription.unsubscribe(); }
	}

	saveToAssetLibrary(){
		this.dialogRef.close(
			{
				videoUrl: this.videoUrl,
				thumbnailURL: this.thumbnailURL,
				saveToAssetLibrary: true,
			}
		);
		if(this.getVideoPreviewSubscription) { this.getVideoPreviewSubscription.unsubscribe(); }
		if(this.getProgressBarSubscription) { this.getProgressBarSubscription.unsubscribe(); }
	}
}
