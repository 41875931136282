import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CurrentSection } from '../health-details.component';

@Component({
  selector: 'acc-main-family-history-form',
  templateUrl: './family-history-form.component.html',
  styleUrls: ['./family-history-form.component.scss']
})
export class FamilyHistoryFormComponent implements OnInit {
  public fatherHistory: boolean | null = null;
  public motherHistory: boolean | null = null;
  public sisterHistory: boolean | null = null;
  public brotherHistory: boolean | null = null;
  public fatherPassedAway: boolean | null = null;
  public motherPassedAway: boolean | null = null;
  public sisterPassedAway: boolean | null = null;
  public brotherPassedAway: boolean | null = null;

  @Input() familyHistory: FormGroup;
  @Input() selectedSection: CurrentSection;
  @Input() maleConditions: Array<Condition>;
  @Input() femaleConditions: Array<Condition>;
  @Input() fatherDeathConditions: Array<any>;
  @Input() motherDeathConditions: Array<any>;
  @Input() sisterDeathConditions: Array<any>;
  @Input() brotherDeathConditions: Array<any>;

  constructor() { }

  ngOnInit() {
    this.watchHistoryClicks();
    this.watchConditionsChanges();
    this.watchPassedAwayChanges();
  }

  watchHistoryClicks() {
    this.familyHistory.get('father').get('fatherHistory').valueChanges.subscribe(value => {
      this.fatherHistory = value === true;
      if (!this.fatherHistory) {
        this.clearGroupFields('father');
      }
    });
    this.familyHistory.get('mother').get('motherHistory').valueChanges.subscribe(value => {
      this.motherHistory = value === true;
      if (!this.motherHistory) {
        this.clearGroupFields('mother');
      }
    });
    this.familyHistory.get('sister').get('sisterHistory').valueChanges.subscribe(value => {
      this.sisterHistory = value === true;
      if (!this.sisterHistory) {
        this.clearGroupFields('sister');
      }
    });
    this.familyHistory.get('brother').get('brotherHistory').valueChanges.subscribe(value => {
      this.brotherHistory = value === true;
      if (!this.brotherHistory) {
        this.clearGroupFields('brother');
      }
    });
  }

  watchConditionsChanges() {
    const male = 'male';
    const female = 'female';

    this.familyHistory.get('father').get('conditions').valueChanges.subscribe(async (data: Array<string>) => {
      if (data.length === 0) {
        this.familyHistory.get('father').get('fatherDie').setValue('');
      }
      this.fatherDeathConditions = await this.getConditions(data, male);
    });
    this.familyHistory.get('mother').get('conditions').valueChanges.subscribe(async (data: Array<string>) => {
      if (data.length === 0) {
        this.familyHistory.get('mother').get('motherDie').setValue('');
      }
      this.motherDeathConditions = await this.getConditions(data, female);
    });
    this.familyHistory.get('sister').get('conditions').valueChanges.subscribe(async (data: Array<string>) => {
      if (data.length === 0) {
        this.familyHistory.get('sister').get('sisterDie').setValue('');
      }
      this.sisterDeathConditions = await this.getConditions(data, female);
    });
    this.familyHistory.get('brother').get('conditions').valueChanges.subscribe(async (data: Array<string>) => {
      if (data.length === 0) {
        this.familyHistory.get('brother').get('brotherDie').setValue('');
      }
      this.brotherDeathConditions = await this.getConditions(data, male);
    });
  }

  watchPassedAwayChanges() {
	const male = 'male';
    const female = 'female';
    this.familyHistory.get('father').get('fatherDie').valueChanges.subscribe(data => {
      this.fatherPassedAway = data === true;
      if (this.fatherPassedAway) {
		  this.fatherDeathConditions = this.getConditions(this.familyHistory.get('father').get('conditions').value, male);
        this.familyHistory.get('father').get('deathConditions').setValue('');
        this.familyHistory.get('father').get('deathAge').setValue('');
      }
    });
    this.familyHistory.get('mother').get('motherDie').valueChanges.subscribe(data => {
      this.motherPassedAway = data === true;
      if (this.motherPassedAway) {
		  this.motherDeathConditions = this.getConditions(this.familyHistory.get('mother').get('conditions').value, female);
        this.familyHistory.get('mother').get('deathConditions').setValue('');
        this.familyHistory.get('mother').get('deathAge').setValue('');
      }
    });
    this.familyHistory.get('sister').get('sisterDie').valueChanges.subscribe(data => {
      this.sisterPassedAway = data === true;
      if (this.sisterPassedAway) {
		  this.sisterDeathConditions = this.getConditions(this.familyHistory.get('sister').get('conditions').value, female);
        this.familyHistory.get('sister').get('deathConditions').setValue('');
        this.familyHistory.get('sister').get('deathAge').setValue('');
      }
    });
    this.familyHistory.get('brother').get('brotherDie').valueChanges.subscribe(data => {
      this.brotherPassedAway = data === true;
      if (this.brotherPassedAway) {
		  this.brotherDeathConditions = this.getConditions(this.familyHistory.get('brother').get('conditions').value, male);
        this.familyHistory.get('brother').get('deathConditions').setValue('');
        this.familyHistory.get('brother').get('deathAge').setValue('');
      }
    });
  }

  getConditions(conditions: Array<string>, gender: string) {
    const currentConditions: Array<any> = [];
    let currentCondition: Condition;
    if (conditions) {
      conditions.forEach((condition) => {
        switch (gender) {
          case 'male':
            currentCondition = this.maleConditions.find(c => c.code === condition);
            break;
            case 'female':
            currentCondition = this.femaleConditions.find(c => c.code === condition);
            break;
        }
        if (currentCondition) {
          currentConditions.push(currentCondition);
        }
      });
    }
    return currentConditions;
  }

  hasSpecificCondition(condition: string, group: string) {
    if (condition && group) {
      const conditions: Array < any > = this.familyHistory.get(`${group}`).get('conditions').value;
      if (!conditions) {
        return false;
      }
      const hasCondition = conditions.find(c => c === condition) ? true : false;
      if (!hasCondition) {
        this.clearAgeDiagnosedField(condition, group);
      }
      return hasCondition;
    }
  }

  hasCancer(group: string) {
    if (group) {
      const conditions: Array < any > = this.familyHistory.get(`${group}`).get('conditions').value;
      if (!conditions) {
        return false;
      }
      const hasCancer = conditions.find(c => c === 'prostateCancer' || c === 'testicularCancer' || c === 'colonCancer' ||
      c === 'melanoma' || c === 'pancreaticCancer' || c === 'otherCancer' || c === 'stomachCancer' ||
      c === 'breastCancer' || c === 'cervicalCancer' || c === 'endometrialCancer' || c === 'fallopianTubeCancer' ||
      c === 'ovarianTubeCancer' || c === 'vaginalCancer' || c === 'vulvarCancer') ?
      true : false;
      if (!hasCancer) {
        this.clearAgeDiagnosedCancerField(group);
      }
      return hasCancer;
    }
  }

  clearGroupFields(group: string) {
    if (group) {
      this.familyHistory.get(`${group}`).get('conditions').setValue('');
      this.familyHistory.get(`${group}`).get(`${group}DiabetesDiagnosedAge`).setValue('');
      this.familyHistory.get(`${group}`).get(`${group}CardioVascularDiagnosedAge`).setValue('');
      this.familyHistory.get(`${group}`).get(`${group}StrokeDiagnosedAge`).setValue('');
      this.familyHistory.get(`${group}`).get(`${group}CancerDiagnosedAge`).setValue('');
      this.familyHistory.get(`${group}`).get('deathConditions').setValue('');
      this.familyHistory.get(`${group}`).get(`${group}Die`).setValue('');
      this.familyHistory.get(`${group}`).get('deathAge').setValue('');
    }
  }

  clearAgeDiagnosedField(condition: string, group: string): void {
    switch (condition) {
      case 'diabetes':
        this.familyHistory.get(`${group}`).get(`${group}DiabetesDiagnosedAge`).setValue('');
        break;
      case 'heartDisease':
        this.familyHistory.get(`${group}`).get(`${group}CardioVascularDiagnosedAge`).setValue('');
        break;
      case 'stroke':
          this.familyHistory.get(`${group}`).get(`${group}StrokeDiagnosedAge`).setValue('');
      break;
    }
  }

  clearAgeDiagnosedCancerField(group: string): void {
    this.familyHistory.get(`${group}`).get(`${group}CancerDiagnosedAge`).setValue('');
  }

}

export interface Condition {
  code: string;
  name: string;
}
