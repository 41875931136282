
<button mat-raised-button class="copy-quote-button quote-button" color="primary" [disabled]="disabled" #copyMenuTrigger="matMenuTrigger" (menuOpened)="selectText()" [matMenuTriggerFor]="copyQuoteMenu"  [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.q_copy_quote}}">Save As</button>
<mat-menu #copyQuoteMenu="matMenu" [overlapTrigger]="false" (closed)="copyQuoteName = ''" >
	<div class="copy-quote-menu" (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();">
		<mat-form-field>
			<mat-label>Description of New Quote</mat-label>
			<input matInput type="text" [(ngModel)]="copyQuoteName" #nameInput (keyup.enter)="copyQuote()">
		</mat-form-field>
		<div class="copy-quote-action-button" (click)="copyQuote()">Save</div>
	</div>
</mat-menu>