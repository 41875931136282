import { NgModule } from '@angular/core';
import { AccModelsModule } from 'acc-models';
import { DataService } from './services/data.service';
import { SessionService } from './services/session.service';
import { DocGenService } from './services/docgen.service';
import { CrmService } from './services/crm.service';
import { AuthGuard } from './guards/auth.guard';
import { IEGuard } from './guards/ie.guard';
import { CheckoutService } from './services/checkout.service';
import { EmailEditorService } from './services/email-editor.service';
import { DynamicScriptLoaderService } from './services/dynamic-script.service';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { MixpanelService } from './services/mixpanel.service';
import { TourService } from './services/tour.service';

@NgModule({
  declarations: [
	  
  ],
  imports: [
	  AccModelsModule
  ],
  exports: [
	
  ],
  providers: [DataService, SessionService, DocGenService, CrmService, AuthGuard, IEGuard, CanDeactivateGuard, CheckoutService, EmailEditorService, DynamicScriptLoaderService, MixpanelService, TourService]
})
export class AccServicesModule { }
