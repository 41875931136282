<div mat-dialog-header>
	<h2 mat-dialog-title>Log In As</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent" >

		<div class="back-to-original" *ngIf="allUsers && originalUserID && originalOrgID">
			<a class="back-to-original" (click)="backToOriginalUser()">Log in as the original user</a>
		</div>		

		<div class="organization-dropdown" *ngIf="allUsers && showDropdown && (!originalUserID || !originalOrgID)">
			<mat-form-field appearance="outline" class="animated fadeIn" >
				<mat-label>Select Organization</mat-label>
				<mat-select  matNativeControl [(ngModel)]="selectedOrganization" (selectionChange)="onSelectedOrganizationChange()">
					<mat-option [value]="this.org.orgID">{{this.org.orgDesc}}</mat-option>
					<mat-option *ngFor="let o of organizations" [value]="o.orgID">{{o.orgDesc}}</mat-option>
				</mat-select >
			</mat-form-field>
		</div>

		<p class="loading" *ngIf="!allUsers">Loading...</p>
		<ul>
			<li *ngFor="let u of allUsers">
				<a (click)="selectUser(u)">{{u.firstName}} {{u.lastName}}</a>
			</li>
		</ul>		
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>
	
	