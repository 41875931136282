import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Folder } from 'acc-models';

@Component({
  selector: 'acc-common-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent implements OnInit {

	public selectedFolder: Folder;
	public errorMessage: string = '';

	constructor(private dialogRef: MatDialogRef<CopyComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

	close() {
		this.dialogRef.close();
	}

	copy() {
		if(this.selectedFolder) {
			this.errorMessage = '';
			this.dialogRef.close(this.selectedFolder);
		} else {
			this.errorMessage = 'Please select a folder.'
		}
		
	}

	action(evt: {actionName: string, payload: any }) {
		this.selectedFolder = evt.payload.folder;
	}

	ngOnInit() {
	}

}
