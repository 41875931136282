
import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'acc-main-template-name',
	templateUrl: './template-name.component.html',
	styleUrls: ['./template-name.component.scss']
})
export class TemplateNameComponent implements OnInit, AfterViewInit {

	@ViewChild('templateNameInput', { static: true }) templateNameInput: ElementRef
	public templateNameField = new FormControl('', [Validators.required]);
	public nameForm: FormGroup;
	constructor(public dialogRef: MatDialogRef<TemplateNameComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

	ngOnInit() {
		this.templateNameField.setValue(this.data.defaultName)
		this.nameForm = this.fb.group({ name: this.templateNameField });
	}

	ngAfterViewInit() {
		this.templateNameInput.nativeElement.select();
		this.templateNameInput.nativeElement.focus();
	}
	close(): void {
		this.dialogRef.close();
	}

	submit(): void {
		this.dialogRef.close({ templateName:  this.templateNameField.value } );
	}

}
