<li class="module" [ngClass]="{disabled: !module.active}">
	<div class="circle box">
		<div class="circleInner">
			<div class="front">
				<mat-icon fontSet="material-icons-outlined">launch</mat-icon>
				<a [routerLink]="['/module', module.moduleID]"  *ngIf="module.active"><span>{{module.moduleDesc}}</span></a>
				<div class="disabled" *ngIf="!module.active"><span >{{module.moduleDesc}}</span></div>
			</div>
			<div class="back">
				<a [routerLink]="['/module', module.moduleID]"><span class="addNew">New</span></a>
				<span class="edit" (click)="showDocuments(module.moduleID)">Edit</span>
			</div>
		</div>
	</div>
</li>
