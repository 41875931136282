<div *ngIf="medias" class="">
	<div class="contentsFiles" *ngIf="folderType != 'cart'">
		<div class="contentFile" *ngFor="let file of medias" >
			<div class="fileName" *ngIf="!folderType">
				<div>{{file.description}}</div>
			</div>
			<div class="fileName" [ngSwitch]="file.mediaSourceID" *ngIf="folderType">
				<div *ngSwitchCase="1">{{file.description}}</div>
				<div *ngSwitchCase="2" contenteditable="true" (focus)="setContent(file, $event)" (blur)="renameFile(file, $event)" (keydown.enter)="renameFile(file, $event)" (keydown.tab)="renameFile(file, $event, true)" (keyup.esc)="resetFile(file, $event)">{{file.description}}</div>
				<div *ngSwitchCase="3" contenteditable="true" (focus)="setContent(file, $event)" (blur)="renameFile(file, $event)" (keydown.enter)="renameFile(file, $event)" (keydown.tab)="renameFile(file, $event, true)" (keyup.esc)="resetFile(file, $event)">{{file.description}}</div>
			</div>
			<div class="file" [appFileActions]="showFileActions" [file]="file" (actionClick)="action($event)" [open]="openLibraryItemId == file.id">
				<a (click)="routeTo(['/module', file.moduleID, (file.id)])" *ngIf="file.mediaSourceID == 3 && file.AllowEdit" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
				<a (click)="showPreview(file.id)" *ngIf="file.mediaSourceID == 3 && !file.AllowEdit" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
				<a (click)="action({ actionName: 'newWindow', payload: { file: file } })" *ngIf="file.mediaSourceID != 3" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
				<div class="fileType" *ngIf="file.mediaFileExt">{{file.mediaFileExt.substring(1)}}</div>
			</div>
		</div>
	</div>
	<!-- not happy about duplicating code here, but can't conditionaly add cdkDrop attribute -->
	<div class="contentsFiles" cdkDropList cdkDropListOrientation="horizontal" *ngIf="folderType == 'cart'" (cdkDropListDropped)="drop($event)">
		<div *ngFor="let file of medias" cdkDrag >
			<div class="fileName" *ngIf="folderType == 'cart'">
				<div>{{file.description}}</div>
			</div>
			<div class="file" [appFileActions]="showFileActions" [file]="file" (actionClick)="action($event)" [open]="openLibraryItemId == file.id">
				<a (click)="routeTo(['/module', file.moduleID, (file.id)])" *ngIf="file.mediaSourceID == 3 && file.AllowEdit" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
				<a (click)="showPreview(file.id)" *ngIf="file.mediaSourceID == 3 && !file.AllowEdit" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
				<a (click)="action({ actionName: 'newWindow', payload: { file: file } })" *ngIf="file.mediaSourceID != 3" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
				<div class="fileType" *ngIf="file.mediaFileExt">{{file.mediaFileExt.substring(1)}}</div>
			</div>
		</div>
	</div>
</div>