export class LibraryItem {
	public id: string;
	public mediaSourceID: number;
	public description: string;
	public shortDescription: string;
	public moduleID: number;
	public mediaFileExt:string;
	public mediaTypeID: number;
	public mediaCategoryID: number;
	public savedFileName: string;
	public imageFile:string;
	public AllowAddCart:boolean;
	public AllowCopy:boolean;
	public AllowDelete:boolean;
	public AllowMove:boolean;
	public AllowShare:boolean;
	public searchWords: string;
	public isCart: boolean;
	public sortOrder: number;
	public docCreated: Date;
	public type: string;

	constructor(o?:any) {
		const values = {
			id: o.mediaItemID || o.mediaAsyncID || o.docID,
			description: o.mediaDesc || o.docDesc,
			shortDescription: o.mediaDesc || o.docDescBaseOnly,
			type: !!o.mediaAsyncID ? 'async' : 'sync'
		}
		Object.assign(this, o, values);
	}
}

