import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { Router } from '@angular/router';

import { User } from 'acc-models';
import { Settings } from 'acc-models';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { SubscriptionLike } from 'rxjs';

@Component({
    selector: 'login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {
    public org: any;
	private loginSubscription: SubscriptionLike;
	private loginFormStatusSubscription: SubscriptionLike;
	public errorMessage:string = '';

	public loginBtnOptions: MatProgressButtonOptions;
	public loginForm: FormGroup;
	public usernameField = new FormControl('', [Validators.required]);
	public passwordField = new FormControl('', [ Validators.required]);

	@Output() onNavigateTo: EventEmitter<string> = new EventEmitter();
	@Output() onLogin: EventEmitter<User> = new EventEmitter();

	constructor (private dataService: DataService, 
		private router:Router, 
		private sessionService: SessionService, 
		private fb: FormBuilder) {
    }
    
    login() {
		if(!this.usernameField.invalid && !this.passwordField.invalid) {
			this.loginBtnOptions.active = true;

			this.loginSubscription = this.dataService.login(this.org.orgID, this.usernameField.value, this.passwordField.value).subscribe(resp => {
				
				this.sessionService.setUser(new User(resp));

				if (!!this.org.requireSubscription && (!this.org.stripeCustomerID && this.org.subscriptionLevelID != 1)){
					this.router.navigateByUrl("/signup/subscription");
				}

				this.sessionService.authToken = (<User>resp).token;
				//get settings
				this.dataService.getSettings(this.org.orgID).subscribe(resp => {
					this.sessionService.settings = new Settings(resp);
					this.loginBtnOptions.active = false;
					this.onLogin.next(new User(resp));
				}, error => {
					//if a problem, set defaults
					this.sessionService.settings = new Settings();
					this.loginBtnOptions.active = false;
					this.onLogin.next(new User(resp));
				})
				this.sessionService.authToken = (<User>resp).token;
				
			}, error => {
				this.loginBtnOptions.active = false;
				this.errorMessage = error.error.Message;
				this.onLogin.next(null);

			});
		}
    }
    navigateTo(page: string):void {
		this.onNavigateTo.next(page);
	}
    ngOnInit():void {
		this.org = this.sessionService.getItem('organization');
		this.loginBtnOptions = {
			active: false,
			text: 'Log In', 
			raised: true,
			stroked: false,
			flat:false,
			buttonColor: 'primary',
			spinnerColor: 'primary', 
			mode: 'indeterminate',
			fullWidth: true,
			spinnerSize: 20,
			disabled:true
		}
		this.loginForm = this.fb.group({
			username: this.usernameField,
			password: this.passwordField
		});
		this.loginFormStatusSubscription = this.loginForm.statusChanges.subscribe(status => this.loginBtnOptions.disabled = (status != 'VALID') )
	}
	
	ngOnDestroy():void {
		if(this.loginSubscription) { this.loginSubscription.unsubscribe(); }
		if(this.loginFormStatusSubscription) { this.loginFormStatusSubscription.unsubscribe(); }
	}


}