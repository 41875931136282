import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  constructor() {}
  transform(str: string) {
	  if(str.trim() == '') {
		  return '';
	  }
    return `(${str.substr(0,3)}) ${str.substr(3,3)}-${str.substr(6)}`;
  }
} 