
<h1>Last Step to receive a Free quote right now!</h1>

<div class="form">
	<mat-form-field appearance="outline">
		<mat-label>First Name</mat-label>
		<input matInput [formControl]="firstNameField" />
		<mat-error *ngIf="firstNameField.hasError('required')">This field is required</mat-error>
	</mat-form-field>
	<mat-form-field appearance="outline">
		<mat-label>Last Name (optional)</mat-label>
		<input matInput [formControl]="lastNameField" />
	</mat-form-field>
	<mat-form-field appearance="outline">
		<mat-label>Email</mat-label>
		<input matInput [formControl]="emailField" type="email" />
		<mat-error *ngIf="emailField.hasError('required')">This field is required</mat-error>
		<mat-error *ngIf="emailField.hasError('pattern')">Please enter a valid email</mat-error>
	</mat-form-field>
	<mat-form-field appearance="outline">
		<mat-label>Phone (optional)</mat-label>
		<input matInput [formControl]="phoneField" [textMask]="{mask: phoneMask, guide: false}" />
	</mat-form-field>
</div>
<div class="actions">
	<button mat-raised-button color="primary" (click)="back()">Back</button>
	<button mat-raised-button color="primary" (click)="next()">Finish</button>
</div>
