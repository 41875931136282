import { Action } from "@ngrx/store";

export enum SignUpActionTypes {
	SAVE_SIGN_UP_DATA = "[SIGN_UP] - Save Sign Up Data",
	GET_SIGN_UP_DATA = "[SIGN_UP] - Get Sign Up Data",
}
export class SetSignUpData implements Action {
	public readonly type = SignUpActionTypes.SAVE_SIGN_UP_DATA;
	constructor(public userData: any) {}
}

export class GetSignUpData implements Action {
	public readonly type = SignUpActionTypes.GET_SIGN_UP_DATA;
	constructor() {}
}

export type SignUpActions = SetSignUpData | GetSignUpData;
