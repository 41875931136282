<div mat-dialog-header>
	<h2 mat-dialog-title>{{data.title || 'Video'}}</h2>
</div>

<mat-dialog-content>
	<div class="video">
		<video #videoPlayer *ngIf="videoUrl; else loading" width="780" controls style="background:black" autoplay >
			<source src="{{videoUrl}}">
		</video>
		<ng-template #loading>
			<div class="loading-message">Please wait while your video is being created</div>
			<div *ngIf="fires > 0" class="fade-in">This process can take up to two minutes</div>
			<div *ngIf="fires > 1" class="fade-in">Still processing</div>
			<div *ngIf="videoUrl === ''" class="error">There was a problem processing your video.  Please try again later.</div>
		</ng-template>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>