import { ICatManageState } from "../state/catmanage.state";
import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { ICommunicationState } from '../state/communication.state';

const selectState =  (state:IAppState) => state.communication;

export const selectTitle = createSelector(
	selectState,
	(state: ICommunicationState) => state.title
)

export const selectLists = createSelector(
	selectState,
	(state: ICommunicationState) => state.lists
)
export const selectList = createSelector(
	selectState,
	(state: ICommunicationState) => state.list
)
export const selectListIdToDelete = createSelector(
	selectState,
	(state:ICommunicationState) => state.listIdToDelete
)

export const selectCampaigns = createSelector(
	selectState,
	(state: ICommunicationState) => state.campaigns
)
export const selectActiveCampaignId = createSelector(
	selectState,
	(state: ICommunicationState) => state.activeCampaignId
)
export const selectActiveGroupId = createSelector(
	selectState,
	(state:ICommunicationState) => state.activeGroupId
)
export const selectActiveCampaign = createSelector(
	selectState,
	(state: ICommunicationState) => state.activeCampaign
)

export const selectCampaignIdToDelete = createSelector(
	selectState,
	(state:ICommunicationState) => state.campaignIdToDelete
)

export const selectReportResults = createSelector(
	selectState,
	(state: ICommunicationState) => state.reportResults
)

export const selectGroups = createSelector(
	selectState,
	(state: ICommunicationState) => state.groups
)
export const selectGroup = createSelector(
	selectState,
	(state: ICommunicationState) => state.group
)
export const selectGroupToDelete = createSelector(
	selectState,
	(state:ICommunicationState) => state.groupToDelete
)
export const selectCampaignReducedGroups = createSelector(
	selectState,
	(state: ICommunicationState) => state.reducedGroups
)
export const selectCampaignSearchTerm = createSelector(
	selectState,
	(state: ICommunicationState) => state.campaignSearchTerm
)

export const selectCampaignDateFilter = createSelector(
	selectState,
	(state: ICommunicationState) => state.selectedDateFilter
);