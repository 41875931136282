import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataService } from 'acc-services';
import * as TextLibActions from '../actions/textlib.actions';
import { switchMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ITextLibState } from '../state/textlib.state';
import { of } from 'rxjs';
import * as StatusActions from '../actions/status.actions';
import { LibraryText } from 'acc-models';


@Injectable()
export class TextLibEffects {

	@Effect()
	getCategories$ = this.actions$.pipe(
		ofType(TextLibActions.TextLibActionTypes.GET_CATEGORIES),
		map((a: TextLibActions.GetCategories) => a),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		switchMap(([action, orgId]) => {
			return this.dataService.getTextLibCategories(orgId)
				.pipe(
					map((resp:any) => new TextLibActions.GetCategoriesSuccess(resp)),
					catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
				)
			}
		)
	)
	@Effect()
	search$ = this.actions$.pipe(
		ofType(TextLibActions.TextLibActionTypes.SEARCH),
		map((a:TextLibActions.Search) => a),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		switchMap(([action, orgId]) => {
			return this.dataService.searchTextLib(orgId, action.criteria.textLibCategoryId, action.criteria.searchString)
				.pipe(
					map((resp:any) => new TextLibActions.SearchSuccess(resp)),
					catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
				)
			}
		)
	)
	@Effect()
	saveItem$ = this.actions$.pipe(
		ofType(TextLibActions.TextLibActionTypes.EDIT_ITEM_CONFIRM),
		map((a: TextLibActions.SearchSuccess) => a),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		withLatestFrom(this.store$.select('adminTextLib', 'itemToEdit')),
		switchMap(([[action, orgId], itemToEdit]) => {
			return this.dataService.saveTextLibItem(orgId, itemToEdit)
				.pipe(
					map((resp:any) => new TextLibActions.EditItemSuccess(new LibraryText(resp))),
					catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
				)
			}
		)
	)

	@Effect()
	deleteItem$ = this.actions$.pipe(
		ofType(TextLibActions.TextLibActionTypes.DELETE_ITEM_CONFIRM),
		withLatestFrom(this.store$.select('adminTextLib', 'itemToDelete')),
		switchMap(([action, itemToDelete]) => this.dataService.deleteTextLibItem(itemToDelete.textLibID)
			.pipe(
				map((resp:any) => new TextLibActions.DeleteItemSuccess()),
				catchError(((resp:any) => { 
					this.store$.dispatch(new TextLibActions.DeleteItemCancel()); //hide the form
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	


	constructor(private actions$:Actions, private store$:Store<ITextLibState>, private dataService:DataService) {}
}

/*filter(([action:MediaActions.EditMediaConfirm, state:IMediaState]) => {
			return new Array<[number, File, File]>([state.select( s => s.OrgId), state.select(s => s.AddMediaFile), state.select(s => s.AddMediaThumbnail)]);
		})
		*/