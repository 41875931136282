import { Component, OnInit, OnDestroy } from '@angular/core';
import { IAppState } from '../../../store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as TextLibActions from '../../../store/actions/textlib.actions';
import * as TextLibSelectors from '../../../store/selectors/textlib.selectors';
import { LibraryText, LibraryTextCategory } from 'acc-models';
import { map, tap, takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'edit-textlib',
  templateUrl: './edit-textlib.component.html',
  styleUrls: ['./edit-textlib.component.scss']
})
export class EditTextlibComponent implements OnInit, OnDestroy {

	private destroy$:Subject<number> = new Subject();

	itemToEdit$ = this.store$.pipe(
		select(TextLibSelectors.selectItemToEdit),
		map(i => this.itemToEdit = new LibraryText(i))
	).subscribe();
	public textLibCategories$ = this.store$.pipe(
		select(TextLibSelectors.selectCategories),
		tap(r => this.textLibCategories = r),
		takeUntil(this.destroy$)
	).subscribe();

	public itemToEdit: LibraryText;
	public textLibCategories: Array<LibraryTextCategory>;
	public errors: Array<string> = new Array<string>();

	constructor(private store$:Store<IAppState>, private dialogRef: MatDialogRef<EditTextlibComponent>) { }

	ngOnInit() {
		this.store$.dispatch(new TextLibActions.GetCategories());
	}

	ngOnDestroy():void {
		this.destroy$.next(0);
		this.destroy$.complete();
	}
	close() {
		this.store$.dispatch(new TextLibActions.EditItemCancel());
		this.dialogRef.close();
	}
	cancel() {
		this.close();
	}
	save() {
		if(this.isValid()) {
			this.store$.dispatch(new TextLibActions.EditItemConfirm(this.itemToEdit));
			this.dialogRef.close();
		}
	}
	isValid(): boolean {
		let valid: boolean = true;
		this.errors = new Array<string>();

		if(!this.itemToEdit.textLibCategoryID)  {
			valid = false;
			this.errors.push('textLibCategoryID');
		}
		if(!this.itemToEdit.textLibTitle){
			valid = false;
			this.errors.push('textLibTitle')
		}
		if(!this.itemToEdit.textLibDesc){
			valid = false;
			this.errors.push('textLibDesc')
		}
		return valid;
	}

	compareValues(a:any,b:any) {
		return String(a).trim() === String(b).trim();
	}
}
