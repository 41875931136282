
<div class="sent-items" (keyup.downarrow)="moveSelection(1)" (keyup.uparrow)="moveSelection(-1)">
	<div class="outbox">
		<div class="select-dates">
			<mat-form-field>
				<mat-label>Start Date</mat-label>
				<input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate">
				<mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			  </mat-form-field>
			  <mat-form-field>
				<mat-label>End Date</mat-label>
				<input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate">
				<mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			  </mat-form-field>
			  <button mat-raised-button color="primary" (click)="getSentItems();">Apply</button>
		</div>
		<div class="item header">
			<div class="attachments"></div>
			<div class="to-email" (click)="sort('toEmail')"><mat-icon class="sorting" *ngIf="sortBy == 'toEmail'" [ngClass]="{desc: !sortAsc}">arrow_right_alt</mat-icon>To</div>
			<div class="subject" (click)="sort('emailSubject')"><mat-icon class="sorting" *ngIf="sortBy == 'emailSubject'" [ngClass]="{desc: !sortAsc}">arrow_right_alt</mat-icon>Subject</div>
			<div class="sent-date" (click)="sort('sentDateTime')"><mat-icon class="sorting" *ngIf="sortBy == 'sentDateTime'" [ngClass]="{desc: !sortAsc}">arrow_right_alt</mat-icon><span>Sent</span></div>
		</div>
		<div class="item filter">
			<div class="attachents">&nbsp;</div>
			<div class="to-email"><mat-form-field appearance="outline"><input matInput [(ngModel)]="emailFilter" (keyup)="filter()" placeholder="Filter"></mat-form-field></div>
			<div class="subject"><mat-form-field appearance="outline"><input matInput [(ngModel)]="subjectFilter" (keyup)="filter()" placeholder="Filter"></mat-form-field></div>
			<div class="sent-date">&nbsp;</div>
		</div>
		<div class="item" *ngFor="let msg of outbox; let i = index" (click)="selectMessage(msg, i)" [ngClass]="{active: selectedMessage && selectedMessage.cartDeliveryID == msg.cartDeliveryID}">
			<div class="attachments"><mat-icon *ngIf="msg.attachments.length > 0">attachment</mat-icon></div>
			<div class="to-email">{{msg.toEmail}}</div>
			<div class="subject">{{msg.emailSubject}}</div>
			<div class="sent-date">{{msg.sentDateTime | date:'MMM d, yyyy h:mm aa'}}</div>
		</div>
	</div>
	<div class="preview">
		<div class="message-body" *ngIf="selectedMessage" [innerHTML]="selectedMessage.emailMsg"></div>
		<div class="activity">
			<ul *ngIf="activity">
				<li *ngFor="let item of activity" [innerHTML]="item.shortDesc"></li>
			</ul>
		</div>
	</div>
	<div class="attachments-bar" *ngIf="selectedMessage?.attachments?.length > 0">
		<ul>
			<li *ngFor="let att of selectedMessage.attachments">
				<a href="{{att.url}}" target="_blank" *ngIf="att.url"><mat-icon class="small">attachment</mat-icon>{{att.title}}</a>
				<span *ngIf="!att.url" class="no-link"><mat-icon class="small">attachment</mat-icon>{{att.title}}</span>
			</li>
		</ul>
	</div>
</div>