import { LibraryItem } from './libraryItem';

export class Media {
	public mediaID: string;
	public mediaItemID : string;
	public mediaTypeID: number;
	public mediaCategoryID: number;
	public mediaCategoryDesc: string
	public mediaDesc:string;
	public mediaSourceID:number;
	public mediaFileExt:string;
	public originalFileName:string;
	public savedFileName: string;
	public imageFile:string;
	public AllowAddCart:boolean;
	public AllowCopy:boolean;
	public AllowDelete:boolean;
	public AllowMove:boolean;
	public AllowShare:boolean;
	public searchWords: string;
	public revisionNeededDate: Date;
	public readOnly: boolean;
	public type: string;

	public get link():string { return this.savedFileName; }

	constructor(o?:any) {
		Object.assign(this, o);
	}

	get status() {
		if(!this.revisionNeededDate) { return ''; }
		if(new Date(this.revisionNeededDate) < new Date()) { return 'past'; }
		let today = new Date();
		let weekOut = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
		if(new Date(this.revisionNeededDate) < weekOut) { return 'soon'; }
		return 'future';
	}
	
	public static fromLibraryItem(o: LibraryItem): Media {
		const values = {
			mediaItemID: o.id,
			mediaDesc: o.description
		}
		return new Media({...o, ...values});
	}
}

