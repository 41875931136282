import { HealthProfile } from "./healthProfile";
import { ClientInformation, ProductInformation } from "./quote";
import * as moment from "moment";
import { DrivingViolationDate } from "./drivingViolation";

export class HealthProfileRequest {
	public healthProfile: HealthProfile;
	public clientInformation: ClientInformation;
	public productInformation: ProductInformation;

	constructor(o?: any) {
		Object.assign(this, o);
	}

	public static fromHealthDetailsForm(healthProfile: HealthProfile, clientInformation: ClientInformation, productInformation: ProductInformation, setDefaults: boolean = false): HealthProfileRequest {
		let hpr = new HealthProfileRequest({
			cholesterolTotalValue: healthProfile.healthConditions.cholesterol.cholesterolTotalValue || 0,
			cholesterolHDLValue: healthProfile.healthConditions.cholesterol.cholesterolHDLValue || 0,
			cholesterolHDLRatio: healthProfile.healthConditions.cholesterol.cholesterolHDLRatio || 0,
			cholesterolMedicationCurrentlyUse: healthProfile.healthConditions.cholesterol.cholesterolMedicationCurrentlyUse,
			cholesterolMedicationUsedInPast: healthProfile.healthConditions.cholesterol.cholesterolMedicationUsedInPast,
			bloodPressureSystolicCurrent: healthProfile.healthConditions.bloodPressure.bloodPressureSystolic || 0,
			bloodPressureDiastolicCurrent: healthProfile.healthConditions.bloodPressure.bloodPressureDiastolic || 0,
			bloodPressureMedicationCurrentlyUse: healthProfile.healthConditions.bloodPressure.bloodPressureMedicationCurrentlyUse,
			bloodPressureMedicationUsedInPast: healthProfile.healthConditions.bloodPressure.bloodPressureMedicationUsedInPast,
			fatherHistory: healthProfile.familyHistory.father.fatherHistory || false,
			fatherCancer: healthProfile.familyHistory.father.fatherCancer,
			fatherCancerDiagnosedAge: healthProfile.familyHistory.father.fatherCancerDiagnosedAge || 0,
			fatherCancerDeath: healthProfile.familyHistory.father.fatherCancerDeath,
			fatherCancerDeathAge: healthProfile.familyHistory.father.fatherCancerDeathAge || 0,
			fatherCancerProstateCancer: healthProfile.familyHistory.father.fatherCancerProstateCancer,
			fatherCancerTesticularCancer: healthProfile.familyHistory.father.fatherCancerTesticularCancer,
			fatherCancerColonCancer: healthProfile.familyHistory.father.fatherCancerColonCancer,
			fatherCancerMelanomaCancer: healthProfile.familyHistory.father.fatherCancerMelanomaCancer,
			fatherCancerPancreaticCancer: healthProfile.familyHistory.father.fatherCancerPancreaticCancer,
			fatherCancerStomachCancer: healthProfile.familyHistory.father.fatherCancerStomachCancer,
			fatherCancerOtherCancer: healthProfile.familyHistory.father.fatherCancerOtherCancer,
			fatherCardiovascular: healthProfile.familyHistory.father.fatherCardiovascular,
			fatherCardiovascularDiagnosedAge: healthProfile.familyHistory.father.fatherCardiovascularDiagnosedAge || 0,
			fatherCardiovascularDeath: healthProfile.familyHistory.father.fatherCardiovascularDeath,
			fatherCardiovascularDeathAge: healthProfile.familyHistory.father.fatherCardiovascularDeathAge || 0,
			fatherDiabetes: healthProfile.familyHistory.father.fatherDiabetes,
			fatherDiabetesDiagnosedAge: healthProfile.familyHistory.father.fatherDiabetesDiagnosedAge || 0,
			fatherDiabetesDeath: healthProfile.familyHistory.father.fatherDiabetesDeath,
			fatherDiabetesDeathAge: healthProfile.familyHistory.father.fatherDiabetesDeathAge || 0,
			fatherStroke: healthProfile.familyHistory.father.fatherStroke,
			fatherStrokeDiagnosedAge: healthProfile.familyHistory.father.fatherStrokeDiagnosedAge || 0,
			fatherStrokeDeath: healthProfile.familyHistory.father.fatherStrokeDeath,
			fatherStrokeDeathAge: healthProfile.familyHistory.father.fatherStrokeDeathAge || 0,
			motherHistory: healthProfile.familyHistory.mother.motherHistory || false,
			motherCancer: healthProfile.familyHistory.mother.motherCancer,
			motherCancerDiagnosedAge: healthProfile.familyHistory.mother.motherCancerDiagnosedAge || 0,
			motherCancerDeath: healthProfile.familyHistory.mother.motherCancerDeath,
			motherCancerDeathAge: healthProfile.familyHistory.mother.motherCancerDeathAge || 0,
			motherCancerBreastCancer: healthProfile.familyHistory.mother.motherCancerBreastCancer,
			motherCancerCervicalCancer: healthProfile.familyHistory.mother.motherCancerCervicalCancer,
			motherCancerEndometrialCancer: healthProfile.familyHistory.mother.motherCancerEndometrialCancer,
			motherCancerFallopianTubeCancer: healthProfile.familyHistory.mother.motherCancerFallopianTubeCancer,
			motherCancerOvarianTubeCancer: healthProfile.familyHistory.mother.motherCancerOvarianTubeCancer,
			motherCancerVaginalCancer: healthProfile.familyHistory.mother.motherCancerVaginalCancer,
			motherCancerVulvarCancer: healthProfile.familyHistory.mother.motherCancerVulvarCancer,
			motherCancerColonCancer: healthProfile.familyHistory.mother.motherCancerColonCancer,
			motherCancerMelanomaCancer: healthProfile.familyHistory.mother.motherCancerMelanomaCancer,
			motherCancerPancreaticCancer: healthProfile.familyHistory.mother.motherCancerPancreaticCancer,
			motherCancerStomachCancer: healthProfile.familyHistory.mother.motherCancerStomachCancer,
			motherCancerOtherCancer: healthProfile.familyHistory.mother.motherCancerOtherCancer,
			motherCardiovascular: healthProfile.familyHistory.mother.motherCardiovascular,
			motherCardiovascularDiagnosedAge: healthProfile.familyHistory.mother.motherCardiovascularDiagnosedAge || 0,
			motherCardiovascularDeath: healthProfile.familyHistory.mother.motherCardiovascularDeath,
			motherCardiovascularDeathAge: healthProfile.familyHistory.mother.motherCardiovascularDeathAge || 0,
			motherDiabetes: healthProfile.familyHistory.mother.motherDiabetes,
			motherDiabetesDiagnosedAge: healthProfile.familyHistory.mother.motherDiabetesDiagnosedAge || 0,
			motherDiabetesDeath: healthProfile.familyHistory.mother.motherDiabetesDeath,
			motherDiabetesDeathAge: healthProfile.familyHistory.mother.motherDiabetesDeathAge || 0,
			motherStroke: healthProfile.familyHistory.mother.motherStroke,
			motherStrokeDiagnosedAge: healthProfile.familyHistory.mother.motherStrokeDiagnosedAge || 0,
			motherStrokeDeath: healthProfile.familyHistory.mother.motherStrokeDeath,
			motherStrokeDeathAge: healthProfile.familyHistory.mother.motherStrokeDeathAge || 0,
			brotherHistory: healthProfile.familyHistory.brother.brotherHistory || false,
			brotherCancer: healthProfile.familyHistory.brother.brotherCancer,
			brotherCancerDiagnosedAge: healthProfile.familyHistory.brother.brotherCancerDiagnosedAge || 0,
			brotherCancerDeath: healthProfile.familyHistory.brother.brotherCancerDeath,
			brotherCancerDeathAge: healthProfile.familyHistory.brother.brotherCancerDeathAge || 0,
			brotherCancerProstateCancer: healthProfile.familyHistory.brother.brotherCancerProstateCancer,
			brotherCancerTesticularCancer: healthProfile.familyHistory.brother.brotherCancerTesticularCancer,
			brotherCancerColonCancer: healthProfile.familyHistory.brother.brotherCancerColonCancer,
			brotherCancerMelanomaCancer: healthProfile.familyHistory.brother.brotherCancerMelanomaCancer,
			brotherCancerPancreaticCancer: healthProfile.familyHistory.brother.brotherCancerPancreaticCancer,
			brotherCancerStomachCancer: healthProfile.familyHistory.brother.brotherCancerStomachCancer,
			brotherCancerOtherCancer: healthProfile.familyHistory.brother.brotherCancerOtherCancer,
			brotherCardiovascular: healthProfile.familyHistory.brother.brotherCardiovascular,
			brotherCardiovascularDiagnosedAge: healthProfile.familyHistory.brother.brotherCardiovascularDiagnosedAge || 0,
			brotherCardiovascularDeath: healthProfile.familyHistory.brother.brotherCardiovascularDeath,
			brotherCardiovascularDeathAge: healthProfile.familyHistory.brother.brotherCardiovascularDeathAge || 0,
			brotherDiabetes: healthProfile.familyHistory.brother.brotherDiabetes,
			brotherDiabetesDiagnosedAge: healthProfile.familyHistory.brother.brotherDiabetesDiagnosedAge || 0,
			brotherDiabetesDeath: healthProfile.familyHistory.brother.brotherDiabetesDeath,
			brotherDiabetesDeathAge: healthProfile.familyHistory.brother.brotherDiabetesDeathAge || 0,
			brotherStroke: healthProfile.familyHistory.brother.brotherStroke,
			brotherStrokeDiagnosedAge: healthProfile.familyHistory.brother.brotherStrokeDiagnosedAge || 0,
			brotherStrokeDeath: healthProfile.familyHistory.brother.brotherStrokeDeath,
			brotherStrokeDeathAge: healthProfile.familyHistory.brother.brotherStrokeDeathAge || 0,
			sisterHistory: healthProfile.familyHistory.sister.sisterHistory || false,
			sisterCancer: healthProfile.familyHistory.sister.sisterCancer,
			sisterCancerDiagnosedAge: healthProfile.familyHistory.sister.sisterCancerDiagnosedAge || 0,
			sisterCancerDeath: healthProfile.familyHistory.sister.sisterCancerDeath,
			sisterCancerDeathAge: healthProfile.familyHistory.sister.sisterCancerDeathAge || 0,
			sisterCancerBreastCancer: healthProfile.familyHistory.sister.sisterCancerBreastCancer,
			sisterCancerCervicalCancer: healthProfile.familyHistory.sister.sisterCancerCervicalCancer,
			sisterCancerEndometrialCancer: healthProfile.familyHistory.sister.sisterCancerEndometrialCancer,
			sisterCancerFallopianTubeCancer: healthProfile.familyHistory.sister.sisterCancerFallopianTubeCancer,
			sisterCancerOvarianTubeCancer: healthProfile.familyHistory.sister.sisterCancerOvarianTubeCancer,
			sisterCancerVaginalCancer: healthProfile.familyHistory.sister.sisterCancerVaginalCancer,
			sisterCancerVulvarCancer: healthProfile.familyHistory.sister.sisterCancerVulvarCancer,
			sisterCancerColonCancer: healthProfile.familyHistory.sister.sisterCancerColonCancer,
			sisterCancerMelanomaCancer: healthProfile.familyHistory.sister.sisterCancerMelanomaCancer,
			sisterCancerPancreaticCancer: healthProfile.familyHistory.sister.sisterCancerPancreaticCancer,
			sisterCancerStomachCancer: healthProfile.familyHistory.sister.sisterCancerStomachCancer,
			sisterCancerOtherCancer: healthProfile.familyHistory.sister.sisterCancerOtherCancer,
			sisterCardiovascular: healthProfile.familyHistory.sister.sisterCardiovascular,
			sisterCardiovascularDiagnosedAge: healthProfile.familyHistory.sister.sisterCardiovascularDiagnosedAge || 0,
			sisterCardiovascularDeath: healthProfile.familyHistory.sister.sisterCardiovascularDeath,
			sisterCardiovascularDeathAge: healthProfile.familyHistory.sister.sisterCardiovascularDeathAge || 0,
			sisterDiabetes: healthProfile.familyHistory.sister.sisterDiabetes,
			sisterDiabetesDiagnosedAge: healthProfile.familyHistory.sister.sisterDiabetesDiagnosedAge || 0,
			sisterDiabetesDeath: healthProfile.familyHistory.sister.sisterDiabetesDeath,
			sisterDiabetesDeathAge: healthProfile.familyHistory.sister.sisterDiabetesDeathAge || 0,
			sisterStroke: healthProfile.familyHistory.sister.sisterStroke,
			sisterStrokeDiagnosedAge: healthProfile.familyHistory.sister.sisterStrokeDiagnosedAge || 0,
			sisterStrokeDeath: healthProfile.familyHistory.sister.sisterStrokeDeath,
			sisterStrokeDeathAge: healthProfile.familyHistory.sister.sisterStrokeDeathAge || 0,
			drivingConvictionExists: healthProfile.drivingViolation.duiDwi.drivingConvictionExists || false,
			drivingLicenseCurrentlyRevoked: healthProfile.drivingViolation.licenseSuspension.drivingLicenseCurrentlyRevoked || false,
			drivingMovingViolationDates: this.mapDatesToDrivingViolationDate(healthProfile.drivingViolation.movingViolation.drivingMovingViolationDates),
			drivingRecklessDrivingDates: healthProfile.drivingViolation.recklessDriving.drivingRecklessDrivingDates.map((date) => <DrivingViolationDate>{ month: +moment(date).format("M"), year: +moment(date).format("YYYY") }),
			drivingDrunkDrivingDates: this.mapDatesToDrivingViolationDate(healthProfile.drivingViolation.duiDwi.drivingDrunkDrivingDates),
			drivingLicenseSuspensionDates: this.mapDatesToDrivingViolationDate(healthProfile.drivingViolation.licenseSuspension.drivingLicenseSuspensionDates),
			drivingMotorVehicleAccidentDates: this.mapDatesToDrivingViolationDate(healthProfile.drivingViolation.motorVehicleAccident.drivingMotorVehicleAccidentDates),
			dobDay: +moment(clientInformation.birthDate).format("D"),
			dobMonth: +moment(clientInformation.birthDate).format("M"),
			dobYear: +moment(clientInformation.birthDate).format("YYYY"),
			everUseTobacco: healthProfile.healthConditions.tobaccoUse.everUseTobacco,
			faceAmounts: productInformation.faceAmounts.map((amount) => +amount),
			gender: clientInformation.gender,
			height: healthProfile.healthConditions.physicalBuild.height,
			howMany: healthProfile.healthConditions.tobaccoUse.howMany ? healthProfile.healthConditions.tobaccoUse.howMany : 0,
			kindOfTobacco: healthProfile.healthConditions.tobaccoUse.kindOfTobacco,
			lastuserTobacco: healthProfile.healthConditions.tobaccoUse.lastuserTobacco,
			xraeProductType: productInformation.quoteType,
			terms: this.replaceYRTTerms(productInformation.terms),
			weight: healthProfile.healthConditions.physicalBuild.weight ? healthProfile.healthConditions.physicalBuild.weight.toString() : "",
		});
		if (setDefaults) {
			let props = Object.keys(hpr);
			for (let prop of props) {
				hpr[prop] = hpr[prop] == null ? false : hpr[prop];
			}
		}
		return hpr;
	}

	private static replaceYRTTerms(terms) {
		var intTerms = [];

		terms.forEach((t) => {
			intTerms.push(t === "YRT" ? 1 : +t);
		});

		return intTerms;
	}

	private static mapDatesToDrivingViolationDate(dates: Array<string>): Array<DrivingViolationDate> {
		const mappedDates: Array<DrivingViolationDate> = [];
		dates.forEach((date) => {
			mappedDates.push(<DrivingViolationDate>{ month: +moment(date).format("M"), year: +moment(date).format("YYYY") });
		});
		return mappedDates;
	}
}
