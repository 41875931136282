import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SessionService, DataService, MixpanelService } from "acc-services";
import { User, VideoProgram, CustVideoRequest, CustVideoRequestParm, MediaCategory, Folder } from "acc-models";
import { PreviewVideoComponent } from "acc-common";
import { SubscriptionLike} from "rxjs";
import { Store } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import { SetErrorMessage } from "../../store/actions/status.actions";
import { tooltips } from "acc-common";
import { tap, flatMap } from 'rxjs/operators';
import { environment } from "projects/acc-main/src/environments/environment";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";

@Component({
	selector: "acc-main-create-video",
	templateUrl: "./create-video.component.html",
	styleUrls: ["./create-video.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CreateVideoComponent implements OnInit {

	@ViewChild('video') video: ElementRef;
	public user: User;
	private getVideoProgramsSubscription: SubscriptionLike;
	private getVideoProgramDetailSubscription: SubscriptionLike;
	selectedVideoId;
	selectedVideoProgram: VideoProgram;
	public videoPrograms: Array<VideoProgram>;
	private videoUrl;
	private process = true;
	private thumbnailURL;
	videoRequest: CustVideoRequest;
	public tooltips = tooltips;
	private personalizedVideosFolder = "Personalized Videos";


	constructor(private dataService: DataService, private snackBar: MatSnackBar, private sessionService: SessionService, 
		private dialog: MatDialog, private store: Store<IAppState>, 
		private router: Router, private mixpanelService: MixpanelService) {
		this.user = this.sessionService.getUser();
		this.videoRequest = new CustVideoRequest();
	}

	ngOnInit() {
		this.getVideoPrograms();
	}

	getVideoPrograms() {
		this.getVideoProgramsSubscription = this.dataService.getVideoPrograms(this.user.orgID).subscribe(
			(videos: any) => {
				this.videoPrograms = videos;
			},
			(err) => {
				this.showError("Error getting video programs");
			}
		);
	}
	videoTypeChange(videoId) {
		this.getVideoProgramDetailSubscription = this.dataService.getVideoDetail(videoId).subscribe(
			(video: any) => {
				this.selectedVideoProgram = video;
				this.process = true;
				this.video.nativeElement.load();
			},
			(err) => {
				this.showError("Error getting video detail");
			}
		);
	}

	validateFieldsBeforePreview() {
		let isAllOk = true;
		this.selectedVideoProgram.CustVideoParameters.forEach((p) => {
			if (!p.parmValue) {
				isAllOk = false;
			}
		});
		return isAllOk;
	}

	showPreview(): void {

		//Mixpanel Event
		this.mixpanelService.trackNewPersonalizedVideo();

		var custVideoRequestParams = this.buildCustRequestParameters();

		if (!this.validateFieldsBeforePreview()) {
			this.showError("All fields are required");
			return;
		}
		const dialogRef = this.dialog.open(PreviewVideoComponent, {
			width: "800px",
			height: "625px",
			data: { programID: this.selectedVideoProgram.programID, programName: this.selectedVideoProgram.programName, custVideoRequestParams: custVideoRequestParams, estBuildSeconds: this.selectedVideoProgram.estBuildSeconds, userID: this.user.userID, orgID: this.user.orgID, process: this.process, videoURL: this.videoUrl, thumbnailURL: this.thumbnailURL, isAdmin: this.user.admin },
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result.videoUrl != null) {
				this.videoUrl = result.videoUrl;
				this.process = false;
				this.thumbnailURL = result.thumbnailURL;

				if(!!result.saveToAssetLibrary){
					//this.saveToAssetLibrary(result.videoUrl, result.thumbnailURL);
					this.saveToMyAssetLibrary(result.videoUrl, result.thumbnailURL);
				}

			}
		});
	}

	saveToAssetLibrary(url, thumb){
		//Check Library
		this.dataService.getSharedLibCategories(this.user.orgID).subscribe( (resp:any) => {
			var folder = resp.find(m => m.mediaCategoryDesc == this.personalizedVideosFolder);
			if(folder == null){
				var mediaCategory = new Folder(0,null, this.personalizedVideosFolder,[]);
				this.dataService.insertMediaCategoryAdmin(this.user.orgID, mediaCategory).subscribe((resp:any) => {
					this.AddVideoToAssetLibrary(resp.mediaCategoryID, url, thumb)
				});
			}else{
				this.AddVideoToAssetLibrary(folder.mediaCategoryID, url, thumb)
			}
		});
	}

	saveToMyAssetLibrary(url, thumb){
		//Check Library
		this.dataService.getUserLibCategories(this.user.userID).subscribe( (resp:any) => {
			var folder = resp.find(m => m.mediaCategoryDesc == this.personalizedVideosFolder);
			if(folder == null){
				this.dataService.saveFolder(this.user.userID, 0, this.personalizedVideosFolder).subscribe((resp:any) => {
					this.AddVideoToMyAssetLibrary(resp.mediaCategoryID, url, thumb)
				});
			}else{
				this.AddVideoToMyAssetLibrary(folder.mediaCategoryID, url, thumb)
			}
		});
	}

	AddVideoToMyAssetLibrary(mediaCategoryID, url, thumb){
		this.dataService.savePersonalizedVideoToMyLibrary(this.user.userID, mediaCategoryID, this.selectedVideoProgram.programName, url, thumb).subscribe((resp:any) => {
			//Mixpanel Track
			this.mixpanelService.trackAssetFileAdded();

			this.snackBar.open(`Video has been saved to your Asset Library in your Personalized Videos folder.`, 'OK', { duration: 3000 });
		})
	}

	AddVideoToAssetLibrary(mediaCategoryID, url, thumb){
		this.dataService.savePersonalizedVideo(this.user.orgID, mediaCategoryID, this.selectedVideoProgram.programName, url, thumb).subscribe((resp:any) => {
			//Mixpanel Track
			this.mixpanelService.trackAssetFileAdded();

			this.snackBar.open(`Video has been saved to the Shared Asset Library in your Personalized Videos folder.`, 'OK', { duration: 3000 });
		})
	}

	buildCustRequestParameters() {
		var custVideoRequestParams = new Array<CustVideoRequestParm>();
		this.selectedVideoProgram.CustVideoParameters.forEach((p) => {
			let c = new CustVideoRequestParm();
			c.parmName = p.parmName;
			c.parmValue = p.parmValue;
			custVideoRequestParams.push(c);
		});
		return custVideoRequestParams;
	}

	showError(err) {
		this.store.dispatch(new SetErrorMessage(err));
	}

	/*
  showModalEmailForm(): void {
    if (!this.validateFieldsBeforePreview()) {
      this.showError("All fields are required");
      return;
    } 

    const custVideoRequestParams =  this.buildCustRequestParameters();
    const dialogRef = this.dialog.open(EmailFormComponent, {
      width: '800px',
      height: '530px',
      data: {programID: this.selectedVideoProgram.programID, custVideoRequestParams: custVideoRequestParams, userID: this.user.userID, orgID: this.user.orgID }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  */
	send() {

		if (!this.validateFieldsBeforePreview()) {
			this.showError("All fields are required");
			return;
		} 

		//Mixpanel Event
		this.mixpanelService.trackNewPersonalizedVideo();

		const user = this.sessionService.getUser();
		let mediaItem = {
			mediaTypeID: 3,
			mediaAsyncTypeID: 1,
			mediaDesc: this.selectedVideoProgram.programName,
			imageFile: "Video-play-button.png",
			MediaAsyncAttributes: [
				{ attributeName: 'programID', attributeValue: this.selectedVideoProgram.programID, mediaAsyncAttributeTypeID: 1 },
				{ attributeName: 'custVideoRequestParams', attributeValue: this.buildCustRequestParameters(), mediaAsyncAttributeTypeID: 4 },
				{ attributeName: 'userID', attributeValue: user.userID, mediaAsyncAttributeTypeID: 1 },
				{ attributeName: 'orgID', attributeValue: user.orgID, mediaAsyncAttributeTypeID: 1 },
				{ attributeName: 'process', attributeValue: true, mediaAsyncAttributeTypeID: 1  },
				{ attributeName: 'estBuildSeconds', attributeValue: this.selectedVideoProgram.estBuildSeconds, mediaAsyncAttributeTypeID: 2 }
			]
		}
		let cartId: string;
		this.dataService.startSession(user.userID).pipe( //start a new cart session
			tap((c:any) => cartId = c.cartSessionID), 
			flatMap((resp: any) => this.dataService.addToCartAsync(cartId, user.userID, mediaItem)), //add this to cart
		).subscribe(resp => {
			this.router.navigate(['/module', 'send']);
		});
	}

	onNotify($event) {
		this.process = true;
	}
}
