import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { CommCampaignListParm } from 'acc-models';
import { DataService } from 'acc-services';
import { SubscriptionLike } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ListParamComponent } from './list-param/list-param.component';

@Component({
  selector: 'app-list-params',
  templateUrl: './list-params.component.html',
  styleUrls: ['./list-params.component.scss']
})
export class ListParamsComponent implements OnInit {

  @Input() filters: Array<CommCampaignListParm>;
  @ViewChildren(ListParamComponent) filterDetails: QueryList<ListParamComponent>;
  public operators = [];
  public fields = [];
  public count = 0;
  private getFieldsSubscription:SubscriptionLike;
  public form: FormGroup;
  public fieldNameField: FormControl = new FormControl('', Validators.required);
  public operatorField: FormControl = new FormControl('', Validators.required);
  public valueField: FormControl = new FormControl('', Validators.required);

  constructor(
	private dataService: DataService,
	private fb: FormBuilder
  ) { }

  ngOnInit() {
  }

  addFilter(){
    this.filters.push(new CommCampaignListParm());
  }

  removeFilter(index){
    this.filters.splice(index, 1);
  }
  
  public validate(): boolean {
	if(this.filterDetails) {
	  return this.filterDetails.map(f => f.valid).reduce(((a, b) => a && b), true);
	} else {
		return true;
	}
 }
  public getFilters(): Array<CommCampaignListParm> {
	  if(this.filterDetails) {
		  return this.filterDetails.map(f => f.getFilter());
	  } else {
		  return [];
	  }
  }
}
