import { IAppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { IMediaState } from '../state/media.state';
import { IRevisionDatesState } from '../state/revisiondates.state';

const selectRevisionDateState = (state:IAppState) => state.revisionDates;

export const selectMedias = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.medias
)
export const selectPastMedias = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.pastMedias
)
export const selectSoonMedias = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.soonMedias
)
export const selectFutureMedias = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.futureMedias
)
export const selectNoDateMedias = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.noDateMedias
)
export const selectEditingMedia = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.editingMedia
)


export const selectTexts = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.texts
)
export const selectPastTexts = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.pastTexts
)
export const selectSoonTexts = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.soonTexts
)
export const selectFutureTexts = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.futureTexts
)
export const selectNoDateTexts = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.noDateTexts
)
export const selectEditingText = createSelector(
	selectRevisionDateState,
	(state: IRevisionDatesState) => state.editingText
)
