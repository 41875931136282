<main class="lists-container">
    <h1>Upload CSV</h1>
    <mat-card >
      <mat-card-content>
          <mat-horizontal-stepper linear="true"  (selectionChange)="onStepChange($event)" #stepper>
              <mat-step label="Upload CSV" editable="true" [completed]="firstStepCompleted">
                  
                  <div 
                      *ngIf="file == null"
                      class="uploadfilecontainer" 
                      (click)="fileInput.click()" 
					  fileDrop 
					  (onFileDropped)="uploadFile($event)">
                    <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
                    <div class="file-text">Drag and drop your file here, or select a file to upload.</div>
                  </div>
                 
                    <div  class="files-list" *ngIf="file != null">
                        <p class="file-name"> {{ file.name }} </p>
                        <button class="delete-file" (click)="deleteAttachment(i)">
                          <mat-icon>delete</mat-icon>
                        </button>
                     </div>

                <div>
                  <button mat-stroked-button color="primary" matStepperNext>Next: Custom Fields</button>
                </div>

              </mat-step>
              <mat-step editable="false" label="Assign Fields" [completed]="step2complete">
				<mat-error *ngIf="!checkForRequiredMappingFields()">
                    Check the required fields for mapping
                </mat-error>
                <mat-card class="csv-field-card" *ngFor="let f of uploadedFileInfo.CSVFields">
                    <div class="nameRow multifieldRow">
                      <mat-form-field>
                          <input matInput placeholder="CSV Column {{f.columnNumber + 1}}" value="{{f.columnName}}" readonly />
                      </mat-form-field>
                      <mat-form-field style="width: 300px;">
                            
                        <input matInput type="text" placeholder="Field Name" [(ngModel)]="commDataFileMap.Mappings[f.columnNumber].fieldName"  [matAutocomplete]="auto" (ngModelChange)="fieldNameChange(f.columnNumber, $event)">
                        <button mat-button *ngIf="commDataFileMap.Mappings[f.columnNumber].fieldName" matSuffix mat-icon-button aria-label="Clear" (click)="clearTargetFieldsFiltered(f.columnNumber)">
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let target of targetFieldsFiltered[f.columnNumber]" [value]="target.fieldName">
                            {{target.fieldName}} {{ (target.required)?'- required':''}} 
                          </mat-option>
                        </mat-autocomplete>

                      </mat-form-field>
                    </div>
                    <mat-list>
                      <mat-list-item *ngFor="let s of f.sampleValues"> {{s}} </mat-list-item>
                    </mat-list>
                </mat-card>

                <div>
                  <button mat-stroked-button matStepperPrevious color="primary" style="margin-right: 20px;">Back: Custom Fields</button>
                  <button mat-stroked-button color="primary" style="margin-left: 20px;" (click)="finishAssignFields()">Next: Add Contacts</button>
                </div>
              </mat-step>
              <mat-step label="Add Contacts"  editable="false" [completed]="step2complete">
                  <mat-card class="import-contacts" >
                    <div class="">
                        <mat-icon class="clock-icon">query_builder</mat-icon>
                        <div class="finish-title">We're processing your contacts</div>
                        <div class="finish-text">Depending on the number of contacts you uploaded, this may take several minutes. You can move on to your next tasks in the mean time</div>
                        <button mat-stroked-button color="primary" (click)="GoToLists()">Done</button>
                    </div>
                  </mat-card>
              </mat-step>
            </mat-horizontal-stepper>
      </mat-card-content>
    </mat-card>
</main>
