export class Organization {
	public orgID: number;
	public orgDesc: string;
	public logoFileName: string;
	public logoFileForVideoName: string;
	public resource: string;
	public whatsNew: string;
	public whatsNewTitle: string;
	public useExchangeEmail: boolean;
	public lifeQuoteModuleID: number;
	public requireSubscription: boolean;
	public subscriptionLevelID: number;
	public subscriptionEndDate: number;
	public organizationUrl: string;

	public masterOrgID: number;
	public isMasterOrganization: boolean;

	public organizationProfile: OrganizationProfile = new OrganizationProfile();
	
	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class OrganizationProfile {
	public orgID: number;
	public email: string;
	public phone: string;
	public website: string;
	public address1: string;
	public address2: string;
	public city: string;
	public state: string;
	public zip: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}
