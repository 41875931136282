<div class="list">
	<div class="form">
	<mat-form-field>      
		<input matInput type="text" placeholder="List Name" [formControl]="listNameField" >
	  </mat-form-field>
	  <mat-form-field>      
		<input matInput type="text" placeholder="List Description" [formControl]="listDescField" >
	  </mat-form-field>
	  <mat-form-field *ngIf="groups">
		<mat-label>Group (optional)</mat-label>
		<mat-select id="listGroupId" name="listGroupId" [formControl]="listGroupIdField">
			<mat-option></mat-option>
			<mat-option *ngFor="let group of groups" [value]="group.groupID">
				{{group.groupName}}
			</mat-option>
		</mat-select>
	</mat-form-field>
	</div>
	<mat-card >
      <mat-card-content>
        <mat-table [dataSource]="records" *ngIf="length > 0; else noRecords" class="mat-elevation-z8">
          <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef> Fist Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> Email Address </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.phone | phone}} </mat-cell>
          </ng-container>
        
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</mat-table>
		<ng-template #noRecords>
			<p>No records imported yet</p>
		</ng-template>
		<mat-paginator 
			*ngIf="length > 0"
          (page)="pageEvent = handlePaging($event)"
          [length]="length"
          [pageSize]="pageSize"
          showFirstLastButtons></mat-paginator>
	  </mat-card-content>
	<mat-card-actions class="page-actions">
		<section class="submit-section">
			<button mat-raised-button class="submit-button" color="primary" (click)="save()" >Save</button>
		</section>
	</mat-card-actions>
    </mat-card>
</div>
<app-dialog *ngIf="showDeleteList" class="small" [title]="'Confirm'" [message]="confirmDeleteItemMessage" [buttons]="confirmDeleteButtons" (closeDialog)="closeDeleteItem($event)"></app-dialog>