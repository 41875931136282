<div id="mkt-module">
  <!-- HEADER -->
    <div class="header">
      <div class="actions">
       
      </div>
    </div>
  <!-- / HEADER -->

	<div class="mkt-container">
		<router-outlet></router-outlet>
	</div>
</div>