	<div cdkDropListGroup #rowLayout>
		<div class="contentsFiles cart" *ngFor="let row of getItemsTable(rowLayout)" cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="row" (cdkDropListDropped)="drop($event)">
			<div class="contentFile" *ngFor="let file of row" cdkDrag >
				<div class="drag-placeholder" *cdkDragPlaceholder></div>
				<div class="fileName">
					<div>{{file.description}}</div>
				</div>
				<div class="file" [appFileActions]="showFileActions" [file]="file" (actionClick)="action($event)" [open]="openLibraryItemId == file.id">
					<a (click)="routeTo(['/module', file.moduleID, (file.id)])" *ngIf="file.mediaSourceID == 3 && file.AllowEdit && file.type == 'sync'" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
					<a (click)="showPreview(file.id)" *ngIf="file.mediaSourceID == 3 && !file.AllowEdit && file.type == 'sync'" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
					<a (click)="action({ actionName: 'newWindow', payload: { file: file } })" *ngIf="file.mediaSourceID != 3 && file.type == 'sync'" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
					<a (click)="generate(file)" *ngIf="file.type == 'async'" [ngStyle]="{'background-image': getBackgroundImageUrl(file.imageFile)}"></a>
					<div class="fileType" *ngIf="file.mediaFileExt">{{file.mediaFileExt.substring(1)}}</div>
				</div>
			</div>
		</div>
	</div>
	
	