<div id="textLibrarySearch">
	<div mat-dialog-header>
		<h2 mat-dialog-title>Library Search</h2>
	</div>
	<mat-dialog-content>
		<div class="dialogContent">
			<div class="form" *ngIf="textLibCategories" mat-dialog-content>
				<mat-form-field>
					<mat-label>Category</mat-label>
					<mat-select [(ngModel)]="categoryId" name="categoryId" id="categoryId">
							<mat-option value="-1"></mat-option>
							<mat-option *ngFor="let category of textLibCategories" value="{{category.textLibCategoryID}}">{{category.textLibCategoryDesc}}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field>
					<mat-label>Search Term</mat-label>
					<input matInput type="text" name="term" id="term" [(ngModel)]="term" />
				</mat-form-field>
				<button mat-stroked-button color="primary" (click)="search()">Search</button>
			</div>
			<div class="bottom">
				<div class="results" *ngIf="results" >
					<ul>
						<li *ngFor="let result of results" (click)="highlightResult(result)" [ngClass]="{active: activeResult?.textLibID == result.textLibID}">
							<h3>{{result.textLibTitle}}</h3>
							<p (dblclick)="expandText(result)">{{result.textLibDesc}}</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions>
		<button mat-raised-button color="primary" (click)="append()" [disabled]="!activeResult">Append</button>
		<button mat-raised-button color="primary" (click)="replace()" [disabled]="!activeResult">Replace</button>
		<div class="spacer">&nbsp;</div>
		<button mat-raised-button color="primary" (click)="close()" style="float: right;">Cancel</button>
	</mat-dialog-actions>
</div>
<acc-common-lightbox [content]="lightboxContent" [title]="lightboxTitle" *ngIf="showLightbox" (closeLightbox)="closeLightbox()"></acc-common-lightbox>