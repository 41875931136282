import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit } from "@angular/core";
import { User, Profile, ProfilePhoto } from "acc-models";
import { Store, select } from "@ngrx/store";
import { IAppState } from "../../../store/state/app.state";
import { ReturnToList, GetProfile, UpdateProfile, SaveProgress, UserActionTypes, SaveProgressSuccess, SavePhotoSuccess } from "../../../store/actions/users.actions";
import { selectProfile, selectOrgId, selectActiveUser, selectProfilePhoto } from "../../../store/selectors/users.selectors";
import { tap, takeUntil, map } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { DataService } from "acc-services";
import { SetErrorMessage, ClearErrorMessage } from "../../../store/actions/status.actions";
import { Actions } from "@ngrx/effects";
import { ProfileFormComponent } from "../../../shared/profile-form/profile-form.component";
import { Subject, forkJoin, of } from "rxjs";
import { tooltips } from "acc-common";
import { selectAddSeats } from "../../../store/selectors/users.selectors";

@Component({
	selector: "user-details",
	templateUrl: "./user-details.component.html",
	styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
	@Input() user: User;
	@ViewChild("profilePhotoField") private profilePhotoField: ElementRef;
	@ViewChild("profileForm") profileForm: ProfileFormComponent;
	private destroy$: Subject<number> = new Subject();
	public tooltips = tooltips;

	public addSeatsDialog$ = this.store.pipe(select(selectAddSeats));

	public profile$ = this.store.pipe(
		select(selectProfile),
		tap((p) => {
			this.profile = p;
			if (p) this.createForm(p);
		})
	);
	public profilePhoto$ = this.store.pipe(
		select(selectProfilePhoto),
		tap((p) => {
			if (p) {
				this.updatePhoto();
			}
		})
	);
	public activeUser$ = this.store
		.pipe(
			select(selectActiveUser),
			tap((u) => {
				this.user = u;
			}),
			takeUntil(this.destroy$)
		)
		.subscribe();

	public profile: Profile;
	private orgId: number;
	private isAdmin: boolean = false;
	private profilePhoto: string;
	private profilePhotoFilename: string;
	private saving: boolean = false;
	private formValid: boolean = true;
	private imageIndex: number = 0;
	private mask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
	public passwordField: FormControl = new FormControl("", [Validators.required]);

	constructor(private store: Store<IAppState>, private fb: FormBuilder, private dataService: DataService, private actions$: Actions) {
		actions$
			.pipe(
				map((p: any) => {
					switch (p.type) {
						case UserActionTypes.SAVE_PHOTO_SUCCESS:
							this.profile.photoFile = p.fileName;
							this.profile.photoFileOnly = p.fileNameOnly;
							this.profile.photoURLPath = p.fileName;
							break;
						case UserActionTypes.GET_PROFILE_SUCCESS:
							this.profile = p.profile;
					}
				})
			)
			.subscribe();

		this.passwordField.setValue(this.user.password);
	}

	ngOnInit(): void {
		this.store.dispatch(new ClearErrorMessage());
		if (this.user.userID) {
			this.store.dispatch(new GetProfile(this.user.userID));
			this.isAdmin = this.user.admin;
		}
		this.store
			.pipe(
				select(selectOrgId),
				tap((id) => {
					this.orgId = id;
				})
			)
			.subscribe();
		window.scrollTo(0, 0);
	}

	ngAfterViewInit(): void {
		//this.profileForm.profileForm.addControl('password', this.passwordField);
	}

	ngOnDestroy(): void {
		this.destroy$.next(0);
		this.destroy$.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.user) {
			this.user = changes.user.currentValue;
			if (this.user.userID) {
				this.store.dispatch(new GetProfile(this.user.userID));
			} else {
				this.profile = new Profile(this.user);
				this.createForm(this.profile);
			}
		}
	}

	returnToList() {
		this.store.dispatch(new ReturnToList());
	}

	createForm(prof: Profile) {
		this.profilePhoto = prof.photoFile;
		this.profilePhotoFilename = prof.photoFileOnly;
		this.profile.profileName = "Main";
	}

	saveProfile() {
		let profilePhoto = this.profileForm.getPhoto();
		let profileLogo = this.profileForm.getLogo();

		let observableBatch = [];
		observableBatch.push(of(true)); //just get something in there, in case there are no images to save
		if (!!profilePhoto) {
			const photoUpload = profilePhoto.getData();
			if (photoUpload.file) {
				//save logo image file
				observableBatch.push(
					this.dataService.uploadProfileImage(photoUpload.file, photoUpload.photo).pipe(
						tap((img: ProfilePhoto) => {
							this.profile.photoID = img.imageID;
							this.profile.photo = img;
						})
					)
				);
			} else {
				if (photoUpload.photo) {
					observableBatch.push(this.dataService.updateProfileImage(photoUpload.photo));
				} else {
					this.profile.photoID = null;
				}
			}
		}
		if (!!profileLogo) {
			const logoUpload = profileLogo.getData();
			if (logoUpload.file) {
				//save logo image file
				observableBatch.push(
					this.dataService.uploadProfileImage(logoUpload.file, logoUpload.photo).pipe(
						tap((img: ProfilePhoto) => {
							this.profile.logoID = img.imageID;
							this.profile.logo = img;
							this.profile.logoURLPath = img.imageModifiedURLPath;
						})
					)
				);
			} else {
				if (logoUpload.photo) {
					observableBatch.push(this.dataService.updateProfileImage(logoUpload.photo));
				} else {
					this.profile.logoID = null;
				}
			}
		}
		forkJoin(observableBatch).subscribe((resp: any) => {
			Object.keys(this.profileForm.profileForm.controls).forEach((key) => {
				this.profileForm.profileForm.get(key).markAsTouched();
			});
			if (this.profileForm.profileForm.valid) {
				//do validation
				this.formValid = true;
				this.updateProfileAndUser();
				this.store.dispatch(new UpdateProfile(this.user, new Profile(this.profile)));
			} else {
				this.formValid = false;
				
				//Scroll to Errors
				if(document.getElementsByClassName('mat-form-field ng-invalid')[0] != null){
					document.getElementsByClassName('mat-form-field ng-invalid')[0].scrollIntoView();
					if(document.getElementsByClassName('mat-form-field ng-invalid')[0].getBoundingClientRect().top < 130) {
						window.scrollBy(0, -125);//to make up for the fixed header
					}
				}
			
			}
		});
	}
	updateProfileAndUser() {
		const controls = this.profileForm.profileForm.controls;
		this.profile.title = controls["title"].value;
		this.profile.firstName = controls["firstName"].value;
		this.profile.lastName = controls["lastName"].value;
		this.profile.companyName = controls["companyName"].value;
		this.profile.address1 = controls["address1"].value;
		this.profile.address2 = controls["address2"].value;
		this.profile.city = controls["city"].value;
		this.profile.state = controls["state"].value;
		this.profile.zip = controls["zip"].value;
		this.profile.mainPhone = (controls["mainPhone"].value || "").replace(/\D/g, "");
		this.profile.cellPhone = (controls["cellPhone"].value || "").replace(/\D/g, "");
		this.profile.directWorkPhone = (controls["directPhone"].value || "").replace(/\D/g, "");
		this.profile.email = controls["email"].value;
		this.profile.website = controls["website"].value || "";
		this.profile.facebook = controls["facebook"].value || "";
		this.profile.twitter = controls["twitter"].value || "";
		this.profile.instagram = controls["instagram"].value || "";
		this.profile.youtube = controls["youtube"].value || "";
		this.profile.linkedIn = controls["linkedin"].value || "";
		this.profile.profileName = controls["profileName"].value || "";
		this.user.admin = this.isAdmin || false;
		this.profile.userID = this.user.userID;
		this.user.password = this.passwordField.value;

		this.user = { ...this.user, firstName: this.profile.firstName, lastName: this.profile.lastName, email: this.profile.email, orgID: this.orgId };
	}
	onPhotoSelected(event) {
		if (!this.user.userID) {
			if (this.profileForm.profileForm.valid) {
				//save progress
				this.updateProfileAndUser();
				this.store.dispatch(new SaveProgress(this.user, this.profile, event.target.files[0]));
				this.profilePhotoField.nativeElement.value = "";
			} else {
				this.store.dispatch(new SetErrorMessage("Please complete and save user form before uploading photo."));
				this.profilePhotoField.nativeElement.value = "";
				return;
			}
		} else {
			this.savePhoto(event.target.files[0]);
			this.profilePhotoField.nativeElement.value = "";
		}
	}

	savePhoto(file) {
		this.dataService.uploadProfileImage(file, new ProfilePhoto()).subscribe((resp: any) => {
			this.profile.photoID = resp.imageID;
			this.store.dispatch(new SavePhotoSuccess(resp.imageURLPath, resp.imageFile));
			//this.saveProfile();
		});
	}

	updatePhoto() {
		if (!this.profile) return;
		this.profilePhoto = this.profile.photoFile;
		this.profilePhotoFilename = this.profile.photoFileOnly;
		this.imageIndex++; //used for a new query param on image, forcing refresh
	}
}
