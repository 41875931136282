import { Component, OnInit, Input, Output } from '@angular/core';
import { CommCampaignListParm } from 'acc-models';
import { DataService } from 'acc-services';
import { SubscriptionLike, Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'acc-main-list-param',
  templateUrl: './list-param.component.html',
  styleUrls: ['./list-param.component.scss']
})

export class ListParamComponent implements OnInit {

  @Input() filter: CommCampaignListParm;
  @Output() onRemoveFilter: Subject<number> = new Subject();
  public operators = [];
  public fields = [];
  public count = 0;
  public form: FormGroup;
  public fieldNameField: FormControl = new FormControl('', Validators.required);
  public operatorField: FormControl = new FormControl('', Validators.required);
  public valueField: FormControl = new FormControl('', Validators.required);

  constructor(
	private dataService: DataService,
	private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getTargetFields();
	this.getOperators();
	this.fieldNameField.setValue(this.filter.fieldName);
	this.operatorField.setValue(this.filter.fieldOperator);
	this.valueField.setValue(this.filter.value);
	this.form = this.fb.group({
		fieldName: this.fieldNameField,
		operator: this.operatorField,
		value: this.valueField
	})
  }

  getTargetFields() {
    this.dataService.getTargetFields().subscribe((targetFields:any) => {
      this.fields = targetFields;
		})
  }

  removeFilter(){
    this.onRemoveFilter.next(0);
  }

  getOperators(){
    this.operators.push({
      name: "Equal",
      value: "="
    });
    this.operators.push({
      name: "Not Equal",
      value: "!="
    });
  }

  public getFilter(): CommCampaignListParm {
	  return new CommCampaignListParm({ fieldName: this.fieldNameField.value, fieldOperator: this.operatorField.value, value: this.valueField.value })
  }

  get valid(): boolean {
	  return this.form.valid;
  }

  
}
