import { Action } from '@ngrx/store';
import { QuoteRequest, HealthProfile, HealthProfileAlt } from 'acc-models';
import { QuoteRecSort } from 'projects/acc-models/src/lib/models/quoteRecFilter';
import { QuoteResponse } from 'projects/acc-models/src/lib/models/quoteResponse';
import { QuoteResult } from 'projects/acc-models/src/lib/models/quoteResult';

export enum QuoteActionTypes {
	NEW_QUOTE = '[QUOTE] New Quote',
	SET_REQUEST = '[QUOTE] Set Request',
	GET_REQUEST = '[QUOTE] Get Request',
	SET_HEALTH_PROFILE = '[HEALTHPROFILE] Set Health Profile',
	GET_HEALTH_PROFILE = '[HEALTHPROFILE] Get Health Profile',

	RESET = '[ROOT] Reset',

	ADD_QUOTE_TO_CACHE = '[CACHE] Add Quote to Cache',
	GET_QUOTE_FROM_CACHE = '[CACHE] Get Quote from Cache',

	SET_HEALTH_PROFILE_ALT = '[HEALTHPROFILE] Set Health Profile Alt',
	GET_HEALTH_PROFILE_ALT = '[HEALTHPROFILE] Get Health Profile Alt',

	SET_SELECTIONS = '[QUOTE] Set Selected Results',
	SET_REC_SORT = '[QUOTE] Set Rec Sort',
	SET_SELECTED_PLANS = '[QUOTE] Set Selected Plans',
	SET_SELLING_POINTS = '[QUOTE] Set Selling Points',
	CLEAR_TEMPORARY_DATA = '[QUOTE] Clear Values Saved while Editing'
}

export class NewQuote implements Action {
	public readonly type = QuoteActionTypes.NEW_QUOTE;
	constructor() {}
}
export class SetQuoteRequest implements Action {
	public readonly type = QuoteActionTypes.SET_REQUEST;
	constructor(public quoteRequest: QuoteRequest) {}
}
export class GetQuoteRequest implements Action {
	public readonly type = QuoteActionTypes.GET_REQUEST;
	constructor() {}
}
export class SetHealthProfile implements Action {
	public readonly type = QuoteActionTypes.SET_HEALTH_PROFILE;
	constructor(public healthProfile: HealthProfile) {}
}
export class GetHealthProfile implements Action {
	public readonly type = QuoteActionTypes.GET_HEALTH_PROFILE;
	constructor() {}
}
export class QuoteReset implements Action {
	public readonly type = QuoteActionTypes.RESET;
	constructor() {}
}
export class AddQuoteToCache implements Action {
	public readonly type = QuoteActionTypes.ADD_QUOTE_TO_CACHE;
	constructor(public quoteRequest: any, public quoteResponse: QuoteResponse) {}
}
export class GetQuoteFromCache implements Action {
	public readonly type = QuoteActionTypes.GET_QUOTE_FROM_CACHE;
	constructor(public quoteRequest: any) {}
}

export class SetHealthProfileAlt implements Action {
	public readonly type = QuoteActionTypes.SET_HEALTH_PROFILE_ALT;
	constructor(public healthProfileAlt: HealthProfileAlt) {}
}

export class GetHealthProfileAlt implements Action {
	public readonly type = QuoteActionTypes.GET_HEALTH_PROFILE_ALT;
	constructor() {}
}

export class SetSelections implements Action {
	public readonly type = QuoteActionTypes.SET_SELECTIONS;
	constructor(public selections: Array<QuoteResult>) {}
}
export class SetRecSort implements Action {
	public readonly type = QuoteActionTypes.SET_REC_SORT;
	constructor(public recSort: Array<QuoteRecSort>) {}
}
export class SetSelectedPlans implements Action {
	public readonly type = QuoteActionTypes.SET_SELECTED_PLANS;
	constructor(public selectedPlans: Array<QuoteResponse>) {}
}
export class SetSellingPoints implements Action {
	public readonly type = QuoteActionTypes.SET_SELLING_POINTS;
	constructor(public sellingPoints: { reason1?: string, reason2?: string, reason3?: string }) {}
}
export class ClearTemporaryQuoteData implements Action {
	public readonly type = QuoteActionTypes.CLEAR_TEMPORARY_DATA;
	constructor() {}
}

export type QuoteActions =
	NewQuote | 
	SetQuoteRequest |
	GetQuoteRequest |
	SetHealthProfile |
	GetHealthProfile |
	QuoteReset |
	AddQuoteToCache |
	GetQuoteFromCache |
	SetHealthProfileAlt |
	GetHealthProfileAlt |
	SetSelections |
	SetRecSort |
	SetSelectedPlans |
	SetSellingPoints |
	ClearTemporaryQuoteData;
