import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IMediaState } from '../state/media.state';
import { DataService } from 'acc-services';
import * as MediaCatgoryActions from '../actions/media.actions';
import { switchMap, withLatestFrom, map, catchError, take } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import * as StatusActions from '../actions/status.actions';
import { Folder, MediaCategory } from 'acc-models';

@Injectable()
export class mediaCategoryEffects {

	@Effect()
	getMediaCategories$ = this.actions$.pipe(
		ofType(MediaCatgoryActions.MediaCategoryActionTypes.GET_MEDIA_CATEGORIES),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		switchMap(([action, orgId]) => {
			return this.dataService.getSharedLibCategories(orgId)
				.pipe(
					map((resp:any) => new MediaCatgoryActions.GetMediaCategoriesSuccess(resp.map(c => new MediaCategory(c)))),
					catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error ? resp.error.Message : resp.Message))))
				)
			}
		)
	)
	@Effect()
	editMultipleCategories$ = this.actions$.pipe(
		ofType(MediaCatgoryActions.MediaCategoryActionTypes.EDIT_MULTIPLE),
		//withLatestFrom(this.store$.select('adminUser', 'orgId')),
		switchMap((action:MediaCatgoryActions.EditMultipleMediaCategories) => {
			let requests = [];
			let orgId;
			this.store$.select('adminUser', 'orgId').pipe(take(1)).subscribe(o => orgId = o)
			for(let cat of action.mediaCategories) {
				requests.push(this.dataService.updateMediaCategoryAdmin(orgId, Folder.create(cat)))
			}
			return forkJoin(requests).pipe(
				map((resp:any) => new MediaCatgoryActions.GetMediaCategories()),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error ? resp.error.Message : resp.Message))))
			)
		})
	)

	@Effect()
	editMediaCategory$ = this.actions$.pipe(
		ofType(MediaCatgoryActions.MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY_CONFIRM),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		withLatestFrom(this.store$.select('adminMedia', 'EditingMediaCategory')),
		switchMap(([[action, orgId], mediaCategory]) => {
			return this.dataService.updateMediaCategoryAdmin(orgId, mediaCategory)
				.pipe(
					map(() => new MediaCatgoryActions.EditMediaCategorySuccess()),
					catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error ? resp.error.Message : resp.Message))))
				)
			}
		)
	)

	@Effect()
	deleteMediaCategory$ = this.actions$.pipe(
		ofType(MediaCatgoryActions.MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY_CONFIRM),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		withLatestFrom(this.store$.select('adminMedia', 'DeletingMediaCategory')),
		switchMap(([[action, orgId], mediaCategory]) => this.dataService.deleteMediaCategoryAdmin(orgId, mediaCategory)
			.pipe(
				map(() => new MediaCatgoryActions.DeleteMediaCategorySuccess()),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error ? resp.error.Message : resp.Message))))
			)
		)
	)
	
	@Effect()
	addMediaCategory$ = this.actions$.pipe(
		ofType(MediaCatgoryActions.MediaCategoryActionTypes.ADD_MEDIA_CATEGORY),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		withLatestFrom(this.store$.select('adminMedia', 'EditingMediaCategory')),
		switchMap(([[action, orgId], mediaCategory]) => this.dataService.insertMediaCategoryAdmin(orgId, mediaCategory)
			.pipe(
				map((resp:any) => new MediaCatgoryActions.AddMediaCategorySuccess(resp.mediaCategoryID)),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error ? resp.error.Message : resp.Message))))
			)
		)

	)

	@Effect()
	moveMediaCategory$ = this.actions$.pipe(
		ofType(MediaCatgoryActions.MediaCategoryActionTypes.MOVE_MEDIA_CATEGORY),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		withLatestFrom(this.store$.select('adminMedia', 'EditingMediaCategory')),
		withLatestFrom(this.store$.select('adminMedia', 'DestinationFolder')),
		switchMap(([[[action, orgId], mediaCategory], destination]) => this.dataService.updateMediaCategoryAdmin(orgId, { ...mediaCategory, parent: {...mediaCategory.parent, mediaCategoryID: destination } } )
			.pipe(
				map(() => new MediaCatgoryActions.MoveMediaCategorySuccess()),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error ? resp.error.Message : resp.Message))))
			)
		)
	)


	constructor(private actions$:Actions, private store$:Store<IMediaState>, private dataService:DataService) {}
}