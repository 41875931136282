import { QuoteResult } from './quoteResult';

export class QuoteIgoRequest {

    public gaid: number;
    public buttonType: string;
    public client: QuoteIgoRequestClient;
    public agent: QuoteIgoRequestAgent;
    public quoteResult: QuoteResult;
    public requestId: string;
    public rowId: number;

	constructor(o?:any) {
		Object.assign(this, o);
    }
    
    public static fromQuote(quote: QuoteResult, clientName: string, logo: string, gaid: number, buttonTypes: any[], agentFullName: string): QuoteIgoRequest[] {
        var results = [];

        var igoRequest = null;
        buttonTypes.forEach(buttonType => {

            igoRequest = new QuoteIgoRequest({
                gaid: gaid,
                buttonType: buttonType.type,
                client: new QuoteIgoRequestClient({
                    name: clientName,
                    age: quote.input.actualAge,
                    ageNearest: quote.input.nearestAge,
                    gender: quote.input.gender,
                    state: quote.input.state
                }),
                agent: new QuoteIgoRequestAgent({
                    name: agentFullName,
                    phoneNumber: "", //TODO
                    licenseNumber: "" //TODO
                }),
                quoteResult: quote,
                requestId: "", //TODO
                rowId: 1,
            }); 

            results.push(igoRequest);

        });
        return results;
	}
}

export class QuoteIgoRequestClient {
	public name: string;
	public age: number;
	public ageNearest: number;
	public gender: string;
    public state: string;
    public dateOfBirth: Date;
	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class QuoteIgoRequestAgent {
	public name: string;
	public phoneNumber: string;
    public licenseNumber: string;
	constructor(o?: any) {
		Object.assign(this, o);
	}
}