		  
<div class="{{cssClass}}" >
	<div id="profile" class="profile-form" >
		<div class="columns" *ngIf="profile">
			<mat-card id="infoColumn" class="about profile-column">
				<mat-card-header>
				<mat-card-title><div class="title">My Profile</div></mat-card-title>
				</mat-card-header>
				<mat-card-content>
				<form class="content">
					<mat-form-field>
						<input matInput placeholder="Profile Name" [formControl]="profileNameField" class="profile-input-profileName" />
					</mat-form-field>
					<mat-form-field>
						<input matInput placeholder="Title" [formControl]="titleField" class="profile-input-title" />
					</mat-form-field>
					<div class="nameRow multifieldRow">
					<mat-form-field>
						<input matInput placeholder="First Name" [formControl]="firstNameField" class="profile-input-firstName" />
					</mat-form-field>
					<mat-form-field>
						<input matInput placeholder="Last Name" [formControl]="lastNameField" class="profile-input-lastName" />
					</mat-form-field>
					</div>
					<mat-form-field>
					<input matInput [ngClass]="{'invalid-label': companyNameField.hasError('required')}" placeholder="Company" [formControl]="companyNameField" class="profile-input-company" />
					</mat-form-field>
					<mat-form-field>
					<input matInput placeholder="Address 1" [formControl]="address1Field" class="profile-input-address1" />
					</mat-form-field>
					<mat-form-field>
					<input matInput placeholder="Address 2" [formControl]="address2Field" />
					</mat-form-field>
					<div class="cityStateZipRow multifieldRow multifield3Row">
					<mat-form-field>
						<input matInput placeholder="City" [formControl]="cityField" />
					</mat-form-field>
					<mat-form-field>
						<input matInput placeholder="State" [formControl]="stateField" style="text-transform: uppercase;"/>
					</mat-form-field>
					<mat-form-field>
						<input matInput placeholder="Zip" [formControl]="zipField" />
					</mat-form-field>
					</div>
					<div class="phoneRow multifieldRow multifield3Row">
					<mat-form-field>
						<input matInput placeholder="Main Phone" [formControl]="mainPhoneField" [mask]="phoneMask" [showMaskTyped] = "true"/>
					</mat-form-field>
					<mat-form-field>
						<input matInput placeholder="Direct Phone" [formControl]="directPhoneField" [mask]="phoneMask" [showMaskTyped] = "true" />
					</mat-form-field>
					<mat-form-field>
						<input matInput placeholder="Cell Phone" [formControl]="cellPhoneField" [mask]="phoneMask"  [showMaskTyped] = "true"/>
					</mat-form-field>
					</div>
					<mat-form-field>
						<input matInput placeholder="Email" [formControl]="emailField" class="profile-input-email"/>
					</mat-form-field>
					<mat-form-field>
						<input matInput placeholder="Url" [formControl]="websiteField" class="profile-input-url" />
					</mat-form-field>
					<ng-content select=[col1]></ng-content>
					<ng-content select=[col2]></ng-content>
				</form>
				</mat-card-content>
			</mat-card>

			<mat-card class="about-sidebar photo-column">
				<mat-card-header>
					<mat-card-title><div class="title">Profile Image <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.profile_image}}" >info_outline</mat-icon></div></mat-card-title>
				</mat-card-header>
				<mat-card-content> 
					<acc-flow-profile-photo type="Profile" [tooltip]="tooltips.profile_image"  [photo]="photo" [buttonText]="'Upload Photo'" [defaultTitle]="'User Photo'" [cssClass]="'profile'" #userPhoto [accept]="imageFileTypes" [errorMessage]="'File must be an image'"></acc-flow-profile-photo>
				</mat-card-content>
			</mat-card>

		</div>
	</div>
	<div id="social" class="profile-form" >
		<div class="columns" *ngIf="profile">
			<!--<mat-card id="infoColumn" class="about social-column">
				<mat-card-header>
					<mat-card-title><div class="title">Social</div></mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<form class="content">
					<mat-form-field>
						<input matInput placeholder="Url" [formControl]="websiteField" class="profile-input-url" />
					</mat-form-field>
					<mat-form-field>
					<input matInput placeholder="Facebook" [formControl]="facebookField" class="profile-input-facebook" />
					</mat-form-field>
					<mat-form-field>
					<input matInput placeholder="Twitter" [formControl]="twitterField" class="profile-input-twitter" />
					</mat-form-field>
					<mat-form-field>
					<input matInput placeholder="LinkedIn" [formControl]="linkedinField" class="profile-input-linkedin"/>
					</mat-form-field>
					<mat-form-field>
					<input matInput placeholder="Instagram" [formControl]="instagramField" class="profile-input-instagram" />
					</mat-form-field>
					<mat-form-field>
					<input matInput placeholder="YouTube" [formControl]="youtubeField" class="profile-input-youtube"/>
					</mat-form-field>
	
				</form>
				</mat-card-content>
			</mat-card>-->

			<mat-card class="about-sidebar logo-photo-column">
				<mat-card-header>
					<mat-card-title><div class="title">Company Logo <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.company_logo}}" >info_outline</mat-icon> <div *ngIf="!!showDefaultImage" class="default-logo-disclaimer">Your company logo below will be used by default. To use a custom logo for this profile, upload a new image here.</div> </div></mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div class="company-image-container">
						<div *ngIf="!!showDefaultImage" class="default-logo-container" [style.backgroundImage]="'url(' + defaultImageUrl + ')'"></div>
						<acc-flow-profile-photo type="Logo" [tooltip]="tooltips.company_logo" [photo]="logo" [buttonText]="'Upload Logo'" [defaultTitle]="'Company Logo'" [cssClass]="'profile-logo signup signup-logo'" #userLogo [accept]="imageFileTypes" [errorMessage]="'File must be an image'"></acc-flow-profile-photo>	
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
		 