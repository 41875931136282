import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, AfterViewInit } from "@angular/core";
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import * as MediaActions from '../../store/actions/media.actions';
import { SetOrgId } from '../../store/actions/users.actions';
import { SessionService } from 'acc-services';
import { Actions } from '@ngrx/effects';
import { selectMediaCategories, selectDeletingCategory, selectActiveCategory, selectEditingCategory } from '../../store/selectors/mediacategories.selectors';
import { Folder, Button, Media, MediaCategory } from 'acc-models';
import { tap, map, takeUntil, take } from 'rxjs/operators';
import { selectMedia, selectDeletingMedia, selectEditingMedia } from '../../store/selectors/media.selectors';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmComponent, tooltips } from 'acc-common';
import { EditMediaFormComponent } from './edit-media-form/edit-media-form.component';
import { RootReset } from '../../store/actions/root.actions';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTree } from '@angular/material/tree';
import * as MediaCategoryActions from '../../store/actions/media.actions';
import { GetMedias, MoveMedia } from '../../store/actions/media.actions';
@Component({
	templateUrl: './media.component.html',
	styleUrls: ['./media.component.scss']
})

export class MediaComponent implements OnInit, OnDestroy {

	private destroy$: Subject<boolean> = new Subject<boolean>();

	public tooltips = tooltips;
	public list: Array<string> = new Array<string>();

	public treeControl = new NestedTreeControl<MediaCategory>(node => node.MediaCategories);
	public dataSource = new MatTreeNestedDataSource<MediaCategory>();
	public hasChild = (_:number, node: MediaCategory) => !!node.MediaCategories && node.MediaCategories.length > 0;

	public cachebust: number = new Date().getMilliseconds();;

	@ViewChild('tree') tree: MatTree<MediaCategory>;

	mediaCategories$ = this.store$.pipe(
		select(selectMediaCategories),
		tap(c => {
			//const topLevel = new MediaCategory({ ...new Folder(0, -1, 'Top Level', c), isOpen: true });
			const topLevel = new MediaCategory({ mediaCategoryID: -1, mediaCategoryDesc: 'Top Level', isOpen: true, type: 0, readOnly: true, MediaCategories: c || [] })
			const oldValues = this.dataSource.data;
			this.dataSource.data = null;
			this.dataSource.data = [topLevel];
			this.treeControl.expand(topLevel);
			if(c) {
				this.recursivelyOpenNodes(this.dataSource.data, oldValues);
			}
		})
	)
	deletingCategory$ = this.store$.pipe(
		select(selectDeletingCategory),
		tap(c => {
			if(c) {
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm',
						message: `Are you sure you want to delete the category <b>${c.mediaCategoryDesc}</b>?`
					}
				});
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
					if(confirm) {
						this.store$.dispatch(new MediaActions.DeleteMediaCategoryConfirm())
					} else {
						this.store$.dispatch(new MediaActions.DeleteMediaCategoryCancel());
					}
				});
			}
		}),
		takeUntil(this.destroy$)
	).subscribe();
	medias$ = this.store$.pipe(
		select(selectMedia)
	)
	deletingMedia$ = this.store$.pipe(
		select(selectDeletingMedia),
		tap(m => {
			if(m) {
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm',
						message: `Are you sure you want to delete the media <b>${m.mediaDesc}</b>?`
					}
				});
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
					if(confirm) {
						this.store$.dispatch(new MediaActions.DeleteMediaConfirm())
					} else {
						this.store$.dispatch(new MediaActions.DeleteMediaCancel());
					}
				});
			}
		}),
		takeUntil(this.destroy$)
	).subscribe();
	editingMedia$ = this.store$.pipe(
		select(selectEditingMedia),
		takeUntil(this.destroy$)
	).subscribe();

	editingCategory$ = this.store$.pipe(
		select(selectEditingCategory)
	)
	activeCategory$ = this.store$.pipe(
		select(selectActiveCategory)
	)

	private newCategoryName: string = '';
	private activeMediaCategory: MediaCategory;
	private confirmDeleteCategoryMessage: string = '';
	private confirmDeleteMediaMessage: string = '';
	private confirmDeleteButtons: Array<Button> = new Array<Button>();
	private confirmDialogRef: MatDialogRef<ConfirmComponent>;
	private editDialogRef: MatDialogRef<EditMediaFormComponent>;

	dragging: string = '';
	dragNode: any;
	dragNodeExpandOverWaitTimeMs = 300;
	dragNodeExpandOverNode: any;
	dragNodeExpandOverTime: number;
	dragNodeExpandOverArea: string;

	dragMedia: Media;

	@ViewChild('categoriesListing') categoriesListing: ElementRef;
/*
	@HostListener("window:scroll", [])
    onWindowScroll() {
		if(window.pageYOffset > 39) {
			this.categoriesListing.nativeElement.classList.add('sticky');
			
		} else {
			this.categoriesListing.nativeElement.classList.remove('sticky');
			//this.categoriesListing.nativeElement.style.height = "auto";
		}
    }
*/

	constructor(private actions$:Actions, private store$:Store<IAppState>, private sessionService:SessionService, private sanitizer: DomSanitizer, private dialog:MatDialog) {
		this.confirmDeleteButtons.push(new Button("Yes", ""));
		this.confirmDeleteButtons.push(new Button("No", ""));

		actions$.pipe(
			map(a => {
				if(a.type == MediaActions.MediaCategoryActionTypes.ADD_MEDIA_CATEGORY_SUCCESS) {
					this.newCategoryName = '';
				}
				if(a.type == MediaActions.MediaActionTypes.EDIT_MEDIA_SUCCESS) {
					this.cachebust = new Date().getMilliseconds();
				}
			}),
			takeUntil(this.destroy$)
		)
			.subscribe();

	}


	addCategory() {
		if(this.newCategoryName) {
			this.store$.dispatch(new MediaActions.AddMediaCategory(new MediaCategory({ mediaCategoryID: null, mediaCategoryDesc: this.newCategoryName, isOpen: false, type: 0, readOnly: false, MediaCategories: [], sortOrder: 999 })));
			
		}
	}

	editMedia(media) {
		this.store$.dispatch(new MediaActions.EditMedia(Object.assign({}, media)))
		this.editDialogRef = this.dialog.open(EditMediaFormComponent, {
			data: {
				media: media
			}
		});
		this.editDialogRef.afterClosed().pipe(take(1)).subscribe(resp => {

		})
}
	deleteMedia(media) {
		this.store$.dispatch(new MediaActions.DeleteMedia(media));
	}
	activateCategory(cat:Folder) {
		this.store$.dispatch(new MediaCategoryActions.ActivateMediaCategory(new MediaCategory(cat)));
		this.store$.dispatch(new GetMedias(cat.mediaCategoryID));
	}

	drop(e) {
		if(e.dragData.type == 'mediaCategory') {
			const movingCategory = e.dragData.mediaCategory;
			if(!movingCategory.parent || movingCategory.parent.mediaCategoryID == -1) {
				return;
			}
			this.store$.dispatch(new MediaActions.MoveMediaCategory(movingCategory, new MediaCategory(new Folder(0, -1, 'TopLevel', null))));
		}
	}
	addMedia() {
		this.store$.dispatch(new MediaActions.EditMedia(new Media({mediaCategoryID: this.activeMediaCategory.mediaCategoryID, mediaItemID: ''})))
		this.editDialogRef = this.dialog.open(EditMediaFormComponent, {
			data: {
				media: new Media({mediaCategoryID: this.activeMediaCategory.mediaCategoryID, mediaItemID: ''})
			}
		});
		this.editDialogRef.afterClosed().pipe(take(1)).subscribe(resp => {

		})
	}
	ngOnInit():void {
		const user = this.sessionService.getUser();
		this.store$.dispatch(new RootReset());
		this.store$.dispatch(new SetOrgId(user.orgID))
		this.store$.dispatch(new MediaActions.GetMediaCategories())

		this.store$.pipe(
			select(selectActiveCategory), 
			takeUntil(this.destroy$),
			map(m => this.activeMediaCategory = m)
		).subscribe();
			
	}

	ngOnDestroy():void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	getBackgroundImageUrl(path) {
		return `url('${path}?${new Date().getMilliseconds()}')`;
	}

	recursivelyOpenNodes(nodes: Array<MediaCategory>, source: Array<MediaCategory>) {
		for(let node of nodes) {
			const oldOne = this.findNode(node.mediaCategoryID, source);
			if(oldOne && oldOne.isOpen) {
				node.isOpen = true;
				this.treeControl.expand(node);
			}
			if(node.MediaCategories.length > 0) {
				this.recursivelyOpenNodes(node.MediaCategories, source)
			}
		}
	}
	findNode(mediaCategoryId: number, source: Array<MediaCategory>) {
		for(let cat of source) {
			if(cat.mediaCategoryID == mediaCategoryId) {
				return cat;
			} else {
				if(cat.MediaCategories && cat.MediaCategories.length > 0){
					let found:MediaCategory = this.findNode(mediaCategoryId, cat.MediaCategories);
					if(found) {
						return found;
					} 
				}
			}
		}
		return null; 

	}

	toggleOpen(cat: MediaCategory) {
		cat.isOpen = !cat.isOpen;
	}
	editCategory(cat) {
		this.store$.dispatch( new MediaCategoryActions.EditMediaCategory(cat));
	}
	editCategoryConfirm() {
		this.store$.dispatch(new MediaCategoryActions.EditMediaCategoryConfirm());
	}
	deleteCategory(cat) {
		this.store$.dispatch(new MediaCategoryActions.DeleteMediaCategory(cat));
	}
	handleDragStart(event, node) {
		event.dataTransfer.setData('foo', 'bar'); //needed for firefox for some reason
		this.dragging = 'category';
		this.dragNode = node;
		if(node.mediaCategoryID > -1) {
			this.treeControl.collapse(node);
		}
	}

	handleDragOver(event, node) {
		event.preventDefault();

		if( node === this.dragNodeExpandOverNode) {
			if(this.dragNode !== node && !this.treeControl.isExpanded(node)) {
				if((new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
					this.treeControl.expand(node);
					node.isOpen = true;
				}
			}
		} else {
			this.dragNodeExpandOverNode = node;
			this.dragNodeExpandOverTime = new Date().getTime();
		}

		//handle drag area 
		const percentageX = event.offsetX / event.target.clientWidth;
		const percentageY = event.offsetY / event.target.clientHeight;
		if(percentageY < 0.25) {
			this.dragNodeExpandOverArea = 'above';
		} else if(percentageY > 0.75) {
			this.dragNodeExpandOverArea = 'below';
		} else {
			this.dragNodeExpandOverArea = 'center';
		}
	}

	handleDrop(event, node) { //node is the node you landed on

		//if you're dragging a readonly object (cat or media), don't allow
		if((this.dragNode && this.dragNode.readOnly || (this.dragMedia && this.dragMedia.readOnly))) {
			return;
		}
		//also, don't allow dropping on a readonly category
		if(node.readOnly) {
			return;
		}
		event.preventDefault();
		if(this.dragging == 'category') {
			if(node !== this.dragNode) {
				switch(this.dragNodeExpandOverArea) {
					case 'below': 
					case 'above':
						const parent = node.parent;
						//this.dragNode.mediaCategoryID = parent.mediaCategoryID; //may already be this, but that's fine
						const oldIndex = parent.MediaCategories.findIndex(v => v.mediaCategoryID == this.dragNode.mediaCategoryID);
						let newIndex = parent.MediaCategories.findIndex(v => v.mediaCategoryID == node.mediaCategoryID);
						if(this.dragNodeExpandOverArea === 'below') {
							newIndex++;
						}
						if(newIndex > oldIndex) {
							newIndex--;
						}
						if(oldIndex > -1) {
							parent.MediaCategories.splice(oldIndex,1); //remove it from whereever it was
						} else {
							newIndex++;
						}
						parent.MediaCategories.splice(newIndex, 0, this.dragNode); //add it in it's new position
						parent.MediaCategories.forEach((element, i) => {
							element.sortOrder = i;
							element.parent = parent;
						});
						let saveStartPoint = oldIndex == -1 ? newIndex : Math.min(oldIndex, newIndex);
						this.store$.dispatch(new MediaActions.EditMultipleMediaCategories(parent.MediaCategories.slice(saveStartPoint)));
						break;
					case 'center':
						this.store$.dispatch(new MediaActions.MoveMediaCategory(this.dragNode, node));
						break;
				}
				
			}
		}
		if(this.dragging == 'media') {
			this.store$.dispatch(new MoveMedia(this.dragMedia, node.mediaCategoryID));
		}
		this.dragging = '';
		this.dragNode = null;
		this.dragNodeExpandOverNode = null;
		this.dragNodeExpandOverTime = 0;

	}

	handleDragEnd(event) {
		this.dragging = '';
		this.dragNode = null;
		this.dragNodeExpandOverNode = null;
		this.dragNodeExpandOverTime = 0;
	}

	handleMediaDragStart(event, media) {
		this.dragging = 'media';
		this.dragMedia = media;
	}
	
	handleMediaDragEnd(event) {
		this.dragging = '';
		this.dragMedia = null;
	}
	
	handleMediaDrop(event, cat) {
		this.dragging = '';
	}
	
}
