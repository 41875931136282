import { Component, OnInit, OnDestroy } from "@angular/core";
import { SessionService } from 'acc-services';
import { User, Button } from 'acc-models';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import { GetUsers, EditUser, AddUser, SetOrgId, DeleteUser, DeleteUserConfirm, DeleteUserCancel } from '../../../store/actions/users.actions';
import { selectUserList, selectUserToDelete } from '../../../store/selectors/users.selectors';
import { map, tap, take, takeUntil } from 'rxjs/operators';
import { ClearErrorMessage } from '../../../store/actions/status.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent, tooltips } from 'acc-common';
import { Subject } from 'rxjs';

@Component({
	selector: 'user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss']
})

export class UserListComponent implements OnInit, OnDestroy {

	public tooltips = tooltips;
	private destroy$:Subject<number> = new Subject();
	public users$;
	public userToDelete$ = this.store.pipe(
		select(selectUserToDelete),
		tap((u:User) => {
			if(u) {
				const dialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm Delete',
						message: `<p>Are you sure you want to delete ${u.firstName} ${u.lastName}?<br><br></p><p>Deleting a user will permanently delete all associated documents, quotations and configuration data.  As an alternative to user deletion, if you are replacing an employee, you can update the user profile so the new employee can assume the data of this user.<br><br></p><p>Continue?</p>`
					}
				});
				dialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
					if(resp) {
						this.store.dispatch(new DeleteUserConfirm());
					} else {
						this.store.dispatch(new DeleteUserCancel());
					}
				})
			}
		}),
		takeUntil(this.destroy$)
	).subscribe();

	private message:string = '';
	private loggedInUser: User;
	private confirmDeleteButtons: Array<Button> = new Array<Button>();

	constructor(private store:Store<IAppState>, private sessionService:SessionService, private dialog:MatDialog) {
		this.confirmDeleteButtons.push(new Button("Yes", ""));
		this.confirmDeleteButtons.push(new Button("No", ""));
	}

	ngOnInit():void {
		this.loggedInUser = this.sessionService.getUser();
		this.store.dispatch(new SetOrgId(this.loggedInUser.orgID));
		this.store.dispatch(new GetUsers(this.loggedInUser.orgID));
		this.store.dispatch(new ClearErrorMessage());

		this.users$ = this.store.pipe(
			select(selectUserList),
			//map(users => users.filter(u => (u.userID != this.loggedInUser.userID)).sort(this.userSort))
			map(users => users.sort(this.userSort))
		);

		window.scrollTo(0,0);
	}

	ngOnDestroy():void {
		this.destroy$.next(0);
		this.destroy$.complete();
	}
	editUser(user:User) {
		this.store.dispatch(new EditUser(user))
	}
	addNew() {
		this.store.dispatch(new AddUser());
	}
	deleteUser(user:User) {
		if(user.userID === this.loggedInUser.userID) {
			return;
		}
		this.store.dispatch(new DeleteUser(user));
	}
	close(idx:number) {
		if(idx === 0) {
			this.store.dispatch(new DeleteUserConfirm());
		} else {
			this.store.dispatch(new DeleteUserCancel());
		}
	}
	private userSort(a:User,b:User)  {
		if(a.lastName > b.lastName) return 1;
		if(a.lastName < b.lastName) return -1;
		return 0;
	}
}
