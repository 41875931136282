import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { QuoteAlertsComponent } from '../../admin/quote-alerts/quote-alerts.component';
import { Injectable } from "@angular/core";

@Injectable()
export class CanDeactivateAdminQuoteAlert implements CanDeactivate<QuoteAlertsComponent> {
    
    canDeactivate(target: QuoteAlertsComponent,
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) 
    {
      return !target.isQuoteAlertSaved()?target.canNavigateAway():true;
    }

  }