/**
 * @prettier
 */
import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store, select } from "@ngrx/store";
import { IAppState } from "../../../store/state/app.state";
import { ActivatedRoute, Router } from "@angular/router";
import {
	DeleteList,
	DeleteListConfirm,
	DeleteListCancel,
	SaveList
} from "../../../store/actions/communication.actions";
import { ConfirmComponent } from "acc-common";
import { SubscriptionLike, Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";
import { Location } from "@angular/common";
import { Actions } from "@ngrx/effects";
import { selectList } from "../../../store/selectors/communication.selectors";

@Component({
	selector: "acc-main-list-detail-actions",
	templateUrl: "./list-detail-actions.component.html",
	styleUrls: ["../../shared/actions.scss"]
})
export class ListDetailActionsComponent implements OnInit, OnDestroy {
	private destroy$: Subject<boolean> = new Subject();
	private listId: string;
	private listUsed: boolean = false;
	private deleteDialogRef: MatDialogRef<ConfirmComponent>;
	private paramsSubscription: SubscriptionLike;

	list$ = this.store
		.pipe(
			select(selectList),
			tap(l => {
				if (l) {
					this.listUsed = l.usedInExecutedCampaign;
				}
			}),
			takeUntil(this.destroy$)
		)
		.subscribe();

	constructor(
		private dialog: MatDialog,
		private store: Store<IAppState>,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location
	) {}

	ngOnInit() {
		this.route.firstChild.params.subscribe((params: any) => {
			if (params.hasOwnProperty("listID") != "") {
				this.listId = params.listID;
			}
		});
	}

	deleteList() {
		this.store.dispatch(new DeleteList(this.listId));
		this.deleteDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `Are you sure you want to delete this list?`
			}
		});
		this.deleteDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe(confirm => {
				if (confirm) {
					this.store.dispatch(new DeleteListConfirm());
					this.router.navigate(["/communication/lists"]);
				} else {
					this.store.dispatch(new DeleteListCancel());
				}
			});
	}

	importData() {
		if (this.listUsed) {
			const confirmDialogRef = this.dialog.open(ConfirmComponent, {
				data: {
					title: "Confirm",
					message:
						"This List was used by a Campaign that has been delivered.  Re-importing will remove all history from this Campaign.  Are you sure you want to continue?"
				}
			});
			confirmDialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe(confirm => {
					if (confirm) {
						this.router.navigate(["/communication/lists/list-import", this.listId]);
					}
				});
		} else {
			this.router.navigate(["/communication/lists/list-import", this.listId]);
		}
	}
	goBack() {
		this.location.back();
	}

	saveList() {
		//removed button for this, but leaving here in case we return to it
		this.store.dispatch(new SaveList());
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
