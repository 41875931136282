<div mat-dialog-header>
	<h2 mat-dialog-title>{{data.action == 'rename' ? 'Rename' : 'Save'}} Cart</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<mat-form-field>
			<mat-label>Name</mat-label>
			<input matInput [formControl]="cartNameField" />
			<mat-error *ngIf="cartNameField.hasError('required')">Name is required</mat-error>
		</mat-form-field>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="saveCart()" [disabled]="!form.valid">Save</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>