import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { selectErrorMessage } from '../../store/selectors/status.selectors';
import { ClearErrorMessage } from '../../store/actions/status.actions';
import { RootReset } from '../../store/actions/root.actions';

@Component({
  templateUrl: './admin-template.component.html',
  styleUrls: [ './admin-template.component.scss']
})
export class AdminTemplateComponent implements OnInit  {

	public errorMessage$ = this.store.pipe(select(selectErrorMessage));
	
	constructor(private store:Store<IAppState>) {}

	ngOnInit(): void {
		
	}
	clearError() {
		this.store.dispatch(new ClearErrorMessage())
	}
}
