import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { SessionService, DataService } from 'acc-services';

@Component({
	template: ''
})
export class SalesforceOauthComponent implements OnInit {

	constructor(private dataService: DataService, private sessionService: SessionService, private route:ActivatedRoute) {}

	ngOnInit():void {
		let user = this.sessionService.getUser();
		this.route.queryParams.subscribe(params => {
			let token = params['code'];
			this.sessionService.setItem('crmToken', token);
			this.dataService.logCrm(user.userID, token).subscribe(resp => {
				this.sessionService.setUser({...user, skipCRM: false, hasCRMTokenData: true})
				const redirectUrl = this.sessionService.getItem<string>('returnUrl')
				window.location.href = redirectUrl;
			})
		});
		
	}
}
