import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'acc-main-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {

  public selectedClassicView: boolean;
  public colorPalette: number;
  constructor( public dialogRef: MatDialogRef<PreferencesComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.selectedClassicView = this.data.selectClassicView;
    this.colorPalette = this.data.colorPalette;
  }

  close(): void {
		this.dialogRef.close();
	}

  savePreferences(){
    this.dialogRef.close({
      selectClassicView: this.selectedClassicView,
      colorPalette: this.colorPalette,
    });
  }
}
