import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { DataService, MixpanelService, QuoteService, SessionService } from 'acc-services';
import { SubscriptionLike } from 'rxjs';
import { User } from 'acc-models';

@Component({
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
	private watchParams:any;
    private resource:string;
	public org: any;
	public action: string;
	private getOrgSubscription:SubscriptionLike;
	constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService, private sessionService: SessionService, private quoteService: QuoteService, private mixpanelService: MixpanelService) {}

	getStarted():void {
		this.router.navigateByUrl('/');
	}
	ngOnInit():void {
		this.watchParams = this.route.paramMap.subscribe(params => {
			this.resource = params.get('resource');
			this.action = params.get('action') || 'login'; //login is default
			this.sessionService.removeItem('organization');
			this.getOrgSubscription = this.dataService.getOrg(this.resource).subscribe(org => {
                
				if(org.orgID) {
                    this.org = org;
				    this.sessionService.setItem('organization', org);
				}
			})
		})
	}
    
    ngOnDestroy():void {
		this.watchParams.unsubscribe();
		this.getOrgSubscription.unsubscribe();
	}
	
	navigateTo(page: string): void {
		switch(page) {
			case 'recover':
				this.action = 'recover-password';
				break;
			case 'login': 
				this.action = 'login';
				break;
			case 'reset': 
				this.action = 'reset'
				break;
		}
	}

	login(user:User) {
		
		//Get Org configuration
		this.quoteService.loadQuoteOrgConfiguration();
		this.mixpanelService.initMixpanelTracker(this.router);

		var u = this.sessionService.getUser();
		
		if(!!u.useClassicView){
			this.router.navigateByUrl("/quote");
		}else{
			this.router.navigate(['/welcome']);
		}
	}
}
