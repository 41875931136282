<main class="lists-container">
	<mat-card>
		<mat-card-content>
			<mat-spinner class="loader" *ngIf="showLoader"></mat-spinner>
			<mat-form-field>
				<mat-label>Filter</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Filter..." [(ngModel)]="filterValue" />
			</mat-form-field>

			<table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
				<!-- Name Column -->
				<ng-container matColumnDef="firstName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
					<td mat-cell *matCellDef="let contact">
						<div class="name">{{ contact.firstName }}</div>
					</td>
				</ng-container>

				<!-- Last Name Column -->
				<ng-container matColumnDef="lastName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
					<td mat-cell *matCellDef="let contact">
						<div class="lastName">{{ contact.lastName }}</div>
					</td>
				</ng-container>

				<!-- Last Name Column -->
				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
					<td mat-cell *matCellDef="let contact">
						<div class="email">{{ contact.email }}</div>
					</td>
				</ng-container>

				<!-- Action Column -->
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>Include in outbound programs</th>
					<td mat-cell *matCellDef="let contact" (click)="$event.stopPropagation()">
						<mat-slide-toggle [(ngModel)]="!contact.excludedFromPrograms" (change)="includeInProgramsChange($event.checked, contact.contactID)"></mat-slide-toggle>
					</td>
				</ng-container>

				<ng-container matColumnDef="details">
					<td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
						<div [@detailExpand]="row == selectedContact ? 'expanded' : 'collapsed'" class="contact-detail" *ngIf="selectedContact == row">
							<ng-container *ngIf="selectedContactDetails">
								<div class="col">
									<dl>
										<dt>Address</dt>
										<dd>{{selectedContactDetails.address1}}<div *ngIf="row.address2">{{selectedContactDetails.address2}}</div></dd>
										<dt>City</dt>
										<dd>{{selectedContactDetails.city}}</dd>
										<dt>State</dt>
										<dd>{{selectedContactDetails.state}}</dd>
										<dt>Zip</dt>
										<dd>{{selectedContactDetails.zip}}</dd>
									</dl>
								</div>
								<div class="col">
									<dl>
										<dt>User Defined 1</dt>
										<dd>{{selectedContactDetails.udf01}}</dd>
										<dt>User Defined 2</dt>
										<dd>{{selectedContactDetails.udf02}}</dd>
										<dt>User Defined 3</dt>
										<dd>{{selectedContactDetails.udf03}}</dd>
										<dt>User Defined 4</dt>
										<dd>{{selectedContactDetails.udf04}}</dd>
										<dt>User Defined 5</dt>
										<dd>{{selectedContactDetails.udf05}}</dd>
									</dl>
								</div>
								<div class="col">
									<dl>
										<dt>Birthdate</dt>
										<dd>{{selectedContactDetails.birthdate | date:'MM/dd/yyyy'}}</dd>
										<dt>Auto Renewal Date</dt>
										<dd>{{selectedContactDetails.autoRenewalDate | date:'MM/dd/yyyy'}}</dd>
										<dt>Home Renewal Date</dt>
										<dd>{{selectedContactDetails.homeRenewalDate | date:'MM/dd/yyyy'}}</dd>
										<dt>Personal Insurance Review Date</dt>
										<dd>{{selectedContactDetails.pirDate | date:'MM/dd/yyyy'}}</dd>
										<dt>Life Policy End Date</dt>
										<dd>{{selectedContactDetails.lifeTerminationDate | date:'MM/dd/yyyy'}}</dd>
									</dl>
								</div>
							</ng-container>
							<ng-container *ngIf="!selectedContactDetails">
								<div class="center">Loading...</div>
							</ng-container>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns" class="expanded-row" [class.expanded-row]="selectedContact == row" (click)="expandContact(row)"></tr>
				<tr mat-row *matRowDef="let row; columns: ['details']" class="contact-details"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter.</td>
				</tr>
			</table>
			<mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
		</mat-card-content>
	</mat-card>
</main>
