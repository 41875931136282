import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { User, Settings, SettingsNavItem, Organization } from "acc-models";
import { Observable, ReplaySubject, Subject } from "rxjs";
import * as moment from "moment";
import Bugsnag from '@bugsnag/js';
import { IPipelineCredentials } from "projects/acc-models/src/lib/models/ipipelineCredentials";
import { MixpanelService } from "./mixpanel.service";
import { NavigationEnd } from "@angular/router";

declare const tinycolor: any;

@Directive()
@Injectable()
export class SessionService {
	@Output() onUserSaved: EventEmitter<User> = new EventEmitter<User>();
	@Output() showSalesforceLogin: EventEmitter<Subject<any>> = new EventEmitter<Subject<any>>();
	@Output() onShowLoader: EventEmitter<string> = new EventEmitter();
	@Output() onHideLoader: EventEmitter<string> = new EventEmitter();
	

	private salesforceLoginSubject: Subject<any> = new Subject<any>();
	public navIsOpen: any = {};

	private setNavOpen: Subject<boolean> = new ReplaySubject();
	public setNavOpen$ = this.setNavOpen.asObservable();

	constructor(private mixpanelService: MixpanelService) {}

	getItem<T>(key: string): T {
		return JSON.parse(window.sessionStorage.getItem(key));
	}
	setItem(key: string, value: any) {
		window.sessionStorage.setItem(key, JSON.stringify(value));
	}

	removeItem(key: string): void {
		window.sessionStorage.removeItem(key);
	}
	getUser(): User {
		return (JSON.parse(window.sessionStorage.getItem("currentUser")) as User) || new User();
	}
	setUser(user: User) {

		//Set user on Bugsnag
		Bugsnag.setUser(user.userID, user.email, user.firstName + " " + user.lastName);

		this.mixpanelService.init(user);

		window.sessionStorage.setItem("currentUser", JSON.stringify(user));
		this.onUserSaved.emit(user);
	}
	getCartId() {
		return window.sessionStorage.getItem("cartId");
	}
	setCartId(cartId: string) {
		window.sessionStorage.setItem("cartId", cartId);
	}

	salesforceLogin(): Observable<string> {
		this.showSalesforceLogin.next(this.salesforceLoginSubject);
		return this.salesforceLoginSubject.asObservable();
	}
	showLoader(id: string): void {
		this.onShowLoader.next(id);
	}
	hideLoader(id: string): void {
		this.onHideLoader.next(id);
	}
	set settings(s: Settings) {
		window.sessionStorage.setItem("settings", JSON.stringify(s));
		this.applySettings(s);
	}
	get settings(): Settings {
		return JSON.parse(window.sessionStorage.getItem("settings"));
	}
	set authToken(token: string) {
		window.sessionStorage.setItem("authToken", token);
	}
	get authToken(): string {
		return window.sessionStorage.getItem("authToken");
	}

	set iPipelineCredentials(creds: IPipelineCredentials) {
		window.sessionStorage.setItem("ipipeline_credentials", JSON.stringify(creds));
	}
	get iPipelineCredentials(): IPipelineCredentials {
		return JSON.parse(window.sessionStorage.getItem("ipipeline_credentials"));
	}

	set carriersProductsFilters(filter: Array<any>) {
		window.sessionStorage.setItem("carriers_products_filters", JSON.stringify(filter));
	}
	get carriersProductsFilters(): Array<any> {
		return JSON.parse(window.sessionStorage.getItem("carriers_products_filters"));
	}

	set loginAsOriginalInformation(user: any) {
		window.sessionStorage.setItem("login_as_original_information", JSON.stringify(user));
	}
	get loginAsOriginalInformation(): any {
		return JSON.parse(window.sessionStorage.getItem("login_as_original_information"));
	}

	openNav() {
		this.setNavOpen.next(true);
	}
	closeNav() {
		this.setNavOpen.next(false);
	}

	updateOrganization(org: Organization) {
		this.removeItem("organization");
		if (org.orgID) {
			this.setItem("organization", org);
		}
	}

	applySettings(settings?: Settings) {
		if (!settings) {
			settings = this.settings;
		}
		if (settings.primaryColor) {
			document.documentElement.style.setProperty("--primary-color", settings.primaryColor);
		}
		if (settings.primaryColorDark) {
			document.documentElement.style.setProperty("--primary-color-dark", settings.primaryColorDark);
		}
		if (settings.primaryColorLight) {
			document.documentElement.style.setProperty("--primary-color-light", settings.primaryColorLight);
		}
		if (settings.primaryColorContrast) {
			document.documentElement.style.setProperty("--primary-color-contrast", settings.primaryColorContrast);
		}
		if (settings.accentColor) {
			document.documentElement.style.setProperty("--accent-color", settings.accentColor);
		}
		if (settings.neutralColor) {
			document.documentElement.style.setProperty("--neutral-color", settings.neutralColor);
		}
		if (settings.logo) {
			document.documentElement.style.setProperty("--logo", settings.logo);
		}
		document.documentElement.style.setProperty("--nav-background", settings.nav.bgColor);
		document.documentElement.style.setProperty("--nav-text-color", settings.nav.textColor);
	}

	getSubscriptionLevelId() {
		var org = this.getItem("organization") as Organization;
		if(org == null){
			return 0;
		}
		
		if (org.requireSubscription) {
			return org.subscriptionLevelID;
		} else {
			return 0;
		}
	}

	public getSubscriptionEndDate() {
		var org = this.getItem("organization") as Organization;

		return org.subscriptionEndDate != null ? moment(org.subscriptionEndDate) : null;
	}

	private allowedPath = ["/welcome", "Term Life Quote", "/team-picture", "AccelerateGo!", "/module/54", "startNewQuote", "/quote/my-quotes", "Admin", "/admin/company", "/admin/users", "/admin/welcome-message", "/admin/subscription", "showLogInAsModal"];

	private level2Feature = ["/quote/analytics", "Messaging", "/module/send", "/sent-items", "/recent-activity", "/videos/create-video", "/library", "Communications", "/communication/lists", "/communication/campaigns", "/communication/reporting", "/admin/media", "/admin/categories", "/admin/revision-dates", "/admin/templates", "quoteSendProposal", "quoteCustomizeVideo", "/admin/quote-alerts", "/quote/self-service", "/admin/carrier-products-filter", "Insured Engagement", "/mkt/setup", "/mkt/contacts", "/mkt/sent-emails", "/mkt/upcoming-emails"];

	canUseSubscriptionLevelFeature(feature: string) {
		if (this.allowedPath.includes(feature)) {
			return true;
		}

		if (this.getSubscriptionLevelId() == 0) {
			return true;
		}

		if (this.getSubscriptionLevelId() >= 2 && this.level2Feature.includes(feature)) {
			return true;
		}

		//Cancelled subscription
		if (this.getSubscriptionLevelId() == -1 && this.getSubscriptionEndDate() != null && this.getSubscriptionEndDate() >= moment()) {
			return true;
		}

		return false;
	}

	isSubscriptionFreeOrEmpty() {
		return this.getSubscriptionLevelId() > 0 && this.getSubscriptionLevelId() <= 1;
	}

	getMasterOrgID(){
		var org = this.getItem("organization") as Organization;
		return org.masterOrgID;
	}
}

export interface Color {
	name: string;
	hex: string;
	darkContrast: boolean;
}
