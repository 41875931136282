
<div class="container">
	<div class="form">
		<p class="loading" *ngIf="!allUsers">Loading...</p>
		<form *ngIf="allUsers">
			<h2>Team Picture</h2>
			<p>Create your team picture by entering a title and dragging the names of the team members who will appear.</p>
			<div class="fieldWrapper">
				<mat-form-field>
					<mat-label>Title</mat-label>
					<input matInput [formControl]="titleField" name="title" />
					<mat-error *ngIf="titleField.hasError('required')">Title is required</mat-error>
				</mat-form-field>
			</div>
			<div class="fieldWrapper">
				<mat-form-field>
					<mat-label>Name</mat-label>
					<input matInput [formControl]="nameField" name="name" />
					<mat-error *ngIf="nameField.hasError('required')">Name is required</mat-error>
				</mat-form-field>
			</div>
				<p>Drag a name from the left list to the right to include in the team picture.  Users can be sorted.</p>

			<div class="bottom">
				<div class="allUsers" cdkDropList (cdkDropListDropped)="drop($event)" #allUsersList="cdkDropList" [cdkDropListData]="allUsers" [cdkDropListConnectedTo]="[leadersList, selectedUsersList]">
					<div *ngFor="let user of allUsers" cdkDrag class="chip">{{user.firstName}}&nbsp;{{user.lastName}}</div>
				</div>
				<div class="dropTargets" [appSticky]="254" [appStickyStyle]="{right: '55px', top: '114px'}" #targets>
					<h3>Leaders</h3>
					<div class="leaders" cdkDropList (cdkDropListDropped)="drop($event)" #leadersList="cdkDropList" [cdkDropListData]="leaders" [cdkDropListConnectedTo]="[allUsersList]">
						<div *ngFor="let user of leaders" cdkDrag class="chip">{{user.firstName}}&nbsp;{{user.lastName}}</div>
					</div>
					<h3>Team</h3>
					<div class="selectedUsers" cdkDropList (cdkDropListDropped)="drop($event)" #selectedUsersList="cdkDropList" [cdkDropListData]="selectedUsers" [cdkDropListConnectedTo]="[allUsersList]">
						<div *ngFor="let user of selectedUsers" cdkDrag class="chip">{{user.firstName}}&nbsp;{{user.lastName}}</div>
					</div>
				</div>
			</div>
			<button mat-flat-button color="primary" (click)="saveForm()">Save</button>
			
			<div class="errorMessage" *ngIf="errorMessage">{{errorMessage}}</div>
			<div class="imageHolder" *ngIf="imagePath">
				<h3>The image below has been saved to your Media Library.</h3>
				<img src="{{imagePath}}" /> 
			</div>
		</form>
	</div>

</div>
	