

export class MediaCategory {
	public mediaCategoryID: any;
	public mediaCategoryDesc: string;
	public isOpen: boolean = false;
	public MediaCategories: Array<MediaCategory> = new Array<MediaCategory>();
	public type: number;
	public parent: MediaCategory;
	public readOnly: boolean;

	constructor(o?:any) {
		if(o.mediaCategoryID != -1) {
			this.parent = new MediaCategory({mediaCategoryID: -1 });
		}
		Object.assign(this,o);
		if(o.MediaCategories) {
			this.MediaCategories = o.MediaCategories.map((c:MediaCategory) => new MediaCategory({ ...c, parent: this }))
		}
	}
}