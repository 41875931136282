<div class="select-health-type__container">
  <button mat-flat-button class="back-button" (click)="onBackToSearch()">
    <i class="fas fa-angle-left"></i>Back to search
  </button>

  <h1 class="page-title">Health Details</h1>
  <mat-card class="select-health-type__card">
    <mat-card-header>
      <mat-card-title class="select-health-type__title">Improve your results!</mat-card-title>
      <mat-card-subtitle class="select-health-type__subtitle">Add a few more details about your client, and get more
        accurate results based on carriers’ unique underwriting guidelines.</mat-card-subtitle>
    </mat-card-header>
    <form [formGroup]="selectHealthTypeForm">
      <mat-card-content class="select-health-type__content" *ngIf="healthTypeList" [formArrayName]="'items'">
        <mat-card class="health-type__panel" *ngFor="let control of selectHealthType.items['controls']; let i = index;"
          [formGroup]="control" (click)="onCardClick(i)" [class.active-border]="control.controls.checkbox.value"
          [class.disabled-card]="control.controls.isDisabled.value" [matTooltip]="message" [matTooltipDisabled]="!control.controls.isDisabled.value"
          matTooltipClass="tooltip">
          <div class="checkbox">
            <input type="checkbox" formControlName="checkbox" id="{{control.controls.id.value}}" disabled="true">
            <label attr.for="{{control.controls.id.value}}"></label>
          </div>
          <mat-card-content>
            <img mat-card-img [src]="control.controls.img.value" [alt]="control.controls.text.value">
            <mat-card-title>{{control.controls.text.value}}</mat-card-title>
            <ul>
              <li *ngFor="let content of control.controls.content.value">{{content}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </form>
    <mat-card-actions>
      <button mat-raised-button color="primary" [disabled]="!selectHealthType.selectedItems.valid" (click)="onContinue()">Continue</button>
    </mat-card-actions>
  </mat-card>
</div>
