import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../store/state/app.state';
import { selectErrorMessage } from '../store/selectors/status.selectors';
@Component({
  selector: 'acc-main-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit {
  public errorMessage$ = this.store.pipe(select(selectErrorMessage));
  constructor(private store:Store<IAppState>) { }

  ngOnInit() {
  }

}
