export class QuoteDetailsRequest {

    public gaid: number;
    public carrierId: number;
    public productId: number;
    public healthClass: string;
    public age: number;
    public faceAmount: number;
    
	constructor(o?:any) {
		Object.assign(this, o);
	}
}