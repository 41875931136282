import { CommCampaignDetailData } from './commCampaignDetailData';

export class CommCampaignDetail {
	public totalRecords: number;
	public totalPages: number;
	public CommCampaignDetailData: Array<CommCampaignDetailData>;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}
