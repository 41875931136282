import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from 'acc-services';
import { TemplateComponent } from '../components/template/template.component';
import { QuoteComponent } from '../quote-engine/quote/quote.component';
import { QuotesResultsComponent } from './quotes-results/quotes-results.component';
import { QuoteDetailComponent } from './quote-detail/quote-detail.component';
import { QuoteListComponent } from './quote-list/quote-list.component';
import { CanDeactivateQuote } from '../components/guard/can-deactivate-quote';

const routes: Routes = [
	{
		path: 'quote',
		component: TemplateComponent,
		canActivate:[AuthGuard],
		children: [{
			path: '',
			component: QuoteComponent,
			children: [
				{path:'results/:quoteId', component: QuotesResultsComponent, canActivate:[AuthGuard], canDeactivate: [CanDeactivateQuote], data: { pageTitle: 'Quote Results' }},
				{path:'results', component: QuotesResultsComponent, canActivate:[AuthGuard], canDeactivate: [CanDeactivateQuote], data: { pageTitle: 'Quote Results' }},
				{path:'my-quotes', component: QuoteListComponent, canActivate:[AuthGuard], data: { pageTitle: 'My Quotes' }},
				{path:'top-10', component: QuotesResultsComponent, data: { fromTopTen: true, pageTitle: 'Top 10 Quotes' }, canActivate:[AuthGuard], canDeactivate: [CanDeactivateQuote]},
				{path:'view-quote/:quoteId/top-10', component: QuotesResultsComponent, data: { fromTopTen: true, fromMyQuotes: true, pageTitle: 'Quote Details' }, canActivate:[AuthGuard], canDeactivate: [CanDeactivateQuote]},
				{path:'view-quote/:quoteId', component: QuotesResultsComponent, data: { fromMyQuotes: true, pageTitle: 'Quote Details' }, canActivate:[AuthGuard], canDeactivate: [CanDeactivateQuote]},
			]
		}]
	},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class QuoteResultsRoutingModule { }
