import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { User } from 'acc-models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'acc-common-share-file',
  templateUrl: './share-file.component.html',
  styles: [`
	mat-dialog-content { min-width: 400px; }
	mat-form-field { width:100%; }
	mat-dialog-actions button { margin-right:8px; }
  `]
})
export class ShareFileComponent implements OnInit {

	public consultant: User;
	
	constructor(public dialogRef: MatDialogRef<ShareFileComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

	close() {
		this.dialogRef.close();
	}
	share() {
		this.dialogRef.close(this.consultant);
	}
	ngOnInit() {
	}

}
