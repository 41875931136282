import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MixpanelService } from 'acc-services';

@Component({
	selector: 'acc-main-recover-password',
	templateUrl: './recover-password.component.html',
	styleUrls: ['../login/login.component.scss', './recover-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RecoverPasswordComponent implements OnInit {

	@Output() onNavigateTo: EventEmitter<string> = new EventEmitter();
	@Output() onRecover: EventEmitter<boolean> = new EventEmitter();

	constructor(private mixpanelService: MixpanelService) { }

	ngOnInit() {
		this.mixpanelService.trackPageView();
	}

	navigateTo(page:string):void {
		this.onNavigateTo.next(page);
	} 
	recover(success:boolean):void {
		this.onRecover.next(success);
	}
}