import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { DataService } from 'acc-services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { CommList, CommDataFileInfo, CommDataFileMap, Mapping } from 'acc-models';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubscriptionLike } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { SetTitle } from '../../store/actions/communication.actions';

@Component({
  selector: 'app-list-import',
  templateUrl: './list-import.component.html',
  styleUrls: ['./list-import.component.scss', '../shared/communication.scss']
})
export class ListImportComponent implements OnInit {
  public list = new CommList();
  private getListSubscription:SubscriptionLike;
  public uploadFileSubscription:SubscriptionLike;
  public importFileSubscription:SubscriptionLike;

  public step2complete: boolean = false;

  firstStepCompleted:boolean = false;
  file: any = null;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  uploadedFileInfo:CommDataFileInfo = new CommDataFileInfo();

  targetFields = [];
  targetFieldsFiltered = [[]];
  commDataFileMap:CommDataFileMap = new CommDataFileMap();
  requiredFields = [];

  constructor(
	private dataService: DataService,
	private route: ActivatedRoute,
	private dialog: MatDialog,
	private router: Router,
	private snackbar: MatSnackBar,
	private _location: Location,
	private ngZone: NgZone,
	private store: Store<IAppState>,
	) { 

  }

  ngOnInit() {
	this.route.params.subscribe(params => {
		this.getList(params.listID);
	});   
  }

  goBack(){
		this._location.back();
  }

  getList(listID){
	this.getListSubscription = this.dataService.getList(listID).subscribe((list:any) => {
	  this.list = list;
	  this.store.dispatch(new SetTitle(this.list.listName));
	})
  }

  GoToLists(){
	this.router.navigate(['/communication/lists']);
  }

  uploadFile(event) {
	this.file = event[0];
	this.firstStepCompleted = true;
  }

  deleteAttachment(index) {
	this.file = null;
	this.firstStepCompleted = false;
  }

  
  fieldNameChange(i, value){
	this.targetFieldsFiltered[i] = this._filter(value);
  }

  private _filter(value: string): string[] {
	const filterValue = value.toLowerCase();
	var filtered = this.targetFields.filter(option => option.fieldName.toLowerCase().includes(filterValue));
	return filtered;
  }

  mapTargetFields(){
	this.targetFields = [];
	this.uploadedFileInfo.TargetFields.forEach(f => {
	  this.targetFields.push(
		{
		   fieldName: f.fieldName,
		   required: f.required
		}
	  )
	});

	this.uploadedFileInfo.CSVFields.forEach((f, i) => {
	  this.targetFieldsFiltered[i] = this.targetFields;
	});

  }

  initCSVImport(result:CommDataFileInfo){
	this.uploadedFileInfo = result;
	this.requiredFields =  this.uploadedFileInfo.TargetFields.filter(option => option.required == true);
	this.mapTargetFields();
	this.commDataFileMap.listID = this.list.listID;
	this.commDataFileMap.filePath = result.filePath;
	this.commDataFileMap.Mappings = new Array<Mapping>();
	//BUILD MODEL.
	this.uploadedFileInfo.CSVFields.forEach((f, i) => {
	  var mapping = new Mapping();
	  mapping.columnNumber = f.columnNumber;
	  this.commDataFileMap.Mappings.push(mapping);
	});

  }

  uploadFileToAPI(listID, file){
	this.uploadFileSubscription = this.dataService.uploadCSV(listID, file).subscribe(
	  	(result:CommDataFileInfo) => {
			this.initCSVImport(result);
	  },
	  err => {
		  this.snackbar.open('CSV Upload Fail' + (err.error.Message ? ': ' + err.error.Message : ''), 'OK', { panelClass: 'errorMessageSnackbar', duration: 5000 })
		  this.stepper.previous();
	  });
  }

  importFile(listID, filePath, Mappings ){
	this.importFileSubscription = this.dataService.importDataFile(listID, filePath, Mappings).subscribe(
	  (result:any) => {
		this.snackbar.open('CSV Import Success.  We\'re processing your content', 'OK', { duration: 5000 })
	  },
	  err => {
		this.snackbar.open('CSV Import Fail' + (err.error.ExceptionMessage ? ': ' + err.error.ExceptionMessage : ''), 'OK', { panelClass: 'errorMessageSnackbar' })
	  });
	
  }

  clearTargetFieldsFiltered(index){
	this.commDataFileMap.Mappings[index].fieldName = null;
	this.targetFieldsFiltered[index] = this.targetFields;
  }

  checkForRequiredMappingFields(){
	var found = true;
	for(let f of this.requiredFields) {
	  found = this.commDataFileMap.Mappings.filter(m => m.fieldName == f.fieldName).length > 0;
	  if(!found) break;
	}
	return found;
  }

  finishAssignFields() {
	if(this.checkForRequiredMappingFields()) {
		this.importFile(this.list.listID, this.commDataFileMap.filePath, this.commDataFileMap.Mappings );
		this.ngZone.run(() => {
			this.step2complete = true;
			this.stepper.selected.completed = true;
			this.stepper.selected.editable = false;
			this.stepper.next();
		  });    
	} else {
		this.step2complete = false;
		this.snackbar.open('All required fields must be mapped', 'OK', { panelClass: 'errorMessageSnackbar', duration: 500000 })
	}
}
  onStepChange(event){
	if(event.selectedIndex == 1 && !!this.file){
		this.uploadFileToAPI(this.list.listID, this.file);
	}
	  
  }



}
