<div class="info-container" [style.width]="data.width" [style.height]="data.height">

	<div class="info-content">
		<h1 *ngIf="data.title" [innerHTML]="data.title"></h1>
		<p [innerHTML]="data.message"></p>
		<div class="close" (click)="close()"><mat-icon>highlight_off</mat-icon></div>
	</div>
	<div class="bottom-branding">
		<div class="branding-neg-slant"></div>
		<div class="branding-pos-slant"></div>
	</div>
</div>