import { Injectable, ɵwhenRendered } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { SessionService } from "../services/session.service";
import { Organization } from "acc-models";
import * as moment from "moment";

@Injectable()
export class SubscriptionLevelGuard implements CanActivate {
	constructor(private router: Router, private sessionService: SessionService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		var routeLevel = route.data.level;
		var userLevel = this.sessionService.getSubscriptionLevelId();

		//Check free (canceled) user with remain credit.
		if (userLevel != null && userLevel == -1) {
			return this.sessionService.getSubscriptionEndDate() >= moment();
		}

		if (userLevel != null && userLevel > 0 && userLevel < routeLevel) {
			this.router.navigate(["/welcome"]);
		}
		if (userLevel == null) {
			this.router.navigate(["/login"]);
		}
		return true;
	}
}
