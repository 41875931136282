<h1 mat-dialog-title class="video-preview-title">{{data.programName}}</h1><br>
<div mat-dialog-content>
    <div *ngIf="!showProgress" class="videoWrapper">
		<video controls>
			<source src="{{videoUrl}}" type="video/mp4">
		</video>
    </div>
    <div *ngIf="showProgress" class="progressBarWrapper">
        <br><br>
        <h1>We are building your video... please wait...</h1>
        <mat-progress-bar class="example-margin" [color]="primary" [mode]="determinate" [value]="counter" [bufferValue]="100">
        </mat-progress-bar>
    </div>
    <div class="video-preview-social">

		<share-buttons *ngIf="!showProgress" [theme]="'material-dark'"
			[include]="['facebook','twitter','linkedin','whatsapp','copy']"
			[show]="6"
			[size]="-1"
			[url]="sharedURL"
			[autoSetMeta]="true"
			[title]="data.programName"
        	[description]="data.programName"
        	[image]="thumbnailURL">
		</share-buttons>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" class="video-preview-button" (click)="onNoClick()">Close</button>
	<button mat-raised-button color="primary" class="video-preview-button" (click)="saveToAssetLibrary()" *ngIf="!showProgress && isAdmin">Save to My Asset Library</button>
</div>