import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
export class DtcRouteService {
	private pathParamState = new Subject<string>();
	private pageState = new BehaviorSubject<string>(null);
	public pathParam: Observable<string>;
	public page: Observable<string>;

	constructor() {
		this.pathParam = this.pathParamState.asObservable();
		this.page = this.pageState.asObservable();
	}

	setPathParam(newParam: string) {
		this.pathParamState.next(newParam);
	}
	setPage(page: string) {
		this.pageState.next(page);
	}
}