import { CommCampaignListParm } from './commCampaignListParm';
import { CommMediaAttachment } from './commMediaAttachment';

export class CommCampaign {
	public campaignID: string;
	public adminUserID: string;
	public EmailMediaItemIDs: Array<string> = [];
	public SMSMediaItemIDs: Array<string> = [];
	public EmailMediaAttachments: Array<CommMediaAttachment> = [];
	public SMSMediaAttachments: Array<CommMediaAttachment> = [];
	public campaignStatusDesc: string;
	public campaignStatusID: number;
    public campaignType: string;
    public phoneWavFilePath: string;
    public orgID: number;
    public campaignName: string;
    public campaignDesc: string;
    public phone: boolean;
    public sms: boolean;
    public email: boolean;
    public deliveryDateTime: Date;
    public listID: string;
    public filterTypeID: number;
    public emailSubject: string;
	public emailContent: string;
	public emailTemplate: string;
    public smsText: string;
    public phoneWavFileName: string;
    public ListParms: Array<CommCampaignListParm>;

    public maxTypesToSend: number;
    public phonePriority: number;
    public smsPriority: number;
    public emailPriority: number;

    constructor(o?:any) {
		Object.assign(this, o);
		if(this.campaignType) {
			this.campaignType = this.campaignType.split('/').filter(t => t.length > 0).join('/');
		}
	}
}