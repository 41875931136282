import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { pairwise, startWith } from 'rxjs/operators';
import { SetErrorMessage } from '../../store/actions/status.actions';
import { IAppState } from '../../store/state/app.state';
@Component({
  selector: 'acc-main-threshold-form',
  templateUrl: './threshold-form.component.html',
  styleUrls: ['./threshold-form.component.scss']
})
export class ThresholdFormComponent implements OnInit {

  @Input() public alert: any;
  @Input() public carriers: any;
  public alertForm: FormGroup;

  private oldSelectsCarrierValues: any[];
  private selectsCarrierValues: any[];

  constructor(private fb: FormBuilder, private store: Store<IAppState>) { }
  
  ngOnInit() {
    
    this.alertForm = this.fb.group({
      message: ["", []],
      alerts: this.fb.array([this.fb.group({
        carrierID: [null, []],
        thresholdID: [null, []]
      })]),
    });
    
    this.buildAlertForm();
    this.checkDuplicates();
  }

  buildAlertForm(){

    this.alertForm.controls.message.setValue(this.alert["message"]);
    var alertArray:FormArray = this.alertForm.controls.alerts as FormArray;

    var a_count = 0;

    this.alert["thresholds"].forEach(element => {
      if(a_count > 0){
        alertArray.push(
          this.fb.group({
            carrierID: [null, []],
            thresholdID: [null, []]
          })
        );
      }
      this.alertForm.controls.alerts['controls'][a_count]['controls'].carrierID.setValue(element.carrierID);
      this.alertForm.controls.alerts['controls'][a_count]['controls'].thresholdID.setValue(element.thresholdID);
      a_count +=1;
    });

  }

  checkDuplicates(){
    var alerts = this.alertForm.get('alerts');
    alerts.valueChanges.pipe(
      startWith(alerts.value),
      pairwise()
    ).subscribe(([prev, next]: [any, any])=>{
      this.oldSelectsCarrierValues = prev;
      this.selectsCarrierValues = next;
    });
  }

  add(){
    var alertArray:FormArray = this.alertForm.controls.alerts as FormArray;
    alertArray.push(
      this.fb.group({
        carrierID: [null, []],
        thresholdID: [ this.alert.favorableUpTo[0].thresholdID, []]
      })
    );
  }

  delete(index){
    let controls = this.alertForm.controls.alerts['controls'];
    let alerts = this.alertForm.value.alerts;
    controls.splice(index, 1);
    alerts.splice(index, 1);
    this.alertForm.markAsDirty();
  }

  selectedCarrierChanged(event){

    var count = this.selectsCarrierValues.filter((item, index) => item.carrierID === event.value).length;

    if(count > 1){
      let carrier = this.carriers.find((item, index) => item.id === event.value)
      this.store.dispatch(new SetErrorMessage("You've already added "+ carrier.name+" to the alerts."));
      this.alertForm.controls.alerts.setValue(this.oldSelectsCarrierValues);
    }
  }

}