
<div class="filter-container">
  <mat-card >
    <mat-card-header>
    </mat-card-header>
    <mat-card-content>
      <div class="header">
        <div class="carrier">Carrier</div>
        <div class="product">Product</div>
        <div class="action"></div>
      </div>

      <div class="row" *ngFor="let item of productsFilter" >
      
        <div class="carrier">
          {{item.carrierName}}
        </div>

        <div class="product">
          {{item.productName}}
        </div>

        <div class="action">
          <mat-slide-toggle [checked]="item.selected" (change)="selectedChanged(item)"></mat-slide-toggle>
        </div>
      </div>

    </mat-card-content>
    <mat-card-actions class="page-actions">
      <button [disabled]="loading" (click)="save()" mat-flat-button color="primary" class="save-quote-alerts-button">Save</button>
    </mat-card-actions>
  </mat-card>
</div>