
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, Inject } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { LibraryText, LibraryTextCategory } from 'acc-models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'acc-common-text-library-search',
	templateUrl: './text-library-search.component.html',
	styleUrls: ['./text-library-search.component.scss'],
	styles: [`
		.popup {
			width:710px;
			height:750px;
		}
	`]
})


export class TextLibrarySearchComponent implements OnInit, OnDestroy {
	@Output() closeForm:EventEmitter<LibraryText> = new EventEmitter<LibraryText>();
	@Output() appendLibraryText:EventEmitter<LibraryText> = new EventEmitter<LibraryText>();
	@Output() replaceWithLibraryText:EventEmitter<LibraryText> = new EventEmitter<LibraryText>();
	public categoryId: number;
	public term: string = ' ';
	public textLibCategories: Array<LibraryTextCategory>;
	public results: Array<LibraryText>;
	public activeResult: LibraryText;
	public showLightbox:boolean = false;
	public lightboxTitle: string = '';
	public lightboxContent: string = '';
	public searching: boolean = false;

	private orgId: number;

	constructor(
		private dataService:DataService, 
		private sessionService:SessionService,
		public dialogRef: MatDialogRef<TextLibrarySearchComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
		) {

		}

	search() {
		this.searching = true;
		this.dataService.searchTextLib(this.orgId, this.categoryId || -1, this.term).subscribe((resp:any) => {
			this.results = resp;
			this.searching = false;
		})
	}

	highlightResult(libText: LibraryText) {
		this.activeResult = libText;
	}

	append() {
		if(this.activeResult) {
			//this.appendLibraryText.next(this.activeResult);
			this.dialogRef.close({
				libText: this.activeResult,
				action: 'append'
			});
		}
	}
	replace() {
		if(this.activeResult) {
			//this.replaceWithLibraryText.next(this.activeResult);
			this.dialogRef.close({
				libText: this.activeResult,
				action: 'replace'
			});
		}
	}
	close() {
		//this.closeForm.next(this.activeResult);
		this.dialogRef.close({
			libText: this.activeResult,
			action: 'close'
		});
	}

	expandText(text:LibraryText) {
		this.showLightbox = true;
		this.lightboxTitle = text.textLibTitle;
		this.lightboxContent = text.textLibDesc;
	}

	closeLightbox() {
		this.showLightbox = false;
	}
	ngOnInit():void {
		this.orgId = this.sessionService.getUser().orgID;
		this.dataService.getTextLibCategories(this.orgId).subscribe((resp:any) => {
			this.textLibCategories = resp;
		})
	}

	ngOnDestroy():void {
	}
}