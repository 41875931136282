import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { DataService, SessionService, MixpanelService } from 'acc-services';
import { User, MediaContents, LibraryItem, Folder, Media } from 'acc-models';
import { Observable, of, forkJoin } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShareFileComponent, ConfirmComponent, NewFolderComponent, UploadFileComponent, MoveFolderComponent, RenameFolderComponent, PersonalizeDialogComponent, LibraryContentFoldersComponent, SaveCartComponent } from 'acc-common';
import { take } from 'rxjs/operators';
import { CopyComponent } from 'projects/acc-common/src/lib/components/library/library-content-files/forms/copy/copy.component';
import { Router } from '@angular/router';
import { tooltips } from 'acc-common';
import { SetErrorMessage } from '../../store/actions/status.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';

@Component({
  selector: 'acc-main-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LibraryComponent implements OnInit {

	public sharedAssets: Array<Folder>;
	public myAssets: Array<Folder>;
	public cartAssets: Array<Folder>;
	private myLibFolders: Array<Folder> = []; //putting this here bc it's needed for copy file
	public folderType: string;

	private mainCartId: string;
	private activeCartId: string;

	public contents: MediaContents;

	private user:User;
	private shareFileDialogRef: MatDialogRef<ShareFileComponent>;
	private confirmDialogRef: MatDialogRef<ConfirmComponent>;
	private copyFileDialogRef: MatDialogRef<CopyComponent>;
	private newFolderDialogRef: MatDialogRef<NewFolderComponent>;
	private uploadFileDialogRef: MatDialogRef<UploadFileComponent>;
	private moveFolderDialogRef: MatDialogRef<MoveFolderComponent>;
	private renameFolderDialogRef: MatDialogRef<RenameFolderComponent>;
	private personalizeDialogRef: MatDialogRef<PersonalizeDialogComponent>;
	private saveCartDialogRef: MatDialogRef<SaveCartComponent>;
	public activeFolder: Folder;

	public isFileUploadEnabled: boolean = false;

	public tooltips = tooltips;

	constructor(
		private dataService: DataService, 
		public sessionService: SessionService, 
		private dialog: MatDialog, 
		private router: Router, 
		private snackBar: MatSnackBar,
		private store: Store<IAppState>,
		private mixpanelService: MixpanelService) { }

	ngOnInit() {
		this.loadFolders();
	}

	loadFolders():void {
		this.user = this.sessionService.getUser();

		//let mbAssetFolder = new Folder(1, -1, "Shared Asset Library", new Array<Folder>(), null);
		let mbAssetFolder = Folder.create({type: 1, mediaCategoryID: -1, mediaCategoryDesc: "Shared Asset Library", isOpen: true, MediaCategories: [], parent: null, sortOrder: 1 })

		let sharedLibFolders: Array<Folder>, myModuleFolders: Array<Folder>, sharedWithMeFolders: Array<Folder>;
		this.sharedAssets = new Array<Folder>(); 
		this.myAssets = new Array<Folder>(); 
		this.cartAssets = new Array<Folder>();
		myModuleFolders = new Array<Folder>(); 
		sharedWithMeFolders = new Array<Folder>(); 

		//shared library folders
		this.dataService.getSharedLibCategories(this.user.orgID).subscribe((resp:any) => {
			sharedLibFolders = resp.map(f => {
				return Folder.create({mediaCategoryID: f.mediaCategoryID, mediaCategoryDesc: f.mediaCategoryDesc, isOpen: false, MediaCategories: f.MediaCategories.sort(this.sortFn), type: 1, parent: mbAssetFolder, sortOrder: f.sortOrder})
			});
			mbAssetFolder.MediaCategories.push(...sharedLibFolders);
			this.sharedAssets.push(mbAssetFolder);
		})

		let myAssetLibraryMainFolder = Folder.create({ type: 2, mediaCategoryID: -1, mediaCategoryDesc: 'My Asset Library', isOpen: true, MediaCategories: [], parent: null, sortOrder: 1 });
		this.myAssets.push(myAssetLibraryMainFolder);

		//modules
		this.dataService.getModules(this.user.orgID).subscribe((resp:any) => {
			myModuleFolders = resp.map(f => {
				return new Folder(3, f.moduleID, f.moduleDesc,null, myAssetLibraryMainFolder);
			});
			myAssetLibraryMainFolder.MediaCategories = [...myModuleFolders, ...this.myLibFolders, ...sharedWithMeFolders]
			

		})

		//my custom folders
		this.dataService.getUserLibCategories(this.user.userID).subscribe((resp:any) => {
			this.myLibFolders = resp.map(f => {
				return new Folder(2, f.mediaCategoryID, f.mediaCategoryDesc, f.MediaCategories, myAssetLibraryMainFolder);
			});
			myAssetLibraryMainFolder.MediaCategories = [...myModuleFolders, ...this.myLibFolders, ...sharedWithMeFolders]
			
		})

		//shared with me folders
		this.dataService.getSharedFolders(this.user.userID).subscribe((resp:any) => {
			sharedWithMeFolders = resp.map(f => {
				return new Folder(4, f.userSharedFrom, f.userShareName, f.MediaCategories, myAssetLibraryMainFolder);
			});
			myAssetLibraryMainFolder.MediaCategories = [...myModuleFolders, ...this.myLibFolders, ...sharedWithMeFolders]
		})

		//cart
		this.getCartId$(this.user.userID).then((cartId) => {
			let cartAssetFolder = new Folder(5, cartId, "Cart", new Array<Folder>(), null);
			cartAssetFolder.MediaCategories = [];
			this.cartAssets.push(cartAssetFolder);
			this.dataService.getSavedCarts(this.user.userID).subscribe((resp:any) => {
				cartAssetFolder.MediaCategories = resp.map(f => new Folder(5, f.cartSessionID, f.sessionDesc, null, null))
			})
			this.activeCartId = cartId;
			this.mainCartId = cartId;
		})


	}
	folderAction(actionInfo: { actionName: string, payload: any }) {
		let folder = actionInfo.payload.folder;
		this.activeFolder = folder;
		switch(actionInfo.actionName) {
			case 'folderSelected':
			case 'breadcrumbClick':
				this.loadFolderContents(folder);
				this.isFileUploadEnabled = (folder.mediaCategoryID > -1 && folder.type == 2);
				this.activeFolder = Folder.create(folder);
				this.activeCartId = '';
				break;
			case 'cartSelected':
				this.isFileUploadEnabled = false;
				let cartId = actionInfo.payload.folder.mediaCategoryID;
				if(cartId) {
					this.loadCartContents(cartId);
				} else {
					//main cart
					this.loadCartContents(this.mainCartId);
				}
				this.activeCartId = cartId;
				this.folderType = 'cart';
				break;
			case 'move':
				let myAssetLibraryMainFolder = new Folder(2, -1, "My Asset Library", new Array<Folder>(), null);
				myAssetLibraryMainFolder.MediaCategories = [ ...this.myLibFolders ]
				this.moveFolderDialogRef = this.dialog.open(MoveFolderComponent, {
					data: {
						folders: [myAssetLibraryMainFolder],
						movingFolder: folder
					}
				});
				this.moveFolderDialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
					if(resp) {
						const src = resp.source; const dest = resp.destination;
						this.dataService.updateFolder(this.user.userID, src.mediaCategoryID, dest.mediaCategoryID, src.mediaCategoryDesc).subscribe(r => {
							this.snackBar.open(`${src.mediaCategoryDesc} has been moved.`, 'OK', { duration: 3000 });
							
							//move folder to new parent
							const currentParent = this.findFolder(src.mediaCategoryID, src.type, this.myAssets).parent;
							const newParent = this.findFolder(dest.mediaCategoryID, dest.type, this.myAssets);
							currentParent.MediaCategories = currentParent.MediaCategories.filter(c => c.mediaCategoryID != src.mediaCategoryID)
							newParent.MediaCategories.push(src);
						});
					}
				})
				break;
			case 'remove':
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm Delete',
						message: 'Are you sure you want to delete this folder?'
					}
				});
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirmed => {
					if(confirmed) {
						this.dataService.removeFolder(this.user.userID, this.activeFolder.mediaCategoryID).subscribe(res => {
							this.activeFolder.parent.MediaCategories = this.activeFolder.parent.MediaCategories.filter(c => c.mediaCategoryID != this.activeFolder.mediaCategoryID);
							this.myAssets[0].removeFolderDeep(this.activeFolder);
							this.contents.LibraryItems = [];
							this.snackBar.open(`${this.activeFolder.mediaCategoryDesc} has been deleted.`, 'OK', { duration: 3000 });
						})
					}
				})
				break;
			case 'rename':
				this.renameFolderDialogRef = this.dialog.open(RenameFolderComponent, { data: { folder }});
				this.renameFolderDialogRef.afterClosed().pipe(take(1)).subscribe(updated => {
					this.dataService.updateFolder(this.user.userID, folder.mediaCategoryID, folder.parent.mediaCategoryID, updated.mediaCategoryDesc).subscribe(resp => {
						this.findFolder(updated.mediaCategoryID, updated.type, this.myAssets).mediaCategoryDesc = updated.mediaCategoryDesc;
						this.snackBar.open(`The folder has been renamed.`, 'OK', { duration: 3000 });
					});
				})
				break;
			case 'clearCart':
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm Delete',
						message: 'Are you sure you want to clear your cart?'
					}
				});
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirmed => {
					if(confirmed) {
						this.dataService.clearCart(this.activeCartId).subscribe(res => {
							this.contents.LibraryItems = [];  //reload cart
						})
					}
				})
				break;

			case 'deleteCart': 
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm Delete',
						message: 'Are you sure you want to delete this saved cart?'
					}
				});
				let cartIdToDelete = actionInfo.payload.folder.mediaCategoryID;
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirmed => {
					if(confirmed) {
						this.dataService.deleteCart(cartIdToDelete).subscribe((resp:any) => {
							if(resp.success) {
								this.cartAssets[0].MediaCategories = this.cartAssets[0].MediaCategories.filter(c => c.mediaCategoryID != cartIdToDelete);
								this.snackBar.open(`${actionInfo.payload.folder.mediaCategoryDesc} has been deleted.`, 'OK', { duration: 3000 });
							}
						})
						
					}
				})
			
			break;
			case 'saveCart':
				const saveCartId = actionInfo.payload.folder.mediaCategoryID;
				this.saveCartDialogRef = this.dialog.open(SaveCartComponent, {
					data: { 
						action: 'save'
					},
				});
		
				this.saveCartDialogRef.afterClosed().subscribe(name => {
					if(name) {
						this.dataService.saveCart(saveCartId, name).subscribe((resp:any) => {
							this.cartAssets[0].MediaCategories.push(new Folder(5, resp.newCartSessionID, name, null))
						})			
					}
				});
		
				break;
			case 'renameCart':
				const renameCartId = actionInfo.payload.folder.mediaCategoryID;
				const dialogRef = this.dialog.open(SaveCartComponent, {
					data: { 
						action: 'rename'
					},
				});
		
				dialogRef.afterClosed().subscribe(name => {
					if(name) {
						this.dataService.renameCart(renameCartId, name).subscribe((resp:any) => {
							this.cartAssets[0].MediaCategories = this.cartAssets[0].MediaCategories.map(c => {
								return c.mediaCategoryID == renameCartId ? Folder.create({ ...c, mediaCategoryDesc: name }) : c;
							})
						})	
					}		
				});
			
				break;
			case 'activateCart':
				const activateCartId = actionInfo.payload.folder.mediaCategoryID;
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Activate Cart',
						message: 'Items in your Cart will be replaced with items from the Saved Cart. Continue?'
					}
				});
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
					if(resp) {
						this.dataService.clearCart(this.mainCartId).subscribe(resp => {
							this.dataService.getCart(activateCartId).subscribe(items => {
								let addItems: Array<Observable<any>> = new Array<Observable<any>>()
								items.Medias.forEach(item => {
									addItems.push(this.dataService.addToCart(this.mainCartId, item.mediaItemID, item.mediaSourceID));
								});
								forkJoin(addItems).subscribe(resp => {
									//this.showCart();
								})
							})
						})
				
					}
				})
				break;
		}
	}
	fileAction(actionInfo: { actionName: string, payload: any }) {
		const file: LibraryItem = actionInfo.payload.file;
		switch(actionInfo.actionName) {
			case 'open':
				if(file.savedFileName) {
					//this isa regular asset
					window.open(file.savedFileName)
				} else {
					let wdw = window.open('/loading');
					this.dataService.createPdf(file.id).subscribe((resp: any) => {
						wdw.location.href = resp.url;
					})
				}
				break;
			case 'newWindow':
					window.open(file.savedFileName);
					break;
			case 'edit':
				this.router.navigate(['/module', file.moduleID, file.id]);
				break;
			case 'addcart':
					this.dataService.addToCart(this.mainCartId, file.id, (file.mediaSourceID || 3)).subscribe(c => {
						this.snackBar.open(`${file.shortDescription} has been added to your cart.`, 'OK', { duration: 3000 });
					})
				break;
			case 'copy':
					if (file.mediaSourceID == 3) {
						//if this is a needs analysis, don't ask which folder to save in
						this.dataService.copyMedia(this.user.userID, 3, file.id, -1).subscribe((resp:any) => {
							this.contents.LibraryItems.push(new LibraryItem({ ...file, mediaItemID: resp.newMediaID}));
						})
					} else {
						this.copyFileDialogRef = this.dialog.open(CopyComponent, {
							data: {
								folders: this.myLibFolders,
								action: 'Copy'
							}
						});
						this.copyFileDialogRef.afterClosed().pipe(take(1)).subscribe((folder:Folder) => {
							if(folder) {
								this.dataService.copyMedia(this.user.userID, file.mediaSourceID, file.id, folder.mediaCategoryID).subscribe((resp:any) => {
									if(file.mediaCategoryID == folder.mediaCategoryID) {
										//if you're copying it to the same folder....
										this.contents.LibraryItems.push(new LibraryItem({ ...file, mediaItemID: resp.newMediaID }));
									}
									this.snackBar.open(`${file.shortDescription} has been copied.`, 'OK', { duration: 3000 });
								});
							}
						})
					}
					
				break;
			case 'delete':
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm Delete',
						message: 'Are you sure you want to delete this file?'
					}
				});
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirmed => {
					if(confirmed) {
						this.dataService.deleteMedia(this.user.userID, file.mediaSourceID, file.id).subscribe(f => {
							this.contents.LibraryItems = this.contents.LibraryItems.filter(i => i.id != file.id);
							this.snackBar.open(`${file.shortDescription} has been deleted.`, 'OK', { duration: 3000 });
						})
					}
				})
				break;
			case 'removeCart': 
				let method = file.type == 'sync' ? 'removeFromCart' : 'removeFromCartAsync';
				this.dataService[method](this.activeCartId, file.id).subscribe(c => {
					this.contents.LibraryItems = this.contents.LibraryItems.filter(i => i.id != file.id);
					this.snackBar.open(`${file.shortDescription} has been removed from the cart.`, 'OK', { duration: 3000 });
				});
				break;
			case 'share':
				this.dataService.getUsers(this.user.orgID, this.user.userID).subscribe(resp => {
					this.shareFileDialogRef = this.dialog.open(ShareFileComponent, {
						data: {
							users: resp
						}
					})
					this.shareFileDialogRef.afterClosed().pipe(take(1)).subscribe(consultant => {
						if(consultant) {
							this.dataService.shareMedia(this.user.userID, file.mediaSourceID, file.id, consultant).subscribe((resp:any) =>{
								if(resp.success) {
									this.snackBar.open(`${file.shortDescription} has been shared.`, 'OK', { duration: 3000 });
								}
							});
						}
					})
				})
				break;
			case 'shareall':
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm',
						message: 'Your media will be shared will all active system users. Continue?'
					}
				});
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirmed => {
					if(confirmed) {
						this.dataService.shareMediaAll(this.user.userID, file.mediaSourceID, file.id).subscribe((resp:any) =>{
							if(resp.success) {
								this.snackBar.open(`${file.shortDescription} has been shared.`, 'OK', { duration: 3000 });
							}
						});
					}
				});
				break;
			case 'move':
				const moveFileDialogRef = this.dialog.open(CopyComponent, {
					data: {
						folders: this.myLibFolders,
						action: 'Move'
					}
				});
				moveFileDialogRef.afterClosed().pipe(take(1)).subscribe((folder:Folder) => {
					if(folder) {
						this.dataService.moveMedia(this.user.userID, file.id, folder.mediaCategoryID).subscribe(resp => {
							this.contents.LibraryItems = this.contents.LibraryItems.filter(i => i.id != file.id);
							this.snackBar.open(`${file.shortDescription} has been moved.`, 'OK', { duration: 3000 });
						});
					}
				})
		
				break;
			case 'rename': 
				this.dataService.renameFile(this.sessionService.getUser().userID, file.mediaSourceID, file.id, actionInfo.payload.newName).subscribe((resp:any) => {
					actionInfo.payload.onComplete.next(resp);
				});
				break;
			case 'showPreview':
				let wdw = window.open();
				this.dataService.createPdf(actionInfo.payload.mediaItemId).subscribe((res:any) => {
					wdw.location.href = res.url;
				});
				break;
			case 'routeTo':
				this.router.navigate(actionInfo.payload);
				break;

			case 'sortCart': 
				this.dataService.sortCart(this.activeCartId, actionInfo.payload).subscribe();
				break;

			case 'error':
				this.store.dispatch(new SetErrorMessage(actionInfo.payload))
		}
	}

	search(term: string) {
		this.dataService.search(this.user.userID, term).subscribe(res => {
			this.contents = new MediaContents(null, res, null, 5);
			this.activeFolder = null;
		});
	}

	personalize(): void {
		this.personalizeDialogRef = this.dialog.open(PersonalizeDialogComponent, {
			data: {
				mainCartId: this.mainCartId
			},
			maxHeight: '90vh',
			minWidth: 675
		});
		this.personalizeDialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
			if(this.activeFolder && resp == this.activeFolder.mediaCategoryID) {
				//resp is cart id if you chose to clear cart after (otherwise undefined)
				//if that equals active category id, clear cart
				this.contents.LibraryItems = [];
			}
		})
	}
	addFolder(): void {
		this.newFolderDialogRef = this.dialog.open(NewFolderComponent);
		this.newFolderDialogRef.afterClosed().pipe(take(1)).subscribe(name => {
			if(name) {
				this.dataService.saveFolder(this.user.userID, this.activeFolder.mediaCategoryID, name).subscribe((resp:any) => {
					this.activeFolder.MediaCategories.push(new Folder(this.activeFolder.type, resp.mediaCategoryID, name, new Array<Folder>(), this.activeFolder));
					this.findFolder(this.activeFolder.mediaCategoryID, this.activeFolder.type, this.myAssets).MediaCategories.push(new Folder(this.activeFolder.type, resp.mediaCategoryID, name, new Array<Folder>(), this.activeFolder));
				})
			}
		})
	}
	uploadFile(): void {
		this.uploadFileDialogRef = this.dialog.open(UploadFileComponent);
		this.uploadFileDialogRef.afterClosed().pipe(take(1)).subscribe((actionInfo: { action: string, payload: any }) => {
			if(actionInfo) {
				switch(actionInfo.action) {
					case 'upload':
							this.dataService.uploadMediaFile(this.user.userID, this.activeFolder.mediaCategoryID, actionInfo.payload.description, actionInfo.payload.file).subscribe((resp) => {
								this.loadFolderContents(this.activeFolder);

								//Mixpanel Track
								this.mixpanelService.trackAssetFileAdded();

								this.snackBar.open(`${actionInfo.payload.file.name} has been uploaded.`, 'OK', { duration: 3000 });
							});
						break;
					case 'uploadByUrl':
							this.dataService.uploadFileByUrl(this.user.userID, this.activeFolder.mediaCategoryID, actionInfo.payload.description, actionInfo.payload.url).subscribe((resp:any) => {
								this.loadFolderContents(this.activeFolder);

								//Mixpanel Track
								this.mixpanelService.trackAssetFileAdded();

								this.snackBar.open(`${actionInfo.payload.url} has been uploaded.`, 'OK', { duration: 3000 });
							})
						break;
				}
			}
		})
	}

	private loadFolderContents(folder: Folder) {
		let user:User = this.sessionService.getUser();
		let getContents:Observable<any>;
		switch (folder.type) {
			case 1:
				getContents = this.dataService.getSharedLibContents(user.orgID, folder.mediaCategoryID); this.folderType = 'shared'; break;
			case 2: 
				getContents = this.dataService.getUserLibContents(user.userID, folder.mediaCategoryID); this.folderType = 'userLib'; break;
			case 3:
				getContents = this.dataService.getModuleContents(user.userID, folder.mediaCategoryID); this.folderType = 'module'; break;
			case 4: 
				getContents = this.dataService.getSharedContents(user.userID, folder.mediaCategoryID); this.folderType = 'sharedWithMe'; break;

		}

		if(getContents) {
			getContents.subscribe((resp) => {
				resp.MediaCategories.sort(this.sortFn)
				this.contents = new MediaContents(resp.MediaCategories, (resp.Medias || resp.MediaUsers), resp.mediaCategoryID, folder.type);
				this.contents.LibraryItems.sort(this.sortFn);
				this.contents.LibraryItems.sort((a,b) => {
					if(a.sortOrder > b.sortOrder) { return 1; }
					if(a.sortOrder < b.sortOrder) { return -1; }
					return 0;
				})
			})
		}
		if(folder.type == 5) {
			forkJoin([this.dataService.getCart(folder.mediaCategoryID), this.dataService.getAsyncCart(folder.mediaCategoryID)]).subscribe((cartData:any) => {
				let items = cartData[0].Medias.map(i => new Media({...i, type: 'sync'}));
				items = [ ...items, ...cartData[1].Medias.map(i => new Media({...i, type: 'async'}))];
				this.contents = new MediaContents(items.MediaCategories, items, items.mediaCategoryID, 5);
				this.contents.LibraryItems.sort((a,b) => {
					if(a.sortOrder > b.sortOrder) { return 1; }
					if(a.sortOrder < b.sortOrder) { return -1; }
					return 0;
				})
			})

		}

	}

	private sortFn(a,b) {
		if(a.sortOrder > b.sortOrder) { return 1; }
		if(a.sortOrder < b.sortOrder) { return -1; }
		if(a.mediaCategoryDesc > b.mediaCategoryDesc) { return 1; }
		if(a.mediaCategoryDesc < b.mediaCategoryDesc) { return -1; }
		return 0;
	}
	private loadCartContents(cartId: string) {

		forkJoin([this.dataService.getCart(cartId), this.dataService.getAsyncCart(cartId)]).subscribe((cartData:any) => {
			let items = cartData[0].Medias.map(i => new Media({...i, type: 'sync', isCart: true}));
			items = [ ...items, ...cartData[1].Medias.map(i => new Media({...i, type: 'async', isCart: true, AllowDelete: true}))];
			this.contents = new MediaContents(items.MediaCategories, items, items.mediaCategoryID, 5);
			this.contents.LibraryItems.sort((a,b) => {
				if(a.sortOrder > b.sortOrder) { return 1; }
				if(a.sortOrder < b.sortOrder) { return -1; }
				return 0;
			})
		})
	}

	private getCartId$(userId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			this.dataService.getPreviousCart(userId).subscribe((resp:any) => {
				if(resp.recentSession) {
					resolve(resp.recentSession);
				} else {
					if(!this.sessionService.getCartId()) {
						this.dataService.startSession(userId).subscribe((c:any) => {
							resolve(c.cartSessionID);
						})
					} else {
						resolve(this.sessionService.getCartId());
					}
				}
			})
		})
	}
	private findFolder(parentId:number, typeId:number, folders:Array<Folder>):Folder {
		for(let folder of folders) {
			if(folder.mediaCategoryID == parentId && folder.type == typeId) {
				return folder;
			} else {
				if(folder.MediaCategories && folder.MediaCategories.length > 0){
					let found:Folder = this.findFolder(parentId, typeId, folder.MediaCategories);
					if(found) {
						return found;
					} 
				}
			}
		}
		return null; 
	}
}
