import { Component, OnInit, ViewChild } from "@angular/core";
import { ProfilePhotoComponent, DialogComponent, tooltips, ConfirmComponent } from "acc-common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DataService, SessionService } from "acc-services";
import { IAppState } from "../../store/state/app.state";
import { Store } from "@ngrx/store";
import { CustomValidators } from "../../validators/custom-validators";
import { User, ProfilePhoto } from "acc-models";
import { SetErrorMessage } from "../../store/actions/status.actions";
import { of, forkJoin, Observable, defer } from "rxjs";
import { SignupCompany } from "projects/acc-models/src/lib/models/signUp";
import { take, tap } from "rxjs/operators";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RootReset } from "../../store/actions/root.actions";
import { OrgProfileComponent } from "../../shared/org-profile/org-profile.component";
import { ICanNavigateAway } from "../../interfaces/can-navigate-away.interface";

@Component({
	selector: "acc-main-company",
	templateUrl: "./company.component.html",
	styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit, ICanNavigateAway {
	public user: User;
	public logo;
	@ViewChild("logoPhoto", { static: true }) logoPhoto: ProfilePhotoComponent;
	@ViewChild("orgProfile") orgProfile: OrgProfileComponent;
	companyFormGroup: FormGroup;
	photoID: string;
	showUrlChangedMessage: boolean = false;
	private dialogRef: MatDialogRef<DialogComponent>;

	public imageFileTypes = [".jpg", ".jpeg", ".png", ".gif"];

	private stripeCustomerID;
	private subscriptionLevelID;
	private subscriptionLevelDesc;
	public tooltips = tooltips;

	public formIsDirty: boolean = false;

	constructor(private fb: FormBuilder, private dataService: DataService, private sessionService: SessionService, private snackBar: MatSnackBar, private dialog: MatDialog, private store: Store<IAppState>) {
		this.user = this.sessionService.getUser();
	}

	ngOnInit() {
		this.initForms();
		this.getCompanyInformation();
		this.store.dispatch(new RootReset());
	}

	initForms() {
		this.companyFormGroup = this.fb.group({
			CompanyName: [null, Validators.required],
			ResourceName: [
				null,
				Validators.compose([
					// 1. Resource Field is Required
					Validators.required,
					// 2. check whether the entered text has no spaces
					CustomValidators.patternValidator(/^\S*$/, { hasSpace: true }),
					// 3. Has a max length of 100 characters
					Validators.maxLength(100),
				]),
			],
		});
	}

	preventChars(e, allowSpace = false) {
		//if (event.which === 32) event.preventDefault();
		var NOT_ALLOWED_CHARS_REGEXP = /[^a-zA-Z0-9 ]/;
		if (NOT_ALLOWED_CHARS_REGEXP.test(e.key) || (!allowSpace && e.key == " ")) {
			event.preventDefault();
		}
	}

	getCompanyInformation() {
		this.dataService.getCompanyInformation(this.user.orgID).subscribe(
			(resp: any) => {
				this.companyFormGroup.controls.CompanyName.setValue(resp.orgDesc);
				this.companyFormGroup.controls.ResourceName.setValue(resp.resource);

				this.stripeCustomerID = resp.stripeCustomerID;
				this.subscriptionLevelID = resp.subscriptionLevelID;
				this.subscriptionLevelDesc = resp.subscriptionLevelDesc;

				this.photoID = resp.photoID;
				this.logoPhoto.photo = resp.photo;
				this.logoPhoto.update();
			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access to SignUp service"));
			}
		);
	}

	submitForm() {
		if (this.companyFormGroup.valid) {
			var company = new SignupCompany();
			company.orgDesc = this.companyFormGroup.value["CompanyName"];
			company.resource = this.companyFormGroup.value["ResourceName"];
			company.photoID = this.photoID;
			const logoUpload = this.logoPhoto.getData();

			company.stripeCustomerID = this.stripeCustomerID;
			company.subscriptionLevelID = this.subscriptionLevelID;
			company.subscriptionLevelDesc = this.subscriptionLevelDesc;

			let observableBatch = [];
			observableBatch.push(of(true)); //just get something in there, in case there are no images to save

			if (logoUpload.file) {
				//save logo image file
				observableBatch.push(
					this.dataService.uploadProfileImage(logoUpload.file, logoUpload.photo).pipe(
						tap((img: ProfilePhoto) => {
							company.photoID = img.imageID;
							company.photo = img;
						})
					)
				);
			} else {
				if (logoUpload.photo) {
					observableBatch.push(this.dataService.updateProfileImage(logoUpload.photo));
				} else {
					company.photoID = null;
				}
			}
			observableBatch.push(defer(() => this.orgProfile.save()));
			forkJoin(observableBatch).subscribe((resp: any) => {
				this.dataService.saveCompanyInformation(this.user.orgID, company).subscribe(
					(org: any) => {
						this.snackBar.open("Your company has been updated!", "OK", { duration: 3000 });
						this.formIsDirty = false;
						
						if (!!this.showUrlChangedMessage) {
							setTimeout(() => {
								this.dialogRef = this.dialog.open(DialogComponent, {
									data: {
										messages: ["The URL to access your site has changed.", "Please be sure to notify your users of this new location."],
										title: "Company URL changed",
									},
									panelClass: "welcome-dialog-container",
								});
							}, 200);
							this.showUrlChangedMessage = false;
						}

						this.sessionService.updateOrganization(org);
					},
					(err: any) => {
						this.store.dispatch(new SetErrorMessage(err.error.Message));
					}
				);
			});
		}
	}

	showCompanyUrlMessage() {
		this.showUrlChangedMessage = true;
	}


	isDirty(): boolean {
		return this.formIsDirty || this.orgProfile.isDirty();
	}

	canNavigateAway(): Observable<boolean> | boolean {
		const confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `Are you sure you want to exit without saving?`,
			},
		});
		return confirmDialogRef.afterClosed().pipe(take(1));
	}

}
