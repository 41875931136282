
import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { DataService, SessionService, DocGenService, QuoteService } from 'acc-services';
import { NavigationComponent } from '../editor/navigation/navigation.component';
import { FormComponent } from '../editor/form/form.component';

import { Document, DocumentOutline, SubCategory, QuoteRequest, ProposalTemplate } from 'acc-models';
import { SubscriptionLike, forkJoin } from 'rxjs';
import { switchMap, tap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import { SetQuoteRequest } from '../../../store/actions/quote.actions';
import { SetErrorMessage } from '../../../store/actions/status.actions';
import { MatDialog } from '@angular/material/dialog';
import { TemplateNameComponent } from './template-name/template-name.component';
import { PersonalizeDialogComponent } from 'acc-common';

@Component({
	selector: 'acc-main-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss']
  })
export class ContainerComponent implements OnInit, OnDestroy {
	private watchParams:any;
	private moduleID:number;
	private moduleTypeId: number;
	private document: Document
	private docOutline: DocumentOutline;
	private documentID:string;

	public detailsSaved: boolean = false;
	private currSubCategory:SubCategory;
	private getDocumentSubscription:SubscriptionLike;
	private getDocOutlineSubscription: SubscriptionLike;

	@ViewChild(NavigationComponent) private navigationComponent:NavigationComponent;
	@ViewChild(FormComponent) public moduleFormComponent:FormComponent;

	public get dirty() { return this.moduleFormComponent.dirty;}

    constructor (
		private dataService:DataService, 
		private sessionService: SessionService, 
		private router: Router, 
		private route:ActivatedRoute, 
		private location:Location, 
		private docGenService:DocGenService, 
		private store: Store<IAppState>,
		private quoteService: QuoteService,
		private dialog: MatDialog
	) { }
	
	ngOnInit():void {
		this.watchParams = this.route.paramMap.subscribe(params => {
			this.moduleID = +params.get('moduleID');
			this.documentID = params.get('documentID');
			const mode = params.get('mode');

			if(this.documentID) {
				this.getDocumentSubscription = this.dataService.getDocument(this.documentID).subscribe((doc:any) => {
					this.document = doc;
					this.getDocOutlineSubscription = this.dataService.getDocumentOutline(this.documentID).subscribe((resp: any) => {
						this.docOutline = resp;
					})
					if(mode == 'edit' || mode == 'template') {
						this.detailsSaved = true;
					}
				})
			}
			this.dataService.getModules(this.sessionService.getUser().orgID).subscribe((resp:any) => {
				this.moduleTypeId = resp.filter(m => m.moduleID == this.moduleID)[0].moduleTypeID
			});
		});
		this.docGenService.currentDocument$.subscribe(doc => {
			this.document = doc;
		})
		 
	}

	docSaved(doc):void {
		this.detailsSaved = true;
		this.location.replaceState(`/module/${this.moduleID}/${this.document.docID}/edit`);
		
	}
	doAction(action: {actionName: string, quoteId?: string}): void {
		switch(action.actionName) {
			case 'goBack': 
				this.moduleFormComponent.onGoBack();
				break;
			case 'editOutline':
				this.moduleFormComponent.editOutline();
				break;
			case 'close':
				this.moduleFormComponent.close();
				break;
			case 'revert':
				this.moduleFormComponent.revert();
				break;
			case 'preview':
				this.moduleFormComponent.preview();
				break;
			case 'save':
				this.moduleFormComponent.save();
				break;
			case 'continue':
				this.moduleFormComponent.continue();
				break;
			case 'send':
				this.moduleFormComponent.save().then(() => {
					let cartId: string;
					const userId = this.sessionService.getUser().userID;
					this.dataService.getPreviousCart(userId).subscribe((session:any) => {
						cartId = session.recentSession;
						let resetCart = (cId) => this.dataService.clearCart(cId).pipe(
							switchMap(() => forkJoin(
								this.dataService.addToCart(cId, this.document.docID, 3),
							))
						)
						
						if(cartId) {
							resetCart(cartId).subscribe(() => {
								this.dialog.open(PersonalizeDialogComponent, { data: { mainCartId: cartId, quoteId: action.quoteId ? action.quoteId : null }, minWidth: 675 })
								//this.router.navigateByUrl(`/module/send${action.quoteId ? '/' + action.quoteId : ''}`);
							})
						} else {
							this.dataService.startSession(userId).subscribe((c:any) => {
								cartId = c.cartSessionID;
								resetCart(cartId).subscribe(() => {
									this.dialog.open(PersonalizeDialogComponent, { data: { mainCartId: cartId, quoteId: action.quoteId ? action.quoteId : null }, minWidth: 675 })
									//this.router.navigateByUrl(`/module/send${action.quoteId ? '/' + action.quoteId : ''}`);
								})
							})							
						}
						
					})
				})
				break;
			case 'quote':
				this.quoteService.getIPipelineJson(this.document.quoteID).subscribe(
					(resp: QuoteRequest) => {
						if (resp) {
							this.store.dispatch(new SetQuoteRequest(resp));
							this.router.navigateByUrl("/quote/results");
						} else {
							this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
						}
					},
					(err: any) => {
						this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
					}
				);
				break;
			case 'template':
				this.moduleFormComponent.save().then(() => {
					const dialogRef = this.dialog.open(TemplateNameComponent, { data: { defaultName: this.document.docDesc }});
					dialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
						if(resp) {
							const template = new ProposalTemplate({
								docID: this.document.docID,
								templateTypeID: 1,
								templateDesc: resp.templateName
							});
							this.dataService.saveTemplate(template).subscribe(resp => {
								//this.location.replaceState(`/module/${this.moduleID}/${this.document.docID}/template`);
								this.router.navigateByUrl(`/module/${this.moduleID}/${this.document.docID}/template`);
							});
							
						}
					})
				})
		}
	}
	subCatSelected(subcat:SubCategory):void {
		this.currSubCategory = subcat;
	}
	goToNextCategory(subCat:SubCategory):void {
		this.navigationComponent.goToNextCategory(subCat);
		window.scroll(0,0);
	}
    ngOnDestroy():void {
		this.watchParams.unsubscribe();
		if(this.getDocumentSubscription) { this.getDocumentSubscription.unsubscribe(); }
 		if(this.getDocOutlineSubscription) { this.getDocOutlineSubscription.unsubscribe(); }
   }
    
}
