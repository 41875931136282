import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmComponent } from "acc-common";
import { DocBuilderItem } from "acc-models";
import { DataService, SessionService } from "acc-services";
import { take } from "rxjs/operators";

@Component({
	selector: "acc-main-docbuilder-admin",
	templateUrl: "./docbuilder-admin.component.html",
	styleUrls: ["./docbuilder-admin.component.scss"],
})
export class DocbuilderAdminComponent implements OnInit {
	public docbuilderItems: Array<DocBuilderItem> = [];
	private docBuilderID: number;
	constructor(private dataService: DataService, private sessionService: SessionService, private snackbar: MatSnackBar, private dialog: MatDialog) {}

	ngOnInit() {
		this.dataService.getDocBuilder(this.sessionService.getUser().orgID).subscribe((resp: any) => {
			this.docBuilderID = resp.docBuilderID;
			this.docbuilderItems = resp.docBuilderItems.sort((a, b) => a.sortOrder - b.sortOrder);
		});
	}
	change(item) {
		item.imageID = String(new Date());
	}
	fileChanged(f: File, item: DocBuilderItem) {
		item.file = f;
	}

	save(item: DocBuilderItem) {
		if (item.file) {
			this.dataService.saveDocBuilderImage(item.file).subscribe((dgi: any) => {
				item.imageID = dgi.imageID;
				this.doSave(item);
				item.isDirty = false;
			});
		} else {
			this.doSave(item);
			item.isDirty = false;
		}
	}

	addNew() {
		this.docbuilderItems.push(new DocBuilderItem({ DocBuilderID: this.docBuilderID, sortOrder: this.docbuilderItems.length }));
	}

	delete(item: DocBuilderItem) {
		const deleteDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm Delete",
				message: `Are you sure you want to delete this item?`,
			},
		});
		deleteDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((confirm) => {
				if (confirm) {
					if(item.DocBuilderItemID == undefined) {
						this.docbuilderItems = this.docbuilderItems.filter((i) => i.DocBuilderItemID !== undefined);
					} else {
						this.dataService.deleteDocBuilderItem(item.DocBuilderItemID).subscribe(() => {
							this.docbuilderItems = this.docbuilderItems.filter((i) => i.DocBuilderItemID !== item.DocBuilderItemID);
						});
					}
				}
			});
	}

	drop(event) {
		moveItemInArray(this.docbuilderItems, event.previousIndex, event.currentIndex);
		this.docbuilderItems.forEach((dbi: DocBuilderItem, i: number) => {
			dbi.sortOrder = i;
			dbi.alwaysInDoc = dbi.alwaysInDoc ? 1 : 0;
			this.dataService.saveDocBuilderItem(dbi).subscribe();
		});
	}
	private doSave(item: DocBuilderItem) {
		item.alwaysInDoc = item.alwaysInDoc ? 1 : 0;
		this.dataService.saveDocBuilderItem(item).subscribe((resp: any) => {
			if (resp.DocBuilderItemID) {
				item.DocBuilderItemID = resp.DocBuilderItemID;
			}
			this.snackbar.open("Item saved", "Close", { duration: 3000 });
		});
	}

	canDeactivate() {
		let isDirty: boolean = false;
		isDirty = this.docbuilderItems.filter(i => i.isDirty).length > 0;
		if(isDirty) {
			const dialogRef = this.dialog.open(ConfirmComponent, { data: { message: "You have unsaved changes.  Are you sure you want to leave this page?"}});
			return dialogRef.afterClosed();
		} else {
			return true;
		}
	}
}
