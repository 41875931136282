

	<form>
		<div class="errorMessage" *ngIf="errorMessage">{{errorMessage}}</div>
		<ul>
			<li>
				<mat-form-field appearance="outline">
					<mat-label>Username</mat-label>
					<input matInput [formControl]="usernameField" name="username" id="username" type="text" autocomplete="username">
					<mat-icon matSuffix>mail</mat-icon>
					<mat-error *ngIf="usernameField.hasError('required')">Username is required</mat-error>
				</mat-form-field>
			</li>
			<li>
				<mat-form-field appearance="outline">
					<mat-label>Password</mat-label>
					<input type="password" matInput [formControl]="passwordField" name="password" id="password" autocomplete="current-password" />
					<mat-icon matSuffix>vpn_key</mat-icon>
					<mat-error *ngIf="passwordField.hasError('required')">Password is required</mat-error>
				</mat-form-field>
			</li>
			<li><p><a (click)="navigateTo('recover')">Forgot password? </a></p></li>
			<li><button (click)="login()" mat-flat-button color="primary">Log In</button></li>
		</ul>
	</form>
