import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { timer, Subject, of } from "rxjs";
import { take, catchError, takeUntil } from "rxjs/operators";

@Component({
	selector: "acc-common-video-dialog",
	templateUrl: "./video-dialog.component.html",
	styleUrls: ["./video-dialog.component.scss"],
})
export class VideoDialogComponent implements OnInit {
	public videoUrl: string;
	public fires: number = 0;
	public stop$: Subject<number> = new Subject();
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit() {
		this.data.videoUrl$
			.pipe(
				catchError(() => {
					this.videoUrl = "";
					this.stop$.next(0);
					return of('')
				})
			)
			.subscribe((url) => (this.videoUrl = url));

		timer(12000, 30000)
			.pipe(take(2), takeUntil(this.stop$))
			.subscribe((i) => (this.fires = i + 1));
	}
}
