import { Component, OnInit, ViewChild } from "@angular/core";
import { QuoteService, DataService } from "acc-services";
import { ActivatedRoute } from "@angular/router";
import { Profile } from 'acc-models';
import { environment } from 'projects/acc-main/src/environments/environment';

@Component({
	selector: "acc-main-quote-video",
	templateUrl: "./quote-video.component.html",
	styleUrls: ["./quote-video.component.scss"],
})
export class QuoteVideoComponent implements OnInit {
	public videoUrl: string;
	public proposalUrl: string;
	public agent: Profile;
	public showOverlay: boolean = false;

	public carrierVideos: string[] = [];
	private currentVideoIndex = 0;
	public youTubePlayer: YT.Player;
	public youTubeVideoID = null;
	public showYouTubeVideoPlayer = false;

	@ViewChild('videoPlayer') videoPlayer
	constructor(private quoteService: QuoteService, private route: ActivatedRoute, private dataService: DataService) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			let userId = params.get("userId");
			let profileId = params.get("profileId");
			let videoParam = params.get("videoUrl");
			let quoteId = params.get("quoteId");
			this.videoUrl = environment.quote_video_url.replace("*id*", videoParam);
			this.proposalUrl = environment.quote_pdf_output.replace('*id*', quoteId);
			
			this.dataService.getProfileDetail(profileId).subscribe(p => {
				this.agent = new Profile(p);
			});

			this.quoteService.getQuoteCarrierVideos(quoteId).subscribe((v:any) => {
				this.carrierVideos = v;
			});

		});
	}

	timeUpdate(e) {
		if(this.videoPlayer.nativeElement.currentTime > 26) {
			this.showOverlay = true
		} else {
			this.showOverlay = false;
		}
	}

	formatPhone(phone: string) {
		if(phone){
			return `(${phone.substr(0,3)}) ${phone.substr(3,3)}-${phone.substr(6)}`
		}
	}

	videoEnded(){
		//Check for next Video
		this.playNextVideo();
	}

	playNextVideo(){
		var video = this.getNextVideo();
		if(video != null){
			this.showOverlay = false;
			var youTubeID = this.isFromYouTube(video);
			if(youTubeID){
				this.youTubeVideoID = youTubeID;
				this.showYouTubeVideoPlayer = true;
				setTimeout( () => { 
					if(this.youTubePlayer != null){
						this.youTubePlayer.loadVideoById(this.youTubeVideoID);
						this.youTubePlayer.playVideo(); 
					}
				}, 
				1000 );
			}else if(this.isAPlayableVideo(video)) {
				this.showYouTubeVideoPlayer = false;
				this.youTubeVideoID = null;
				this.videoUrl = video;
				if(this.videoPlayer != null){
					this.videoPlayer.nativeElement.src = video;
					this.videoPlayer.nativeElement.play();
				}
			}
		}
	}

	getNextVideo(){
		var videoUrl = null;

		if(this.currentVideoIndex >= this.carrierVideos.length){
			return null;
		}
		var i = 0;
		while (videoUrl == null && i < this.carrierVideos.length) {
			i = this.currentVideoIndex;
			this.currentVideoIndex++;
			videoUrl = this.carrierVideos[i];
		}
		return videoUrl;
	}

	isFromYouTube(url){
		if (url != undefined || url != '') {        
			var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
			var match = url.match(regExp);
			if (match && match[2].length == 11) {
				return match[2];
			} else {
				return false;
			}
		}
	}

	isAPlayableVideo(url){
		return(!url.includes('https://www.youtube.com'));
	}


	savePlayer(player) {
		this.youTubePlayer = player;
		this.youTubePlayer.playVideo();
		//console.log('player instance', player);
	}

	onStateChange(event) {
		//console.log('player state', event.data);
		var state = event.data;
		if(state == 0){
			this.playNextVideo();
		}
	}



}
