import { Component, OnInit, HostListener, ElementRef, OnDestroy, ViewEncapsulation } from "@angular/core";
import { SessionService, DataService } from "acc-services";
import { SentItem, SentItemActivity } from 'acc-models';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: "acc-main-sent-items",
	templateUrl: "./sent-items.component.html",
	styleUrls: ["./sent-items.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SentItemsComponent implements OnInit, OnDestroy {
	public startDate: Date ;
	public endDate: Date;
	public outbox: Array<SentItem>;
	public rawData: Array<SentItem>;
	public selectedMessage: SentItem;
	public selectedIndex: number;
	public sortBy: 'toEmail' | 'emailSubject' | 'sentDateTime' = 'sentDateTime';
	public sortAsc: boolean = true; //because on load will flip this
	private destroy$: Subject<boolean> = new Subject();
	private setSelectedMessage$: Subject<{msg: SentItem, index: number}> = new Subject();
	public activity: Array<SentItemActivity>;
	private userId: string;
	public emailFilter: string = '';
	public subjectFilter: string = '';

	@HostListener('document:keydown', ['$event']) onkeyup($event) {
		if(this.selectedIndex < 0 || this.selectedIndex >= this.outbox.length) { return; }
		switch($event.keyCode) {
			case 38:
				this.moveSelection(-1); $event.preventDefault();
				break;
			case 40:
				this.moveSelection(1); $event.preventDefault();
				break;
		}
	}
	constructor(private sessionService: SessionService, private dataService: DataService, private route: ActivatedRoute, private router:Router) {}

	ngOnInit() {
		this.userId = this.sessionService.getUser().userID;
		this.endDate = new Date();
		this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 30);
		this.getSentItems();
		this.setSelectedMessage$.pipe(takeUntil(this.destroy$)).subscribe(data => {
			this.selectedMessage = data.msg;
			this.selectedIndex = data.index;
			if(data.msg != null){
				this.dataService.getSentItemActivity(this.userId, data.msg.cartDeliveryID).subscribe((resp: any) => {
					this.activity = resp;
				})
			}
		})
	}

	getSentItems() {
		this.dataService.getSentItems(this.userId, moment(this.startDate).format("MM/DD/YYYY"), moment(this.endDate).format("MM/DD/YYYY")).subscribe((resp: any) => {
			this.outbox = resp;
			this.rawData = resp;
			this.sort();
			this.route.params.pipe(take(1), takeUntil(this.destroy$)).subscribe((data) => {
				if (data["deliveryId"]) {
					const msg = resp.filter(m => m.cartDeliveryID == data['deliveryId'])[0];
					const index = resp.findIndex(m => m.cartDeliveryID == data.deliveryId)
					this.setSelectedMessage$.next({msg, index});
				}
			});
		})
	}

	sort(field?: 'toEmail' | 'emailSubject' | 'sentDateTime') {
		const newSortBy = field || this.sortBy;
		this.sortAsc = (newSortBy == this.sortBy ? !this.sortAsc : true);  //if same field, toggle sort order, else set ascending
		const sortFactor = this.sortAsc ? 1 : -1;
		this.outbox.sort((a,b) => {
			if(a[newSortBy] > b[newSortBy]) return 1 * sortFactor;
			if(a[newSortBy] < b[newSortBy]) return -1 * sortFactor;
			return 0;
		});
		this.sortBy = newSortBy;
	}
	selectMessage(msg: SentItem, index:number) {
		this.setSelectedMessage$.next({msg, index})
		if(msg != null){
			this.router.navigate(['/sent-items', msg.cartDeliveryID], { relativeTo: this.route })
		}
	}
	moveSelection(count) {
		this.setSelectedMessage$.next({msg: this.outbox[this.selectedIndex], index: this.selectedIndex + count});
		//this.scrollIntoViewIfNeeded(document.getElementsByClassName('active')[0]);
		document.getElementsByClassName('active')[0].scrollIntoView(false);
	}

	scrollIntoViewIfNeeded(target) { 
		if (target.getBoundingClientRect().top > target.parentElement.getBoundingClientRect().bottom) {
			target.scrollIntoView(false);
		}
	
		if (target.getBoundingClientRect().top < target.parentElement.getBoundingClientRect().top) {
			target.scrollIntoView();
		} 
	}

	filter() {
		this.outbox = this.rawData.filter(e => e.toEmail.toLocaleLowerCase().includes(this.emailFilter.toLocaleLowerCase()) && e.emailSubject.toLocaleLowerCase().includes(this.subjectFilter.toLocaleLowerCase()))
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
