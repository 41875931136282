export class Category {
	public categoryID: number;
	public categoryDesc: string;
	public SubCategories: Array<SubCategory>;
	public foundInSearch:boolean = false;
	public sortOrder: number;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export class SubCategory {
	public subCategoryID: number;
	public subCategoryDesc: string;
	public foundInSearch:boolean = false;
	public isCustom: boolean;
	public categoryID: number;
	public sortOrder: number;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}