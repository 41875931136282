export class Document {
	public docID: string;
	public userID: string;
	public moduleID: number;
	public consultant2: string;
	public consultant2Primary: boolean;
	public docDesc: string;
	public docCreated: Date;
	public prospectName: string = '';
	public prospectLogoFile: string;
	public layoutNum: number;
	public companyPhoto: any;
	public crmAccountID:string;
	public displayDate: Date
	public quoteID: string;

	public get link():string { 
		return `/module/${this.moduleID}/${this.docID}`;
	}

	constructor(o?:any) {
		Object.assign(this,o);
	}

}

export class DocumentOutline {
	public docID: string;
	public outlineDepth: number;
	public categoryDivider: boolean;
	public subCategoryDivider: boolean;
	public categoryListTypeID: number;
	public subCategoryListTypeID: number;
	public subCatTextListTypeID: number;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export class ListType {
	public listTypeID: number;
	public listTypeDesc: string;
	public listTypeDescLong: string;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}