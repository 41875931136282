
  <mat-card >
    <mat-card-header>
      <mat-card-title>Company Profile</mat-card-title>
    </mat-card-header>

    <mat-card-content >
		<div #companyInfo>
			<ng-content></ng-content>
		</div>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput type="text" [formControl]="emailField" />
        </mat-form-field>
  
		<mat-form-field>
			<mat-label>Phone</mat-label>
			<input matInput placeholder="Main Phone" [formControl]="phoneField" [mask]="phoneMask" [showMaskTyped]="true"/>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Website</mat-label>
			<input matInput [formControl]="websiteField"/>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Address 1</mat-label>
			<input matInput [formControl]="address1Field"/>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Address 2</mat-label>
			<input matInput [formControl]="address2Field"/>
		</mat-form-field>

		<mat-form-field>
			<mat-label>City</mat-label>
			<input matInput [formControl]="cityField"/>
		</mat-form-field>

		<mat-form-field>
			<mat-label>State</mat-label>
			<input matInput [formControl]="stateField"/>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Zip</mat-label>
			<input matInput [formControl]="zipField"/>
		</mat-form-field>


    </mat-card-content>
  </mat-card>