import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from "@angular/core";
import { QuoteService, SessionService, DataService } from "acc-services";
import { select, Store } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import { SetErrorMessage } from "../../store/actions/status.actions";
import { UserQuote } from "projects/acc-models/src/lib/models/userQuote";
import { QuoteRequest } from "projects/acc-models/src/lib/models/quoteRequest";
import * as moment from "moment";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmComponent, PersonalizeDialogComponent, tooltips } from "acc-common";
import { take, flatMap, tap, mergeMap, concatMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { SetQuoteRequest, SetHealthProfile, SetHealthProfileAlt, SetSelections, SetRecSort, SetSelectedPlans, SetSellingPoints, ClearTemporaryQuoteData } from "../../store/actions/quote.actions";
import { ProposalTemplate, HealthProfile, Organization, HealthProfileAlt } from "acc-models";
import { HealthDetailsAttributes } from "projects/acc-models/src/lib/models/healthDetailsAttributes";
import { CurrencyPipe } from "@angular/common";
import { iif } from "rxjs";
import { LockFeaturesComponent } from "../../components/lock-features/lock-features.component";
import { MatMenuTrigger } from "@angular/material/menu";
import { DocbuilerOptionsComponent } from "../../components/shared/docbuiler-options/docbuiler-options.component";

@Component({
	selector: "acc-main-quote-list",
	templateUrl: "./quote-list.component.html",
	styleUrls: ["./quote-list.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class QuoteListComponent implements OnInit {
	myQuotes: Array<UserQuote>;
	myDisplayQuotes: Array<UserQuote>;
	searchFilter: string;
	sortCriteria: string = "20";
	private deleteDialogRef: MatDialogRef<ConfirmComponent>;
	public templates: Array<ProposalTemplate>;
	public tooltips = tooltips;
	public lockedFeaturesDialogRef: MatDialogRef<LockFeaturesComponent>;
	public sourceId: number = 0;
	@Input("matMenuTrigger") menuTrigger: MatMenuTrigger;
	@ViewChild("copyMenuTrigger") copyMenuTrigger: MatMenuTrigger;

	constructor(private quoteService: QuoteService, private store: Store<IAppState>, private sessionService: SessionService, private dataService: DataService, private dialog: MatDialog, private router: Router, private currencyPipe: CurrencyPipe, private snackBar: MatSnackBar) {}

	ngOnInit() {
		this.getData();
		this.store.dispatch(new ClearTemporaryQuoteData()); //clear out any stored selections from previous views
	}

	searchQuotes() {
		if (this.searchFilter != undefined) {
			this.getFilteredQuotes(this.searchFilter);
		} else {
			this.getData();
		}
	}

	getData() {
		this.quoteService.getUserQuotes(this.sessionService.getUser().userID).subscribe(
			(resp: Array<UserQuote>) => {
				if (resp) {
					this.myQuotes = this.sortUserQuotes(this.sortCriteria, resp);
					this.myDisplayQuotes = this.myQuotes.filter(q => this.sourceId == 0 ? true : q.sourceID == this.sourceId);
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
				}
			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
			}
		);
		this.quoteService.getTemplates(this.sessionService.getUser().orgID).subscribe((resp: any) => (this.templates = resp));
	}

	getFilteredQuotes(filter) {
		this.quoteService.getUserQuotesFiltered(this.sessionService.getUser().userID, filter).subscribe(
			(resp: Array<UserQuote>) => {
				if (resp) {
					this.myQuotes = this.sortUserQuotes(this.sortCriteria, resp);
					this.myDisplayQuotes = this.myQuotes.filter(q => this.sourceId == 0 ? true : q.sourceID == this.sourceId);
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
				}
			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
			}
		);
	}

	clearFilter(value) {
		if (value == "") {
			this.searchFilter == undefined;
			this.getData();
		}
	}

	sortingChanged(change) {
		this.myQuotes = this.sortUserQuotes(this.sortCriteria, this.myQuotes);
		this.myDisplayQuotes = this.myQuotes.filter(q => this.sourceId == 0 ? true : q.sourceID == this.sourceId);
	}

	sortUserQuotes(criteria, quotes): UserQuote[] {
		var sorted: UserQuote[] = [];
		switch (criteria) {
			case "10": {
				sorted = quotes.sort((a, b) => (a.dateEdited >= b.dateEdited ? 1 : -1));
				break;
			}
			case "20": {
				sorted = quotes.sort((a, b) => (a.dateEdited < b.dateEdited ? 1 : -1));
				break;
			}
			case "30": {
				sorted = quotes.sort((a, b) => (a.highestFaceAmt < b.highestFaceAmt ? 1 : -1));
				break;
			}
			case "40": {
				sorted = quotes.sort((a, b) => (a.highestFaceAmt >= b.highestFaceAmt ? 1 : -1));
				break;
			}
			case "50": {
				sorted = quotes.sort((a, b) => (a.clientName < b.clientName ? 1 : -1));
				break;
			}
			case "60": {
				sorted = quotes.sort((a, b) => (a.clientName >= b.clientName ? 1 : -1));
				break;
			}
			default: {
				sorted = quotes;
				break;
			}
		}
		return sorted;
	}

	getFormat(date) {
		return moment(date).format("MM/DD/YYYY");
	}

	fromNow(date) {
		return moment(date).fromNow();
	}

	getBoxes(element) {
		var boxes = [];
		element.terms.forEach((terms) => {
			boxes.push(terms.term === 1 ? terms.term + " year" : terms.term + " years");
		});
		element.faceAmounts.forEach((f) => {
			boxes.push(this.currencyPipe.transform(f.faceAmount, "USD", "symbol", "1.0-0"));
		});

		return boxes;
	}

	deleteQuote(quoteId): void {
		this.deleteDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `Are you sure you want to delete this quote?`,
			},
		});
		this.deleteDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((confirm) => {
				if (confirm) {
					this.quoteService.deleteQuote(quoteId).subscribe(
						(resp: any) => {
							this.getData();
						},
						(err: any) => {
							this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
						}
					);
				}
			});
	}


	editQuote(quoteId) {
		let qr: QuoteRequest;
		this.quoteService
			.getIPipelineJson(quoteId)
			.pipe(
				tap((resp: QuoteRequest) => (qr = resp)),
				mergeMap((resp) => this.quoteService.getHealthProfileAttributes(qr.inputs[0].quoteID)),
				tap((attr: HealthDetailsAttributes) => {
					if (attr) {
						this.store.dispatch(new SetHealthProfile(HealthProfile.fromHealthDetailsAttributes(attr)));
					}
				})
			)
			.subscribe(
				(resp: any) => {
					//Get Health Profile Alt
					this.quoteService.getHealthProfileAlt(quoteId).subscribe((hpAlt: HealthProfileAlt) =>{
						this.store.dispatch(new SetHealthProfileAlt(hpAlt));
						if (qr) {
							this.store.dispatch(new SetQuoteRequest(qr));
							this.router.navigateByUrl("/quote?mq=true");
						} else {
							this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
						}

					},
					(err: any) => {
						this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
					});
				},
				(err: any) => {
					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
				}
			);
	}

	sendProposal(quoteId, includeProposal: boolean) {
		const userId = this.sessionService.getUser().userID;
		let cartId: string;
		this.dataService.getPreviousCart(userId).pipe( //get cart id
			tap((c: any) => (cartId = c.recentSession)),
			flatMap((c: any) =>
				iif(
					() => cartId !== "", //if you have a cart id,
					this.dataService.clearCart(cartId), //clear it out
					this.dataService.startSession(userId).pipe(tap((c: any) => (cartId = c.cartSessionID))) //otherwise, start new session
				)
			)
		)
		.subscribe((resp) => {
			if(includeProposal) {
				this.dialog.open(DocbuilerOptionsComponent, { data: { mainCartId: cartId, quoteId }, minWidth: 675 })
			} else {
				this.dataService.saveLifeQuoteDocBuilderOptions(quoteId, [], false, true).pipe(
					concatMap(v1 => this.dataService.createDocBuildDoc(quoteId)),
					concatMap(v2 => this.dataService.addToCart(cartId, quoteId, 4)))
				.subscribe(resp => {
					this.dialog.open(PersonalizeDialogComponent, { data: { mainCartId: cartId, quoteId }, minWidth: 830, maxWidth: 830 })
				})
		}
		});
	}

	validateSubscription(action) {
		if (this.sessionService.canUseSubscriptionLevelFeature(action)) {
			if(this.menuTrigger != null){
				this.menuTrigger.openMenu();
			}
		} else {
			this.lockedFeaturesDialogRef = this.dialog.open(LockFeaturesComponent, {
				panelClass: "lock-features",
			});
		}
	}
	setSourceFilter(id) {
		this.sourceId = id;
		this.myDisplayQuotes = this.myQuotes.filter(q => this.sourceId == 0 ? true : q.sourceID == this.sourceId);
	}


}
