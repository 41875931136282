import { Attachment } from "./attachment";

export class ModuleField {
	public textID: number;
	public text: string;
	public textSelected : boolean;
	public defaultText: string;
	public attachments: Array<Attachment>;
	public hasInvalidAttachment:boolean = false;
	public pageBreak: boolean = false;
	public usePlaceholder: boolean = false;

	constructor(o?:any) {
		this.attachments = new Array<Attachment>();
		Object.assign(this,o);
	}

	get isDefault() {
		return (this.text == this.defaultText || this.text == '');
	}
	/*
	get usePlaceholder() {
		return([1035,1036,1037,1241,1242,1243].indexOf(this.textID) > -1);
	}
	*/
}