import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { MixpanelService, QuoteService, SessionService } from 'acc-services';
import { MatRadioChange } from '@angular/material/radio';
import * as moment from "moment";
import { Subject, Observable, combineLatest, SubscriptionLike, ReplaySubject, forkJoin } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';
import { isArray } from 'util';
import { tooltips } from 'acc-common';
import { NewList } from "../../store/actions/communication.actions";

@Component({
	selector: "acc-main-analytics",
	templateUrl: "./analytics.component.html",
	styleUrls: ["./analytics.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class AnalyticsComponent implements OnInit, OnDestroy {
	private CHART_LIMIT = 10;

	private currentTimePeriod: Array<TimePeriod>;
	public metric: string = 'Premium';
	public showQuoted: boolean = true;
	public showApplied: boolean = true;
	public filteredOn: { name: string, id: string }
	public doCompare: boolean = false;
	public rawData: {Brokers: Array<any>, Carriers: Array<any>, TimePeriods: Array<any>};
	public brokerData: Array<any>;
	public carrierData: Array<any>;
	//public stateData: Array<any>;
	public timePeriodData: Array<any>;
	public dataPoint1: { name: string, field: string; };
	public dataPoint2: { name: string, field: string; };
	public defaultTimePeriod: TimePeriod;
	
	public dateSelected1: ReplaySubject<TimePeriod> = new ReplaySubject();
	public dateSelected2: ReplaySubject<TimePeriod> = new ReplaySubject()
	private dateSelected: Observable<any>;
	private dateSelectedSubscription: SubscriptionLike;
	private destroy$: Subject<boolean> = new Subject();

	private sortOrder: any = { broker: '', carrier: '', state: '' };

	public tooltips = tooltips;
	private userId: string;
	public today: Date = new Date();
	
	constructor(private quoteService: QuoteService, private sessionService: SessionService, private mixpanelService: MixpanelService) {}

	ngOnInit() {
		this.dateSelected = this.dateSelected1.asObservable();

		this.dateSelectedSubscription = this.dateSelected.pipe(takeUntil(this.destroy$)).subscribe(resp => {
			if(isArray(resp)){
				this.getData(resp)
			} else {
				this.getData([resp]);
			}
		})
		this.dataPoint1 = { name: 'Quoted', field: 'Quoted'+this.metric };
		this.dataPoint2 = { name: 'Applied', field: 'Applied'+this.metric };

		//default to current year
		this.defaultTimePeriod = {name: 'This Year', start: moment().startOf('year').toDate(), end: moment().endOf('year').toDate()};

		this.userId = this.sessionService.getUser().userID;
	}

	getData(timePeriod, broker?) {
		timePeriod = timePeriod || this.currentTimePeriod;
		this.currentTimePeriod = timePeriod;
		if(timePeriod.length == 1) {
			return new Promise((resolve, reject) => {
				this.quoteService.getAnalytics({ orgId: this.sessionService.getUser().orgID, start: moment(timePeriod[0].start).format('MM/DD/YYYY'), end: moment(timePeriod[0].end).format('MM/DD/YYYY'), userID: this.userId, brokerId: broker ? broker.id : undefined }).subscribe((resp: any) => {
					this.rawData = resp;
					this.selectData();
					resolve('');
				}),
				(error: any) => {
					reject(error)
				}
			})
		} else {
			return new Promise((resolve, reject) => { 
				if(timePeriod[0].name == timePeriod[1].name) {
					timePeriod[1].name += ' 2';
				}
				forkJoin(
					this.quoteService.getAnalytics({ orgId: this.sessionService.getUser().orgID, start: moment(timePeriod[0].start).format('MM/DD/YYYY'), end: moment(timePeriod[0].end).format('MM/DD/YYYY'), userID: this.userId, brokerId: broker ? broker.id : undefined }),
					this.quoteService.getAnalytics({ orgId: this.sessionService.getUser().orgID, start: moment(timePeriod[1].start).format('MM/DD/YYYY'), end: moment(timePeriod[1].end).format('MM/DD/YYYY'), userID: this.userId, brokerId: broker ? broker.id : undefined })
				).subscribe((resp: any) => {
					let data = resp[0];
					['Broker', 'Carrier', 'TimePeriod'].forEach(name => {
						let section: Array<any> = resp[1][name+'s'];
						section.forEach(item => { //loop through everything in second array
							let existing = data[name+'s'].find(e => e.id == item.id); // and find corresponding item in first array
							if(existing) { //and if it exists, add the new fields
								existing.CompareQuotedPremium = item.QuotedPremium;
								existing.CompareQuotedFaceAmount = item.QuotedFaceAmount;
								existing.CompareQuotedItemCount = item.QuotedItemCount;
								existing.CompareAppliedPremium = item.AppliedPremium;
								existing.CompareAppliedFaceAmount = item.AppliedFaceAmount;
								existing.CompareAppliedItemCount = item.AppliedItemCount;
							} else { //otherwise, add a new row
								data[name+'s'].push({
									Name: item.Name,
									id: item.id,
									CompareQuotedPremium: item.QuotedPremium, 
									CompareQuotedFaceAmount: item.QuotedFaceAmount,
									CompareQuotedItemCount: item.QuotedItemCount,
									CompareAppliedPremium: item.AppliedPremium,
									CompareAppliedFaceAmount: item.AppliedFaceAmount,
									CompareAppliedItemCount: item.AppliedItemCount
								})
							}
						})

					});
					this.rawData = data;
					this.dataPoint1 = { name: timePeriod[0].name, field: `${this.showQuoted ? 'Quoted' : 'Applied'}${this.metric}`}
					this.dataPoint2 = { name: timePeriod[1].name, field: `Compare${this.showQuoted ? 'Quoted' : 'Applied'}${this.metric}`}
					this.selectData();
					resolve('');
				}),
				(error: any) => {
					reject(error)
				}
			});
		}
	}

	selectData() {
		if(this.rawData) {
			let data = this.rawData;
			this.brokerData = data.Brokers.map(b => { return { name: b.Name, id: b.id, [this.dataPoint1.name]: b[this.dataPoint1.field] || 0, [this.dataPoint2.name]: b[this.dataPoint2.field] || 0}});
			this.carrierData = data.Carriers.map(c => { return { name: c.Name, [this.dataPoint1.name]: c[this.dataPoint1.field] || 0, [this.dataPoint2.name]: c[this.dataPoint2.field] || 0}});
			this.timePeriodData = data.TimePeriods.map(s => { return { name: s.Name, [this.dataPoint1.name]: s[this.dataPoint1.field] || 0, [this.dataPoint2.name]: s[this.dataPoint2.field] || 0}});
		} else {
			this.brokerData = null;
			this.carrierData = null;
			this.timePeriodData = null;
		}
	}

	filterBy(broker) {
		this.getData(false, broker).then(() => {
			this.filteredOn = broker;
		})
	}
	setMetric(e) {
		this.metric = e.value;
		if(this.doCompare) {
			this.dataPoint1.field = (this.showQuoted ? 'Quoted' : 'Applied') + this.metric;
			this.dataPoint2.field = 'Compare' + (this.showQuoted ? 'Quoted' : 'Applied') + this.metric;
		} else {
			this.dataPoint1 = { name: 'Quoted', field: 'Quoted'+this.metric }
			this.dataPoint2 = { name: 'Applied', field: 'Applied'+this.metric }
			}
		this.selectData();
	}

	toggleCompare() {
		this.doCompare = !this.doCompare;
		this.filteredOn = null;
		if(this.doCompare) {
			this.dateSelected = combineLatest(this.dateSelected1, this.dateSelected2);
		} else {
			this.dateSelected = this.dateSelected1.asObservable();
			this.dataPoint1 = { name: 'Quoted', field: 'Quoted'+this.metric };
			this.dataPoint2 = { name: 'Applied', field: 'Applied'+this.metric };
	
		}
		this.dateSelectedSubscription.unsubscribe();
		this.dateSelectedSubscription = this.dateSelected.pipe(takeUntil(this.destroy$)).subscribe(resp => {
			if(isArray(resp)){
				this.getData(resp)
			} else {
				this.getData([resp]);
			}
		})
		//clear out any data displayed
		this.rawData = null;
		this.selectData();
	}

	sort(data, field) {
		if(data == 'timePeriod') {
			this[data+'Data'] = this[data+'Data'].sort((a,b) => (moment(a[field]) as any) - (moment(b[field]) as any));
		} else {
			this[data+'Data'] = this[data+'Data'].sort((a,b) => a[field] > b[field] ? 1 : (a[field] < b[field] ? -1 : 0))
		}
		if(this.sortOrder[data] == field) {
			this[data+'Data'].reverse();
			this.sortOrder[data] = '';
		} else {
			this.sortOrder[data] = field;
		}
	}

	setVisibility() {
		this.dataPoint1 = { name: 'Quoted', field: 'Quoted'+this.metric };
		this.dataPoint2 = { name: 'Applied', field: 'Applied'+this.metric };

		if(!this.showQuoted && !this.showApplied) {
			this.dataPoint1 = null;
			this.dataPoint2 = null;
			return;
		}
		if(!this.showQuoted) {
			this.dataPoint1 = { name: 'Applied', field: 'Applied'+this.metric }; 
			this.dataPoint2 = null;
		}
		if(!this.showApplied) {
			this.dataPoint1 = { name: 'Quoted', field: 'Quoted'+this.metric }; 
			this.dataPoint2 = null;
		}
	}
	setType(e:MatRadioChange) {
		if(e.source.checked) {
			this.showQuoted = (e.value == 1);
			this.showApplied = (e.value == 2);
		}
		if(this.showQuoted) {
			this.dataPoint1.field = "Quoted"+this.metric;
			this.dataPoint2.field = "CompareQuoted"+this.metric
		} else {
			this.dataPoint1.field = "Applied"+this.metric;
			this.dataPoint2.field = "CompareApplied"+this.metric;
		}

		if(this.rawData) {
			this.brokerData = this.rawData.Brokers.map(b => { return { name: b.Name, id: b.id, [this.dataPoint1.name]: b[this.dataPoint1.field] || 0, [this.dataPoint2.name]: b[this.dataPoint2.field] || 0}});
			this.carrierData = this.rawData.Carriers.map(c => { return { name: c.Name, [this.dataPoint1.name]: c[this.dataPoint1.field] || 0, [this.dataPoint2.name]: c[this.dataPoint2.field] || 0}});
			this.timePeriodData = this.rawData.TimePeriods.map(s => { return { name: s.Name, [this.dataPoint1.name]: s[this.dataPoint1.field] || 0, [this.dataPoint2.name]: s[this.dataPoint2.field] || 0}});
		}
	}

	exportToExcel() {
		let timePeriod = this.currentTimePeriod[0]
		this.quoteService.getAnalyticsExcel({ orgId: this.sessionService.getUser().orgID, start: moment(timePeriod.start).format('MM/DD/YYYY'), end: moment(timePeriod.end).format('MM/DD/YYYY'), userId: this.userId, tzOffset: moment().utcOffset()}).subscribe((csvUrl:any) => {
			window.open(csvUrl, "_blank");
	  
		  })
	}
	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}


}

export interface GraphData {
	name: string;
	series: Array<{name: string, value: number}>;
}

export interface TimePeriod {
	name: string;
	start: Date | moment.Moment | '';
	end: Date | moment.Moment | '';
}