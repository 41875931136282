<div class="container adminContainer">
	<div class="header">
		<div class="drag-handle">&nbsp;</div>
		<div class="question">Question</div>
		<div class="blurb">Blurb</div>
		<div class="infographic">Infographic</div>
		<div class="always">Always in Doc</div>
	</div>
	<div class="droplist" cdkDropList (cdkDropListDropped)="drop($event)">
		<div class="row" *ngFor="let item of docbuilderItems" cdkDrag>
			<div class="drag-handle" cdkDragHandle ><mat-icon>drag_indicator</mat-icon></div>
			<div class="question">
				<textarea [(ngModel)]="item.questionDesc" (keyup)="item.isDirty = true"></textarea>
			</div>
			<div class="blurb">
				<textarea [(ngModel)]="item.blurb" (keyup)="item.isDirty = true"></textarea>
			</div>
			<div class="infographic">
				<acc-common-auto-preview-upload (fileChange)="fileChanged($event, item);item.isDirty = true" [url]="item.image?.imageURLPath" [text]="'Choose File or Drop Here'"></acc-common-auto-preview-upload>
			</div>
			<div class="always">
				<mat-checkbox [(ngModel)]="item.alwaysInDoc" value="1" (change)="item.isDirty = true"></mat-checkbox>
			</div>
			<div class="actions">
				<button mat-raised-button color="primary" (click)="save(item)" [disabled]="!item.isDirty">Save</button>
				<button mat-stroked-button color="" (click)="delete(item)">Delete</button>
			</div>
		</div>
	</div>
	<div class="actions">
		<button mat-raised-button color="primary" (click)="addNew()">Add New Item</button>
	</div>
</div>
