<div class="container">
	<mat-tab-group>
		<mat-tab label="Text Fields">
			<ng-template mat-tab-label>
				<label  class="labelHeading" >Text Fields<mat-icon style="font-size: 15px;" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_rev_date_text}}" >info_outline</mat-icon></label>
			</ng-template>
			<ng-container *ngIf="texts$ | async">
				<mat-accordion>
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header>
							<mat-panel-title>Expired <span class="count">({{(pastText$ | async)?.length}})</span></mat-panel-title>
						</mat-expansion-panel-header>
						<div class="textCards">
							<acc-main-text-card *ngFor="let past of pastText$ | async" [text]="past"></acc-main-text-card>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>Soon <span class="count">({{(soonText$ | async)?.length}})</span></mat-panel-title>
						</mat-expansion-panel-header>
						<div class="textCards">
							<acc-main-text-card *ngFor="let soon of soonText$ | async" [text]="soon"></acc-main-text-card>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>Future <span class="count">({{(futureText$ | async)?.length}})</span></mat-panel-title>
						</mat-expansion-panel-header>
						<div class="textCards">
							<acc-main-text-card *ngFor="let future of futureText$ | async" [text]="future"></acc-main-text-card>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>No Date <span class="count">({{(noDateText$ | async)?.length}})</span></mat-panel-title>
						</mat-expansion-panel-header>
						<div class="textCards">
							<acc-main-text-card *ngFor="let blank of noDateText$ | async" [text]="blank"></acc-main-text-card>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</ng-container>
		</mat-tab>
		<mat-tab label="Media Items" >
			<ng-template mat-tab-label>
				<label  class="labelHeading" >Media Items<mat-icon style="font-size: 15px;" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_rev_media}}" >info_outline</mat-icon></label>
			</ng-template>
			<ng-container *ngIf="medias$ | async">
				<mat-accordion>
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header>
							<mat-panel-title>Expired <span class="count">({{(pastMedia$ | async)?.length}})</span></mat-panel-title>
						</mat-expansion-panel-header>
						<div class="textCards">
							<acc-main-media-card *ngFor="let past of pastMedia$ | async" [media]="past"></acc-main-media-card>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>Soon <span class="count">({{(soonMedia$ | async)?.length}})</span></mat-panel-title>
						</mat-expansion-panel-header>
						<div class="textCards">
							<acc-main-media-card *ngFor="let soon of soonMedia$ | async" [media]="soon"></acc-main-media-card>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>Future <span class="count">({{(futureMedia$ | async)?.length}})</span></mat-panel-title>
						</mat-expansion-panel-header>
						<div class="textCards">
							<acc-main-media-card *ngFor="let future of futureMedia$ | async" [media]="future"></acc-main-media-card>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>No Date <span class="count">({{(noDateMedia$ | async)?.length}})</span></mat-panel-title>
						</mat-expansion-panel-header>
						<div class="textCards">
							<acc-main-media-card *ngFor="let blank of noDateMedia$ | async" [media]="blank"></acc-main-media-card>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</ng-container>
		</mat-tab>
	</mat-tab-group>
</div>