import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'acc-services';

import { QuoteComponent } from './quote/quote.component';
import { TemplateComponent } from '../components/template/template.component';
import { SelectHealthTypeComponent } from './select-health-type/select-health-type.component';
import { QuoteFormComponent } from './quote-form/quote-form.component';
import { HealthDetailsComponent } from './health-details/health-details.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SubscriptionLevelGuard } from 'projects/acc-services/src/lib/guards/level.guard';
import { SelfServiceQuotingComponent } from './self-service-quoting/self-service-quoting.component';

const routes: Routes = [
	{
		path: 'quote',
		component: TemplateComponent,
		children: [{
				path: 'analytics',
				component: AnalyticsComponent,
				canActivate:[SubscriptionLevelGuard],
				data: { pageTitle: 'LifeQuote Analytics', level: 2 }
			}, 
			{ path: "self-service", component: SelfServiceQuotingComponent, data: { pageTitle: 'Self Service Quoting'} },
			{
			path: '',
			component: QuoteComponent,
			children: [
				{ path: '', pathMatch: 'full', component: QuoteFormComponent, data: { pageTitle: 'Life Quote'} },
				{ path: 'health-types', component: SelectHealthTypeComponent, data: { pageTitle: 'Life Quote'} },
				{ path: 'health-details', component: HealthDetailsComponent, data: { pageTitle: 'Life Quote'} }
			]
		}]
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuoteEngineRoutingModule { }
