import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogComponent } from 'acc-common';
import { QuoteService } from 'acc-services';
import { SetErrorMessage } from '../../store/actions/status.actions';
import { IAppState } from '../../store/state/app.state';


@Component({
  selector: 'acc-main-apply-dialog',
  templateUrl: './apply-dialog.component.html',
  styleUrls: ['./apply-dialog.component.scss']
})
export class ApplyDialogComponent implements OnInit {

	constructor(private quoteService: QuoteService, private store: Store<IAppState>, 
    public dialogRef: MatDialogRef<ApplyDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {

    }

  ngOnInit() {
    this.prepButtonsText();
  }

	close(): void {
		this.dialogRef.close();
	}

  prepButtonsText(){
    //PaperForms  0
		//IGoEApp 1
		//IGoDropTicket 2
	 	//IGoGenericTicket 3
    this.data.buttons.forEach(b => {
    if(b.type == '1'){
        if(b.customName == null || b.customName == ""){
          b.customName = "e-Application";
        }
        if(b.customNotes == null || b.customNotes == ""){
          b.customNotes = "Complete and process in good order life insurance applications.";
        }
      }else if(b.type == '0'){
        if(b.customName == null || b.customName == ""){
          b.customName = "Paper Forms";
        }
        if(b.customNotes == null || b.customNotes == ""){
          b.customNotes = "Access all product and application forms.";
        }
      }else{
        if(b.customName == null || b.customName == ""){
          b.customName = "Drop Ticket";
        }
        if(b.customNotes == null || b.customNotes == ""){
          b.customNotes = "Simply fill in the basic information & let the carrier do the rest.";
        }
      }
    });
  }

  applyToEApp(igoRequest){
    
    var userAgentString = navigator.userAgent;
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    let safariAgent = userAgentString.indexOf("Safari") > -1;
    var myWindow = null;
    if((chromeAgent) && (safariAgent)) safariAgent = false;
    if(!safariAgent){
      myWindow = window.open("/loading","_blank");
    }

    if(igoRequest.buttonType != 0){
      this.quoteService.getToIgoLink(igoRequest).subscribe(
        (resp: any) => {
          if(!safariAgent){
            if (!!resp && !resp.error) {
              if(!!myWindow){
                myWindow.location.href = resp.result;
                myWindow.focus();
              }
            } else {
              if(!!myWindow){
                myWindow.close();
              }
              this.store.dispatch(new SetErrorMessage(resp.error));
            }
          }else{
            this.showSafariToIgoDialog(resp.result);
          }
          this.close();
        },
        (err: any) => {
          if(!!myWindow){
            myWindow.close();
          }
          this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
        }
      );
    }else{
      var url = this.getPapersLink(igoRequest)
      if(!safariAgent){
        if(!!myWindow){
          myWindow.location.href = url;
          myWindow.focus();
        }
      }else{
        this.showSafariToIgoDialog(url);
      }
      this.close();
    }
  }

  getPapersLink(request){
    //console.log(request);
    //https://formspipe.ipipeline.com/PipeConnect/LifePipe.aspx?ServiceTypeID=4&NB=true&XML_GAID=1357&XML_STATE=CA&XML_CARRIER=178&XML_PRODUCTTYPE=1&NAV_results=1&LOAD=1&XML_AGE=38&XML_FACEAMOUNT=300000&XML_TERM=10&XML_PREMIUMAMOUNT=147&XML_PRODUCT=6025&EMAIL=1&npt=38&ProfileID=1357
    //https://formspipe.ipipeline.com/PipeConnect/LifePipe.aspx?ServiceTypeID=4&NB=true&XML_GAID=1357&XML_STATE=AR&XML_CARRIER=178&XML_PRODUCTTYPE=1&NAV_results=1&LOAD=1&XML_AGE=50&XML_FACEAMOUNT=300000&XML_TERM=10&XML_PREMIUMAMOUNT=336&XML_PRODUCT=6025&EMAIL=1&npt=38&ProfileID=1357
    //https://formspipe.ipipeline.com/PipeConnect/LifePipe.aspx?ServiceTypeID=4&NB=true&XML_GAID=1357&XML_STATE=AR&XML_CARRIER=161&XML_PRODUCTTYPE=1&NAV_results=1&LOAD=1&XML_AGE=50&XML_FACEAMOUNT=300000&XML_TERM=10&XML_PREMIUMAMOUNT=357&XML_PRODUCT=8066&EMAIL=1&npt=38&ProfileID=1357

    var url = "https://formspipe.ipipeline.com/PipeConnect/LifePipe.aspx?ServiceTypeID=4&NB=true&XML_GAID=" + request.gaid;
    url = url + "&XML_STATE="+ request.client.state +"&XML_CARRIER="+ request.quoteResult.input.companyId + "&XML_PRODUCTTYPE=1&NAV_results=1&LOAD=1&";
    url = url + "XML_AGE="+request.client.age +"&XML_FACEAMOUNT="+request.quoteResult.faceAmount +"&XML_TERM="+request.quoteResult.input.term;
    url = url + "&XML_PREMIUMAMOUNT="+request.quoteResult.premiun + "&XML_PRODUCT="+request.quoteResult.input.productId;
    url = url + "&EMAIL=1&npt="+request.client.age +"&ProfileID="+ request.gaid;
    return url;
  }

  showSafariToIgoDialog(url) {
    var dialogContent = [`Please <a href='${url}' target='_blank'>click here</a> to start your application.`];

    var dialog = this.dialog.open(DialogComponent, {
      data: {
        messages: dialogContent.filter((m) => !!m).map((m) => `<pre>${m}</pre>`),
        title: "Redirecting",
      },
      panelClass: "subscription-admin",
    });
  }

}
