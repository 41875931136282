export class CrmContact {
	public ID:string;
	public Name:string;
	public AccountID: string;
	public Email: string;
	public CrmContactId:string

	constructor(o?:any) {
		this.CrmContactId = o.ID; //need crmcontactid for send email
		Object.assign(this,o); 
	}
}  