import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
 
@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(private router: Router) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }
 
		if(sessionStorage.getItem('organization')) {
			this.router.navigate(['/', JSON.parse(sessionStorage.getItem('organization')).resource])
		} else {
        // not logged in so redirect to login page with the return url and return false
			this.router.navigate(['/']);
		}
        return false;
        
    }
}