import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import * as RevisionDatesSelectors from '../../store/selectors/revisiondates.selectors';
import * as RevisionDatesActions from '../../store/actions/revisiondates.actions';
import { Text, Organization, Media } from 'acc-models';
import { SessionService } from 'acc-services';
import { tap } from 'rxjs/operators';
import { tooltips } from 'acc-common';
import { RootReset } from '../../store/actions/root.actions';
@Component({
  selector: 'acc-main-revision-dates',
  templateUrl: './revision-dates.component.html',
  styleUrls: ['./revision-dates.component.scss']
})
export class RevisionDatesComponent implements OnInit {

	public tooltips = tooltips;

	public texts$ = this.store.pipe(
		select(RevisionDatesSelectors.selectTexts)
	)
	public pastText$ = this.store.pipe(
		select(RevisionDatesSelectors.selectPastTexts)
	)
	public soonText$ = this.store.pipe(
		select(RevisionDatesSelectors.selectSoonTexts)
	)
	public futureText$ = this.store.pipe(
		select(RevisionDatesSelectors.selectFutureTexts)
	)
	public noDateText$ = this.store.pipe(
		select(RevisionDatesSelectors.selectNoDateTexts)
	)

	public medias$ = this.store.pipe(
		select(RevisionDatesSelectors.selectMedias)
	)
	public pastMedia$ = this.store.pipe(
		select(RevisionDatesSelectors.selectPastMedias)
	)
	public soonMedia$ = this.store.pipe(
		select(RevisionDatesSelectors.selectSoonMedias)
	)
	public futureMedia$ = this.store.pipe(
		select(RevisionDatesSelectors.selectFutureMedias)
	)
	public noDateMedia$ = this.store.pipe(
		select(RevisionDatesSelectors.selectNoDateMedias)
	)

	constructor(private store: Store<IAppState>, private sessionService: SessionService) { }

	ngOnInit() {
		const orgId = this.sessionService.getItem<Organization>('organization').orgID;
		this.store.dispatch(new RootReset());
		this.store.dispatch(new RevisionDatesActions.GetTexts(orgId));
		this.store.dispatch(new RevisionDatesActions.GetMedias(orgId));
	}

}
