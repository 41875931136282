import { Component, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { NewQuote } from '../../store/actions/quote.actions';
import { QuoteService } from 'acc-services';
import { Router } from "@angular/router";

@Component({
	selector: "acc-main-quote",
	templateUrl: "./quote.component.html",
	styleUrls: ["./quote.component.scss"]
})
export class QuoteComponent implements OnInit {
	constructor(private store: Store<IAppState>, private quoteService: QuoteService, private router: Router) {}

	ngOnInit() {}

	newQuote() {
		if(this.router.url.includes("/quote") && !this.router.url.includes("/quote/")){
			this.store.dispatch(new NewQuote());
		}
		this.router.navigateByUrl("/quote");
	}

	expireToken() {
		this.quoteService.expireToken();
	}
}
