import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DialogComponent, ProfilePhotoComponent } from "acc-common";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { CustomValidators } from "../../validators/custom-validators";
import { DataService, MixpanelService, QuoteService, SessionService } from "acc-services";
import { ProfilePhoto, Settings, User } from "acc-models";
import { tap, takeUntil } from "rxjs/operators";
import { SignupInput, SignupCompany, SignupUser } from "projects/acc-models/src/lib/models/signUp";
import { of, forkJoin, Subject, SubscriptionLike } from "rxjs";
import { SetErrorMessage, ShowGettingStarted } from "../../store/actions/status.actions";
import { IAppState } from "../../store/state/app.state";
import { Store, select } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CheckoutService } from "projects/acc-services/src/lib/services/checkout.service";
import { tooltips } from "acc-common";
import * as SignUpSelector from "../../store/selectors/signup.selectors";
import { SetSignUpData } from "../../store/actions/signup.actions";

@Component({
	selector: "acc-main-signup-third",
	templateUrl: "./signup-third.component.html",
	styleUrls: ["./signup-third.component.scss"],
})
export class SignUp3Component implements OnInit {
	public logo;
	public photo;
	@ViewChild("logoPhoto", { static: true }) logoPhoto: ProfilePhotoComponent;
	@ViewChild("userPhoto", { static: true }) userPhoto: ProfilePhotoComponent;
	signUpForm: FormGroup;
	signUp: SignupInput;

	loading = false;
	confirmation;
	selectedPlan = null;
	products;
	plans;
	masterOrgID;

	public urlEdited: boolean = false;

	private userData: any;
	public tooltips = tooltips;
	public imageFileTypes = ['.jpg', '.jpeg', '.png', '.gif'];
	public validImageFileTypes = ['image/jpeg', 'image/png', 'image/gif'];

	private destroy$: Subject<boolean> = new Subject();
	public userData$ = this.store.pipe(select(SignUpSelector.selectUserDataResponse), takeUntil(this.destroy$));
	private getOrgSubscription: SubscriptionLike;

	constructor(private fb: FormBuilder, private router: Router, private dataService: DataService, private snackBar: MatSnackBar,
		private checkoutService: CheckoutService, private route: ActivatedRoute, private store: Store<IAppState>,
		private sessionService: SessionService, private dialog: MatDialog, private quoteService: QuoteService, private mixpanelService: MixpanelService) {}

	ngOnInit() {
		window.scrollTo(0, 0);
		this.userData$.subscribe((data) => (this.userData = data));
		this.initForms();

		this.mixpanelService.trackSignup();
	}

	initForms() {
		this.signUpForm = this.fb.group(
			{
				CompanyName: [null, Validators.required],
				ResourceName: [
					null,
					Validators.compose([
						// 1. Resource Field is Required
						Validators.required,
						// 2. check whether the entered text has no spaces
						CustomValidators.patternValidator(/^\S*$/, { hasSpace: true }),
						// 3. Has a max length of 100 characters
						Validators.maxLength(100),
					]),
				],
				Title: [null, []],
				FirstName: [null, Validators.required],
				LastName: [null, Validators.required],
				Email: [null, Validators.compose([Validators.email, Validators.required])],
				Password: [
					null,
					Validators.compose([
						// 1. Password Field is Required
						Validators.required,
					]),
				],
				ConfirmPassword: [null, Validators.compose([Validators.required])],
			},
			{
				// check whether our password and confirm password match
				validator: CustomValidators.passwordMatchValidator,
			}
		);

		//Init Model
		this.signUp = new SignupInput();
		this.signUp.company = new SignupCompany();
		this.signUp.user = new SignupUser();
		this.signUp.activationURL = window.location.origin + "/verify/";

		//STRIPE
		this.getProducts();
		this.getPlans();

		if (!!this.userData) {
			this.signUpForm.controls.FirstName.setValue(this.userData.firstName);
			this.signUpForm.controls.LastName.setValue(this.userData.lastName);
			this.signUpForm.controls.Email.setValue(this.userData.email);
			this.masterOrgID = this.userData.masterOrgID;

			if(this.masterOrgID == null){
				//Support on Session Storage
				this.masterOrgID  = sessionStorage.getItem('masterOrgID');
				sessionStorage.removeItem('masterOrgID');
			}
		}
	}

	getProducts() {
		this.checkoutService.getProducts().subscribe(
			(resp: any) => {
				this.products = resp.data;
			},
			(err: any) => {
				if (err.error) {
					this.snackBar.open(err.error.Message, "Close", { duration: 5000 });
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access to SignUp service"));
				}
			}
		);
	}

	getPlans() {
		this.checkoutService.getPlans().subscribe(
			(resp: any) => {
				this.plans = resp.data;

				//Select the first plan:
				this.selectedPlan = this.plans[0];
			},
			(err: any) => {
				if (err.error) {
					this.snackBar.open(err.error.Message, "Close", { duration: 5000 });
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access to SignUp service"));
				}
			}
		);
	}

	setDefaultResource(e) {
		//if(this.urlEdited) return;
		//if (e.target.value != null && e.target.value.trim() != "") {
			let newValue = e.target.value.trim().replace(/\s/g, "").toLowerCase();
			newValue = newValue.replace(/[^a-zA-Z0-9 ]/g, "");
			this.signUpForm.controls.ResourceName.setValue(newValue);
			this.signUpForm.controls.ResourceName.markAsTouched();
			this.checkResource(newValue);
		//}
	}

	preventChars(e, allowSpace = false) {
		//if (event.which === 32) event.preventDefault();
		var NOT_ALLOWED_CHARS_REGEXP = /[^a-zA-Z0-9 ]/;
		if (NOT_ALLOWED_CHARS_REGEXP.test(e.key) || (!allowSpace && e.key == " ")) {
			e.preventDefault();
		}
	}

	checkResource(value) {
		if (value != null && value != "") {
			this.dataService.verifyResource(value).subscribe(
				(resp: any) => {
					if (!resp) {
						this.signUpForm.controls.ResourceName.setErrors({ exist: true });
					}
				},
				(err: any) => {
					this.store.dispatch(new SetErrorMessage("Error verifying resource name."));
				}
			);
		}
	}

	sendWelcomeEmailAndRedirect(user) {
		this.dataService.sendWelcomeEmail(user.userID).subscribe(() => {
			this.store.dispatch(new SetSignUpData(null));
			this.goToWelcome(user);
		});
	}

	createCompanyUser() {
		const promise = new Promise(async (resolve, reject) => {

			const logoUpload = this.logoPhoto.getData();
			const userUpload = this.userPhoto.getData();
			let observableBatch = [];
			let imageTypeError = false;

			observableBatch.push(of(true)); //just get something in there, in case there are no images to save
			if (logoUpload.file) {

				if(!this.validImageFileTypes.includes(logoUpload.file.type)){
					imageTypeError = true;
					reject({error:{Message:"Please select a valid image type."}});
				}

				//save logo image file
				observableBatch.push(
					this.dataService.uploadProfileImage(logoUpload.file, logoUpload.photo).pipe(
						tap((img: ProfilePhoto) => {
							this.signUp.company.photoID = img.imageID;
						})
					)
				);
			}

			if (userUpload.file) {

				if(!this.validImageFileTypes.includes(userUpload.file.type)){
					imageTypeError = true;
					reject({error:{Message:"Please select a valid image type."}});
				}

				//save user image file
				observableBatch.push(
					this.dataService.uploadProfileImage(userUpload.file, userUpload.photo).pipe(
						tap((img: ProfilePhoto) => {
							this.signUp.user.photoID = img.imageID;
						})
					)
				);
			}

			forkJoin(observableBatch).subscribe((resp: any) => {
				if(!imageTypeError){
					this.dataService.selfSignUp(this.signUp).subscribe(
						(resp: any) => {
							resolve(resp);
						},
						(err: any) => {
							reject(err);
						}
					);
				}
			},
			(err: any) => {
				reject(err);
			});
		});
		return promise;
	}

	buildDataModel() {
		this.signUp.masterOrgID = this.masterOrgID;
		this.signUp.company.orgDesc = this.signUpForm.value["CompanyName"];
		this.signUp.company.resource = this.signUpForm.value["ResourceName"];
		this.signUp.user.title = this.signUpForm.value["Title"];
		this.signUp.user.firstName = this.signUpForm.value["FirstName"];
		this.signUp.user.lastName = this.signUpForm.value["LastName"];
		this.signUp.user.email = this.signUpForm.value["Email"];
		this.signUp.user.password = this.signUpForm.value["Password"];
	}

	back() {
		this.router.navigate(["/signup/1"], { state: { data: this.userData } });
	}

	goToWelcome(user) {

		//Track Conversion
		this.mixpanelService.trackSignupConversion();

		var u = new User(user);
		this.sessionService.setUser(u);
		this.sessionService.authToken = u.token;

		this.getOrgSubscription = this.dataService.GetOrgFromID(u.orgID).subscribe((org) => {
			this.sessionService.removeItem("organization");
			if (org.orgID) {
				this.sessionService.setItem("organization", org);
			}

			//Get Org configuration
			this.quoteService.loadQuoteOrgConfiguration();

			//Init Mixpanel Subscription
			this.mixpanelService.initMixpanelTracker(this.router);

			//Show Getting Started
			this.store.dispatch(new ShowGettingStarted());

			//get settings
			this.dataService.getSettings(u.orgID).subscribe(
				(resp) => {
					this.sessionService.settings = new Settings(resp);

					this.router.navigate(["/welcome"]);
				},
				(error) => {
					//if a problem, set defaults
					this.sessionService.settings = new Settings();

					this.router.navigate(["/welcome"]);
				}
			);
		});
	}

	showFeaturesDialog() {

		this.dialog.open(DialogComponent, {
			data: {
				messages: ["With information about you and your brokerage, Accelerate can personalize your life quotes and your sales and marketing videos!"],
				title: "Why am I being asked to Register",
			},
			panelClass: "features-dialog",
		});
	}

	async submitForm() {
		if (this.signUpForm.valid) {
			this.loading = true;
			this.buildDataModel();

			//Stripe Customer
			await this.processCustomer().then(
				(resp) => {
					this.sendWelcomeEmailAndRedirect(resp["user"]);
				},
				(err) => {
					this.loading = false;
					this.store.dispatch(new SetErrorMessage("Your card could not be processed.  Please make sure you entered the number correctly."));
				}
			);
		}
	}


	processCustomer() {
		const promise = new Promise(async (resolve, reject) => {
			let customerInfo = {
				email: this.signUp.user.email,
				name: this.signUp.user.firstName + " " + this.signUp.user.lastName,
				company: this.signUp.company.orgDesc,
			};

			this.checkoutService.createCustomer(customerInfo).subscribe(
				(customer: any) => {

					//Handle stripe customer id
					this.signUp.stripeCustomerId = customer.id;

					this.createCompanyUser().then(
						async (user: User) => {
							// Otherwise send paymentMethod.id to your server
							let subscriptionInfo = {
								orgId: user.orgID,
								plan: this.selectedPlan.id,
								email: this.signUp.user.email,
								name: this.signUp.user.firstName + " " + this.signUp.user.lastName,
								company: this.signUp.company.orgDesc,
								customerId: customer.id,
							};
							await this.checkoutService.createSubscription(subscriptionInfo).subscribe(
								(resp: any) => {
									resolve({ user: user, subscription: resp });
								},
								(err: any) => {
									this.store.dispatch(new SetErrorMessage("Your card could not be processed.  Please make sure you entered the number correctly."));
									reject(err);
								}
							);
						},
						(err) => {
							this.loading = false;
							this.store.dispatch(new SetErrorMessage(err.error.Message));
						}
					);
				},
				(err: any) => {
					this.store.dispatch(new SetErrorMessage("Your card could not be processed.  Please make sure you entered the number correctly."));
					reject(err);
				}
			);
		});
		return promise;
	}

}
