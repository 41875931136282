<div>
	<div mat-dialog-header>
		<h2 mat-dialog-title>Apply Options</h2>
		<div class="close" (click)="close()"><mat-icon>highlight_off</mat-icon></div> 
	</div>
	<mat-dialog-content>

    <div class="title-container">
      <div class="logo" [style.backgroundImage]="'url(' + this.data.image + ')'">
      </div>
      <div class="title">
        {{this.data.programName}}
      </div>
    </div>

    <div class="buttons-container">
      <div class="apply-button" *ngFor="let b of this.data.buttons; let i = index">
        <div class="apply-icon">
          <i *ngIf="b.type == '0'" class="fa fa-edit apply-icon-ticket"></i>
          <i *ngIf="b.type == '1'" class="fa fa-check-circle apply-icon-app"></i>
          <i *ngIf="b.type == '2' || b.type == '3'" class="fa fa-headset apply-icon-ticket"></i>
        </div>
        <div class="apply-data">
          <h3>{{b.customName}}</h3>
          <div class="button-notes" [innerHTML]="b.customNotes"></div>
          <button mat-raised-button (click)="applyToEApp(b.request)" color="primary">Start {{b.customName}} <i class="fa fa-external-link-alt"></i></button>
        </div>
      </div>
    </div>
	</mat-dialog-content>

	<div class="spacer">&nbsp;</div>

	<mat-dialog-actions>
    <button color="primary" mat-stroked-button (click)="close()">Close</button>
	</mat-dialog-actions>
</div>
