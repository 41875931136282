import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { QuoteService } from "acc-services";
import { selectApplication, selectBroker, selectResults } from "../../store/selectors/dtc.selectors";
import { IAppState } from "../../store/state/app.state";
import { DtcRouteService } from "../dtc.route.service";

@Component({
	selector: "acc-main-finish",
	templateUrl: "./finish.component.html",
	styleUrls: ["./finish.component.scss"],
})
export class FinishComponent implements OnInit {
	public app$;
	public broker$;
	public results$ = this.store.pipe(select(selectResults));
	public dtcResults = null;
	public monthAmount = 0;
	public tableRatings: Array<any>;
	constructor(private store: Store<IAppState>, private quoteService: QuoteService, private dtcRouteService: DtcRouteService) {}

	ngOnInit() {
		this.app$ = this.store.pipe(select(selectApplication));
		this.broker$ = this.store.pipe(select(selectBroker));
		this.results$.subscribe((r) => { 
			this.dtcResults = r;
			this.monthAmount = (!!r)?r.monthAmount:0;
		});

		this.dtcRouteService.setPage('finish');
	}

	getTableRatingsOptions() {
		this.tableRatings = this.quoteService.getTableRatingsOptions();
	}

	getTableRating(rating) {
		var rat = this.tableRatings.find((x) => x.code == rating);
		return rat.name;
	}
}
