import { HealthProfile } from './healthProfile';

export class HealthDetailsAttributes {
	public healthClassType: string;
	public height: string;
	public weight: number;
	public everUseTobacco: boolean;
	public lastUseTobacco: string;
	public kindOfTobacco: string;
	public howMany: number;
	public healthConditionsIsDisabled: boolean;
	public familyHistoryIsDisabled: boolean;
	public drivingViolationsIsDisabled: boolean;
	public everUseBloodPressureMedication: string;
	public bloodPressureSystolic: number;
	public bloodPressureDiastolic: number;
	public everUseCholesterolMedication: string;
	public cholesterolTotalValue: number;
	public cholesterolHDLValue: number;
	public cholesterolHDLRatio: number;
	public fatherHistory: boolean;
	public fatherConditions: Array<string>;
	public fatherDiabetesDiagnosedAge: number;
	public fatherCardioVascularDiagnosedAge: number;
	public fatherStrokeDiagnosedAge: number;
	public fatherCancerDiagnosedAge: number;
	public fatherDeathConditions: Array<string>;
	public fatherDie: boolean;
	public fatherDeathAge: number;
	public motherHistory: boolean;
	public motherConditions: Array<string>;
	public motherDiabetesDiagnosedAge: number;
	public motherCardioVascularDiagnosedAge: number;
	public motherStrokeDiagnosedAge: number;
	public motherCancerDiagnosedAge: number;
	public motherDeathConditions: Array<string>;
	public motherDie: boolean;
	public motherDeathAge: number;
	public sisterHistory: boolean;
	public sisterConditions: Array<string>;
	public sisterDiabetesDiagnosedAge: number;
	public sisterCardioVascularDiagnosedAge: number;
	public sisterStrokeDiagnosedAge: number;
	public sisterCancerDiagnosedAge: number;
	public sisterDeathConditions: Array<string>;
	public sisterDie: boolean;
	public sisterDeathAge: number;
	public brotherHistory: boolean;
	public brotherConditions: Array<string>;
	public brotherDiabetesDiagnosedAge: number;
	public brotherCardioVascularDiagnosedAge: number;
	public brotherStrokeDiagnosedAge: number;
	public brotherCancerDiagnosedAge: number;
	public brotherDeathConditions: Array<string>;
	public brotherDie: boolean;
	public brotherDeathAge: number;
	public drivingConvictionExists: boolean;
	public duiDwiHowMany: number;
	public duiDwiDates: Array<Date>;
	public drivingLicenseSuspensionExists: boolean;
	public drivingLicenseSuspensionHowMany: number;
	public drivingLicenseSuspensionDates: Array<Date>;
	public motorVehicleAccidentExists: boolean;
	public motorVehicleAccidentHowMany: number;
	public motorVehicleAccidentDates: Array<Date>;
	public movingViolationExists: boolean;
	public movingViolationHowMany: number;
	public movingViolationDates: Array<Date>;
	public recklessDrivingViolationExists: boolean;
	public recklessDrivingHowMany: number;
	public recklessDrivingDates: Array<Date>;

	constructor(o?: any) {
		Object.assign(this, o);
	}

	public static fromHealthProfile(profile: HealthProfile): HealthDetailsAttributes {
		let details = new HealthDetailsAttributes();
		details.healthClassType = profile.healthClass;
		details.height = profile.healthConditions.physicalBuild.height;
		details.weight = profile.healthConditions.physicalBuild.weight;
		details.everUseTobacco = profile.healthConditions.tobaccoUse.everUseTobacco;
		details.lastUseTobacco = profile.healthConditions.tobaccoUse.lastuserTobacco;
		details.kindOfTobacco = profile.healthConditions.tobaccoUse.kindOfTobacco;
		details.howMany = profile.healthConditions.tobaccoUse.howMany;
		details.healthConditionsIsDisabled = !profile.healthConditions.isSelected;
		details.familyHistoryIsDisabled = !profile.familyHistory.isSelected;
		details.drivingViolationsIsDisabled = !profile.drivingViolation.isSelected;
		details.everUseBloodPressureMedication = (
			profile.healthConditions.bloodPressure.bloodPressureMedicationNeverUsed  ? "never" :
			profile.healthConditions.bloodPressure.bloodPressureMedicationCurrentlyUse ? "current" :
			profile.healthConditions.bloodPressure.bloodPressureMedicationUsedInPast ? "past" : ""
		);
		details.bloodPressureSystolic = profile.healthConditions.bloodPressure.bloodPressureSystolic;
		details.bloodPressureDiastolic = profile.healthConditions.bloodPressure.bloodPressureDiastolic;
		details.everUseCholesterolMedication = (
			profile.healthConditions.cholesterol.cholesterolMedicationCurrentlyUse ? "current" :
			profile.healthConditions.cholesterol.cholesterolMedicationUsedInPast ? "past" :
			profile.healthConditions.cholesterol.cholesterolMedicationNeverUsed ? "never" : ""
		)
		details.cholesterolTotalValue = profile.healthConditions.cholesterol.cholesterolTotalValue;
		details.cholesterolHDLValue = profile.healthConditions.cholesterol.cholesterolHDLValue;
		details.cholesterolHDLRatio = profile.healthConditions.cholesterol.cholesterolHDLRatio;
		details.fatherHistory = profile.familyHistory.father.fatherHistory;
		details.fatherConditions = profile.familyHistory.father.conditions;
		details.fatherDiabetesDiagnosedAge = profile.familyHistory.father.fatherDiabetesDiagnosedAge;
		details.fatherCardioVascularDiagnosedAge = profile.familyHistory.father.fatherCardiovascularDiagnosedAge;
		details.fatherStrokeDiagnosedAge = profile.familyHistory.father.fatherStrokeDiagnosedAge;
		details.fatherCancerDiagnosedAge = profile.familyHistory.father.fatherCancerDiagnosedAge
		details.fatherDeathConditions = profile.familyHistory.father.deathConditions;
		details.fatherDie = profile.familyHistory.father.fatherDie;
		details.fatherDeathAge = profile.familyHistory.father.deathAge;
		details.motherHistory = profile.familyHistory.mother.motherHistory;
		details.motherConditions = profile.familyHistory.mother.conditions;
		details.motherDiabetesDiagnosedAge = profile.familyHistory.mother.motherDiabetesDiagnosedAge;
		details.motherCardioVascularDiagnosedAge = profile.familyHistory.mother.motherCardiovascularDiagnosedAge;
		details.motherStrokeDiagnosedAge = profile.familyHistory.mother.motherStrokeDiagnosedAge;
		details.motherCancerDiagnosedAge = profile.familyHistory.mother.motherCancerDiagnosedAge
		details.motherDeathConditions = profile.familyHistory.mother.deathConditions;
		details.motherDie = profile.familyHistory.mother.motherDie;
		details.motherDeathAge = profile.familyHistory.mother.deathAge;

		details.sisterHistory = profile.familyHistory.sister.sisterHistory;
		details.sisterConditions = profile.familyHistory.sister.conditions;
		details.sisterDiabetesDiagnosedAge = profile.familyHistory.sister.sisterDiabetesDiagnosedAge;
		details.sisterCardioVascularDiagnosedAge = profile.familyHistory.sister.sisterCardiovascularDiagnosedAge;
		details.sisterStrokeDiagnosedAge = profile.familyHistory.sister.sisterStrokeDiagnosedAge;
		details.sisterCancerDiagnosedAge = profile.familyHistory.sister.sisterCancerDiagnosedAge
		details.sisterDeathConditions = profile.familyHistory.sister.deathConditions;
		details.sisterDie = profile.familyHistory.sister.sisterDie;
		details.sisterDeathAge = profile.familyHistory.sister.deathAge;

		details.brotherHistory = profile.familyHistory.brother.brotherHistory;
		details.brotherConditions = profile.familyHistory.brother.conditions;
		details.brotherDiabetesDiagnosedAge = profile.familyHistory.brother.brotherDiabetesDiagnosedAge;
		details.brotherCardioVascularDiagnosedAge = profile.familyHistory.brother.brotherCardiovascularDiagnosedAge;
		details.brotherStrokeDiagnosedAge = profile.familyHistory.brother.brotherStrokeDiagnosedAge;
		details.brotherCancerDiagnosedAge = profile.familyHistory.brother.brotherCancerDiagnosedAge
		details.brotherDeathConditions = profile.familyHistory.brother.deathConditions;
		details.brotherDie = profile.familyHistory.brother.brotherDie;
		details.brotherDeathAge = profile.familyHistory.brother.deathAge;

		details.drivingConvictionExists = profile.drivingViolation.duiDwi.drivingConvictionExists;
		details.duiDwiHowMany = profile.drivingViolation.duiDwi.drivingDrunkDrivingDates.length;
		details.duiDwiDates = profile.drivingViolation.duiDwi.drivingDrunkDrivingDates.map(d => new Date(d));
		details.drivingLicenseSuspensionExists = profile.drivingViolation.licenseSuspension.drivingLicenseCurrentlyRevoked;
		details.drivingLicenseSuspensionHowMany = profile.drivingViolation.licenseSuspension.drivingLicenseSuspensionDates.length;
		details.drivingLicenseSuspensionDates = profile.drivingViolation.licenseSuspension.drivingLicenseSuspensionDates.map(d => new Date(d))
		details.motorVehicleAccidentExists = profile.drivingViolation.motorVehicleAccident.motorVehicleAccidentExists;
		details.motorVehicleAccidentHowMany = profile.drivingViolation.motorVehicleAccident.drivingMotorVehicleAccidentDates.length;
		details.motorVehicleAccidentDates = profile.drivingViolation.motorVehicleAccident.drivingMotorVehicleAccidentDates.map(d => new Date(d));
		details.movingViolationExists = profile.drivingViolation.movingViolation.movingViolationExists;
		details.movingViolationHowMany = profile.drivingViolation.movingViolation.drivingMovingViolationDates.length;
		details.movingViolationDates = profile.drivingViolation.movingViolation.drivingMovingViolationDates.map(d => new Date(d));
		details.recklessDrivingViolationExists = profile.drivingViolation.recklessDriving.recklessDrivingViolationExists;
		details.recklessDrivingHowMany = profile.drivingViolation.recklessDriving.drivingRecklessDrivingDates.length;
		details.recklessDrivingDates = profile.drivingViolation.recklessDriving.drivingRecklessDrivingDates.map(d => new Date(d));

		return details;
	}
}