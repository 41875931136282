export class Folder {
	public mediaCategoryID: any;
	public mediaCategoryDesc: string;
	public isOpen: boolean;
	public MediaCategories: Array<Folder>;
	public type: number;
	public parent: Folder;
	public sortOrder: number = 999;

	constructor(type:number, id: any, name: any, subcategories:any, parent:Folder = null, sortOrder: number = 999, isOpen: boolean = false) {
		this.type = type;
		this.mediaCategoryID = id;
		this.mediaCategoryDesc = name;
		this.parent = parent;
		this.sortOrder = sortOrder;
		this.isOpen = isOpen;
		this.MediaCategories = new Array<Folder>();
		if(subcategories) {
			this.MediaCategories = subcategories.map((f:Folder) => {
				return new Folder(type, f.mediaCategoryID, f.mediaCategoryDesc, f.MediaCategories, this);
			})
		}
	}
	public get categoryTypeId():string { return `${this.type}_${this.mediaCategoryID}`; }
	public static create(o:any) {
		return new Folder(o.type, o.mediaCategoryID, o.mediaCategoryDesc, o.MediaCategories, o.parent, o.sortOrder, o.isOpen)
	}

	public removeFolderDeep(folder: Folder) {
		this.doRemoveFolder(this.MediaCategories, folder)
	}
	private doRemoveFolder(parent: Array<Folder>, toDelete: Folder) {
		for(var i = 0; i < parent.length; i++) {
			if(parent[i].type == toDelete.type && parent[i].mediaCategoryID == toDelete.mediaCategoryID) {
				parent.splice(i, 1);
				return;
			} else {
				if(parent[i].MediaCategories) {
					this.doRemoveFolder(parent[i].MediaCategories, toDelete);
				}
			}
		}
		
	}
}