import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appStylizedCheckbox]'
})
export class StylizeCheckboxDirective {

  	constructor(el:ElementRef) {
		let wrapper = document.createElement('div');
		wrapper.className = "stylizedCheckboxWrapper";
		el.nativeElement.parentNode.insertBefore(wrapper, el.nativeElement);
		wrapper.appendChild(el.nativeElement);

	  	let frag:DocumentFragment = document.createDocumentFragment();
		let span:HTMLElement = document.createElement('span');
		span.className = "checkboxSpan";
		frag.appendChild(span);
	  	wrapper.insertBefore(frag, el.nativeElement.nextSibling);
   	}

}
