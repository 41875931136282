import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CurrentSection } from '../health-details.component';
import * as moment from 'moment';

@Component({
  selector: 'acc-main-driving-violations-form',
  templateUrl: './driving-violations-form.component.html',
  styleUrls: ['./driving-violations-form.component.scss']
})
export class DrivingViolationsFormComponent implements OnInit {
  public haveDuiOrDwi: boolean;
  public isDrivingLicenseRevoke: boolean;
  public haveMotorVehicleAccident: boolean;
  public haveMovingValidation: boolean;
  public haveRecklessDrivingViolation: boolean;
  public availableInputs: Array<number>;
  public todaysDate: string;
  public fiveYearsAgoDate: string;

  @Input() drivingViolations: FormGroup;
  @Input() selectedSection: CurrentSection;

  constructor() { }

  ngOnInit() {
    this.todaysDate = moment(new Date()).format('YYYY-MM-DD');
    this.fiveYearsAgoDate = moment(new Date(`${(+moment(this.todaysDate).format('YYYY') - 5)}-01-01`)).format('YYYY-MM-DD');
    this.availableInputs = Array(5).fill(1).map((x, i) => i + 1);
    this.watchHowManyRadioBtns();
  }

  watchHowManyRadioBtns() {
    this.drivingViolations.get('duiDwi').get('howMany').valueChanges.subscribe(data => {
      if (data == null) { this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates1').setValue(''); }
      if (data == null || data < 2) { this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates2').setValue(''); }
      if (data == null || data < 3) { this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates3').setValue(''); }
      if (data == null || data < 4) { this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates4').setValue(''); }
      if (data == null || data < 5) { this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates5').setValue(''); }
    });
    this.drivingViolations.get('licenseSuspension').get('howMany').valueChanges.subscribe(data => {
      if (data == null) { this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates1').setValue(''); }
      if (data == null || data < 2) { this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates2').setValue(''); }
      if (data == null || data < 3) { this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates3').setValue(''); }
      if (data == null || data < 4) { this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates4').setValue(''); }
      if (data == null || data < 5) { this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates5').setValue(''); }
    });
    this.drivingViolations.get('motorVehicleAccident').get('howMany').valueChanges.subscribe(data => {
      if (data == null) { this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates1').setValue(''); }
      if (data == null || data < 2) { this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates2').setValue(''); }
      if (data == null || data < 3) { this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates3').setValue(''); }
      if (data == null || data < 4) { this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates4').setValue(''); }
      if (data == null || data < 5) { this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates5').setValue(''); }
    });
    this.drivingViolations.get('movingViolation').get('howMany').valueChanges.subscribe(data => {
      if (data == null) { this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates1').setValue(''); }
      if (data == null || data < 2) { this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates2').setValue(''); }
      if (data == null || data < 3) { this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates3').setValue(''); }
      if (data == null || data < 4) { this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates4').setValue(''); }
      if (data == null || data < 5) { this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates5').setValue(''); }
    });
    this.drivingViolations.get('recklessDriving').get('howMany').valueChanges.subscribe(data => {
      if (data == null) { this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates1').setValue(''); }
      if (data == null || data < 2) { this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates2').setValue(''); }
      if (data == null || data < 3) { this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates3').setValue(''); }
      if (data == null || data < 4) { this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates4').setValue(''); }
      if (data == null || data < 5) { this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates5').setValue(''); }
    });
  }

  onDrivingConvictionChange(value: boolean) {
    this.haveDuiOrDwi = value;
    if (!value) {
      this.drivingViolations.get('duiDwi').get('howMany').setValue('');
      this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates1').setValue('');
      this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates2').setValue('');
      this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates3').setValue('');
      this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates4').setValue('');
      this.drivingViolations.get('duiDwi').get('drivingDrunkDrivingDates5').setValue('');
    }
  }

  onDrivingLicenseRevokedChange(value: boolean) {
    this.isDrivingLicenseRevoke = value;
    if (!value) {
      this.drivingViolations.get('licenseSuspension').get('howMany').setValue('');
      this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates1').setValue('');
      this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates2').setValue('');
      this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates3').setValue('');
      this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates4').setValue('');
      this.drivingViolations.get('licenseSuspension').get('drivingLicenseSuspensionDates5').setValue('');
    }
  }

  onMotorVehicleAccidentExistsChange(value: boolean) {
    this.haveMotorVehicleAccident = value;
    if (!value) {
      this.drivingViolations.get('motorVehicleAccident').get('howMany').setValue('');
      this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates1').setValue('');
      this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates2').setValue('');
      this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates3').setValue('');
      this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates4').setValue('');
      this.drivingViolations.get('motorVehicleAccident').get('drivingMotorVehicleAccidentDates5').setValue('');
    }
  }

  onMovingViolationExistsChange(value: boolean) {
    this.haveMovingValidation = value;
    if (!value) {
      this.drivingViolations.get('movingViolation').get('howMany').setValue('');
      this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates1').setValue('');
      this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates2').setValue('');
      this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates3').setValue('');
      this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates4').setValue('');
      this.drivingViolations.get('movingViolation').get('drivingMovingViolationDates5').setValue('');
    }
  }

  onRecklessDrivingViolationExistsChange(value: boolean) {
    this.haveRecklessDrivingViolation = value;
    if (!value) {
      this.drivingViolations.get('recklessDriving').get('howMany').setValue('');
      this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates1').setValue('');
      this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates2').setValue('');
      this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates3').setValue('');
      this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates4').setValue('');
      this.drivingViolations.get('recklessDriving').get('drivingRecklessDrivingDates5').setValue('');
    }
  }

  onViolationDateChange(dateValue: string, group: string, field: string) {
		if (dateValue && moment(dateValue).isAfter(this.todaysDate)) {
			this.drivingViolations.get(`${group}`).get(`${field}`).setValue(this.todaysDate);
    } else if (dateValue && moment(dateValue).isBefore(this.fiveYearsAgoDate)) {
      this.drivingViolations.get(`${group}`).get(`${field}`).setValue(this.fiveYearsAgoDate);
    }
	}

}
