import { Action } from '@ngrx/store';
import { Media, MediaCategory } from 'acc-models';

export enum MediaActionTypes {
	GET_MEDIAS = '[MEDIAS] Get Medias',
	GET_MEDIAS_SUCCESS = '[MEDIAS] Get Medias Success',
	EDIT_MEDIA = '[MEDIAS] Edit Media',
	EDIT_MEDIA_CONFIRM = '[MEDIAS] Edit Media Confirm',
	EDIT_MEDIA_CANCEL = '[MEDIAS] Edit Media Cancel',
	EDIT_MEDIA_SUCCESS = '[MEDIAS] Edit Media Success',
	DELETE_MEDIA = '[MEDIAS] Delete Media',
	DELETE_MEDIA_CONFIRM = '[MEDIAS] Delete Media Confirm',
	DELETE_MEDIA_CANCEL = '[MEDIAS] Delete Media Cancel',
	DELETE_MEDIA_SUCCESS = '[MEDIAS] Delete Media Success',
	ADD_MEDIA = '[MEDIAS] Add Media',
	ADD_MEDIA_CONFIRM = '[MEDIAS] Add Media Confirm',
	ADD_MEDIA_CANCEL = '[MEDIAS] Add Media Cancel',
	ADD_MEDIA_SUCCESS = '[MEDIAS] Add Media Success',
	MOVE_MEDIA = '[MEDIAS] Move Media',
	MOVE_MEDIA_SUCCESS = '[MEDIAS] Move Media Success',
	RESET = '[ROOT] Reset'
}

export enum MediaCategoryActionTypes {
	EDIT_MULTIPLE = '[MEDIACATEGORIES] Edit Multiple Categories',
	GET_MEDIA_CATEGORIES = '[MEDIACATEGORIES] Get Media Categories',
	GET_MEDIA_CATEGORIES_SUCCESS = '[MEDIACATEGORIES] Get Media Categories Success',
	ACTIVATE_MEDIA_CATEGORY = '[MEDIACATEGORIES] Activate',
	TOGGLE_MEDIA_CATEGORY = '[MEDIACATEGORIES] Toggle Media Category',
	EDIT_MEDIA_CATEGORY = '[MEDIACATEGORIES] Edit Media Category',
	EDIT_MEDIA_CATEGORY_CONFIRM = '[MEDIACATEGORIES] Edit Media Category Confirm',
	EDIT_MEDIA_CATEGORY_CANCEL = '[MEDIACATEGORIES] Edit Media Category Cancel',
	EDIT_MEDIA_CATEGORY_SUCCESS = '[MEDIACATEGORIES] Edit Media Category Success',
	DELETE_MEDIA_CATEGORY = '[MEDIACATEGORIES] Delete Media Category',
	DELETE_MEDIA_CATEGORY_CONFIRM = '[MEDIACATEGORIES] Delete Media Category Confirm',
	DELETE_MEDIA_CATEGORY_CANCEL = '[MEDIACATEGORIES] Delete Media Category Cancel',
	DELETE_MEDIA_CATEGORY_SUCCESS = '[MEDIACATEGORIES] Delete Media Category Success',
	ADD_MEDIA_CATEGORY = '[MEDIACATEGORIES] Add Media Category',
	ADD_MEDIA_CATEGORY_SUCCESS = '[MEDIACATEGORIES] Add Media Category Success',
	MOVE_MEDIA_CATEGORY = '[MEDIACATEGORIES] Move Media Category',
	MOVE_MEDIA_CATEGORY_SUCCESS = '[MEDIACATEGORIES] Move Media Category Success',
}

export class GetMedias implements Action {
	public readonly type = MediaActionTypes.GET_MEDIAS;
	constructor(public mediaCategoryID: number) {}
}
export class GetMediasSuccess implements Action {
	public readonly type = MediaActionTypes.GET_MEDIAS_SUCCESS;
	constructor(public medias: Array<Media>) {}
}
export class EditMedia implements Action {
	public readonly type = MediaActionTypes.EDIT_MEDIA;
	constructor(public media: Media) {}
}
export class EditMediaConfirm implements Action {
	public readonly type = MediaActionTypes.EDIT_MEDIA_CONFIRM;
	constructor(public media: Media, public mediaFile: File, public mediaThumbnail: File) {}
}
export class EditMediaCancel implements Action {
	public readonly type = MediaActionTypes.EDIT_MEDIA_CANCEL;
	constructor() {}
}
export class EditMediaSuccess implements Action {
	public readonly type = MediaActionTypes.EDIT_MEDIA_SUCCESS;
	constructor(public media: Media) {}
}
export class DeleteMedia implements Action {
	public readonly type = MediaActionTypes.DELETE_MEDIA;
	constructor(public media: Media) {}
}
export class DeleteMediaConfirm implements Action {
	public readonly type = MediaActionTypes.DELETE_MEDIA_CONFIRM;
	constructor() {}
}
export class DeleteMediaCancel implements Action {
	public readonly type = MediaActionTypes.DELETE_MEDIA_CANCEL;
	constructor() {}
}
export class DeleteMediaSuccess implements Action {
	public readonly type = MediaActionTypes.DELETE_MEDIA_SUCCESS;
	constructor() {}
}
export class AddMedia implements Action {
	public readonly type = MediaActionTypes.ADD_MEDIA;
	constructor(public media: Media) {}
}
export class AddMediaConfirm implements Action {
	public readonly type = MediaActionTypes.ADD_MEDIA_CONFIRM;
	constructor(public media: Media, public mediaFile: File, public mediaThumbnail: File) {}
}
export class AddMediaCancel implements Action {
	public readonly type = MediaActionTypes.ADD_MEDIA_CANCEL;
	constructor(public media: Media, public mediaFile: File, public mediaThumbnail: File) {}
}
export class AddMediaSuccess implements Action {
	public readonly type = MediaActionTypes.ADD_MEDIA_SUCCESS;
	constructor(public media:Media) {}
}
export class MoveMedia implements Action {
	public readonly type = MediaActionTypes.MOVE_MEDIA;
	constructor(public media: Media, public destinationFolder: number) {}
}
export class MoveMediaSuccess implements Action {
	public readonly type = MediaActionTypes.MOVE_MEDIA_SUCCESS;
	constructor() {}
}


export class GetMediaCategories implements Action {
	public readonly type = MediaCategoryActionTypes.GET_MEDIA_CATEGORIES;
	constructor() {}
}
export class GetMediaCategoriesSuccess implements Action {
	public readonly type = MediaCategoryActionTypes.GET_MEDIA_CATEGORIES_SUCCESS;
	constructor(public mediaCategories: Array<MediaCategory>) {}
}
export class ActivateMediaCategory implements Action {
	public readonly type = MediaCategoryActionTypes.ACTIVATE_MEDIA_CATEGORY;
	constructor(public mediaCategory: MediaCategory) {}
}
export class ToggleMediaCategory implements Action {
	public readonly type = MediaCategoryActionTypes.TOGGLE_MEDIA_CATEGORY;
	constructor(public mediaCategory: MediaCategory) {}
}
export class EditMediaCategory implements Action {
	public readonly type = MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY;
	constructor(public mediaCategory: MediaCategory) {}
}
export class EditMediaCategoryConfirm implements Action {
	public readonly type = MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY_CONFIRM;
	constructor() {}
}
export class EditMediaCategoryCancel implements Action {
	public readonly type = MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY_CANCEL;
	constructor() {}
}
export class EditMediaCategorySuccess implements Action {
	public readonly type = MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY_SUCCESS;
	constructor() {}
}
export class DeleteMediaCategory implements Action {
	public readonly type = MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY;
	constructor(public mediaCategory: MediaCategory) {}
}
export class DeleteMediaCategoryConfirm implements Action {
	public readonly type = MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY_CONFIRM;
	constructor() {}
}
export class DeleteMediaCategoryCancel implements Action {
	public readonly type = MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY_CANCEL;
	constructor() {}
}
export class DeleteMediaCategorySuccess implements Action {
	public readonly type = MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY_SUCCESS;
	constructor() {}
}
export class AddMediaCategory implements Action {
	public readonly type = MediaCategoryActionTypes.ADD_MEDIA_CATEGORY;
	constructor(public mediaCategory: MediaCategory) {}
}
export class AddMediaCategorySuccess implements Action {
	public readonly type = MediaCategoryActionTypes.ADD_MEDIA_CATEGORY_SUCCESS;
	constructor(public mediaCategoryID: number) {}
}
export class MoveMediaCategory implements Action {
	public readonly type = MediaCategoryActionTypes.MOVE_MEDIA_CATEGORY;
	constructor(public mediaCategory: MediaCategory, public parentCategory: MediaCategory) {}
}
export class MoveMediaCategorySuccess implements Action {
	public readonly type = MediaCategoryActionTypes.MOVE_MEDIA_CATEGORY_SUCCESS;
	constructor() {}
}
export class EditMultipleMediaCategories implements Action {
	public readonly type = MediaCategoryActionTypes.EDIT_MULTIPLE;
	constructor(public mediaCategories: Array<MediaCategory>) {}
}

export class MediaReset implements Action {
	public readonly type = MediaActionTypes.RESET;
	constructor() {}
}

export type MediaActions =
	GetMedias |
	GetMediasSuccess |
	EditMedia |
	EditMediaConfirm |
	EditMediaCancel |
	EditMediaSuccess |
	DeleteMedia |
	DeleteMediaConfirm |
	DeleteMediaCancel |
	DeleteMediaSuccess |
	AddMedia |
	AddMediaConfirm |
	AddMediaCancel |
	AddMediaSuccess |
	MoveMedia |
	MoveMediaSuccess |
	
	GetMediaCategories |
	GetMediaCategoriesSuccess |
	ActivateMediaCategory |
	ToggleMediaCategory |
	EditMediaCategory |
	EditMediaCategoryConfirm |
	EditMediaCategoryCancel |
	EditMediaCategorySuccess |
	DeleteMediaCategory |
	DeleteMediaCategoryConfirm |
	DeleteMediaCategoryCancel |
	DeleteMediaCategorySuccess |
	AddMediaCategory |
	AddMediaCategorySuccess |
	MoveMediaCategory |
	MoveMediaCategorySuccess |
	EditMultipleMediaCategories |
	
	MediaReset;
