
export class QuoteRecFilter {

	public recID: number;
	public faceAmount: number;
	public annualPremium: number;
	public companyID: number;
	public carrierDesc: string;
	public term: number;
	public productID: number;
	public productName: string;
	public sortOrder: number;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class QuoteRecSort {
	public term: number;
	public faceAmount: number;
	public recId: number;
	public sortOrder: number;
}