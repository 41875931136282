import { Component, OnInit, Output } from "@angular/core";
import { Subject } from 'rxjs';

@Component({
	selector: "acc-main-help-dialog",
	templateUrl: "./help-dialog.component.html",
	styleUrls: ["./help-dialog.component.scss"],
})
export class HelpDialogComponent implements OnInit {

	public visible: boolean;
	@Output() onClose: Subject<boolean> = new Subject();

	constructor() {}

	ngOnInit() {
		setTimeout(() => (this.visible = true), 5);
	}

	close() {
		this.onClose.next();
	}
}
