import { Action } from '@ngrx/store';
import { Category, SubCategory, Module, Text } from 'acc-models';


export enum CategoryActionTypes {
	GET_MODULES = '[MODULES] Get',
	GET_MODULES_SUCCESS = '[MODULES] Get Success',

	GET_CATEGORIES = '[CATEGORY] Get',
	GET_CATEGORIES_SUCCESS  = '[CATEGORY] Get Success',
	ACTIVATE_CATEGORY = '[CATEGORY] Activate',
	ADD_CATEGORY = '[CATEGORY] Add',
	ADD_CATEGORY_SUCCESS = '[CATEGORY] Add Success',
	EDIT_CATEGORY = '[CATEGORY] Edit',
	EDIT_CATEGORY_SAVE = '[CATEGORY] Edit Save',
	EDIT_CATEGORY_CANCEL = '[CATEGORY] Edit Cancel',
	EDIT_CATEGORY_SUCCESS = '[CATEGORY] Edit Success',
	DELETE_CATEGORY = '[CATEGORY] Delete',
	DELETE_CATEGORY_CONFIRM = '[CATEGORY] Delete Confirm',
	DELETE_CATEGORY_CANCEL = '[CATEGORY] Delete Cancel',
	DELETE_CATEGORY_SUCCESS = '[CATEGORY] Delete Success',
	SAVE_CATEGORIES = '[CATEGORY] Save All',
	SAVE_CATEGORIES_SUCCESS = '[CATEGORY] Save All Success',
	
	ACTIVATE_SUBCATEGORY = '[SUBCATEGORY] Activate',
	ADD_SUBCATEGORY = '[SUBCATEGORY] Add',
	ADD_SUBCATEGORY_SUCCESS = '[SUBCATEGORY] Add Success',
	EDIT_SUBCATEGORY = '[SUBCATEGORY] Edit',
	EDIT_SUBCATEGORY_SAVE = '[SUBCATEGORY] Edit Save',
	EDIT_SUBCATEGORY_CANCEL = '[SUBCATEGORY] Edit Cancel',
	EDIT_SUBCATEGORY_SUCCESS = '[SUBCATEGORY] Edit Success',
	DELETE_SUBCATEGORY = '[SUBCATEGORY] Delete',
	DELETE_SUBCATEGORY_CONFIRM = '[SUBCATEGORY] Delete Confirm',
	DELETE_SUBCATEGORY_CANCEL = '[SUBCATEGORY] Delete Cancel',
	DELETE_SUBCATEGORY_SUCCESS = '[SUBCATEGORY] Delete Success',
	SAVE_SUBCATEGORIES = '[SUBCATEGORY] Save All',
	SAVE_SUBCATEGORIES_SUCCESS = '[SUBCATEGORY] Save All Success',

	GET_SUBCAT_TEXTS_SUCCESS = '[TEXTS] Get Success',
	ACTIVATE_SUBCAT_TEXT = '[TEXTS] Activate',
	ADD_SUBCAT_TEXT = '[TEXTS] Add Text',
	ADD_SUBCAT_TEXT_SUCCESS = '[TEXTS] Add Text Success',
	EDIT_SUBCAT_TEXT = '[TEXTS] Edit Text',
	EDIT_SUBCAT_TEXT_SUCCESS = '[TEXTS] Edit Text Success',
	EDIT_SUBCAT_TEXT_CANCEL = '[TEXTS] Edit Text Cancel',
	EDIT_SUBCAT_TEXT_CONFIRM = '[TEXTS] Edit Text Confirm',
	DELETE_SUBCAT_TEXT = '[TEXTS] Delete Text',
	DELETE_SUBCAT_TEXT_CONFIRM = '[TEXTS] Delete Text Confirm',
	DELETE_SUBCAT_TEXT_CANCEL = '[TEXTS] Delete Text Cancel',
	DELETE_SUBCAT_TEXT_SUCCESS = '[TEXTS] Delete Text Success',
	UPDATE_SUBCAT_TEXTS = '[TEXTS] Save All Texts',
	UPDATE_SUBCAT_TEXTS_SUCCESS = '[TEXTS] Save All Texts Success',

	RESET = '[ROOT] Reset'
	
}

export class GetModules implements Action {
	public readonly type = CategoryActionTypes.GET_MODULES;
	constructor(public orgId: number) {}
}
export class GetModulesSuccess implements Action {
	public readonly type = CategoryActionTypes.GET_MODULES_SUCCESS;
	constructor(public modules: Array<Module>){}
}
export class GetCategories implements Action {
	public readonly type = CategoryActionTypes.GET_CATEGORIES;
	constructor(public moduleId: number) {}
}
export class GetCategoriesSuccess implements Action {
	public readonly type = CategoryActionTypes.GET_CATEGORIES_SUCCESS;
	constructor(public categories: Array<Category>) {}
}
export class ActivateCategory implements Action {
	public readonly type = CategoryActionTypes.ACTIVATE_CATEGORY;
	constructor(public category:Category) {}
}
export class EditCategory implements Action {
	public readonly type = CategoryActionTypes.EDIT_CATEGORY;
	constructor(public category:Category) {}
}
export class EditCategorySave implements Action {
	public readonly type = CategoryActionTypes.EDIT_CATEGORY_SAVE;
	constructor(public category: Category) {}
}
export class EditCategoryCancel implements Action {
	public readonly type = CategoryActionTypes.EDIT_CATEGORY_CANCEL;
	constructor() {}
}
export class EditCategorySuccess implements Action {
	public readonly type = CategoryActionTypes.EDIT_CATEGORY_SUCCESS;
	constructor() {}
}
export class DeleteCategory implements Action {
	public readonly type = CategoryActionTypes.DELETE_CATEGORY;
	constructor(public category:Category) {}
}
export class DeleteCategoryConfirm implements Action {
	public readonly type = CategoryActionTypes.DELETE_CATEGORY_CONFIRM;
	constructor() {}
}
export class DeleteCategoryCancel implements Action {
	public readonly type = CategoryActionTypes.DELETE_CATEGORY_CANCEL;
	constructor() {}
}
export class DeleteCategorySuccess implements Action {
	public readonly type = CategoryActionTypes.DELETE_CATEGORY_SUCCESS;
	constructor() {}
}
export class AddCategory implements Action {
	public readonly type = CategoryActionTypes.ADD_CATEGORY;
	constructor(public moduleId: number, public category: Category) {}
}
export class AddCategorySuccess implements Action {
	public readonly type = CategoryActionTypes.ADD_CATEGORY_SUCCESS;
	constructor(public category:Category) {}
}
export class SaveCategories implements Action {
	public readonly type = CategoryActionTypes.SAVE_CATEGORIES;
	constructor(public categories:Array<any>) {}
}
export class SaveCategoriesSuccess implements Action {
	public readonly type = CategoryActionTypes.SAVE_CATEGORIES_SUCCESS;
	constructor() {}
}


export class ActivateSubcategory implements Action {
	public readonly type = CategoryActionTypes.ACTIVATE_SUBCATEGORY;
	constructor(public subcategory:SubCategory) {}
}
export class AddSubcategory implements Action {
	public readonly type = CategoryActionTypes.ADD_SUBCATEGORY;
	constructor(public subcategory:SubCategory) {}
}
export class AddSubcategorySuccess implements Action {
	public readonly type = CategoryActionTypes.ADD_SUBCATEGORY_SUCCESS;
	constructor(public subcategory:SubCategory) {}
}
export class EditSubcategory implements Action {
	public readonly type = CategoryActionTypes.EDIT_SUBCATEGORY;
	constructor(public subcategory:SubCategory) {}
}
export class EditSubcategorySave implements Action {
	public readonly type = CategoryActionTypes.EDIT_SUBCATEGORY_SAVE;
	constructor(public subcategory: SubCategory) {}
}
export class EditSubcategoryCancel implements Action {
	public readonly type = CategoryActionTypes.EDIT_SUBCATEGORY_CANCEL;
	constructor() {}
}
export class EditSubcategorySuccess implements Action {
	public readonly type = CategoryActionTypes.EDIT_SUBCATEGORY_SUCCESS;
	constructor() {}
}
export class DeleteSubcategory implements Action {
	public readonly type = CategoryActionTypes.DELETE_SUBCATEGORY;
	constructor(public subcategory:SubCategory) {}
}
export class DeleteSubcategoryConfirm implements Action {
	public readonly type = CategoryActionTypes.DELETE_SUBCATEGORY_CONFIRM;
	constructor() {}
}
export class DeleteSubcategoryCancel implements Action {
	public readonly type = CategoryActionTypes.DELETE_SUBCATEGORY_CANCEL;
	constructor() {}
}
export class DeleteSubcategorySuccess implements Action {
	public readonly type = CategoryActionTypes.DELETE_SUBCATEGORY_SUCCESS;
	constructor() {}
}
export class SaveSubcategories implements Action {
	public readonly type = CategoryActionTypes.SAVE_SUBCATEGORIES;
	constructor(public subcategories: Array<any>) {}
}
export class SaveSubcategoriesSuccess implements Action {
	public readonly type = CategoryActionTypes.SAVE_SUBCATEGORIES_SUCCESS;
	constructor() {}
}

export class GetSubcatTextsSuccess implements Action {
	public readonly type = CategoryActionTypes.GET_SUBCAT_TEXTS_SUCCESS;
	constructor(public texts: Array<Text>) {}
}
export class ActivateSubcatText implements Action {
	public readonly type = CategoryActionTypes.ACTIVATE_SUBCAT_TEXT;
	constructor(public text: Text) {}
}
export class AddSubcatText implements Action {
	public readonly type = CategoryActionTypes.ADD_SUBCAT_TEXT;
	constructor(public text: Text) {}
}
export class AddSubcatTextSuccess implements Action {
	public readonly type = CategoryActionTypes.ADD_SUBCAT_TEXT_SUCCESS;
	constructor(public text: Text) {}
}
export class EditSubcatText implements Action {
	public readonly type = CategoryActionTypes.EDIT_SUBCAT_TEXT;
	constructor(public text: Text) {}
}
export class EditSubcatTextConfirm implements Action {
	public readonly type = CategoryActionTypes.EDIT_SUBCAT_TEXT_CONFIRM;
	constructor(public text: Text) {}
}
export class EditSubcatTextCancel implements Action {
	public readonly type = CategoryActionTypes.EDIT_SUBCAT_TEXT_CANCEL;
	constructor() {}
}
export class EditSubcatTextSuccess implements Action {
	public readonly type = CategoryActionTypes.EDIT_SUBCAT_TEXT_SUCCESS;
	constructor() {}
}
export class DeleteSubcatText implements Action {
	public readonly type = CategoryActionTypes.DELETE_SUBCAT_TEXT;
	constructor(public text: Text) {}
}
export class DeleteSubcatTextConfirm implements Action {
	public readonly type = CategoryActionTypes.DELETE_SUBCAT_TEXT_CONFIRM;
	constructor() {}
}
export class DeleteSubcatTextCancel implements Action {
	public readonly type = CategoryActionTypes.DELETE_SUBCAT_TEXT_CANCEL;
	constructor() {}
}
export class DeleteSubcatTextSuccess implements Action {
	public readonly type = CategoryActionTypes.DELETE_SUBCAT_TEXT_SUCCESS;
	constructor() {}
}
export class UpdateSubcatTexts implements Action {
	public readonly type = CategoryActionTypes.UPDATE_SUBCAT_TEXTS;
	constructor(public texts: Array<any>) {}
}
export class UpdateSubcatTextsSuccess implements Action {
	public readonly type = CategoryActionTypes.UPDATE_SUBCAT_TEXTS_SUCCESS;
	constructor() {}
}
export class Reset implements Action {
	public readonly type = CategoryActionTypes.RESET;
	constructor() {}
}



export type CategoriesActions = 
	GetModules |
	GetModulesSuccess |
	
	GetCategories |
	GetCategoriesSuccess |
	ActivateCategory |
	EditCategory |
	EditCategoryCancel |
	EditCategorySave |
	EditCategorySuccess |
	DeleteCategory |
	DeleteCategoryCancel |
	DeleteCategoryConfirm |
	DeleteCategorySuccess |
	AddCategory |
	AddCategorySuccess |
	SaveCategories |
	SaveCategoriesSuccess |
	
	ActivateSubcategory |
	AddSubcategory |
	AddSubcategorySuccess |
	EditSubcategory |
	EditSubcategoryCancel |
	EditSubcategorySave |
	EditSubcategorySuccess |
	DeleteSubcategory |
	DeleteSubcategoryCancel |
	DeleteSubcategoryConfirm |
	DeleteSubcategorySuccess |
	SaveSubcategories |
	SaveSubcategoriesSuccess |
	
	GetSubcatTextsSuccess |
	ActivateSubcatText |
	AddSubcatText |
	AddSubcatTextSuccess |
	EditSubcatText |
	EditSubcatTextCancel |
	EditSubcatTextConfirm |
	EditSubcatTextSuccess |
	DeleteSubcatText |
	DeleteSubcatTextCancel |
	DeleteSubcatTextConfirm |
	DeleteSubcatTextSuccess |
	UpdateSubcatTexts |
	UpdateSubcatTextsSuccess |
	Reset;

