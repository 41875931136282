import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccCommonModule } from 'acc-common';
import { QuoteResultsRoutingModule } from './quote-results-routing.module';
import { QuotesResultsComponent } from './quotes-results/quotes-results.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuoteDetailComponent } from './quote-detail/quote-detail.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuoteListComponent } from './quote-list/quote-list.component';
import { FormsModule } from '@angular/forms';
import { CanDeactivateQuote } from '../components/guard/can-deactivate-quote';
import { CookieService } from 'ngx-cookie-service';
import { SendProposalButtonComponent } from '../components/shared/send-proposal-button/send-proposal-button.component';
import { CopyQuoteButtonComponent } from '../components/shared/copy-quote-button/copy-quote-button.component';
import { ApplyDialogComponent } from './apply-dialog/apply-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [QuotesResultsComponent, QuoteDetailComponent, QuoteListComponent, SendProposalButtonComponent, CopyQuoteButtonComponent, ApplyDialogComponent],
  imports: [
    CommonModule,
    AccCommonModule,
    QuoteResultsRoutingModule,
    MatTabsModule,
    MatExpansionModule,
    MatTableModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    DragDropModule,
    MatIconModule,
    FormsModule,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
  ],
  entryComponents: [QuoteDetailComponent, ApplyDialogComponent],
  providers: [
    CanDeactivateQuote,
    CookieService
  ]
})
export class QuoteResultsModule { }
