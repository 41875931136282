import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Folder } from 'acc-models';

@Component({
  selector: 'acc-common-move-folder',
  templateUrl: './move-folder.component.html',
  styleUrls: ['./move-folder.component.scss']
})
export class MoveFolderComponent implements OnInit {

	public selectedFolder: Folder;

	constructor(public dialogRef: MatDialogRef<MoveFolderComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

	selectDestination(actionInfo) {
		this.selectedFolder = actionInfo.payload.folder;
	}
	close() {
		this.dialogRef.close();
	}
	moveFolder() {
		this.dialogRef.close({source: this.data.movingFolder, destination: this.selectedFolder});
	}
	ngOnInit() {
	}

}
