import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { ConfirmComponent } from 'acc-common';
import { Organization } from 'acc-models';
import { CanComponentDeactivate, QuoteService, SessionService } from 'acc-services';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SetErrorMessage } from '../../store/actions/status.actions';
import { IAppState } from '../../store/state/app.state';

@Component({
  selector: 'acc-main-carrier-products-filter',
  templateUrl: './carrier-products-filter.component.html',
  styleUrls: ['./carrier-products-filter.component.scss']
})
export class CarrierProductsFilterComponent implements OnInit, CanComponentDeactivate {

  allProducts: Array<any> = [];
  allCarriers: Array<any> = [];

  productsFilter: Array<any> = [];
  private org: Organization;
  public loading = true;
  
  private changesDialogRef: MatDialogRef<ConfirmComponent>;
  private selectionChanged: boolean = false;
  private isMasterOrganization: boolean = false;

  constructor(private quoteService: QuoteService, private sessionService: SessionService, private store: Store<IAppState>, private snackBar: MatSnackBar,  private dialog: MatDialog) { 
    this.org = this.sessionService.getItem("organization") as Organization;
    this.isMasterOrganization = this.isAMasterOrganization(this.org);
  }

  ngOnInit() {
    this.quoteService.getCarrierProductsFilter(this.org.orgID);
    this.getCarriersList();
  }

  isAMasterOrganization(org:Organization): boolean {
    return (org.isMasterOrganization || org.masterOrgID == null || org.masterOrgID == 0);
  }

  getAllProducts() {
    
    this.quoteService.getAllIPipelineProducts().subscribe(
			(resp:any) => {

        this.allProducts = resp;
        var selectedProductFilters = this.sessionService.carriersProductsFilters;
        if(this.isMasterOrganization){
          this.buildCarrierProductsFilterMaster(selectedProductFilters);
        }else{
          this.buildCarrierProductsFilterChild(selectedProductFilters);
        }

			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
			}
    );
	}

  getCarriersList(){
		this.quoteService.getCarriers().subscribe(
			(resp:any) => {
        this.allCarriers = resp.sort((a, b) => a.name.localeCompare(b.name));
        this.getAllProducts();

			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
			}
    );
  }

  buildCarrierProductsFilterMaster(selectedProductFilters: any[]){
    this.allCarriers.forEach(carrier => {
      var products = this.allProducts.filter(x => x.carrierID == carrier.id);
      products.forEach(p => {

        this.buildFilterSelection(selectedProductFilters, carrier,p);

      });
    });
    this.loading = false;
  }

  buildCarrierProductsFilterChild(selectedProductFilters: any[]){
    //Get list from master organization
    this.quoteService.getCarrierProductsMasterFilter(this.org.masterOrgID).subscribe((masterSelection:any) => {

      this.allCarriers.forEach(carrier => {
        var products = this.allProducts.filter(x => x.carrierID == carrier.id);
        products.forEach(p => {
        
          this.buildFilterForChildOrg(selectedProductFilters, masterSelection, carrier, p);
  
        });
      });

      this.loading = false;

    },
    (err: any) => {
      this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
    }
  );
    
  }

  buildFilterForChildOrg(selectedProductFilters, masterSelection, carrier, product){
    var selectedFromMaster = masterSelection.filter(x => x.carrierID == carrier.id && x.productID == product.productID).length > 0;
    
    if(selectedFromMaster){
      this.buildFilterSelection(selectedProductFilters, carrier, product);
    }

  }

  buildFilterSelection(selectedProductFilters, carrier, product){
    var selected = selectedProductFilters.filter(x => x.carrierID == carrier.id && x.productID == product.productID).length > 0;

    var filter = {
      carrierID : carrier.id,
      productID : +product.productID,
      carrierName : carrier.name,
      productName : product.productName,
      selected : selected
    }

    this.productsFilter.push(filter);
  }

  selectedChanged(value){
    var prod = this.productsFilter.find(x => x.carrierID == value.carrierID && x.productID == value.productID);
    prod.selected = !prod.selected;
    this.selectionChanged = true;
  }

  buildFilter(exclude: boolean){
    var filters = [];
    this.productsFilter.forEach(element => {
      if ((exclude && !element.selected) || (!exclude && element.selected)) {
        var f = {
          carrierID : element.carrierID,
          productID : element.productID,
        }

        filters.push(f);
      }
    });
    return filters;
  }

  save(){

    var filters = this.buildFilter(!this.isMasterOrganization);

    if(this.isMasterOrganization){
      //save Carrier Products Filters
      this.quoteService.saveCarrierProductsFilter(filters, this.org.orgID).subscribe(
        (resp:any) => {
          this.quoteService.getCarrierProductsFilter(this.org.orgID);
          this.snackBar.open('Filters Saved !', 'OK', { duration: 3000 });
          this.selectionChanged = false;
        },
        (err: any) => {
          this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
        }
      );
    }else{
      //Save Exclusion List
      this.quoteService.saveCarrierProductsFilterForChildOrganization(filters, this.org.orgID).subscribe(
        (resp:any) => {
          this.quoteService.getCarrierProductsFilter(this.org.orgID);
          this.snackBar.open('Filters Saved !', 'OK', { duration: 3000 });
          this.selectionChanged = false;
        },
        (err: any) => {
          this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
        }
      );
    }

  }

  canDeactivate():Observable<boolean> | boolean{
    return this.selectionChanged?this.canNavigateAway():true;
  } 
  
  canNavigateAway(): Observable<boolean> | boolean {
		this.changesDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `Are you sure you want to exit without saving?`,
			},
		});
		return this.changesDialogRef.afterClosed().pipe(take(1));
  }


}
