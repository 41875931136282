import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Folder } from 'acc-models';
import { MatMenuTrigger } from '@angular/material/menu';
import { tooltips } from "../../../tooltips/tooltips";

@Component({
  selector: 'acc-common-library-actions',
  templateUrl: './library-actions.component.html',
  styleUrls: ['./library-actions.component.scss']
})
export class LibraryActionsComponent implements OnInit {

	@Input() activeFolder: Folder;
	@Input() uploadEnabled: boolean;
	@Output() onPersonalize: EventEmitter<number> = new EventEmitter();
	@Output() onAddFolder:EventEmitter<number> = new EventEmitter();
	@Output() onUploadFile: EventEmitter<number> = new EventEmitter();
	@ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

	public showNewMenu: boolean = false;

	public tooltips = tooltips;

	constructor() { }

	ngOnInit() {
	}

	openNewMenu(event):void {
		if(this.activeFolder && this.activeFolder.type == 2) {
			this.showNewMenu = !this.showNewMenu;
			event.cancelBubble = true;
		}
	}
	onNewFolderClick(event) {
		event.cancelBubble = true;
		this.showNewMenu = !this.showNewMenu;
		this.trigger.closeMenu();
		setTimeout(() => {this.onAddFolder.emit(1); }, 1);
	}
	onUploadFileClick(event) {
		event.cancelBubble = true;
		this.trigger.closeMenu();
		if(this.uploadEnabled) {
			this.showNewMenu = !this.showNewMenu;
			this.onUploadFile.emit(1);
		}
	}
	onPersonalizeClick() {
		this.onPersonalize.emit(1);
	}
}
