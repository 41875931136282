import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { Document, SubCategory } from 'acc-models';


@Injectable()
export class DocGenService {
	
	public document: Document;

	private activeCategory = new BehaviorSubject<number>(0);
	public activeCategoryObservable = this.activeCategory.asObservable();

	private tryChangeCategorySource = new Subject<boolean>();
	public tryChangeCategory$ = this.tryChangeCategorySource.asObservable();

	private canChangeCategorySource = new Subject<boolean>();
	public canChangeCategory$ = this.canChangeCategorySource.asObservable();

	private currentDocumentSource = new Subject<Document>();
	public currentDocument$ = this.currentDocumentSource.asObservable();
	
	private subcatRenamedSource = new Subject<SubCategory>();
	public subcatRenamed$ = this.subcatRenamedSource.asObservable();

	private categoryCountSource = new Subject<number>();
	public categoryCount$ = this.categoryCountSource.asObservable();
	
    constructor() {}
    
	selectCategory(id) {
		this.activeCategory.next(id);
	}

	canChangeCategory(clean:boolean) {
		this.canChangeCategorySource.next(clean)
	}
	tryChangeCategory() {
		this.tryChangeCategorySource.next();
	}

	setCurrentDocument(doc:Document) {
		this.currentDocumentSource.next(doc);
		this.document = doc;
	}

	renameSubcat(subcat:SubCategory) {
		this.subcatRenamedSource.next(subcat);
	}

	setCategoryCount(count: number) {
		this.categoryCountSource.next(count);
	}
}