import { IQuoteState, initialQuoteState } from '../state/quote.state';
import { QuoteActions, QuoteActionTypes } from '../actions/quote.actions';
import { QuoteRequest, HealthProfile, HealthProfileAlt } from 'acc-models';

export function quoteReducer(state:IQuoteState = initialQuoteState, action:QuoteActions): IQuoteState {
	switch(action.type) {
		case QuoteActionTypes.NEW_QUOTE:
			return { ...state, request: new QuoteRequest(), healthProfile: new HealthProfile(), healthProfileAlt: new HealthProfileAlt() };
		case QuoteActionTypes.GET_REQUEST:
			return state;
		case QuoteActionTypes.SET_REQUEST: 
			return { ...state, request: action.quoteRequest }; 
		case QuoteActionTypes.GET_HEALTH_PROFILE:
			return state;
		case QuoteActionTypes.SET_HEALTH_PROFILE:
			return { ...state, healthProfile: action.healthProfile };
		case QuoteActionTypes.RESET:
			return initialQuoteState;
		case QuoteActionTypes.ADD_QUOTE_TO_CACHE:
			state.quotesCache.set(JSON.stringify(action.quoteRequest), action.quoteResponse);
			return { ...state };
		case QuoteActionTypes.GET_QUOTE_FROM_CACHE:
			return { ...state, response: state.quotesCache.get(JSON.stringify(action.quoteRequest)) };
		case QuoteActionTypes.GET_HEALTH_PROFILE_ALT:
			return state;
		case QuoteActionTypes.SET_HEALTH_PROFILE_ALT:
			return { ...state, healthProfileAlt: action.healthProfileAlt };
		case QuoteActionTypes.SET_SELECTIONS:
			return { ...state, selections: action.selections };
		case QuoteActionTypes.SET_REC_SORT:
			return { ...state, recSort: action.recSort };
		case QuoteActionTypes.SET_SELECTED_PLANS:
			return { ...state, selectedPlans: action.selectedPlans };
		case QuoteActionTypes.SET_SELLING_POINTS:
			return { ...state, sellingPoints: action.sellingPoints };
		case QuoteActionTypes.CLEAR_TEMPORARY_DATA:
			return { ...state, selections: null, recSort: null, selectedPlans: null, sellingPoints: null };
		default: 
			return state;
	}
}