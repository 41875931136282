import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from 'acc-models';
import { MixpanelService } from 'acc-services';

@Component({
  selector: 'acc-main-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

	@Output() onNavigateTo: EventEmitter<string> = new EventEmitter();
	@Output() onLogin: EventEmitter<User> = new EventEmitter();
	constructor(private mixpanelService: MixpanelService) { }

	ngOnInit() {
		this.mixpanelService.initAnonymousMixpanel();
	}

	navigateTo(page:string): void {
		this.onNavigateTo.next(page);
	}
	login(user:User): void {
		if(user) {
			this.onLogin.next(user);
		}
	}
}
