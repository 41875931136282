import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { SessionService } from 'acc-services';
import { User, Organization } from 'acc-models';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionLike } from 'rxjs';


@Component({
  selector: 'acc-main-module-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, OnDestroy {

	@Output() onAction: EventEmitter<{actionName: string, quoteId?: string}> = new EventEmitter();
	@Input() quoteId: string;

	private paramSubscription: SubscriptionLike;
	public user: User;
	public isTemplate: boolean = false;
	private moduleId: number;
	constructor(private sessionService: SessionService, private route: ActivatedRoute) { }

	ngOnInit() {
		this.user = this.sessionService.getUser();
		this.paramSubscription = this.route.paramMap.subscribe(params => {
			if(params.get('mode') == 'template') {
				this.isTemplate = true
			}
			this.moduleId = +params.get('moduleID');
		})
	}

	doAction(actionName: string, quoteId?: string): void {
		this.onAction.next({actionName, quoteId});
	}

	ngOnDestroy(): void {
		if(this.paramSubscription) { this.paramSubscription.unsubscribe(); }
	}
	get showTemplateLink(): boolean {
		return this.user.admin && !this.isTemplate && this.moduleId == this.sessionService.getItem<Organization>('organization').lifeQuoteModuleID && !this.quoteId;
	}
}
