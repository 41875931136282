<div class="container subscription-container">
  <mat-card class="container-card"  *ngIf="!this.subscriptionLoading">
    <mat-card-content >
      
      <form class="company__subscription--grid">

        <mat-card class="subscription__status__card subscription-card">
          <mat-card-header>
            <mat-card-title>Subscription Status: {{this.subscriptionStatus}}</mat-card-title>
            <mat-card-subtitle *ngIf="!!this.isTrial && !this.hasCCInformation">
              <br>
              <p>You are using Accelerate PRO for a 30-day trial period and have {{trialEnds}} day{{trialEnds == 1 ? '' : 's'}} left in Trial mode. To purchase a non-trial license of Accelerate PRO see below. </p>
              <p>Your PRO trial license will revert to Accelerate Basic (FREE) if you don't upgrade in 30 days.</p>
              <br>
              Click <span class="underline" (click)="showFeaturesDialog()" >here</span> to learn more about Accelerate PRO.
            </mat-card-subtitle>
            <mat-card-subtitle class="subscription-subtitle" *ngIf="!this.canceledAt && !this.isTrial || ( !!this.isTrial && !!this.hasCCInformation)">
              <br>
              Your credit card will be charged on {{nextBillingDate | date:'longDate'}}, for the billing period from {{nextPeriodStart | date:'longDate'}} to {{nextPeriodEnd | date:'longDate'}}.
              <br>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div *ngIf="!!this.canceledAt" class="cancelled-label">
              Your subscription has been cancelled on {{canceledAt | date:'longDate'}}. When your current paid period ends on {{currentPeriodEnd | date:'longDate'}}, you will automatically be reverted to the free version.
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!this.subscription && !this.subscriptionLoading" class="subscription__status__card subscription-card">
          <mat-card-header>
            <mat-card-title>Subscription Status: FREE </mat-card-title>
            <mat-card-subtitle>You are using the Free version. The PRO version costs ${{this.subscriptionAmount / 100}} per user per month. Click <span class="underline" (click)="showFeaturesDialog()" >here</span> to learn more about Accelerate PRO!</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
          </mat-card-content>
        </mat-card>
 
        <mat-card *ngIf="!!this.subscription && !this.subscriptionLoading" class="subscription__card subscription-card">
          <mat-card-header>
            <mat-card-title>{{this.subscription.plan.nickname}} - ${{this.subscriptionAmount / 100}}.00</mat-card-title>
          </mat-card-header>
         
          <mat-card-content>
            <h1 class="plan-title">Licenses</h1>
            <mat-form-field appearance="outline" class="qty-field" >
              <input matInput type="number"  min="{{usersMinValue}}" [(ngModel)]="selectedSeats" [ngModelOptions]="{standalone: true}" (change)="validateMinSeats($event)" />
            </mat-form-field>
            <span class="subscription-charged">You will be charged {{ ((this.selectedSeats * this.subscriptionAmount)/100) | currency}} monthly.</span>
            <div class="subscription-charged" *ngIf="usersMinValue > 1 && usersMinValue == selectedSeats">
              Since you currently have {{usersMinValue}} users in your organization, you can't enter a number lower than {{usersMinValue}}.
            </div>
            <h1 class="subscription__card-title" >Change your card number</h1>

            <div class="subscription__card" #subscriptionCardElement>
              <!-- Elements will create input elements here -->
            </div>
          
            <!-- We'll put the error messages in this element -->
            <p class="subscription__error">
              {{cardErrors}}
            </p>

          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!this.subscription && !this.subscriptionLoading" class="subscription__card subscription-card">
          <mat-card-header></mat-card-header>
         
          <mat-card-content>
            <h1 class="plan-title">Licenses</h1>
            <mat-form-field appearance="outline" class="qty-field" >
              <input matInput type="number"  min="{{usersMinValue}}" [(ngModel)]="selectedSeats" [ngModelOptions]="{standalone: true}" (change)="validateMinSeats($event)" />
            </mat-form-field>
            <span class="subscription-charged">You will be charged {{ ((this.selectedSeats * this.subscriptionAmount)/100) | currency}} monthly.</span>
            <div class="subscription-charged" *ngIf="usersMinValue > 1 && usersMinValue == selectedSeats">
              Since you currently have {{usersMinValue}} users in your organization, you can't enter a number lower than {{usersMinValue}}.
            </div>
            <h1 class="subscription__card-title" >Credit card number</h1>

            <div class="subscription__card" #subscriptionCardElement>
              <!-- Elements will create input elements here -->
            </div>
          
            <!-- We'll put the error messages in this element -->
            <p class="subscription__error">
              {{cardErrors}}
            </p>

          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!!this.subscription && !this.subscriptionLoading && (!this.isTrial || ( !!this.isTrial && !!this.hasCCInformation ))" class="subscription__plans subscription-card">
          <mat-card-header>
            <mat-card-title>Changing Subscription Status</mat-card-title>
          </mat-card-header>
         
          <mat-card-content >
            <div class="cancel-information">If you choose to cancel your Accelerate subscription, PRO features will continue to be available through the end of your current subscription period.  At the end of your current subscription period, only free features will be available.</div>
          </mat-card-content>
        </mat-card>

        <!--<div *ngIf="!!this.subscriptionLoading" class="subscription-spinner">
          <mat-spinner></mat-spinner>
        </div>-->
        <div class="subscription__save">
          <!--<mat-progress-bar class="subscription-progress" *ngIf="!!this.loading" mode="indeterminate"></mat-progress-bar>-->
          <button mat-raised-button class="company-button" color="primary" *ngIf="!this.subscriptionLoading && !!this.subscription && !this.canceledAt"   (click)="submitForm()" [disabled]="!!this.loading || !this.subscriptionChanged" >Update Subscription</button>
          <button mat-raised-button class="company-button" color="primary" *ngIf="!this.subscriptionLoading && !!this.subscription && !!this.canceledAt" (click)="renewSubscription()" [disabled]="!!this.loading" >Renew Subscription</button>
          <button mat-raised-button class="company-button" color="primary" *ngIf="!this.subscriptionLoading && !this.subscription" (click)="purchaseSubscription()" [disabled]="!!this.loading" >Purchase PRO Subscription</button>
        </div>

        <div class="subscription__cancel">
          <button mat-raised-button class="company-button" color="primary" *ngIf="!this.subscriptionLoading && !!this.subscription && (!this.isTrial || ( !!this.isTrial && !!this.hasCCInformation ))" [disabled]="!!this.canceledAt"  (click)="cancelSubscription()" >Cancel Subscription</button>
        </div>
      </form>

    </mat-card-content>
  </mat-card>

</div>