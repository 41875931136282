import { Folder } from "./folder";
import { LibraryItem } from './libraryItem';

export class MediaContents {
	public mediaCategoryID:number;
	public MediaCategories:Array<Folder>;
	public LibraryItems:Array<LibraryItem>;

	constructor(mediaCategories, medias, mediaCategoryID, type) {
		this.MediaCategories = (mediaCategories && mediaCategories.length > 0) ? mediaCategories : new Array<Folder>();
		this.LibraryItems = medias.length > 0 ? medias.map(m => { return {...(new LibraryItem(m)), isCart: type == 5}; } ) : new Array<LibraryItem>();
		this.mediaCategoryID = mediaCategoryID;
		this._setType(type, this.MediaCategories)
	}
	private _setType(type:number, folders:Array<Folder>):void {
		if(folders) {
			folders.forEach(f => {
				f.type = type;
				if(f.MediaCategories.length > 0) {
					this._setType(type, f.MediaCategories);
				}
			})
		}
	}
}