import { Component, OnInit, SimpleChanges, Input, OnChanges, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { Profile, User } from "acc-models";
import { ProfilePhotoComponent, tooltips } from "acc-common";
import { DataService, SessionService } from "acc-services";
import { IAppState } from "../../store/state/app.state";
import { Store } from "@ngrx/store";
import { SetErrorMessage } from "../../store/actions/status.actions";
@Component({
	selector: "acc-main-profile-form",
	templateUrl: "./profile-form.component.html",
	styleUrls: ["./profile-form.component.scss"],
})
export class ProfileFormComponent implements OnInit, OnChanges {
	public photo;
	@ViewChild("userPhoto") userPhoto: ProfilePhotoComponent;
	@Input() public profile: Profile;
	@Input() controls: Array<{ name: string; ctl: FormControl }>;
	@Input() cssClass: string = "";

	public logo;
	@ViewChild("userLogo") userLogo: ProfilePhotoComponent;

	public profileForm: FormGroup;
	public titleField = new FormControl("", [Validators.required]);
	public firstNameField = new FormControl("", [Validators.required]);
	public lastNameField = new FormControl("", [Validators.required]);
	public companyNameField = new FormControl("", [Validators.required]);
	public address1Field = new FormControl();
	public address2Field = new FormControl();
	public cityField = new FormControl();
	public stateField = new FormControl();
	public zipField = new FormControl();
	public mainPhoneField = new FormControl();
	public cellPhoneField = new FormControl();
	public directPhoneField = new FormControl();
	public emailField = new FormControl("", [Validators.required]);
	public websiteField = new FormControl();
	public facebookField = new FormControl();
	public twitterField = new FormControl();
	public linkedinField = new FormControl();
	public instagramField = new FormControl();
	public youtubeField = new FormControl();

	public phoneMask = "(000) 000-0000";
	public imageFileTypes = [".jpg", ".jpeg", ".png", ".gif"];

	public profileNameField = new FormControl();

	public tooltips = tooltips;

	public showDefaultImage = true;
	public defaultImageUrl;
	public user: User;
	
	constructor(private fb: FormBuilder, private dataService: DataService, private store: Store<IAppState>, private sessionService: SessionService) {
		this.user = this.sessionService.getUser();
	}

	ngOnInit() {
		this.profileForm = this.fb.group({
			title: this.titleField,
			firstName: this.firstNameField,
			lastName: this.lastNameField,
			companyName: this.companyNameField,
			address1: this.address1Field,
			address2: this.address2Field,
			city: this.cityField,
			state: this.stateField,
			zip: this.zipField,
			mainPhone: this.mainPhoneField,
			cellPhone: this.cellPhoneField,
			directPhone: this.directPhoneField,
			email: this.emailField,
			website: this.websiteField,
			facebook: this.facebookField,
			twitter: this.twitterField,
			instagram: this.instagramField,
			youtube: this.youtubeField,
			linkedin: this.linkedinField,
			profileName: this.profileNameField,
		});
		if (this.controls) {
			this.controls.forEach((ctl) => {
				this.profileForm.addControl(ctl.name, ctl.ctl);
			});
		}
	}

	public getPhoto(): ProfilePhotoComponent {
		return this.userPhoto;
	}

	public getLogo(): ProfilePhotoComponent {
		return this.userLogo;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.profile && changes.profile.currentValue) {
			const prof = changes.profile.currentValue;
			this.profile = prof;
			this.titleField.setValue(prof.title);
			this.firstNameField.setValue(prof.firstName);
			this.lastNameField.setValue(prof.lastName);
			this.companyNameField.setValue(prof.companyName);
			this.address1Field.setValue(prof.address1);
			this.address2Field.setValue(prof.address2);
			this.cityField.setValue(prof.city);
			this.stateField.setValue(prof.state);
			this.zipField.setValue(prof.zip);
			this.mainPhoneField.setValue(prof.mainPhone);
			this.cellPhoneField.setValue(prof.cellPhone);
			this.directPhoneField.setValue(prof.directWorkPhone);
			this.emailField.setValue(prof.email);
			this.websiteField.setValue(prof.website);
			this.facebookField.setValue(prof.facebook);
			this.twitterField.setValue(prof.twitter);
			this.instagramField.setValue(prof.instagram);
			this.youtubeField.setValue(prof.youtube);
			this.linkedinField.setValue(prof.linkedIn);
			this.profileNameField.setValue(prof.profileName);

			this.profileForm = this.fb.group({});
			this.profileForm.addControl("title", this.titleField);
			this.profileForm.addControl("firstName", this.firstNameField);
			this.profileForm.addControl("lastName", this.lastNameField);
			this.profileForm.addControl("companyName", this.companyNameField);
			this.profileForm.addControl("address1", this.address1Field);
			this.profileForm.addControl("address2", this.address2Field);
			this.profileForm.addControl("city", this.cityField);
			this.profileForm.addControl("state", this.stateField);
			this.profileForm.addControl("zip", this.zipField);
			this.profileForm.addControl("mainPhone", this.mainPhoneField);
			this.profileForm.addControl("cellPhone", this.cellPhoneField);
			this.profileForm.addControl("directPhone", this.directPhoneField);
			this.profileForm.addControl("email", this.emailField);
			this.profileForm.addControl("website", this.websiteField);
			this.profileForm.addControl("facebook", this.facebookField);
			this.profileForm.addControl("twitter", this.twitterField);
			this.profileForm.addControl("instagram", this.instagramField);
			this.profileForm.addControl("youtube", this.youtubeField);
			this.profileForm.addControl("linkedin", this.linkedinField);
			this.profileForm.addControl("profileName", this.profileNameField);

			setTimeout(() => {
				this.userPhoto.photo = this.profile.photo;
				this.userPhoto.update();
			}, 1000);

			setTimeout(() => {
				this.userLogo.photo = this.profile.logo;
				this.userLogo.update();
			}, 1000);

			this.showDefaultImage = this.profile.logo == null;
			if(!!this.showDefaultImage && this.profile.logoForOutput != null){
				this.defaultImageUrl = this.profile.logoForOutput.imageModifiedURLPath;
			}else {
				if(this.profile.logoForOutput == null){
					this.dataService.getCompanyInformation(this.user.orgID).subscribe(
						(resp: any) => {
							if(resp.photo!=null){
								this.defaultImageUrl = resp.photo.imageModifiedURLPath;
							}
						},
						(err: any) => {
							this.store.dispatch(new SetErrorMessage("Could not access to SignUp service"));
						}
					);
				}
			} 
		}
	}

	public refreshImages() {
		setTimeout(() => {
			this.userPhoto.photo = this.profile.photo;
			this.userPhoto.update();
		}, 2000);

		setTimeout(() => {
			this.userLogo.photo = this.profile.logo;
			this.userLogo.update();
		}, 2000);
	}
	public onFormSubmit() {
		if (this.profileForm.invalid) {
			this.markFormGroupTouched(this.profileForm);
			return;
		}
	}

	private markFormGroupTouched(form: FormGroup) {
		Object.values(form.controls).forEach((control) => {
			control.markAsTouched();

			if ((control as any).controls) {
				this.markFormGroupTouched(control as FormGroup);
			}
		});
	}
}
