import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";

@Component({
	selector: "acc-main-analytics-chart",
	templateUrl: "./analytics-chart.component.html",
	styleUrls: ["./analytics-chart.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class AnalyticsChartComponent implements OnInit, OnChanges {
	
	@Input() chartWidth: number = 440;
	@Input() barHeight: number = 35;
	@Input() data: any;
	@Input() dataPoint1: { name: string, field: string }
	@Input() dataPoint2: { name: string, field: string }
	@Input() legendTitle: string = "Legend";
	@Input() barLegendPosition: string = 'below';
	@Input() title: string;
	@Input() showTop: number = 10;
	public barChartViewSize: Array<number> = [this.chartWidth,160];
	public customColors: Array<{ name: string, value: string }>;
	
	constructor() {}

	ngOnInit() {
		this.customColors = [
			{ name: this.dataPoint1.name, value: '#a8385d' },
			{ name: this.dataPoint2.name, value: '#7aa3e5' }
		];
	}
	ngOnChanges(changes: SimpleChanges): void {
		if(changes && changes.data && changes.data.currentValue != changes.data.previousValue) {
			this.data = this.formatData(changes.data.currentValue);
			this.barChartViewSize = this.getChartSize(this.data);
		}
	}

	getChartSize(data): Array<number> {
		return  [this.chartWidth, ((data ? data.length : 0) * this.barHeight) + 72];
	}

	formatData(data) {
		let newList: Array<any>;
		newList = data.map(i => { return { ...i, sortField: i[this.dataPoint1.name]}}).sort((a, b) => this.sortFunc(a, b));
		newList = newList.map(i => {
			return {
				name: i.name,
				series: [
					{ name: this.dataPoint1.name, value: i[this.dataPoint1.name] },
					{ name: this.dataPoint2.name, value: i[this.dataPoint2.name] }
				]
			};
		});
		return this.getTopRows(newList);
	}
	private sortFunc(a, b) {
		if (a.sortField < b.sortField) {
			return 1;
		}
		if (a.sortField > b.sortField) {
			return -1;
		}
		return 0;
	}

	getTopRows(data) {
		let newList: Array<any>;
		if (data.length > this.showTop + 1) {
			newList = data.slice(0, this.showTop);
			let col1 = 0,
				col2 = 0;
			for (let i = this.showTop; i < data.length; i++) {
				col1 += data[i].series[0].value;
				col2 += data[i].series[1].value;
			}
			let item: any = { name: "Other" };
			item = {
				...item,
				series: [
					{ name: this.dataPoint1.name, value: col1 },
					{ name: this.dataPoint2.name, value: col2 },
				],
			};
			newList.push(item);
			return newList;
		} else {
			return data;
		}
	}


}
