<div class="actionButtons">
	<button mat-button [matMenuTriggerFor]="newMenu"  [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.al_new}}">
		<mat-icon>add</mat-icon>
		<span>New</span>
	</button>
	<mat-menu #newMenu="matMenu">
		<button mat-menu-item (click)="onNewFolderClick($event)" [disabled]="!(activeFolder && activeFolder.type == 2)"><i class="fa fa-folder-open"></i><span>New Folder</span></button>
		<button mat-menu-item (click)="onUploadFileClick($event)" [disabled]="!uploadEnabled"><i class="fa fa-upload"></i><span>Upload</span></button>
	</mat-menu>
	<button mat-button (click)="onPersonalizeClick()"  [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.al_personalize}}">
		<mat-icon>send</mat-icon>
		<span>Personalize and Send</span>
	</button>
</div>