export class Settings {
	public navItems: Array<SettingsNavItem> = [];
	public primaryColor: string;
	public primaryColorDark: string;
	public primaryColorLight: string;
	public primaryColorContrast: string;
	public accentColor: string;
	public neutralColor: string;
	public logo: string;
	public nav: NavSettings;
	public module: SettingsModule;
	public landingPageImages: Array<string>;
	public landingPageCopy: string;
	public welcomeBlocks: Array<WelcomeBlock>;
	public unlayer: UnlayerData;
	public showHelp: boolean;
	public showTutorial: boolean;
	public showPreferences: boolean;

	constructor(o?:any) {
		const settingsDefaults: any = {
			navItems: [
				{ name: "Home", "path": "/welcome" },
			],
			primaryColor: "#65a0cd",
			secondaryColor: "#f6921e",
			logo: "url(/assets/main_logo.png)",
			nav: {
				bgColor: "#ffffff",
				textColor: "#000000",
				iconColor: "#000000"
			},
			module: {
				htmlEditor: true,
				charCount: 150,
				addFile: true,
				pageBreak: true,
				library: true,
				symbols: [174, 169, 8482, 8480],
				showBreadcrumbs: false
			},
			welcomeBlocks: [
				{ "type": "link", "name": "New Quote", "link": "/quote", "icon": "library_add" },
				{ "type": "link", "name": "My Quotes", "link": "/quote/my-quotes", "icon": "library_books" },
				{ "type": "link", "name": "Send a Message", "link": "/module/send", "icon": "mail_outline" },
				{ "type": "link", "name": "Browse the Library", "link": "/library", "icon": "photo_library" }
			],
			landingPageCopy: '',
			landingPageImages: ['assets/images/Landing1.png'],
			showHelp: true,
			showTutorial: true,
			showPreferences: true,
			unlayer: {
				isShared: true
			}
		}

		Object.assign(this, settingsDefaults, o);
	}

}

export class SettingsNavItem {
	public name: string;
	public path: string;
	public action: string;
	public target: "_blank" | "" = "";
	public children: Array<SettingsNavItem>;
	public permission: string;
	public isOpen: boolean = false;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class SettingsModule {
	public htmlEditor: boolean;
	public charCount: number
	public addFile: boolean;
	public pageBreak: boolean;
	public library: boolean;
	public symbols: Array<number>;
	public showBreadcrumbs: boolean;

}

export class WelcomeBlock {
	public type: string;
	public name?: string;
	public link?: string;
	public icon?: string;
	public data?: Array<{api: string, title?: string, limit: number}>;
	public permission?: string;
	public size?: number;
}

export class NavSettings {
	public bgColor: string;
	public textColor: string;
}

export class UnlayerData {
	public authCode?: string;
	public projectId?: number;
	public isShared?: boolean;
}


