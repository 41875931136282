<div class="health-details__container">
  <div class="health-details__side-panel">
    <button mat-flat-button class="back-button" (click)="onBackToHealthTypes()">
      <i class="fas fa-angle-left"></i>Back to Type of Health Details
    </button>
    <h1 class="page-title">Health Details</h1>
    <mat-card class="animated fadeIn hd-card-disable-animation" *ngIf="healthTypesSections">
      <ng-container *ngFor="let healthType of healthTypesSections">
        <div class="health-details__section" *ngIf="healthType.isEnabled">
          <img [src]="healthType.img" alt="healthType.key" class="section__img" width="100%">
          <div class="section__name">{{healthType.name}}</div>
          <div class="section__list">
            <span *ngFor="let section of healthType.sections" (click)="selectHealthType(section)"
              [class.active-section]="selectedSection.section === section">
              <ng-container *ngIf="selectedSection.section === section">
                <i class="fas fa-caret-right animated fadeIn"></i>
              </ng-container>
              {{section}}
            </span>
          </div>
        </div>
      </ng-container>
    </mat-card>
  </div>

  <div class="health-details__form animated fadeIn" *ngIf="currentSections">
    <mat-card>
      <mat-card-header>
		    <mat-card-title>{{selectedSection.section}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="healthDetailsForm">

          <ng-container [ngSwitch]="selectedSection.key">
            <ng-container *ngSwitchCase="'healthConditions'">
              <acc-main-health-conditions-form [healthConditions]="form.healthConditions" [heights]="heights"
                [lastUseOptions]="lastUseOptions" [typesOfTobacco]="typesOfTobacco" [selectedSection]="selectedSection">
              </acc-main-health-conditions-form>
            </ng-container>
            <ng-container *ngSwitchCase="'familyHistory'">
              <acc-main-family-history-form [familyHistory]="form.familyHistory" [selectedSection]="selectedSection"
                [maleConditions]="maleConditions" [femaleConditions]="femaleConditions"
                [fatherDeathConditions]="fatherDeathConditions" [motherDeathConditions]="motherDeathConditions"
                [sisterDeathConditions]="sisterDeathConditions" [brotherDeathConditions]="brotherDeathConditions">
              </acc-main-family-history-form>
            </ng-container>
            <ng-container *ngSwitchCase="'drivingViolations'">
              <acc-main-driving-violations-form [drivingViolations]="form.drivingViolations"
                [selectedSection]="selectedSection"></acc-main-driving-violations-form>
            </ng-container>
          </ng-container>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="button" (click)="onBackSection()"
          [style.visibility]="this.selectedSectionIndex == 0 ? 'hidden': 'visible'"
          class="animated fadeIn">Back</button>
        <button mat-raised-button type="button" (click)="onNextSection()"
          [style.visibility]="this.selectedSectionIndex != (currentSections.length - 1) ? 'visible' : 'hidden'"
          class="animated fadeIn">Next</button>
      </mat-card-actions>
    </mat-card>

    <div class="health-details__actions">
      <button mat-raised-button type="button" (click)="onShowTopTen()">Show Top 10</button>
      <button mat-raised-button color="primary" type="button" (click)="onSubmit()">Get Quotes</button>
    </div>
  </div>
</div>