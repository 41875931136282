import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuoteService } from 'acc-services';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Store, select } from "@ngrx/store";
import { IAppState } from '../../store/state/app.state';
import * as QuoteSelectors from "../../store/selectors/quote.selectors";
import { SetHealthProfile } from "../../store/actions/quote.actions";
import { take, takeUntil} from 'rxjs/operators';
import { HealthProfile, HealthConditions, FamilyHistory, DrivingViolation } from 'acc-models';

@Component({
  selector: 'acc-main-select-health-type',
  templateUrl: './select-health-type.component.html',
  styleUrls: ['./select-health-type.component.scss']
})
export class SelectHealthTypeComponent implements OnInit, OnDestroy {
  public selectHealthTypeForm: FormGroup;
  public healthTypes: FormArray;
  public hiddenControl: FormControl;
  public healthTypeList: Array<any>;
  public isCardDisabled: boolean;
  private destroy$: Subject<boolean> = new Subject();
  public message = `Looks like you already entered some of this information.\n` + `\nClick 'Continue' to add or edit`;
  public healthProfile: HealthProfile;

  public results$ = this.store.pipe(
		select(QuoteSelectors.selectHealthProfile),
		take(1),
		takeUntil(this.destroy$)
	);

  constructor(private quoteService: QuoteService, private fb: FormBuilder, private router: Router, private store: Store<IAppState>) {
  }

  ngOnInit() {
    this.quoteService.getHealthTypes().subscribe((data: any) => {
      this.healthTypeList = data;
      this.createForm();
    });
  }

  get selectHealthType() {
    return this.selectHealthTypeForm.controls;
  }

  createForm() {
    this.healthTypes = new FormArray(this.healthTypeList.map(item => new FormGroup({
      id: new FormControl(item.key),
      img: new FormControl(item.img),
      content: new FormControl(item.content),
      text: new FormControl(item.name),
      isDisabled: new FormControl(false),
      checkbox: new FormControl(false)
    })));

    this.hiddenControl = new FormControl(this.mapItems(this.healthTypes.value), Validators.required);

    this.healthTypes.valueChanges.subscribe((v) => {
      this.hiddenControl.setValue(this.mapItems(v));
    });

    this.selectHealthTypeForm = new FormGroup({
      items: this.healthTypes,
      selectedItems: this.hiddenControl
    });

    this.results$.subscribe(res => this.populateForm(res));
  }

  mapItems(items: any) {
    const selectedItems = items.filter((item) => item.checkbox).map((item) => item.id);
    return selectedItems.length ? selectedItems : null;
  }

  populateForm(hp: HealthProfile) {
    if (hp) {
        this.healthProfile = hp;
        this.healthTypes.at(0).get('checkbox').setValue(hp.healthConditions.isSelected);
        this.healthTypes.at(0).get('isDisabled').setValue(hp.healthConditions.isSelected);
        if (hp.familyHistory) {
          this.healthTypes.at(1).get('checkbox').setValue(hp.familyHistory.isSelected ? hp.familyHistory.isSelected : false);
        }
        if (hp.drivingViolation) {
          this.healthTypes.at(2).get('checkbox').setValue(hp.drivingViolation.isSelected ? hp.drivingViolation.isSelected : false);
        }
      }
      this.watchClicks();
  }

  watchClicks(): void {
    this.healthTypes.at(0).get('checkbox').valueChanges.subscribe(value => {
      if (this.healthProfile.healthConditions) {
        this.healthProfile.healthConditions.isSelected = value;
      } else {
        this.healthProfile.healthConditions = new HealthConditions({isSelected: value});
      }
    });
    this.healthTypes.at(1).get('checkbox').valueChanges.subscribe(value => {
      if (this.healthProfile.familyHistory) {
        this.healthProfile.familyHistory.isSelected = value;
      } else {
        this.healthProfile.familyHistory = new FamilyHistory({isSelected: value});
      }
    });
    this.healthTypes.at(2).get('checkbox').valueChanges.subscribe(value => {
      if (this.healthProfile.drivingViolation) {
        this.healthProfile.drivingViolation.isSelected = value;
      } else {
        this.healthProfile.drivingViolation = new DrivingViolation({isSelected: value});
      }
    });
  }

  onCardClick(index: number) {
    if (!this.healthTypes.at(index).get('isDisabled').value) {
      const isChecked = this.healthTypes.at(index).get('checkbox').value;
      this.healthTypes.at(index).get('checkbox').setValue(!isChecked);
    }
  }

  onContinue() {
    this.store.dispatch(new SetHealthProfile(this.healthProfile));
    this.router.navigateByUrl("/quote/health-details");
  }

  onBackToSearch() {
    this.store.dispatch(new SetHealthProfile(this.healthProfile));
    this.router.navigateByUrl("/quote?hd=true");
  }

  ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

}
