import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DtcTemplateComponent } from './dtc-template/dtc-template.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { HealthComponent } from './health/health.component';
import { CoverageComponent } from './coverage/coverage.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { FinishComponent } from './finish/finish.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccCommonModule } from 'acc-common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'acc-services';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { DtcRouteService } from './dtc.route.service';

const maskConfig: Partial<IConfig> = {
	validation: false,
};
@NgModule({
  declarations: [DtcTemplateComponent, DemographicsComponent, HealthComponent, CoverageComponent, ContactInfoComponent, FinishComponent],
  imports: [
	CommonModule,
	RouterModule,
	FormsModule,
	ReactiveFormsModule,
	AccCommonModule,

	MatInputModule,
	MatSelectModule,
	MatDatepickerModule,
	MatButtonModule,
	MatIconModule,
	MatSliderModule,
	MatProgressSpinnerModule,

	//NgxMaskModule.forRoot(maskConfig),
	TextMaskModule

  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, DtcRouteService]
})
export class DtcModule { }
