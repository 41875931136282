<div class="table-container">
  <div class="table-title"  id="features-table-scroll-to" >Compare Subscriptions and Top Features</div>
  <div class="divTable featuresTable">
    <div class="divTableHeading">
      <div class="divTableRow">
        <div class="divTableHead signup-features-text">Features</div>
        <div class="divTableHead signup-padding-bottom">Basic</div>
        <div class="divTableHead">Professional<br><span class="table-trial-text">$25/mo.</span></div>
      </div>
    </div>
    <div class="divTableBody">
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Run Term Quotes <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_term_quotes}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange">  <mat-icon>check_circle</mat-icon>   </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon> </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">My Quotes <mat-icon class="q-info-icon"  [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_my_quotes}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>check_circle</mat-icon>  </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Personalize Quote and Send<mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_personalize_send}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"> <mat-icon>radio_button_unchecked</mat-icon>   </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Asset Library Access <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_assets_library}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>radio_button_unchecked</mat-icon> </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Proposal Build & Sender <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_proposal_send}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>radio_button_unchecked</mat-icon>  </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Branded Quote Video <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_branded_quote}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>radio_button_unchecked</mat-icon>  </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Activity & Customer Engagement Dashboard <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_dashboard}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>radio_button_unchecked</mat-icon>  </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Analytics & Reporting <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_analytics}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>radio_button_unchecked</mat-icon>  </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Personalized Email/Social Media Marketing <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_personalized_videos}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>radio_button_unchecked</mat-icon>  </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Client Engagement Tracking <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_engagement}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>radio_button_unchecked</mat-icon>  </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
      <div class="divTableRow">
        <div class="divTableCell feature-left-justify">Self Service Quoting <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_self_service}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><mat-icon>radio_button_unchecked</mat-icon>  </div><div class="divTableCell tableCellOrange"> <mat-icon>check_circle</mat-icon>  </div></div>
          

    </div>
  </div>

</div>
