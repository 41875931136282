<mat-card >
  <mat-card-header>
    <mat-card-title>Select a subscription level to continue</mat-card-title>
  </mat-card-header>

  <mat-card-content >
    <div class="card-content">
      <h1 class="plan-title">Select your plan</h1>
      <mat-radio-group class="plan-group" [(ngModel)]="selectedPlan">
        <mat-radio-button class="plan-radio" *ngFor="let p of plans" [value]="p.id"  (change)="subscriptionChange($event)"> {{p.nickname}} ${{p.amount/100}}.00</mat-radio-button>
      </mat-radio-group>
    </div>   
    
  </mat-card-content>
</mat-card>

<mat-card *ngIf="isPaidPlanSelected">
  <mat-card-header>
    <mat-card-title>Payment Information</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    
    <h1 class="plan-title">Seats</h1>
    <mat-form-field appearance="outline" class="qty-field" >
      <input matInput type="number"  min="1" [(ngModel)]="selectedSeats" [ngModelOptions]="{standalone: true}" />
    </mat-form-field>
    <span class="subscription-charged">You will be charged {{ ((this.selectedSeats * this.subscriptionAmount)/100) | currency}} monthly.</span>

    <div class="payment__container">
      <div class="payment__card" #cardElement>
        <!-- Elements will create input elements here -->
      </div>
    
      <!-- We'll put the error messages in this element -->
      <p class="payment__error">
        {{cardErrors}}
      </p>
    </div>
      
  </mat-card-content>
</mat-card>

<div>
  <mat-progress-bar class="subscription-progress" *ngIf="!!this.loading" mode="indeterminate"></mat-progress-bar>
  <button mat-raised-button class="signup-button" color="primary" [disabled]="!!this.loading" (click)="submitForm()" >Submit</button>
</div>
