export const steps = [
	{
		page: '/welcome',
		daysUntilRedisplay: 4,
		id: "self-service-quoting",
		attachTo: {
			element: ".self-service-quoting-nav-link",
			on: "right",
		},
		beforeShowPromise: function () {
			return new Promise(function (resolve, reject) {
				if(document.getElementsByClassName('self-service-quoting-nav-link').length == 0) { reject(); }
				document.getElementById('welcomeContainer').addEventListener('welcomeMessageClosed', () => resolve(true))
			});
		},
		buttons: [
			{
				classes: "shepherd-button-secondary",
				text: "Close",
				action: function() { return this.complete(); }
			},
		],
		cancelIcon: {
			enabled: true,
		},
		scrollTo: false,
		title: "Welcome to Accelerate",
		text: ["Have you put self service quoting on your website or social media?"],
	},
	{
		page: '/quote/view-quote/*',
		daysUntilRedisplay: 5,
		id: "quote-reorder",
		attachTo: {
			element: ".mat-expansion-panel-content",
			on: "top",
		},
		beforeShowPromise: function () {
			return new Promise(function (resolve) {
				document.getElementsByTagName('body')[0].addEventListener('quoteLoaded', () => resolve(true))
			});
		},
		buttons: [
			{
				classes: "shepherd-button-secondary",
				text: "Close",
				action: function() { return this.next(); }
			},
		],
		cancelIcon: {
			enabled: true,
		},
		highlightClass: "shep--highlight",
		scrollTo: false,
		title: "My Quotes",
		text: ["Did you know you can re-order quote items with drag and drop?"],
	},
	{
		page: '/quote/view-quote/*',
		daysUntilRedisplay: 6,
		id: "quote-download-send-options",
		attachTo: {
			element: ".download-send-options",
			on: "left",
		},
		beforeShowPromise: function () {
			return new Promise(function (resolve) {
				document.getElementById('download-send-button').addEventListener('click', () => resolve(true))
			});
		},
		buttons: [
			{
				classes: "shepherd-button-secondary",
				text: "Close",
				action: function() { return this.next(); }
			},
		],
		cancelIcon: {
			enabled: true,
		},
		modalOverlayOpeningPadding: 0,
		highlightClass: "shep--highlight",
		scrollTo: false,
		title: "My Quotes",
		text: ["The Enhanced Quote option creates a personalized quote, is branded for you and your agency and includes a personalized video."],
	},	{
		page: '/quote/results',
		daysUntilRedisplay: 5,
		id: "quote-reorder",
		attachTo: {
			element: ".mat-expansion-panel-content",
			on: "top",
		},
		beforeShowPromise: function () {
			return new Promise(function (resolve) {
				document.getElementsByTagName('body')[0].addEventListener('quoteLoaded', () => resolve(true))
			});
		},
		buttons: [
			{
				classes: "shepherd-button-secondary",
				text: "Close",
				action: function() { return this.next(); }
			},
		],
		cancelIcon: {
			enabled: true,
		},
		highlightClass: "shep--highlight",
		scrollTo: false,
		title: "My Quotes",
		text: ["Did you know you can re-order quote items with drag and drop?"],
	},
	{
		page: '/quote/results',
		daysUntilRedisplay: 6,
		id: "quote-download-send-options",
		attachTo: {
			element: ".download-send-options",
			on: "left",
		},
		beforeShowPromise: function () {
			return new Promise(function (resolve) {
				document.getElementById('download-send-button').addEventListener('click', () => resolve(true))
			});
		},
		buttons: [
			{
				classes: "shepherd-button-secondary",
				text: "Close",
				type: "cancel",
			},
		],
		cancelIcon: {
			enabled: true,
		},
		modalOverlayOpeningPadding: 0,
		highlightClass: "shep--highlight",
		scrollTo: false,
		title: "My Quotes",
		text: ["An Enhanced Quote is branded for you and your agency and includes a personalized video."],
	},
];

export const stepOptions = {
	cancelIcon: { enabled: true },
	scrollTo: true,
	modal: true,
	confirmCancel: false,
	useModalOverlay: true,
	highlightClass: 'shep-highlight-elem',
	modalOverlayOpeningPadding: 10,
	modalOverlayOpeningRadius: 5
}
