import { HealthConditions, PhysicalBuild, TobaccoUse, BloodPressure, Cholesterol } from "./healthConditions";
import { FamilyHistory } from "./familyHistory";
import { DrivingViolation, DuiDwi } from "./drivingViolation";
import { HealthProfileRequest } from "./healthProfileRequest";
import { ClientInformation, ProductInformation } from "./quote";
import { HealthDetailsAttributes } from "./healthDetailsAttributes";

export class HealthProfile {
	public healthClass: string = "healthClassInfo";
	public healthConditions: HealthConditions = new HealthConditions();
	public familyHistory: FamilyHistory = new FamilyHistory();
	public drivingViolation: DrivingViolation = new DrivingViolation();

	constructor(o?: any) {
		Object.assign(this, o);
	}

	public getRequest(clientInfo: ClientInformation, productInfo: ProductInformation): HealthProfileRequest {
		return HealthProfileRequest.fromHealthDetailsForm(this, clientInfo, productInfo);
	}

	public static fromHealthDetailsAttributes(details: HealthDetailsAttributes): HealthProfile {
		let profile = new HealthProfile();
		profile.healthClass = details.healthClassType || 'healthClassInfo';
		profile.healthConditions = {
			physicalBuild: new PhysicalBuild({
				height: details.height,
				weight: details.weight
			}),
			tobaccoUse: new TobaccoUse({
				everUseTobacco: details.everUseTobacco,
				lastuserTobacco: details.lastUseTobacco,
				kindOfTobacco: details.kindOfTobacco,
				howMany: details.howMany
			}),
			bloodPressure: new BloodPressure({
				bloodPressureMedicationCurrentlyUse: details.everUseBloodPressureMedication == "current",
				bloodPressureMedicationNeverUsed: details.everUseBloodPressureMedication == "never",
				bloodPressureMedicationUsedInPast: details.everUseBloodPressureMedication == "past",
				bloodPressureSystolic: details.bloodPressureSystolic,
				bloodPressureDiastolic: details.bloodPressureDiastolic
			}),
			cholesterol: new Cholesterol({
				cholesterolMedicationCurrentlyUse: details.everUseCholesterolMedication == "current",
				cholesterolMedicationUsedInPast: details.everUseCholesterolMedication == "past",
				cholesterolMedicationNeverUsed: details.everUseCholesterolMedication == "never",
				cholesterolTotalValue: details.cholesterolTotalValue,
				cholesterolHDLValue: details.cholesterolHDLValue,
				cholesterolHDLRatio: details.cholesterolHDLRatio
			}),
			isSelected: !details.healthConditionsIsDisabled
		};
		profile.familyHistory = {
			isSelected: !details.familyHistoryIsDisabled,
			father: {
				fatherHistory: details.fatherHistory || false,
				conditions: details.fatherConditions,
				deathConditions: details.fatherDeathConditions,
				fatherCancer: details.fatherConditions.includes("cancer"),
				fatherCancerDiagnosedAge: details.fatherCancerDiagnosedAge,
				fatherCancerDeath: details.fatherDeathConditions.includes("cancer"),
				fatherCancerDeathAge: details.fatherDeathAge,
				fatherCancerProstateCancer: details.fatherConditions.includes("prostateCancer"),
				fatherCancerTesticularCancer: details.fatherConditions.includes("testicularCancer"),
				fatherCancerColonCancer: details.fatherConditions.includes("colonCancer"),
				fatherCancerMelanomaCancer: details.fatherConditions.includes("melanomaCancer"),
				fatherCancerPancreaticCancer: details.fatherConditions.includes("pancreaticCancer"),
				fatherCancerStomachCancer: details.fatherConditions.includes("stomachCancer"),
				fatherCancerOtherCancer: details.fatherConditions.includes("otherCancer"),
				fatherCardiovascular: details.fatherConditions.includes("cardiovascular"),
				fatherCardiovascularDiagnosedAge: details.fatherCardioVascularDiagnosedAge,
				fatherCardiovascularDeath: details.fatherDeathConditions.includes("cardiovascular"),
				fatherCardiovascularDeathAge: details.fatherDeathAge,
				fatherDiabetes: details.fatherConditions.includes("diabetes"),
				fatherDiabetesDiagnosedAge: details.fatherDiabetesDiagnosedAge,
				fatherDiabetesDeath: details.fatherDeathConditions.includes("diabetes"),
				fatherDiabetesDeathAge: details.fatherDeathAge,
				fatherStroke: details.fatherConditions.includes("stroke"),
				fatherStrokeDiagnosedAge: details.fatherStrokeDiagnosedAge,
				fatherStrokeDeath: details.fatherDeathConditions.includes("stroke"),
				fatherStrokeDeathAge: details.fatherDeathAge,
				fatherDie: details.fatherDie || false,
				deathAge: details.fatherDeathAge
			},
			mother: {
				motherHistory: details.motherHistory || false,
				conditions: details.motherConditions,
				deathConditions: details.motherDeathConditions,
				motherCancer: details.motherConditions.includes("cancer"),
				motherCancerDiagnosedAge: details.motherCancerDiagnosedAge,
				motherCancerDeath: details.motherDeathConditions.includes("cancer"),
				motherCancerDeathAge: details.motherDeathAge,
				motherCancerBreastCancer: details.motherConditions.includes("breastCancer"),
				motherCancerCervicalCancer: details.motherConditions.includes("cervicalCancer"),
				motherCancerEndometrialCancer: details.motherConditions.includes("endometrialCancer"),
				motherCancerFallopianTubeCancer: details.motherConditions.includes("fallopianTubeCancer"),
				motherCancerOvarianTubeCancer: details.motherConditions.includes("ovarianTubeCancer"),
				motherCancerVaginalCancer: details.motherConditions.includes("vaginalCancer"),
				motherCancerVulvarCancer: details.motherConditions.includes("vulvarCancer"),
				motherCancerColonCancer: details.motherConditions.includes("colonCancer"),
				motherCancerMelanomaCancer: details.motherConditions.includes("melanomaCancer"),
				motherCancerPancreaticCancer: details.motherConditions.includes("pancreaticCancer"),
				motherCancerStomachCancer: details.motherConditions.includes("stomachCancer"),
				motherCancerOtherCancer: details.motherConditions.includes("otherCancer"),
				motherCardiovascular: details.motherConditions.includes("cardiovascular"),
				motherCardiovascularDiagnosedAge: details.motherCardioVascularDiagnosedAge,
				motherCardiovascularDeath: details.motherDeathConditions.includes("cardiovascular"),
				motherCardiovascularDeathAge: details.motherDeathAge,
				motherDiabetes: details.motherConditions.includes("diabetes"),
				motherDiabetesDiagnosedAge: details.motherDiabetesDiagnosedAge,
				motherDiabetesDeath: details.motherDeathConditions.includes("diabetes"),
				motherDiabetesDeathAge: details.motherDeathAge,
				motherStroke: details.motherConditions.includes("stroke"),
				motherStrokeDiagnosedAge: details.motherStrokeDiagnosedAge,
				motherStrokeDeath: details.motherDeathConditions.includes("stroke"),
				motherStrokeDeathAge: details.motherDeathAge,
				motherDie: details.motherDie || false,
				deathAge: details.motherDeathAge
			},
			sister: {
				sisterHistory: details.sisterHistory || false,
				conditions: details.sisterConditions,
				deathConditions: details.sisterDeathConditions,
				sisterCancer: details.sisterConditions.includes("cancer"),
				sisterCancerDiagnosedAge: details.sisterCancerDiagnosedAge,
				sisterCancerDeath: details.sisterDeathConditions.includes("cancer"),
				sisterCancerDeathAge: details.sisterDeathAge,
				sisterCancerBreastCancer: details.sisterConditions.includes("breastCancer"),
				sisterCancerCervicalCancer: details.sisterConditions.includes("cervicalCancer"),
				sisterCancerEndometrialCancer: details.sisterConditions.includes("endometrialCancer"),
				sisterCancerFallopianTubeCancer: details.sisterConditions.includes("fallopianTubeCancer"),
				sisterCancerOvarianTubeCancer: details.sisterConditions.includes("ovarianTubeCancer"),
				sisterCancerVaginalCancer: details.sisterConditions.includes("vaginalCancer"),
				sisterCancerVulvarCancer: details.sisterConditions.includes("vulvarCancer"),
				sisterCancerColonCancer: details.sisterConditions.includes("colonCancer"),
				sisterCancerMelanomaCancer: details.sisterConditions.includes("melanomaCancer"),
				sisterCancerPancreaticCancer: details.sisterConditions.includes("pancreaticCancer"),
				sisterCancerStomachCancer: details.sisterConditions.includes("stomachCancer"),
				sisterCancerOtherCancer: details.sisterConditions.includes("otherCancer"),
				sisterCardiovascular: details.sisterConditions.includes("cardiovascular"),
				sisterCardiovascularDiagnosedAge: details.sisterCardioVascularDiagnosedAge,
				sisterCardiovascularDeath: details.sisterDeathConditions.includes("cardiovascular"),
				sisterCardiovascularDeathAge: details.sisterDeathAge,
				sisterDiabetes: details.sisterConditions.includes("diabetes"),
				sisterDiabetesDiagnosedAge: details.sisterDiabetesDiagnosedAge,
				sisterDiabetesDeath: details.sisterDeathConditions.includes("diabetes"),
				sisterDiabetesDeathAge: details.sisterDeathAge,
				sisterStroke: details.sisterConditions.includes("stroke"),
				sisterStrokeDiagnosedAge: details.sisterStrokeDiagnosedAge,
				sisterStrokeDeath: details.sisterDeathConditions.includes("stroke"),
				sisterStrokeDeathAge: details.sisterDeathAge,
				sisterDie: details.sisterDie || false,
				deathAge: details.sisterDeathAge
			},
			brother: {
				brotherHistory: details.brotherHistory || false,
				conditions: details.brotherConditions,
				deathConditions: details.brotherDeathConditions,
				brotherCancer: details.brotherConditions.includes("cancer"),
				brotherCancerDiagnosedAge: details.brotherCancerDiagnosedAge,
				brotherCancerDeath: details.brotherDeathConditions.includes("cancer"),
				brotherCancerDeathAge: details.brotherDeathAge,
				brotherCancerProstateCancer: details.brotherConditions.includes("prostateCancer"),
				brotherCancerTesticularCancer: details.brotherConditions.includes("testicularCancer"),
				brotherCancerColonCancer: details.brotherConditions.includes("colonCancer"),
				brotherCancerMelanomaCancer: details.brotherConditions.includes("melanomaCancer"),
				brotherCancerPancreaticCancer: details.brotherConditions.includes("pancreaticCancer"),
				brotherCancerStomachCancer: details.brotherConditions.includes("stomachCancer"),
				brotherCancerOtherCancer: details.brotherConditions.includes("otherCancer"),
				brotherCardiovascular: details.brotherConditions.includes("cardiovascular"),
				brotherCardiovascularDiagnosedAge: details.brotherCardioVascularDiagnosedAge,
				brotherCardiovascularDeath: details.brotherDeathConditions.includes("cardiovascular"),
				brotherCardiovascularDeathAge: details.brotherDeathAge,
				brotherDiabetes: details.brotherConditions.includes("diabetes"),
				brotherDiabetesDiagnosedAge: details.brotherDiabetesDiagnosedAge,
				brotherDiabetesDeath: details.brotherDeathConditions.includes("diabetes"),
				brotherDiabetesDeathAge: details.brotherDeathAge,
				brotherStroke: details.brotherConditions.includes("stroke"),
				brotherStrokeDiagnosedAge: details.brotherStrokeDiagnosedAge,
				brotherStrokeDeath: details.brotherDeathConditions.includes("stroke"),
				brotherStrokeDeathAge: details.brotherDeathAge,
				brotherDie: details.brotherDie || false,
				deathAge: details.brotherDeathAge
			}
		};
		profile.drivingViolation = {
			isSelected: !details.drivingViolationsIsDisabled,
			duiDwi: {
				drivingConvictionExists: details.drivingConvictionExists || false,
				drivingDrunkDrivingDates: details.duiDwiDates.map(d => String(d).split('T')[0])
			},
			licenseSuspension: {
				drivingLicenseCurrentlyRevoked: details.drivingLicenseSuspensionExists,
				drivingLicenseSuspensionDates: details.drivingLicenseSuspensionDates.map(d => String(d).split('T')[0])
			},
			motorVehicleAccident: {
				motorVehicleAccidentExists: details.motorVehicleAccidentExists,
				drivingMotorVehicleAccidentDates: details.motorVehicleAccidentDates.map(d => String(d).split('T')[0])
			},
			movingViolation: {
				movingViolationExists: details.movingViolationExists,
				drivingMovingViolationDates: details.movingViolationDates.map(d => String(d).split('T')[0])
			},
			recklessDriving: {
				recklessDrivingViolationExists: details.recklessDrivingViolationExists,
				drivingRecklessDrivingDates: details.recklessDrivingDates.map(d => String(d).split('T')[0])
			}
		}
		return profile;
	}
}
