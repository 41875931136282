import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'acc-common-upload-file',
  templateUrl: './upload-file.component.html',
  styles: [`
	  mat-dialog-actions { margin-bottom: 0 !important; }
	  mat-tab-body { padding-top 15px; }
	  p { padding: 20px 0 10px }
  `]
})
export class UploadFileComponent implements OnInit {

	@Output() onUpload: EventEmitter<{ description: string, file: File }> = new EventEmitter();
	@Output() onUploadByUrl: EventEmitter<{ description: string, url: string }> = new EventEmitter();

	public uploadForm: FormGroup;
	public uploadByUrlForm: FormGroup;
	public descriptionField = new FormControl('', [Validators.required]);
	public fileField = new FormControl('', [Validators.required]);
	public urlDescriptionField = new FormControl('', [Validators.required]);
	public urlField = new FormControl('', [Validators.required]);
	
	constructor(public dialogRef: MatDialogRef<UploadFileComponent>, private fb: FormBuilder) { }

	ngOnInit() {
		this.uploadForm = this.fb.group({ 
			description: this.descriptionField ,
			file: this.fileField
		});
		this.uploadByUrlForm = this.fb.group( {
			description: this.urlDescriptionField,
			url: this.urlField
		})
	}

	upload() {
		if(this.uploadForm.valid) { //need to validate
			this.dialogRef.close({ action: 'upload', payload: { description: this.descriptionField.value, file: this.fileField.value }})
		}
	}

	uploadByUrl() {
		if(this.uploadByUrlForm.valid) { //need to validate
			this.dialogRef.close({ action: 'uploadByUrl', payload: { description: this.urlDescriptionField.value, url: this.urlField.value }})
		}

	}
	close() {
		this.dialogRef.close();
	}

}
