import { Component, Output, Input, EventEmitter, OnInit, OnDestroy, SimpleChanges, OnChanges, HostListener, ViewChild, AfterViewChecked, ElementRef, forwardRef } from '@angular/core';
import { CrmAccount } from 'acc-models';
import { CrmService } from 'acc-services';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, tap } from 'rxjs/operators';

@Component({
	selector: 'acc-common-account-selector',
	templateUrl: './account-selector.component.html',
	styleUrls: ['./account-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: AccountSelectorComponent,
			multi:true
		}
	]
})
export class AccountSelectorComponent implements OnInit,OnChanges, AfterViewChecked {
	@Input() placeholder:string;
	@Input() label:string;
	@Input() public value: CrmAccount = new CrmAccount();
	@Input() public control: FormControl;
	@Input() hasCrmToken: boolean = false;
	@Input() tabindex: number;
	@Output() AccountSelected:EventEmitter<CrmAccount> = new EventEmitter<CrmAccount>();
	@ViewChild('input', { static: true }) input:ElementRef;

	public accountOptions:Array<CrmAccount> = new Array<CrmAccount>();
	public accountOptionsToShow:Array<CrmAccount> = new Array<CrmAccount>();

	public currentHighlight: number = 0;
	private isClear:boolean = true; //don't have a selected account
	onChange: Function;
	
	constructor(private crmService:CrmService) {}
	
	ngOnInit():void {
		this.value = this.value || new CrmAccount();

		fromEvent(document.getElementById('accountFilter'), 'keyup')
			.pipe(
				debounceTime(250),
				//map((e:any) => e.target.value),
				distinctUntilChanged(),
				tap((e) => this.filterOptions(e))
			).subscribe();
			
	}


	ngOnChanges(changes:SimpleChanges):void {
		if(changes.placeholder) {
			this.placeholder = changes.placeholder.currentValue;
		}
		if(changes.label) {
			this.label = changes.label.currentValue;
		}
	}

	ngAfterViewChecked():void {
		//this.input.nativeElement.addEventListener('blur', () => setTimeout(() => {this.accountOptionsToShow.length = 0;}, 250) )
	}
	
	selectAccount(account:CrmAccount) {
		this.isClear = false;
		this.accountOptionsToShow.length = 0;
		this.value = new CrmAccount(account);
		this.control.setValue(this.value.Name);
		this.AccountSelected.next(account);
	}
	clearAccount() {
		if(!this.isClear) {
			this.isClear = true;
			this.value.Id ='';
			this.AccountSelected.next(this.control.value);
		}
	}
	filterOptions(e) {
		if(!this.hasCrmToken) { return; }
		this.clearAccount(); //clear out id once you start typing, to get rid of "crm" icon
		if(!this.value.Name || this.value.Name.length < 3) {
			this.accountOptionsToShow.length = 0;
			return;
		}
		if(e) {
			switch(e.key) {
				case 'ArrowDown': 
					this.currentHighlight = ++this.currentHighlight % this.accountOptionsToShow.length;
					break;
				case 'ArrowUp':
					this.currentHighlight = this.currentHighlight == 0 ? this.accountOptionsToShow.length-1 : --this.currentHighlight % this.accountOptionsToShow.length
					break;
				case 'Enter':
				case 'Tab':
					this.selectAccount(this.accountOptionsToShow[this.currentHighlight]);
					return;
				//case 'Tab':
					//if there are options showing, just choose the highlighted (done below in checkTab) and move on
				//		return;
				default: 
					this.crmService.searchContacts(this.value.Name).subscribe(res => {
						this.accountOptionsToShow = res;
						this.value.Id = '';
					})
				}
		}
	}
	checkTab(e) {
		if(e && e.key == 'Tab') {
			if(this.accountOptionsToShow.length > 0) { 
				e.preventDefault(); 
				//this.selectAccount(this.accountOptionsToShow[this.currentHighlight]);
				//this.accountOptionsToShow.length = 0;
			}
		}
	}

	writeValue( value: null ) {
		this.input.nativeElement.value = '';
		this.value = value;
	}
	registerOnChange( fn: Function ) {
		this.onChange = fn;
	}

	registerOnTouched( fn: Function ) {
	}
 }
