import { Component, Input, Inject, OnInit } from "@angular/core";
import { Media } from 'acc-models';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import { EditMediaCancel, EditMediaConfirm, AddMediaConfirm } from '../../../store/actions/media.actions';
import { DataService, MixpanelService } from 'acc-services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { tooltips } from "acc-common";

@Component({
	selector: 'edit-media-form',
	templateUrl: './edit-media-form.component.html',
	styleUrls: ['./edit-media-form.component.scss'],
	styles: [`#accelerate .mat-form-field-label-wrapper {
		top: -0.84375em
	}`]
})

export class EditMediaFormComponent implements OnInit {
	private fileFile: File;
	private thumbnailFile: File;
	public errorMessage: string = '';
	public mediaDescField: FormControl = new FormControl('', [Validators.required]);
	public mediaTypeField: FormControl = new FormControl('1', [Validators.required]);
	public keywordsField: FormControl = new FormControl('', []);
	public fileField: FormControl;
	public thumbnailField: FormControl;
	public revDateField: FormControl = new FormControl('', []);
	public editForm: FormGroup;
	public tooltips = tooltips;
	public cachebust: number = new Date().getMilliseconds();

	public imageFileTypes = ['.jpg', '.jpeg', '.png', '.gif'];
	
	constructor(private store$:Store<IAppState>, private dataService:DataService, 
		private dialogRef: MatDialogRef<EditMediaFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
		private mixpanelService: MixpanelService ) {
	}

	ngOnInit():void {
		this.mediaDescField.setValue(this.data.media.mediaDesc);
		this.mediaTypeField.setValue((this.data.media.mediaTypeID == null)?"1":this.data.media.mediaTypeID);
		this.keywordsField.setValue(this.data.media.searchWords);
		this.revDateField.setValue(this.data.media.revisionNeededDate);
		this.fileField = new FormControl('', [this.requiredIfNew(this.data.media.mediaItemID)])
		this.thumbnailField = new FormControl('', [this.requiredIfNew(this.data.media.mediaItemID)])
		this.editForm = this.fb.group({
			mediaDesc: this.mediaDescField,
			mediaType: this.mediaTypeField,
			keywords: this.keywordsField,
			file: this.fileField,
			thumbnail: this.thumbnailField,
			revDate: this.revDateField
		});
	}

	close(idx):void {
		Object.keys(this.editForm.controls).forEach(key => {
			this.editForm.get(key).markAsTouched(); //to run validation on each field
		})
		switch(idx) {
			case 0: //save
				if(this.editForm.valid) {
					this.errorMessage = '';
					let editmedia = { 
						...this.data.media, 
						mediaDesc: this.mediaDescField.value,
						mediaTypeID: this.mediaTypeField.value,
						searchWords: this.keywordsField.value || '',
						revisionNeededDate: new DatePipe('en-us').transform(this.revDateField.value, 'shortDate')
					}
					this.store$.dispatch(new EditMediaConfirm(editmedia, this.fileField.value, this.thumbnailField.value));
					this.dialogRef.close();
				} else {
					//this.errorMessage = 'Description and Media Type are required.'
				}
				break;
			case 1: //cancel
				this.store$.dispatch(new EditMediaCancel());
				this.dataService.cancelUpload();
				this.dialogRef.close();
				break;
			case 2: //add new
				if(this.editForm.valid) {
					if(this.fileField.value && this.thumbnailField.value) {
						this.errorMessage = '';
						let addmedia = { 
							...this.data.media, 
							mediaDesc: this.mediaDescField.value,
							mediaTypeID: this.mediaTypeField.value,
							searchWords: this.keywordsField.value || '',
							revisionNeededDate: new DatePipe('en-us').transform(this.revDateField.value, 'shortDate')
						};
						this.store$.dispatch(new AddMediaConfirm(addmedia, this.fileField.value, this.thumbnailField.value));

						//Mixpanel Track
						this.mixpanelService.trackAssetFileAdded();

						this.dialogRef.close();
					}else {
						this.errorMessage = 'Please provide a media file and a thumbnail file.'
					}
				} else {
					//this.errorMessage = 'Description and Media Type are required.'
				}
		}

	}
	fileSelected(event) {
		if(event.target.files && event.target.files.length > 0) {
			this.fileFile = event.target.files[0];
		}
	}
	thumbnailSelected(event) {
		if(event.target.files && event.target.files.length > 0) {
			this.thumbnailFile = event.target.files[0];
		}
	}
	compareValues(a,b) {
		return String(a).trim() === String(b).trim();
	}

	requiredIfNew(mediaItemId: string): ValidatorFn {

		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if(mediaItemId != '') {
				//this is edit, field not required
				return null;
			}
			if(!control.value) {
				return { requiredIfNew: true };
			}
			return null;
		}
	}
	getBackgroundImageUrl(path) {
		return `url('${path}')`;
	}
 }