import { Component, OnInit, Input, HostListener, ElementRef, forwardRef, OnChanges, EventEmitter, SimpleChanges, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'acc-common-auto-preview-upload',
  templateUrl: './auto-preview-upload.component.html',
  styleUrls: ['./auto-preview-upload.component.scss']
})
export class AutoPreviewUploadComponent {
	@Input() url: string;
	@Input() text: string = "Choose File or Drop File Here";
	@Output() fileChange = new EventEmitter<File>();

	public file: File | null = null;

	@HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
		const file = event && event.item(0);
		const ext = file.name.substring(file.name.lastIndexOf("."));
		if (this.accept && !this.accept.some((e) => e == ext)) {
			this.displayError = true;
			this.errorMessage = this.errorMessage ||  "File must be one of these types: " + this.accept.join(", ")
		} else {
			this.displayError = false;
			this.fileChange.emit(file);
			this.file = file;
		}
	}
	@Input() accept: Array<string>;
	@Input() errorMessage: string;
	public displayError: boolean = false;

	constructor(private host: ElementRef<HTMLInputElement>, private sanitizer: DomSanitizer) {}

	upload(e) {

		this.url = URL.createObjectURL(e.target.files[0]);
	}

	sanitize(str) {
		return this.sanitizer.bypassSecurityTrustUrl(str);
	}

}

