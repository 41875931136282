export class Attachment {
	public attachmentID: string;
	public docID: string;
	public textID: number;
	public attachmentLabel:string;
	public attachemntFilePath:string;
	public attachmentFileExt:string;
	public attachmentOrigName: string;
	public attachmentFilePathFull: string;
	public placementTypeID:number;
	public type:string;
	public imgSizeID: number;
	public sortOrder: number;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export 	enum PlacementType {
	AFTER_COVER = 1,
	END_OF_DOC = 2,
	BEFORE_TEXT = 3,
	AFTER_TEXT = 4,
	APPENDIX = 5
}
export enum ImageSize {
	AUTO = 1,
	SMALL = 2,
	MEDIUM = 3,
	LARGE = 4,
	PAGE_WIDTH = 5
}