
export class DocBuilderItem {
	public DocBuilderItemID: string;
	public DocBuilderID: number;
	public questionDesc: string;
	public blurb: string;
	public imageID: string;
	public sortOrder: number;
	public alwaysInDoc: number = 0;

	public checked: boolean = false;
	public isDirty: boolean = false;

	//used in admin
	public file: File;
	public image: any;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}