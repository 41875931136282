import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { SetErrorMessage } from '../../store/actions/status.actions';
import { SessionService } from 'acc-services';
import { GetGroups, NewList } from '../../store/actions/communication.actions';
import { CommGroup, CommList } from 'acc-models';
import * as CommunicationSelectors from "../../store/selectors/communication.selectors";
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-new-list',
  templateUrl: './new-list.component.html',
  styleUrls: ['./new-list.component.css']
})
export class NewListComponent implements OnInit, OnDestroy {

	public groups: Array<CommGroup>;
	private destroy$: Subject<boolean> = new Subject();
	public groups$ = this.store.pipe(
		select(CommunicationSelectors.selectGroups),
		tap((g) => (this.groups = g)),
		takeUntil(this.destroy$)
	).subscribe();
	public errorMessage: string = '';
  constructor(
    public dialogRef: MatDialogRef<NewListComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
	private store: Store<IAppState>,
	private sessionService: SessionService
    ) {

    }

  ngOnInit() {
	this.store.dispatch(new GetGroups(this.sessionService.getUser().orgID));
  }

  cancel(){
    this.dialogRef.close();
  }

  createNew(data){
    var newList = new CommList({
      listName: data.listName, 
	  listDesc: data.listDesc,
	  orgID: this.sessionService.getUser().orgID,
	  groupID: data.groupID
    });
    if(newList.listName == null || newList.listName == ''){
	  //this.store.dispatch(new SetErrorMessage());
	  this.errorMessage = 'Name is required to create new list';
    }else{
		this.store.dispatch(new NewList(newList));
      	this.dialogRef.close(newList);
    }
  }

  ngOnDestroy() {
	  this.destroy$.next(false);
	  this.destroy$.complete();
  }
}
