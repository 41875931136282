import { Component, OnDestroy, OnInit } from "@angular/core";
import { Form, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Application, User } from "acc-models";
import { DataService } from "acc-services";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { SetBroker, SetProgress, SetServiceEnabled, UpdateApplication } from "../../store/actions/dtc.actions";
import { selectApplication } from "../../store/selectors/dtc.selectors";
import { IAppState } from "../../store/state/app.state";
import * as moment from 'moment';
import { CustomValidators } from "../../validators/custom-validators";
import { DtcRouteService } from "../dtc.route.service";

@Component({
	selector: "acc-main-demographics",
	templateUrl: "./demographics.component.html",
	styleUrls: ["./demographics.component.scss"],
})
export class DemographicsComponent implements OnInit, OnDestroy {
	public broker: User;
	public gender;

	public form: FormGroup;
	public dobField: FormControl;
	public monthField: FormControl;
	public dayField: FormControl;
	public yearField: FormControl;
	public stateField: FormControl;
	public genderField: FormControl;

	public monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
	public months: Array<{name: string, number: number}>;
	public days: Array<number>;
	public years: Array<number>;
	public destroy$: Subject<boolean> = new Subject();

	constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService, private store: Store<IAppState>, private fb: FormBuilder, private dtcRouteService: DtcRouteService) {}

	ngOnInit() {
		this.route.params.pipe(take(1)).subscribe((p) => {
			if(p["userId"]) {
				this.dtcRouteService.setPathParam(p["userId"]);
			}
		});
		this.dtcRouteService.setPage('demographics');

		const thisYear = new Date().getFullYear();
		this.months = Array(12).fill(0).map((x,i) => { return { number: i, name: this.monthNames[i]}});
		this.days = Array(31).fill(0).map((x,i) => i + 1);
		this.years = Array(120).fill(0).map((x,i) => thisYear - i);
		this.dobField = new FormControl();
		this.monthField = new FormControl("", [Validators.required]);
		this.dayField = new FormControl("", [Validators.required]);
		this.yearField = new FormControl("", [Validators.required]);
		this.stateField = new FormControl("", [Validators.required]);
		this.genderField = new FormControl("", [Validators.required]);
		this.form = this.fb.group({
			dob: this.dobField,
			month: this.monthField,
			day:this.dayField,
			year: this.yearField,
			state: this.stateField,
			gender: this.genderField,
		}, { validators: CustomValidators.isDateValidator });

		//this.form.get('month').setValue(6);
		//this.form.get('day').setValue(15);
		this.form.get('year').setValue(1980);
		
		this.store.pipe(select(selectApplication), takeUntil(this.destroy$)).subscribe(app => {
			if(app) {
				this.form.get('month').setValue(moment(app.birthdate).month());
				this.form.get('day').setValue(moment(app.birthdate).date());
				this.form.get('year').setValue(moment(app.birthdate).year());
				this.form.get('state').setValue(app.state);
				this.form.get('gender').setValue(app.gender);
				this.gender = app.gender;
			}
		})

		this.store.dispatch(new SetProgress(0));
		window.scrollTo(0,0);
	}

	setGender(val) {
		this.form.get("gender").setValue(val);
		this.gender = val;
	}

	next() {
		if (this.form.valid) {
			const dob = new Date(this.form.controls["year"].value, this.form.controls["month"].value, this.form.controls["day"].value);
			const app = new Application({ birthdate: dob, state: this.form.controls["state"].value.toString(), gender: this.form.controls["gender"].value });
			this.store.dispatch(new UpdateApplication(app));
			this.store.dispatch(new SetProgress(30));
			this.router.navigateByUrl("/dtc/health");
		} else {
			Object.keys(this.form.controls).forEach((c) => this.form.get(c).markAsTouched());
		}
	}

	ngOnDestroy() {
		this.destroy$.next(true)
	}
}
