import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PersonalizeDialogComponent } from "acc-common";
import { DocBuilder } from "acc-models";
import { DataService, SessionService } from "acc-services";
import { concat, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

@Component({
	selector: "acc-main-docbuiler-options",
	templateUrl: "./docbuiler-options.component.html",
	styleUrls: ["./docbuiler-options.component.scss"],
})
export class DocbuilerOptionsComponent implements OnInit, OnDestroy {
	public docBuilder: DocBuilder;
	public includeCarrierInformation: boolean = true;

	public destroy$: Subject<boolean> = new Subject();
	constructor(private dataService: DataService, private sessionService: SessionService, public dialogRef: MatDialogRef<DocbuilerOptionsComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {}

	ngOnInit() {
		this.dataService.getDocBuilder(this.sessionService.getUser().orgID, 1).subscribe((resp: any) => {
			this.docBuilder = resp;
			this.docBuilder.docBuilderItems = this.docBuilder.docBuilderItems.sort((a, b) => a.sortOrder - b.sortOrder).filter(i => !i.alwaysInDoc);
			if(this.docBuilder.docBuilderItems.length == 0) {
				this.continue();
			} else {
				this.dataService.getDocBuilderSelections(this.data.quoteId).subscribe((resp: any) => {
					this.docBuilder.docBuilderItems.forEach((i) => (i.checked = resp.includes(i.DocBuilderItemID)));
				});
			}
		});
	}

	continue() {
		let items = this.docBuilder.docBuilderItems.filter((i) => i.checked).map((i) => i.DocBuilderItemID);
		const saveItems = this.dataService.saveLifeQuoteDocBuilderOptions(this.data.quoteId, items, this.includeCarrierInformation, false);
		const createDoc = this.dataService.createDocBuildDoc(this.data.quoteId);
		const addToCart =  this.dataService.addToCart(this.data.mainCartId, this.data.quoteId, 4).pipe(
			tap(() => {
				this.dialogRef.close();
				this.dialog.open(PersonalizeDialogComponent, { data: { mainCartId: this.data.mainCartId, quoteId: this.data.quoteId }, minWidth: 675 });
			})
		);
		concat(saveItems, createDoc, addToCart).pipe(takeUntil(this.destroy$)).subscribe();
	}

	close(){
		this.dialogRef.close();
	}
	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
