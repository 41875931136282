<mat-card>
	<mat-card-header>
		<mat-card-title>{{media.mediaCategoryDesc}}</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<p class="cardContent">{{media.mediaDesc}}</p>
		<div class="revDateField" *ngIf="(editingMedia$ | async)?.mediaID == media.mediaID; else nonEdit">
			<div class="field">
				<mat-form-field>
					<input matInput [matDatepicker]="picker" [(ngModel)]="media.revisionNeededDate">
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-label>Revision Needed Date</mat-label>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
			</div>
		</div>
		<ng-template #nonEdit>
			<div class="revDate {{media.status}}">Revision Needed:</div>
			<div class="revDate {{media.status}}">{{media.revisionNeededDate | date:"longDate"}}</div>
		</ng-template>
	</mat-card-content>
	<mat-card-actions *ngIf="(editingMedia$ | async)?.mediaID == media.mediaID; else nonEditActions">
		<button mat-flat-button color="primary" (click)="save()">Save</button>
		<button mat-stroked-button (click)="cancel()">Cancel</button>
	</mat-card-actions>
	<ng-template #nonEditActions>
		<mat-card-actions>
			<button mat-stroked-button (click)="edit()">Edit</button>
		</mat-card-actions>
	</ng-template>
</mat-card>
