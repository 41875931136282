import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CurrencyValidators {
    static min(min: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value) {
                const formattedControlValue = Number.parseInt(String(control.value).replace(/\,/g, ''), 10);
                if (isNaN(formattedControlValue) || formattedControlValue < min) {
                    return {'minLimit': true};
                }
            }
            return null;
        };
    }

    static max(max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value) {
                const formattedControlValue = Number.parseInt(String(control.value).replace(/\,/g, ''), 10);
                if (formattedControlValue && (isNaN(formattedControlValue) || formattedControlValue > max)) {
                    return {'maxLimit': true};
                }
            }
            return null;
        };
    }
}
