import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { SubscriptionLike } from 'rxjs';
import { DataService, MixpanelService, SessionService } from 'acc-services';
import { MustMatch } from 'acc-common';

@Component({
  selector: 'acc-main-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

	public oldPasswordField = new FormControl('', [Validators.required]);
	public newPasswordField = new FormControl('', [Validators.required]);
	public confirmPasswordField = new FormControl('', [Validators.required]);

	public changePasswordForm: FormGroup;
	public btnOptions: MatProgressButtonOptions;
	public errorMessage: string;

	private formStatusSubscription: SubscriptionLike;

	constructor(private dataService: DataService, private sessionService: SessionService, public dialogRef: MatDialogRef<ChangePasswordComponent>, @Inject(MAT_DIALOG_DATA) 
		public data: any, private fb: FormBuilder, private mixpanelService: MixpanelService) { }

	ngOnInit() {

		this.mixpanelService.trackPageView();

		this.changePasswordForm = this.fb.group({
			oldPassword: this.oldPasswordField,
			newPassword: this.newPasswordField,
			confirmPassword: this.confirmPasswordField
		}, {validator: MustMatch('newPassword', 'confirmPassword')});

		this.btnOptions = {
			active: false,
			text: 'Change Password', 
			raised: true,
			stroked: false,
			flat:false,
			buttonColor: 'primary',
			spinnerColor: 'primary', 
			mode: 'indeterminate',	
			fullWidth: true,
			spinnerSize: 20,
			disabled: true
		};

		this.formStatusSubscription = this.changePasswordForm.statusChanges.subscribe(status => this.btnOptions.disabled = (status != 'VALID'))
	}

	close(): void {
		this.dialogRef.close(false);
	}

	changePassword(): void {
		this.btnOptions.active = true;
		this.errorMessage = '';
		this.dataService.changePassword(this.sessionService.getUser().userID, this.oldPasswordField.value, this.newPasswordField.value).subscribe(resp => {
			this.btnOptions.active = false;
			this.dialogRef.close(true);
			
		}, error => {
			this.btnOptions.active = false;
			let body = error.error;
			this.errorMessage = body.Message;
		});

	}

	ngOnDestroy(): void {
		if(this.formStatusSubscription) { this.formStatusSubscription.unsubscribe(); }
	}
}
