export interface IStatusState {
	errorMessage: string;
	successMessage: string;
	gettingStartedDialog: boolean;
	trialFeature:boolean;
	daysLeft: number;
	ccEntered: boolean;
	showClassicView: boolean;
}

export const initialStatusState: IStatusState = {
	errorMessage: "",
	successMessage: "",
	gettingStartedDialog: false,
	trialFeature: false,
	daysLeft: null,
	ccEntered: false,
	showClassicView: false,
};
