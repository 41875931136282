<div class="help-container" [ngClass]="{open: visible}">

	<div class="help-content">
		<h1>Support Portal</h1>
		<div class="help-icons">
			<div>
				<a href="http://support.consumeroptix.com/support/home" target="_blank">
					<mat-icon>library_books</mat-icon>
					<h2>Knowledgebase</h2>
				</a>
			</div>
			<div>
				<a href="http://support.consumeroptix.com/support/solutions/67000016952" target="_blank">
					<mat-icon>ondemand_video</mat-icon>
					<h2>Videos</h2>
				</a>
			</div>
			<div>
				<a href="http://support.consumeroptix.com/support/tickets/new" target="_blank">
					<mat-icon>bug_report</mat-icon>
					<h2>Report Bug</h2>
				</a>
			</div>
		</div>
		<div class="close" (click)="close()"><mat-icon>highlight_off</mat-icon></div>
	</div>
	<div class="bottom-branding">
		<div class="branding-neg-slant"></div>
		<div class="branding-pos-slant"></div>
	</div>
</div>