<div class="selections" >
	<mat-form-field  appearance="outline">
		<mat-icon *ngIf="value.Id" color="primary">person</mat-icon>
		<mat-label>{{label}}</mat-label>
		<input matInput autocomplete="off" [(ngModel)]="value.Name" id="accountFilter" (keydown)="checkTab($event)" #input [formControl]="control" tabindex="{{tabindex}}" >
		<mat-error class="error" *ngIf="control && control.invalid">{{label}} is required</mat-error>
	</mat-form-field>
	</div>
<ul class="optionList" [ngClass]="{open: accountOptionsToShow.length > 0}">
	<li *ngFor="let account of accountOptionsToShow; let i = index" (mouseup)="selectAccount(account)" [innerHTML]="account.Name | highlight:control.value" [ngClass]="{active: currentHighlight == i}"><li>
</ul>
	