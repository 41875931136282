export const tooltips = {
	position: "above",
	delay: "0",
	position_right: "right",

	admin_quote_template: "Create, edit or delete templates that are used within proposals generated in Accelerate.",
	admin_rev_date_text: "Displays how the existing Text, found in the Text Library & used in Proposals generated, is classified.",
	admin_rev_media: "Displays how the existing Media, found in the Asset Library, used within certain areas of Accelerate is classified.",
	admin_media_new: "Import new media, into a specific folder, to be stored in the Digital Asset Library",
	admin_media_type: "Select Personalized PDF to add your contact information to the footer of a pdf document.\n\nSelect Personalized Video to add your contact information and picture to the beginning of a video.",
	admin_categories_3level: "Set-up a 3-level folder system for categorizing the text which is used in documents generated within Accelerate.",
	admin_category_description: "Create & name the main categories of Text information, in the order preferred, for use in any Proposals generated.",
	admin_subcategory_description: "Create & name the subcategories of Text information, for each corresponding Text Category.",
	admin_subcategory_text: "Insert/add text for each corresponding Text Subcategory.",
	admin_users_users: "Add new users to the system",
	admin_users_isAdmin: "Choose whether to grant Admin access to user.",
	admin_quote_alerts: "Add, update or delete Carrier-specific information regarding their current stance towards Marijuana usage & DUI history.",
	q_metrics: "Filter report by quote Premium, Face Amount or Count.",
	vq_edit_quote: "Go back to New Life Quote to view or make any changes",
	vq_send_proposal: "Send this Term Life quote as either an Enhanced quote containing supplemental marketing material and carrier information OR as a Standard quote only. A Quote Video is automatically built and included when sending your quote.",
	q_edit_video: "Edit the video Accelerate bundles with your Term Quote.  If no selections are made, the video will select the three lowest priced options with the highest death benefit and longest term.",
  	q_copy_quote: "Create a copy of the current quote allowing you to modify an existing quote while keeping a copy of the original.",
	q_view_quote: "Go to quote summary page to view results.",
	q_edit_quote: "Go back to Quote to view or make any changes.",
	q_send_proposal: "Send quote, along with a summary video, within a personalized proposal.",
	q_customize_video: "Customize the video that is sent with the quote & proposal: \n\nDrag 3 checked quotes, which you want to appear in the video, into the Plan 1, 2 & 3 boxes. \n\nChange the selling points text to best describe WHY you choose these 3 quotes.",
	//q_customize_video: "Customize video that accompanies this quote when sent to client.\n\nSelect 3 plans to recommend, and provide 3 highlights of the plans.\n\nYou can drag plans directly into the boxes to the left.",
	q_health_details: "Add additional health details to allow the system to automatically assign a Health Class.",
	q_save: "Saves your quote and you can view later in 'My Quotes'.",
	q_download: "Choose a report type and then view the term quotes you selected.",
	nq_riders:
		"The Accidental Death Benefit is usually an amount paid in addition to the standard benefit payable if the insured died of natural causes.\n\nA Waiver of Premium is a provision that allows the insured not to pay premiums during a period of disability that has lasted for a particular length of time (typically for at least 6 months).\n\nReturn of Premium is a type of life insurance policy that returns the premiums paid for coverage if the insured party survives the policy's term, or includes a portion of the premiums paid to the beneficiary upon the death of the insured.\n\nA Child Term Rider is simply an extra form of life insurance coverage that you buy specifically for your child when you purchase a standard term life insurance policy.",
	nq_flat: "A flat Extra Premium is an additional charge or cost that is tacked on to the regular premium of your life insurance policy.  This flat extra premium can be added for a short time or the entire life of your insurance policy.",
	nq_table_ratings: "A Table Rating is used to modify the price that comes with a normal risk class, and they are most often paired with either the Standard Non-Tobacco or Standard Tobacco risk classes, since the medical issues that lead to table ratings will almost always keep you from getting a best-class offer.",
	p_personalize_personalize: "Personalize any videos added to this communication.",
	p_personalize_clearcart: "Remove these items from your My Asset Library cart after sending.",
	p_personalize_onevideo: "Combine multiple videos into one whenever possible.",
	p_create_add: "Add files from Asset Library.",
	al_shared: "Shared Library content is available to all users for sending. Add content to cart & send.",
	al_my: "Choose to add folders & content by clicking +New. This content is only viewable and usable by you.",
	al_cart: "Items which have been added to Cart can be sent via Personalize & Send.",
	al_new: "Admins add new folders & media to Shared Asset Library, other users can add new folders & media to My Asset Library.",
	al_personalize: "Add Asset Library content, personalize a message and send via Accelerate email.",
	v_build: "Build your personalized video. Preview and share.",
	v_email: "Email video to recipient(s) with a message. You do not have to wait for preview to be built",
	signup_term_quotes: "Run quotes & compare insurance companies from your own computer 24 hours/day, 7 days/week.",
	signup_personalize_send: "Send your term life quotes & other rich media (to help you sell) to clients or prospects.",
	signup_assets_library: "Access & send insurance-based content (PDFs, Podcasts, Videos) which is centrally-stored in the Accelerate Digital Asset Library.",
	signup_proposal_send: "Brand yourself, your agency, and/or ORG by sending your quote and/or media within an automatically generated, award-winning proposal document.",
	signup_leverage: "Leverage insurance-specific information (your agency, your BGA, products, carriers) via pre-built text templates and include it in your proposal document.",
	signup_branded_quote: "Automatically send a branded (with your picture, logo, contact information) video, which includes your product recommendation, with your term quote. Client can interact with quote and contact you directly.",
	signup_dashboard: "Your new Accelerate home page will include quick links to core features and a dashboard which displays your latest, quotes, applications and reports.",
	signup_analytics: "View all your quoting & application activity on one page. Customize the data and graphical outputs by: Premium, Face Amount, Count, Date, & Carrier.",
	signup_personalized_videos: "Easily & quickly create & customize short videos from the Accelerate Personalized Video library to engage with your clients. Send in a one-to-one OR one-to-many fashion.",
	signup_resource: "This field is auto-populated with the company name, but you can modify it.",
	signup_my_quotes: "Accelerate will store all of your Term Life Quotes and display them is an easy to use, searchable list.",
	signup_engagement: "Recipient engagement tracking insight at your fingertips. Was my email delivered? Did the email bounce? When was my email opened? Did the recipient click the link I included & when did they click?",
	signup_self_service: "Consumer Self-Quoting Platform allows your prospects and customers to generate term life quotes 24/7 on a site branded for your brokerage.",
	company_logo: "Company logo setup by your administrator will be used if left empty.  Bitmap (.bmp), JPEG (.jpg, .jpeg), GIF (.gif) and PNG (.png) file formats accepted.  For best results, use a high resolution, transparent image.",
	company_logo_signup: "Bitmap (.bmp), JPEG (.jpg, .jpeg), GIF (.gif) and PNG (.png) file formats accepted.  For best results, use a high resolution, transparent image.",
	profile_image: "Bitmap (.bmp), JPEG (.jpg, .jpeg), GIF (.gif) and PNG (.png) file formats accepted.  For best results, use a high resolution image.",
	message_sent_items: "View all communications you sent in Accelerate. Filter by date. Select each communication to view more details about it.",
	message_recent: "View what you sent in Accelerate & the status (opened, delivered, etc.). Filter by date or search Activities by keyword.",
	message_send: "A quick method to send a communication within Accelerate. Choose to include media from the Asset Library.",
	admin_company: "This page stores important information regarding the company associated with this Accelerate account: Name, Logo, & URL (where Accelerate users, tied to this company, access Accelerate).",
	admin_media: "Create the Asset Library for Accelerate users.  Organize and setup topics and folders and then add media (PDFs, Videos, Podcasts, Infographics etc) to each folder.",
	admin_welcome: "Create Welcome or What’s New information for users to see when they log into Accelerate.",
	create_video: "Select and personalize a video from the library below to send to client and prospects.",
	assets_library: "Select media (PDFs, Videos) from Asset Library folders to send to clients or prospects.",
	upload_files: "Accepted files types are: JPG, PNG, GIF",
};
