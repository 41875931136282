<div mat-dialog-header>
	<h2 mat-dialog-title>Upload New File</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<mat-tab-group>
			<mat-tab label="Upload File">
				<form [formGroup]="uploadForm">
					<p>Enter a description and select a file to upload</p>
					<div class="form">
						<mat-form-field>
							<mat-label>Media Description</mat-label>
							<input matInput #fileDescription [formControl]="descriptionField" />
							<mat-error *ngIf="descriptionField.hasError('required')">Description is required</mat-error>
						</mat-form-field>
						<acc-common-file-upload formControlName="file"></acc-common-file-upload>
					</div>	
					<mat-dialog-actions>
						<button mat-raised-button color="primary" (click)="upload()">Save</button>
						<button mat-stroked-button (click)="close()">Cancel</button>
					</mat-dialog-actions>
				</form>
			</mat-tab>
			<mat-tab label="Upload from URL">
				<p>Enter a description and a file URL</p>
				<div class="form" >
					<mat-form-field>
						<mat-label>Media Description</mat-label>
						<input matInput [formControl]="urlDescriptionField" />
						<mat-error *ngIf="urlDescriptionField.hasError('required')">Description is required</mat-error>
					</mat-form-field>
					<mat-form-field>
						<mat-label>Url</mat-label>
						<input matInput [formControl]="urlField" />
						<mat-error *ngIf="urlField.hasError('required')">URL is required</mat-error>
					</mat-form-field>
				</div>
				<mat-dialog-actions>
					<button mat-raised-button color="primary" (click)="uploadByUrl()">Upload</button>
					<button mat-stroked-button (click)="close()">Cancel</button>
				</mat-dialog-actions>
			</mat-tab>
		</mat-tab-group>
	</div>
</mat-dialog-content>
