export class Module {
	public moduleID: number;
	public moduleDesc: string;
	public moduleTypeID: number;
	public active: boolean;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export enum ModuleType {
	STANDARD = 1,
	ADVANCED = 2
}