import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'acc-common-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

	public yesText: string = 'Yes';
	public noText: string = 'No'
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
		if(this.data.yesText != undefined) { this.yesText = this.data.yesText; }
		if(this.data.noText != undefined) { this.noText = this.data.noText; }
	}

}
