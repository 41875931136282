
<div mat-dialog-header>
	<h2 mat-dialog-title>Communication Test</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<mat-form-field>
			<mat-label>Email</mat-label>
			<input type="text" id="email" name="email" matInput [(ngModel)]="data.email"  />
		</mat-form-field>
		<mat-form-field>
			<mat-label>Phone</mat-label>
			<input type="text" id="phone" name="phone" matInput [(ngModel)]="data.phone"  />
		</mat-form-field>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
        <button id="btnTest" mat-stroked-button (click)="close()">Close</button>
		<button mat-raised-button color="primary" (click)="executeTest()" [disabled]="data.email === '' || data.phone === ''" cdkFocusInitial>Execute Test</button>
	</mat-dialog-actions>

