import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'acc-services';
import { CommunicationComponent } from './communication.component';
import { ListsComponent } from './lists/lists.component';
import { ListDetailComponent } from './list-detail/list-detail.component';
import { ListImportComponent } from './list-import/list-import.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';
import { ReportingComponent } from './reporting/reporting.component';
import { TemplateComponent } from '../components/template/template.component';
import { CampaignActionsComponent } from './campaigns/campaign-actions/campaign-actions.component';
import { SubscriptionLevelGuard } from 'projects/acc-services/src/lib/guards/level.guard';
import { GroupsComponent } from './groups/groups.component';

const routes: Routes = [
	{
		path: 'communication',
		component: TemplateComponent,
		children: [{
			path: '',
			component: CommunicationComponent, 
			children: [
				{path: 'groups', component: GroupsComponent, data: { pageTitle: 'List Groups', level: 2} },
				{path:'lists', component: ListsComponent, canActivate:[AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Communication Lists', level: 2} },
				{path: 'lists/list-detail/:listID', component: ListDetailComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Communication Lists', level: 2} },
				{path: 'lists/list-import/:listID', component: ListImportComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Communication Lists', level: 2} },
				{path:'campaigns', component: CampaignsComponent, canActivate:[AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Campaigns', level: 2} },
				{path: 'campaigns/campaign-new', component: CampaignFormComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'New Campaign', level: 2} },
				{path: 'campaigns/campaign-edit/:campaignID', component: CampaignFormComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Edit Campaign', level: 2} },
				{path:'reporting', component: ReportingComponent, canActivate:[AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Campaign Reporting', level: 2} },
			]
		}]
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunicationRoutingModule { }
