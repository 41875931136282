import "hammerjs";
import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AccCommonModule, DialogComponent } from "projects/acc-common/src/public_api";
import { AccServicesModule, DataService, SessionService, AuthInterceptor } from "projects/acc-services/src/public_api";
import { LandingComponent } from "./components/landing/landing.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { LoginComponent } from "./components/auth/login/login.component";
import { TemplateComponent } from "./components/template/template.component";
import { RouterModule } from "@angular/router";
import { NgProgressModule } from "@ngx-progressbar/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { StoreModule } from "@ngrx/store";
import { appReducers } from "./store/reducers/app.reducers";
import { AuthComponent } from "./components/auth/auth/auth.component";
import { ChangePasswordComponent } from "./components/auth/change-password/change-password.component";
import { RecoverPasswordComponent } from "./components/auth/recover-password/recover-password.component";
import { ResetPasswordComponent } from "./components/auth/reset-password/reset-password.component";
import { DocDetailsComponent } from "./components/module/doc-details/doc-details.component";
import { FormComponent } from "./components/module/editor/form/form.component";
import { NavigationComponent } from "./components/module/editor/navigation/navigation.component";
import { ActionsComponent } from "./components/module/editor/actions/actions.component";
import { ContainerComponent } from "./components/module/container/container.component";
import { AccountSelectorComponent } from "./components/shared/account-selector/account-selector.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { QuillModule } from "ngx-quill";
import { TeamPictureComponent } from "./components/team-picture/team-picture.component";
import { MatProgressButtonsModule } from "mat-progress-buttons";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ProfileComponent } from "./components/profile/profile.component";
import { AdminModule } from "./admin/admin.module";
import { AdminRoutingModule } from "./admin/admin-routing.module";
import { NgDragDropModule } from "ng-drag-drop";
import { AuthGuard } from "acc-services";
import { EffectsModule } from "@ngrx/effects";
import { UserEffects } from "./store/effects/users.effects";
import { CategoriesEffects } from "./store/effects/categories.effects";
import { MediaEffects } from "./store/effects/media.effects";
import { mediaCategoryEffects } from "./store/effects/mediacategory.effects";
import { CatManageEffects } from "./store/effects/catmanage.effects";
import { StatusEffects } from "./store/effects/status.effects";
import { TextLibEffects } from "./store/effects/textlib.effects";
import { RevisionDatesEffects } from "./store/effects/revisiondates.effects";
import { LibraryComponent } from "./components/library/library.component";
import { EditOutlineComponent } from "./components/module/edit-outline/edit-outline.component";
import { LogInAsComponent } from "./components/template/log-in-as/log-in-as.component";
import { SalesforceLoginComponent } from "./components/shared/salesforce-login/salesforce-login.component";
import { SalesforceOauthComponent } from "./components/shared/salesforce-oauth/salesforce-oauth.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CategorySortComponent } from "./components/settings/category-sort/category-sort.component";
import { ProfileEditComponent } from "./components/profile/edit/edit.component";
import { ModuleAssetLibraryComponent } from "./components/welcome/module-asset-library/module-asset-library.component";
import { SharedModule } from "./shared/shared.module";
import { VideoComponent } from "./components/video/video.component";
import { LoaderComponent } from "./components/shared/loader/loader.component";
import { CommunicationModule } from "./communication/communication.module";
import { CommunicationEffects } from "./store/effects/communication.effects";
import { VideosModule } from "./videos/videos.module";
import { QuoteEngineModule } from "./quote-engine/quote-engine.module";
import { QuoteResultsModule } from "./quote-results/quote-results.module";
import { SendComponent } from "./components/module/send/send.component";
import { TemplateNameComponent } from "./components/module/container/template-name/template-name.component";
import { ModuleBlockComponent } from "./components/welcome/module-block/module-block.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { LoadingErrorComponent } from "./components/loading/loading-error/loading-error.component";
import { SsoComponent } from "./sso/sso.component";
import { NavColorComponent } from "./components/template/nav-color/nav-color.component";
import { SubscriptionLevelGuard } from "projects/acc-services/src/lib/guards/level.guard";
import { LockFeaturesComponent } from "./components/lock-features/lock-features.component";
import { DataBlockComponent } from "./components/welcome/data-block/data-block.component";
import { HelpDialogComponent } from "./components/template/help-dialog/help-dialog.component";
import { ErrorDialogComponent } from "./components/template/error-dialog/error-dialog.component";
import { SignUp1Component } from "./signup/signup-first/signup-first.component";
import { SignUp2Component } from "./signup/signup-second/signup-second.component";
import { SignUp3Component } from "./signup/signup-third/signup-third.component";
import { SignUpComponent } from "./signup/signup.component";
import { VerifyComponent } from "./signup/verify/verify.component";
import { SubscriptionComponent } from "./signup/subscription/subscription.component";
import { InfoDialogComponent } from "./components/template/info-dialog/info-dialog.component";
import { SentItemsComponent } from "./components/messaging/sent-items/sent-items.component";
import { SignUpWelcomeComponent } from "./signup/signup-welcome/signup-welcome.component";
import { ActivityComponent } from "./components/messaging/activity/activity.component";
import { SignUpGuard } from "projects/acc-services/src/lib/guards/signup.guard";
import { MasterOrgGuard } from 'projects/acc-services/src/lib/guards/master-org.guard';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { environment } from '../environments/environment';
import { DocbuilerOptionsComponent } from './components/shared/docbuiler-options/docbuiler-options.component';
import { TrialFeaturePopupComponent } from './components/shared/trial-feature-popup/trial-feature-popup.component';
import { DtcModule } from "./dtc/dtc.module";
import { PreferencesComponent } from './components/preferences/preferences.component';
import { MktModule } from "./mkt/mkt.module";
import { CanNavigateAwayGuard } from "./components/guard/can-navigate-away";
import { YourFamilyComponent } from './components/your-family/your-family.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';

//Bugsnag API Key
Bugsnag.start({
	//enabledReleaseStages: [ 'production', 'stage', 'dev' ],
	releaseStage: environment.environment_name,
	apiKey: environment.bugsnag_api_key,
	appVersion: environment.appVersion,
	enabledReleaseStages: ['production', 'stage', 'dev']
});

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
	return new BugsnagErrorHandler()
  }

@NgModule({
	declarations: [
		AppComponent,
		LandingComponent,
		WelcomeComponent,
		LoginComponent,
		TemplateComponent,
		AuthComponent,
		ChangePasswordComponent,
		RecoverPasswordComponent,
		ResetPasswordComponent,
		DocDetailsComponent,
		FormComponent,
		NavigationComponent,
		ActionsComponent,
		ContainerComponent,
		AccountSelectorComponent,
		TeamPictureComponent,
		ProfileComponent,
		LibraryComponent,
		EditOutlineComponent,
		LogInAsComponent,
		SalesforceLoginComponent,
		SalesforceOauthComponent,
		CategorySortComponent,
		ProfileEditComponent,
		ModuleAssetLibraryComponent,
		VideoComponent,
		LoaderComponent,
		SendComponent,
		TemplateNameComponent,
		ModuleBlockComponent,
		LoadingComponent,
		LoadingErrorComponent,
		SsoComponent,
		NavColorComponent,
		LockFeaturesComponent,
		DataBlockComponent,
		HelpDialogComponent,
		ErrorDialogComponent,
		SignUp3Component,
		VerifyComponent,
		SignUp1Component,
		SignUp2Component,
		SignUpComponent,
		SubscriptionComponent,
		InfoDialogComponent,
		SentItemsComponent,
		SignUpWelcomeComponent,
		ActivityComponent,
		DocbuilerOptionsComponent,
		TrialFeaturePopupComponent,
		PreferencesComponent,
		YourFamilyComponent,
		UnsubscribeComponent
	],
	imports: [
		BrowserModule,
		AdminModule,
		CommunicationModule,
		VideosModule,
		QuoteEngineModule,
		QuoteResultsModule,
		SharedModule,
		DtcModule,
		MktModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		AccCommonModule,
		AccServicesModule,
		RouterModule.forRoot([]),
		StoreModule.forRoot(appReducers),
		EffectsModule.forRoot([UserEffects, CategoriesEffects, MediaEffects, mediaCategoryEffects, CatManageEffects, StatusEffects, TextLibEffects, RevisionDatesEffects, CommunicationEffects]),
		RouterModule,

		DragDropModule,
		NgDragDropModule.forRoot(),
		NgProgressModule,
		MatToolbarModule,
		MatDatepickerModule,
		MatIconModule,
		MatDialogModule,
		MatInputModule,
		MatCheckboxModule,
		MatSelectModule,
		MatOptionModule,
		MatButtonModule,
		MatProgressButtonsModule,
		MatCardModule,
		MatMenuModule,
		MatToolbarModule,
		MatSnackBarModule,
		MatTabsModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatTooltipModule,
		MatSidenavModule,
		MatStepperModule,
		MatListModule,
		MatRadioModule,
		MatExpansionModule,

		QuillModule.forRoot({
			modules: {
				toolbar: {
					container: [
						["bold", "italic", "underline"], // toggled buttons
						[{ color: [] }],
						[{ list: "bullet" }],
						['link'],
					],
					handlers: { emoji: noop },
				},
			},
		}),
	],
	 /* Pass the BugsnagErrorHandler class along to the providers for your module */
	providers: [DataService, SessionService, AuthGuard, SubscriptionLevelGuard, CanNavigateAwayGuard, SignUpGuard, MasterOrgGuard, { provide: MatDialogRef, useValue: {} }, { provide: MAT_DIALOG_DATA, useValue: [] }, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, { provide: ErrorHandler, useFactory: errorHandlerFactory } ],
	bootstrap: [AppComponent],
	entryComponents: [DialogComponent, ChangePasswordComponent, LogInAsComponent, SalesforceLoginComponent, ModuleAssetLibraryComponent, LoaderComponent, 
		TemplateNameComponent, LockFeaturesComponent, DocbuilerOptionsComponent, TrialFeaturePopupComponent, PreferencesComponent],
})
export class AppModule {}
export function noop() {}
