
<div class="container">
	<div *ngIf="!videoId">
		<p>Your video is loading.</p>
	</div>

	<div *ngIf="videoId" class="content">
		<video width="810" controls >
			<source src="{{ videoUrl }}" />
		</video>
		<div class="button-container">
			<button mat-raised-button color="primary" (click)="getStarted()">GET STARTED</button>
		</div>
	</div>
</div>
