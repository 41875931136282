export class QuoteSaveProps {
    
	public isAccidentalDeathBenefitRider : boolean;
	public isWaiverOfPremiumRider : boolean;
	public isChildTermRider : boolean;
	public isReturnOfPremiumRider: boolean;
	public vraeUsage : number;
	public simplifiedUsage : boolean;
	public tableRating : number;
    public isGuaranteed : boolean;
    public flatExtraAmount : number;
    public flatExtraYearStart : number;
	public flatExtraYearEnd : number;
	public childRiderUnit : number;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}