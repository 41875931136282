import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, MixpanelService, QuoteService, SessionService } from 'acc-services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User, Settings } from 'acc-models';

@Component({
  selector: 'acc-main-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  private token: string;
  public loading = true;
  public orgs;

  constructor(
    private route: ActivatedRoute, 
    private router:Router,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private quoteService: QuoteService, 
    private mixpanelService: MixpanelService 
  ) {
    
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
		});

  }

  ngOnInit() {
    this.verifyToken();
  }


  verifyToken(){
    this.dataService.getOrgsByToken(this.token).subscribe((resp:any) =>{

      if (resp.length <= 0){
        this.loading = false;
        this.snackBar.open("Error getting the organizations, please contact the administrator", 'Close', { duration: 5000 });
      }else if (resp.length == 1){        
        var org = resp[0];
        this.loginWithToken(org);
      }else if (resp.length > 0) {
        this.orgs = resp;
        this.loading = false;
      }
    },
    (err) => {
      if(err.error){
        this.snackBar.open(err.error.Message, 'Close', { duration: 5000 });
      }else{
        this.snackBar.open("Error getting the organizations, please contact the administrator", 'Close', { duration: 5000 });
      }
    });
  }

  loginWithToken(org){

    this.sessionService.removeItem('organization');
    if(org.orgID) {
      this.sessionService.setItem('organization', org);
    }

    this.dataService.loginWithToken(org.orgID, this.token).subscribe((resp:any) =>{

      this.sessionService.setUser(new User(resp));
      this.sessionService.authToken = (<User>resp).token;

		  //Get Org configuration
      this.quoteService.loadQuoteOrgConfiguration();
      this.mixpanelService.initMixpanelTracker(this.router);

      //get settings
      this.dataService.getSettings(org.orgID).subscribe(resp => {
        this.sessionService.settings = new Settings(resp);

        this.router.navigateByUrl('/welcome');
      }, error => {
        //if a problem, set defaults
        this.sessionService.settings = new Settings();

        this.router.navigateByUrl('/welcome');
      })
      this.sessionService.authToken = (<User>resp).token;

    },
    (err) => {
      if(err.error){
        this.snackBar.open(err.error.Message, 'Close', { duration: 5000 });
      }else{
        this.snackBar.open("Error during login, please contact the administrator", 'Close', { duration: 5000 });
      }
    });

  }
}
