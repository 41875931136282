import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataService, SessionService } from 'acc-services';
import { User, Module, Category, SubCategory } from 'acc-models';

@Component({
  selector: 'acc-main-category-sort',
  templateUrl: './category-sort.component.html',
  styleUrls: ['./category-sort.component.scss']
})

@Component({
	templateUrl: './category-sort.component.html',
	styleUrls: ['./category-sort.component.scss']
})

export class CategorySortComponent implements OnInit {

	public moduleId: number = 0;
	public categoryId: number = 0;
	public modules: Array<Module>;
	public categories: Array<Category>;
	public subcategories: Array<SubCategory>;

	private user: User;

	constructor(private dataService:DataService, private sessionService:SessionService) {}

	ngOnInit():void {
		this.user = this.sessionService.getUser();
		this.dataService.getModules(this.user.orgID).subscribe((resp:any) => {
			this.modules = resp;
		})
	}
	getCategories():void {
		if(this.moduleId > 0) {
			this.dataService.getCategoriesForModule(this.moduleId, this.user.userID).subscribe((resp:any) => {
				this.categories = resp;
				this.subcategories = [];
			})
		}
	}

	getSubcategories(category:Category):void {
		this.categoryId = category.categoryID;
		this.subcategories = category.SubCategories;
	}
	
	dropCategory(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
		const newOrder = this.categories.map((c,i) => { return { ID: c.categoryID, sortOrder:  i + 1 }; });
		this.dataService.resortCategories(this.user.userID, this.moduleId, newOrder).subscribe(res => {

		})
	}
	dropSubCategory(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.subcategories, event.previousIndex, event.currentIndex);
		const newOrder = this.subcategories.map((c,i) => { return { ID: c.subCategoryID, sortOrder:  i + 1 }; });
		this.dataService.resortSubCategories(this.user.userID, this.moduleId, this.categoryId, newOrder).subscribe(res => {

		})
	}
	
}