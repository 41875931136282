import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { SessionService } from "../services/session.service";

@Injectable()
export class MasterOrgGuard implements CanActivate {
	constructor(private router: Router, private sessionService: SessionService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		var orgID = this.sessionService.getMasterOrgID();
		return (orgID == null || orgID <= 0);
	}
}
