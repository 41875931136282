import { Action } from '@ngrx/store';

export const ROOT_RESET = "[ROOT] Reset";

export class RootReset implements Action {
	public readonly type = ROOT_RESET;
	constructor() {}
}

export type RootActions = 
	RootReset;
