import { ElementRef, EventEmitter, Output } from "@angular/core";
import { ViewChild } from "@angular/core";
import { AfterViewInit } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { OrganizationProfile, Profile } from "acc-models";
import { DataService, SessionService } from "acc-services";

@Component({
	selector: "acc-main-org-profile",
	templateUrl: "./org-profile.component.html",
	styleUrls: ["./org-profile.component.scss"],
})
export class OrgProfileComponent implements OnInit, AfterViewInit {

	@Input() orgID: number;
	@Output() profileLoaded: EventEmitter<Profile> = new EventEmitter();
	@ViewChild('companyInfo') companyInfo: ElementRef;
	public hasCompanyInfo: boolean = false;
	public phoneMask = "(000) 000-0000";

	public profileForm: FormGroup;
	public emailField: FormControl  = new FormControl();
	public phoneField: FormControl  = new FormControl();
	public websiteField: FormControl  = new FormControl();
	public address1Field: FormControl  = new FormControl();
	public address2Field: FormControl  = new FormControl();
	public cityField: FormControl  = new FormControl();
	public stateField: FormControl  = new FormControl();
	public zipField: FormControl  = new FormControl();

	public companyForm: FormGroup;
	public companyNameField: FormControl = new FormControl();

	public companyName: string;

	private origOrgProfile: OrganizationProfile;

	constructor(private fb: FormBuilder, private dataService :DataService, private sessionService: SessionService) {}

	ngOnInit() {
		this.profileForm = this.fb.group({
			email: this.emailField,
			phone: this.phoneField,
			website: this.websiteField,
			address1: this.address1Field,
			address2: this.address2Field,
			city: this.cityField,
			state: this.stateField,
			zip: this.zipField
		});
		this.dataService.getOrgProfileFromID(this.orgID).subscribe((orgProf:any) => {
			if(orgProf) {
				this.origOrgProfile = orgProf;
				this.profileForm.get('email').setValue(orgProf.email);
				this.profileForm.get('phone').setValue(orgProf.phone);
				this.profileForm.get('website').setValue(orgProf.website);
				this.profileForm.get('address1').setValue(orgProf.address1);
				this.profileForm.get('address2').setValue(orgProf.address2);
				this.profileForm.get('city').setValue(orgProf.city);
				this.profileForm.get('state').setValue(orgProf.state);
				this.profileForm.get('zip').setValue(orgProf.zip);

				this.profileLoaded.next(orgProf);
			}
		});

	}

	ngAfterViewInit() {
		if((this.companyInfo.nativeElement.innerHTML ?? '').trim() !== '') {
			//this has company info fields, so fill those out
			this.companyForm = this.fb.group({
				companyName: this.companyNameField,
				resourceName: new FormControl()
			})
			this.dataService.getCompanyInformation(this.sessionService.getUser().orgID).subscribe(
				(resp: any) => {
					this.companyName = resp.orgDesc;

					this.companyForm.get('companyName').setValue(resp.orgDesc);
					this.companyForm.get('resourceName').setValue(resp.resource);
				}
			);
		}
	}

	public save(): Promise<OrganizationProfile> {
		return new Promise((resolve, reject) => {
			let orgProf = this.getOrgProfileFromForm();
			this.dataService.saveOrgProfile(orgProf).subscribe(() => {
				this.origOrgProfile = orgProf;
				resolve(orgProf);
			}, () => {
				reject();
			})
		})
	}
	public isDirty(): boolean {
		const currOrgProf = this.getOrgProfileFromForm();
		let val = currOrgProf.email != this.origOrgProfile?.email
			|| currOrgProf.website != this.origOrgProfile?.website
			|| currOrgProf.phone != this.origOrgProfile?.phone.trim()
			|| currOrgProf.address1 != this.origOrgProfile?.address1
			|| currOrgProf.address2 != this.origOrgProfile?.address2
			|| currOrgProf.city != this.origOrgProfile?.city
			|| currOrgProf.state != this.origOrgProfile?.state
			|| currOrgProf.zip != this.origOrgProfile?.zip;
		return val;
	}

	private getOrgProfileFromForm(): OrganizationProfile {
		return new OrganizationProfile({
			email: this.emailField.value,
			website: this.websiteField.value,
			phone: this.phoneField.value,
			address1: this.address1Field.value,
			address2: this.address2Field.value,
			city: this.cityField.value,
			state: this.stateField.value,
			zip: this.zipField.value,
			orgID: this.orgID
		});
	}

	private shallowEqual(object1, object2) {
		//if one object has more properties than the other, make the first parameter the one with fewer properties
		const keys1 = Object.keys(object1);
		const keys2 = Object.keys(object2);
	  
		for (let key of keys1) {
		  if (object1[key] !== object2[key]) {
			return false;
		  }
		}
	  
		return true;
	  }
}
