import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewListComponent } from '../../new-list/new-list.component';
import { DataService, SessionService } from 'acc-services';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { map, takeUntil } from 'rxjs/operators';
import { CommunicationActionTypes, CommunicationActions } from '../../../store/actions/communication.actions';
import { Subject } from 'rxjs';

@Component({
  selector: "acc-main-list-actions",
  templateUrl: "./list-actions.component.html",
  styleUrls: ['../../shared/actions.scss']
})
export class ListActionsComponent implements OnInit, OnDestroy {
	private destroy$: Subject<boolean> = new Subject();

  constructor(public dialog: MatDialog, private dataService: DataService, private sessionService: SessionService, private snackBar: MatSnackBar, private router: Router, private actions$: Actions) {}

  ngOnInit() {
	this.actions$.pipe(
		map((a: any) => {
			if(a.type == CommunicationActionTypes.NEW_LIST_SUCCESS) {
				this.router.navigate(["/communication/lists/list-detail", a.list.listID]);
			}
		}),
		takeUntil(this.destroy$)
	).subscribe();
  }

  addNewList() {
    const dialogRef = this.dialog.open(NewListComponent, {
      data: { listName: "", listDesc: "" }
    });
  }

  ngOnDestroy(): void {
	  this.destroy$.next(true);
	  this.destroy$.complete();
  }
			
}
