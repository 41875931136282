import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MatDialogRef } from '@angular/material/dialog';
import { SessionService } from 'acc-services';

@Component({
  selector: 'acc-main-salesforce-login',
  templateUrl: './salesforce-login.component.html',
  styleUrls: ['./salesforce-login.component.scss']
})
export class SalesforceLoginComponent implements OnInit  {
	
	public crmLoginUrl: any;
	constructor(private sessionService: SessionService, private router:Router, public dialogRef: MatDialogRef<SalesforceLoginComponent>) {}

	ngOnInit():void {
	}
	close() {
		this.dialogRef.close(false);
	}
	login() {
		this.dialogRef.close(true);
		let user = this.sessionService.getUser();
		this.sessionService.setItem('returnUrl', this.router.url);
		window.location.href = user.crmOAuthRedirectURL + window.location.origin + '/salesforce-oauth';
	}
}