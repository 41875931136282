<div class="card-content">

	<div class="signup-button-container">
		<p>No credit card required</p>
		<button mat-raised-button class="action-button" color="primary" (click)="next()">Get Started Free</button>
	</div>

	<div class="signup-text-container-video">
		<div class="signup-testimony">"<span class="signup-testimony-bold"> Accelerate</span> is the best sales &amp; marketing tool to help sell policies and grow your business."</div>
		<div class="signup-testimony-signature">
			<div>-Dustin Johnson</div>
			<div class="signup-testimony-signature-title">EVP Marketing, Chief of Staff<br />One Resource Group</div>
		</div>
	</div>

	<div class="video-container signup-video-background">
		<!--
		<div class="logo">
		</div>
		-->
		<div class="signup-video-container">
			<p>Play video to learn more about Accelerate</p>
			<video #videoPlayer width="735" controls poster="{{activeVideoUrl.replace('mp4', 'png')}}" (ended)="reloadVideo()" >
				<source src="{{ activeVideoUrl }}" />
			</video>
		</div>
		
	</div>

	<div class="signup-text-background">
		<div class="subhead"><span class="signup-acc-bold">Accelerate</span> is more than a better Term Life Quoter. It’s your new platform to market, sell and analyze your life insurance business.</div>
	</div>
</div>
