<div class="photo {{cssClass}}">
	<ng-container *ngIf="photo; else nophoto">
		<div class="image-placeholder" #croppieImage></div>
	</ng-container>
	<ng-template #nophoto>
		<div class="image-placeholder no-image">
			<h3>{{defaultTitle}}</h3>
			<h4>{{defaultDescription}}</h4>
		</div>
	</ng-template>
	<div class="controls">
		<h1 *ngIf="caption">{{caption}}</h1>
		<div class="upload-button-container">
			<div class="upload-button">
				<button class="btn secondary">{{buttonText}}</button>
				<input type="file" class="file-input" (change)="upload($event);" accept="{{accept?.join(',')}}" #fileInput>
			</div>
			<mat-icon class="info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{selectedTooltip}}">info_outline</mat-icon>
		</div>
		<div *ngIf="photo" class="delete" (click)="delete()">Delete</div>
		<div *ngIf="displayError">{{errorMessage}}</div>
	</div>
</div>
