import { Component, OnInit , Input, ViewChild, NgZone, Output, EventEmitter } from "@angular/core";
import { CustVideoParm } from "acc-models";
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';



@Component({
	selector: "acc-main-cust-video-parms",
	templateUrl: "./cust-video-parms.component.html",
	styleUrls: ["./cust-video-parms.component.scss"]
})
export class CustVideoParmsComponent implements OnInit {
	@Input() params: Array<CustVideoParm>;
	@ViewChild('autosize') autosize: CdkTextareaAutosize;
	@Output()
	notify = new EventEmitter<boolean>();

	public imageFileTypes = ['.jpg', '.jpeg', '.png', '.gif'];
	public invalidFileType: boolean = false;
  
	fileMessage = [];
	file = [];

	maxChars = 59;
	constructor(
		private _ngZone: NgZone
	) {}

	ngOnInit() {}

	onFileChange(event, paramName, index) {
		if (event.target.files[0]) {
			const ext = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.'));
			if(!this.imageFileTypes.some(e => e === ext)) {
				this.invalidFileType = true;
				return;
			}
			this.invalidFileType = false;
			this.file[index] = event.target.files[0];
			let filename = event.target.files[0].name.split("/").reverse()[0];
			this.fileMessage[index] = `${filename} will be uploaded.`;

			let fileParam = this.params.find(x => x.parmName === paramName);
			fileParam.parmValue = this.file[index];
		}
	}

	removeFile(index) {
		this.fileMessage[index] = null;
	}

	triggerResize() {
		// Wait for changes to be applied, then trigger textarea resize.
		this._ngZone.onStable.pipe(take(1))
			.subscribe(() => this.autosize.resizeToFitContent(true));
	}

	keyPress(event: KeyboardEvent, maxRows) {
		var text = (<HTMLInputElement>event.target).value;
		var numberOfLines = (text.match(/\n/g) || []).length + 1;

		if (event.which === 13 && numberOfLines === maxRows ) {
			event.preventDefault();
		}
	}

	paramValueChanged(event){
		this.notify.emit(true);
	}
}
