<mat-card>
<div class="popup-container">
	<h2>Your 30-day Free trial of Accelerate PRO ends in {{daysLeft}} day{{daysLeft == 1 ? '' : 's'}}.</h2>
	<div class="feature-content" *ngIf="featureNumber == 1">
		<p class="title">Have you tried the Personalized Video feature yet?</p>
		<section>
			<img src="../../../../assets/images/trial-feature/PersVideoBranded1.png">
			<!--
			<div>
				<h4>Create Your Own Branded Agency Video</h4>
				<p>Customize the messaging on all three screens</p>
				<p>Video branded with your agency logo and website url</p>
				<p>Press ENTER/RETURN to separate your text onto multiple lines.  Two lines maximum.</p>
			</div>
		-->
		</section>
		<footer>Go to <a [routerLink]="['/videos', 'create-video']">Personalized Video</a> to create &amp; send one to a client.</footer>
	</div>
	<div class="feature-content" *ngIf="featureNumber == 2">
		<p class="title">Have you viewed the Analytics &amp; Reporting yet?</p>
		<section>
			<img src="../../../../assets/images/trial-feature/AnalyticsReporting.png">
		</section>
		<footer>Go to <a [routerLink]="['/quote', 'analytics']">Life Quote/Analytics &amp; Reporting</a> to look at quoting activity.</footer>
	</div>

	<div class="feature-content" *ngIf="featureNumber == 3">
		<p class="title">Did you know you can track Accelerate communications?</p>
		<section>
			<img src="../../../../assets/images/trial-feature/RecentActivity.png">
		</section>
		<footer>Go to <a [routerLink]="['/recent-activity']">Messaging/Recent Activity &amp; Sent Items</a> page.</footer>
	</div>

	<div class="feature-content" *ngIf="featureNumber == 4">
		<p class="title">Have you noticed a branded video is included with your Term Quote?</p>
		<section>
			<img src="../../../../assets/images/trial-feature/QuoteVideo.png">
		</section>
		<footer>The video brands, personalizes &amp; helps sell the quote.</footer>
	</div>

	<div class="feature-content" *ngIf="featureNumber == 5">
		<p class="title">View the video below to see what Accelerate can do for you.</p>
		<section>
			<video controls poster="{{activeVideoUrl.replace('mp4', 'png')}}" >
				<source src="{{ activeVideoUrl }}" />
			</video>
		</section>
		<footer></footer>
	</div>
</div>
</mat-card>