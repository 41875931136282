import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataService } from 'acc-services';
import * as MediaActions from '../actions/media.actions';
import { switchMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IMediaState } from '../state/media.state';
import { of } from 'rxjs';
import * as StatusActions from '../actions/status.actions';
import { Media } from 'acc-models';


@Injectable()
export class MediaEffects {

	@Effect()
	getMedias$ = this.actions$.pipe(
		ofType(MediaActions.MediaActionTypes.GET_MEDIAS),
		map((a:MediaActions.GetMedias) => a),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		switchMap(([action, orgId]) => {
			return this.dataService.getSharedLibContents(orgId, action.mediaCategoryID)
				.pipe(
					map((resp:any) => new MediaActions.GetMediasSuccess(resp.Medias)),
					catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
				)
			}
		)
	)
	@Effect()
	editMedia$ = this.actions$.pipe(
		ofType(MediaActions.MediaActionTypes.EDIT_MEDIA_CONFIRM),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		withLatestFrom(this.store$.select('adminMedia', 'EditingMedia')),
		withLatestFrom(this.store$.select('adminMedia', 'AddMediaFile')),
		withLatestFrom(this.store$.select('adminMedia', 'AddMediaThumbnail')),
		switchMap(([[[[action, orgId], media], file], thumbnail]) => {
			return this.dataService.saveMedia(orgId, media, file, thumbnail)
				.pipe(
					map((resp:any) => new MediaActions.EditMediaSuccess(resp)),
					catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
				)
			}
		)
	)

	@Effect()
	deleteMedia$ = this.actions$.pipe(
		ofType(MediaActions.MediaActionTypes.DELETE_MEDIA_CONFIRM),
		withLatestFrom(this.store$.select('adminMedia', 'DeletingMedia')),
		switchMap(([action, deletingMedia]) => this.dataService.deleteMediaAdmin(deletingMedia)
			.pipe(
				map((resp:any) => new MediaActions.DeleteMediaSuccess),
				catchError(((resp:any) => { 
					this.store$.dispatch(new MediaActions.DeleteMediaCancel()); //hide the form
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	addMedia$ = this.actions$.pipe(
		ofType(MediaActions.MediaActionTypes.ADD_MEDIA_CONFIRM),
		map((a:MediaActions.AddMediaConfirm) => a),
		withLatestFrom(this.store$.select('adminUser', 'orgId')),
		switchMap(([action, orgId]) => {
			return this.dataService.saveMedia(orgId, action.media, action.mediaFile, action.mediaThumbnail)
				.pipe(
					map((resp:any) => new MediaActions.AddMediaSuccess(new Media(resp))),
					catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
				)
			}
		)
	)

	@Effect()
	moveMedia$ = this.actions$.pipe(
		ofType(MediaActions.MediaActionTypes.MOVE_MEDIA),
		switchMap((action:MediaActions.MoveMedia) => this.dataService.moveMediaAdmin(action.media, action.destinationFolder)
			.pipe(
				map(() => new MediaActions.MoveMediaSuccess()),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
			)
		)
	)


	constructor(private actions$:Actions, private store$:Store<IMediaState>, private dataService:DataService) {}
}

/*filter(([action:MediaActions.EditMediaConfirm, state:IMediaState]) => {
			return new Array<[number, File, File]>([state.select( s => s.OrgId), state.select(s => s.AddMediaFile), state.select(s => s.AddMediaThumbnail)]);
		})
		*/