<div class="container verify-container">
    
  <mat-card >
    <mat-card-header>
      <mat-card-title>Verifying Account...</mat-card-title>
    </mat-card-header>
    <mat-card-content >
      <div class="verify-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </mat-card-content>
  </mat-card>

</div>