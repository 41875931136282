import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from "@angular/core";
import { ActivatedRoute, Router, ParamMap, NavigationEnd, NavigationStart } from "@angular/router";
import { DataService, SessionService, DynamicScriptLoaderService, CheckoutService, TourService } from "acc-services";
import { User, SettingsNavItem, Organization, Profile } from "acc-models";
//import { SalesforceLoginComponent } from '../shared/salesforce-login.component';
import { Store, select } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import { selectErrorMessage, selectSuccessMessage, selectGettingStarted, selectClassicView } from "../../store/selectors/status.selectors";
import { ClearErrorMessage, ClearSuccessMessage, SetCcEntered, SetDaysLeft, SetErrorMessage, ShowClassicView, ShowDefaultView, ShowGettingStarted } from "../../store/actions/status.actions";
import { SubscriptionLike, Observer, Observable, Subject, timer } from "rxjs";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangePasswordComponent } from "../auth/change-password/change-password.component";
import { take, filter, map, mergeMap, tap } from "rxjs/operators";
import { LogInAsComponent } from "./log-in-as/log-in-as.component";
import { LoaderComponent } from "../shared/loader/loader.component";
import { NewQuote } from "../../store/actions/quote.actions";
import { RootReset } from "../../store/actions/root.actions";
import { pageTitles } from "projects/acc-main/src/assets/data/pagetitles";
import { DialogComponent, tooltips } from "acc-common";
import { LockFeaturesComponent } from "../lock-features/lock-features.component";
import * as moment from "moment";
import { PreferencesComponent } from "../preferences/preferences.component";
import { takeUntil } from "rxjs/operators";
import { stepOptions, steps } from "../../../assets/data/tour-steps";

@Component({
	templateUrl: "./template.component.html",
	styleUrls: ["./template.component.scss"],
})
export class TemplateComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild("menuItems") menuItems: ElementRef;

	public user: User;
	public isAdmin: boolean = false;
	public showLogInAs: boolean = false;
	public showSalesforceLogin: boolean = false;
	public navigationItems: Array<SettingsNavItem>;
	public showHelp: boolean = true;
	public showTutorial: boolean = true;
	private loaderDialogRef: MatDialogRef<LoaderComponent>;
	private loaderCount: number = 0;
	public showLoader: boolean = false;
	private loaderIds: Array<{ key: string; observable: SubscriptionLike }> = [];

	private showLoaderSubscription: SubscriptionLike;
	private hideLoderSubscription: SubscriptionLike;
	private setNavSubscription: SubscriptionLike;
	private userSavedSubscription: SubscriptionLike;

	public changePasswordDialogRef: MatDialogRef<ChangePasswordComponent>;

	private salesForceLoginActionSubscription: SubscriptionLike;
	//TODO: Restore Salesforce Login form (should be in acc-common)
	//@ViewChild('salesforceLoginForm') salesforceLoginForm: SalesforceLoginComponent;

	public errorMessage$ = this.store.pipe(select(selectErrorMessage));
	public successMessage$ = this.store.pipe(select(selectSuccessMessage));
	public showGettingStartedDialog$ = this.store.pipe(select(selectGettingStarted));
	public showClassicView$ = this.store.pipe(select(selectClassicView));

	public expandNav: boolean = true;
	public pageTitle: string;

	public openNavs: any = {};
	public loaded: boolean = false;

	public activeProfileID: string;
	public activeProfileName: string;
	public profiles: Profile[];
	private _profiles: Observable<Profile[]>;
	private _activeProfile: Observable<any>;

	public lockedFeaturesDialogRef: MatDialogRef<LockFeaturesComponent>;
	public helpVisible: boolean = false;
	public tooltipMessage: string;
	public showTooltip: boolean = false;
	public tooltips = tooltips;

	public trialEnds = null;
	public subscription = null;
	public selectedClassicView = false;
	public preferencesDialogRef: MatDialogRef<PreferencesComponent>;
	public showPreferences: boolean = true;

	private destroy$: Subject<boolean> = new Subject();

	constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService, public sessionService: SessionService, 
		private store: Store<IAppState>, private dialog: MatDialog, private snackBar: MatSnackBar, private dynamicScriptLoader: DynamicScriptLoaderService, 
		private checkoutService: CheckoutService, private tourService: TourService) {
		this.loaded = false;
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				tap((e) => this.closeAllDialogs()),
				map(() => this.route),
				map((r) => {
					while (r.firstChild) r = r.firstChild;
					return r;
				}),
				mergeMap((r) => r.data)
			)
			.subscribe((e:any) => {
				this.pageTitle = e.pageTitle;
				this.tooltipMessage = e.tooltip;
				this.showTooltip = (e.tooltip != null);
			});
			this.router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
				if(e instanceof NavigationEnd) {
					setTimeout(() => {
						this.tourService.init(stepOptions, steps, location.hash == '#protractor');
						this.tourService.reset();
					}, 500);
				}
			}) 
			this.router.events.pipe(filter(e => e instanceof NavigationStart), take(1)).subscribe(() => {
				this.tourService.close();
				this.tourService.uncancel();
			})
		}

	ngOnInit(): void {
		this.user = this.sessionService.getUser();
		this.isAdmin = this.user.admin;


		this.userSavedSubscription = this.sessionService.onUserSaved.subscribe((u) => {
			this.user = u;
		});

		if (this.sessionService.settings) {
			this.navigationItems = this.sessionService.settings.navItems.filter((l) => !l.permission || this.user.admin); //.map(i => { return { ...i, isOpen: false }});
			this.navigationItems.filter((i) => i.isOpen === true).map((i) => (this.sessionService.navIsOpen[i.name] = true));
			//Added Subscription dynamically
			var org = this.sessionService.getItem<Organization>("organization");
			var requireSubscription = org.requireSubscription;
		
			if (!!requireSubscription) {
				var adminMenu = this.navigationItems.find((m) => m.name == "Admin");
				if (adminMenu != null) {
					var subs = new SettingsNavItem();
					subs.name = "Subscription";
					subs.path = "/admin/subscription";
					subs.permission = "admin";
					adminMenu.children.push(subs);
				}
			}

			//Check MasterOrgID for Quote Alerts
			var adminMenu = this.navigationItems.find((m) => m.name == "Admin");
			if (adminMenu != null) {
				var masterOrgID = this.sessionService.getMasterOrgID();
				if(masterOrgID != null && masterOrgID > 0){
					//Hide Quote Alerts 
					let index = adminMenu.children.findIndex(x => x.name == "Quote Alerts");
					adminMenu.children.splice(index, 1);
					//Hide Proposal Builder
					index = adminMenu.children.findIndex(x => x.name == "Proposal Builder");
					adminMenu.children.splice(index, 1);
				}
			}
			


			this.showHelp = this.sessionService.settings.showHelp;
			this.showTutorial = this.sessionService.settings.showTutorial;
			this.showPreferences = this.sessionService.settings.showPreferences;
		}
		if (this.showHelp) {
			this.dynamicScriptLoader.load("helpdesk");
		}
		this.sessionService.applySettings(); //for the event of a page refresh
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});
		setTimeout(() => (this.loaded = true), 500);

		//GetProfiles
		this._profiles = this.dataService.getUserProfiles();
		this._profiles.subscribe((result: Profile[]) => {
			this.profiles = result;
			result.forEach((p) => {
				if (p.profileID == this.user.activeProfileID) {
					this.dataService.refreshActiveProfileData(p.profileID);
				}
			});
		});
		this.dataService.refreshUsersProfiles(this.user.userID);

		//GetActiveProfileInfo
		this._activeProfile = this.dataService.getActiveProfile();
		this._activeProfile.subscribe((result: any) => {
			this.activeProfileID = result["profileID"];
			var p = this.profiles.find((x) => x.profileID == this.activeProfileID);
			if (!!p) {
				this.activeProfileName = p.profileName;
			}
		});

		//Subscriptions
		this.checkoutService.getSubscriptions(org.orgID).subscribe(
			(resp: any) => {
				if (resp.subscription != null && resp.subscription.id != null) {
					this.subscription = resp.subscription;
					var default_source = this.subscription.customer.default_source;
					if(this.subscription.status == 'trialing' && default_source == null){
						var today = moment().startOf('day');
						var m = moment.unix(this.subscription.current_period_end).startOf('day');
						this.trialEnds = Math.round(moment.duration(m.diff(today)).asDays());
						this.store.dispatch(new SetDaysLeft(this.trialEnds));
					} 
					if(default_source == null) {
						this.store.dispatch(new SetCcEntered(false));
					} else {
						this.store.dispatch(new SetCcEntered(true));
					}
				}
			},
			(err: any) => {
				if (err.error) {
					this.snackBar.open(err.error.Message, "Close", { duration: 5000 });
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access to checkout service"));
				}
			}
		);`
		`

		if(this.user.useClassicView){
			this.setClassicView();
			this.selectedClassicView = true;
		}

		this.setNavSubscription = this.sessionService.setNavOpen$.subscribe((open) => (this.expandNav = open));

		this.showClassicView$.subscribe((result)=> {
			this.selectedClassicView = result;
		});
	}

	ngOnDestroy(): void {
		this.showLoaderSubscription.unsubscribe();
		this.hideLoderSubscription.unsubscribe();
		if (this.setNavSubscription) {
			this.setNavSubscription.unsubscribe();
		}
		if (this.userSavedSubscription) {
			this.userSavedSubscription.unsubscribe();
		}
		this.destroy$.next(true);
		this.destroy$.complete();
	}
	ngAfterViewInit(): void {
		this.showLoaderSubscription = this.sessionService.onShowLoader.subscribe((id) => {
			if (this.loaderIds.filter((l) => l.key == id).length > 0) {
				//if hide has already fired...
				return;
			}
			let obs = timer(500);
			this.loaderIds.push({
				key: id,
				observable: obs.subscribe((val) => {
					this.showLoader = true;
				}),
			});
		});
		this.hideLoderSubscription = this.sessionService.onHideLoader.subscribe((id) => {
			let ldr = this.loaderIds.filter((o) => o.key == id);
			if (ldr.length > 0) {
				if (ldr[0].observable) {
					ldr[0].observable.unsubscribe();
				}
				ldr[0].observable = null;
			} else {
				this.loaderIds.push({ key: id, observable: null });
			}

			if (this.loaderIds.filter((x) => x.observable != null).length == 0) {
				this.showLoader = false;
			}
		});
	}


	changePassword(): void {
		this.changePasswordDialogRef = this.dialog.open(ChangePasswordComponent);
		this.changePasswordDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((resp) => {
				if (resp) {
					this.snackBar.open("Your password has been updated", "OK", { duration: 3000 });
				}
			});
	}
	doAction(action: string): void {
		if (typeof this[action] === "function") {
			this[action]();
		}
	}
	showLogInAsModal() {
		this.dialog.open(LogInAsComponent);
	}

	clearError() {
		this.store.dispatch(new ClearErrorMessage());
	}
	clearSuccess() {
		this.store.dispatch(new ClearSuccessMessage());
	}

	startNewQuote() {
		if(this.router.url.includes("/quote") && !this.router.url.includes("/quote/")){
			this.store.dispatch(new NewQuote());
		}
		this.router.navigateByUrl("/quote");
	}

	logout() {
		this.store.dispatch(new RootReset());
		this.router.navigateByUrl("/logout");
	}

	showWelcomeMessage() {
		let org = this.sessionService.getItem<Organization>("organization");
		this.dialog.open(DialogComponent, {
			data: {
				messages: [org.whatsNew, this.user.shareList].filter((m) => !!m).map((m) => `<pre>${m}</pre>`), //filter out nulls
				title: org.whatsNewTitle,
			},
			panelClass: "welcome-dialog-container",
		});
	}

	toggleMenu() {
		this.expandNav = !this.expandNav;
		this.navigationItems.forEach((i) => (i.isOpen = false));
	}

	closeAllDialogs() {
		this.dialog.closeAll();
	}

	selectProfile(profileId, profileName) {
		var currentToken = this.user.token;
		this.dataService.setActiveProfile(this.user.userID, profileId).subscribe((result: any) => {
			this.activeProfileID = profileId;
			this.activeProfileName = profileName;
			this.dataService.getUser(this.user.userID).subscribe((resp) => {
				var u = new User(resp);
				u.token = currentToken;
				this.sessionService.setUser(u);
			});

			let snackBarRef = this.snackBar.open("Active profile changed!", "Close", {
				duration: 3000,
			});
		});
	}

	validateSubscription(link) {
		var actionName = link.path ? link.path : link.action;

		if (this.sessionService.canUseSubscriptionLevelFeature(actionName)) {
			if (!!link.path) {
				this.router.navigateByUrl(link.path);
			} else {
				this.doAction(link.action);
			}
		} else {
			this.lockedFeaturesDialogRef = this.dialog.open(LockFeaturesComponent, {
				panelClass: "lock-features",
			});
		}
	}

	gettingStarted() {
		this.store.dispatch(new ShowGettingStarted());
	}

	upgradeToPro() {
		//this.lockedFeaturesDialogRef = this.dialog.open(LockFeaturesComponent);
		this.router.navigate(["/admin/subscription"]);
	}

	videoTutorials(){
		window.open("http://support.consumeroptix.com/support/solutions/67000016952", "_blank");
	}

	goToProfile(){
		this.router.navigate(['/profile/edit']);
	}

	openPreferencesMenu(){

		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			selectClassicView: (!this.selectedClassicView)?0:1,
			colorPalette: 1
		};

		this.preferencesDialogRef = this.dialog.open(PreferencesComponent, dialogConfig);
		this.preferencesDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((resp) => {
				if (resp) {
					this.selectView((resp.selectClassicView=="1")?true:false);
					this.snackBar.open("Your preferences have been updated!", "OK", { duration: 3000 });
				}
			});
	}

	selectView(showClassicView:boolean){
		if(!!showClassicView){
			this.setClassicView();
			//Go to new Quote Screen
			this.router.navigateByUrl("/quote");
		}else{
			this.setDefaultView();
		}
	}

	setClassicView(){
		this.store.dispatch(new ShowClassicView());
		var help = document.getElementById('freshworks-container');
		if(!!help){
			help.style.display = 'none';
		}
		this.sessionService.closeNav();
		this.expandNav = false;
		this.dataService.setViewType(this.user.userID, true).subscribe(() => {
			//Update User
			var u = this.user;
			u.useClassicView = true;
			this.sessionService.setUser(u);
		});
		this.navigationItems.forEach((i) => (i.isOpen = false));
	}

	setDefaultView(){
		this.store.dispatch(new ShowDefaultView());
		document.getElementById('freshworks-container').style.display = 'block';
		this.sessionService.openNav();
		this.expandNav = true;
		this.dataService.setViewType(this.user.userID, false).subscribe(() => {
			//Update User
			var u = this.user;
			u.useClassicView = false;
			this.sessionService.setUser(u);
		});
		this.navigationItems.forEach((i) => (i.isOpen = false));
	}

}
