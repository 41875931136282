import { Category, SubCategory, Text, Module } from 'acc-models';

export interface ICategoriesState {
	Modules: Array<Module>,
	Categories: Array<Category>,
	Subcategories: Array<SubCategory>,
	Texts: Array<Text>,
	ActiveCategory: Category,
	ActiveSubcategory: SubCategory,
	ActiveText: Text,
	EditingCategory: Category,
	EditingSubcategory: SubCategory,
	EditingText: Text,
	CategoryToDelete: Category,
	SubcategoryToDelete: SubCategory,
	TextToDelete: Text
}

export const initialCategoriesState:ICategoriesState = {
	Modules: null,
	Categories: null,
	Subcategories: null,
	Texts: null,
	ActiveCategory: null,
	ActiveSubcategory: null,
	ActiveText: null,
	EditingCategory: null,
	EditingSubcategory:null,
	EditingText: null,
	CategoryToDelete: null,
	SubcategoryToDelete: null,
	TextToDelete: null
}