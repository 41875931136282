<div class="card-content">
  <div class="plan-section">

    <!--<div class="selected-plan-div signup-blocks-section">-->
    <div class="selected-plan-div">
      <div class="upper-left-blocks-section"></div>

      <h1 class="plan-title"><span class="plan-title-select">Select</span> your plan</h1>

      <div class="plan-button-container">
        <div class="button-left">
          <button mat-raised-button class="signup-button-free" color="primary" (click)="freePlan()" ><div class="button-content" ><span>Basic</span><br><span class="button-sub-text">Free</span></div></button>
          <div class="button-sub-text">Same Life Quoting as before</div>
        </div>
        <div class="button-right">
          <button mat-raised-button class="signup-button-paid" color="primary" (click)="paidPlan()" ><div class="button-content"><span>Professional</span><br><span class="button-sub-text">$25/mo per seat</span></div></button>
          <div class="button-sub-text">Free Trial<br>Downgrade to free version anytime</div>
        </div>
      </div>

    </div>

  </div>
  <div class="table-section">
    <div class="logo">
    </div>
    <div class="table-title">Compare Subscriptions and Top Features</div>
    <div>
      <div class="divTable featuresTable">
        <div class="divTableHeading">
          <div class="divTableRow">
            <div class="divTableHead signup-features-text">Features</div>
            <div class="divTableHead signup-padding-bottom">Basic</div>
            <div class="divTableHead">Professional<br><span class="table-trial-text">30-day no-risk trial, $25/mo. thereafter</span></div>
          </div>
        </div>
        <div class="divTableBody">
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Run Term Quotes <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_term_quotes}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange">  <i class="fas fa-check-circle"></i>  </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">My Quotes <mat-icon class="q-info-icon"  [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_my_quotes}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="fas fa-check-circle"></i>  </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Personalize Quote and Send<mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_personalize_send}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i>  </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Asset Library Access <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_assets_library}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i> </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Proposal Build & Sender <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_proposal_send}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i> </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Branded Quote Video <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_branded_quote}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i> </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Activity & Customer Engagement Dashboard <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_dashboard}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i> </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Analytics & Reporting <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_analytics}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i> </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Personalized Email/Social Media Marketing <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_personalized_videos}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i> </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Client Engagement Tracking <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_engagement}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i> </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
            <div class="divTableCell feature-left-justify">Self Service Quoting <mat-icon class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position_right" matTooltip="{{tooltips.signup_self_service}}" >info_outline</mat-icon></div><div class="divTableCell tableCellOrange"><i class="far fa-circle"></i> </div><div class="divTableCell tableCellOrange"> <i class="fas fa-check-circle"></i> </div></div>
          <div class="divTableRow">
              <div class="divTableCell feature-left-justify"></div>
              <div class="divTableCell tableCellOrange">
                <button mat-raised-button class="signup-button-free" color="primary" (click)="freePlan()" >
                  <div class="button-content" ><span>Get Basic</span></div>
                </button>
              </div>
              <div class="divTableCell tableCellOrange">
                <button mat-raised-button class="signup-button-paid" color="primary" (click)="paidPlan()" >
                  <div class="button-content"><span> Get Pro</span></div>
                </button>
              </div>
          </div>
        </div>
    </div>
  </div>
</div>