
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { tap, finalize } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private router:Router, private sessionService:SessionService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	
		const token = this.sessionService.authToken || '';
		const authReq = request.clone({ setHeaders: { token: token } });
		//const authReq = request.clone({ setHeaders: { token: token, 'Content-type': 'application/json' } });

		let showLoader = (!!authReq.headers)?!authReq.headers.keys().includes('noloader'):false;
		const guid = this.createGuid();
		if(showLoader) {
			this.sessionService.showLoader(guid);
		}
		
		return next.handle(authReq).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					// do stuff with response if you want
					if(showLoader) {
						this.sessionService.hideLoader(guid);
					}
				}
			}, (err: any) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status === 403) {
						this.router.navigate(['/', 'logout']);
					}
				}
			}
		),
		finalize(() => {
			if(showLoader) {
				this.sessionService.hideLoader(guid);
			}
		}));
	}

	createGuid() {
		var dt = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = (dt + Math.random()*16)%16 | 0;
			dt = Math.floor(dt/16);
			return (c=='x' ? r :(r&0x3|0x8)).toString(16);
		});
		return uuid;
	}
}