import { Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges, HostListener, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { CrmContact } from 'acc-models';
import { MatFormField } from '@angular/material/form-field';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'acc-common-email-selector',
  templateUrl: './email-selector.component.html',
  styleUrls: ['./email-selector.component.scss']
})
export class EmailSelectorComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
	@Input() label:string = '';
	@Input() isRequired: boolean = false;
	@Input() contacts: Array<CrmContact> = new Array<CrmContact>();
	@Input() contactOptions:Array<CrmContact> = new Array<CrmContact>();
	@Output() onListUpdated: EventEmitter<Array<CrmContact>> = new EventEmitter();
	@ViewChild('formField') formField: MatFormField;
	@ViewChild('input', { static: true }) input:ElementRef;
	@ViewChild('contactOptionList', { static: true }) contactOptionList:ElementRef;
	@HostListener('document:click', ['$event']) onbodyclick($event) {
		if($event.target != this.input.nativeElement && $event.target != this.contactOptionList.nativeElement) {
			//clicked outside this item
			this.contactOptionsToShow.length = 0;
		}
	}

	public emailAddressForm: FormGroup;
	public emailField: FormControl = new FormControl('');
	public filter:string = '';
	public contactOptionsToShow:Array<CrmContact> = new Array<CrmContact>();
	public invalidEmails: Array<string> = new Array<string>();

	public currentHighlight:number = 0;
	constructor(private fb: FormBuilder) {}
	
	ngOnInit():void {
		if(this.isRequired) {
			this.emailField.setValidators([(c: FormControl) => {
				if(this.contacts.length == 0) { return { noEmail: true }} else return null;
			}])
		}
		this.emailAddressForm = this.fb.group({
			'emailAddress': this.emailField
		})
	}
	ngOnDestroy():void {
	}
	ngOnChanges(changes:SimpleChanges) {
		if(changes.emailAddresses) {
			this.contacts = changes.emailAddresses.currentValue;
		}
		if(changes.emailOptions) {
			this.contactOptions = changes.emailOptions.currentValue;
			this.contactOptionsToShow = this.contactOptions.slice() || [];
		}
		if(changes.label) {
			this.label = changes.label.currentValue;
		}
	}

	ngAfterViewInit():void {
		this.input.nativeElement.addEventListener('blur', () => { 
			if(this.filter.length > 0){
				if(this.isValidEmail(this.filter)) {
					this.contacts.push(new CrmContact({Email: this.filter.replace(',', '').replace(';', ''), CrmContactId: ''}));
					this.filter = '';
				} else {
					this.invalidEmails.push(this.filter);
					this.filter ='';
				}

			}
		} );
		this.contactOptionList.nativeElement.addEventListener('click', () => { this.input.nativeElement.focus(); });
	}

	public getContacts():Array<CrmContact> {
		return this.contacts;
	}
	addContact(contact) {
		if(contact) {
			this.contacts.push({CrmContactId: contact.ID, ...contact});
			this.filter = '';
			this.contactOptionsToShow.length = 0;
			this.onListUpdated.emit(this.contacts);
			this.input.nativeElement.blur();
		}
	}
	removeContact(email:string) {
		this.contacts = this.contacts.filter(e => e.Email != email);
		this.onListUpdated.emit(this.contacts);
	}
	removeInvalid(email:string):void {
		this.invalidEmails = this.invalidEmails.filter(e => e != email);
		this.onListUpdated.emit(this.contacts);
	}
	focusInput(e) {
		const input = e.target.getElementsByTagName('input')[0];
		if(input) {
			input.focus();
		}
	}
	filterOptions(e = null) {
		if(e) {
			switch(e.key) {
				case ',':
				case ';':
				case ' ':
				case 'Tab':
					//if what they entered is a valid email, use it, otherwise mark it invalid
					if(this.filter == '') return;
					if(this.isValidEmail(this.filter)) {
						this.contacts.push(new CrmContact({Email: this.filter.replace(',', '').replace(';', ''), crmcontactid : ''}));
						this.onListUpdated.emit(this.contacts);
						this.filter = '';
					} else {
						this.invalidEmails.push(this.filter);
						this.filter ='';
					}
					break;
				case 'ArrowDown': 
					this.currentHighlight = ++this.currentHighlight % this.contactOptionsToShow.length;
					break;
				case 'ArrowUp':
					this.currentHighlight = this.currentHighlight == 0 ? this.contactOptionsToShow.length-1 : --this.currentHighlight % this.contactOptionsToShow.length
					break;
				case 'Enter':
					this.addContact(this.contactOptionsToShow[this.currentHighlight]);
					break;
				case 'Escape':
					this.contactOptionsToShow.length = 0;
					return;
			}

			
		}
		if(!this.contactOptions) { return; }
		if(this.filter == '') {
			this.contactOptionsToShow = this.contactOptions.slice() || []; //show all on focus
		} else {
			this.contactOptionsToShow = this.contactOptions.filter(o => (o.Name && o.Name.toLowerCase().includes(this.filter.toLowerCase())) || (o.Email || '').toLowerCase().includes(this.filter.toLowerCase()))
		}
	}

	checkTab(e) {
		//not using tab to select here, because no way to determine if intention was to select highlighted option, or move to next field
		if(e && e.key == 'Tab') {
			this.contactOptionsToShow.length = 0;
			if(this.filter != '') { e.preventDefault(); }
			return; 

			if(this.contactOptionsToShow.length > 0) { 
				if(e.preventDefault) { e.preventDefault(); }
				this.addContact(this.contactOptionsToShow[this.currentHighlight]);
				this.contactOptionsToShow.length = 0;
			}
		}
	}

	private isValidEmail(email:string):boolean {
		const EMAIL_REGEXP = /[a-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})/;
		return EMAIL_REGEXP.test(email);
	}
 }
