import { IStatusState, initialStatusState } from "../state/status.state";
import { StatusActions, StatusActionTypes } from "../actions/status.actions";

export function statusReducer(state: IStatusState = initialStatusState, action: StatusActions): IStatusState {
	switch (action.type) {
		case StatusActionTypes.SET_ERROR_MESSAGE:
			return { ...state, errorMessage: action.errorMessage };

		case StatusActionTypes.CLEAR_ERROR_MESSAGE:
			return { ...state, errorMessage: "" };

		case StatusActionTypes.SET_SUCCESS_MESSAGE:
			return { ...state, successMessage: action.successMessage };

		case StatusActionTypes.CLEAR_SUCCESS_MESSAGE:
			return { ...state, successMessage: "" };

		case StatusActionTypes.RESET:
			return initialStatusState;

		case StatusActionTypes.SHOW_GETTING_STARTED:
			return { ...state, gettingStartedDialog: true };

		case StatusActionTypes.HIDE_GETTING_STARTED:
			return { ...state, gettingStartedDialog: false };

		case StatusActionTypes.SHOW_TRIAL_FEATURE:
			return { ...state, trialFeature: !state.gettingStartedDialog }; //only show trial feature if not also showing getting started dialog
		
		case StatusActionTypes.HIDE_TRIAL_FEATURE:
			return { ...state, trialFeature: false };

		case StatusActionTypes.SET_DAYS_LEFT:
			return { ...state, daysLeft: action.daysLeft}

		case StatusActionTypes.SET_CC_ENTERED:
			return { ...state, ccEntered: action.ccEntered };

		case StatusActionTypes.SHOW_CLASSIC_VIEW:
			return { ...state, showClassicView: true };

		case StatusActionTypes.SHOW_DEFAULT_VIEW:
			return { ...state, showClassicView: false };

		default:
			return state;
	}
}
