import { Component, Output, Input, EventEmitter, OnDestroy, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'acc-common-save-cart',
	templateUrl: './save-cart.component.html',
	styleUrls: ['./save-cart.component.scss']
})
export class SaveCartComponent implements OnInit {
	@Output() closeForm:EventEmitter<number> = new EventEmitter();

	public cartNameField = new FormControl('', [Validators.required]);
	public form: FormGroup;

	constructor(public dialogRef: MatDialogRef<SaveCartComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {}
	
	saveCart() {
		if(this.form.valid) {
			this.dialogRef.close(this.cartNameField.value);
		}
	}
	close():void {
		this.dialogRef.close();
	}

	ngOnInit():void {
		this.form = this.fb.group({name: this.cartNameField});
	}
}
