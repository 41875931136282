<div class="container">
	<ng-container *ngIf="!unsubscribeComplete">
		<p>We're sorry to see you go.</p>
		<p>Click the &quot;Unsubscribe&quot; button below to unsubscribe {{email}}</p>		
		<button class="btn btn-primary" (click)="unsubscribe()">Unsubscribe</button>
	</ng-container>
	<ng-container *ngIf="unsubscribeComplete">
		<p>You have been unsubscribed from all emails.</p>
	</ng-container>
</div>
