<div class="seats-container">

	<div class="seats-content">
		<h1>Organization does not have enought seats.</h1>
		<div *ngIf="!this.subscriptionLoading">
			<h1 class="plan-title">Seats</h1>
            <mat-form-field appearance="outline" class="qty-field" >
              <input matInput type="number"  min="{{usersMinValue}}" [(ngModel)]="selectedSeats" [ngModelOptions]="{standalone: true}" (change)="validateMinSeats($event)" />
            </mat-form-field>
            <span class="subscription-charged">You will be charged {{ ((this.selectedSeats * this.subscriptionAmount)/100) | currency}} monthly.</span>
            <div class="subscription-charged">
			  You must have at least {{usersMinValue}} total seats to add this new user. Click Update Subscription to modify your total number of seats, then click Save to add the new user.
            </div>
		</div>
		<div class="close" (click)="close()"><mat-icon>highlight_off</mat-icon></div>
		<div *ngIf="!!this.subscriptionLoading" class="subscription-spinner">
			<mat-spinner></mat-spinner>
		</div>
		<div class="subscription-buttons">
			<mat-progress-bar class="subscription-progress" *ngIf="!!this.loading" mode="indeterminate"></mat-progress-bar>
			<button mat-raised-button class="company-button" color="primary" *ngIf="!this.subscriptionLoading && !!this.subscription && !this.canceledAt"  (click)="submitForm()" [disabled]="!!this.loading" >Update Subscription</button>
			<button mat-raised-button class="company-button" color="primary" *ngIf="!this.subscriptionLoading && !!this.subscription && !!this.canceledAt" (click)="renewSubscription()" [disabled]="!!this.loading" >Renew Subscription</button>
		</div>
	</div>
	<div class="bottom-branding">
		<div class="branding-neg-slant"></div>
		<div class="branding-pos-slant"></div>
	</div>
</div>