
<div class="activity-container">
	<div class="outbox">
		<div class="top">
			<div class="search-field">
				<mat-form-field>
					<mat-label>Search</mat-label>
					<input matInput [(ngModel)]="searchTerm" (keyup)="groupData()">
				</mat-form-field>
			</div>
			<div class="select-dates">
				<mat-form-field>
					<mat-label>Start Date</mat-label>
					<input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate">
					<mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
					<mat-datepicker #startPicker></mat-datepicker>
				</mat-form-field>
				<mat-form-field>
					<mat-label>End Date</mat-label>
					<input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate">
					<mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
					<mat-datepicker #endPicker></mat-datepicker>
				</mat-form-field>
				<button mat-raised-button color="primary" (click)="getActivity();">Apply</button>
			</div>
		</div>
		<ng-container *ngIf="timePeriods">
			<div class="activity" *ngFor="let period of timePeriodHeaders">
				<h2>{{period}}</h2>
				<ul>
					<li *ngFor="let msg of timePeriods.get(period).keys()">
						<h4 (click)="navigateTo(timePeriods.get(period).get(msg)[0].link)"><mat-icon>email</mat-icon><span class="subject" [innerHTML]="timePeriods.get(period).get(msg)[0].emailSubject | highlight: searchTerm"></span> sent to <span class="recipeint" [innerHTML]="timePeriods.get(period).get(msg)[0].toEmail | highlight: searchTerm"></span></h4>
						<div class="items">
							<div class="activity-item" *ngFor="let item of timePeriods.get(period).get(msg), let i = index" [ngSwitch]="item.eventDesc">
								<div class="time">{{getTime(item.localTime)}}</div>
								<ng-container *ngSwitchCase="'Attachment'"><a target="_blank" [href]="item.url">Attachment</a>&nbsp;opened</ng-container>
								<ng-container *ngSwitchDefault><span [innerHTML]="item.eventDesc.toUpperCase() | highlight: searchTerm"></span></ng-container>
							</div>
							
						</div>
					</li>
				</ul>
			</div>
		</ng-container>
	</div>
</div>