import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { User } from 'acc-models';
import * as mixpanel from 'mixpanel-browser';
import { environment } from "../../../../acc-main/src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  public static readonly EVENT_PAGE_VIEW:string = "Page View";
  public static readonly EVENT_SIGNUP:string = "SignUp";
  public static readonly EVENT_SIGNUP_CONVERSION:string = "SignUp-Conversion";
  public static readonly EVENT_NEW_LIFE_QUOTE:string = "New-Life-Quote";
  public static readonly EVENT_NEW_PERSONALIZED_VIDEO:string = "New-Personalized-Video";
  public static readonly EVENT_PERSONALIZE_AND_SEND_EMAIL:string = "New-Personalized-And-Send-Email";
  public static readonly EVENT_ASSET_FILE_ADDED:string = "Asset-File-Added";
  public static readonly EVENT_DTC_ENABLED:string = "DTC-Enabled";
  public static readonly EVENT_DTC_QUOTE_RUN:string = "DTC-Quote-Run";

  
  public subscribers: any = {};
  public enabled:boolean = true;
	private API_ROOT = environment.api_url;
	constructor(private http: HttpClient) {
	}

  disable(){
    this.enabled = false;
  }

  enable(){
    this.enabled = true;
  }


  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(user: User = null): void {
    if(environment.mixpanel_api_token != null){

      if(environment.mixpanel_api_proxy != null){
        mixpanel.init(environment.mixpanel_api_token, {api_host: environment.mixpanel_api_proxy});
      }else{
        mixpanel.init(environment.mixpanel_api_token);
      }

      if(user != null){
          mixpanel.identify(user.userID);
          mixpanel.people.set("$email", user.email);
          mixpanel.people.set("$name", user.firstName + " " + user.lastName);
          mixpanel.people.set("Organization ID", user.orgID);
      }else{
        var identifier = Date.now();
        mixpanel.identify(identifier.toString());
      }
    }
  }

  /**
   * Init for Signup
   */
  initSignup(){
    this.init();
    this.trackSignup();
  }

  /**
   * Init for Login
   */
  initAnonymousMixpanel(){
    this.init();
    this.trackPageView();
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {

    if(this.enabled){
      try {
        var mixpanel_distinct_id = mixpanel.get_distinct_id();
  
        if(environment.mixpanel_api_token != null){
          mixpanel.track(id, action);
        }
  
      } catch(e) {
        this.init();
        if(environment.mixpanel_api_token != null){
          mixpanel.track(id, action);
        }
      }
    }
  }

 /**
  * Track a Page View into MixPanel
  * @param action 
  */
  trackPageView(action: any={}){
    this.track(MixpanelService.EVENT_PAGE_VIEW, action);
  }

  /**
   * Track a Signup Page View into MixPanel
   * @param action 
   */
  trackSignup(action: any={}){
    this.track(MixpanelService.EVENT_PAGE_VIEW, action);
    this.track(MixpanelService.EVENT_SIGNUP, action);

    //Mixpanel Server Side
    this.trackServerSignup().subscribe();
  }

  /**
   * Track Signup on Server
   * @returns 
   */
  trackServerSignup() {
		return this.http.get(`${this.API_ROOT}mixpanel/signup`);
	}

    /**
   * Track New LifeQuote on Server
   * @returns 
   */
     trackServerNewLifeQuote() {
      return this.http.get(`${this.API_ROOT}mixpanel/newquote`);
    }

    /**
   * Track a Welcome Page View into MixPanel
   * @param action 
   */
  trackSignupConversion(action: any={}){
    this.track(MixpanelService.EVENT_SIGNUP_CONVERSION, action);
  }

/**
 * Track new Life Quote.
 * @param action 
 */
  trackNewLifeQuote(action: any={}){
    this.track(MixpanelService.EVENT_NEW_LIFE_QUOTE, action);

    //Mixpanel Server Side
    this.trackServerNewLifeQuote().subscribe();
  }

  /**
   * Track New Personalized Video
   * @param action 
   */
  trackNewPersonalizedVideo(action: any={}){
    this.track(MixpanelService.EVENT_NEW_PERSONALIZED_VIDEO, action);
  }

  /**
   * Track Personalize and Send
   * @param action 
   */
  trackPersonalizeAndSendEmail(action: any={}){
    this.track(MixpanelService.EVENT_PERSONALIZE_AND_SEND_EMAIL, action);
  }

  /**
   * Track Asset File Added
   * @param action 
   */
  trackAssetFileAdded(action: any={}){
    this.track(MixpanelService.EVENT_ASSET_FILE_ADDED, action);
  }

  /**
   * Track Enable DTC
   * @param action 
   */
  trackEnableDTC(action: any={}){
    this.track(MixpanelService.EVENT_DTC_ENABLED, action);
  }

  /**
   * Track DTC quote run
   * @param action 
   */
  trackDTCQuoteRun(action: any={}){
    this.init();
    this.track(MixpanelService.EVENT_DTC_QUOTE_RUN, action);
  }

  /*
  * Mixpanel Subscription
  */
  initMixpanelTracker(router){
		//Set up Page View Traking,
    this.subscribers.mixpanel = router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) { 
				this.trackPageView();
			}
		});
	}

  /**
   * Unsubscribe from Mixpanel Tracking
   */
  unsubscribeTrack(){
	  if(this.subscribers && this.subscribers.mixpanel) {
		  this.subscribers.mixpanel.unsubscribe();
    }
  }


  
}