import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from 'acc-services';
import { Module, Category, SubCategory, Button, Text } from 'acc-models';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { GetModules, CategoryActionTypes, CategoriesActions, GetModulesSuccess, GetCategories, EditCategory, EditCategorySave, DeleteCategory, DeleteCategoryConfirm, DeleteCategoryCancel, SaveCategories, ActivateCategory, DeleteSubcategoryConfirm, DeleteSubcategoryCancel, DeleteSubcategory, EditSubcategory, EditSubcategorySave, ActivateSubcategory, SaveSubcategories, ActivateSubcatText, EditSubcatText, EditSubcatTextConfirm, DeleteSubcatText, DeleteSubcatTextConfirm, DeleteSubcatTextCancel, UpdateSubcatTexts, AddCategory, AddSubcategory, AddSubcatText } from '../../store/actions/categories.actions';
import { Subject } from 'rxjs';
import * as CategorySelectors from '../../store/selectors/categories.selectors';
import { tap, map, take } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Actions } from '@ngrx/effects';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmComponent, tooltips } from 'acc-common';
import { DatePipe } from '@angular/common';
import { RootReset } from '../../store/actions/root.actions';

@Component({
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {

	public modules: Array<Module>;
	public activeModuleId: number;
	public categories: Array<Category>;
	public editingCategory: Category;
	public categoryToDelete: Category;
	public activeCategory: Category;
	public newCategoryName: string;
	public subcategories: Array<SubCategory>;
	public editingSubcategory:SubCategory;
	public subcategoryToDelete: SubCategory;
	public activeSubcategory: SubCategory;
	public newSubcategoryName: string;
	public texts: Array<Text>;
	public activeText:Text;
	public editingText: Text;
	public textToDelete: Text;
	public newTextCopy: string;
	private newRevisionDate: Date;

	private confirmDialogRef: MatDialogRef<ConfirmComponent>;

	public confirmDeleteCategoryMessage: string = '';
	public confirmDeleteSubcategoryMessage: string = '';
	public confirmDeleteTextMessage: string = '';
	private confirmDeleteButtons: Array<Button> = new Array<Button>();

	private destroy$:Subject<number> = new Subject();

	public tooltips = tooltips;

	public modules$ = this.store.pipe(
		select(CategorySelectors.selectModules),
		tap(m => this.modules = m)
	)
	public categories$ = this.store.pipe(
		select(CategorySelectors.selectCategories),
		tap(c => this.categories = c)
	)
	public subcategories$ = this.store.pipe(
		select(CategorySelectors.selectSubcategories),
		tap(s => this.subcategories = s)
	)
	public texts$ = this.store.pipe(
		select(CategorySelectors.selectSubcatTexts),
		tap(t => {
			this.texts = t ? t.map(t => new Text(t)) : [];
		})
	)
	public editingCategory$ = this.store.pipe(
		select(CategorySelectors.selectEditingCategory),
		tap(c => this.editingCategory = c)
	).subscribe()
	public editingSubcategory$ = this.store.pipe(
		select(CategorySelectors.selectEditingSubcategory),
		tap(s => this.editingSubcategory = s)
	).subscribe()
	public editingText$ = this.store.pipe(
		select(CategorySelectors.selectEditingSubcatText),
		tap(t => this.editingText = t)
	).subscribe()
	public categoryToDelete$ = this.store.pipe(
		select(CategorySelectors.selectCategoryToDelete)
	).subscribe()
	public subcategoryToDelete$ = this.store.pipe(
		select(CategorySelectors.selectSubcategoryToDelete)
	).subscribe()
	public textToDelete$ = this.store.pipe(
		select(CategorySelectors.selectSubcatTextToDelete)
	).subscribe()
	public activeCategory$ = this.store.pipe(
		select(CategorySelectors.selectActiveCategory),
		tap(c => this.activeCategory = c)
	).subscribe()
	public activeSubcategory$ = this.store.pipe(
		select(CategorySelectors.selectActiveSubcategory),
		tap(c => this.activeSubcategory = c)
	).subscribe()
	public activeText$ = this.store.pipe(
		select(CategorySelectors.selectActiveSubcatText),
		tap(t => this.activeText = t)
	).subscribe()
	
	constructor(private store:Store<IAppState> ,private sessionService:SessionService, private actions$:Actions, private dialog: MatDialog) {
		this.confirmDeleteButtons.push(new Button("Yes", ""));
		this.confirmDeleteButtons.push(new Button("No", ""));

		actions$.pipe(
			map((a) => {
				switch(a.type) {
					case CategoryActionTypes.ADD_CATEGORY_SUCCESS:
						this.newCategoryName = '';
						break;
					case CategoryActionTypes.ADD_SUBCATEGORY_SUCCESS:
						this.newSubcategoryName = '';
						break;
					case CategoryActionTypes.ADD_SUBCAT_TEXT_SUCCESS:
						this.newTextCopy = '';
						this.newRevisionDate = null;
						break;
				}
			})
	
		)
		.subscribe();

	}

    ngOnInit():void {
		const user = this.sessionService.getUser();
		this.store.dispatch(new RootReset());
		this.store.dispatch(new GetModules(user.orgID));
		this.activeModuleId = 0;
	}

	ngOnDestroy():void {
		this.destroy$.next(0);
		this.destroy$.complete();
	}

	moduleSelected() {
		this.store.dispatch(new GetCategories(this.activeModuleId));
	}

	selectCategoryToEdit(cat) {
		this.store.dispatch(new EditCategory(cat));
	}
	saveCategoryEdit(cat) {
		this.store.dispatch(new EditCategorySave(cat));
	}
	deleteCategory(cat) {
		this.store.dispatch(new DeleteCategory(cat));
		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `<p>Are you sure you want to delete the category <b>${cat.categoryDesc}?`
			}
		});
		this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new DeleteCategoryConfirm());
			} else {
				this.store.dispatch(new DeleteCategoryCancel());
			}
		})
	}

	addCategory() {
		if(this.newCategoryName) {
			this.store.dispatch(new AddCategory(this.activeModuleId, new Category({ categoryDesc: this.newCategoryName, SubCategories: new Array<SubCategory>() })))
		}
	}
	selectSubcategoryToEdit(subcat) {
		this.store.dispatch(new EditSubcategory(subcat));
	}
	saveSubcategoryEdit(subcat) {
		this.store.dispatch(new EditSubcategorySave(subcat));
	}
	deleteSubcategory(subcat) {
		this.store.dispatch(new DeleteSubcategory(subcat));
		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `<p>Are you sure you want to delete the subcategory <b>${subcat.subCategoryDesc}?`
			}
		});
		this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new DeleteSubcategoryConfirm());
			} else {
				this.store.dispatch(new DeleteSubcategoryCancel());
			}
		})
	}
	addSubcategory() {
		if(this.newSubcategoryName) {
			this.store.dispatch(new AddSubcategory(new SubCategory({ subCategoryDesc: this.newSubcategoryName, categoryID: this.activeCategory.categoryID })))
		}
	}
	activateCategory(cat:Category) {
		this.store.dispatch(new ActivateCategory(cat));
	}
	activateSubcategory(sub:SubCategory) {
		this.store.dispatch(new ActivateSubcategory(sub));
	}
	/*
	activateText(text:Text) {
		this.store.dispatch(new ActivateSubcatText(text));
	}*/
	selectTextToEdit(text) {
		this.store.dispatch(new EditSubcatText(text));
	}
	saveTextEdit(text) {
		this.store.dispatch(new EditSubcatTextConfirm(text));
	}
	deleteText(text) {
		this.store.dispatch(new DeleteSubcatText(text));
		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `<p>Are you sure you want to delete this text?`
			}
		});
		this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new DeleteSubcatTextConfirm());
			} else {
				this.store.dispatch(new DeleteSubcatTextCancel());
			}
		})
	}
	addSubcatText() {
		if(this.newTextCopy) {
			this.store.dispatch(new AddSubcatText(new Text({ defaultText: this.newTextCopy, subCategoryID: this.activeSubcategory.subCategoryID, revisionNeededDate: new DatePipe('en-us').transform(this.newRevisionDate, 'short') })))
		}
	}

	dropCategory(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
		const newOrder = this.categories.map((c,i) => { return { categoryID: c.categoryID, categoryDesc: c.categoryDesc, sortOrder:  i + 1 }; });
		this.store.dispatch(new SaveCategories(newOrder));
	}
	dropSubcategory(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.subcategories, event.previousIndex, event.currentIndex);
		const newOrder = this.subcategories.map((s,i) => { return { categoryID: s.categoryID, subCategoryID: s.subCategoryID, subCategoryDesc: s.subCategoryDesc, sortOrder:  i + 1 }; });
		this.store.dispatch(new SaveSubcategories(newOrder));
	}
	dropText(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.texts, event.previousIndex, event.currentIndex);
		const newOrder = this.texts.map((t,i) => { return { textID: t.textID, defaultText: t.defaultText, subcategoryID: t.subCategoryID, sortOrder:  i + 1 }; });
		this.store.dispatch(new UpdateSubcatTexts(newOrder));
	}

	checkEnter(e, section) {
		if(e.keyCode == 13) {
			switch (section) {
				case 'cat': 
					this.addCategory(); break;
				case 'subcat':
					this.addSubcategory(); break;
			}
		}
	}

}
