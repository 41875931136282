import { ProfilePhoto } from './profilePhoto';
import { Team } from './team';

export class Profile {
	public userID: string = '';
	public title:string = '';
    public firstName: string = '';
    public lastName: string = '';
    public companyName: string = '';
    public mainPhone: string = '';
    public directWorkPhone: string = '';
    public cellPhone: string = '';
    public email: string = '';
    public website: string = '';
    public linkedIn: string = '';
    public twitter: string = '';
    public instagram: string = '';
    public facebook: string = '';
    public youtube: string = '';
    /*
    public logoTopLeftX: number = 0;
    public logoTopLeftY: number = 0;
    public logoBottomRightX : number = 0;
    public logoBottomRightY: number = 0;
	public logoZoom: number = 0;
	public photoTopLeftX: number = 0;
    public photoTopLeftY: number = 0;
    public photoBottomRightX: number = 0;
    public photoBottomRightY: number = 0;
	public photoZoom: number = 0;
	*/
    public logoFile: string = '';
    public logoFileOnly: string = '';
    public photoFile: string = '';
    public photoFileOnly: string = '';
    public address1: string = '';
    public address2: string = '';
    public city: string = '';
    public state: string = '';
	public zip: string = '';
	
	public profileTypeID: number;
	public teamDesc: string = '';
	public photoID: string = '';
	public logoID: string = '';
	public photoURLPath: string = '';
	public logoURLPath: string = '';
	public photo: ProfilePhoto = null;
	public logo: ProfilePhoto;
	public profilePartner: Profile;
	public profileEntireTeam: Team;

	public profileName: string = '';
	public profileID: string = '';

	public logoForOutput: ProfilePhoto;

	constructor(o?:any) {
		Object.assign(this,o);
	}

	public isValid?(): boolean {
		//profile is valid if all required fields are filled in
		return !!(
			this.userID &&
			this.firstName &&
			this.lastName &&
			this.companyName &&
			this.mainPhone &&
			this.email
		)
	}
}