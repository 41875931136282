import { FormGroup, FormControl } from '@angular/forms';

export function MustMatch(controlName: string, matchingControlName:string) {
	return (formGroup: FormGroup) => {
		const control = formGroup.controls[controlName];
		const matchingControl = formGroup.controls[matchingControlName];

		if(matchingControl.errors && matchingControl.errors.mustMatch) {
			return;
		}
		if(control.value != matchingControl.value) {
			matchingControl.setErrors({ mustMatch: true });
		} else {
			matchingControl.setErrors(null);
		}
	}
}

export function ArrayNotEmpty(controlName: string) {
	return (formgroup: FormGroup) => {
		const control = formgroup.controls[controlName];
		if(control.value.length == 0) {
			control.setErrors({ noEmail: true });
		} else {
			control.setErrors(null);
		}
	}
}
