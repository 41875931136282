<h1 *ngIf="app$ | async; let app">
	Great news, {{app.firstName}}! Coverage is available starting at {{ monthAmount | currency }}/month on a ${{app.amount | number}} policy with a {{app.duration}}-year term.
</h1>

<div *ngIf="dtcResults" class="quotes-results-container">
	<div class="quotes-header-row">
		<div class="mat-column-image">
			<div>Carrier</div>
		</div>
		<div class="mat-column-product">
			<div>Product</div>
		</div>
		<div class="mat-column-amount">
			<div>Price</div>
		</div>
	</div>
	<div class="mat-row-container plan-list">
		<div class="mat-row" *ngFor="let element of dtcResults.results">
			<div class="mat-column-image mat-column-image-cell" [style.backgroundImage]="'url(' + element.logoPath + ')'">

			</div>
			<div  class="mat-column-product">
				<div class="product-info">
					<div class="product-info-name">
						<a>{{ element.productName }}</a>
					</div>
					<div>{{ element.productVersionInfo.healthNameLong }}</div>
					<div *ngIf="element.productVersionInfo.useAgeNearest">Age Nearest: {{ element.input.nearestAge }}</div>
					<div *ngIf="!element.productVersionInfo.useAgeNearest">Actual Age: {{ element.input.actualAge }}</div>
					<div class="underwriting-badge" *ngIf="!!element.extensionValues.isAcceleratedUnderwriting">Accelerated Underwriting Eligible</div>
					<div *ngIf="element.input.tableRating != 0">Table {{ getTableRating(element.input.tableRating) }}</div>
					<div class="rider-badge" *ngIf="!!element.input.isAccidentalDeathBenefitRider" >ADB</div>
					<div class="rider-badge" *ngIf="!!element.input.isWaiverOfPremiumRider" >WOP</div>
					<div class="rider-badge" *ngIf="!!element.input.isReturnOfPremiumRider" >ROP</div>
					<div class="rider-badge" *ngIf="!!element.input.isChildTermRider" >CIR ({{ element.input.cru }})</div>
				</div>
			</div>

			<div class="mat-column-amount">
				<div class="currency-amount">
					{{ element.extensionValues.quoteAmountMonthly | currency }}<span class="currency-peryear">/mth</span>
				</div>
			</div>
		</div>
	</div>
</div>

<h1 *ngIf="app$ | async; let app">
	Your detailed quote is being prepared and will be sent to {{app.email}} shortly.
</h1>

<div class="icon-holder">
	<mat-icon class="computer checked">computer</mat-icon>
	<div class="spacer"></div>
	<mat-icon class="received unchecked">computer</mat-icon>
</div>
<div class="icon-holder">
	<mat-icon class="checked">check_circle_outline</mat-icon>
	<div class="line"></div>
	<mat-icon class="unchecked">panorama_fish_eye</mat-icon>
</div>

<div class="again-link" *ngIf="broker$ | async; let broker"><a [routerLink]="['/dtc/', broker.userID]">Click here</a>&nbsp;to start a new quote.</div>