<div class="libraryContents">
	<div class="top">

		<mat-card class="contentsHead">
			<mat-card-content>
				<acc-common-library-search (onSearch)="search($event)"></acc-common-library-search>

				<acc-common-library-actions (onPersonalize)="personalize()" (onUploadFile)="uploadFile()" (onAddFolder)="addFolder()" [uploadEnabled]="isFileUploadEnabled" [activeFolder]="activeFolder"></acc-common-library-actions>
				<acc-common-library-breadcrumbs *ngIf="sessionService.settings.module.showBreadcrumbs" [folder]="activeFolder" (onClick)="folderAction({ actionName: 'breadcrumbClick', payload: { folder: $event } })"></acc-common-library-breadcrumbs>
			</mat-card-content>
		</mat-card>
	</div>

	<div class="bottom">
		<div class="navContainer">
			<div class="libraryFolders" *ngIf="sharedAssets || myAssets" [appSticky]="83" [appStickyStyle]="{overflow: 'auto', height: 'to-bottom', top: '111px', width: '297px'}">
				<ul>
					<acc-common-library-content-folders [showFolderTooltip]="true" [folderTooltip]="tooltips.al_shared" [folders]="sharedAssets" (onAction)="folderAction($event)" [(activeFolder)]="activeFolder" [showFolderActions]="true"></acc-common-library-content-folders>
					<acc-common-library-content-folders [showFolderTooltip]="true" [folderTooltip]="tooltips.al_my" [folders]="myAssets" (onAction)="folderAction($event)" [(activeFolder)]="activeFolder" [showFolderActions]="true" [showCartActions]="false"></acc-common-library-content-folders>
					<acc-common-library-content-folders [showFolderTooltip]="true" [folderTooltip]="tooltips.al_cart" [folders]="cartAssets" (onAction)="folderAction($event)" [(activeFolder)]="activeFolder" [showCartActions]="true"></acc-common-library-content-folders>
				</ul>
			</div>
		</div>

		<div *ngIf="contents" class="contentsFilesHolder libraryContents">
			<div class="contentsFoldersHolder"><div class="contentsFolders"><ul>
				<acc-common-library-content-folders [folders]="contents.MediaCategories" [showFolderActions]="false" (onAction)="folderAction($event)"></acc-common-library-content-folders>
			</ul></div></div>
			<acc-common-library-content-files [medias]="contents.LibraryItems" (onAction)="fileAction($event)" [folderType]="folderType" *ngIf="folderType != 'cart'"></acc-common-library-content-files>
			<acc-common-library-cart-files [files]="contents.LibraryItems" (onAction)="fileAction($event)" [folderType]="folderType" [cartId]="activeCartId" *ngIf="folderType == 'cart'"></acc-common-library-cart-files>
		</div>	
	</div>
</div>