<h1 mat-dialog-title></h1>
<div mat-dialog-content>
  <div *ngIf="productDetail != null" class="detail-header-container">
    <div class="detail-header-logo">
      <img src="{{data.image}}">  
    </div>
    <div class="detail-header-product-container">
      <div class="detail-header-product-title">
        {{(productDetail.policyProduct != null)?productDetail.policyProduct.productName : ""}}
        <!--{{data.result.productVersionInfo.name}}-->
      </div>
      <div class="detail-header-product-info">
        {{data.result.productVersionInfo.healthNameLong}} - {{data.result.faceAmount | currency:'USD':'symbol':'6.0'}} - {{(data.result.productVersionInfo.useAgeNearest) ? "Age Nearest: " + data.result.input.nearestAge : "Actual Age: " + data.result.input.actualAge }}
      </div>
    </div>
  </div>
  <div class="detail-banner">
    <div *ngIf="productDetail != null" class="detail-banner-ambest">
      AM Best {{productDetail.amBest}}
    </div>
    <div [innerHTML]="productDetail.policyProduct.specialFeatures" *ngIf="productDetail != null && productDetail.policyProduct != null && productDetail.policyProduct.specialFeatures !=''" class="detail-banner-specialfeatures">
    </div>
  </div>
  <div class="detail-info-table-container">
    <div class="detail-info-table">
      <div class="detail-info-table-title">Description</div>
      <div class="detail-info-table-text">
        <p *ngIf="productDetail != null && productDetail.policyProduct != null && productDetail.policyProduct.description != null" [innerHTML]="productDetail.policyProduct.description"></p>
        <p *ngIf="productDetail == null || productDetail.policyProduct == null || productDetail.policyProduct.description == null">No description available</p>
      </div>
    </div>
    <div class="detail-info-table">
      <div class="detail-info-table-title">Conversion information</div>
      <div class="detail-info-table-text">
        <p *ngIf="productDetail != null && productDetail.policyProduct != null" [innerHTML]="productDetail.policyProduct.conversionInfo"></p>
        <p *ngIf="productDetail == null || productDetail.policyProduct == null || productDetail.policyProduct.conversionInfo == null">No conversion information available</p>
      </div>
    </div>
    <div class="detail-info-table">
      <div class="detail-info-table-title">Underwriting Requirements</div>
      <div class="detail-info-table-text">
        <ul class="requirements-info-lines" *ngIf="productDetail != null && productDetail.policyProduct != null && productDetail.policyProduct.requirements.length > 0">
          <li *ngFor="let req of productDetail.policyProduct.requirements">
            <div class="req-container">
              <div class="req-icon"><i class="material-icons">done</i></div> 
              <div class="req-text">{{req.requirement}}</div>
            </div>
          </li>
        </ul>
        <div class="detail-info-table-text" *ngIf="productDetail == null || productDetail.policyProduct == null || productDetail.policyProduct.requirements.length <= 0">
          <p>No underwriting requirements available</p> 
        </div>
      </div>
    </div>
  </div>

</div>
<div mat-dialog-actions>
  <button class="close-button" mat-stroked-button (click)="onNoClick()">Close</button>
</div>