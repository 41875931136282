import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DataService } from 'acc-services';
import { IAppState } from '../state/app.state';
import * as StatusActions from '../actions/status.actions';
import { catchError, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { RevisionDatesActionTypes } from '../actions/revisiondates.actions';
import * as RevisionDatesActions from '../actions/revisiondates.actions';

@Injectable()
export class RevisionDatesEffects {

	@Effect() 
	getMedias$ = this.actions$.pipe(
		ofType(RevisionDatesActionTypes.GET_MEDIAS),
		switchMap((action:RevisionDatesActions.GetMedias) => this.dataService.getMediaAll(action.orgId)
			.pipe(
				map((resp:any) => new RevisionDatesActions.GetMediasSuccess(resp)),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
			)
		)
	)
	@Effect()
	editMedia$ = this.actions$.pipe(
		ofType(RevisionDatesActionTypes.EDIT_MEDIA_CONFIRM),
		withLatestFrom(this.store$.select('revisionDates', 'orgId')),
		withLatestFrom(this.store$.select('revisionDates', 'editingMedia')),
		switchMap(([[action, orgId], editingMedia]) => this.dataService.saveMedia(orgId, editingMedia, null, null)
			.pipe(
				map((resp:any) => new RevisionDatesActions.EditMediaSuccess(resp)),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
			)
		)
	)

	@Effect() 
	getTexts$ = this.actions$.pipe(
		ofType(RevisionDatesActionTypes.GET_TEXTS),
		switchMap((action:RevisionDatesActions.GetTexts) => this.dataService.getSubcatTextAll(action.orgId)
			.pipe(
				map((resp:any) => new RevisionDatesActions.GetTextsSuccess(resp)),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
			)
		)
	)
	@Effect()
	editText$ = this.actions$.pipe(
		ofType(RevisionDatesActionTypes.EDIT_TEXT_CONFIRM),
		withLatestFrom(this.store$.select('revisionDates', 'orgId')),
		withLatestFrom(this.store$.select('revisionDates', 'editingText')),
		switchMap(([[action, orgId], editingText]) => this.dataService.updateSubcatText(editingText)
			.pipe(
				map((resp:any) => new RevisionDatesActions.EditTextSuccess(resp)),
				catchError(((resp:any) => of(new StatusActions.SetErrorMessage(resp.error.Message))))
			)
		)
	)


	constructor(private actions$: Actions, private store$:Store<IAppState>, private dataService:DataService) {}
}
