
export class ProposalTemplate {
	public templateID: string;
	public docID: string;
	public templateTypeID: number;
	public templateDesc: string;
	public templateDetail: string;
	public readOnly: boolean;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}