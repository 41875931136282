<div mat-dialog-header>
		<h2 mat-dialog-title>{{data.title || 'Confirm'}}</h2>
	</div>
	
	<mat-dialog-content>
		<div class="dialogContent" [innerHTML]="data.message"></div>
	</mat-dialog-content>
	
	<mat-dialog-actions>
		<button mat-raised-button color="primary" [mat-dialog-close]="true">{{yesText}}</button>
		<button mat-stroked-button [mat-dialog-close]="false" *ngIf="noText != ''">{{noText}}</button>
	</mat-dialog-actions>