<h1>About how much coverage are you looking for?</h1>

<div class="form">
	<div class="amount" [ngClass]="{error: amountField.touched && amountField.hasError('required')}">
		<h2>Choose Amount</h2>
		<div class="slider-container">
			<span>$100,000</span>
			<mat-slider
				[max]="2000000"
				[min]="100000"
				[step]="100000"
				[thumbLabel]="true"
				[tickInterval]="'auto'"
				[formControl]="amountField"
				[displayWith]="formatAmount">
			</mat-slider>
			<span>$2,000,000</span>
		</div>
		<div class="coverage-amount">{{amountField.value | currency}}</div>
	</div>

	<div class="duration">
		<mat-form-field appearance="outline">
			<mat-label>For how long?</mat-label>
			<select matNativeControl [formControl]="durationField">
				<option></option>
				<option value="10">10 years</option>
				<option value="15">15 years</option>
				<option value="20">20 years</option>
				<option value="25">25 years</option>
				<option value="30">30 years</option>
				<option value="35">35 years</option>
			</select>
			<mat-error *ngIf="durationField.hasError('required')">This field is required</mat-error>
		</mat-form-field>
	</div>

</div>
<div class="actions">
	<button mat-raised-button color="primary" (click)="back()">Back</button>
	<button mat-raised-button color="primary" (click)="next()">Next</button>
</div>
