import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { ITextLibState } from '../state/textlib.state';


const selectTextLib = (state:IAppState) => state.adminTextLib;

export const selectCategories = createSelector(
	selectTextLib,
	(state: ITextLibState) => state.categories
)
export const selectSearchResults = createSelector(
	selectTextLib,
	(state:ITextLibState) => state.results
)
export const selectItemToEdit = createSelector(
	selectTextLib,
	(state: ITextLibState) => state.itemToEdit
)
export const selectItemToDelete = createSelector(
	selectTextLib,
	(state: ITextLibState) => state.itemToDelete
)

