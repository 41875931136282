<div mat-dialog-header>
	<h2 mat-dialog-title>Edit a document</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<div class="form">
			<p>Select a document from the list below.</p>
			<ul>
				<li class="mediaAsset" *ngFor="let media of mediaAssets">
					<div (click)="selectMedia(media)">{{media.docDesc}} <span>(Created: {{media.docCreated | date}})</span></div>
				</li>
			</ul>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>
