<div class="profile">

		<!-- HEADER -->
		<header class="profile-header">
	
			<div *ngIf="profile" class="user-info" >
				<div class="profile-image avatar huge" [style.backgroundImage]="'url(' + profile.photoURLPath + ')'"></div>
				<div class="name">
				   {{profile.firstName + " " + profile.lastName}}
				</div>
			</div>
	
			<div class="actions">
				<div *ngIf="integrateCRM" >
					<button mat-raised-button color="accent" aria-label="Follow" *ngIf="crmLoggedIn" (click)="unlinkCrm()">Unlink from CRM</button>		
					<button mat-raised-button color="accent"  aria-label="Follow" *ngIf="!crmLoggedIn" (click)="linkCrm()">Link to CRM</button>	
				</div>
				<div class="profile-button-row">
					<button  (click)="editProfile()" color="primary" mat-flat-button="" class="mat-flat-button">
						<span class="mat-button-wrapper">Edit</span>
						<div class="mat-button-ripple mat-ripple" matripple=""></div>
						<div class="mat-button-focus-overlay"></div>
					</button>

					<button  (click)="close()" mat-flat-button="" class="mat-flat-button">
						<span class="mat-button-wrapper">Close</span>
						<div class="mat-button-ripple mat-ripple" matripple=""></div>
						<div class="mat-button-focus-overlay"></div>
					</button>
				</div>
			</div>
	
		</header>
		<!-- / HEADER -->
	
		<mat-tab-group dynamicHeight="true">
	
			<mat-tab label="My Profile" *ngIf="profile">
	
				<mat-card class="about">
					<mat-card-header> 
						<mat-card-title>
							<div class="title">General Information</div>
						</mat-card-title>
					</mat-card-header> 
					<mat-card-content>
							<div class="content">
								<div class="info-line">
									<div class="title">Title</div>
									<div class="info">{{profile.title}}</div>
								</div>
				
								<div class="info-line">
									<div class="title">Name & Surname</div>
									<div class="info">{{profile.firstName + " " + profile.lastName}}</div>
								</div>
				
								<div class="info-line">
									<div class="title">Company</div>
									<div class="info">{{profile.companyName}}</div>
									<div class="info"  *ngIf="profile.address1" >{{profile.address1 + " " + profile.address2 + ", " + profile.city + ", " + profile.state + " " + profile.zip }}</div>
								</div>
				
								<div class="info-line">
									<div class="title">Main Phone</div>
									<div class="info">{{profile.mainPhone}}</div>
								</div>
				
								<div class="info-line">
									<div class="title">Direct Phone</div>
									<div class="info">{{profile.directWorkPhone}}</div>
								</div>
				
								<div class="info-line">
									<div class="title">Cell Phone</div>
									<div class="info">{{profile.cellPhone}}</div>
								</div>
				
								<div class="info-line">
									<div class="title">Email</div>
									<div class="info">{{profile.email}}</div>
								</div>
				
							</div>
					</mat-card-content>
				</mat-card>
				<mat-card class="about-sidebar">
					<mat-card-header> 
						<mat-card-title>
								<div class="title">Social</div>
						</mat-card-title>
					</mat-card-header> 
					<mat-card-content>
							<div class="content">
									<div class="info-line">
										<div class="title">Url</div>
										<div class="info">{{profile.website}}</div>
									</div>
					
									<div class="info-line">
										<div class="title">Facebook</div>
										<div class="info" >{{profile.facebook}}</div>
									</div>
					
									<div class="info-line">
										<div class="title">Twitter</div>
										<div class="info">{{profile.twitter}}</div>
									</div>
					
									<div class="info-line">
										<div class="title">Linkedin</div>
										<div class="info" >{{profile.linkedIn}}</div>
									</div>
					
									<div class="info-line">
										<div class="title">Instagram</div>
										<div class="info" >{{profile.instagram}}</div>
									</div>
					
									<div class="info-line">
										<div class="title">YouTube</div>
										<div class="info" >{{profile.youtube}}</div>
									</div>
					
								</div>
					</mat-card-content>
				</mat-card>
	
			</mat-tab>
	
		</mat-tab-group>
	</div>
	