<div class="container">
		<div class="top">
			<div class="form" *ngIf="org">
				<dl>
					<dt><label>What's new</label></dt>
					<dd>
						<textarea [(ngModel)]="org.whatsNew"></textarea>
					</dd>
					<dt><label>Title</label></dt>
					<dd>
						<textarea [(ngModel)]="org.whatsNewTitle"></textarea>
					</dd>
				</dl>
				<button mat-flat-button color="primary" (click)="save()"  mat-flat-button>
					<span class="mat-button-wrapper">Save</span>
					<div class="mat-button-ripple mat-ripple" matripple=""></div>
					<div class="mat-button-focus-overlay"></div>
				</button>
			</div>
		</div>
	</div>
	