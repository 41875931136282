import { Action } from '@ngrx/store';
import { Media, Text } from 'acc-models';

export enum RevisionDatesActionTypes {
	GET_MEDIAS = '[REVDATE] Get Medias',
	GET_MEDIAS_SUCCESS = '[REVDATE] Get Medias Success',
	EDIT_MEDIA = '[REVDATE] Edit Media',
	EDIT_MEDIA_CONFIRM = '[REVDATE] Edit Media Confirm',
	EDIT_MEDIA_CANCEL = '[REVDATE] Edit Media Cancel',
	EDIT_MEDIA_SUCCESS = '[REVDATE] Edit Media Success',

	GET_TEXTS = '[REVDATE] Get Texts',
	GET_TEXTS_SUCCESS = '[REVDATE] Get Texts Success',
	EDIT_TEXT = '[REVDATE] Edit Text',
	EDIT_TEXT_CONFIRM = '[REVDATE] Edit Text Confirm',
	EDIT_TEXT_CANCEL = '[REVDATE] Edit Text Cancel',
	EDIT_TEXT_SUCCESS = '[REVDATE] Edit Text Success',

	RESET = '[ROOT] Reset'
}

export class GetMedias implements Action {
	public readonly type = RevisionDatesActionTypes.GET_MEDIAS;
	constructor(public orgId: number) {}
}
export class GetMediasSuccess implements Action {
	public readonly type = RevisionDatesActionTypes.GET_MEDIAS_SUCCESS;
	constructor(public medias: Array<Media>) {}
}
export class EditMedia implements Action {
	public readonly type = RevisionDatesActionTypes.EDIT_MEDIA;
	constructor(public media: Media) {}
}
export class EditMediaConfirm implements Action {
	public readonly type = RevisionDatesActionTypes.EDIT_MEDIA_CONFIRM;
	constructor(public revisionDate: Date) {}
}
export class EditMediaCancel implements Action {
	public readonly type = RevisionDatesActionTypes.EDIT_MEDIA_CANCEL;
	constructor() {}
}
export class EditMediaSuccess implements Action {
	public readonly type = RevisionDatesActionTypes.EDIT_MEDIA_SUCCESS;
	constructor(public media: Media) {}
}


export class GetTexts implements Action {
	public readonly type = RevisionDatesActionTypes.GET_TEXTS;
	constructor (public orgId: number){}
}
export class GetTextsSuccess implements Action {
	public readonly type = RevisionDatesActionTypes.GET_TEXTS_SUCCESS;
	constructor(public texts: Array<Text>) {}
}
export class EditText implements Action {
	public readonly type = RevisionDatesActionTypes.EDIT_TEXT;
	constructor(public text: Text) {}
}
export class EditTextConfirm implements Action {
	public readonly type = RevisionDatesActionTypes.EDIT_TEXT_CONFIRM;
	constructor(public revisionDate: Date) {}
}
export class EditTextCancel implements Action {
	public readonly type = RevisionDatesActionTypes.EDIT_TEXT_CANCEL;
	constructor() {}
}
export class EditTextSuccess implements Action {
	public readonly type = RevisionDatesActionTypes.EDIT_TEXT_SUCCESS;
	constructor(public text: Text) {}
}
export class RevisionDateReset implements Action {
	public readonly type = RevisionDatesActionTypes.RESET;
	constructor() {}
}


export type RevisionDatesActions =
	GetMedias |
	GetMediasSuccess |
	EditMedia |
	EditMediaConfirm |
	EditMediaCancel |
	EditMediaSuccess |

	GetTexts |
	GetTextsSuccess |
	EditText |
	EditTextCancel |
	EditTextConfirm |
	EditTextSuccess | 
	RevisionDateReset;