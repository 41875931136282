import { Component, OnInit } from "@angular/core";
import { SessionService, DataService, QuoteService } from "acc-services";
import { ProposalTemplate, Organization } from "acc-models";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent, tooltips } from 'acc-common';
import { take, switchMap } from 'rxjs/operators';
import { RootReset } from '../../store/actions/root.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';

@Component({
	selector: "acc-main-templates",
	templateUrl: "./templates.component.html",
	styleUrls: ["./templates.component.scss"]
})
export class TemplatesComponent implements OnInit {
	public templates: Array<ProposalTemplate>;
	public templateToEdit: ProposalTemplate;
	public newDocName: string ='';
	public nameMissing: boolean  = false;
	
	public tooltips = tooltips;
	constructor(private sessionService: SessionService, private dataService: DataService, private quoteService: QuoteService, private router: Router, private dialog: MatDialog, private store: Store<IAppState>) {}

	ngOnInit() {
		this.getTemplates();
		this.store.dispatch(new RootReset());
	}

	getTemplates() {
		this.quoteService.getTemplatesAdmin(this.sessionService.getUser().orgID).subscribe((temps:any) => {
			this.templates = temps;
		})
	}

	selectTemplateToEdit(template) {
		this.templateToEdit = template;
	}
	renameTemplate(template) {
		this.dataService.updateTemplate(template).subscribe(resp => {
			this.templateToEdit = null
		})
	}
	editTemplate(template) {
		const moduleId = this.sessionService.getItem<Organization>('organization').lifeQuoteModuleID;
		this.router.navigateByUrl(`/module/${moduleId}/${template.docID}/template`)
	}
	deleteTemplate(template) {
		const dialogRef = this.dialog.open(ConfirmComponent, { data: { message: 'Are you sure you want to remove this template?'}});
		dialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.dataService.deleteTemplate(template.templateID).subscribe(resp => {
					this.templates = this.templates.filter(t => t.templateID != template.templateID);
				})
			}
		})
	}
	addNew() {
		if(this.newDocName) {
			this.nameMissing = false;
			const moduleId = this.sessionService.getItem<Organization>('organization').lifeQuoteModuleID;
			let docId;
			this.dataService.insertDocument(this.sessionService.getUser().userID, moduleId).pipe(
				switchMap((doc:any) => { 
					docId = doc.docID;
					const template = new ProposalTemplate({
						docID: doc.docID,
						templateTypeID: 1,
						templateDesc: this.newDocName
					});
					return this.dataService.saveTemplate(template)

				})
			).subscribe(resp => {
				this.router.navigateByUrl(`/module/${moduleId}/${docId}/template`);
			});
		} else {
			this.nameMissing = true;
		}
	}
}
