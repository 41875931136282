<div mat-dialog-header>
	<h2 mat-dialog-title>Move Folder</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<div class="libraryFolders">
			<ul>
				<acc-common-library-content-folders [folders]="data.folders" (onAction)="selectDestination($event)" [activeFolder]="selectedFolder" [showFolderActions]="false"></acc-common-library-content-folders>
			</ul>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="moveFolder()" [disabled]="!selectedFolder">Move</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>

