import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';

import { AccCommonModule } from 'acc-common';
import { EmailEditorModule } from 'angular-email-editor';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MktComponent } from './mkt.component';
import { ContactsImportComponent } from './contacts-import/contacts-import.component';
import { MktRoutingModule } from './mkt-routing.module';
import { ProgramsSetupComponent } from './programs-setup/programs-setup.component';
import { ProgramsSelectionComponent } from './programs-selection/programs-selection.component';
import { SharedModule } from '../shared/shared.module';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MktReportComponent } from './mkt-report/mkt-report.component';
import { CanDeactivateMktSetup } from '../components/guard/can-deactivate-mkt-setup';

@NgModule({
  declarations: [
	MktComponent,
	ContactsImportComponent,
	ProgramsSetupComponent,
	ProgramsSelectionComponent,
	ContactsListComponent,
	MktReportComponent,
  ],
  imports: [
	CommonModule,
	AccCommonModule,
	SharedModule,
	FormsModule,
	ReactiveFormsModule,
	MktRoutingModule,

	MatCardModule,
	MatIconModule,
	MatListModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatInputModule,
	MatTableModule,
	MatPaginatorModule,
	MatStepperModule,
	MatAutocompleteModule,
	MatCheckboxModule,
	MatButtonToggleModule,
	MatGridListModule,
	MatDatetimepickerModule,
	MatMomentDatetimeModule,
	MatSelectModule,
	MatButtonModule,
	MatDialogModule,
	MatRippleModule,
	MatMenuModule,
	MatIconModule,
	MatSortModule,
	MatProgressSpinnerModule,
	BrowserModule,
	DragDropModule,

	EmailEditorModule,


  ],
  entryComponents: [],
  providers: [
	  CanDeactivateMktSetup
  ]
})
export class MktModule { }
