import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { IAppState } from "../../store/state/app.state";
import { Store } from "@ngrx/store";
import { SetSignUpData } from "../../store/actions/signup.actions";
import { MixpanelService } from "acc-services";

@Component({
	selector: "acc-main-signup-welcome",
	templateUrl: "./signup-welcome.component.html",
	styleUrls: ["./signup-welcome.component.scss", "../signup.component.scss"],
})
export class SignUpWelcomeComponent implements OnInit {
	private userData: any;

	constructor(private router: Router, private route: ActivatedRoute, private store: Store<IAppState>, private mixpanelService: MixpanelService) {}

	ngOnInit() {
		//GET PARAMETERS
		this.route.queryParamMap.subscribe((queryParams) => {
			if (queryParams.keys.length > 0) {
				var f,
					l,
					e,
					o = null;

				queryParams.keys.forEach((key) => {
					if (key.toLowerCase() === "firstname") {
						f = queryParams.get(key);
					}
					if (key.toLowerCase() === "lastname") {
						l = queryParams.get(key);
					}
					if (key.toLowerCase() === "email") {
						e = queryParams.get(key);
					}
					if (key.toLowerCase() === "masterorgid") {
						o = queryParams.get(key);
					}
				});

				this.userData = {
					firstName: f,
					lastName: l,
					email: e,
					masterOrgID: o,
				};
				this.store.dispatch(new SetSignUpData(this.userData));
				//Support on Session Storage
				sessionStorage.setItem('masterOrgID',o);
			}
		});

		//Init Mixpanel for Signup.
		this.mixpanelService.trackSignup();
		
	}

	login() {
		this.router.navigateByUrl("/signup/1");
	}
}
