import { ITextLibState, initialTextLibState } from '../state/textlib.state';
import { TextLibActions, TextLibActionTypes } from '../actions/textlib.actions';

export function textLibReducer(state:ITextLibState = initialTextLibState, action:TextLibActions):ITextLibState {
	switch (action.type) {
		case TextLibActionTypes.GET_CATEGORIES:
			return state;
		case TextLibActionTypes.GET_CATEGORIES_SUCCESS: 
			return { ...state, categories: action.results };
		case TextLibActionTypes.SEARCH:
			return { ...state, searchCriteria: action.criteria};
		case TextLibActionTypes.SEARCH_SUCCESS: 
			return { ...state, results: action.results };
		case TextLibActionTypes.CLEAR_SEARCH:
			return { ...state, results: null }
		case TextLibActionTypes.EDIT_ITEM: 
			return { ...state, itemToEdit: action.itemToSave};
		case TextLibActionTypes.EDIT_ITEM_CONFIRM: 
			//return state;
			return { ...state, itemToEdit: action.itemToSave};
		case TextLibActionTypes.EDIT_ITEM_CANCEL:
			return { ...state, itemToEdit: null }
		case TextLibActionTypes.EDIT_ITEM_SUCCESS: 
			let newResults = state.results ? state.results.map(r => r.textLibID == state.itemToEdit.textLibID ? action.item : r) : null;
			//if this is a new one, add it in
			if(newResults && !newResults.find(r => r.textLibID == action.item.textLibID)) {
				if(state.searchCriteria.textLibCategoryId == state.itemToEdit.textLibCategoryID) {
					//only add if we're displaying the same category
					newResults.push(state.itemToEdit);
				}
			}
			return { ...state, itemToEdit: null, results: newResults }
		case TextLibActionTypes.DELETE_ITEM:
			return { ...state, itemToDelete: action.itemToDelete }
		case TextLibActionTypes.DELETE_ITEM_CANCEL:
			return { ...state, itemToDelete: null }
		case TextLibActionTypes.DELETE_ITEM_CONFIRM: 
			return state;
		case TextLibActionTypes.DELETE_ITEM_SUCCESS: 
			return { ...state, results: state.results.filter(r => r.textLibID != state.itemToDelete.textLibID), itemToDelete: null}
		case TextLibActionTypes.RESET:
			return initialTextLibState;
		default:
			return state;
	}
}