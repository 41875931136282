import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { User } from "acc-models";
import { DataService, SessionService } from "acc-services";
import { Subject, SubscriptionLike, timer } from "rxjs";
import { skip, take, takeUntil } from "rxjs/operators";
import { SetBroker, SetServiceEnabled } from "../../store/actions/dtc.actions";
import { selectServiceEnabled, selectApplication, selectBroker, selectProgress } from "../../store/selectors/dtc.selectors";
import { IAppState } from "../../store/state/app.state";
import { DtcRouteService } from "../dtc.route.service";

@Component({
	selector: "acc-main-dtc-template",
	templateUrl: "./dtc-template.component.html",
	styleUrls: ["./dtc-template.component.scss"],
})
export class DtcTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
	public broker: User;
	public progress: number;
	public progressBackground: string;
	public state$;
	private destroy$: Subject<boolean> = new Subject();
	public showLoader: boolean = false;
	private loaderIds: Array<{ key: string; observable: SubscriptionLike }> = [];

	public serviceEnabled: boolean = null;
	private showLoaderSubscription: SubscriptionLike;
	private hideLoderSubscription: SubscriptionLike;
	public progressLabel = "Progress";

	public brokerId: string;

	constructor(private store: Store<IAppState>, private sessionService: SessionService, private dtcRouteService: DtcRouteService, private dataService: DataService, private router: Router) {
		this.state$ = this.store.pipe(select(selectApplication));

	}

	ngOnInit() {

		//if we don't have a userid in the url, then we need to find it another way
		timer(500).pipe(takeUntil(this.dtcRouteService.pathParam)).subscribe(() => {
			//haven't gotten a user id
			this.brokerId = this.sessionService.getItem<string>('dtcBroker');
			if(!this.brokerId) {
				//we still don't have a broker id, so we don't know who this is
				//tell them it's disabled
				this.store.dispatch(new SetServiceEnabled(false));
				return;
			} else {
				this.router.navigateByUrl('/dtc/'+this.brokerId);
			}
		})
	
		this.store.pipe(select(selectBroker), takeUntil(this.destroy$)).subscribe((b: any) => (this.broker = b));
		this.store.pipe(select(selectServiceEnabled), takeUntil(this.destroy$)).subscribe((e:any) => this.serviceEnabled = e);
		this.store.pipe(select(selectProgress), takeUntil(this.destroy$)).subscribe((p:number) => {
			this.progressLabel = (p< 100)?"Progress": "Congrats - Your Quote is Here!";
			this.progress = p;
			this.progressBackground = 'linear-gradient(to right, var(--primary-color) 0%, var(--primary-color) ' + p + '%, #666 ' + p + '%, #666 100%)'
		});
		this.dtcRouteService.pathParam.pipe(
			takeUntil(this.destroy$),
			//skip(1)
		).subscribe(userId => {
			this.setBroker(userId);
			this.sessionService.setItem('dtcBroker', userId);

			//Check Subscription level ID
			this.dataService.getOrgSubscriptionLevel(userId).subscribe( level => {
				if(level != null && +level <= 1){
					this.store.dispatch(new SetServiceEnabled(false));
				}
			});

		});


		// //if this isn't the demographics page, this is probably a refresh
		// //send them to first page
		// this.dtcRouteService.page.pipe(take(1), takeUntil(this.destroy$)).subscribe(page => {
		// 	if(page !== 'demographics') {
		// 		this.router.navigateByUrl('/dtc/'+this.brokerId);
		// 	}
		// })

	}

	ngAfterViewInit(): void {
		this.showLoaderSubscription = this.sessionService.onShowLoader.subscribe((id) => {
			if (this.loaderIds.filter((l) => l.key == id).length > 0) {
				//if hide has already fired...
				return;
			}
			let obs = timer(500);
			this.loaderIds.push({
				key: id,
				observable: obs.subscribe((val) => {
					this.showLoader = true;
				}),
			});
		});
		this.hideLoderSubscription = this.sessionService.onHideLoader.subscribe((id) => {
			let ldr = this.loaderIds.filter((o) => o.key == id);
			if (ldr.length > 0) {
				if (ldr[0].observable) {
					ldr[0].observable.unsubscribe();
				}
				ldr[0].observable = null;
			} else {
				this.loaderIds.push({ key: id, observable: null });
			}

			if (this.loaderIds.filter((x) => x.observable != null).length == 0) {
				this.showLoader = false;
			}
		});
		//this.menuItems.nativeElement.addEventListener('click', () => this.expandNav = false)
	}

	setBroker(userId) {
		this.dataService.getSelfServeEnabled(userId).subscribe((resp:any) => {
			this.store.dispatch(new SetServiceEnabled(resp.enabled));
			this.dataService.getBroker(userId).subscribe((b: any) => this.store.dispatch(new SetBroker(b)));
		});

	}
	ngOnDestroy() {
		this.destroy$.next(true);
	}
}
