<div class="slider-container">
  <div class="slides" #slides>
    <ng-content select="[appSliderItem]" #sliderItem></ng-content>
  </div>
  <div class="counter" *ngIf="showDots">
	  <div class="dot" *ngFor="let item of items; let i = index" [ngClass]="{active: slidesIndex == i}" (click)="goToSlide(i)"></div>
  </div>
  <div class="controls">
    <button mat-raised-button color="primary" class="acc-button" (click)="onClickLeft()"  [disabled]="!!this.loading || this.slidesIndex <= 0" >Back</button>
    <button mat-raised-button color="primary" class="acc-button" (click)="onClickRight()" [disabled]="!!this.loading" *ngIf="(this.slidesIndex >= 0 && this.slidesIndex < this.items.length - 1)" >Next</button>
    <button mat-raised-button color="primary" class="acc-button" (click)="close()" [disabled]="!!this.loading" *ngIf="(this.slidesIndex == this.items.length - 1)" >Finish</button>
  </div>
</div>