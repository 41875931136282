import { Category, SubCategory, Text, DocumentOutline, ListType, Module } from 'acc-models';

export interface ICatManageState {
	Module: Module;
	Depth: number;
	ListTypes: Array<ListType>,
	DocOutline: DocumentOutline,
	Categories: Array<Category>,
	Subcategories: Array<SubCategory>,
	Texts: Array<Text>,
	ActiveCategory: Category,
	ActiveSubcategory: SubCategory,
	ActiveText: Text,
	EditingCategory: Category,
	EditingSubcategory: SubCategory,
	EditingText: Text,
	CategoryToDelete: Category,
	SubcategoryToDelete: SubCategory,
	TextToDelete: Text
}

export const initialCatManageState:ICatManageState = {
	Module: null,
	Depth: 3,
	ListTypes: null,
	DocOutline: new DocumentOutline(),
	Categories: null,
	Subcategories: null,
	Texts: null,
	ActiveCategory: null,
	ActiveSubcategory: null,
	ActiveText: null,
	EditingCategory: null,
	EditingSubcategory:null,
	EditingText: null,
	CategoryToDelete: null,
	SubcategoryToDelete: null,
	TextToDelete: null
}