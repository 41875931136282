import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { User } from "acc-models";
import { SessionService } from "acc-services";

@Component({
	selector: "acc-main-lock-features",
	templateUrl: "./lock-features.component.html",
	styleUrls: ["./lock-features.component.scss"],
})
export class LockFeaturesComponent implements OnInit {
	constructor(private router: Router, public dialogRef: MatDialogRef<LockFeaturesComponent>, public sessionService: SessionService) {}

	public user: User;
	public isAdmin: Boolean;

	ngOnInit() {
		this.user = this.sessionService.getUser();
		this.isAdmin = this.user.admin;
	}

	// Workaround for angular component issue #13870
	disableAnimation = true;
	ngAfterViewInit(): void {
		// timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
		setTimeout(() => (this.disableAnimation = false));
	}

	close(): void {
		this.dialogRef.close(false);
	}

	upgrade(): void {
		this.router.navigate(["/admin/subscription"]);
		this.dialogRef.close(false);
	}

	scroll() {
		setTimeout(() => {
			let scrollTo = document.getElementById('features-table-scroll-to');
			let container = document.getElementById('features-dialog-content');
	
			container.scrollTop = scrollTo.offsetTop - container.offsetTop + container.scrollTop;

		}, 100);

	}
}
