<h2 *ngIf="title">{{title}}</h2>
<div class="chart-container">
	<div class="chart-container">
		<ngx-charts-bar-horizontal-2d
			[results]="data"
			[view]="barChartViewSize"
			[xAxis]="true"
			[yAxis]="true"
			[legend]="true"
			[legendPosition]="barLegendPosition"
			[customColors]="customColors"
			[legendTitle]="legendTitle"
			[roundEdges]="false"
		></ngx-charts-bar-horizontal-2d>
	</div>

</div>
