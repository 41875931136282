import { Routes, RouterModule } from "@angular/router";
import { NgModule } from '@angular/core';
import { VideosComponent } from './videos/videos.component';
import { CreateVideoComponent } from './create-video/create-video.component';
import { AuthGuard } from 'acc-services';
import { TemplateComponent } from '../components/template/template.component';
import { SubscriptionLevelGuard } from 'projects/acc-services/src/lib/guards/level.guard';
import { tooltips } from "acc-common";

const routes: Routes = [
	{
		path: 'videos',
		component: TemplateComponent,
		children: [{
			path: '',
			component: VideosComponent,
			children: [
				{path:'create-video', component: CreateVideoComponent, canActivate:[AuthGuard, SubscriptionLevelGuard ], data: { level: 2,  pageTitle: 'Create Video', tooltip: tooltips.create_video }},
			]
		}]
	},

];

@NgModule({
	imports: [ RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class VideosRoutingModule {}