import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { tooltips } from "acc-common";
import { MixpanelService } from "acc-services";
@Component({
	selector: "acc-main-signup-second",
	templateUrl: "./signup-second.component.html",
	styleUrls: ["./signup-second.component.scss"],
})
export class SignUp2Component implements OnInit {
	selectedPlan;

	plans = [
		{
			id: 0,
			nickname: "Free",
			amount: 0,
		},
		{
			id: 1,
			nickname: "Monthly",
			amount: 2500,
		},
	];

	constructor(private router: Router, private mixpanelService: MixpanelService) {}
	public tooltips = tooltips;

	ngOnInit() {
		window.scrollTo(0, 0);

		this.mixpanelService.trackSignup();
	}

	freePlan() {
		this.selectedPlan = this.plans[0];
		this.router.navigate(["/signup/3", this.selectedPlan.id]);
	}

	paidPlan() {
		this.selectedPlan = this.plans[1];
		this.router.navigate(["/signup/3", this.selectedPlan.id]);
	}
}
