import { TargetField } from './targetField';
import { CSVField } from './csvField';

export class CommDataFileInfo {

	public filePath: string;
	public CSVFields: Array<CSVField>;
	public TargetFields: Array<TargetField>;

    constructor(o?:any) {
		Object.assign(this, o);
	}
}