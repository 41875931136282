<div mat-dialog-header>
	<h2 mat-dialog-title>Personalize and Send</h2>
</div>

<div mat-dialog-content>
	<div id="personalizeDialogContent">
		<div class="form">
			<div class="checkboxContainer"> 
				<div class="checkboxHolder">
					<mat-checkbox [formControl]="personalizeForm.controls['personalize']">Personalize <mat-icon class="per-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.p_personalize_personalize}}" >info_outline</mat-icon></mat-checkbox>
				</div>
				<div class="checkboxHolder">
					<mat-checkbox [formControl]="personalizeForm.controls['clearAfterSend']" id="clearAfterSend">Clear cart after sending <mat-icon class="per-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.p_personalize_clearcart}}" >info_outline</mat-icon></mat-checkbox>
				</div>
				<div class="checkboxHolder">
					<mat-checkbox [formControl]="personalizeForm.controls['concatVideo']" id="concatVideo">Create one video <mat-icon class="per-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.p_personalize_onevideo}}" >info_outline</mat-icon></mat-checkbox>
				</div>
			</div>
			<acc-common-account-selector *ngIf="integrateCRM" (AccountSelected)="handleAccountSelected($event)" [hasCrmToken]="user.hasCRMTokenData" [value]="defaultAccount" [control]="personalizeForm.controls['accountName']" [label]="'Client Name'"></acc-common-account-selector>
			
			<acc-common-email-selector [contacts]="emailAddresses" (onListUpdated)="updateEmailList($event, 'to')" [isRequired]="true" [contactOptions]="emailOptions" [label]="'Email To'" #emailSelector></acc-common-email-selector>
			
			<acc-common-email-selector [contacts]="bccEmailAddresses" (onListUpdated)="updateEmailList($event, 'bcc')" [contactOptions]="bccEmailOptions" [label]="'Email Bcc'" #bccSelector></acc-common-email-selector>
				
			<mat-form-field appearance="outline">
				<mat-label>Subject</mat-label>
				<input matInput autocomplete="off" [formControl]="personalizeForm.controls['subject']">
				<mat-error *ngIf="personalizeForm.controls['subject'].hasError('required')">Subject is required</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="displayCredentialFields">
				<mat-label>Exchange Email</mat-label>
				<input matInput autocomplete="off" [formControl]="personalizeForm.controls['exchangeEmail']">
				<mat-error *ngIf="personalizeForm.controls['exchangeEmail'].hasError('required')">Exchange Email is required</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="displayCredentialFields">
				<mat-label>Exchange Password</mat-label>
				<input matInput autocomplete="off" [formControl]="personalizeForm.controls['exchangePassword']" type="password">
				<mat-error *ngIf="personalizeForm.controls['exchangePassword'].hasError('required')">Password is required</mat-error>
			</mat-form-field>
			<!--
			<div class="textarea" *ngIf="templates">
				<mat-form-field appearance="outline">
					<mat-label>Select Template</mat-label>
					<mat-select [formControl]="personalizeForm.controls['templateId']">
						<mat-option value=""></mat-option>
						<mat-option *ngFor="let template of templates" [value]="template.templateID">{{template.name}}</mat-option>
					</mat-select>
				</mat-form-field>
				<button mat-raised-button color="primary" (click)="getTemplatePreview()" [disabled]="personalizeForm.controls['templateId'].value == 0">Preview</button>
				<div class="spacer">&nbsp;</div>
			</div>
			-->
			<div class="textarea">
				<quill-editor [formControl]="personalizeForm.controls['message']" [style]="{minHeight:'167px'}" #quill></quill-editor>
				<mat-error *ngIf="personalizeForm.controls['message'].hasError('required') && personalizeForm.controls['message'].touched">Message is required</mat-error>
			</div>
			<div class="media-holder">
				<label>Media</label>
				<div class="medias" *ngIf="contents">
					<div *ngFor="let item of contents.LibraryItems" class="media-item">
						<h4>{{item.description}}</h4>
						<div class="img" [ngStyle]="{'background-image': getBackgroundImageUrl(item.imageFile)}">
							<a (click)="showPreview(item.id)" *ngIf="item.type == 'sync' && item.mediaSourceID == 3"></a>
							<a (click)="open(item)" *ngIf="item.type == 'sync' && item.mediaSourceID != 3"></a>
							<a (click)="generate(item)" *ngIf="item.type == 'async'"></a>
							<div class="delete-item" (click)="deleteItem(item)"></div>
							<div class="fileType" *ngIf="item.mediaFileExt">{{item.mediaFileExt.substring(1)}}</div>
						</div>
					</div>
					<div class="media-item placeholder" *ngIf="quoteId">
						<h4>LifeQuote Video</h4>
						<div class="img" [ngStyle]="{'background-image': getVideoBackgroundImage(videoUrl)}">
							<a (click)="showVideo()"></a>
							<div class="delete-item" (click)="deleteQuote()"></div>
						</div>
					</div>
				</div>
				<div><button [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.p_create_add}}" mat-raised-button color="primary" (click)="showSelectFile()">Add Media</button></div>
			</div>
			<p class="quote-video-copy" *ngIf="quoteId">LifeQuote Video is automatically included.  You can remove it if you do not wish to send it.</p>
			<p class="quote-video-copy" *ngIf="contents?.LibraryItems?.length > 0">Click icons above to preview content</p>
		</div>
	</div>
</div>

<mat-dialog-actions>
	<button mat-flat-button (click)="sendMail()" color="primary">Send</button>
	<!--<button mat-flat-button [matMenuTriggerFor]="sendAsMenu" color="primary">Send As...</button>
	<mat-menu #sendAsMenu="matMenu" [overlapTrigger]="false">
	  <button *ngFor="let p of profiles" (click)="sendMail(p.profileID)" mat-menu-item color="primary" >
		<span><a >{{p.profileName}}</a></span>
	  </button>		
	</mat-menu>-->
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>
