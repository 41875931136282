
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { DataService, SessionService, DocGenService } from 'acc-services';
import { User, Document, CrmAccount } from 'acc-models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountSelectorComponent } from '../../shared/account-selector/account-selector.component';
import { SubscriptionLike } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import { SetErrorMessage } from '../../../store/actions/status.actions';

@Component({
	selector: 'acc-main-doc-details',
	templateUrl: './doc-details.component.html',
	styleUrls: ['./doc-details.component.scss']
  })
export class DocDetailsComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild('fileInput') fileInput:ElementRef;

	@Input() public moduleID: number;
	@Input() public document:Document;
	@Input() public documentId:string;

	@Output() onDocSaved:EventEmitter<Document> = new EventEmitter();

	@ViewChild('accountSelector') accountSelector: AccountSelectorComponent;

	public user: User;
	public consultants: Array<User>;
	public account:CrmAccount;
	public accountName:string = '';
	public accounts:Array<CrmAccount> = new Array<CrmAccount>();
	public accountOptionsToShow:Array<CrmAccount> = new Array<CrmAccount>();
	public docDetails:FormGroup;
	private message: string = '';
	private getOrgUsersSubscription:SubscriptionLike;
	private updateDocumentSubscription:SubscriptionLike;
	private insertDocumentSubscription:SubscriptionLike;
	private updateDocumentImageSubscription:SubscriptionLike;
	private getAccountsSubscription:SubscriptionLike;

	constructor (private dataService:DataService, private sessionService: SessionService, private fb: FormBuilder, private docGenService:DocGenService, private store$: Store<IAppState>) {
	}
	
	ngOnInit(): void {
		this.user = this.sessionService.getUser();
		this.getOrgUsersSubscription = this.dataService.getOrgUsers(this.user.orgID, this.user.userID).subscribe((usrs:any) => {
			this.consultants = usrs;
		})
		if(!this.docDetails) {
			this.docDetails = this.fb.group({
				'docDesc': ['', Validators.required],
				'prospectName': ['', Validators.required],
				'consultant2': [''],
				'companyPhoto': [''],
				'crmAccountID': [''],
				'displayDate': ['']
			})
		}
	}


	handleAccountSelected(acct:CrmAccount):void {
		//this.accountName = acct.Name;
		//this.docDetails.controls['prospectName'].setValue(acct.Name);
		//this.docDetails.controls['crmAccountID'].setValue(acct.Id);
		///this.accountOptionsToShow.length = 0;
	}
	onFileChange(event) {
		if(event.target.files[0]) {
			this.message = `${event.target.files[0].name.split('/').reverse()[0]} will be uploaded.`;
		}
	}
	ngOnChanges(changes:SimpleChanges):void {
		if(changes['document'] && changes['document'].currentValue) {
			let doc:Document = changes['document'].currentValue;
			this.docDetails = this.fb.group({
				'docDesc': [doc.docDesc, Validators.required],
				'prospectName': [doc.prospectName, Validators.required],
				'companyPhoto': [''],
				'consultant2': [doc.consultant2],
				'crmAccountID': [doc.crmAccountID],
				'displayDate': [doc.displayDate]
			})
			this.docDetails.controls['consultant2'].setValue((this.docDetails.controls['consultant2'].value || ''), {onlySelf: true});
			this.accountName = doc.prospectName;
			this.account = new CrmAccount({ Name:doc.prospectName, Id: doc.crmAccountID });
		}
	}

	save():void {
		if(!this.accountSelector.isClear) {
			this.docDetails.patchValue({prospectName: this.accountSelector.value.Name, crmAccountID: this.accountSelector.value.Id});
		}
		Object.keys(this.docDetails.controls).forEach(element => {
			this.docDetails.controls[element].markAsTouched();
		});
		if(this.docDetails.valid) {
			if(this.document) {
				this.document.docDesc = this.docDetails.get('docDesc').value;
				this.document.prospectName = this.accountSelector.value.Name;
				this.document.consultant2 = this.docDetails.get('consultant2').value;
				this.document.crmAccountID = this.accountSelector.value.Id;
				this.document.displayDate = this.docDetails.get('displayDate').value;
				this.updateDocumentSubscription = this.dataService.updateDocument(this.document).subscribe(resp => {
					this.saveImage()
				});
			} else {
				this.insertDocumentSubscription = this.dataService.insertDocument(this.sessionService.getUser().userID, this.moduleID).subscribe((doc:any) => {
					this.document = doc;
					this.docGenService.setCurrentDocument(doc);
					this.document.docDesc = this.docDetails.get('docDesc').value;
					this.document.prospectName = this.accountSelector.value.Name;
					this.document.crmAccountID = this.accountSelector.value.Id;
					this.document.consultant2 = this.docDetails.get('consultant2').value;
					this.document.displayDate = this.docDetails.get('displayDate').value;
					this.updateDocumentSubscription = this.dataService.updateDocument(this.document).subscribe(resp => {
						this.saveImage();
					});
			
				})
			}
		}
	}

	removeImage() {
		
	}
	saveImage() {
		if(this.fileInput.nativeElement.value != '') {
			let fileName:string = this.fileInput.nativeElement.files[0].name;
			let ext:string = fileName.toLowerCase().split('.').reverse()[0];
			if(['jpg', 'jpeg', 'png'].indexOf(ext) > -1) {
				this.updateDocumentImageSubscription = this.dataService.uploadDocImage(this.document.docID, this.fileInput.nativeElement.files[0]).subscribe((resp:any) => {
					this.document.prospectLogoFile = resp.imagePath;
					this.onDocSaved.emit(this.document);
				})
			} else {
				this.store$.dispatch(new SetErrorMessage('Photo must be .jpg, .jpeg, or .png'));
				this.message = 'Photo must be .jpg, .jpeg, or .png';
			}
		} else {
			this.onDocSaved.emit(this.document);
		}

	}

	removeLogo():void {
		let remDocImageSubscription = this.dataService.removeDocImage(this.document.docID).subscribe(resp => {
			this.document.prospectLogoFile = '';
			remDocImageSubscription.unsubscribe();
		})
	}

	ngOnDestroy():void {
		if(this.getOrgUsersSubscription) { this.getOrgUsersSubscription.unsubscribe(); }
		if(this.updateDocumentSubscription) { this.updateDocumentSubscription.unsubscribe(); }
		if(this.insertDocumentSubscription) { this.insertDocumentSubscription.unsubscribe(); }
		if(this.updateDocumentImageSubscription) { this.updateDocumentImageSubscription.unsubscribe(); }
		if(this.getAccountsSubscription) { this.getAccountsSubscription.unsubscribe(); }
	}
}
