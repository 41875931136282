<main class="lists-container">
	<mat-horizontal-stepper linear="true" #stepper >
		<!--Step 1 -->
		<mat-step label="Select your programs" editable="true" >
			<acc-main-programs-selection [mktPrograms]="mktPrograms" (programsLoaded)="setOriginalProgramsState($event)"></acc-main-programs-selection>
			<button mat-raised-button color="primary" style="margin-left: 20px" (click)="saveSelectingPrograms()">Save</button>
			<button mat-raised-button color="primary" style="margin-left: 20px" (click)="finishSelectingPrograms()">Next: Update Company Info</button>
		</mat-step>
		<!--Step 2 -->
		<mat-step label="Update Company Info" editable="true">
			<acc-main-org-profile [orgID]="orgID" #orgProfile (profileLoaded)="setOriginalProfile($event)">
				<div #companyInfo>
					<mat-form-field>
						<mat-label>Company Name</mat-label>
						<input matInput type="text" [(ngModel)]="companyName" />
					</mat-form-field>

					<div class="company__logo">
						<acc-flow-profile-photo type="Logo" [tooltip]="tooltips.company_logo_signup" [photo]="logo" [buttonText]="'Upload Logo'" [defaultTitle]="'Company Logo'" [cssClass]="'signup signup-logo'" #logoPhoto [accept]="imageFileTypes" [errorMessage]="'File must be an image'"></acc-flow-profile-photo>
					</div>
				</div>
			</acc-main-org-profile>
			<button mat-raised-button (click)="saveProfile()" color="primary" style="margin-left: 20px">Save</button>
			<button mat-raised-button matStepperPrevious color="primary" style="margin-left: 20px">Back: Select your programs</button>
			<button mat-raised-button (click)="finishProfile()" color="primary" style="margin-left: 20px">Next: Import Contacts</button>
		</mat-step>
		<mat-step label="Upload your data" editable="true">
			<acc-main-contacts-import (onContactsImported)="backToFirstStep($event)"></acc-main-contacts-import>
			<button mat-raised-button matStepperPrevious color="primary" style="margin-right: 20px">Back: Update Company Info</button>
		</mat-step>
	</mat-horizontal-stepper>
</main>
