<form autocomplete="off">
	<div *ngIf="!linkSent">
		<h5>Enter your email address to receive an email with instructions on how to reset your password</h5>
		<ul>
			<li>
				<mat-form-field appearance="outline">
					<mat-label>Email Address</mat-label>
					<input matInput [formControl]="emailField">
					<mat-icon matSuffix>mail</mat-icon>
					<mat-error *ngIf="emailField.hasError('required')">Email is required</mat-error>
				</mat-form-field>
			</li>
			<li><button (click)="sendLink()" mat-flat-button color="primary" >Send Link</button></li>
		</ul>
		<p><a (click)="showLoginForm()">Return to Login Page</a></p>
	</div>

	<div *ngIf="linkSent">
		<p>If there is an account for <strong>{{emailField.value}}</strong> in our database, you will receive an email with a link to reset your password.</p>
		<p><a (click)="showLoginForm()">Return to Login Page</a></p>
	</div>
</form>
