import { User, Profile } from "acc-models";

export interface IUserState {
	orgId: number;
	users: Array<User>;
	activeUser: User | null;
	profile: Profile | null;
	userToDelete: User;
	profilePhotoToUpload: any;
	profilePhoto: string;
	addSeatDialog: boolean;
}

export const initialUserState: IUserState = {
	orgId: 0,
	users: new Array<User>(),
	activeUser: null,
	profile: null,
	userToDelete: null,
	profilePhotoToUpload: null,
	profilePhoto: null,
	addSeatDialog: false,
};
