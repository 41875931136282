import { Mapping } from './mapping';

export class CommDataFileMap {

    public listID: string;
	public filePath: string;
	public Mappings: Array<Mapping>;

    constructor(o?:any) {
		Object.assign(this, o);
	}
}