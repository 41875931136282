<div mat-dialog-header>
	<h2 mat-dialog-title>Change Password</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
			<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
		<ul>
			<li class="password">
				<mat-form-field appearance="outline">
					<mat-label>Old Password</mat-label>
					<input matInput [formControl]="oldPasswordField" type="password">
					<mat-error *ngIf="oldPasswordField.hasError('required')">Old password is required</mat-error>
				</mat-form-field>
			</li>
			<li class="password">
				<mat-form-field appearance="outline">
					<mat-label>New Password</mat-label>
					<input matInput [formControl]="newPasswordField" type="password" placeholder="Min 8 char. One upper, lower, number, special char.">
					<mat-error *ngIf="newPasswordField.hasError('required')">New password is required</mat-error>
				</mat-form-field>
			</li>
			<li class="password">
				<mat-form-field appearance="outline">
					<mat-label>Confirm Password</mat-label>
					<input matInput [formControl]="confirmPasswordField" type="password">
					<mat-error *ngIf="confirmPasswordField.hasError('required')">Confirm password is required</mat-error>
					<mat-error *ngIf="confirmPasswordField.hasError('mustMatch')">Passwords do not match</mat-error>
				</mat-form-field>
			</li>
		</ul>
	</div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="changePasswordForm">
	<button mat-raised-button color="primary" (click)="changePassword()" [disabled]="!changePasswordForm.valid">Change Password</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>

