import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MixpanelService } from 'acc-services';

@Component({
  selector: 'acc-main-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login/login.component.scss', './reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

	@Output() onNavigateTo:EventEmitter<string> = new EventEmitter();
	@Output() onReset:EventEmitter<boolean> = new EventEmitter();
	
	constructor(private mixpanelService: MixpanelService) { }

	ngOnInit() {
		this.mixpanelService.trackPageView();
	}

	navigateTo(page: string): void {
		this.onNavigateTo.next(page);
	}
	reset(success: boolean): void {
		this.onReset.next(success);
	}
}
