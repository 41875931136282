<mat-card class="filter-card">
	<mat-card-content>
		<mat-form-field >
			<mat-select  [formControl]="fieldNameField" placeholder="Field Name">
				<mat-option *ngFor="let field of fields" [value]="field.fieldName">
					{{field.fieldName}} 
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field >
			<mat-select  [formControl]="operatorField" placeholder="Operator">
				<mat-option *ngFor="let o of operators" [value]="o.value">
					{{o.name}} 
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field >
			<input matInput type="text" [formControl]="valueField" placeholder="Value" />
		</mat-form-field>
	</mat-card-content>
	<mat-card-actions>
		<button mat-raised-button class="remove-button" (click)="removeFilter()">Remove</button>
	</mat-card-actions>
</mat-card>
