import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SessionService } from 'acc-services';
import { Store, select } from '@ngrx/store';
import { CatManageActionTypes } from '../../../store/actions/catmanage.actions';
import * as CatManageActions from '../../../store/actions/catmanage.actions';
import { Subject } from 'rxjs';
import { Category, SubCategory, Button, Text, DocumentOutline, ListType } from 'acc-models';
import * as CatManageSelectors from '../../../store/selectors/catmanage.selectors';
import { tap, take } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Actions } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { IAppState } from '../../../store/state/app.state';
import { ISaveChanges } from '../../../interfaces/save-changes.interface';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ConfirmComponent } from 'acc-common';

@Component({
	selector: 'acc-main-edit-outline',
	templateUrl: './edit-outline.component.html',
	styleUrls: ['./edit-outline.component.scss']
})
export class EditOutlineComponent implements OnInit, OnDestroy, ISaveChanges {

	@ViewChild('outlineDepthSelect') outlineDeptSelect: MatSelect;
	public categories: Array<Category>;
	public editingCategory: Category;
	public categoryToDelete: Category;
	public activeCategory: Category;
	public newCategoryName: string;
	public subcategories: Array<SubCategory>;
	public editingSubcategory:SubCategory;
	public subcategoryToDelete: SubCategory;
	public activeSubcategory: SubCategory;
	public newSubcategoryName: string;
	public texts: Array<Text>;
	public activeText:Text;
	public editingTextCopy: Text;
	public textToDelete: Text;
	public newTextCopy: string;
	public docOutline: DocumentOutline;
	public listTypes: Array<ListType>
	public currentDepth: number = 999;
	public confirmTextChange: boolean = false;
	private confirmDialogRef: MatDialogRef<ConfirmComponent>;

	private savedNewText: any = {};

	private documentId: string;
	private moduleId: number;
	
	public confirmDeleteCategoryMessage: string = '';
	public confirmDeleteSubcategoryMessage: string = '';
	public confirmDeleteTextMessage: string = '';
	public loweringDepthMessage: string = '';
	public loweringDepth: number = 0;

	private confirmDeleteButtons: Array<Button> = new Array<Button>();

	private destroy$:Subject<number> = new Subject();
	public canNavigateAway:Subject<boolean> = new Subject();

	public get dirty() { return !!this.newTextCopy; }

	public documentOutline$ = this.store.pipe(
		select(CatManageSelectors.selectDocOutline),
		tap(o => { this.docOutline = o; this.currentDepth = o.outlineDepth; })
	)
	public listTypes$ = this.store.pipe(
		select(CatManageSelectors.selectListTypes),
		tap(t => this.listTypes = t)
	)
	public categories$ = this.store.pipe(
		select(CatManageSelectors.selectCategories),
		tap(c => this.categories = c)
	)
	public subcategories$ = this.store.pipe(
		select(CatManageSelectors.selectSubcategories),
		tap(s => this.subcategories = s)
	)
	public texts$ = this.store.pipe(
		select(CatManageSelectors.selectSubcatTexts),
		tap(t => this.texts = t)
	)
	public editingCategory$ = this.store.pipe(
		select(CatManageSelectors.selectEditingCategory),
		tap(c => this.editingCategory = c)
	).subscribe()
	public editingSubcategory$ = this.store.pipe(
		select(CatManageSelectors.selectEditingSubcategory),
		tap(s => this.editingSubcategory = s)
	).subscribe()
	public categoryToDelete$ = this.store.pipe(
		select(CatManageSelectors.selectCategoryToDelete),
		tap(c => {
			this.categoryToDelete = c;
			if(c) {
				this.confirmDeleteCategoryMessage = `<p>Are you sure you want to delete the category <b>${c.categoryDesc}?`
			}
		})
	).subscribe()
	public subcategoryToDelete$ = this.store.pipe(
		select(CatManageSelectors.selectSubcategoryToDelete),
		tap(s => {
			this.subcategoryToDelete = s;
			if(s) {
				this.confirmDeleteSubcategoryMessage = `<p>Are you sure you want to delete the subcategory <b>${s.subCategoryDesc}?`
			}
		})
	).subscribe()
	public editingText$ = this.store.pipe(
		select(CatManageSelectors.selectEditingSubcatText),
		tap(t => this.editingTextCopy = new Text(t))
	).subscribe();
	public textToDelete$ = this.store.pipe(
		select(CatManageSelectors.selectSubcatTextToDelete),
		tap(s => {
			this.textToDelete = s;
			if(s) {
				this.confirmDeleteTextMessage = `<p>Are you sure you want to delete this text?`
			}
		})
	).subscribe()
	public activeCategory$ = this.store.pipe(
		select(CatManageSelectors.selectActiveCategory),
		tap(c => this.activeCategory = c)
	).subscribe()
	public activeSubcategory$ = this.store.pipe(
		select(CatManageSelectors.selectActiveSubcategory),
		tap(c => {
			this.activeSubcategory = c;
			if(c && this.savedNewText['subcat'+c.subCategoryID]) {
				this.newTextCopy = this.savedNewText['subcat'+c.subCategoryID];
				this.savedNewText['subcat'+c.subCategoryID] = null;
			} else {
				this.newTextCopy = '';
			}
		})
	).subscribe()
	public module$ = this.store.pipe(
		select(CatManageSelectors.selectModule)
	)
	constructor(private store:Store<IAppState> ,private sessionService:SessionService, private actions$:Actions, private route: ActivatedRoute, private router:Router, private dialog: MatDialog) {
		this.confirmDeleteButtons.push(new Button("Yes", ""));
		this.confirmDeleteButtons.push(new Button("No", ""));

		this.route.paramMap.subscribe(params => {
			this.moduleId = +params.get('moduleID');
			this.documentId = params.get('documentID');
		});

		actions$
		.pipe(
			tap((a) => {
			switch(a.type) {
				case CatManageActionTypes.ADD_CATEGORY_SUCCESS:
					this.newCategoryName = '';
					break;
				case CatManageActionTypes.ADD_SUBCATEGORY_SUCCESS:
					this.newSubcategoryName = '';
					break;
				case CatManageActionTypes.ADD_SUBCAT_TEXT_SUCCESS:
					this.newTextCopy = '';
					this.canNavigateAway.next(true);
					break;
				case CatManageActionTypes.GET_CATEGORIES_SUCCESS:
					//special case: if depth is 1, need to get texts	
					if(this.docOutline.outlineDepth == 1) {
						this.store.dispatch(new CatManageActions.GetSubcatTexts())
					}
			}
		}))
		.subscribe();

	}

    ngOnInit():void {
		const user = this.sessionService.getUser();
		this.store.dispatch(new CatManageActions.GetDocumentOutline(this.documentId));
		this.store.dispatch(new CatManageActions.GetCategories(this.moduleId, user.userID, this.documentId));
		this.store.dispatch(new CatManageActions.GetListTypes());
		this.store.dispatch(new CatManageActions.GetModule(this.moduleId));
	}

	ngOnDestroy():void {
		this.destroy$.next(0);
		this.destroy$.complete();
	}

	updateOutline(field, elem) {
		if(field == 'outlineDepth') {
			if(+elem.value < this.docOutline.outlineDepth && this.categories.length > 0) {
				this.loweringDepth = +elem.value;
				this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: 'Confirm',
						message: `By lowering the outline depth to ${elem.value}, you will be losing your category values.  Do you want to continue?`
					}
				});
				this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
					if(confirm) {
						this.store.dispatch(new CatManageActions.SetOutlineDepth(this.loweringDepth));
						this.store.dispatch(new CatManageActions.UpdateOutline(new DocumentOutline({ ...this.docOutline, outlineDepth: this.loweringDepth })));
					} else {
						this.outlineDeptSelect.value = this.docOutline.outlineDepth;
					}
					this.loweringDepth = 0;
				})
			} else {
				this.store.dispatch(new CatManageActions.SetOutlineDepth(+elem.value));
			}
		} else {
			let o = {};
			o[field] = +elem.value;
			this.store.dispatch(new CatManageActions.UpdateOutline(new DocumentOutline({ ...this.docOutline, ...o })));
		}

	}
	updatePageBreak(field, elem) {
		let o = {};
		o[field] = elem.target.checked;
		if(field == 'subCategoryDivider' && elem.target.checked) {
			//if subcateory page break is selected, the category page break needs to be selected too
			o['categoryDivider'] = true;
		}
		this.store.dispatch(new CatManageActions.UpdateOutline(new DocumentOutline({ ...this.docOutline, ...o })));

	}

	selectCategoryToEdit(cat) {
		this.store.dispatch(new CatManageActions.EditCategory(cat));
	}
	saveCategoryEdit(cat) {
		this.store.dispatch(new CatManageActions.EditCategorySave(cat));
	}
	deleteCategory(cat) {
		this.store.dispatch(new CatManageActions.DeleteCategory(cat));
		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `<p>Are you sure you want to delete the category <b>${cat.categoryDesc}?`
			}
		});
		this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new CatManageActions.DeleteCategoryConfirm());
			} else {
				this.store.dispatch(new CatManageActions.DeleteCategoryCancel());
			}
		})
	}
	addCategory() {
		if(this.newCategoryName) {
			this.store.dispatch(new CatManageActions.AddCategory(this.moduleId, new Category({ categoryDesc: this.newCategoryName, SubCategories: new Array<SubCategory>() })))
		}
	}
	selectSubcategoryToEdit(subcat) {
		this.store.dispatch(new CatManageActions.EditSubcategory(subcat));
	}
	saveSubcategoryEdit(subcat) {
		this.store.dispatch(new CatManageActions.EditSubcategorySave(subcat));
	}
	deleteSubcategory(subcat) {
		this.store.dispatch(new CatManageActions.DeleteSubcategory(subcat));
		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `<p>Are you sure you want to delete the subcategory <b>${subcat.subCategoryDesc}?`
			}
		});
		this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new CatManageActions.DeleteSubcategoryConfirm());
			} else {
				this.store.dispatch(new CatManageActions.DeleteSubcategoryCancel());
			}
		})
	}
	addSubcategory() {
		if(this.newSubcategoryName) {
			this.store.dispatch(new CatManageActions.AddSubcategory(new SubCategory({ subCategoryDesc: this.newSubcategoryName, categoryID: this.activeCategory.categoryID })))
		}
	}
	activateCategory(cat:Category) {
		if(this.newTextCopy && this.activeSubcategory) {
			this.savedNewText['subcat'+this.activeSubcategory.subCategoryID] = this.newTextCopy;
		}
		this.store.dispatch(new CatManageActions.ActivateCategory(cat));
	}
	activateSubcategory(sub:SubCategory) {
		if(this.newTextCopy) {
			this.savedNewText['subcat'+this.activeSubcategory.subCategoryID] = this.newTextCopy;
		}
		this.store.dispatch(new CatManageActions.ActivateSubcategory(sub));
	}
	selectTextToEdit(text) {
		this.editingTextCopy = text.defaultText;
		this.store.dispatch(new CatManageActions.EditSubcatText(text));
	}
	saveTextEdit(text:Text) {
		if(text.defaultText != this.editingTextCopy.defaultText) {
			this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
				data: {
					title: 'Confirm',
					message: 'Modifying content will overwrite any detailed responses entered in the document builder.  Continue?'
				}
			});
			this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
				if(confirm) {
					this.store.dispatch(new CatManageActions.EditSubcatTextConfirm(this.editingTextCopy));
					this.editingTextCopy = null;
				} else {
					this.store.dispatch(new CatManageActions.EditSubcatTextCancel());
				}
			})
		} else {
			this.store.dispatch(new CatManageActions.EditSubcatTextCancel());
		}
		
	}
	deleteText(text) {
		this.store.dispatch(new CatManageActions.DeleteSubcatText(text));
		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm',
				message: `<p>Are you sure you want to delete this text?`
			}
		});
		this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store.dispatch(new CatManageActions.DeleteSubcatTextConfirm());
			} else {
				this.store.dispatch(new CatManageActions.DeleteSubcatTextCancel());
			}
		})
	}
	addSubcatText() {
		if(this.newTextCopy) {
			this.store.dispatch(new CatManageActions.AddSubcatText(new Text({ defaultText: this.newTextCopy, subCategoryID: this.activeSubcategory.subCategoryID, revisionNeededDate: null })))
		}
	}

	dropCategory(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
		const newOrder = this.categories.map((c,i) => { return { categoryID: c.categoryID, categoryDesc: c.categoryDesc, sortOrder:  i + 1 }; });
		this.store.dispatch(new CatManageActions.SaveCategories(newOrder));
	}
	dropSubcategory(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.subcategories, event.previousIndex, event.currentIndex);
		const newOrder = this.subcategories.map((s,i) => { return { categoryID: s.categoryID, subCategoryID: s.subCategoryID, subCategoryDesc: s.subCategoryDesc, sortOrder:  i + 1 }; });
		this.store.dispatch(new CatManageActions.SaveSubcategories(newOrder));
	}
	dropText(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.texts, event.previousIndex, event.currentIndex);
		const newOrder = this.texts.map((t,i) => { return { textID: t.textID, defaultText: t.defaultText, subcategoryID: t.subCategoryID, sortOrder:  i + 1 }; });
		this.store.dispatch(new CatManageActions.UpdateSubcatTexts(newOrder));
	}

	return() {
		this.router.navigate(['/module', this.moduleId, this.documentId, 'edit']);
	}

	checkEnter(e, section: string) {
		if(e.keyCode == 13) {
			switch (section) {
				case 'cat': 
					this.addCategory(); break;
				case 'subcat':
					this.addSubcategory(); break;
			}
		}
	}

	
	saveChangesBeforeNavigation() {
		this.addSubcatText();
	}

	compareValues(a,b) {
		return String(a).trim() === String(b).trim();
	}
}
