import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import { ClearErrorMessage } from '../../../store/actions/status.actions';

@Component({
  selector: 'acc-main-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

	@Input() title: string = 'Oops! Something went wrong';
  @Input() message: string;
  @Input() cssClass: string = '';

  constructor(private store:Store<IAppState>) { }

  ngOnInit() {
  }

  close() {
	this.store.dispatch(new ClearErrorMessage())
  }
}
