<mat-card>
	<mat-card-header>
		<mat-card-title>{{text.categoryDesc}}</mat-card-title>
		<mat-card-subtitle>{{text.subCategoryDesc}}</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<p class="cardContent">{{text.defaultText}}</p>
		<div class="revDateField" *ngIf="(editingText$ | async)?.textID == text.textID; else nonEdit">
			<div class="field">
				<mat-form-field>
					<input matInput [matDatepicker]="picker" [(ngModel)]="text.revisionNeededDate">
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-label>Revision Needed Date</mat-label>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
			</div>
		</div>
		<ng-template #nonEdit>
			<div class="revDate {{text.status}}">Revision Needed:</div>
			<div class="revDate {{text.status}}">{{text.revisionNeededDate | date:"longDate"}}</div>
		</ng-template>
	</mat-card-content>
	<mat-card-actions *ngIf="(editingText$ | async)?.textID == text.textID; else nonEditActions">
		<button mat-flat-button color="primary" (click)="save()">Save</button>
		<button mat-stroked-button (click)="cancel()">Cancel</button>
	</mat-card-actions>
	<ng-template #nonEditActions>
		<mat-card-actions>
			<button mat-stroked-button (click)="edit()">Edit</button>
		</mat-card-actions>
	</ng-template>
</mat-card>
