import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { environment } from '../../../../acc-main/src/environments/environment'; //not sure if it's ok to get it from here, what about acc-flow?
import { 
	User, Profile, Document, ListType, DocumentOutline, ModuleField, EditableCategory, CrmContact, Organization, Module,
	Category, SubCategory, SavedCart, Media, Text, Folder, LibraryText, LibraryTextCategory, ProfilePhoto, DocumentLayout, ProfilePartner, Team, CommList, Mapping, CommCampaignDetail, CommCampaign, CustVideoRequest, CustVideoRequestParm, ProposalTemplate, DocBuilder, DocBuilderItem, OrganizationProfile  
} from 'acc-models';
import { of, empty, throwError, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpRequest, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap, switchMap, catchError, takeUntil, take } from 'rxjs/operators';
import { NgProgress } from '@ngx-progressbar/core';
import { SignupInput, SignupCompany } from 'projects/acc-models/src/lib/models/signUp';
import * as moment from 'moment';
import { CommGroup } from 'projects/acc-models/src/lib/models/commGroup';

@Injectable()
export class DataService {
	constructor(private http:HttpClient, private ngProgress: NgProgress) {}
	
	private API_ROOT = environment.api_url;
	//private API_ROOT = 'http://localhost:43392/api/';
	
	private cancelUploadSubject:Subject<boolean> = new Subject();
	private cancelUpload$: Observable<boolean> = this.cancelUploadSubject.asObservable();

    private getHeaders(hdrs?: any):any {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');  
		if(hdrs) {
			Object.keys(hdrs).forEach(h => {
				headers = headers.set(h, String(hdrs[h]));
			});
		}
		return {headers: headers};
	}

	//TODO: replace this with actual api call
	getSettings(orgId: number) {
		return this.http.get(`/assets/data/settings${orgId}.json?rnd${Math.random()}`).pipe(
			catchError(() => {
				return this.http.get(`/assets/data/settings.json?rnd${Math.random()}`)
			})
		);
	}
	
	GetOrgFromID(orgId:number) {
		return this.http.get<Organization>(`${this.API_ROOT}/auth2/org?orgId=${orgId}`)
	}
    
	getOrg(resource:string) {
		return this.http.get<Organization>(`${this.API_ROOT}/auth2/org?resource=${resource}`)
	}
    
	getOrgProfileFromID(orgId:number) {
		return this.http.get<Organization>(`${this.API_ROOT}/auth2/org/profile?orgId=${orgId}`)
	}

	saveOrgProfile(orgProfile: OrganizationProfile) {
		return this.http.post(`${this.API_ROOT}auth2/org/profile`, orgProfile ,this.getHeaders())
	}

    login(orgID: number, username: string, password: string):Observable<any> {
		return this.http.post(`${this.API_ROOT}auth2/login`, {orgID: orgID, email: username, password: password},this.getHeaders())
	}

	sendRecoverLink(orgID: number, email: string, url: string) {
		return this.http.post(`${this.API_ROOT}auth2/recoverylink?orgID=${orgID}&email=${email}&recoveryURL=${url}`, {orgID: orgID, email: email, recoveryURL: url},this.getHeaders())
	}

	validateRecoveryToken(recoveryKeyID:string) {
		return this.http.get(`${this.API_ROOT}auth2/recoverykey/${recoveryKeyID}`, this.getHeaders())
	}

	resetPassword(token: string, newPassword: string) {
		return this.http.post(`${this.API_ROOT}auth2/passwordfromrecoverykey`, {recoveryKeyID: token, newPassword: newPassword},this.getHeaders())
	}

	getOrgUsers(orgId:number, userId: string) {
		return this.http.get(`${this.API_ROOT}users?orgId=${orgId}&userId=${userId}`, this.getHeaders({ noloader: true }))
	}
	getModules(orgID: number) {
		return this.http.get<Array<Module>>(`${this.API_ROOT}docgen/GetModules?orgID=${orgID}`, this.getHeaders())
	}
	
	///Welcome blocks
	getRecentLifeQuoteQuoteData(userID: string, limit: number) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/recent?userID=${userID}&topValues=${limit}`, this.getHeaders({ noloader: true })).pipe(
			map((resp:any) => resp.Quotes.map(i => { return { url: '/quote/results/' + i.quoteID, text: `${i.clientName} last edited ${moment(i.dateEdited).format('MM/DD/YYYY')}`, icon: i.sourceID == 1 ? '' : 'supervisor_account', icon_title: 'Self Service Quote' } }))
		)
	}

	getRecentLifeQuoteAppData(userID: string, limit: number) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/recent?userID=${userID}&topValues=${limit}`, this.getHeaders({ noloader: true })).pipe(
			map((resp:any) => resp.Applications.map(i => { return { text: `${i.clientName} applied ${moment(i.historyDate).format('MM/DD/YYYY')}`}} ))
		)
	}

	getNewLifeQuoteQuoteViews(orgId: number) {
		return timer(4000).pipe(
			take(1),
			switchMap(() => of(['Dave Jones', 'Chris Johnson', 'John Paulson']))
		)
	}
	getNewLifeQuoteAppViews(orgId: number) {
		return timer(3000).pipe(
			take(1),
			switchMap(() => of(['Paul Johnson', 'Rick Davis']))
		)
	}

	getLatestQuotes(orgId: number) {
		return timer(2000).pipe(
			take(1),
			switchMap(() => of([{quoteID: '2E763A48-3D9A-4165-AA82-4AD45CF00A09', name: 'Steve Eckert'}, {quoteID: '433DC9EC-23EE-43E3-9DA7-FF5530FD9003', name: 'Paul Johnson'}, {quoteID: '90A2E78C-4476-465D-9D10-5BD0CD89C0D1', name: 'Dave Stephens'}])),
			map(resp => resp.map(i => { return { url: '/quote/results/' + i.quoteID, text: i.name } }))
		)
	}



	///End Welcome Blocks
	getProfile(userID: string) {
		return this.http.get<Profile>(`${this.API_ROOT}users/${userID}/profile`, this.getHeaders())
	}
	
	getProfileDetail(profileID: string) {
		return this.http.get<Profile>(`${this.API_ROOT}users/profile/${profileID}`, this.getHeaders())
	}
	
	getProfiles(userID: string) {
		return this.http.get<Profile>(`${this.API_ROOT}users/${userID}/profiles`, this.getHeaders())
	}

	//Users Profile as observable
	private profileSubject = new BehaviorSubject([]);

	getUserProfiles(): Observable<Profile[]> {
		return this.profileSubject.asObservable();
	}

	refreshUsersProfiles(userID: string) {
        this.getProfiles(userID).subscribe((result:any) => {
		    this.profileSubject.next(result);
		});
	}
	//END - Users Profile as observable

	//Users Profile as observable
	private activeProfileSubject = new BehaviorSubject({});

	getActiveProfile(): Observable<any> {
		return this.activeProfileSubject.asObservable();
	}

	refreshActiveProfileData(profileID: string) {
		var profileInfo = {
			profileID: profileID,
		};
		this.activeProfileSubject.next(profileInfo);
	}
	//END - Users Profile as observable

	setActiveProfile(userID: string, profileID: string){
		return this.http.put(`${this.API_ROOT}users/${userID}/profile/active/${profileID}`, {}, this.getHeaders())
	}
	
	deleteProfile(profileID: string){
		return this.http.delete(`${this.API_ROOT}users/profile/${profileID}`, this.getHeaders());
	}

    saveProfile(profile:Profile):Observable<any> {
        return this.http.post(`${this.API_ROOT}users/profile`, profile, this.getHeaders())
	}
	createUser(user: User) {
		return this.http.post(`${this.API_ROOT}users`, { User: user, DocGenProfile: new Profile() })
	}

	convertUser(user: User) {
		return this.http.post(`${this.API_ROOT}users/convert`, user)
	}
	
	changePassword(userId:string, oldPassword:string, newPassword:string) {
        return this.http.put(`${this.API_ROOT}auth2/passwordreset`, {userID: userId, oldPassword:oldPassword, newPassword:newPassword}, this.getHeaders())
	}

	getCategoriesForModule(moduleID: number, userID:string, docId?:string) {
		return this.http.get<Array<Category>>(`${this.API_ROOT}docgen/GetCategoriesAll?moduleID=${moduleID}&userID=${userID}${docId ? '&docID=' + docId : ''}`, this.getHeaders())
	}
	getDocument(docId:string) {
		return this.http.get<Document>(`${this.API_ROOT}docgen/getDoc?docID=${docId}`, this.getHeaders())
	}
	insertDocument(userId:string, moduleId: number) {
		return this.http.post(`${this.API_ROOT}docgen/InsertDoc?userID=${userId}&moduleID=${moduleId}`, {userID: userId, moduleID: moduleId},this.getHeaders())
	}
	updateDocument(document:Document) {
		return this.http.put(`${this.API_ROOT}docgen/UpdateDoc`, JSON.stringify(document),{ headers: { 'Content-Type': 'application/json' } })
	}
	getDocumentLayout(docId: string) {
		return this.http.get(`${this.API_ROOT}docgenlayout/${docId}`, this.getHeaders());
	}
	saveDocumentLayout(docLayout: DocumentLayout) {
		return this.http.post(`${this.API_ROOT}docgenlayout`, docLayout, this.getHeaders());
	}

	
	uploadDocImage(docId:string, fileItem:File) {
		const formData: FormData = new FormData();
		formData.append('', fileItem, fileItem.name);
		const myHeaders = new Headers();  
		myHeaders.append('enctype', 'multipart/form-data');
		myHeaders.append('Accept', 'application/json');
		return this.http.post(`${this.API_ROOT}docgen/UploadDocImage?docID=${docId}`, formData)
	}
	removeDocImage(docId:string) {
		return this.http.delete(`${this.API_ROOT}docgen/deletedocimage?docId=${docId}`, this.getHeaders())
	}
	
	getText(docId: string, subCategoryId: number) {
		return this.http.get(`${this.API_ROOT}docgen/GetText?docID=${docId}&subCategoryID=${subCategoryId}`, this.getHeaders())
	}
	saveText(field:ModuleField, docID: string) {
        return this.http.put(`${this.API_ROOT}docgen/SaveText?docID=${docID}`, field, this.getHeaders())
	}
	getTextAttachments(docId: string, textId: number) {
		return this.http.get(`${this.API_ROOT}docgen/GetTextAttachList?docID=${docId}&textID=${textId}`, this.getHeaders())
		
	}
	getTextAttachmentsMedia(docId: string, textId: number) {
		return this.http.get(`${this.API_ROOT}docgen/GetTextAttachListMedia?docID=${docId}&textID=${textId}`, this.getHeaders())
		
	}
	deleteAttachment(attachId:string) {
		return this.http.delete(`${this.API_ROOT}docgen/DeleteTextAttach?attachmentID=${attachId}`)
	}

	uploadAttachment(fileItem:File,  docId: string, textId: number, placementTypeId:number) {
		const formData: FormData = new FormData();
		formData.append('', fileItem, fileItem.name);
		const myHeaders = new Headers();  
		myHeaders.append('enctype', 'multipart/form-data');
		myHeaders.append('Accept', 'application/json');
		const req = new HttpRequest('POST', `${this.API_ROOT}docgen/UploadTextAttach?docID=${docId}&textID=${textId}&placementTypeId=${placementTypeId}`, formData, { reportProgress: true })
		return this.sendWithUpdates(req);

	}

	attachMedia(docId: string, textId: number, mediaId:string, mediaSourceId:number, placementTypeId:number) {
		return this.http.post(`${this.API_ROOT}docgen/InsertTextAttachMedia?docID=${docId}&textId=${textId}&mediaId=${mediaId}&mediaSourceId=${mediaSourceId}&placementTypeId=${placementTypeId}`, {docID: docId, textId: textId, mediaId: mediaId, mediaSourceId:mediaSourceId, placementTypeId:placementTypeId },this.getHeaders())
	}
	deleteAttachmentMedia(attachmentId:string) {
		return this.http.delete(`${this.API_ROOT}docgen/DeleteTextAttachMedia?attachmentID=${attachmentId}`)
	}
	updateAttachmentPlacement(attachmentId:string, placementTypeId:number, imgSizeId: number, label: string) {
		return this.http.put(`${this.API_ROOT}docgen/UpdateTextAttach?attachmentId=${attachmentId}&placementTypeId=${placementTypeId}&imgSizeID=${imgSizeId}&attachmentLabel=${label}`, null, this.getHeaders({ noloader: true }))
	}
	updateAttachmentPlacementMedia(attachmentId:string, placementTypeId:number, imgSizeId: number, label: string) {
		return this.http.put(`${this.API_ROOT}docgen/UpdateTextAttachMedia?attachmentId=${attachmentId}&placementTypeId=${placementTypeId}&imgSizeID=${imgSizeId}&attachmentLabel=${label}`, null, this.getHeaders({ noloader: true }))
	}
	sortAttachments(data: Array<{ ID: string, sortOrder: number}>) {
		return this.http.post(`${this.API_ROOT}docgen/insertAttachmentSort`, { IDs: data }, this.getHeaders())
	}
	createPdf(docID:string) {
		return this.http.post(`${this.API_ROOT}docgen/CreatePdf?docID=${docID}`, {docID: docID},this.getHeaders())
	}
	createPdfDYV(docID:string) {
		return this.http.post(`${this.API_ROOT}docgen/CreatePdfDYV?docID=${docID}`, {docID: docID},this.getHeaders())
	}
	downloadPdf(pdfUrl: string) {
		return this.http.get(pdfUrl, { responseType: 'blob' });
	}
	createPdfImage(docID: string) {
		return this.http.post(`${this.API_ROOT}docgen/CreatePdfImage?docID=${docID}`, {docID: docID},this.getHeaders())
	}

	updateSubcatName(docId:string, subCategoryId:number, subCategoryDesc:string) {
		return this.http.put(`${this.API_ROOT}docgen/UpdateSubcatCustom?docId=${docId}&subCategoryId=${subCategoryId}&subCategoryDesc=${subCategoryDesc}`, null, this.getHeaders())
	}

 	/* docgen templates */
	deleteTemplate(templateId: string) {
		return this.http.delete(`${this.API_ROOT}docgentemplates/${templateId}`, this.getHeaders())
	}
	getTemplate(templateId: string) {
		return this.http.get(`${this.API_ROOT}docgentemplates/${templateId}`, this.getHeaders())
	}
	saveTemplate(template: ProposalTemplate) {
		return this.http.post(`${this.API_ROOT}docgentemplates`, {...template}, this.getHeaders())
	}
	updateTemplate(template: ProposalTemplate) {
		return this.http.put(`${this.API_ROOT}docgentemplates/${template.templateID}`, {...template}, this.getHeaders())
	}

	/* media - get categories */
	getSharedLibCategories(orgId: number) {
		return this.http.get(`${this.API_ROOT}media/GetSharedLibCategories?orgId=${orgId}`, this.getHeaders())
	}
	getUserLibCategories(userId: string) {
		return this.http.get(`${this.API_ROOT}media/GetUserLibCategories?userId=${userId}`, this.getHeaders())
	}
	getSharedFolders(userId: string) {
		return this.http.get(`${this.API_ROOT}media/GetSharedFolders?userId=${userId}`, this.getHeaders())
	}
	
	/* media - get contents */
	getSharedLibContents(orgId: number, categoryId: number) {
		return this.http.get(`${this.API_ROOT}media/GetSharedLibContent?orgId=${orgId}&mediaCategoryID=${categoryId}`, this.getHeaders())
	}
	getUserLibContents(userId: string, categoryId: number) {
		return this.http.get(`${this.API_ROOT}media/GetUserLibContent?userId=${userId}&mediaCategoryID=${categoryId}`, this.getHeaders())
	}
	getModuleContents(userId: string, moduleId: number) {
		return this.http.get(`${this.API_ROOT}docgen/GetList?userId=${userId}&moduleID=${moduleId}`, this.getHeaders({ noloader: true }))
		.pipe(map((resp:any) => {
			return {MediaCategories: [], Medias: resp}
		}));
	}
	getSharedContents(userId: string, userSharedFrom: string) {
		return this.http.get(`${this.API_ROOT}media/GetSharedMedia?userId=${userId}&userSharedFrom=${userSharedFrom}`, this.getHeaders())
		.pipe(map((resp:any) => {
			return {MediaCategories: [], Medias: resp}
		}));
	}

	/* media - other */
	saveFolder(userId:string, parentId: number, mediaCategoryDesc:string) {
		return this.http.post(`${this.API_ROOT}media/MediaUserInsertCategory?userID=${userId}&parentId=${parentId}&mediaCategoryDesc=${mediaCategoryDesc}`, {userID: userId, parentId: parentId, mediaCategoryDesc: mediaCategoryDesc},this.getHeaders())
	}

	renameFile(userId:string, mediaSourceID: number, mediaItemID: string, mediaDesc: string) {
		return this.http.put(`${this.API_ROOT}media/MediaUserUpdateMedia?userId=${userId}&mediaSourceID=${mediaSourceID}&mediaItemID=${mediaItemID}&mediaDesc=${mediaDesc}`, null, this.getHeaders())
	}
	
	uploadMediaFile(userId:string, categoryId: number, description:string,  fileItem:File) {
		const formData: FormData = new FormData();
		formData.append('', fileItem, fileItem.name);
		const myHeaders = new Headers();  
		myHeaders.append('enctype', 'multipart/form-data');
		myHeaders.append('Accept', 'application/json');
		const req = new HttpRequest('POST', `${this.API_ROOT}media/UploadUserMedia?userId=${userId}&mediaCategoryID=${categoryId}&mediaDescription=${description}&url=`, formData, { reportProgress: true })
		return this.sendWithUpdates(req);
	}
	
	uploadFileByUrl(userId:string, mediaCategoryId: number, description:string, url: string)	 {
		return this.http.post(`${this.API_ROOT}media/UploadUserMedia?userID=${userId}&mediaCategoryID=${mediaCategoryId}&mediaDescription=${description}&url=${url}`, {userID: userId, mediaCategoryId: mediaCategoryId, mediaDescription: description, url:url},this.getHeaders())
	}
	search(userId: string, searchString:string) {
		return this.http.get(`${this.API_ROOT}media/SearchMedia?userId=${userId}&searchString=${searchString}`, this.getHeaders())
	}

	deleteMedia(userId:string, mediaSourceId: number, mediaItemId: string) {
        return this.http.put(`${this.API_ROOT}media/MediaUserDeleteMedia?userId=${userId}&mediaSourceId=${mediaSourceId}&mediaItemId=${mediaItemId}`, null, this.getHeaders())
	}
	moveMedia(userId:string, mediaItemId: string, destinationMediaCategoryID: number) {
        return this.http.put(`${this.API_ROOT}media/MediaUserMove?userId=${userId}&mediaItemId=${mediaItemId}&destinationMediaCategoryID=${destinationMediaCategoryID}`, null, this.getHeaders())
	}
	shareMedia(userId:string, userShareSourceId:number, mediaItemId: string, shareToUserId: string) {
        return this.http.put(`${this.API_ROOT}media/MediaShare?userId=${userId}&userShareSourceID=${userShareSourceId}&mediaItemID=${mediaItemId}&shareToUserID=${shareToUserId}`, null, this.getHeaders())
 	}
	shareMediaAll(userId:string, userShareSourceId:number, mediaItemId: string) {
        return this.http.put(`${this.API_ROOT}media/MediaShareAll?userId=${userId}&userShareSourceID=${userShareSourceId}&mediaItemID=${mediaItemId}`, null, this.getHeaders())
	}
	copyMedia(userId:string, userShareSourceId:number, mediaItemId: string, destinationMediaCategoryID: number) {
        return this.http.put(`${this.API_ROOT}media/MediaCopy?userId=${userId}&userShareSourceID=${userShareSourceId}&mediaItemID=${mediaItemId}&destinationMediaCategoryID=${destinationMediaCategoryID}`, null, this.getHeaders())
	}

	sendMail(userID:string, cartSessionID:string, subject:string, emailTo:Array<CrmContact>, bcc:Array<CrmContact>, exchangeEmail:string, exchangePassword:string, emailMessage:string, personalize:string, clearAfterSend:boolean, concatVideo: boolean = false, quoteID: string = '', templateID: number = 0) {
		return this.http.post(`${this.API_ROOT}media/SendMail`, {userID, cartSessionID, subject, EmailTo: emailTo, EmailBcc: bcc, exchangeEmail, exchangePassword, emailMessage, personalize, clearCart: clearAfterSend, concatVideo, quoteID, templateID},this.getHeaders())
	}
	getLastCredentials(userId:string) {
		return this.http.get(`${this.API_ROOT}media/GetLastEmailCredentials?userId=${userId}`, this.getHeaders())
	}

	updateFolder(userId:string, mediaCategoryId: number, parentId: number, mediaCategoryDesc:string) {
		return this.http.put(`${this.API_ROOT}media/MediaUserUpdateCategory?userId=${userId}&mediaCategoryId=${mediaCategoryId}&parentId=${parentId}&mediaCategoryDesc=${mediaCategoryDesc}`, null, this.getHeaders())
	}
	removeFolder(userId:string, mediaCategoryId: number) {
		return this.http.put(`${this.API_ROOT}media/MediaUserDeleteCategory?userId=${userId}&mediaCategoryId=${mediaCategoryId}`, null, this.getHeaders())
	}
	getPlaylist(userId:string, mediaItemId: string, personalize: string, profileId: string) {
		return this.http.get(`${this.API_ROOT}media/GetVideoPlaylist?userId=${userId}&mediaItemId=${mediaItemId}&personalize=${personalize}&profileId=${profileId}`, this.getHeaders())
	}
	/* cart */
	startSession(userId:string) {
		return this.http.post(`${this.API_ROOT}cart/StartSession?userID=${userId}`, {userID: userId},this.getHeaders())
	}
	getCart(cartSessionID:string) {
		return this.http.get(`${this.API_ROOT}cart/GetSession?cartSessionID=${cartSessionID}`, this.getHeaders())
		.pipe(map((resp:any) => { return {Medias: resp}; }));
	}
	getAsyncCart(cartSessionID:string) {
		return this.http.get(`${this.API_ROOT}cart/GetCartAsyncItems?cartSessionID=${cartSessionID}`, this.getHeaders())
		.pipe(map((resp:any) => { return {Medias: resp}; }));
	}
	addToCart(cartSessionId: string, mediaItemId:string, mediaSourceId: number) {
		return this.http.put(`${this.API_ROOT}cart/AddItem?cartSessionId=${cartSessionId}&cartItemId=${mediaItemId}&mediaSourceId=${mediaSourceId}`, null, this.getHeaders())
	}
	addToCartAsync(cartSessionID, userID, item) {
		const formData: FormData = new FormData();
		let customVideoRequestParams = item.MediaAsyncAttributes.filter(i => i.attributeName == 'custVideoRequestParams')[0].attributeValue;

		customVideoRequestParams.forEach(param => {
			let fileValue = param.parmValue;
			if(fileValue instanceof File){
				let file:File = <File>param.parmValue;
				formData.append(param.parmName, file, file.name);
			}
		});
	
		const otherParams = customVideoRequestParams.filter(p => !(p.parmValue instanceof File));	
		item.MediaAsyncAttributes.filter(i => i.attributeName == 'custVideoRequestParams')[0].attributeValue = JSON.stringify(otherParams);
		formData.append('data', JSON.stringify(item));

		//No Loading headers
		let initHeaders = new HttpHeaders(); 
		initHeaders = initHeaders.append('enctype', 'multipart/form-data');
		initHeaders = initHeaders.append('Accept', 'application/json');
		let newheaders = initHeaders.append('noloader', 'true' );
		let init = { headers: newheaders };
		
		return this.http.post(`${this.API_ROOT}cart/AddItemAsync?cartSessionID=${cartSessionID}&userID=${userID}`, formData, init);
	}
	removeFromCart(cartSessionId: string, cartItemId:string) {
		return this.http.put(`${this.API_ROOT}cart/RemoveItem?cartSessionId=${cartSessionId}&cartItemId=${cartItemId}`, null, this.getHeaders())
	}
	removeFromCartAsync(cartSessionId: string, cartItemId:string) {
		return this.http.put(`${this.API_ROOT}cart/RemoveItemAsync?cartSessionId=${cartSessionId}&cartItemId=${cartItemId}`, null, this.getHeaders())
	}
	clearCart(cartSessionId:string) {
		return this.http.put(`${this.API_ROOT}cart/ClearCart?cartSessionId=${cartSessionId}`, null, this.getHeaders())
	}
	sortCart(cartId: string, data: Array<{ ID: string, sortOrder: number }>) {
		return this.http.post(`${this.API_ROOT}cart/insertCartSort?cartSessionID=${cartId}`, { IDs: data }, this.getHeaders())
	}

	getPreviousCart(userID:string) {
		return this.http.get(`${this.API_ROOT}cart/GetSessionRecent?userID=${userID}`, this.getHeaders())
	}
	saveCart(cartSessionId: string, cartName:string) {
		return this.http.put<SavedCart>(`${this.API_ROOT}cart/SaveCart?cartSessionId=${cartSessionId}&sessionDesc=${cartName}`, null, this.getHeaders())
	}
	renameCart(cartSessionId: string, cartName:string) {
		return this.http.put(`${this.API_ROOT}cart/RenameCart?cartSessionId=${cartSessionId}&sessionDesc=${cartName}`, null, this.getHeaders())
	}
	getSavedCarts(userID:string) {
		return this.http.get(`${this.API_ROOT}cart/GetSavedCarts?userID=${userID}`, this.getHeaders())
	}
	deleteCart(cartSessionId: string) {
		return this.http.delete(`${this.API_ROOT}cart/deleteSavedCart?cartSessionId=${cartSessionId}`, this.getHeaders())
	}

	updateCategory(moduleId:number, cat:EditableCategory) {
		return this.http.put(`${this.API_ROOT}docgen/AdminDocGenCategoryUpdate?moduleId=${moduleId}&categoryid=${cat.categoryID}&categorydesc=${cat.categoryDesc}`, null, this.getHeaders())
	}
	/* CRM */
	getAccounts(userId: string, filter: string) {
		return this.http.get(`${this.API_ROOT}crm/GetAccounts?userId=${userId}&filter=${filter}`, this.getHeaders({ noloader: true }))
	}

	getContacts(userId: string, accountId:string) {
		return this.http.get(`${this.API_ROOT}crm/GetContacts?accountId=${accountId}&userId=${userId}`, this.getHeaders())
	}

	logCrm(userId:string, token:string) {
		return this.http.get(`${this.API_ROOT}crm/LoginCRM?userId=${userId}&code=${token}`)
	}
	getDefaultAccount(cartSessionID:string) {
		return this.http.get(`${this.API_ROOT}cart/GetSessionDefaultAccount?cartSessionID=${cartSessionID}`, this.getHeaders())
	}

	unlinkCrm(userId: string) {
		return this.http.post(`${this.API_ROOT}CRM/CRMUnlink?userID=${userId}`, { }, this.getHeaders())
	}
	/* settings */
	resortCategories(userId: string, moduleId: number, sort:Array<any>) {
		return this.http.post(`${this.API_ROOT}docgen/insertusercategorysort?userID=${userId}&moduleId=${moduleId}`, {IDs: sort},this.getHeaders())
	}
	resortSubCategories(userId: string, moduleId: number, categoryId: number, sort:Array<any>) {
		return this.http.post(`${this.API_ROOT}docgen/insertusersubcategorysort?userID=${userId}&moduleId=${moduleId}&categoryId=${categoryId}`, {IDs: sort},this.getHeaders())
		.pipe(map((resp:any) => resp));
	}

	teamPictures(userID:string, collageData:any) {
		return this.http.post(`${this.API_ROOT}imagebuilder/createusercollage?userID=${userID}`, collageData ,this.getHeaders())
	}

	/* ADMIN */
	getUsers(orgId:number, userId?:string) {
		return this.http.get<Array<User>>(`${this.API_ROOT}users?orgId=${orgId}${userId ? '&userId=' + userId : ''}`, this.getHeaders())
	}

	deleteUser(userId:string) {
		return this.http.delete(`${this.API_ROOT}users/${userId}`, this.getHeaders());
	}

	saveUser(user:User, profile: Profile) {
		return this.http.post(`${this.API_ROOT}users`, { User: user, DocGenProfile: profile }, this.getHeaders())
	}

	getUser(userId:string) {
		return this.http.get(`${this.API_ROOT}users/${userId}`, this.getHeaders());
	}

	uploadProfileImage(fileItem:File,  photo: ProfilePhoto) {
		const formData: FormData = new FormData();
		if(fileItem) {
			formData.append('file', fileItem, fileItem.name);
		}
		formData.append('json', JSON.stringify(photo));
		const myHeaders = new Headers();  
		myHeaders.append('enctype', 'multipart/form-data');
		myHeaders.append('Accept', 'application/json');
		return this.http.post(`${this.API_ROOT}docgenimage`, formData)
	}
	updateProfileImage(photo: ProfilePhoto) {
		return this.http.put<ProfilePhoto>(`${this.API_ROOT}/docgenimage`, photo, this.getHeaders());
	}
	saveProfilePartner(partner: ProfilePartner) {
		return this.http.post(`${this.API_ROOT}users/profile/partner`, partner, this.getHeaders());
	}
	saveTeam(team: Team) {
		return this.http.post(`${this.API_ROOT}users/profile/entireteam`, team, this.getHeaders());
	}

	getCategories(moduleId:number, docId?:string):Observable<HttpEvent<Array<Category>>> {
		return this.http.get<Array<Category>>(`${this.API_ROOT}admin/categoriesget?moduleId=${moduleId}${docId ? '&docID='+docId : ''}`, this.getHeaders());
	}
	insertCategory(moduleId: number, category:Category, docId?:string): Observable<Category> {
		return this.http.post<Category>(`${this.API_ROOT}admin/categoryinsert?moduleId=${moduleId}&categoryDesc=${encodeURIComponent(category.categoryDesc)}${docId ? '&docID='+docId : ''}`,this.getHeaders())
	}
	updateCategoryAdmin(category:Category, docId?:string) {
		return this.http.put(`${this.API_ROOT}admin/categoryupdate${docId ? '?docID='+docId : ''}`, { categoryDesc: category.categoryDesc, categoryID: category.categoryID, sortOrder: category.sortOrder }, this.getHeaders());
	}
	deleteCategory(categoryId: number, docId?:string) {
		return this.http.delete(`${this.API_ROOT}admin/categorydelete?categoryId=${categoryId}${docId ? '&docID='+docId : ''}`);
	}
	updateCategories(categories:Array<Category>, docId?:string) {
		return this.http.put(`${this.API_ROOT}admin/categoriesupdate${docId ? '?docID='+docId : ''}`, { AdminCategories: categories }, this.getHeaders());
	}

	insertSubcategory(categoryId: number, subcategory:SubCategory, docId?:string): Observable<Category> {
		return this.http.post<Category>(`${this.API_ROOT}admin/subcatinsert?categoryID=${categoryId}&subCategoryDesc=${encodeURIComponent(subcategory.subCategoryDesc)}&isCustom=false${docId ? '&docID='+docId : ''}`,this.getHeaders())
	}
	updateSubcategoryAdmin(subcategory:SubCategory, docId?:string) {
		return this.http.put(`${this.API_ROOT}admin/subcatupdate${docId ? '?docID='+docId : ''}`, { ...subcategory }, this.getHeaders());
	}
	deleteSubcategory(subcategoryId: number, docId?:string) {
		return this.http.delete(`${this.API_ROOT}admin/subcatdelete?subcategoryId=${subcategoryId}${docId ? '&docID='+docId : ''}`);
	}
	updateSubcategories(subcategories:Array<SubCategory>, docId?:string) {
		return this.http.put(`${this.API_ROOT}admin/subcatsupdate${docId ? '?docID='+docId : ''}`, { AdminSubCategories: subcategories }, this.getHeaders());
	}

	getSubcategoryTexts(subcatId:number, docId?:string) {
		return this.http.get<Array<Text>>(`${this.API_ROOT}admin/subcattextget?subcategoryId=${subcatId}${docId ? '&docID='+docId : ''}`, this.getHeaders());
	}
	insertSubcatText(subCatId:number, text: string, revisionDate: Date, docId?:string):Observable<Text> {
		return this.http.post<Text>(`${this.API_ROOT}admin/subcattextinsert?subCategoryId=${subCatId}&defaulttext=${encodeURIComponent(text)}${docId ? '' : '&RevisionNeededDate=' + revisionDate}${docId ? '&docID='+docId : ''}`,this.getHeaders()); // not a typo - don't include revdate if there's a docid (ie, it comes from edit outline)
	}
	updateSubcatText(subcatText:Text, docId?:string):Observable<HttpEvent<Text>> {
		return this.http.put<Text>(`${this.API_ROOT}admin/subcattextupdate${docId ? '?docID='+docId : ''}`, { ...subcatText }, this.getHeaders());
	}	
	deleteSubcatText(textId: number, docId?:string) {
		return this.http.delete(`${this.API_ROOT}admin/subcattextdelete?textId=${textId}${docId ? '&docID='+docId : ''}`);
	}
	updateSubcatTexts(texts:Array<Text>, docId?:string) {
		return this.http.put(`${this.API_ROOT}admin/subcattextsupdate${docId ? '?docID='+docId : ''}`, { AdminSubCatTexts: texts }, this.getHeaders());
	}

	insertMediaCategoryAdmin(orgId: number, mediaCategory: Folder) {
		return this.http.post(`${this.API_ROOT}admin/mediainsertcategory?orgId=${orgId}&parentId=${mediaCategory.parent ? mediaCategory.parent.mediaCategoryID : -1}&mediaCategoryDesc=${encodeURIComponent(mediaCategory.mediaCategoryDesc)}&sortOrder=${mediaCategory.sortOrder}`,this.getHeaders())
	}
	deleteMediaCategoryAdmin(orgId:number, mediaCategory: Folder) {
		return this.http.delete(`${this.API_ROOT}admin/mediadeletecategory?orgId=${orgId}&mediaCategoryID=${mediaCategory.mediaCategoryID}`);
	}
	updateMediaCategoryAdmin(orgId: number, mediaCategory: Folder) {
		return this.http.put(`${this.API_ROOT}admin/mediaupdatecategory?orgId=${orgId}&mediaCategoryId=${mediaCategory.mediaCategoryID}&parentId=${mediaCategory.parent ? mediaCategory.parent.mediaCategoryID : -1}&mediaCategoryDesc=${mediaCategory.mediaCategoryDesc}&sortOrder=${mediaCategory.sortOrder}`, null, this.getHeaders());
	}
	saveMedia(orgId: number, media:Media, file: File, thumbnail: File) {
		const formData: FormData = new FormData();
		if(file) { formData.append('mediafile', file, file.name); }
		if(thumbnail) { formData.append('imagefile', thumbnail, thumbnail.name); }
		const myHeaders = new Headers();  
		myHeaders.append('enctype', 'multipart/form-data');
		myHeaders.append('Accept', 'application/json');
		
		const req = new HttpRequest('POST', `${this.API_ROOT}admin/savemedia?orgId=${orgId}&mediaCategoryID=${media.mediaCategoryID}&mediaDescription=${encodeURIComponent(media.mediaDesc)}&mediaTypeId=${media.mediaTypeID}&mediaItemID=${media.mediaID || media.mediaItemID}&searchWords=${encodeURIComponent(media.searchWords)}&revisionNeededDate=${media.revisionNeededDate ? new Date(media.revisionNeededDate).toLocaleDateString() : ''}`, formData, { reportProgress: true })
		return this.sendWithUpdates(req);

	}

	moveMediaAdmin(media:Media, destination: number) {
		return this.http.put(`${this.API_ROOT}admin/mediamove?mediaItemID=${media.mediaItemID}&destinationMediaCategoryID=${destination}`, null, this.getHeaders());
	}
	deleteMediaAdmin(media:Media) {
		return this.http.delete(`${this.API_ROOT}admin/mediadeletemedia?mediaItemID=${media.mediaItemID}`);
	}
	
	/* text library */
	getTextLibCategories(orgId: number) {
		return this.http.get<Array<LibraryTextCategory>>(`${this.API_ROOT}textlib/getcategories?orgid=${orgId}`, this.getHeaders());
	}
	searchTextLib(orgId: number, textLibCategoryId: number, searchString: string) {
		return this.http.get<Array<LibraryText>>(`${this.API_ROOT}textlib/libsearch?orgid=${orgId}&textLibCategoryId=${textLibCategoryId}&searchString=${searchString}`, this.getHeaders());
	}

	saveTextLibItem(orgId: number, item: LibraryText) {
		return this.http.post(`${this.API_ROOT}textlib/savelibitem?orgId=${orgId}`, item, this.getHeaders())
	}
	deleteTextLibItem(textLibItemId: number) {
		return this.http.delete(`${this.API_ROOT}textlib/deletelibitem?textLibId=${textLibItemId}`);
	}


	/* document management */
	getListTypes() {
		return this.http.get<Array<ListType>>(`${this.API_ROOT}docgen/getlisttypes`, this.getHeaders());
	}
	getDocumentOutline(docId:string) {
		return this.http.get<DocumentOutline>(`${this.API_ROOT}docgen/getdocoutline?docid=${docId}`, this.getHeaders());
	}
	setDocumentDepth(docId: string, depth: number) {
		return this.http.put(`${this.API_ROOT}docgen/updatedocoutlinedepth?docId=${docId}&depth=${depth}`, null, this.getHeaders());
	}
	updateDocumentOutline(docOutline: DocumentOutline) {
		return this.http.put(`${this.API_ROOT}docgen/updatedocoutline`, docOutline, this.getHeaders());
	}

	/* what's new message */
	getOrgDetails(orgId: number) {
		return this.http.get<Array<Organization>>(`${this.API_ROOT}auth2/org?orgID=${orgId}`, this.getHeaders());
	}

	saveWhatsNew(org: Organization) {
		return this.http.put(`${this.API_ROOT}admin/whatsnewupdate?orgID=${org.orgID}`, { whatsNew: org.whatsNew, whatsNewTitle: org.whatsNewTitle }, this.getHeaders());
	}

	getMediaAll(orgId: number) {
		return this.http.get<Array<Organization>>(`${this.API_ROOT}admin/mediagetall?orgid=${orgId}`, this.getHeaders());
	}
	getSubcatTextAll(orgId: number) {
		return this.http.get<Array<Organization>>(`${this.API_ROOT}admin/subcattextgetall?orgid=${orgId}`, this.getHeaders());
	}	

	sendWithUpdates(req:HttpRequest<any>) {
		this.ngProgress.ref().start();
		this.ngProgress.ref().set(5);
		return this.http.request(req).pipe(
			tap((resp:any) => {
				if(resp.type == HttpEventType.UploadProgress) {
					const percentage = 100 * resp.loaded / resp.total;
					this.ngProgress.ref().set(percentage);
				}
				if(resp.type == HttpEventType.Response) {
					//switchMap(m => m.body)
					this.ngProgress.ref().complete();
				}
			}),
			takeUntil(this.cancelUpload$),
			catchError((resp) => {
				this.ngProgress.ref().complete(); 
				return throwError(resp);
			}),
			switchMap((resp) => resp.type == HttpEventType.Response ? of(resp.body) : empty())
		)
	}

	sendWithOutUpdates(req:HttpRequest<any>) {
		return this.http.request(req).pipe(
			tap((resp:any) => {	}),
			takeUntil(this.cancelUpload$),
			catchError((resp) => {
				return throwError(resp);
			}),
			switchMap((resp) => resp.type == HttpEventType.Response ? of(resp.body) : empty())
		)
	}

	cancelUpload():void {
		this.cancelUploadSubject.next(true);
		if(this.ngProgress.ref().isStarted) {
			//if there's a pending upload when this is closed, finish the progressbar
			//(ideally, this would happen when the call is cancelled, but there's no way to catch the aborted call)
			this.ngProgress.ref().set(0);
		}
	}

	//#########################################
	//COMMUNICATION MODULE
	//#########################################

	getLists(orgID: number) {
		return this.http.get<Array<CommList>>(`${this.API_ROOT}comm/lists?orgID=${orgID}`, this.getHeaders())
	}
	
	newList(orgID: number, listName:string, listDesc: string, groupID: number) {
			return this.http.post(`${this.API_ROOT}/comm/lists`, {orgID: orgID, listName: listName, listDesc: listDesc, groupID: groupID},this.getHeaders())
	}
	
	getList(listID: string) {
			return this.http.get<CommList>(`${this.API_ROOT}comm/lists/${listID}`, this.getHeaders())
	}

	getListDataByPage(listID: string, pageIndex: number) {
		return this.http.get<CommList>(`${this.API_ROOT}comm/lists/${listID}?pagenum=${pageIndex}`, this.getHeaders())
	}

	deleteList(listID: string) {
		return this.http.delete(`${this.API_ROOT}/comm/lists/${listID}`,this.getHeaders())
	}

	uploadCSV(listID: string,  file: File) {
			const formData: FormData = new FormData();
			formData.append('', file, file.name);
			const myHeaders = new Headers();  
			myHeaders.append('enctype', 'multipart/form-data');
			myHeaders.append('Accept', 'application/json');
			const req = new HttpRequest('POST', `${this.API_ROOT}/comm/lists/${listID}/datafile`, formData, { reportProgress: true })
			return this.sendWithUpdates(req);
	}
  
	importDataFile(listID: string, filePath: string, mappings: Array<Mapping>) {
		return this.http.post(`${this.API_ROOT}/comm/lists/${listID}/datafile/import`, {listID: listID, filePath: filePath, Mappings: mappings},this.getHeaders())
	}

	updateList(listID:string, list: CommList) {
		return this.http.put(`${this.API_ROOT}comm/lists/${listID}`, list, this.getHeaders());
	}

	getCampaigns(orgID: number, filterType?: number) {
		return this.http.get<Array<CommList>>(`${this.API_ROOT}comm/campaigns?orgID=${orgID}${filterType !== undefined ? '&filterType=' + filterType : ''}`, this.getHeaders());
	}

	getCampaignDetail(campaignID: string, groupID: number, lastName: string, phone: string, email: string, statusCode: string, commTypeID: string) {
		return this.http.get<Array<CommCampaignDetail>>(`${this.API_ROOT}comm/campaigns/results?campaignID=${campaignID}&groupID=${groupID}&lastName=${lastName}&phone=${phone}&email=${email}&statusCode=${statusCode}&commTypeID=${commTypeID}`, this.getHeaders());
	}

	getCampaignDetailByPage(campaignID: string, groupID: number, lastName: string, phone: string, email: string, commTypeID: string, statusCode: string, pageNum: number) {
		return this.http.get<Array<CommCampaignDetail>>(`${this.API_ROOT}comm/campaigns/results?campaignID=${campaignID}&groupID=${groupID}&lastName=${lastName}&phone=${phone}&email=${email}&statusCode=${statusCode}&commTypeID=${commTypeID}&pageNum=${pageNum}`, this.getHeaders());
	}

	getCampaignDetailCsv(campaignID: string, groupID: number) {
		return this.http.get<Array<CommCampaignDetail>>(`${this.API_ROOT}comm/campaigns/results/csv?campaignID=${campaignID ? campaignID : ''}&groupID=${groupID ? groupID : ''}`, this.getHeaders());
	}

	getCampaign(campaignID: string) {
			return this.http.get<CommList>(`${this.API_ROOT}comm/campaigns/${campaignID}`, this.getHeaders())
	}

	deleteCampaign(campaignID: string) {
		return this.http.delete(`${this.API_ROOT}comm/campaigns/${campaignID}`,this.getHeaders())
	}

	newCampaign(campaign: CommCampaign) {
		return this.http.post(`${this.API_ROOT}comm/campaigns`, campaign, this.getHeaders())
	}

	getTargetFields() {
		return this.http.get<Array<CommList>>(`${this.API_ROOT}comm/lists/targetfields`, this.getHeaders())
	}

	editCampaign(campaignID:string, campaign: CommCampaign) {
		return this.http.put(`${this.API_ROOT}comm/campaigns/${campaignID}`, campaign, this.getHeaders());
	}

	testCampaign(campaignID: string, email: string, phone: string) {
		return this.http.post(`${this.API_ROOT}comm/campaigns/test/${campaignID}?email=${email}&phone=${phone}`, this.getHeaders());
	}

	uploadWavFile(file: File) {
		const formData: FormData = new FormData();
		formData.append('', file, file.name);
		const myHeaders = new Headers();  
		myHeaders.append('enctype', 'multipart/form-data');
		myHeaders.append('Accept', 'application/json');
		const req = new HttpRequest('POST', `${this.API_ROOT}/comm/campaigns/wavfile`, formData, { reportProgress: true })
		return this.sendWithUpdates(req);
	}

	executeCampaign(campaignID: string, userId: string){
		return this.http.post(`${this.API_ROOT}comm/campaigns/execute/${campaignID}?userID=${userId}`, {}, this.getHeaders())
	}

	copyCampaign(campaignID: string){
		return this.http.post(`${this.API_ROOT}comm/campaigns/${campaignID}/copy`, {}, this.getHeaders())
	}

	getCampaignSources(orgId: number) {
		return this.http.get(`${this.API_ROOT}comm/sources/${orgId}`, this.getHeaders());
	}

	getCommGroups(orgId: number) {
		return this.http.get(`${this.API_ROOT}/comm/groups/${orgId}`, this.getHeaders());
	}

	addCommGroup(commGroup: CommGroup) {
		return this.http.post(`${this.API_ROOT}comm/groups`, commGroup, this.getHeaders());
	}
	editCommGroup(commGroup: CommGroup) {
		return this.http.put(`${this.API_ROOT}comm/groups`, commGroup, this.getHeaders());
	}
	deleteCommGroup(commGroup: CommGroup) {
		return this.http.delete(`${this.API_ROOT}comm/groups/${commGroup.groupID}`, this.getHeaders());
	}

	//#########################################
	//END - COMMUNICATION MODULE
	//#########################################
	

	//#########################################
	//VIDEOS MODULE
	//#########################################
	
	getVideoPrograms(orgID: number) {
		return this.http.get<Array<CommList>>(`${this.API_ROOT}custvideo/programs?orgID=${orgID}`, this.getHeaders())
	}

	getVideoDetail(programID: number) {
		return this.http.get<Array<CommList>>(`${this.API_ROOT}custvideo/programs/${programID}`, this.getHeaders())
	}

	sendVideoEmail(programID: number, custVideoRequest:CustVideoRequest, orgID:number, userID:string ) {

		const formData: FormData = new FormData();
		
		custVideoRequest.CustVideoParameters.forEach(param => {
			let fileValue = param.parmValue;
			if(fileValue instanceof File){
				let file:File = <File>param.parmValue;
				formData.append(param.parmName, file, file.name);
			}
		});

		const otherParams = custVideoRequest.CustVideoParameters.filter(p => !(p.parmValue instanceof File));	
		formData.append('data', JSON.stringify({emailTo: custVideoRequest.emailTo, emailSubject: custVideoRequest.emailSubject, emailMessage: custVideoRequest.emailMessage, CustVideoParameters: otherParams }));
		
		//No Loading headers
		let newheaders = new HttpHeaders(); 
		newheaders = newheaders.append('enctype', 'multipart/form-data');
		newheaders = newheaders.append('Accept', 'application/json');
		let init = { headers: newheaders };

		//return this.http.post(`${this.API_ROOT}custvideo/programs/${programID}/email`, formData, this.getHeaders())
		const req = new HttpRequest('POST', `${this.API_ROOT}custvideo/programs/${programID}/email?orgID=${orgID}&userID=${userID}`, formData, init);
		return this.sendWithUpdates(req);	
	}

	previewVideo(programID: number, customVideoRequestParams:Array<CustVideoRequestParm>, orgID:number, userID:string, mediaAsyncID: string, programName: string ) {
		
		const formData: FormData = new FormData();
		
		customVideoRequestParams.forEach(param => {
			let fileValue = param.parmValue;
			if(fileValue instanceof File){
				let file:File = <File>param.parmValue;
				formData.append(param.parmName, file, file.name);
			}
		});
	
		const otherParams = customVideoRequestParams.filter(p => !(p.parmValue instanceof File));	
		formData.append('data', JSON.stringify(otherParams));
		formData.append('mediaDesc', programName);

		//No Loading headers
		let initHeaders = new HttpHeaders(); 
		initHeaders = initHeaders.append('enctype', 'multipart/form-data');
		initHeaders = initHeaders.append('Accept', 'application/json');
		let newheaders = initHeaders.append('noloader', 'true' );
		let init = { headers: newheaders };
		
		const req = new HttpRequest('POST', `${this.API_ROOT}/custvideo/programs/${programID}/preview?orgID=${orgID}&userID=${userID}&mediaAsyncID=${mediaAsyncID}`, formData, init);
		return this.sendWithOutUpdates(req);	
	}

	//#########################################
	//END - VIDEOS MODULE
	//#########################################
	
	//#########################################
	//SELF SIGN UP MODULE
	//#########################################

	selfSignUp(signup: SignupInput) {
		return this.http.post(`${this.API_ROOT}/signup/selfsignup`, signup, this.getHeaders());
	}

	verifyAccount(signUpKey: string) {
		return this.http.post(`${this.API_ROOT}/signup/unlock/${signUpKey}`, {}, this.getHeaders());
	}

	getCompanyInformation(orgID: number) {
		return this.http.get<SignupCompany>(`${this.API_ROOT}signup/org/${orgID}`, this.getHeaders())
	}

	saveCompanyInformation(orgID:number, company: SignupCompany) {
		return this.http.put(`${this.API_ROOT}signup/org/${orgID}`, company, this.getHeaders());
	}

	sendWelcomeEmail(userID:string){
		return this.http.post(`${this.API_ROOT}/signup/welcomemail/${userID}`, {}, this.getHeaders());
	}

	verifyResource(resource: string) {
		return this.http.post(`${this.API_ROOT}/signup/check-resource`, {resource: resource}, this.getHeaders());
	}

	//#########################################
	//END - SELF SIGN UP MODULE
	//#########################################
	
	//#########################################
	//SSO MODULE
	//#########################################
	
	getOrgsByToken(token:string) {
		return this.http.get(`${this.API_ROOT}auth2/orgs/${token}`, this.getHeaders());
	}

	loginWithToken(orgID:number, token:string) {
		return this.http.post(`${this.API_ROOT}auth2/loginwithtoken`, {orgID: orgID, token: token},this.getHeaders());
	}

	//#########################################
	//END - SSO MODULE
	//#########################################

	//#########################################
	//UNLAYER MODULE
	//#########################################
	
	getUnlayerTemplates(orgId: number, authKey: string, isShared: boolean) {
		return this.http.get(`${this.API_ROOT}media/GetUnlayerTemplates?orgId=${orgId}&authKey=${authKey}&isSharedInstance=${isShared}`, this.getHeaders())
	}
	getUnlayerTemplateImage(templateId: number, message: string) {
		return this.http.get(`${this.API_ROOT}media/GetTemplateImage?templateId=${templateId}&message=${encodeURIComponent(message)}`, this.getHeaders())
	}

	//#########################################
	//END - UNLAYER MODULE
	//#########################################

	getSentItems(userId: string, startDate?: string, endDate?: string) {
		return this.http.get(`${this.API_ROOT}cart/GetSentItems?userId=${userId}${startDate ? '&startDate='+startDate : ''}${endDate ? '&endDate='+endDate : ''}`, this.getHeaders());
	}

	getOrgUsersCount(orgId:number) {
		return this.http.get(`${this.API_ROOT}users/count?orgId=${orgId}`, this.getHeaders({ noloader: true }))
	}

	//#########################################
	//START - ENGAGEMENT
	//#########################################

	getEngagementStats(userId: string, limit: number) {
		return this.http.get(`${this.API_ROOT}engagement/${userId}/?topx=${limit}`, this.getHeaders()).pipe(
			map((i:any) => i.map(x => { return { url: '/sent-items/'+ x.cartDeliveryID, text: x.longDesc } }))
		);
	}
	getSentItemActivity(userId: string, cartDeliveryId: string) {
		return this.http.get(`${this.API_ROOT}engagement/${userId}/?cartDeliveryID=${cartDeliveryId}`, this.getHeaders());
	}

	getActivityByDate(userId: string, startDate: string, endDate: string) {
		return this.http.get(`${this.API_ROOT}engagement/${userId}/${encodeURIComponent(startDate.replace(' ', 'T'))}/${encodeURIComponent(endDate.replace(' ', 'T'))}`, this.getHeaders()).pipe(
			map((i:any) => i.map(x => { return { ...x, link: '/sent-items/'+x.cartDeliveryID } }))
		);
	}
	//#########################################
	//END - ENGAGEMENT
	//#########################################

	//#########################################
	//START - DOCBUILDER
	//#########################################

	getDocBuilder(orgId: number, docBuilderTypeId: number = 1) {
		return this.http.get<DocBuilder>(`${this.API_ROOT}docbuilder/${orgId}?docBuilderTypeId=${docBuilderTypeId}`, this.getHeaders());
	}

	getDocBuilderSelections(quoteId: string) {
		return this.http.get<Array<string>>(`${this.API_ROOT}lifequote/${quoteId}/docBuilderItems`, this.getHeaders());
	}
	saveLifeQuoteDocBuilderOptions(quoteId: string, items: Array<string>, includeCarrierInfo: boolean, baseQuoteOnly: boolean) {
		return this.http.post(`${this.API_ROOT}lifequote/${quoteId}/docBuilderItems`, { items, includeCarrierInfo, baseQuoteOnly  }, this.getHeaders());
	}

	saveDocBuilderItem(dbi: DocBuilderItem) {
		if(dbi.DocBuilderItemID) {
			return this.http.put(`${this.API_ROOT}/docbuilder/${dbi.DocBuilderID}/item/${dbi.DocBuilderItemID}`, dbi, this.getHeaders())
		} else {
			return this.http.post(`${this.API_ROOT}/docbuilder/${dbi.DocBuilderID}/item`, dbi, this.getHeaders())
		}
	}

	saveDocBuilderImage(f: File) {
		//just created this method so I don't need to call a method named "uploadProfileImage" in the docbuilder admin
		return this.uploadProfileImage(f, new ProfilePhoto())
	}

	deleteDocBuilderItem(DocBuilderItemID: string) {
		return this.http.delete(`${this.API_ROOT}docbuilder/${DocBuilderItemID}/item/delete`, this.getHeaders())
	}

	createDocBuildDoc(quoteId: string) {
		return this.http.get(`${this.API_ROOT}lifequote/quotedocument/${quoteId}`, this.getHeaders());
	}

	//#########################################
	//END - DOCBUILDER
	//#########################################

	//#########################################
	//START - DTC
	//#########################################
	getBroker(userId: string) {
		return this.http.get(`${this.API_ROOT}users/broker/${userId}`, this.getHeaders());
	}

	getSelfServeEnabled(userId: string) {
		return this.http.get(`${this.API_ROOT}lifequote/dtc/user/${userId}`, this.getHeaders());
	}
	setSelfServeEnabled(userId: string, enabled: boolean) {
		return this.http.put(`${this.API_ROOT}lifequote/dtc/user/${userId}`, {enabled}, this.getHeaders());
	}	
	setSelfServeProfile(userId: string, profileId: string) {
		return this.http.put(`${this.API_ROOT}lifequote/dtc/profile/${userId}/${profileId}`, {}, this.getHeaders());
	}

	//#########################################
	//END - DTC
	//#########################################

	//#########################################
	//START - CLASSIC VIEW
	//#########################################

	setViewType(userId: string, useClassicView: boolean) {
		return this.http.put(`${this.API_ROOT}users/view/${userId}`, {useClassicView}, this.getHeaders());
	}	

	//#########################################
	//END - CLASSIC VIEW
	//#########################################


	//#########################################
	//START - LOG IN AS
	//#########################################

	getChildrenOrganizations(masterOrgId:number) {
		return this.http.get(`${this.API_ROOT}users/organization/${masterOrgId}`, this.getHeaders({ noloader: true }));
	}

	//#########################################
	//END - LOG IN AS
	//#########################################


	//#########################################
	//START - SAVE VIDEO TO ASSET LIBRARY
	//#########################################

	savePersonalizedVideo(orgId: number, mediaCategoryID: number, mediaDescription: string, videoUrl: string, thumbUrl: string) {		
		return this.http.post(`${this.API_ROOT}admin/savePersonalizedVideo?orgId=${orgId}&mediaCategoryID=${mediaCategoryID}&mediaDescription=${encodeURIComponent(mediaDescription)}&videoUrl=${encodeURIComponent(videoUrl)}&thumbUrl=${encodeURIComponent(thumbUrl)}`, {}, this.getHeaders());
	}

	savePersonalizedVideoToMyLibrary(userID: string, mediaCategoryID: number, mediaDescription: string, videoUrl: string, thumbUrl: string) {		
		return this.http.post(`${this.API_ROOT}media/savePersonalizedVideo?userID=${userID}&mediaCategoryID=${mediaCategoryID}&mediaDescription=${encodeURIComponent(mediaDescription)}&videoUrl=${encodeURIComponent(videoUrl)}&thumbUrl=${encodeURIComponent(thumbUrl)}`, {}, this.getHeaders());
	}

	//#########################################
	//END - SAVE VIDEO TO ASSET LIBRARY
	//#########################################

	
	//#########################################
	//START - MKT 
	//#########################################

	uploadContactsCSV(file: File) {
		const formData: FormData = new FormData();
		formData.append('', file, file.name);
		const myHeaders = new Headers();  
		myHeaders.append('enctype', 'multipart/form-data');
		myHeaders.append('Accept', 'application/json');
		const req = new HttpRequest('POST', `${this.API_ROOT}/mkt/contacts`, formData, { reportProgress: true })
		return this.sendWithUpdates(req);
	}

	importContactsDataFile(orgID: number, filePath: string, mappings: Array<Mapping>) {
		return this.http.post(`${this.API_ROOT}/mkt/contacts/import/${orgID}`, {filePath: filePath, Mappings: mappings},this.getHeaders())
	}

	getMktPrograms() {
		return this.http.get(`${this.API_ROOT}/mkt/programs`, this.getHeaders());
	}

	saveMktPrograms(programs) {
		return this.http.post(`${this.API_ROOT}/mkt/programs`, { programs }, this.getHeaders());
	}

	getMktContacts() {
		return this.http.get(`${this.API_ROOT}/mkt/contacts`, this.getHeaders());
	}
	getMktContactsSummary() {
		return this.http.get(`${this.API_ROOT}/mkt/contacts/summary`, this.getHeaders());
	}

	getMktContact(orgID: number, contactID: string) {
		return this.http.get(`${this.API_ROOT}/mkt/contact/${orgID}/${contactID}`, this.getHeaders());
	}

	updateIncludeInPrograms(checked, contactID) {
		return this.http.post(`${this.API_ROOT}/mkt/contact`, {contactID:contactID, checked:checked}, this.getHeaders());
	}

	getMktSentReport({orgId, start, end, tzOffset} :{orgId: number, start: string, end:string, tzOffset: number}) {
		return this.http.get(`${this.API_ROOT}mkt/report/sent?orgID=${orgId}&beginDate=${start}&endDate=${end}&tzOffset=${tzOffset || 0}`);
	}

	getMktUpcomingReport({orgId, start, end, tzOffset} :{orgId: number, start: string, end:string, tzOffset: number}) {
		return this.http.get(`${this.API_ROOT}mkt/report/upcoming?orgID=${orgId}&beginDate=${start}&endDate=${end}&tzOffset=${tzOffset || 0}`);
	}

	//#########################################
	//END - MKT
	//#########################################

	//#########################################
	//START - GET ORG SUBSCRIPTION BY USERID
	//#########################################

	getOrgSubscriptionLevel(userId: string) {
		return this.http.get(`${this.API_ROOT}users/subscrption-level/${userId}`, this.getHeaders());
	}

	//#########################################
	//END - GET ORG SUBSCRIPTION BY USERID
	//#########################################

	submitHelpQuestion(name: string, email: string, question: string) {
		return this.http.post(`${this.API_ROOT}help/question`, { name, email, question }, this.getHeaders());
	}

	public unsubscribe(email: string) {
		return this.http.post(`${this.API_ROOT}auth2/unsubscribe`, {recipient_emails: [email]}, this.getHeaders());
	}

}
