import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "acc-services";

@Component({
	selector: "acc-main-unsubscribe",
	templateUrl: "./unsubscribe.component.html",
	styleUrls: ["./unsubscribe.component.scss"],
})
export class UnsubscribeComponent implements OnInit {
	public email: string;
	public unsubscribeComplete: boolean = false;

	constructor(private route: ActivatedRoute, private dataService: DataService) {}

	ngOnInit(): void {
		this.email = this.route.snapshot.paramMap.get("email");
	}

	unsubscribe() {
		this.dataService.unsubscribe(this.email).subscribe((res) => {
			if(res) {
				this.unsubscribeComplete = true;
			}
		});
	}
}
