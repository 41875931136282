import { Directive, ElementRef, Input, Output, OnChanges, SimpleChanges, HostListener, OnDestroy, EventEmitter, OnInit } from '@angular/core';
import { LibraryItem } from 'acc-models';

@Directive({
  selector: '[appFileActions]'
})
export class FileActionDirective implements OnInit, OnChanges, OnDestroy {

	@Input('appFileActions') isActive: boolean;
	@Input() file: LibraryItem;
	@Input() open: boolean;
	@HostListener('click', ['$event']) onclick($event) {
		this.handleClick($event);
		$event.cancelBubble = true;
	}
	@HostListener('document:click', ['$event']) onbodyclick($event) {
		this.menu.classList.remove('open');
	}
	@Output() actionClick: EventEmitter<{actionName: string, payload: any }> = new EventEmitter();

	private el:ElementRef;
	private menu:HTMLElement;

  	constructor(el:ElementRef) {
		this.el = el;
   	}

	ngOnInit():void {
		if(!this.isActive) {
			return;
		}
		let wrapper = document.createElement('div');
		wrapper.className = "fileActions";
		wrapper.setAttribute('data-action', 'expand');
		wrapper.innerHTML = "<div data-action=\"expand\"></div><div data-action=\"expand\"></div><div data-action=\"expand\"></div>";
		this.el.nativeElement.appendChild(wrapper);

		

	}
	handleClick(event) {
		if(event.target.classList.contains('disabled'))
		{
			return;
		}
		const actionName = event.target.getAttribute('data-action');
		let payload: any = {file: this.file};
		if(actionName == 'expand') { payload = { id: this.file.id}}
		this.actionClick.emit({actionName, payload });
		
	}
	ngOnChanges(changes:SimpleChanges) {
		if(changes['file'] && changes['file'].currentValue) {
			let menuHolder = document.createElement('div');
			menuHolder.className = "fileMenu";
			let list = document.createElement('ul');
			let item = document.createElement('li');
			item.innerHTML = 'Open';
			item.setAttribute('data-action', this.file['AllowEdit'] ? 'edit' : 'open');
			item.classList.add('open', 'fileAction');
			list.appendChild(item);
			['AllowAddCart', 'AllowCopy', 'AllowDelete', 'AllowMove', 'AllowShare', 'AllowShareAll'].forEach(p => {
				let item = document.createElement('li');
				item.classList.add("action"+p, 'fileAction');
				if(!this.file[p]) {
					item.classList.add('disabled');
				}
				//special case for share-all (not separate permission, coupled with share permission)
				if(p == 'AllowShareAll' && this.file.AllowShare) {
					item.classList.remove('disabled');
				}
				switch(p) {
					case 'AllowAddCart': item.innerHTML = 'Add to Cart'; item.setAttribute('data-action', 'addcart'); break;
					case 'AllowCopy': item.innerHTML = 'Copy'; item.setAttribute('data-action', 'copy');break;
					case 'AllowDelete': item.innerHTML = this.file.isCart ? 'Remove' : 'Delete'; item.setAttribute('data-action', this.file.isCart ? 'removeCart' : 'delete');break;
					case 'AllowMove': item.innerHTML = 'Move'; item.setAttribute('data-action', 'move');break;
					case 'AllowShare': item.innerHTML = 'Share'; item.setAttribute('data-action', 'share');break;
					case 'AllowShareAll': item.innerHTML = 'Share With All'; item.setAttribute('data-action', 'shareall');break;
				}
				list.appendChild(item);
			});
			menuHolder.appendChild(list);
			this.el.nativeElement.appendChild(menuHolder);
			this.menu = menuHolder;
		}
		if(changes['open']) {
			if(changes['open'].currentValue) {
				this.menu.classList.add('open');
			} else {
				this.menu.classList.remove('open');
			}
		}
	}

	ngOnDestroy():void {

	}
}
