import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import { HideAddSeats } from "../../store/actions/users.actions";
import { CheckoutService, DataService, SessionService } from "acc-services";
import { Organization } from "acc-models";
import { SetErrorMessage } from "../../store/actions/status.actions";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { CurrencyPipe, DatePipe } from "@angular/common";

@Component({
	selector: "acc-main-seats-dialog",
	templateUrl: "./seats-dialog.component.html",
	styleUrls: ["./seats-dialog.component.scss"],
})
export class SeatsDialogComponent implements OnInit {
	org;
	user;
	subscriptionLoading = false;
	subscription;
	selectedPlan = null;
	subscriptionAmount = 0;
	selectedSeats = 0;
	usersMinValue = 1;
	loading = false;
	canceledAt;

	constructor(private store: Store<IAppState>, private sessionService: SessionService, private checkoutService: CheckoutService, private dataService: DataService, private snackBar: MatSnackBar) {}

	ngOnInit() {
		this.getSubscriptionInformation();
	}

	getSubscriptionInformation() {
		this.subscriptionLoading = true;
		this.user = this.sessionService.getUser();
		this.org = this.sessionService.getItem<Organization>("organization");
		this.checkoutService.getSubscriptions(this.org.orgID).subscribe(
			(resp: any) => {
				this.subscriptionLoading = false;
				if (resp.subscription != null && resp.subscription.id != null) {
					this.subscription = resp.subscription;
					if (!!this.subscription.canceled_at) {
						this.canceledAt = moment.unix(this.subscription.canceled_at);
					}
					this.selectedPlan = this.subscription.plan.id;
					this.subscriptionAmount = this.subscription.plan.tiers[0].unit_amount_decimal; //this.subscription.latest_invoice.amount_paid
					this.selectedSeats = this.subscription.quantity + 1;
				}

				//Get Minimun users min value
				this.getMinimunOrgCount();
			},
			(err: any) => {
				this.subscriptionLoading = false;
				this.store.dispatch(new SetErrorMessage(err.error.Message));
			}
		);
	}

	getMinimunOrgCount() {
		this.dataService.getOrgUsersCount(this.org.orgID).subscribe(
			(count: any) => {
				this.usersMinValue = count + 1;
				if (this.selectedSeats == 0) {
					this.selectedSeats = this.usersMinValue;
				}
			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage(err.error.Message));
			}
		);
	}

	async submitForm() {
		this.loading = true;
		var subscription = {
			token: null,
			orgID: this.org.orgID,
			subscriptionId: this.subscription.id,
			quantity: this.selectedSeats,
		};

		this.checkoutService.updateSubscription(subscription).subscribe(
			(resp:any) => {
				this.loading = false;

				this.subscription = resp.subscription;
				var upcomingInvoice = resp.upcomingInvoice;
				var nextBillingDate;
				if(upcomingInvoice != null){
					nextBillingDate = moment.unix(upcomingInvoice.period_end);
				}
				var datePipe = new DatePipe("en-US");
				var renewDate = datePipe.transform(nextBillingDate, 'MM/dd/yyyy');

				var currencyPipe = new CurrencyPipe("en-US");
				var charge = ((this.selectedSeats * this.subscriptionAmount)/100);
				var newCharge = currencyPipe.transform(charge);

				this.snackBar.open("Thank you!, When your subscription renews on " + renewDate + ", the new charge will be "+ newCharge +".", "Close", { duration: 5000 });
				this.close();
			},
			(err) => {
				this.loading = false;
				this.store.dispatch(new SetErrorMessage("The subscription could not be updated, please contact the administrator."));
			}
		);
	}

	async renewSubscription() {
		var subscriptionRenew = {
			token: this.subscription.token,
			subscriptionId: this.subscription.id,
			plan: this.selectedPlan,
			orgId: this.org.orgID,
			quantity: this.selectedSeats,
		};

		this.checkoutService.renewSubscription(subscriptionRenew).subscribe(
			(resp:any) => {
				this.subscription = resp.subscription;
				var upcomingInvoice = resp.upcomingInvoice;

				this.loading = false;
				var nextBillingDate;
				if(upcomingInvoice != null){
					nextBillingDate = moment.unix(upcomingInvoice.period_end);
				}
				var datePipe = new DatePipe("en-US");
				var renewDate = datePipe.transform(nextBillingDate, 'MM/dd/yyyy');

				this.snackBar.open("Thank you!, When your subscription renews on " + renewDate + ", your new card will be charged.", "Close", { duration: 5000 });
				this.close();
			},
			(err) => {
				this.loading = false;
				this.store.dispatch(new SetErrorMessage("Your card could not be processed.  Please make sure you entered the number correctly."));
			}
		);
	}

	validateMinSeats(event) {
		var value = Number.parseInt(event.target.value);
		if (value < this.usersMinValue) {
			this.selectedSeats = this.usersMinValue;
		}
	}

	close() {
		this.store.dispatch(new HideAddSeats());
	}
}
