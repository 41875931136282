import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../store/state/app.state';
import * as CommunicationSelectors from '../store/selectors/communication.selectors';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {

	public title$ = this.store.pipe(
		select(CommunicationSelectors.selectTitle)
	)
	public activeCampaignId: string;
	constructor(public router: Router, private route: ActivatedRoute, private store: Store<IAppState>) { }

	ngOnInit() {
	}

}
