import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { DataService } from 'acc-services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import { RunReport } from '../../../store/actions/communication.actions';
import { selectReportResults, selectActiveCampaignId, selectActiveGroupId } from '../../../store/selectors/communication.selectors';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'acc-main-reporting-actions',
  templateUrl: './reporting-actions.component.html',
  styleUrls: ['../../shared/actions.scss']
})
export class ReportingActionsComponent implements OnInit, OnDestroy {

	private destroy$: Subject<boolean> = new Subject<boolean>();
	results$ = this.store.pipe(
		select(selectReportResults)
	)
	campaign$ = this.store.pipe(
		select(selectActiveCampaignId),
		takeUntil(this.destroy$),
		tap(c => {
			//if(c) {
				this.campaignID = c;
			//}
		})
	).subscribe();	
	group$ = this.store.pipe(
		select(selectActiveGroupId),
		takeUntil(this.destroy$),
		tap(g => {
			//if(g) {
				this.groupID = g;
			//}
		})
	).subscribe();
	private campaignID: string;
	private groupID: number
	constructor(private location: Location, private dataService: DataService, private snackBar: MatSnackBar, private store: Store<IAppState>) { }

	ngOnInit() {
	}
	goBack() {
		this.location.back();
	}
	runReport() {
		this.store.dispatch(new RunReport());
	}
	export() {
		this.dataService.getCampaignDetailCsv(this.campaignID, this.groupID).subscribe((csvUrl:any) => {
			let snackBarRef = this.snackBar.open(`Report created`, 'Close', { duration: 3000 });
	
			let link=document.createElement('a');
			link.href = csvUrl;
			link.download = csvUrl.substr(csvUrl.lastIndexOf('/') + 1);
			link.click();
		})
	}
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
