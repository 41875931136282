import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminTemplateComponent } from "./admin-template/admin-template.component";
import { CategoriesComponent } from "./categories/categories.component";
import { MediaComponent } from "./media/media.component";
import { UsersComponent } from "./users/users.component";
import { AdminTextLibraryComponent } from "./admin-text-library/admin-text-library.component";
import { WelcomeMessageComponent } from "./welcome-message/welcome-message.component";
import { AuthGuard, CanDeactivateGuard } from "acc-services";
import { TemplateComponent } from "../components/template/template.component";
import { RevisionDatesComponent } from "./revision-dates/revision-dates.component";
import { TemplatesComponent } from "./templates/templates.component";
import { CompanyComponent } from "./company/company.component";
import { SubscriptionComponent } from "./subscription/subscription.component";
import { SubscriptionLevelGuard } from "projects/acc-services/src/lib/guards/level.guard";
import { tooltips } from "acc-common";
import { QuoteAlertsComponent } from './quote-alerts/quote-alerts.component';
import { CanDeactivateAdminQuoteAlert } from '../components/guard/can-deactivate-admin-quote-alert';
import { MasterOrgGuard } from 'projects/acc-services/src/lib/guards/master-org.guard';
import { DocbuilderAdminComponent } from "./docbuilder/docbuilder-admin/docbuilder-admin.component";
import { CarrierProductsFilterComponent } from "./carrier-products-filter/carrier-products-filter.component";
import { CanNavigateAwayGuard } from "../components/guard/can-navigate-away";

const routes: Routes = [
	{
		path: "admin",
		component: TemplateComponent,
		children: [
			{
				path: "",
				component: AdminTemplateComponent,
				children: [
					{ path: "categories", component: CategoriesComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: "Text Category Admin", level: 2 } },
					{ path: "media", component: MediaComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: "Media Admin", level: 2, tooltip: tooltips.admin_media } },
					{ path: "users", component: UsersComponent, canActivate: [AuthGuard], data: { pageTitle: "User Admin" } },
					{ path: "library", component: AdminTextLibraryComponent, canActivate: [AuthGuard], data: { pageTitle: "Text Library Admin" } },
					{ path: "welcome-message", component: WelcomeMessageComponent, canActivate: [AuthGuard], data: { pageTitle: "Welcome Message Admin", tooltip: tooltips.admin_welcome } },
					{ path: "revision-dates", component: RevisionDatesComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: "Revision Date Admin", level: 2 } },
					{ path: "templates", component: TemplatesComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: "LifeQuote Template Admin", level: 2 } },
					{ path: "company", component: CompanyComponent, canActivate: [AuthGuard], canDeactivate: [CanNavigateAwayGuard], data: { pageTitle: "Company Admin", tooltip: tooltips.admin_company } },
					{ path: "subscription", component: SubscriptionComponent, canActivate: [AuthGuard], data: { pageTitle: "Subscription Admin" } },
					{ path: "quote-alerts", component: QuoteAlertsComponent, canActivate: [AuthGuard, MasterOrgGuard], canDeactivate: [CanDeactivateAdminQuoteAlert], data: { pageTitle: "Quote Alert Admin", tooltip: tooltips.admin_quote_alerts} },
					{ path: "docbuilder", component: DocbuilderAdminComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], canDeactivate: [CanDeactivateGuard], data: { pageTitle: "Proposal Builder Admin", level: 2 } },
					{ path: "carrier-products-filter", component: CarrierProductsFilterComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], canDeactivate: [CanDeactivateGuard], data: { pageTitle: "Carrier/Products Filter", level: 2 } },
				],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AdminRoutingModule {}
