import { Component, ElementRef, Output, EventEmitter , NgZone, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommDataFileInfo, Mapping, CommDataFileMap } from 'acc-models';
import { DataService, SessionService } from 'acc-services';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'acc-main-contacts-import',
  templateUrl: './contacts-import.component.html',
  styleUrls: ['./contacts-import.component.scss']
})
export class ContactsImportComponent implements OnInit {

  file: any = null;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  uploadedFileInfo:CommDataFileInfo = new CommDataFileInfo();
  commDataFileMap:CommDataFileMap = new CommDataFileMap();

  public step1completed:boolean = false;
  public step2completed: boolean = false;

  public showImportWizard:boolean = false;

  requiredFields = [];
  targetFields = [];
  targetFieldsFiltered = [[]];
  missingFields = [];
  submitted = false;

  public uploadFileSubscription:SubscriptionLike;
  public importFileSubscription:SubscriptionLike;
  @Output() onContactsImported = new EventEmitter();

  constructor(
    private dataService: DataService,
    private snackbar: MatSnackBar,
    private ngZone: NgZone,
    private router: Router,
	  private sessionService: SessionService,
  ) { }

  ngOnInit() {
    this.showImportWizard = false;
  }

  onStepChange(event){
    if(event.selectedIndex == 1 && !!this.file){
      this.uploadFileToAPI(this.file);
    }

  }

  uploadFileToAPI(file){
    this.uploadFileSubscription = this.dataService.uploadContactsCSV(file).subscribe(
	  	  (result:CommDataFileInfo) => {
        this.initCSVImport(result);
      },
      err => {
        this.snackbar.open('CSV Upload Fail' + (err.error.Message ? ': ' + err.error.Message : ''), 'OK', { panelClass: 'errorMessageSnackbar', duration: 5000 })
        this.stepper.previous();
      });
  }

  uploadFile(event) {
    this.file = event[0];
    this.step1completed = true;
  }

  deleteFile(index) {
    this.file = null;
    this.step1completed = false;
  }

  initCSVImport(result:CommDataFileInfo){
    this.uploadedFileInfo = result;
    this.requiredFields =  this.uploadedFileInfo.TargetFields.filter(option => option.required == true);
    this.mapTargetFields();
    this.commDataFileMap.filePath = result.filePath;
    this.commDataFileMap.Mappings = new Array<Mapping>();
    //BUILD MODEL.
    this.uploadedFileInfo.CSVFields.forEach((f, i) => {
      var mapping = new Mapping();
      mapping.columnNumber = f.columnNumber;
      this.commDataFileMap.Mappings.push(mapping);
    });
  }

  mapTargetFields(){
    this.targetFields = [];
    this.uploadedFileInfo.TargetFields.forEach(f => {
      this.targetFields.push(
      {
         fieldName: f.fieldName,
         required: f.required
      }
      )
    });

    this.uploadedFileInfo.CSVFields.forEach((f, i) => {
      this.targetFieldsFiltered[i] = this.targetFields;
    });

  }

  checkForRequiredMappingFields(){
    this.missingFields = [];
    for(let f of this.requiredFields) {
      var found = this.commDataFileMap.Mappings.filter(m => m.fieldName == f.fieldName).length > 0;
      if(!found){
        this.missingFields.push(f.fieldName);
      }
    }
    return this.missingFields.length <= 0;
  }

  clearTargetFieldsFiltered(index){
    this.commDataFileMap.Mappings[index].fieldName = null;
    this.targetFieldsFiltered[index] = this.targetFields;
  }

  fieldNameChange(i, value){
    this.submitted = false;
    this.targetFieldsFiltered[i] = this._filter(value);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    var filtered = this.targetFields.filter(option => option.fieldName.toLowerCase().includes(filterValue));
    return filtered;
  }

  finishAssignFields() {
    this.submitted = true;
    if(this.checkForRequiredMappingFields()) {
      this.importFile(this.commDataFileMap.filePath, this.commDataFileMap.Mappings );
      this.ngZone.run(() => {
        this.step2completed = true;
        this.stepper.selected.completed = true;
        this.stepper.selected.editable = false;
        this.stepper.next();
        });
    } else {
      this.step2completed = false;
      this.snackbar.open('All required fields must be mapped. Missing fields: ' + this.missingFields.join(', '), 'OK', { panelClass: 'errorMessageSnackbar', duration: 500000 })
    }
  }

  importFile(filePath, Mappings ){
    this.importFileSubscription = this.dataService.importContactsDataFile(this.sessionService.getUser().orgID, filePath, Mappings).subscribe(
      (result:any) => {
      this.snackbar.open('CSV Import Success.', 'OK', { duration: 5000 })
      },
      err => {
      this.snackbar.open('CSV Import Fail' + (err.error.Message ? ': ' + err.error.Message : ''), 'OK', { panelClass: 'errorMessageSnackbar', duration: 5000 })
      });

  }

  Close(){
    this.router.navigate(['/welcome']);
  }

  toogleImportWizard(value){
    this.showImportWizard = value;
  }

  backToFirstPage(){
    this.toogleImportWizard(false);
    this.onContactsImported.emit();
  }

}
