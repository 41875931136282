import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DataService, SessionService } from "acc-services";
import * as moment from "moment";
import { MtkReportResult } from "acc-models";
import { ReplaySubject, Subject } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";
import { TimePeriod } from "../../quote-engine/analytics/analytics.component";
import { ActivatedRoute } from "@angular/router";
import { DateSelectorComponent } from "../../shared/date-selector/date-selector.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
	selector: "acc-main-mkt-report",
	templateUrl: "./mkt-report.component.html",
	styleUrls: ["./mkt-report.component.scss"],
})
export class MktReportComponent implements OnInit, OnDestroy {
	@ViewChild('dateSelector') dateSelector: DateSelectorComponent;
	public defaultTimePeriod: TimePeriod;
	public dateSelected: ReplaySubject<TimePeriod> = new ReplaySubject<TimePeriod>();
	public results: Array<MtkReportResult>
	public displayedColumns = ['mktProgramDesc', 'firstName', 'lastName', 'email', 'dateSent', 'activity']
	private mode: 'Sent' | 'Upcoming'
	public minDate: Date;
	public maxDate: Date;

	private destroy$: Subject<boolean> = new Subject();
	constructor(private dataService: DataService, private sessionService: SessionService, private route: ActivatedRoute, private snackbar: MatSnackBar) {}

	ngOnInit(): void {

		this.route.data.subscribe(data => {
			this.mode = data.mode;
			switch(this.mode) {
				case 'Sent':
					this.displayedColumns = ['mktProgramDesc', 'firstName', 'lastName', 'email', 'emailDate', 'activity'];
					this.defaultTimePeriod = { name: "Custom", start: moment().startOf("year").toDate(), end: moment().toDate() };
					this.maxDate = new Date()
					break;
				case 'Upcoming':
					this.displayedColumns = ['mktProgramDesc', 'firstName', 'lastName', 'email', 'emailDate']
					this.defaultTimePeriod = { name: "Custom", start: moment().toDate(), end: moment().endOf("year").toDate() };
					this.minDate = new Date()
					break;
			}
			this.dateSelected.pipe(takeUntil(this.destroy$)).subscribe((resp) => {
				this.dataService['getMkt'+this.mode+'Report']({ orgId: this.sessionService.getUser().orgID, start: moment(resp.start).format('MM/DD/YYYY'), end: moment(resp.end).format('MM/DD/YYYY'), tzOffset: moment().utcOffset() }).subscribe((results:any) => {
					this.results = results;
				})
			});

		})
	}

	cancel(contactID, name) {
		this.dataService.updateIncludeInPrograms(false, contactID).subscribe(() => {
			this.dataService['getMktUpcomingReport']({ orgId: this.sessionService.getUser().orgID, start: moment(this.dateSelector.dateRangeSelection.start).format('MM/DD/YYYY'), end: moment(this.dateSelector.dateRangeSelection.end).format('MM/DD/YYYY'), tzOffset: moment().utcOffset() }).subscribe((results:any) => {
				this.results = results;
				this.snackbar.open(name + " has been removed", "OK", { duration: 3000})
			})

		});
	}

	sortData(e) {
		this.results = this.results.sort((a,b) => {
			let factor = e.direction == 'desc' ? -1 : 1;
			if(a[e.active] < b[e.active]) { return -1 * factor; }
			if(a[e.active] > b[e.active]) { return 1 * factor; }
			return 0;
		}).slice();
	}
	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
