import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { of, Observable, forkJoin } from "rxjs";
import { mergeMap, take, catchError, map } from "rxjs/operators";
import { environment } from "../../../../acc-main/src/environments/environment"; // not sure if it's ok to get it from here, what about acc-flow?
import { HealthDetailsAttributes } from "projects/acc-models/src/lib/models/healthDetailsAttributes";
import { QuoteIgoRequest } from "projects/acc-models/src/lib/models/quoteIgoRequest";
import { HealthProfileAlt, Organization } from 'acc-models';
import { DtcQuoteRequest } from "projects/acc-models/src/lib/models/Dtc/dtc-quote-request";
import { SessionService } from "./session.service";
import { IPipelineCredentials } from "projects/acc-models/src/lib/models/ipipelineCredentials";

@Injectable()
export class QuoteService {

	private API_ROOT = environment.api_url;
	//private API_ROOT = 'http://localhost:43392/api/';
	private QUOTE_API_ROOT;
	private GAID;
	private token: any;

	constructor(private http: HttpClient, private sessionService: SessionService) {
		if(this.sessionService.iPipelineCredentials != null){
			this.GAID = this.sessionService.iPipelineCredentials.gaid;
			this.QUOTE_API_ROOT = this.sessionService.iPipelineCredentials.quote_api_url;
		}
	}

	private getHeaders(hdrs?: any): any {
		let headers = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
		headers.set("Access-Control-Allow-Origin", "*");
		if (hdrs) {
			Object.keys(hdrs).forEach((h) => {
				headers = headers.set(h, hdrs[h]);
			});
		}
		return { headers: headers };
	}

	expireToken() {
		this.token = { access_token: "abcdef", token_type: "Bearer", expires_in: 3599 };
	}
	getQuoteFormOptions(state?: string, productType?: string): Observable<any[]> {
		const statesResponse = this.get(`${this.QUOTE_API_ROOT}lifepipe/states?gaid=${this.GAID}`);
		const quoteTypeResponse = this.get(`${this.QUOTE_API_ROOT}lifepipe/producttypecodes?gaid=${this.GAID}`);
		const termLengthResponse = this.get(`${this.QUOTE_API_ROOT}lifepipe/termlength?gaid=${this.GAID}`);
		const ridersResponse = this.get(`${this.QUOTE_API_ROOT}lifepipe/riders?gaid=${this.GAID}`);
		return forkJoin([statesResponse, quoteTypeResponse, termLengthResponse, ridersResponse]);
	}

	getGenderOptions() {
		return this.get(`${this.QUOTE_API_ROOT}lifepipe/genders?gaid=${this.GAID}`).pipe(map((res: any) => res.filter((data) => data.name !== "Unknown")));
	}

	getHealthClassOptions(productType: string) {
		return this.get(`${this.QUOTE_API_ROOT}lifepipe/healthclass?gaid=${this.GAID}&producttype=${productType}`);
	}

	getTableRatingsOptions() {
		const tableRatings: Array<any> = [];
		tableRatings.push({ name: "None", code: 0 });
		for (let index = 1; index <= 10; index++) {
			tableRatings.push({ name: `${index} or ${String.fromCharCode(97 + (index - 1)).toUpperCase()}`, code: index });
		}
		return tableRatings;
	}

	getHeightsOptions() {
		const heights: Array<any> = [];
		for (let ft = 4; ft <= 7; ft++) {
			for (let inches = 0; inches <= 11; inches++) {
				heights.push({ name: `${ft}'${inches}"`, code: `${ft}'${inches}` });
			}
		}
		return heights;
	}

	getLastUseTobaccoOptions() {
		const lastUseOptions: Array<any> = [];
		lastUseOptions.push({ name: "Currently", code: "C" });
		lastUseOptions.push({ name: "Quit less than 1 year ago", code: "0" });
		for (let index = 1; index <= 4; index++) {
			lastUseOptions.push({ name: `Quit ${index}-${index + 1} years ago`, code: `${index}` });
		}
		lastUseOptions.push({ name: "Quit more than 5 years ago", code: "5" });
		return lastUseOptions;
	}

	getTypesOfTobaccoOptions() {
		const typesOfTobacco: Array<any> = [
			{ name: "Cigarettes", code: "C" },
			{ name: "Cigars", code: "R" },
			{ name: "Pipe", code: "P" },
			{ name: "Chewing Tobacco", code: "S" },
			{ name: "Nicotine Gum", code: "G" },
			{ name: "Nicotine Patch", code: "H" },
		];
		return typesOfTobacco;
	}

	getFlatExtaYearOptions() {
		const flatExtraYears: Array<any> = [];
		flatExtraYears.push({ name: "1 Year", code: 1 });
		for (let index = 2; index <= 99; index++) {
			flatExtraYears.push({ name: `${index} Years`, code: index });
		}
		return flatExtraYears;
	}

	/// gets token and appends it to the header then runs a 'get'
	get(url: string, headers: any = {}, isRetry: boolean = false) {
		return this.getToken().pipe(
			take(1),
			mergeMap((t) => {
				const hdrs = this.getHeaders({ ...headers, Authorization: `${t.token_type} ${t.access_token}` });
				return this.http.get(url, hdrs).pipe(
					map((r: any) => r.result),
					catchError((err: any) => {
						if (err.status === 401) {
							// invalid token
							if (isRetry) {
								return of();
							}
							this.token = null;
              //Clear headers on Retry.
							return this.get(url, {}, true);
						}
					})
				);
			})
		);
	}
	/// gets token and appends it to the header then runs a 'post'
	post(url: string, body: any = {}, headers: any = {}, isRetry: boolean = false) {
		return this.getToken().pipe(
			take(1),
			mergeMap((t) => {
				const hdrs = this.getHeaders({ ...headers, Authorization: `${t.token_type} ${t.access_token}` });
				return this.http.post(url, body, hdrs).pipe(
					map((r: any) => r.result),
					catchError((err: any) => {
						if (err.status === 401) {
							// invalid token
							if (isRetry) {
								return of(err);
							}
							this.token = null;
              //Clear headers on Retry.
							return this.post(url, body, {}, true);
						}
					})
				);
			})
		);
	}
	private getToken() {
		if (this.token) {
			return of(this.token);
		}
		return this.http.get(`${this.API_ROOT}lifequote/ipipeline/token`).pipe(
			take(1),
			mergeMap((t) => {
				this.token = { access_token: t, token_type: "Bearer", expires_in: 3599 };
				return of(this.token);
			})
		);
	}

	private getJsonHeaders(hdrs?: any): any {
		let headers = new HttpHeaders().set("Content-Type", "application/json");
		headers.set("Access-Control-Allow-Origin", "*");
		if (hdrs) {
			Object.keys(hdrs).forEach((h) => {
				headers = headers.set(h, hdrs[h]);
			});
		}
		return { headers: headers };
	}

	getProductDetail(body: any) {
		return this.jsonPost(`${this.QUOTE_API_ROOT}product/details`, body);
	}

	getProductsForState(state: string) {
		return this.get(`${this.QUOTE_API_ROOT}product?gaid=${this.GAID}&state=${state}`);
	}

	/*getToIgoUrl(body: QuoteIgoRequest) {
		return this.jsonPost(`${this.QUOTE_API_ROOT}partnerData/GetToIgoUrl`, body);
	}*/

	getToIgoLink(body: QuoteIgoRequest) {
		return this.jsonPost(`${this.QUOTE_API_ROOT}partnerData/GetToIgoLink`, body);
	}

	getQuote(quoteID: string) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/${quoteID}`);
	}
	getTemplates(orgId: number) {
		return this.http.get(`${this.API_ROOT}lifequote/templates?orgID=${orgId}`);
	}
	getTemplatesAdmin(orgId: number) {
		return this.http.get(`${this.API_ROOT}lifequote/templates/admin?orgID=${orgId}`);
	}
	createProposal(quoteID: string, userID: string, templateID: any) {
		return this.http.post(`${this.API_ROOT}lifequote/quotes/${quoteID}/proposal`, { userID, templateID });
	}

	getUserQuotes(userID: string) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/alt?userID=${userID}`);
	}

	getUserQuotesFiltered(userID: string, filter: string) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/alt?userID=${userID}&filter=${filter}`);
	}

	deleteQuote(quoteID: string) {
		return this.http.delete(`${this.API_ROOT}lifequote/quotes/${quoteID}`);
	}

	getHealthProfileAttributes(quoteID: string) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/${quoteID}/healthprofile`);
	}
	saveHealthProfile(quoteID: string, profile: HealthDetailsAttributes) {
		return this.http.post(`${this.API_ROOT}lifequote/quotes/${quoteID}/healthprofile`, profile);
	}
	apply(quoteId: string, input: { recID: number; faceAmount: number }) {
		return this.http.post(`${this.API_ROOT}lifequote/quotes/${quoteId}/apply`, input);
	}
	getAnalytics({ orgId, start, end, userID, brokerId }: { orgId: number; start: string; end: string; userID: string; brokerId?: string; }) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/analytics?orgID=${orgId}&beginDate=${start}&endDate=${end}&userID=${userID}${brokerId ? "&userIDFilter=" + brokerId : ""}`);
	}
	getAnalyticsExcel({ orgId, start, end, userId, tzOffset }: { orgId: number; start: string; end: string; userId: string, tzOffset?: number}) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/analytics/xls?orgID=${orgId}&beginDate=${start}&endDate=${end}&userID=${userId}&tzOffset=${tzOffset || 0}`);
	}


	makeVideo(quoteId: string) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/${quoteId}/video`);
	}

	copyQuote(quoteId: string, name: string) {
		return this.http.post(`${this.API_ROOT}lifequote/quotes/${quoteId}/copy`, {name});
	}

	/// gets token and appends it to the header then runs a 'get'
	jsonGet(url: string, headers: any = {}, isRetry: boolean = false) {
		return this.getToken().pipe(
			take(1),
			mergeMap((t) => {
				const hdrs = this.getJsonHeaders({ ...headers, Authorization: `${t.token_type} ${t.access_token}` });
				return this.http.get(url, hdrs).pipe(
					map((r: any) => r.result),
					catchError((err: any) => {
						if (err.status === 401) {
							// invalid token
							if (isRetry) {
								return of(err);
							}
							this.token = null;
							return this.get(url, hdrs, true).pipe(map((r: any) => r.result));
						}
					})
				);
			})
		);
	}
	/// gets token and appends it to the header then runs a 'post'
	jsonPost(url: string, body: any = {}, headers: any = {}, isRetry: boolean = false) {
		return this.getToken().pipe(
			take(1),
			mergeMap((t) => {
				const hdrs = this.getJsonHeaders({ ...headers, Authorization: `${t.token_type} ${t.access_token}` });
				return this.http.post(url, body, hdrs).pipe(
					map((r: any) => r),
					catchError((err: any) => {
						if (err.status === 401) {
							// invalid token
							if (isRetry) {
								return of(err);
							}
							this.token = null;
							return this.post(url, body, hdrs, true).pipe(map((r: any) => r.result));
						}
					})
				);
			})
		);
	}

	getBasicHealthProfile(body: any) {
		return this.jsonPost(`${this.QUOTE_API_ROOT}healthProfile/basicHealthProfile?gaid=${this.GAID}`, body).toPromise();
	}

	getHealthProfile(body: any) {
		return this.jsonPost(`${this.QUOTE_API_ROOT}healthProfile?gaid=${this.GAID}`, body).toPromise();
	}

	getHealthTypes() {
		return of([
			{
				key: "healthConditions",
				name: "Health Conditions",
				img: "https://quote.ipipeline.com/static/images/xrae_health.png",
				content: ["- Blood Pressure", "- Build", "- Cholesterol", "- Tobacco Use"],
				sections: ["Physical Build", "Tobacco Use", "Blood Pressure", "Cholesterol"],
			},
			{
				key: "familyHistory",
				name: "Family History",
				img: "https://quote.ipipeline.com/static/images/xrae_family.png",
				content: ["- Cardiovascular Disease", "- Cerebrovascular Disease", "- Cancer", "- Diabetes"],
				sections: ["Father", "Mother", "Sister", "Brother"],
			},
			{
				key: "drivingViolations",
				name: "Driving Violations",
				img: "https://quote.ipipeline.com/static/images/xrae_vehicle.png",
				content: ["- Accidents", "- License Suspensions", "- License Revocations", "- Tickets"],
				sections: ["DUI / DWI", "License Suspension", "Motor Vehicle Accident", "Moving Violation", "Reckless Driving"],
			},
		]);
	}

	getMaleConditions() {
		return of([
			{ name: "Diabetes", code: "diabetes" },
			{ name: "Heart Disease", code: "heartDisease" },
			{ name: "Stroke", code: "stroke" },
			{ name: "Prostate Cancer", code: "prostateCancer" },
			{ name: "Testicular Cancer", code: "testicularCancer" },
			{ name: "Colon Cancer", code: "colonCancer" },
			{ name: "Melanoma", code: "melanoma" },
			{ name: "Pancreatic Cancer", code: "pancreaticCancer" },
			{ name: "Stomach Cancer", code: "stomachCancer" },
			{ name: "Other Cancer", code: "otherCancer" },
		]);
	}
	getFemaleConditions() {
		return of([
			{ name: "Diabetes", code: "diabetes" },
			{ name: "Heart Disease", code: "heartDisease" },
			{ name: "Stroke", code: "stroke" },
			{ name: "Colon Cancer", code: "colonCancer" },
			{ name: "Melanoma", code: "melanoma" },
			{ name: "Pancreatic Cancer", code: "pancreaticCancer" },
			{ name: "Stomach Cancer", code: "stomachCancer" },
			{ name: "Breast Cancer", code: "breastCancer" },
			{ name: "Cervical Cancer", code: "cervicalCancer" },
			{ name: "Endometrial Cancer", code: "endometrialCancer" },
			{ name: "Fallopian Tube Cancer", code: "fallopianTubeCancer" },
			{ name: "Ovarian Tube Cancer", code: "ovarianTubeCancer" },
			{ name: "Vaginal Cancer", code: "vaginalCancer" },
			{ name: "Vulvar Cancer", code: "vulvarCancer" },
			{ name: "Other Cancer", code: "otherCancer" },
		]);
	}

	//QUOTE DATA FROM API

	/// gets token and appends it to the header then runs a 'post'
	quotePost(url: string, body: any = {}, headers: any = {}, isRetry: boolean = false) {
		return this.getToken().pipe(
			take(1),
			mergeMap((t) => {
				var urlWithToken = url + (url.indexOf("?") > -1 ? "&token=" : "?token=");
				urlWithToken += t.access_token;
				return this.http.post(urlWithToken, body, headers).pipe(
					map((r: any) => r),
					catchError((err: any) => {
						return of(err);
					})
				);
			})
		);
	}

	quoteGet(url: string, headers: any = {}, isRetry: boolean = false) {
		return this.getToken().pipe(
			take(1),
			mergeMap((t) => {
				var urlWithToken = url + (url.indexOf("?") > -1 ? "&token=" : "?token=");
				urlWithToken += t.access_token;
				return this.http.get(urlWithToken, headers).pipe(
					map((r: any) => r),
					catchError((err: any) => {
						return of();
					})
				);
			})
		);
	}

	quotePut(url: string, body: any = {}, headers: any = {}, isRetry: boolean = false) {
		return this.getToken().pipe(
			take(1),
			mergeMap((t) => {
				var urlWithToken = url + (url.indexOf("?") > -1 ? "&token=" : "?token=");
				urlWithToken += t.access_token;
				return this.http.put(urlWithToken, body, headers).pipe(
					map((r: any) => r),
					catchError((err: any) => {
						return of(err);
					})
				);
			})
		);
	}

	getQuoteData(body: any, force: boolean) {
		return this.quotePost(`${this.API_ROOT}lifequote/ipipeline/quote?force=${force}`, body);
	}
	buildPdf(quoteID: string) {
		return this.quoteGet(`${this.API_ROOT}lifequote/quotes/${quoteID}/pdf`);
	}
	buildDetailPdf(quoteID: string) {
		return this.quoteGet(`${this.API_ROOT}lifequote/quotes/${quoteID}/pdf?detail=true`);
	}
	buildComparePdf(quoteID: string) {
		return this.quoteGet(`${this.API_ROOT}lifequote/quotes/${quoteID}/pdf?compare=true`);
	}
	getIPipelineJson(quoteID: string) {
		return this.quoteGet(`${this.API_ROOT}lifequote/quotes/${quoteID}/ipipelinejson`);
	}
	getCarriers() {
		return this.quoteGet(`${this.API_ROOT}lifequote/ipipeline/carriers`);
	}
	saveQuotes(body: any) {
		return this.quotePost(`${this.API_ROOT}lifequote/quotes`, body);
	}

	updateQuotes(body: any) {
		return this.quotePut(`${this.API_ROOT}lifequote/quotes`, body);
	}

	saveHealthProfileAlt(quoteID: string, healthProfileAlt: HealthProfileAlt) {
		return this.http.post(`${this.API_ROOT}lifequote/quotes/${quoteID}/healthprofilealt`, healthProfileAlt);
	}

	getHealthProfileAlt(quoteID: string) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/${quoteID}/healthprofilealt`);
	}

	getMarijuanaUseTypesOptions() {
		const useTypesOptions: Array<any> = [];
		useTypesOptions.push({ name: "Medicinal", code: 1 });
		useTypesOptions.push({ name: "Recreational", code: 2 });
		useTypesOptions.push({ name: "Both", code: 3 });
		return useTypesOptions;
	}

	getQuoteAlertFrequenciesOptions(thresholdTypeID: number) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/thresholds/${thresholdTypeID}`);
	}

	getQuoteAlertData(orgID: number, thresholdTypeID: number) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/alerts/${orgID}/${thresholdTypeID}`);
	}

	getQuoteAlertThresholds(orgID: number) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/alerts/thresholds/${orgID}`);
	}

	getQuoteAlertThresholdsTypes() {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/alerts/thresholds/types`);
	}

	saveQuoteAlertThresholds(body: any) {
		return this.quotePost(`${this.API_ROOT}lifequote/quotes/alerts/thresholds`, body);
	}

	saveDtcQuote(request: DtcQuoteRequest) {
		return this.http.post(`${this.API_ROOT}lifequote/dtc`, request);
	}


	getQuoteCarrierVideos(quoteID: string) {
		return this.http.get(`${this.API_ROOT}lifequote/quotes/videos/${quoteID}`);
	}

	//#########################################
	//START - Life Quote Credentials
	//#########################################

	getIPipelineCredentials(){
		this.http.get(`${this.API_ROOT}lifequote/ipipeline/credentials`).subscribe((resp: IPipelineCredentials) => {
			this.sessionService.iPipelineCredentials = resp;
			if(resp != null){
				this.QUOTE_API_ROOT = resp.quote_api_url;
				this.GAID = +resp.gaid;
			}
		});
	}

	loadQuoteOrgConfiguration(){
		this.getIPipelineCredentials();
		var org = this.sessionService.getItem("organization") as Organization;
		this.getCarrierProductsFilter(org.orgID);
	}

	getAllIPipelineProducts() {
		return this.quoteGet(`${this.API_ROOT}lifequote/ipipeline/products`);
	}

	//#########################################
	//END - Life Quote Credentials
	//#########################################


	//#########################################
	//START - Life Carrier/Products Filters to Apply
	//#########################################

	getCarrierProductsFilter(orgID:number) {
		return this.quoteGet(`${this.API_ROOT}lifequote/filter/products/${orgID}`).subscribe((resp: Array<any>) => {
			this.sessionService.carriersProductsFilters = resp;
		});
	}

	saveCarrierProductsFilter(filter, orgID:number) {
		return this.http.post(`${this.API_ROOT}lifequote/filter/products/${orgID}`, filter);
	}

	getCarrierProductsMasterFilter(orgID:number) {
		return this.quoteGet(`${this.API_ROOT}lifequote/filter/products/master/${orgID}`);
	}

	saveCarrierProductsFilterForChildOrganization(filter, orgID:number) {
		return this.http.post(`${this.API_ROOT}lifequote/filter/products/child/${orgID}`, filter);
	}

	//#########################################
	//END - Life Carrier/Products Filters to Apply
	//#########################################

}
