import { QuoteSaveProps } from './quoteSaveProps';
import { QuoteRecFilter } from './quoteRecFilter';

export class QuoteSave {

    public userID: string;
    public quoteID: string;
    public dateEdited: Date;
    public birthDate: Date | string;
    public actualAge: number;
    public nearestAge: number;
    public state: string;
	public clientName: string;
	public quoteDesc: string;
    public props: QuoteSaveProps;
    public recFilters: Array<QuoteRecFilter>;
    public faceAmounts: Array<String>;
    public terms: Array<String>;
    public healthClasses: Array<String>;
    public gender: string;
    public quoteType: string;
	public docID: string;
	public videoPrefs: {reason1?: string, reason2?: string, reason3?: string};
	public recFiltersVideo: Array<{recID: string, faceAmount: number}>;

    public quoteResponse: string;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}