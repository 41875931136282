import { QuoteThresholdAlertAdminThreshold } from './quoteThresholdAlertAdminThreshold';

export class QuoteThresholdAlertAdmin {
	public orgID: number;
	public thresholdTypeID: number;
	public message: string;
	public thresholds: QuoteThresholdAlertAdminThreshold[] = [];

	constructor(o?: any) {
		Object.assign(this, o);
	}
}