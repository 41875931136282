import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'acc-common-new-folder',
  templateUrl: './new-folder.component.html',
  styleUrls: ['./new-folder.component.scss']
})
export class NewFolderComponent implements OnInit {

	public newFolderName: string;
	constructor(public dialogRef: MatDialogRef<NewFolderComponent>) { }

	ngOnInit() {
	}

	newFolder() {
		this.dialogRef.close(this.newFolderName);
	}
	close() {
		this.dialogRef.close();
	}

}
