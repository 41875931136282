<mat-card >
  <mat-card-content>
    <div class="programs-container">
      <div class="header">
        <div class="action"></div>
        <div class="program">Program name</div>
        <div class="days">Send days prior to date</div>
		<div class="date-field">Contact Date Field</div>
      </div>

      <div class="row" *ngFor="let item of mktPrograms" (click)="selectMktProgram(item)" [ngClass]="{active: selectedMktProgram?.id == item.id }">

        <div class="action">
          <mat-checkbox [(ngModel)]="item.selected" ></mat-checkbox>
        </div>
        <div class="program">
          {{item.name}}
        </div>
        <div class="days">
          <mat-select [(ngModel)]="item.days">
            <mat-option *ngFor="let day of days" [value]="day.id">{{day.name}}</mat-option>
          </mat-select>
        </div>
		<div class="date-field">
			{{item.dateField}}
		</div>
      </div>
    </div>
    <div class="email-template-container">
      <div class="preview-title"><h1>Message Preview</h1><h3> - Click the program above to see a preview video.</h3></div>
      <div class="email-template" [innerHTML]="(selectedMktProgram != null)?selectedMktProgram.emailBody:''" ></div>
    </div>
  </mat-card-content>
</mat-card>
