import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { CurrencyPipe, Location } from "@angular/common";
import { MixpanelService, QuoteService, SessionService } from "acc-services";
import { Store, select } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import { SetErrorMessage } from "../../store/actions/status.actions";
import * as moment from "moment";
import { CurrencyValidators } from "../validators/currency/currency.validators";
import { ClientInformation, ProductInformation, UnderwritingInformation, Quote, QuoteRequest, BasicHealthProfile, BasicHealthProfileResponse, HealthProfile, HealthConditions, PhysicalBuild, TobaccoUse, FamilyHistory, DrivingViolation, BloodPressure, Cholesterol, HealthProfileRequest, HealthProfileAlt, Organization } from "acc-models";
import { SetQuoteRequest, SetHealthProfile, SetHealthProfileAlt, ClearTemporaryQuoteData, NewQuote } from "../../store/actions/quote.actions";
import { Router, ActivatedRoute } from "@angular/router";
import * as QuoteSelectors from "../../store/selectors/quote.selectors";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { tooltips } from "acc-common";
import { NativeDateAdapter } from "@angular/material/core";
import { MatDateFormats, DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { DOBValidators } from "../validators/dob.validators";

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
	format(date: Date, displayFormat: Object): string {
		date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
		if (displayFormat === "input") {
			let day: string = date.getDate().toString();
			day = +day < 10 ? "0" + day : day;
			let month: string = (date.getMonth() + 1).toString();
			month = +month < 10 ? "0" + month : month;
			let year = date.getFullYear();
			return `${year}-${month}-${day}`;
		}
		return date.toDateString();
	}
}
export const APP_DATE_FORMATS: MatDateFormats = {
	parse: {
		dateInput: { month: "short", year: "numeric", day: "numeric" },
	},
	display: {
		dateInput: "input",
		monthYearLabel: { year: "numeric", month: "numeric" },
		dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
		monthYearA11yLabel: { year: "numeric", month: "long" },
	},
};

@Component({
	selector: "acc-main-quote-form",
	templateUrl: "./quote-form.component.html",
	styleUrls: ["./quote-form.component.scss"],
	providers: [
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
	],
})
export class QuoteFormComponent implements OnInit, OnDestroy {
	private destroy$: Subject<boolean> = new Subject();
	private quoteRequest: QuoteRequest;

	public quoteForm: FormGroup;
	public healthProfile: HealthProfile;
	public quoteDescField = new FormControl();
	public nameField = new FormControl();
	public birthDateField = new FormControl("", [Validators.min(moment(new Date()).millisecond())]);
	public ageField = new FormControl({ disabled: true });
	public nearestAgeField = new FormControl({ disabled: true });
	public genderField = new FormControl("", [Validators.required]);
	public stateField = new FormControl("", [Validators.required]);
	public quoteTypeField = new FormControl("", [Validators.required]);
	public termsField = new FormControl("", [Validators.required]);
	public deathBenefit1Field = new FormControl("", [Validators.required, CurrencyValidators.min(10000)]);
	public deathBenefit2Field = new FormControl("", [CurrencyValidators.min(10000)]);
	public deathBenefit3Field = new FormControl("", [CurrencyValidators.min(10000)]);
	public ridersField = new FormControl([]);
	public childRiderUnitField = new FormControl("");
	public healthClassesField = new FormControl("");
	public tableRatingField = new FormControl();
	public flatExtraAmountField = new FormControl("", [CurrencyValidators.min(0), CurrencyValidators.max(999)]);
	public flatExtraYearField = new FormControl();
	public heightField = new FormControl();
	public weightField = new FormControl("", [Validators.min(0), Validators.max(500)]);
	public everUseTobaccoField = new FormControl();
	public lastUserTobaccoField = new FormControl();
	public kindOfTobaccoField = new FormControl();
	public howManyField = new FormControl();
	public genders: Array<any>;
	public states: Array<any>;
	public quoteTypes: Array<any>;
	public terms: Array<any>;
	public riders: Array<any>;
	public healthClasses: Array<any>;
	public determineHealthClass: string = "healthClassInfo";
	public tableRatings: Array<any>;
	public heights: Array<any>;
	public lastUseOptions: Array<any>;
	public typesOfTobacco: Array<any>;
	public flatExtraYears: Array<any>;
	public todaysDate: string;
	public isSubmitted: boolean = false;
	public everUseTobaccoProducts: boolean;
	private isFamilyHistorySelected: boolean = false;
	private isDrivingViolationSelected: boolean = false;
	private heightWasChosen: boolean = false;
	public fromQuote: boolean = false;
	public fromMyQuotes: boolean = false;
	public fromQuoteId;
	public fromHealthDetails: boolean = false;
	public selectedQuoteType: string = null;
	public editedQuote = false;
	public fromViewQuote: boolean = false;
	
	public everUseMarijuanaSelected = false;
	public everUseMarijuanaField = new FormControl();
	public marijuanaUseTypeField = new FormControl();
	public marijuanaFrequencyField = new FormControl();
	public marijuanaUseTypes: Array<any>;
	public marijuanaFrequencies: Array<any>;

	public tooltips = tooltips;
	public dateFormat = "YYYY-MM-DD";
	public dateDisplayFormat = "MM-DD-YYYY";
	public dateMask = {
		guide: true,
		showMask: true,
		mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
	};

	public cirSelected = false;
	public childRiderUnits = [];

	public quoteRequestResults$ = this.store.pipe(select(QuoteSelectors.selectQuoteRequest), takeUntil(this.destroy$));
	public healthProfileResults$ = this.store.pipe(select(QuoteSelectors.selectHealthProfile), takeUntil(this.destroy$));
	public healthProfileAlt$ = this.store.pipe(select(QuoteSelectors.selectHealthProfileAlt), takeUntil(this.destroy$));
	private org: Organization;
	public hasMarijuanaAlertConfiguration = false;

	constructor(private sessionService: SessionService, private quoteService: QuoteService, private store: Store<IAppState>, private fb: FormBuilder, private currencyPipe: CurrencyPipe, private router: Router, private location: Location, private route: ActivatedRoute, private mixpanelService: MixpanelService) {
		//New Quote
		//this.store.dispatch(new NewQuote());

		this.createForm();
		this.quoteRequestResults$.subscribe((qr) => this.populateForm(qr));
		this.healthProfileResults$.subscribe((hp) => this.populateHealthProfileForm(hp));
		this.healthProfileAlt$.subscribe((hpalt) => this.populateHealthProfileAltForm(hpalt));
		this.org = this.sessionService.getItem("organization") as Organization;
	}

	ngOnInit() {
		this.fromViewQuote = !!this.route.snapshot.queryParamMap.get("vq");
		this.fromMyQuotes = !!this.route.snapshot.queryParamMap.get("mq");
		this.fromHealthDetails = !!this.route.snapshot.queryParamMap.get("hd");

		//is a new Quote
		if(!this.fromViewQuote && !this.fromMyQuotes && !this.fromHealthDetails){
			this.store.dispatch(new NewQuote());
		}

		this.todaysDate = moment(new Date()).format(this.dateFormat);
		this.getData();
		this.getTableRatingsOptions();
		this.getExtraFlatYearsOptions();
		this.getHeightsOptions();
		this.getLastUseOptions();
		this.getTypesOfTobbacoOptions();
		this.getChildRiderUnits();
		this.getMarijuanaAlertConfigurationData();
		this.getMarijuanaUseTypes();
		this.getMarijuanaFrequencies();	
		

		if(!!this.fromHealthDetails){
			setTimeout(() => {
				document.getElementById('healthCard').scrollIntoView();
			}, 1000);
		}
		
	}

	get form() {
		return this.quoteForm.controls;
	}
	get clientInfo() {
		return this.form.clientInfo["controls"];
	}
	get productInfo() {
		return this.form.productInfo["controls"];
	}
	get healthClassInfo() {
		return this.form.healthClassInfo["controls"];
	}
	get healthProfileInfo() {
		return this.form.healthProfileInfo["controls"];
	}
	get healthProfileAlt() {
		return this.form.healthProfileAlt["controls"];
	}

	createForm() {
		this.quoteForm = this.fb.group({
			quoteID: null,
			clientInfo: this.fb.group(
				{
					quoteDesc: this.quoteDescField,
					name: this.nameField,
					birthDate: this.birthDateField,
					age: this.ageField,
					nearestAge: this.nearestAgeField,
					gender: this.genderField,
					state: this.stateField,
				},
				{ validators: [DOBValidators.dobOrAge, DOBValidators.validDob()] }
			),
			productInfo: this.fb.group({
				quoteType: this.quoteTypeField,
				terms: this.termsField,
				deathBenefit1: this.deathBenefit1Field,
				deathBenefit2: this.deathBenefit2Field,
				deathBenefit3: this.deathBenefit3Field,
				riders: this.ridersField,
				childRiderUnit: this.childRiderUnitField,
			}),
			healthClassInfo: this.fb.group({
				healthClasses: this.healthClassesField,
				tableRating: this.tableRatingField,
				flatExtraAmount: this.flatExtraAmountField,
				flatExtraYear: this.flatExtraYearField,
			}),
			healthProfileInfo: this.fb.group({
				height: this.heightField,
				weight: this.weightField,
				everUseTobacco: this.everUseTobaccoField,
				lastUserTobacco: this.lastUserTobaccoField,
				kindOfTobacco: this.kindOfTobaccoField,
				howMany: this.howManyField,
			}),
			healthProfileAlt: this.fb.group({
				everUsedMarijuana: this.everUseMarijuanaField,
				typeMarijuanaUsage: this.marijuanaUseTypeField,
				marijuanaFrequence: this.marijuanaFrequencyField,
			}),
		});
	}

	populateForm(q: QuoteRequest) {
		q = q || new QuoteRequest(); //in case you're clearing it out

		this.fromQuote = !!(q.inputs[0].terms && q.inputs[0].terms.length > 0);
		this.fromQuoteId = this.fromQuote ? q.inputs[0].quoteID : null;
		this.selectedQuoteType = q.quoteType;

		this.quoteForm.get("quoteID").setValue(q.inputs[0].quoteID);

		this.quoteForm
			.get("clientInfo")
			.get("name")
			.setValue(q.props ? q.props.clientName : (q.inputs[0] as any).clientName || "");
		this.quoteForm
			.get("clientInfo")
			.get("quoteDesc")
			.setValue(q.props ? q.props.quoteDesc || "" : (q.inputs[0] as any).quoteDesc || "");
		this.quoteForm
			.get("clientInfo")
			.get("birthDate")
			.setValue(q.inputs[0].birthDate ? moment(q.inputs[0].birthDate).format(this.dateDisplayFormat) : "");
		this.quoteForm
			.get("clientInfo")
			.get("age")
			.setValue(q.inputs[0].actualAge || this.calculateAge(q.inputs[0].birthDate));
		this.quoteForm
			.get("clientInfo")
			.get("nearestAge")
			.setValue(q.inputs[0].nearestAge || this.calculateNearestAge(q.inputs[0].birthDate));
		this.quoteForm.get("clientInfo").get("gender").setValue(q.inputs[0].gender);
		this.quoteForm.get("clientInfo").get("state").setValue(q.inputs[0].state);

		this.quoteForm.get("productInfo").get("quoteType").setValue(q.quoteType);
		this.quoteForm
			.get("productInfo")
			.get("terms")
			.setValue((q.inputs[0].terms || []).map((t) => String(t)));
		this.quoteForm.get("productInfo").get("deathBenefit1").setValue(q.inputs[0].faceAmounts[0]);
		this.transformToCurrency("productInfo", "deathBenefit1");
		if (q.inputs[0].faceAmounts[1] || 0 != 0) {
			this.quoteForm.get("productInfo.deathBenefit2").setValue(q.inputs[0].faceAmounts[1]);
			this.transformToCurrency("productInfo", "deathBenefit2");
		} else {
			this.quoteForm.get("productInfo.deathBenefit2").setValue("");
		}
		if (q.inputs[0].faceAmounts[2] || 0 != 0) {
			this.quoteForm.get("productInfo.deathBenefit3").setValue(q.inputs[0].faceAmounts[2]);
			this.transformToCurrency("productInfo", "deathBenefit3");
		} else {
			this.quoteForm.get("productInfo.deathBenefit3").setValue("");
		}
		const riders = [];
		if (q.inputs[0].props.isAccidentalDeathBenefitRider) {
			riders.push("isAccidentalDeathBenefitRider");
		}
		if (q.inputs[0].props.isChildTermRider) {
			riders.push("isChildTermRider");
		}
		if (q.inputs[0].props.isReturnOfPremiumRider) {
			riders.push("isReturnOfPremiumRider");
		}
		if (q.inputs[0].props.isWaiverOfPremiumRider) {
			riders.push("isWaiverOfPremiumRider");
		}
		this.cirSelected = q.inputs[0].props.isChildTermRider;

		this.quoteForm.get("productInfo").get("riders").setValue(riders);
		if (!!q.inputs[0].props.cru) {
			this.quoteForm.get("productInfo").get("childRiderUnit").setValue(q.inputs[0].props.cru);
		} else {
			this.quoteForm.get("productInfo").get("childRiderUnit").setValue(q.inputs[0].props["childRiderUnit"]);
		}

		this.quoteForm.get("healthClassInfo").get("healthClasses").setValue(q.inputs[0].healthClasses);
		this.quoteForm.get("healthClassInfo").get("tableRating").setValue(q.inputs[0].props.tableRating);
		this.quoteForm.get("healthClassInfo").get("flatExtraAmount").setValue(q.inputs[0].props.flatExtraAmount);
		this.quoteForm.get("healthClassInfo").get("flatExtraYear").setValue(q.inputs[0].props.flatExtraYearEnd);

		this.quoteForm.get("healthProfileAlt").get("everUsedMarijuana").setValue("false");

		this.onProductTypeChange(q.quoteType);
	}

	populateHealthProfileAltForm(hp: HealthProfileAlt) {
		if (hp) {
			let usedMarijuana = (hp.everUsedMarijuana)?hp.everUsedMarijuana.toString():"false";
			this.healthProfileAlt.everUsedMarijuana.setValue(usedMarijuana);
			this.healthProfileAlt.typeMarijuanaUsage.setValue((hp.typeMarijuanaUsage)?parseInt(hp.typeMarijuanaUsage.toString()):null);
			this.healthProfileAlt.marijuanaFrequence.setValue((hp.marijuanaFrequence)?parseInt(hp.marijuanaFrequence.toString()):null);
			this.onEverUseMarijuanaChange(usedMarijuana === "true");
		}
	}

	populateHealthProfileForm(hp: HealthProfile) {
		if (hp) {
			this.healthProfile = hp;
			this.determineHealthClass = hp.healthClass;
			this.healthProfileInfo.height.setValue(hp.healthConditions.physicalBuild.height);
			this.healthProfileInfo.weight.setValue(hp.healthConditions.physicalBuild.weight);
			this.healthProfileInfo.everUseTobacco.setValue(String(hp.healthConditions.tobaccoUse.everUseTobacco));
			this.everUseTobaccoProducts = hp.healthConditions.tobaccoUse.everUseTobacco;
			this.healthProfileInfo.lastUserTobacco.setValue(hp.healthConditions.tobaccoUse.lastuserTobacco);
			this.healthProfileInfo.kindOfTobacco.setValue(hp.healthConditions.tobaccoUse.kindOfTobacco);
			this.healthProfileInfo.howMany.setValue(hp.healthConditions.tobaccoUse.howMany);
		}
	}

	setDefaultQuoteType() {
		//if (!this.fromQuote ) {
		if(	this.selectedQuoteType == null){
			this.onProductTypeChange(this.quoteTypes[0].code);
			this.quoteForm.get("productInfo").get("quoteType").setValue(this.quoteTypes[0].code);
		}
	}

	filterQuoteTypeTerm(code) {
		let quoteCodesForTerms = ["Term", "TERMSI"]; //Filter only by Term
		return quoteCodesForTerms.indexOf(code) > -1;
	}

	getDataFromQuoteForm() {
		this.quoteService.getQuoteFormOptions().subscribe(
			(responseList) => {
				this.states = responseList[0];
				this.quoteTypes = responseList[1].filter((quoteType) => this.filterQuoteTypeTerm(quoteType.code));
				this.terms = responseList[2];
				this.riders = responseList[3];

				this.setDefaultQuoteType();
			},
			(err: any) => this.store.dispatch(new SetErrorMessage("Could not access pipeline api"))
		);
	}
	getData() {
		this.quoteService.getGenderOptions().subscribe(
			(data: any) => {
				if (data) {
					this.genders = data;
					this.getDataFromQuoteForm();
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
				}
			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
			}
		);
	}
	getHealthClassOptions(productType: string) {
		this.quoteService.getHealthClassOptions(productType).subscribe(
			(data: any) => {
				if (data) {
					this.healthClasses = data;
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
				}
			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
			}
		);
	}
	getTableRatingsOptions() {
		this.tableRatings = this.quoteService.getTableRatingsOptions();
	}
	getExtraFlatYearsOptions() {
		this.flatExtraYears = this.quoteService.getFlatExtaYearOptions();
	}
	getHeightsOptions() {
		this.heights = this.quoteService.getHeightsOptions();
	}
	getLastUseOptions() {
		this.lastUseOptions = this.quoteService.getLastUseTobaccoOptions();
	}
	getTypesOfTobbacoOptions() {
		this.typesOfTobacco = this.quoteService.getTypesOfTobaccoOptions();
	}

	getChildRiderUnits() {
		for (let index = 1; index <= 50; index++) {
			this.childRiderUnits.push(index);
		}
	}

	getMarijuanaUseTypes() {
		this.marijuanaUseTypes = this.quoteService.getMarijuanaUseTypesOptions();
	}

	getMarijuanaFrequencies() {
		this.quoteService.getQuoteAlertFrequenciesOptions(1).subscribe((results:any[]) => {
			this.marijuanaFrequencies = results;
		});
	}

	getMarijuanaAlertConfigurationData(){
		var masterOrgID = this.sessionService.getMasterOrgID();
		var orgID = (masterOrgID == null || masterOrgID <=0)? this.org.orgID : masterOrgID;
		this.quoteService.getQuoteAlertThresholds(orgID).subscribe(
			(resp: any[]) => {
				this.hasMarijuanaAlertConfiguration = resp.filter(x => x["thresholdTypeID"] == 1).length > 0;
			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
			}
		);
	}

	async getBasicHealthProfile(basicHealthProfile: BasicHealthProfile) {
		const basicHealthProfileRes = new BasicHealthProfileResponse();
		await this.quoteService
			.getBasicHealthProfile(basicHealthProfile)
			.then((data: BasicHealthProfileResponse) => {
				if (data) {
					basicHealthProfileRes.result = data.result;
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
				}
			})
			.catch((err: any) => this.store.dispatch(new SetErrorMessage("Could not access pipeline api")));
		return basicHealthProfileRes;
	}

	async onSubmit(action: string) {
		this.isSubmitted = true;
		if (this.quoteForm.valid) {
			this.store.dispatch(new ClearTemporaryQuoteData());
			await this.getDataFromForm();
			if (action === "onResults") {
				//Edit Quote
				this.editedQuote =  (this.fromMyQuotes && this.quoteForm.dirty) || (this.fromQuote && !this.fromMyQuotes) || (this.fromViewQuote && this.quoteForm.dirty);
				var data = {
					force: true,
					editedQuote: this.editedQuote,
				};

				this.trackNewLifeQuote();

				this.router.navigateByUrl("/quote/results", { state: { data: data } });
			} else {
				this.router.navigateByUrl("/quote/health-types");
			}
		} else {
			if(document.getElementsByClassName('mat-form-field ng-invalid')[0] != null){
				document.getElementsByClassName('mat-form-field ng-invalid')[0].scrollIntoView();
				if(document.getElementsByClassName('mat-form-field ng-invalid')[0].getBoundingClientRect().top < 130) {
					window.scrollBy(0, -125);//to make up for the fixed header
				}
			}
		}
	}

	async getDataFromForm() {
		if (this.quoteForm.valid) {
			const clientInformation = this.mapFormToClientInfo(this.quoteForm.value.clientInfo);
			const productInformation = this.mapFormToProductInfo(this.quoteForm.value.productInfo);
			const healthProfile = this.mapFormToHealthProfile(this.quoteForm.value.healthProfileInfo);
			const healthProfileAlt = this.mapFormToHealthProfileAlt(this.quoteForm.value.healthProfileAlt);

			//if no dob provided, estimate it from age
			let usingFakeDob: boolean = false;
			if (!clientInformation.birthDate || isNaN(Date.parse(clientInformation.birthDate))) {
				usingFakeDob = true;
				clientInformation.birthDate = this.estimateDob(clientInformation.age, clientInformation.nearestAge);
			}

			const underwritingInformation = this.determineHealthClass === "healthClassInfo" ? this.mapFormToHealthClassInfo(this.quoteForm.value.healthClassInfo) : await this.mapHealthProfileToUnderwriterInfo(healthProfile, clientInformation, productInformation);
			const quote = new Quote(this.quoteForm.value.quoteID, clientInformation, productInformation, underwritingInformation, healthProfile, healthProfileAlt);

			if (usingFakeDob) {
				clientInformation.birthDate = "";
			}

			this.store.dispatch(new SetQuoteRequest(quote.getRequest(this.sessionService.carriersProductsFilters)));
			this.store.dispatch(new SetHealthProfile(quote.healthProfileInformation));
			this.store.dispatch(new SetHealthProfileAlt(quote.healthProfileAlt));
		}
	}

	mapFormToClientInfo(clientInfo: any): ClientInformation {
		let dob = '';
		if(clientInfo.birthDate) {
			dob = moment(clientInfo.birthDate.split('-').join('/')).format(this.dateFormat);
		}
		return <ClientInformation>{
			quoteDesc: clientInfo.quoteDesc,
			clientName: clientInfo.name,
			birthDate: dob,
			age: clientInfo.age,
			nearestAge: clientInfo.nearestAge,
			gender: clientInfo.gender,
			state: clientInfo.state,
		};
	}

	mapFormToProductInfo(productInfo: any): ProductInformation {
		const deathBenefits: Array<string> = [productInfo.deathBenefit1, productInfo.deathBenefit2, productInfo.deathBenefit3].filter((benefit) => benefit !== "");
		return <ProductInformation>{
			quoteType: productInfo.quoteType,
			terms: productInfo.terms,
			faceAmounts: deathBenefits.map((benefit) => this.currencyToNumber(benefit).toString()),
			isAccidentalDeathBenefitRider: productInfo.riders.includes("isAccidentalDeathBenefitRider"),
			isChildTermRider: productInfo.riders.includes("isChildTermRider"),
			isReturnOfPremiumRider: productInfo.riders.includes("isReturnOfPremiumRider"),
			isWaiverOfPremiumRider: productInfo.riders.includes("isWaiverOfPremiumRider"),
			childRiderUnit: productInfo.childRiderUnit,
		};
	}

	mapFormToHealthClassInfo(healthClassInfo: any): UnderwritingInformation {
		return <UnderwritingInformation>{
			healthClasses: healthClassInfo.healthClasses,
			tableRating: healthClassInfo.tableRating ? healthClassInfo.tableRating : 0,
			flatExtraAmount: this.currencyToNumber(healthClassInfo.flatExtraAmount),
			flatExtraYearEnd: healthClassInfo.flatExtraYear ? healthClassInfo.flatExtraYear : 0,
		};
	}

	async mapHealthProfileToUnderwriterInfo(healthProfileInfo: HealthProfile, clientInformation: ClientInformation, productInformation: ProductInformation) {
		const healthClasses: Array<string> = [];
		//get value from store
		let healthProfile;
		//await this.store.select(QuoteSelectors.selectHealthProfile).pipe(take(1)).subscribe(p => healthProfile = p);
		//if(healthProfile == null){
		//	healthProfile = healthProfileInfo;
		//}
		//NOT USING THE STORE OPTION
		healthProfile = healthProfileInfo;

		if (healthProfile) {
			const healthProfileRes = await this.getHealthProfileReq(HealthProfileRequest.fromHealthDetailsForm(healthProfile, clientInformation, productInformation, true));
			if (healthProfileRes) {
				const distinctHealthProfiles = healthProfileRes.filter((value, i, arr) => arr.findIndex((val) => val.healthClassCode === value.healthClassCode) === i);
				distinctHealthProfiles.forEach((val) => {
					healthClasses.push(val.healthClassCode);
				});
			}
		} else {
			//we don't have any health profile data, so use all classes
			healthClasses.push("PP");
			healthClasses.push("P");
			healthClasses.push("S");
			healthClasses.push("TP");
			healthClasses.push("TS");
			healthClasses.push("SP");
		}
		const underwritingInfo = this.mapFormToHealthClassInfo({ healthClasses, tableRating: 0, flatExtraAmount: 0, flatExtraYear: 0 });
		return underwritingInfo;
	}
	async getHealthProfileReq(healthProfileReq: HealthProfileRequest) {
		let healthProfileRes: any;
		await this.quoteService
			.getHealthProfile(healthProfileReq)
			.then((data: any) => {
				if (data) {
					healthProfileRes = data.result;
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
				}
			})
			.catch((err: any) => this.store.dispatch(new SetErrorMessage("Could not access pipeline api")));
		return healthProfileRes;
	}

	mapFormToBasicHealthProfileInfo(healthProfileInfo: HealthProfile, clientInformation: ClientInformation, productInformation: ProductInformation): BasicHealthProfile {
		return <BasicHealthProfile>{
			dobDay: +moment(clientInformation.birthDate).format("D"),
			dobMonth: +moment(clientInformation.birthDate).format("M"),
			dobYear: +moment(clientInformation.birthDate).format("YYYY"),
			everUseTobacco: healthProfileInfo.healthConditions.tobaccoUse.everUseTobacco,
			faceAmounts: productInformation.faceAmounts.map((amount) => +amount),
			gender: clientInformation.gender,
			height: healthProfileInfo.healthConditions.physicalBuild.height,
			howMany: healthProfileInfo.healthConditions.tobaccoUse.howMany ? healthProfileInfo.healthConditions.tobaccoUse.howMany : 0,
			kindOfTobacco: healthProfileInfo.healthConditions.tobaccoUse.kindOfTobacco,
			lastuserTobacco: healthProfileInfo.healthConditions.tobaccoUse.lastuserTobacco,
			productType: productInformation.quoteType,
			terms: productInformation.terms.map((term) => +term),
			weight: healthProfileInfo.healthConditions.physicalBuild.weight ? healthProfileInfo.healthConditions.physicalBuild.weight.toString() : "",
		};
	}

	mapFormToHealthProfile(hp: any): HealthProfile {
		const healthProfile = new HealthProfile({
			healthClass: this.determineHealthClass,
			healthConditions: new HealthConditions({
				physicalBuild: new PhysicalBuild({
					height: hp.height ? hp.height : "",
					weight: hp.weight,
				}),
				tobaccoUse: new TobaccoUse({
					everUseTobacco: hp.everUseTobacco === "true",
					lastuserTobacco: hp.lastUserTobacco ? hp.lastUserTobacco : "B",
					kindOfTobacco: hp.kindOfTobacco ? hp.kindOfTobacco : "N",
					howMany: hp.howMany > 0 ? hp.howMany : "",
				}),
			}),
		});
		healthProfile.healthConditions.isSelected = healthProfile.healthConditions.physicalBuild.height || healthProfile.healthConditions.physicalBuild.weight || healthProfile.healthConditions.tobaccoUse.everUseTobacco ? true : false;
		if (this.healthProfile) {
			healthProfile.healthConditions.bloodPressure = this.healthProfile.healthConditions.bloodPressure ? this.healthProfile.healthConditions.bloodPressure : new BloodPressure();
			healthProfile.healthConditions.cholesterol = this.healthProfile.healthConditions.cholesterol ? this.healthProfile.healthConditions.cholesterol : new Cholesterol();
			healthProfile.familyHistory = this.healthProfile.familyHistory ? this.healthProfile.familyHistory : new FamilyHistory({ isSelected: false });
			healthProfile.drivingViolation = this.healthProfile.drivingViolation ? this.healthProfile.drivingViolation : new DrivingViolation({ isSelected: false });
		}
		return healthProfile;
	}

	mapFormToHealthProfileAlt(hp: any): HealthProfileAlt {
		const healthProfileAlt = new HealthProfileAlt({
			everUsedMarijuana: hp.everUsedMarijuana,
			typeMarijuanaUsage: hp.typeMarijuanaUsage,
			marijuanaFrequence: hp.marijuanaFrequence,
		});

		return healthProfileAlt;
	}

	estimateDob(age: number, nearestAge: number) {
		const today = new Date();
		let dob: Date;
		if (nearestAge) {
			dob = new Date(today.getFullYear() - age, nearestAge > age ? today.getMonth() - 5 : today.getMonth() + 1, 1);
		} else {
			dob = new Date(today.getFullYear() - age, today.getMonth() + 1, 1);
		}
		return moment(dob).format(this.dateFormat);
	}
	currencyToNumber(amount: string): number {
		if (amount) {
			return Number.parseInt(String(amount).replace(/\,/g, ""), 10);
		} else {
			return 0;
		}
	}

	onProductTypeChange(value: string) {
		if (value) {
			this.getHealthClassOptions(value);
		} else {
			this.healthClasses = null;
		}
	}

	onBirthDateChange(dateValue: string) {
		if (dateValue && dateValue !== "__/__/____") {
			if (moment(dateValue, this.dateDisplayFormat).isAfter(this.todaysDate)) {
				//var today = moment(this.todaysDate, this.dateFormat)
				this.clientInfo.birthDate.setValue(moment(this.todaysDate, this.dateFormat).format(this.dateDisplayFormat));
			}
			var momentDate = moment(dateValue, this.dateDisplayFormat); //check with strict moment
			if (momentDate.isValid()) {
				//this.birthDateField.setErrors(null);
				this.clientInfo.age.setValue(this.calculateAge(this.clientInfo.birthDate.value));
				this.clientInfo.nearestAge.setValue(this.calculateNearestAge(this.clientInfo.birthDate.value));
			} else {
				//this.birthDateField.setErrors({ invalidDate: true });
			}
		}
	}

	calculateAge(dateOfBirth): number | "" {
		return dateOfBirth ? moment(new Date()).diff(dateOfBirth, "years") : "";
	}
	calculateNearestAge(dateOfBirth): number {
		return +moment(dateOfBirth).fromNow(true).replace("years", "");
	}
	checkAge() {
		if (this.birthDateField.value && moment.isDate(this.birthDateField.value)) {
			this.onBirthDateChange(moment(this.birthDateField.value).format(this.dateDisplayFormat));
			return;
		}
		if (this.nearestAgeField.value && this.ageField.value) {
			if (this.nearestAgeField.value < this.ageField.value) {
				this.nearestAgeField.setErrors({ lessThanAge: true });
			} else {
				if (this.nearestAgeField.value !== this.ageField.value && this.nearestAgeField.value !== this.ageField.value + 1) {
					this.nearestAgeField.setErrors({ invalidNearestAge: true });
				} else {
					this.nearestAgeField.setErrors(null);
				}
			}
		}
	}

	onlyAllowNumbers(e) {
		if (e.shiftKey == true) {
			e.preventDefault();
		}
		// Allow Only: keyboard 0-9, numpad 0-9, backspace, tab, left arrow, right arrow, delete
		if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 46) {
			// Allow normal operation
		} else {
			// Prevent the rest
			e.preventDefault();
		}
	}
	transformToCurrency(group: string, field: string) {
		const fieldValue = this.quoteForm.get(`${group}.${field}`).value;
		if (fieldValue && !String(fieldValue).match(".*[a-zA-Z].*")) {
			const value = Number.parseInt(String(fieldValue).replace(/\,/g, ""), 10);
			const formattedAmount = this.currencyPipe.transform(value, "USD", "symbol", "1.0").replace("$", "");
			this.quoteForm.get(`${group}.${field}`).setValue(formattedAmount);
		}
	}

	onHealthClassChange(value: string) {
		this.resetGroupForm(this.determineHealthClass);
		this.determineHealthClass = value;
		if (value === "healthClassInfo") {
			this.healthClassInfo.healthClasses.setValidators([Validators.required]);
		} else {
			this.healthClassInfo.healthClasses.clearValidators();
		}
		this.healthClassInfo.healthClasses.updateValueAndValidity();
	}

	onEverUseTobaccoChange(value: boolean) {
		this.everUseTobaccoProducts = value;
		if (!value) {
			this.healthProfileInfo.lastUserTobacco.setValue("");
			this.healthProfileInfo.kindOfTobacco.setValue("");
			this.healthProfileInfo.howMany.setValue("");
		}
	}

	onHeightOpened(opened) {
		if (opened && !this.healthProfileInfo.height.value) {
			//this.healthProfileInfo.height.setValue("5'6");
			document.querySelector("span.h5_6").scrollIntoView();
		}
		/*
		if(!opened && !this.heightWasChosen) {
			this.healthProfileInfo.height.setValue("");
		} 
		*/
	}

	onRiderSelectionChange() {
		this.cirSelected = this.productInfo.riders.value.includes("isChildTermRider");
		if (!this.cirSelected) {
			this.productInfo.childRiderUnit.setValue(null);
		}
	}

	onEverUseMarijuanaChange(value: boolean) {
		this.everUseMarijuanaSelected = value;
		if (!value) {
			this.healthProfileAlt.typeMarijuanaUsage.setValue(null);
			this.healthProfileAlt.marijuanaFrequence.setValue(null);
		}
	}

	resetGroupForm(groupName: string) {
		this.form[`${groupName}`].reset();
	}

	async onShowTopTen() {
		this.isSubmitted = true;
		if (this.quoteForm.valid) {
			this.store.dispatch(new ClearTemporaryQuoteData());
			await this.getDataFromForm();
			//Edit Quote
			this.editedQuote =  (this.fromMyQuotes && this.quoteForm.dirty) || (this.fromQuote && !this.fromMyQuotes) || (this.fromViewQuote && this.quoteForm.dirty);
			var data = {
				editedQuote: this.editedQuote,
			};

			this.trackNewLifeQuote();

			this.router.navigateByUrl("/quote/top-10", { state: { data: data } });
		} else {
			document.getElementsByClassName('mat-form-field ng-invalid')[0].scrollIntoView();
			if(document.getElementsByClassName('mat-form-field ng-invalid')[0].getBoundingClientRect().top < 130) {
				window.scrollBy(0, -125);//to make up for the fixed header
			}
		}
	}

	backToQuote() {
		this.router.navigateByUrl("/quote/results");
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	selectTerm() {

		if (this.termsField.value.length < 5) {
			this.termsField.setErrors(null);
		} else {
			this.termsField.setErrors({termSelectionError: true});
		}
	}

	trackNewLifeQuote(){
		var trackNewQuote = (this.fromQuote && !this.fromMyQuotes && !this.fromViewQuote);
		if(trackNewQuote){
			//Mixpanel Event
			this.mixpanelService.trackNewLifeQuote();
		}
	}

}
