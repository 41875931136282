import { Directive, ElementRef, Input, HostListener, AfterViewInit } from '@angular/core';

const HEADER_HEIGHT: number = 64;
const FOOTER_HEIGHT = 20;

@Directive({
  selector: '[appSticky]'
})

export class StickyDirective implements AfterViewInit {

	@Input('appSticky') pixels: number; //value is number of pixels worth of scrolling before adding sticky
	@Input('appStickyStyle') style: any = {}; //any other css you want to add to sticky (ie, overflow:auto;)
	@HostListener("window:scroll", [])
    onWindowScroll() {
		if(window.pageYOffset > (this.pixels || 254)) {
			Object.keys(this.stickyStyle).forEach(k => this.element.nativeElement.style[k] = this.stickyStyle[k]);
			if(this.style.height == 'to-bottom') {
				const height = window.innerHeight - (parseInt(this.style.top) || HEADER_HEIGHT) - FOOTER_HEIGHT;
				this.element.nativeElement.parentElement.style.height = height + 40 + 'px';
				this.element.nativeElement.style.height = height + 'px';
			}
			this.isSticky = true;
			this.element.nativeElement.classList.add('sticky');
		} else {
			Object.keys(this.scrollStyle).forEach(k => this.element.nativeElement.style[k] = this.scrollStyle[k]);
			this.isSticky = false;
			this.element.nativeElement.classList.remove('sticky');
		}
	}
	
	private element:ElementRef;
	private isSticky: boolean = false;
	
	private stickyStyle: any = {};
	private scrollStyle: any = {};

  	constructor(el:ElementRef) {
		this.element = el;
   	}

	ngAfterViewInit(): void {
		let stickyWidth = this.element.nativeElement.offsetWidth;
		if(this.style.widthAdj) {
			stickyWidth += this.style.widthAdj;
		}
		this.stickyStyle = {
			position: 'fixed',
			width: stickyWidth + 'px',
			//marginLeft: this.element.nativeElement.offsetLeft - parseInt(window.getComputedStyle(this.element.nativeElement.offsetParent, null).getPropertyValue('padding-left')) + 'px',
			top: this.style.top || '84px',
			...this.style,
			//height: this.style.height ? (this.style.height == 'to-bottom' ? window.innerHeight - (parseInt(this.style.top) || HEADER_HEIGHT) - FOOTER_HEIGHT + 'px' : this.style.height) : '',
			height: "calc(100vh - " + (parseInt(this.style.top) || HEADER_HEIGHT) + "px)"
		};
		Object.keys(this.style).forEach(k => this.scrollStyle[k] = ''); //unset everything that the sticky style is setting
		this.scrollStyle = {
			...this.scrollStyle,
			position: '',
			//width: this.style.width || (stickyWidth + 'px'),
			//marginLeft: '',
			top: ''
		}

		if(this.style.height == 'to-bottom') {
			//listen for resizing
			window.addEventListener('resize', () => {
				if(this.isSticky) {
					const height = window.innerHeight - (parseInt(this.style.top) || HEADER_HEIGHT) - FOOTER_HEIGHT + 'px';
					this.element.nativeElement.parentElement.style.height = height;
					this.element.nativeElement.style.height = height;
				}
			})
		}
	}
}
