import { IMediaState, initialMediaState } from '../state/media.state';
import { MediaActions, MediaActionTypes } from '../actions/media.actions';
import { MediaCategoryActionTypes } from '../actions/media.actions';
import { MediaCategory } from 'acc-models';

export function mediaReducer(state:IMediaState = initialMediaState, action:MediaActions):IMediaState {

	function findCategory(categories:Array<MediaCategory>, cat:MediaCategory): MediaCategory {
		let thisCat = null
		for(let c of categories) {
			if(c.mediaCategoryID == cat.mediaCategoryID) {
				thisCat = c;
				break;
			}
			if(c.MediaCategories.length > 0) {
				let found:MediaCategory = findCategory(c.MediaCategories, cat);
				if(found) {
					return found;
				}
			}
		}
		return thisCat;
	}


	switch(action.type) {
		case MediaActionTypes.GET_MEDIAS:
			return state;
		case MediaActionTypes.GET_MEDIAS_SUCCESS:
			return { ...state, Medias: action.medias};
		case MediaActionTypes.EDIT_MEDIA:
			return { ...state, EditingMedia: action.media };
		case MediaActionTypes.EDIT_MEDIA_CONFIRM:
			return { ...state, EditingMedia: action.media, AddMediaFile: action.mediaFile, AddMediaThumbnail: action.mediaThumbnail };
		case MediaActionTypes.EDIT_MEDIA_CANCEL: 
			return { ...state, EditingMedia: null }
		case MediaActionTypes.EDIT_MEDIA_SUCCESS:
			return { ...state, Medias: state.Medias.map(m => m.mediaItemID === state.EditingMedia.mediaItemID ? action.media : m), EditingMedia: null, AddMediaFile: null, AddMediaThumbnail: null };
		case MediaActionTypes.DELETE_MEDIA:
			return { ...state, DeletingMedia: action.media };
		case MediaActionTypes.DELETE_MEDIA_CONFIRM:
			return state;
		case MediaActionTypes.DELETE_MEDIA_CANCEL:
			return { ...state, DeletingMedia: null }
		case MediaActionTypes.DELETE_MEDIA_SUCCESS:
			return { ...state, Medias: state.Medias.filter(m => m.mediaItemID !== state.DeletingMedia.mediaItemID), DeletingMedia: null };
		case MediaActionTypes.ADD_MEDIA:
			return { ...state, EditingMedia: action.media};
		case MediaActionTypes.ADD_MEDIA_CONFIRM:
			return { ...state, EditingMedia: action.media, AddMediaFile: action.mediaFile, AddMediaThumbnail: action.mediaThumbnail};
		case MediaActionTypes.ADD_MEDIA_CANCEL:
			return { ...state, EditingMedia: null}
		case MediaActionTypes.ADD_MEDIA_SUCCESS:
			return { ...state, Medias: [ ...state.Medias, action.media ], EditingMedia: null, AddMediaFile: null, AddMediaThumbnail: null };
		case MediaActionTypes.MOVE_MEDIA:
			return { ...state, MovingMedia: action.media, DestinationFolder: action.destinationFolder };
		case MediaActionTypes.MOVE_MEDIA_SUCCESS:
			return { ...state, DestinationFolder: null, Medias: state.Medias.filter(m => m.mediaItemID !== state.MovingMedia.mediaItemID), MovingMedia: null };

			
		case MediaCategoryActionTypes.GET_MEDIA_CATEGORIES:
			return state;
		case MediaCategoryActionTypes.GET_MEDIA_CATEGORIES_SUCCESS:
			return { ...state, MediaCategories: action.mediaCategories };
		case MediaCategoryActionTypes.ACTIVATE_MEDIA_CATEGORY:
			const activateCat = findCategory(state.MediaCategories, action.mediaCategory);
			if(activateCat) { activateCat.isOpen = true; }
			return { ...state, ActiveMediaCategory: action.mediaCategory }
		case MediaCategoryActionTypes.TOGGLE_MEDIA_CATEGORY: 
			let tempMediaCategoriesToggle = Array.from(state.MediaCategories)
			const toggleCat = findCategory(tempMediaCategoriesToggle, action.mediaCategory);
			if(toggleCat) {
				toggleCat.isOpen = !toggleCat.isOpen;
			}
			return { ...state, MediaCategories: tempMediaCategoriesToggle }
		case MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY:
			return { ...state, EditingMediaCategory: action.mediaCategory };
		case MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY_CONFIRM: 
			return state;
		case MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY_CANCEL:
			return { ...state, EditingMediaCategory: null };
		case MediaCategoryActionTypes.EDIT_MEDIA_CATEGORY_SUCCESS:
			const tempMediaCategoriesEdit = Array.from(state.MediaCategories);
			const editCat = findCategory(tempMediaCategoriesEdit, state.EditingMediaCategory);
			if(editCat) {
				editCat.mediaCategoryDesc = state.EditingMediaCategory.mediaCategoryDesc; //this is the only thing you'd be changing here
			}
			
			return { ...state, MediaCategories: tempMediaCategoriesEdit, EditingMediaCategory: null };
		case MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY: 
			return { ...state, DeletingMediaCategory: action.mediaCategory };
		case MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY_CONFIRM:
			return state;
		case MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY_CANCEL:
			return { ...state, DeletingMediaCategory: null };
		case MediaCategoryActionTypes.DELETE_MEDIA_CATEGORY_SUCCESS:
			let newMediaCategories = Array.from(state.MediaCategories)
			let parent = findCategory(newMediaCategories, state.DeletingMediaCategory.parent);
			if(parent) {
				parent.MediaCategories = parent.MediaCategories.filter(c => c.mediaCategoryID != state.DeletingMediaCategory.mediaCategoryID);
			} else {
				//this is top level
				newMediaCategories = newMediaCategories.filter(c => c.mediaCategoryID != state.DeletingMediaCategory.mediaCategoryID);
			}
			return { 
				...state,
				 MediaCategories: newMediaCategories, 
				 DeletingMediaCategory: null,
				  Medias: (state.ActiveMediaCategory && state.DeletingMediaCategory.mediaCategoryID == state.ActiveMediaCategory.mediaCategoryID) ? null : state.Medias 
			};
		case MediaCategoryActionTypes.ADD_MEDIA_CATEGORY:
			return { ...state, EditingMediaCategory: action.mediaCategory };
		case MediaCategoryActionTypes.ADD_MEDIA_CATEGORY_SUCCESS:
			return { ...state, MediaCategories: [ ...state.MediaCategories, { ...state.EditingMediaCategory, mediaCategoryID: action.mediaCategoryID, parent: new MediaCategory({ mediaCategoryID: -1 }) } ], EditingMediaCategory: null}
		case MediaCategoryActionTypes.MOVE_MEDIA_CATEGORY:
			//action.mediaCategory.parent = action.parentCategory;
			return { ...state, EditingMediaCategory: action.mediaCategory, DestinationFolder:action.parentCategory.mediaCategoryID }; 
		case MediaCategoryActionTypes.MOVE_MEDIA_CATEGORY_SUCCESS:
			let newMediaCategoriesMove = Array.from(state.MediaCategories);
			let oldParent = findCategory(newMediaCategoriesMove, state.EditingMediaCategory.parent);
			if(oldParent && oldParent.MediaCategories) {
				oldParent.MediaCategories = oldParent.MediaCategories.filter(c => c.mediaCategoryID != state.EditingMediaCategory.mediaCategoryID);
			}
			let newParent = findCategory(newMediaCategoriesMove, new MediaCategory({mediaCategoryID: state.DestinationFolder}));
			if(newParent && newParent.MediaCategories) {
				newParent.MediaCategories.push({ ...state.EditingMediaCategory, parent: { ...state.EditingMediaCategory.parent, mediaCategoryID: state.DestinationFolder }})
				newParent.isOpen = true;
			}
			if(state.DestinationFolder == -1) {
				//this is top level now, add it in
				newMediaCategoriesMove.push(state.EditingMediaCategory);
			} else {
				//not top level, remove it from there if it's there
				newMediaCategoriesMove = newMediaCategoriesMove.filter(mc => mc.mediaCategoryID != state.EditingMediaCategory.mediaCategoryID);
			}
			return { ...state, MediaCategories: newMediaCategoriesMove, EditingMediaCategory: null };
		case MediaCategoryActionTypes.EDIT_MULTIPLE: 
			return state;
			
		case MediaActionTypes.RESET:
			return initialMediaState;
			
		default:
			return state;
	}
} 