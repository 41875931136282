import { initialUserState, IUserState } from "../state/user.state";
import { UserActions, UserActionTypes } from "../actions/users.actions";
import { User } from "acc-models";

export function userReducer(state = initialUserState, action: UserActions): IUserState {
	switch (action.type) {
		case UserActionTypes.SET_ORGID:
			return { ...state, orgId: action.orgId };

		case UserActionTypes.SELECT_USER:
			return { ...state, activeUser: action.user };

		case UserActionTypes.DELETE_USER:
			return { ...state, userToDelete: action.user };

		case UserActionTypes.DELETE_USER_CANCEL:
			return { ...state, userToDelete: null };

		case UserActionTypes.DELETE_USER_CONFIRM:
			return state;

		case UserActionTypes.DELETE_USER_SUCCESS:
			return { ...state, users: state.users.filter((u) => u.userID != action.userId), activeUser: null, userToDelete: null };

		case UserActionTypes.EDIT_USER:
			return { ...state, activeUser: action.user };

		case UserActionTypes.ADD_USER:
			return { ...state, activeUser: new User() };

		case UserActionTypes.SAVE_USER:
			return state;

		case UserActionTypes.SAVE_USER_SUCCESS:
			return { ...state, users: state.users.map((u) => (u.userID == action.user.userID ? action.user : u)), activeUser: null, profilePhoto: null };

		case UserActionTypes.SAVE_PROGRESS:
			return { ...state, profilePhotoToUpload: action.profilePhotoToUpload };

		case UserActionTypes.SAVE_PROGRESS_SUCCESS:
			return { ...state, users: state.users.map((u) => (u.userID == action.user.userID ? action.user : u)), activeUser: action.user, profile: { ...state.profile, userID: action.user.userID } };

		case UserActionTypes.SAVE_PHOTO_SUCCESS:
			return { ...state, profilePhoto: `${action.fileName}?m=${new Date().getMilliseconds()}`, profile: { ...state.profile, photoFile: action.fileName, photoFileOnly: action.fileName.split("/").reverse()[0] } };

		case UserActionTypes.GET_USERS:
			return state;

		case UserActionTypes.GET_USERS_SUCCESS:
			return { ...state, users: action.users, activeUser: null };

		case UserActionTypes.GET_PROFILE:
			return state;

		case UserActionTypes.GET_PROFILE_SUCCESS:
			return { ...state, profile: action.profile, profilePhoto: action.profile.photoFileOnly ? action.profile.photoFile + `?m=${new Date().getMilliseconds()}` : "" };

		case UserActionTypes.RETURN_TO_LIST:
			return { ...state, activeUser: null, profile: null, profilePhoto: null };

		case UserActionTypes.UPDATE_PROFILE:
			return state;

		case UserActionTypes.UPDATE_PROFILE_SUCCESS:
			return { ...state, activeUser: null, profilePhoto: null }; //return to main page

		case UserActionTypes.RESET:
			return initialUserState;

		case UserActionTypes.ADD_SEATS:
			return { ...state, addSeatDialog: true };

		case UserActionTypes.HIDE_ADD_SEATS:
			return { ...state, addSeatDialog: false };

		case UserActionTypes.RESET:
			return initialUserState;
			
		default:
			return state;
	}
}
