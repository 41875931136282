import { Directive, ElementRef, Input, Output, OnChanges, SimpleChanges, HostListener, EventEmitter, OnInit } from '@angular/core';
import { Media, Folder } from 'acc-models';

@Directive({
  selector: '[appFolderActions]'
})
export class FolderActionDirective implements OnInit, OnChanges {

	@Input('appFolderActions') showFolderActions: string;
	@Input() folder: Folder
	//@Input('activeFolder') currentFolder: Folder;
	@HostListener('click', ['$event']) onclick($event) {
		[].forEach.call(document.getElementsByClassName('folderMenu'), c => c.classList.remove('open'));  //close any that are already open
		this.handleClick($event);
		$event.cancelBubble = true;
	}
	
	@HostListener('document:click', ['$event']) onbodyclick($event) {
		if(this.menu) { this.menu.classList.remove('open'); }
	}
	
	@Output() actionClick: EventEmitter<{actionName: string, payload: any }> = new EventEmitter();
	private el:ElementRef;
	private menu:HTMLElement;
	
  	constructor(el:ElementRef) {
		  this.el = el;
	  }

	ngOnInit(): void {
		
		if(!this.showFolderActions) { return }
				
		let wrapper = document.createElement('div');
		wrapper.className = "folderActions";
		wrapper.setAttribute('data-action', 'expand');
		wrapper.innerHTML = '<div data-action=\"expand\"></div><div data-action=\"expand\"></div><div data-action=\"expand\"></div>';
		this.el.nativeElement.appendChild(wrapper);

		let menuHolder = document.createElement('div');
		menuHolder.className = "folderMenu";
		let list = document.createElement('ul');
		['Remove', 'Rename', 'Move'].forEach(p => {
			let item = document.createElement('li');
			item.classList.add("action"+p, 'folderAction');
			switch(p) {
				case 'Remove': item.innerHTML = 'Remove'; item.setAttribute('data-action', 'remove'); break;
				case 'Rename': item.innerHTML = 'Rename'; item.setAttribute('data-action', 'rename');break;
				case 'Move': item.innerHTML = 'Move'; item.setAttribute('data-action', 'move');break;
			}
			list.appendChild(item);
		});
		menuHolder.appendChild(list);
		this.el.nativeElement.appendChild(menuHolder);
		this.menu = menuHolder;
   }

	handleClick(event) {
		let action = event.target.getAttribute('data-action');
		switch (action) {
			case 'expand':
				this.actionClick.emit({ actionName: 'expand', payload: {id: this.folder.mediaCategoryID} })
				this.menu.classList.add('open');
				break;
			case 'remove':
				this.actionClick.emit({ actionName: 'remove', payload: {folder: this.folder} });
				this.menu.classList.remove('open');
				break;
			case 'rename':
				this.actionClick.emit({ actionName: 'rename', payload: {folder: this.folder} });
				this.menu.classList.remove('open');
				break;
			case 'move':
				this.actionClick.emit({ actionName: 'move', payload: {folder: this.folder} });
				this.menu.classList.remove('open');
				break;

		}
	}
	ngOnChanges(changes:SimpleChanges) {
		if(changes['currentFolder'] && changes['currentFolder'].currentValue) {
			this.folder = changes['currentFolder'].currentValue;
		}
	}

}
