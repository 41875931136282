import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Folder } from 'acc-models';
import { tooltips } from '../../../../tooltips/tooltips'

@Component({
	selector: 'acc-common-folder-structure',
	templateUrl: './folder-structure.component.html'
})

export class FolderStructureComponent {
	@Input() folders:Array<Folder>;
	@Input() public actions:boolean = true;
	@Input() activeFolder: Folder;
	@Input() showFolderActions: boolean;
	@Input() showCartActions: boolean;
	@Input() showFolderTooltip: boolean = false;
	@Input() folderTooltip: string = "";


	@Output() onAction: EventEmitter<{actionName: string, payload: any}> = new EventEmitter();
	
	public tooltips = tooltips;

	constructor () {}
	
	public sortFn(a,b)  {
		if(a.sortOrder > b.sortOrder) { return 1; }
		if(a.sortOrder < b.sortOrder) { return -1; }
		if(a.mediaCategoryDesc > b.mediaCategoryDesc) { return 1; }
		if(a.mediaCategoryDesc < b.mediaCategoryDesc) { return -1; }
		return 0;
	}

	toggleFolder(folder) {
		this.raiseAction({actionName: folder.type == 5 ? 'cartSelected' : 'folderSelected', payload: {folder}})
		folder.isOpen = true;
		
	}
	expandFolder(folder) {
		folder.isOpen = !folder.isOpen;

	}
	raiseAction(evt) {
		this.onAction.emit(evt);
	}

}