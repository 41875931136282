import { Action } from '@ngrx/store';
import { LibraryText, LibraryTextSearchCriteria, LibraryTextCategory } from 'acc-models';

export enum TextLibActionTypes {
	GET_CATEGORIES = '[TEXTLIB] Get Categories',
	GET_CATEGORIES_SUCCESS = '[TEXTLIB] Get Categories Success',
	SEARCH = '[TEXTLIB] Search',
	SEARCH_SUCCESS = '[TEXTLIB] Search Success',
	CLEAR_SEARCH = '[TEXTLIB] Clear Search',
	EDIT_ITEM = '[TEXTLIB] Edit Item',
	EDIT_ITEM_CONFIRM = '[TEXTLIB] Edit Item Confirm',
	EDIT_ITEM_CANCEL = '[TEXTLIB] Edit Item Cancel',
	EDIT_ITEM_SUCCESS = '[TEXTLIB] Save Item Success',
	DELETE_ITEM = '[TEXTLIB] Delete Item',
	DELETE_ITEM_CANCEL = '[TEXTLIB] Delete Item Cancel',
	DELETE_ITEM_CONFIRM = '[TEXTLIB] Delete Item Confirm',
	DELETE_ITEM_SUCCESS = '[TEXTLIB] Delete Item Success',
	RESET = '[ROOT] Reset'
}

export class Search implements Action {
	public readonly type = TextLibActionTypes.SEARCH;
	constructor(public criteria: LibraryTextSearchCriteria) {}
}
export class SearchSuccess implements Action {
	public readonly type = TextLibActionTypes.SEARCH_SUCCESS;
	constructor(public results: Array<LibraryText>) {}
}
export class ClearSearch implements Action {
	public readonly type = TextLibActionTypes.CLEAR_SEARCH;
	constructor() {}
}
export class GetCategories implements Action {
	public readonly type = TextLibActionTypes.GET_CATEGORIES;
	constructor() {}
}
export class GetCategoriesSuccess implements Action {
	public readonly type = TextLibActionTypes.GET_CATEGORIES_SUCCESS;
	constructor(public results: Array<LibraryTextCategory>) {}
}
export class EditItem implements Action {
	public readonly type = TextLibActionTypes.EDIT_ITEM;
	constructor(public itemToSave: LibraryText) {}
}
export class EditItemConfirm implements Action {
	public readonly type = TextLibActionTypes.EDIT_ITEM_CONFIRM;
	constructor(public itemToSave: LibraryText) {}
}
export class EditItemCancel implements Action {
	public readonly type = TextLibActionTypes.EDIT_ITEM_CANCEL;
	constructor() {}
}
export class EditItemSuccess implements Action {
	public readonly type = TextLibActionTypes.EDIT_ITEM_SUCCESS;
	constructor(public item: LibraryText) {}
}
export class DeleteItem implements Action {
	public readonly type = TextLibActionTypes.DELETE_ITEM;
	constructor(public itemToDelete: LibraryText) {}
}
export class DeleteItemConfirm implements Action {
	public readonly type = TextLibActionTypes.DELETE_ITEM_CONFIRM;
	constructor() {}
}
export class DeleteItemCancel implements Action {
	public readonly type = TextLibActionTypes.DELETE_ITEM_CANCEL;
	constructor() {}
}
export class DeleteItemSuccess implements Action {
	public readonly type = TextLibActionTypes.DELETE_ITEM_SUCCESS;
	constructor() {}
}
export class Reset implements Action {
	public readonly type = TextLibActionTypes.RESET;
	constructor() {}
}


export type TextLibActions =
	GetCategories | 
	GetCategoriesSuccess |
	Search |
	SearchSuccess |
	ClearSearch | 
	EditItem |
	EditItemConfirm |
	EditItemCancel |
	EditItemSuccess | 
	DeleteItem |
	DeleteItemConfirm |
	DeleteItemCancel |
	DeleteItemSuccess |
	Reset;