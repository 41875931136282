<p class="results-container">
	<mat-card [ngClass]="{summaryCard: quoteDesc != null && quoteDesc != ''}">
		<mat-card-content>
			<div class="global-actions quote-summary-container">
				<div class="summary-title">quote summary</div>
				<div *ngIf="quoteRequest != null" class="quote-summary">{{ responseInput != null && responseInput.props != null ? this.responseInput.props.clientName : this.quoteRequest.inputs[0].clientName }}, {{ this.quoteRequest.inputs[0].birthDate ? this.quoteRequest.inputs[0].birthDate + ", " : this.quoteRequest.inputs[0].actualAge + " years old," }} {{ this.quoteRequest.inputs[0].gender == "M" ? "Male" : "Female" }}, {{ this.quoteRequest.inputs[0].state }}</div>
				<div *ngIf="quoteDesc != null" class="quote-summary">{{ quoteDesc }}</div>
			</div>
			<div class="sort-actions">
				<div class="sort-title">View by</div>
				<div class="sort-select">
					<mat-form-field appearance="outline">
						<mat-select [(ngModel)]="paymentOption" (selectionChange)="viewByChanged($event)">
							<mat-option value="1">Annual Premium</mat-option>
							<mat-option value="2">Semi-Annual Premium</mat-option>
							<mat-option value="4">Quarterly Premium</mat-option>
							<mat-option value="12">Monthly Premium</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card>
		<mat-card-content>
			<div class="global-actions">
				<button mat-raised-button draggable="true" class="back-button" color="primary" (click)="backToSearch()" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{ tooltips.vq_edit_quote }}">Edit Quote</button>
				<div class="save-buttons">
					<!--<acc-main-send-proposal-button hide-in-classic-view class="send-proposal" (sendProposal)="sendProposal($event)" [disabled]="!selection.hasValue()"></acc-main-send-proposal-button>-->
					<button mat-raised-button id="download-send-button" class="download-button" color="primary" [matMenuTriggerFor]="menu" [disabled]="!selection.hasValue()" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{ tooltips.q_download }}">Download / Send</button>
					<mat-menu #menu="matMenu" class="download-send-options">
						<button mat-menu-item hide-in-classic-view (click)="validateSendProposal(true)">Send Enhanced Quote <mat-icon class="lock-icon-grey" *ngIf="!sessionService.canUseSubscriptionLevelFeature('quoteSendProposal')">lock</mat-icon> </button>
						<button mat-menu-item hide-in-classic-view class="last-send-button" (click)="validateSendProposal(false)">Send Quote Only <mat-icon class="lock-icon-grey" *ngIf="!sessionService.canUseSubscriptionLevelFeature('quoteSendProposal')">lock</mat-icon> </button>

						<button mat-menu-item (click)="downloadSelection()">List Report</button>
						<button mat-menu-item (click)="downloadDetailSelection()">Detail Report</button>
						<button mat-menu-item (click)="downloadCompareSelection()">Comparison Ledger</button>
					</mat-menu>
					<acc-main-copy-quote-button [quoteId]="currentQuoteId" [quoteName]="''" (onCopyQuote)="copyQuote($event)" [disabled]="false"></acc-main-copy-quote-button>
					<button mat-raised-button class="save-button" color="primary" (click)="saveSelection({})" [disabled]="!selection.hasValue()" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{ tooltips.q_save }}">Save</button>
					<button mat-raised-button hide-in-classic-view class="edit-video-button" color="primary" (click)="validateSubscriptionAndOpenVideo('quoteCustomizeVideo')" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{ tooltips.q_edit_video }}">Edit Video <mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature('quoteCustomizeVideo')">lock</mat-icon></button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card id="results-card">
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let tab of this.termsKeys; let index = index" label="{{ getTabTermLabel(tab) }}">
					<mat-expansion-panel [expanded]="true" class="expansion-panel" *ngFor="let panel of this.faceAmountsKeys.get(tab); let panelIndex = index">
						<mat-expansion-panel-header>
							<mat-panel-title>
								{{ panel | currency: "USD":"symbol":"5.0" }}
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div [ngClass]="{ 'mat-elevation-z8': terms.get(tab).get(panel) != null && terms.get(tab).get(panel).length > 0 }">
							<div *ngIf="terms.get(tab).get(panel) != null && terms.get(tab).get(panel).length > 0" class="quotes-header-row">
								<div class="mat-column-select">
									<mat-checkbox (change)="$event ? masterToggle(tab, panel) : null" [checked]="selectionHasValue(tab, panel) && isAllSelected(tab, panel)" [indeterminate]="selectionHasValue(tab, panel) && !isAllSelected(tab, panel)" [aria-label]="checkboxLabel(null, tab, panel)"> </mat-checkbox>
								</div>
								<div class="mat-column-image">
									<div>Carrier/Product</div>
								</div>
								<div class="mat-column-product"></div>
								<div class="mat-column-amount"></div>
								<div class="mat-column-actions"></div>
							</div>
							<div class="mat-row-container plan-list">
								<div *ngIf="terms.get(tab).get(panel) != null && terms.get(tab).get(panel).length > 0" cdkDropList #plansDropList="cdkDropList" (cdkDragMoved)="dragMoved($event)" (cdkDropListDropped)="drop($event, tab, panel)" [cdkDropListConnectedTo]="[plansDropList, selection1DropList, selection2DropList, selection3DropList]">
									<div class="mat-row" *ngFor="let element of terms.get(tab).get(panel)" (cdkDragMoved)="dragMoved($event)" cdkDrag [cdkDragData]="{ plan: element, selected: selection.isSelected(element) }" (cdkDragStarted)="onDragStart($event)" (cdkDragEnded)="onDragEnd($event)" (cdkDragMoved)="onDragMoved($event)">
										<div class="mat-column-select mat-column-select-cell">
											<mat-icon class="q-alert-icon" *ngIf="element.alerts != null && element.alerts.length > 0" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{ getAlertMessages(element) }}">flag</mat-icon>
											<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionToggle(element) : null" [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element, tab, panel)"> </mat-checkbox>
										</div>
										<div class="mat-column-image mat-column-image-cell" [style.backgroundImage]="'url(' + logos.get(+element.input.companyId) + ')'">
											<!--<img src="{{logos.get(+element.input.companyId)}}">  -->
										</div>
										<div (click)="$event.stopPropagation()" class="mat-column-product">
											<div class="product-info">
												<div class="product-info-name">
													<a (click)="productDetail(element)">{{ getProductName(element) }}</a>
												</div>
												<div>{{ element.productVersionInfo.healthNameLong }}</div>
												<div *ngIf="element.productVersionInfo.useAgeNearest">Age Nearest: {{ element.input.nearestAge }}</div>
												<div *ngIf="!element.productVersionInfo.useAgeNearest">Actual Age: {{ element.input.actualAge }}</div>
												<div class="underwriting-badge" *ngIf="!!element.extensionValues.isAcceleratedUnderwriting">Accelerated Underwriting Eligible</div>
												<div *ngIf="element.input.tableRating != 0">Table {{ getTableRating(element.input.tableRating) }}</div>
												<div class="rider-badge" *ngIf="!!element.input.isAccidentalDeathBenefitRider" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="Additional  {{ getAdbAmount(element) | currency }}">ADB</div>
												<div class="rider-badge" *ngIf="!!element.input.isWaiverOfPremiumRider" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="Additional  {{ getWopAmount(element) | currency }}">WOP</div>
												<div class="rider-badge" *ngIf="!!element.input.isReturnOfPremiumRider" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="Included">ROP</div>
												<div class="rider-badge" *ngIf="!!element.input.isChildTermRider" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="Additional  {{ getCrAmount(element) | currency }}">CIR ({{ element.input.cru }})</div>
											</div>
										</div>

										<div class="mat-column-amount">
											<div class="currency-amount">
												{{ getPaymentOptionAmount(element) | currency }}<span class="currency-peryear">{{ getAmountViewBy() }}</span>
											</div>
										</div>

										<div class="mat-column-actions">
											<button mat-raised-button class="apply-button" color="primary" (click)="applyToProduct(element)">Apply</button>
										</div>
									</div>
								</div>
								<div class="quote-row no-quote-row" *ngIf="terms.get(tab).get(panel) == null || terms.get(tab).get(panel).length <= 0">
									<div class="no-quotes">
										<div>No products found.</div>
									</div>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
</p>

<div class="customize-video-container" [ngClass]="{ open: isCustomizeOpen }">
	<div class="customize-video">
		<button mat-fab title="Edit Quote Video" *ngIf="!!this.isCustomizeOpen" (click)="validateSubscriptionAndOpenVideo('quoteCustomizeVideo')">
			<mat-icon>expand_less</mat-icon>
		</button>
		<div class="form-holder">
			<div class="plans" cdkDropList id="select-plans">
				<div class="plan-container" cdkDropList #selection1DropList="cdkDropList" (cdkDropListDropped)="videoDrop($event, 0)">
					<label>Plan 1 (Your Recommendation)</label>
					<div class="plan">
						<div class="details" *ngIf="selectedPlans[0]">
							<img [src]="logos.get(+selectedPlans[0].input.companyId)" />
							<div>{{ getProductName(selectedPlans[0]) }}</div>
							<div class="clear-plan" (click)="clearPlan(0)">X</div>
							<div class="terms">
								<span>{{ selectedPlans[0].input.term }} years</span>
								<span>{{ selectedPlans[0].faceAmount | currency }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="plan-container" cdkDropList #selection2DropList="cdkDropList" (cdkDropListDropped)="videoDrop($event, 1)">
					<label>Plan 2</label>
					<div class="plan">
						<div class="details" *ngIf="selectedPlans[1]">
							<img [src]="logos.get(+selectedPlans[1].input.companyId)" />
							<div>{{ getProductName(selectedPlans[1]) }}</div>
							<div class="clear-plan" (click)="clearPlan(1)">X</div>
							<div class="terms">
								<span>{{ selectedPlans[1].input.term }} years</span>
								<span>{{ selectedPlans[1].faceAmount | currency }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="plan-container" cdkDropList #selection3DropList="cdkDropList" (cdkDropListDropped)="videoDrop($event, 2)">
					<label>Plan 3</label>
					<div class="plan">
						<div class="details" *ngIf="selectedPlans[2]">
							<img [src]="logos.get(+selectedPlans[2].input.companyId)" />
							<div>{{ getProductName(selectedPlans[2]) }}</div>
							<div class="clear-plan" (click)="clearPlan(2)">X</div>
							<div class="terms">
								<span>{{ selectedPlans[2].input.term }} years</span>
								<span>{{ selectedPlans[2].faceAmount | currency }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="points">
				<label>Selling points</label>
				<ul>
					<li>
						<input type="text" [(ngModel)]="sellingPoints.reason1" (change)="selectionChanged = true" />
					</li>
					<li>
						<input type="text" [(ngModel)]="sellingPoints.reason2" (change)="selectionChanged = true" />
					</li>
					<li>
						<input type="text" [(ngModel)]="sellingPoints.reason3" (change)="selectionChanged = true" />
					</li>
				</ul>
			</div>
			<mat-icon matSuffix class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{ tooltips.q_customize_video }}">info_outline</mat-icon>
		</div>
	</div>
</div>
