import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListsComponent } from './lists/lists.component';
import { CommunicationComponent } from './communication.component';
import { ListDetailComponent } from './list-detail/list-detail.component';
import { NewListComponent } from './new-list/new-list.component';
import { ListImportComponent } from './list-import/list-import.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';
import { ListParamsComponent } from './list-params/list-params.component';
import { ReportingComponent } from './reporting/reporting.component';
import { CampaignTestComponent } from './campaign-test/campaign-test.component';
import { FileDropDirective } from 'projects/acc-common/src/lib/directives/file-drop.directive';
import { CommunicationRoutingModule } from './communication-routing.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';

import { AccCommonModule } from 'acc-common';
import { EmailEditorModule } from 'angular-email-editor';
import { CampaignActionsComponent } from './campaigns/campaign-actions/campaign-actions.component';
import { ListActionsComponent } from './lists/list-actions/list-actions.component';
import { CampaignFormActionsComponent } from './campaign-form/campaign-form-actions/campaign-form-actions.component';
import { ListDetailActionsComponent } from './list-detail/list-detail-actions/list-detail-actions.component';
import { ListImportActionsComponent } from './list-import/list-import-actions/list-import-actions.component';
import { ReportingActionsComponent } from './reporting/reporting-actions/reporting-actions.component';
import { ListParamComponent } from './list-params/list-param/list-param.component';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GroupsComponent } from './groups/groups.component';
import { ListPreviewComponent } from './campaign-form/list-preview/list-preview.component';

@NgModule({
  declarations: [
	ListsComponent,
	CommunicationComponent,
	ListDetailComponent,
	NewListComponent,
	ListImportComponent,
	CampaignsComponent,
	CampaignFormComponent,
	ListParamsComponent,
	ReportingComponent,

	CampaignTestComponent,
	CampaignActionsComponent,
	ListActionsComponent,
	CampaignFormActionsComponent,
	ListDetailActionsComponent,
	ListImportActionsComponent,
	ReportingActionsComponent,
	ListParamComponent,
	GroupsComponent,
	ListPreviewComponent,
  ],
  imports: [
	CommonModule,
	AccCommonModule,
	FormsModule,
	ReactiveFormsModule,
	CommunicationRoutingModule,

	MatCardModule,
	MatIconModule,
	MatListModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatInputModule,
	MatTableModule,
	MatPaginatorModule,
	MatStepperModule,
	MatAutocompleteModule,
	MatCheckboxModule,
	MatButtonToggleModule,
	MatGridListModule,
	MatDatetimepickerModule,
	MatMomentDatetimeModule,
	MatSelectModule,
	MatButtonModule,
	MatDialogModule,
	MatRippleModule,
	MatMenuModule,
	MatIconModule,
	MatSortModule,
	BrowserModule,
	DragDropModule,

	EmailEditorModule,


  ],
  entryComponents: [NewListComponent, CampaignTestComponent, ListPreviewComponent],
  providers: [{ provide: MatDialogRef, useValue: {} }, { provide: MAT_DIALOG_DATA, useValue: [] }]
})
export class CommunicationModule { }
 