
<div class="content-container">
	<header id="mainHeader">
		<div class="container">
			<div class="toolbarContainer">
				<div class="pageTitle">Your Personalized Quote&nbsp;</div>
		
			</div>
		</div>
	</header>


	<main id="quote-video-content">
		
		<div class="user-card" *ngIf="agent">
			<!--<div class="profile-image" [style.backgroundImage]="'url(' + agent.photoURLPath + ')'"></div>-->
			<div class="profile-image" [style.backgroundImage]="'url(' + agent.photo?.imageModifiedURLPath + ')'"></div>
			
			<div class="agent-info">
				<div class="name">{{agent.firstName}} {{agent.lastName}}</div>
				<div class="company">{{agent.companyName}}</div>
				<div class="email">{{agent.email}}</div>
				<div class="phone">{{formatPhone(agent.mainPhone)}}</div>
				<address>
					{{agent.address1}}<br/>
					{{agent.city}}<span *ngIf="agent.city && agent.state">,</span> {{agent.state}} {{agent.zip}}
				</address>
			</div>
		</div>
		<div class="org-card" *ngIf="agent">
			<div *ngIf="!!agent.logoForOutput" class="org-logo" [style.backgroundImage]="'url(' + agent.logoForOutput.imageURLPath + ')'"></div>
		</div>
		
		
		<div class="video-container">
			<div class="loading" *ngIf="!videoUrl">Loading Video...</div>
			<video #videoPlayer *ngIf="videoUrl && !showYouTubeVideoPlayer" muted (timeupdate)="timeUpdate($event)" controls style="background:black" autoplay (ended)="videoEnded()">
				<source src="{{videoUrl}}">
			</video>
			<div class="overlay" *ngIf="showOverlay">
				<div class="email"><a href="mailTo:{{agent.email}}">Email Me</a></div>
				<div class="view-quote"><a target="_blank" href="{{proposalUrl}}">View Your Quote</a></div>
			</div>

			<div *ngIf="showYouTubeVideoPlayer" class="youtube-video-responsive">
				<youtube-player class="youtube-iframe" *ngIf="showYouTubeVideoPlayer" [videoId]="youTubeVideoID" (ready)="savePlayer($event)" (change)="onStateChange($event)"></youtube-player>
			</div>

		</div>
	</main>

</div>
