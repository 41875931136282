import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Folder } from 'acc-models';

@Component({
  selector: 'acc-common-library-breadcrumbs',
  templateUrl: './library-breadcrumbs.component.html',
  styleUrls: ['./library-breadcrumbs.component.scss']
})
export class LibraryBreadcrumbsComponent implements OnInit, OnChanges {

	@Input() folder: Folder;
	@Output() onClick: EventEmitter<Folder> = new EventEmitter();
	public breadcrumbs: Array<Folder>
	constructor() { }

	selectCategory(folder) {
		this.onClick.emit(folder);
	}
	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.folder && changes.folder.currentValue) {
			let b = changes.folder.currentValue;
			/*
			if(
				b[b.length-1].type == 0 
				|| b[0].type == 5 //this is the cart
				|| b.length > 1 //if length is 1, you've selected from top of content area
				|| b[0].mediaCategoryID == -1 //this allows choosing top level (MB Asset Library or My Asset Library)
			) {
				this.breadcrumbs = [b];
			} else {
				*/
				this.breadcrumbs = new Array<Folder>();
				while(b) {
					this.breadcrumbs.unshift(b);
					b = b.parent;
				}
			//}
		}
	}

}
