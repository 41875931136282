import { formatCurrency } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Application } from "acc-models";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { SetProgress, UpdateApplication } from "../../store/actions/dtc.actions";
import { selectApplication } from "../../store/selectors/dtc.selectors";
import { IAppState } from "../../store/state/app.state";
import { DtcRouteService } from "../dtc.route.service";

@Component({
	selector: "acc-main-coverage",
	templateUrl: "./coverage.component.html",
	styleUrls: ["./coverage.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CoverageComponent implements OnInit, OnDestroy {
	public form: FormGroup;
	public amountField: FormControl;
	public durationField: FormControl;

	public steps = [100000, 250000, 500000, 750000, 1000000, 1500000, 2000000];

	private destroy$: Subject<boolean> = new Subject();

	constructor(private router: Router, private store: Store<IAppState>, private fb: FormBuilder, private dtcRouteService: DtcRouteService) {}

	ngOnInit() {

		this.amountField = new FormControl("", [Validators.required]);
		this.durationField = new FormControl("", [Validators.required]);
		this.form = this.fb.group({
			amount: this.amountField,
			duration: this.durationField,
		});
		this.store.pipe(select(selectApplication), takeUntil(this.destroy$)).subscribe(app => {
			this.form.get('amount').setValue(app.amount);
			this.form.get('duration').setValue(app.duration);
		});

		this.dtcRouteService.setPage('coverage');

		this.store.dispatch(new SetProgress(60));
		window.scrollTo(0,0);
	}

	formatAmount(val) {
		return formatCurrency(val, "en-US", "$");
	}

	next() {
		if (this.form.valid) {
			const app = new Application({ amount: this.form.controls["amount"].value, duration: this.form.controls["duration"].value });
			this.store.dispatch(new UpdateApplication(app));
			this.store.dispatch(new SetProgress(90));
			this.router.navigateByUrl("/dtc/contact");
		} else {
			Object.keys(this.form.controls).forEach((c) => this.form.get(c).markAsTouched());
		}
	}
	back() {
		this.router.navigateByUrl('/dtc/health');
	}

	ngOnDestroy() {
		this.destroy$.next(true);
	}
}
