import { MediaCategory, Media } from 'acc-models';

export interface IMediaState {
	OrgId: number,
	MediaCategories: Array<MediaCategory>,
	ActiveMediaCategory: MediaCategory,
	EditingMediaCategory: MediaCategory,
	DeletingMediaCategory: MediaCategory,
	Medias: Array<Media>,
	AddingMedia: Media,
	EditingMedia: Media,
	DeletingMedia: Media,
	MovingMedia: Media,
	AddMediaFile: File,
	AddMediaThumbnail: File,
	DestinationFolder: number
}

export const initialMediaState: IMediaState = {
	OrgId: 0,
	MediaCategories: null,
	ActiveMediaCategory: null,
	EditingMediaCategory: null,
	DeletingMediaCategory: null,
	Medias: null,
	AddingMedia: null,
	EditingMedia: null,
	DeletingMedia: null,
	MovingMedia: null,
	AddMediaFile: null,
	AddMediaThumbnail: null,
	DestinationFolder: null
}