import { NgModule } from "@angular/core";
import { LoginFormComponent } from "./components/auth/login-form/login-form.component";
import { BrowserModule } from "@angular/platform-browser";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressButtonsModule } from "mat-progress-buttons";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { DataService, SessionService, DocGenService, CrmService } from "acc-services";
import { LogoutComponent } from "./components/auth/logout/logout.component";
import { RecoverPasswordFormComponent } from "./components/auth/recover-password-form/recover-password-form.component";
import { ResetPasswordFormComponent } from "./components/auth/reset-password-form/reset-password-form.component";
import { ModuleFieldEditorComponent } from "./components/module/module-field-editor/module-field-editor.component";
import { HighlightTermPipe } from "./pipes/highlight-term.pipe";
import { QuillModule } from "ngx-quill";
import { StickyDirective } from "./directives/sticky.directive";
import { DialogComponent } from "./components/dialog/dialog.component";
import { LibraryContentFilesComponent } from "./components/library/library-content-files/library-content-files.component";
import { LibraryContentFoldersComponent } from "./components/library/library-content-folders/library-content-folders.component";
import { FileActionDirective } from "./directives/file-action.directive";
import { FolderStructureComponent } from "./components/library/library-content-folders/folder-structure/folder-structure.component";
import { ShareFileComponent } from "./components/library/library-content-files/forms/share-file/share-file.component";
import { ConfirmComponent } from "./components/dialog/confirm/confirm.component";
import { CopyComponent } from "./components/library/library-content-files/forms/copy/copy.component";
import { LibrarySearchComponent } from "./components/library/library-search/library-search.component";
import { LibraryActionsComponent } from "./components/library/library-actions/library-actions.component";
import { NewFolderComponent } from "./components/library/library-content-folders/forms/new-folder/new-folder.component";
import { UploadFileComponent } from "./components/library/library-content-folders/forms/upload-file/upload-file.component";
import { FolderActionDirective } from "./directives/folder-action.directive";
import { MoveFolderComponent } from "./components/library/library-content-folders/forms/move-folder/move-folder.component";
import { RenameFolderComponent } from "./components/library/library-content-folders/forms/rename-folder/rename-folder.component";
import { LibraryBreadcrumbsComponent } from "./components/library/library-breadcrumbs/library-breadcrumbs.component";
import { ChangePasswordComponent } from "./components/auth/change-password/change-password.component";
import { PersonalizeComponent } from "./components/library/library-actions/personalize/personalize.component";
import { EmailSelectorComponent } from "./components/salesforce/email-selector/email-selector.component";
import { AccountSelectorComponent } from "./components/salesforce/account-selector/account-selector.component";
import { TextLibrarySearchComponent } from "./components/module/text-library-search/text-library-search.component";
import { LightboxComponent } from "./components/dialog/lightbox/lightbox.component";
import { StylizeCheckboxDirective } from "./directives/stylized-checkbox.directive";
import { FileSelectorComponent } from "./components/module/file-selector/file-selector/file-selector.component";
import { CartActionDirective } from "./directives/cart-action.directive";
import { SaveCartComponent } from "./components/library/library-content-folders/forms/save-cart/save-cart.component";
import { FileUploadComponent } from "./controls/file-upload/file-upload.component";
import { NgDragDropModule } from "ng-drag-drop";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { LibraryCartFilesComponent } from "./components/library/library-cart-files/library-cart-files.component";
import { PopupDirective } from "./directives/popup.directive";
import { FileDropDirective } from "./directives/file-drop.directive";
import { PersonalizeDialogComponent } from "./components/library/library-actions/personalize/personalize-dialog/personalize-dialog.component";
import { SafePipe } from "./pipes/safe.pipe";
import { AutosizeModule } from "ngx-autosize";
import { ProfilePhotoComponent } from "./components/profile/profile-photo/profile-photo.component";
import { VideoDialogComponent } from "./components/dialog/video-dialog/video-dialog.component";
import { InfoDialogComponent } from "./components/dialog/info-dialog/info-dialog.component";
import { PreviewVideoComponent } from "./components/preview-video/preview-video.component";
import { SliderComponent } from "./components/slider/slider.component";
import { SliderItemDirective } from "./directives/slider-item.directive";
import { AutoPreviewUploadComponent } from './controls/auto-preview-upload/auto-preview-upload.component';
import { PhonePipe } from './pipes/phone.pipe';
import { ClassicViewDirective } from "./directives/classic-view.directive";
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

@NgModule({
	declarations: [
		LoginFormComponent,
		LogoutComponent,
		RecoverPasswordFormComponent,
		ResetPasswordFormComponent,
		ModuleFieldEditorComponent,

		HighlightTermPipe,
		SafePipe,
		PhonePipe,
		StickyDirective,
		StylizeCheckboxDirective,
		FileActionDirective,
		FolderActionDirective,
		CartActionDirective,
		PopupDirective,
		FileDropDirective,
		DialogComponent,
		LibraryContentFilesComponent,
		LibraryContentFoldersComponent,
		FolderStructureComponent,
		ShareFileComponent,
		ConfirmComponent,
		CopyComponent,
		LibrarySearchComponent,
		LibraryActionsComponent,
		NewFolderComponent,
		UploadFileComponent,
		MoveFolderComponent,
		RenameFolderComponent,
		LibraryBreadcrumbsComponent,
		ChangePasswordComponent,
		PersonalizeComponent,
		EmailSelectorComponent,
		AccountSelectorComponent,
		TextLibrarySearchComponent,
		LightboxComponent,
		FileSelectorComponent,
		SaveCartComponent,
		FileUploadComponent,
		LibraryCartFilesComponent,
		PersonalizeDialogComponent,
		ProfilePhotoComponent,
		VideoDialogComponent,
		InfoDialogComponent,
		PreviewVideoComponent,
		SliderComponent,
		SliderItemDirective,
		AutoPreviewUploadComponent,
		ClassicViewDirective,
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,

		MatInputModule,
		MatCheckboxModule,
		MatChipsModule,
		MatListModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatIconModule,
		MatMenuModule,
		MatButtonModule,
		MatCardModule,
		MatProgressButtonsModule,
		MatDialogModule,
		MatSelectModule,
		MatToolbarModule,
		MatTabsModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		DragDropModule,
		NgDragDropModule.forRoot(),
		MatTooltipModule,
		MatProgressBarModule,
		ShareModule,
		ShareButtonsModule,

		AutosizeModule,

		QuillModule.forRoot({
			modules: {
				toolbar: [["bold", "italic", "underline", "strike"], [{ color: [] }], [{ list: "bullet" }]],
			},
		}),
	],
	exports: [
		LoginFormComponent,
		LogoutComponent,
		RecoverPasswordFormComponent,
		ResetPasswordFormComponent,
		ModuleFieldEditorComponent,
		DialogComponent,
		LibraryCartFilesComponent,
		LibraryContentFilesComponent,
		LibraryContentFoldersComponent,
		FolderStructureComponent,
		LibrarySearchComponent,
		LibraryActionsComponent,
		LibraryBreadcrumbsComponent,
		AccountSelectorComponent,
		PersonalizeComponent,

		FileUploadComponent,
		AutoPreviewUploadComponent,

		HighlightTermPipe,
		SafePipe,
		PhonePipe,
		StickyDirective,
		PopupDirective,
		FileDropDirective,
		StylizeCheckboxDirective,
		ProfilePhotoComponent,
		PreviewVideoComponent,
		SliderComponent,
		SliderItemDirective,
		ClassicViewDirective
	],
	providers: [DataService, SessionService, DocGenService, CrmService],
	entryComponents: [ShareFileComponent, ConfirmComponent, CopyComponent, NewFolderComponent, UploadFileComponent, MoveFolderComponent, RenameFolderComponent, PersonalizeDialogComponent, TextLibrarySearchComponent, FileSelectorComponent, SaveCartComponent, VideoDialogComponent, InfoDialogComponent, PreviewVideoComponent],
})
export class AccCommonModule {}
