import { IAppState } from "../state/app.state";
import { createSelector } from "@ngrx/store";
import { IStatusState } from "../state/status.state";

const selectStatus = (state: IAppState) => state.status;

export const selectErrorMessage = createSelector(selectStatus, (state: IStatusState) => state.errorMessage);

export const selectSuccessMessage = createSelector(selectStatus, (state: IStatusState) => state.successMessage);

export const selectGettingStarted = createSelector(selectStatus, (state: IStatusState) => state.gettingStartedDialog);

export const selectTrialFeature = createSelector(selectStatus, (state: IStatusState) => state.trialFeature);

export const selectDaysLeft = createSelector(selectStatus, (state: IStatusState) => state.daysLeft);

export const selectCcEntered = createSelector(selectStatus, (state: IStatusState) => state.ccEntered);

export const selectShouldShowTrialFeature = createSelector(selectStatus, (state: IStatusState) => !state.ccEntered && state.daysLeft !== null);

export const selectClassicView = createSelector(selectStatus, (state: IStatusState) => state.showClassicView);
