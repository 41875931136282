import { Application } from "./application";

export class DtcQuoteRequest {
	public userID: string;
	public name: string;
	public birthdate: string;
	public faceAmount: string;
	public gender: string;
	public healthClass: string;
	public state: string;
	public term: string;
	public emailAddress: string;
	public phone: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}

	public static FromApplication(app: Application, userId: string): DtcQuoteRequest {
		let healthClass:string;
		switch(app.healthRating) {
			case 'A':
				healthClass = app.smoker ? 'TP' : 'PP';
				break;
			case 'B':
				healthClass = app.smoker ? 'TP' : 'P';
				break;
			case 'C':
				healthClass = app.smoker ? 'TS' : 'SP';
				break;
			case 'D':
				healthClass = app.smoker ? 'TS' : 'S';
				break;
		}
		const dob = new Date(app.birthdate);
		return new DtcQuoteRequest(
			{
				userID: userId,
				name: app.firstName + ' ' + app.lastName,
				birthdate: `${dob.getFullYear()}-${dob.getMonth()+1}-${dob.getDate()}`,
				faceAmount: app.amount.toString(),
				gender: app.gender,
				healthClass: healthClass,
				state: app.state,
				term: app.duration.toString(),
				emailAddress: app.email,
				phone: app.phone
			}
		);


	}
}