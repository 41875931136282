import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class SignUpGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (sessionStorage.getItem("currentUser")) {
			// logged in so return false
			this.router.navigate(["/welcome"]);
			return false;
		}
		return true;
	}
}
