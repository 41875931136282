import { QuoteInput, QuoteInputProps } from "./quoteInput";
import { Quote } from "./quote";
import { HealthProfileAlt } from './healthProfileAlt';
import { HealthProfile } from './healthProfile';

export class QuoteRequest {
	public inputs: Array<QuoteInput> = [new QuoteInput()];
	public returnResultsWithError: boolean;
	public props: QuoteRequestProps;
	public quoteType: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}

	public static getProducts(carrierProductsFilterList: Array<any>): Array<any> {
		var result = [];
		if(carrierProductsFilterList == null){
			return result;
		}

		carrierProductsFilterList.forEach(o => {
			result.push({
				"companyId" : o.carrierID,
				"productId" : o.productID,
				"productType" : "TERM"
			});	
		});

		return result;
	}

	public static fromQuote(quote: Quote, carrierProductsFilterList: Array<any>): QuoteRequest {
		return new QuoteRequest({
			inputs: [
				new QuoteInput({
					quoteID: quote.quoteID,
					products: this.getProducts(carrierProductsFilterList),
					birthDate: quote.clientInformation.birthDate,
					actualAge: quote.clientInformation.age || 0,
					nearestAge: quote.clientInformation.nearestAge || 0,
					gender: quote.clientInformation.gender,
					healthClasses: quote.underwritingInformation.healthClasses,
					state: quote.clientInformation.state,
					faceAmounts: quote.productInformation.faceAmounts,
					terms: quote.productInformation.terms,
					paymentOptions: ["1"],
					solves: null,
					illustrateYear: 0,
					props: new QuoteInputProps({
						illustratedRate: "",
						isAccidentalDeathBenefitRider: quote.productInformation.isAccidentalDeathBenefitRider,
						isReturnOfPremiumRider: quote.productInformation.isReturnOfPremiumRider,
						isChildTermRider: quote.productInformation.isChildTermRider,
						isWaiverOfPremiumRider: quote.productInformation.isWaiverOfPremiumRider,
						cru: quote.productInformation.childRiderUnit,
						loanType: null,
						tableRating: quote.underwritingInformation.tableRating,
						isGuaranteed: true,
						showTesting: false,
						simplifiedIssue: false,
						flatExtraAmount: quote.underwritingInformation.flatExtraAmount,
						flatExtraYearStart: quote.underwritingInformation.flatExtraYearEnd ? 1 : 0,
						flatExtraYearEnd: quote.underwritingInformation.flatExtraYearEnd
					})
				})
			],
			returnResultsWithError: false,
			props: new QuoteRequestProps({
				clientName: quote.clientInformation.clientName,
				quoteDesc: quote.clientInformation.quoteDesc,
				agentName: "",
				agentPhoneNumber: "",
				agentLicenseNumber: "",
				xraeOutput: null
			}),
			quoteType: quote.productInformation.quoteType
		});
	}
}

export class QuoteRequestProps {
	public clientName: string;
	public quoteDesc: string;
	public agentName: string;
	public agentPhoneNumber: string;
	public agentLicenseNumber: string;
	public xraeOutput: any;
	constructor(o?: any) {
		Object.assign(this, o);
	}
}
