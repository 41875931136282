<acc-main-trial-feature-popup *ngIf="showTrialFeature" appPopup (close)="closeTrialFeature($event)"></acc-main-trial-feature-popup>


<div id="welcomeContainer">
	<div id="welcomeBottom" [ngClass]="{'with-charts': showCharts}">
		<div class="blocks-section">
			<ul class="blocks">
				<ng-container *ngFor="let block of visibleWelcomeBlocks">
					<ng-container *ngIf="block.type == 'modules'">
						<acc-main-module-block  *ngFor="let module of modules$ | async; let i = index" [module]="module" (onShowDocuments)="showDocuments($event)"></acc-main-module-block>
					</ng-container>
					<ng-container *ngIf="block.type == 'link'">
						<li class="link">
							<div class="box">
								<mat-icon fontSet="material-icons-outlined">{{block.icon}}</mat-icon>
								<a [routerLink]="[block.link]" *ngIf="block.link"><span>{{block.name}}</span></a>
								<a (click)="this[block.action]()" *ngIf="block.action"><span>{{block.name}}</span></a>
							</div>
						</li>
					</ng-container>
				</ng-container>
			</ul>
			<ul class="blocks">
				<ng-container *ngFor="let block of visibleWelcomeBlocks">
					<ng-container *ngIf="block.type == 'data'">
						<acc-main-data-block [block]="block" [ngStyle]="{gridColumn: block.size ? 'auto / span ' + block.size : null}"></acc-main-data-block>
					</ng-container>
				</ng-container>
			</ul>
		</div>
		<div class="charts-section" *ngIf="showCharts">
			<div>
				<h1>Your Business Analytics</h1>
				<a (click)="validateSubscription('/quote/analytics')" >Click here to see more Analytics &amp; Reporting<mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature('/quote/analytics')" >lock</mat-icon></a>
				<div class="charts-container">
					<ng-container *ngFor="let block of settings.welcomeBlocks">
						<mat-card *ngIf="block.type == 'analytics' && block.name == 'broker'">
							<mat-card-header>
								<mat-card-title>Broker Data</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<acc-main-analytics-chart [data]="brokerData" [showTop]="5" [dataPoint1]="dataPoint1" [dataPoint2]="dataPoint2" [chartWidth]="300" [barHeight]="16" [legendTitle]="'Brokers'" [barLegendPosition]="'below'"></acc-main-analytics-chart>
							</mat-card-content>
						</mat-card>
						<mat-card *ngIf="block.type == 'analytics' && block.name == 'carrier'">
							<mat-card-header>
								<mat-card-title>Carrier Data</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<acc-main-analytics-chart [data]="carrierData" [showTop]="5" [dataPoint1]="dataPoint1" [dataPoint2]="dataPoint2" [chartWidth]="300" [barHeight]="16" [legendTitle]="'Carriers'" [barLegendPosition]="'below'"></acc-main-analytics-chart>
							</mat-card-content>
						</mat-card>
						<mat-card *ngIf="block.type == 'analytics' && block.name == 'state'">
							<mat-card-header>
								<mat-card-title>Time Period Data</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<acc-main-analytics-chart [data]="timePeriodData" [showTop]="5" [dataPoint1]="dataPoint1" [dataPoint2]="dataPoint2" [chartWidth]="300" [barHeight]="16" [legendTitle]="'Time Periods'" [barLegendPosition]="'below'"></acc-main-analytics-chart>
							</mat-card-content>
						</mat-card>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
	 <!--
		 TODO: Restore Module Asset Library component
	
	<module-asset-library *ngIf="showModuleAssetLibrary" (closeDialog)="closeDialog($event)" [moduleId]="selectedModuleId"></module-asset-library>
	 -->