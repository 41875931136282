import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { DataService, SessionService, QuoteService } from "acc-services";
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FileSelectorComponent } from 'acc-common';
import { take } from 'rxjs/operators';
import { Folder, LibraryItem } from 'acc-models';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: "acc-main-send",
	templateUrl: "./send.component.html",
	styleUrls: ["./send.component.scss", "../editor/form/form.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SendComponent implements OnInit {
	public cartId: string;
	public quoteId: string;
	public fileAdded: Subject<LibraryItem> = new Subject();
	private fileSelectorDialogRef: MatDialogRef<FileSelectorComponent>;
	constructor(private dataService: DataService, private sessionService: SessionService, private location: Location, private dialog: MatDialog, private route: ActivatedRoute, private quoteService: QuoteService) {}

	ngOnInit() {
		this.getCartId$(this.sessionService.getUser().userID).then(cartId => {
			this.cartId = cartId;
		});
		this.route.paramMap.subscribe(params => {
			this.quoteId = params.get('quoteId');
		});
	}

	close() {
		this.location.back();
	}
	private getCartId$(userId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			this.dataService.getPreviousCart(userId).subscribe((resp: any) => {
				if (resp.recentSession) {
					resolve(resp.recentSession);
				} else {
					if (!this.sessionService.getCartId()) {
						this.dataService.startSession(userId).subscribe((c: any) => {
							resolve(c.cartSessionID);
						});
					} else {
						resolve(this.sessionService.getCartId());
					}
				}
			});
		});
	}

	showSelectFile(textField) {
		this.fileSelectorDialogRef = this.dialog.open(FileSelectorComponent, {
			data: {
				folders: [new Folder(1, 1, 'test', [])],
				//fileTypesToShow: ['.pdf', '.png', '.jpg', '.jpeg']
			},
			height: '75vh',
			width: '60vw'
		})
		this.fileSelectorDialogRef.afterClosed().pipe(take(1)).subscribe(file => {
			if(file) {
				this.dataService.addToCart(this.cartId, file.id, file.mediaSourceID).subscribe(resp => {
					this.fileAdded.next(file);
				})
			}
	
		})
	}

}
