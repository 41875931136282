<div class="content" id="create-video">
    <mat-card class="mat-card-video">
        <mat-card-content>
            <div class="video--grid">
                <div class="video__select">
                    <mat-form-field appearance="outline" class="animated fadeIn">
                        <mat-label>Select your video program</mat-label>
                        <mat-select [(ngModel)]="selectedVideoId" (ngModelChange)="videoTypeChange($event)">
                            <mat-option *ngFor="let v of videoPrograms" [value]="v.programID">
                                {{v.programName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="selectedVideoProgram != null">
                    <div class="video__sample animated fadeIn">
                        <div class="videoWrapper">
							<video controls style="width:100%;" #video>
								<source src="{{selectedVideoProgram.sampleVideo}}" type="video/mp4">
							</video>
                        </div>
                    </div>
                    <div class="video__desc animated fadeIn">
                        <div class="descriptionWrapper" [innerHTML]="selectedVideoProgram.programDesc">
                        </div>
                    </div>

                    <div class="video__fields animated fadeIn">
                        <div class="formWrapper">
                            <section>
                                <acc-main-cust-video-parms [params]='selectedVideoProgram.CustVideoParameters' (notify)='onNotify($event)'></acc-main-cust-video-parms>
                            </section>
                        </div>
                    </div>
                </ng-container>

            </div>
        </mat-card-content>
    </mat-card>

    <div class="video__submit-container--flex">
        <div>
         <button mat-raised-button color="primary" type="submit" (click)="showPreview()" [disabled]="selectedVideoProgram == null">Preview Video</button> <mat-icon class="video-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.v_build}}" >info_outline</mat-icon>
        </div> 
        <div>
         <button mat-raised-button type="button" class="send-email-video-button" (click)="send()" [disabled]="selectedVideoProgram == null">Send Email</button> <mat-icon class="video-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.v_email}}" >info_outline</mat-icon>
        </div>
    </div>
</div>