<ng-container [formGroup]="familyHistory" [ngSwitch]="selectedSection.section">
  <div class="family__container animated fadeIn" *ngSwitchCase="'Father'" [formGroupName]="'father'">
    <div class="family__history multiple-radio-btns animated fadeIn">
      <mat-label>Did client's Father have a diagnosis of Cancer, Diabetes, Heart Disease, or Stroke before age 70?
      </mat-label>
      <mat-radio-group [formControlName]="'fatherHistory'">
        <mat-radio-button [value]=true aria-label="Have History">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Doesn't Have History">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="family__conditions"
      *ngIf="fatherHistory || familyHistory.get('father').get('fatherHistory').value == true">
      <mat-label>Which of the following conditions did he have?</mat-label>
      <mat-select multiple [formControlName]="'conditions'">
        <mat-option [value]="condition.code" *ngFor="let condition of maleConditions">{{condition.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('diabetes', 'father')">
      <mat-label>Age Diagnosed: Diabetes</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'fatherDiabetesDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('heartDisease', 'father')">
      <mat-label>Age Diagnosed: Heart Disease</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'fatherCardioVascularDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('stroke', 'father')">
      <mat-label>Age Diagnosed: Stroke</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'fatherStrokeDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasCancer('father')">
      <mat-label>Age Diagnosed: Cancer</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'fatherCancerDiagnosedAge'" />
    </mat-form-field>

    <div class="family__passed-away multiple-radio-btns animated fadeIn" *ngIf="familyHistory.get('father').get('conditions').value?.length > 0">
      <mat-label>Did client's Father die due to any conditions?</mat-label>
      <mat-radio-group [formControlName]="'fatherDie'">
        <mat-radio-button [value]=true aria-label="Father Passed Away">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Father Hasn't Passed Away">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="family__passed-away-conditions animated fadeIn"
      *ngIf="fatherPassedAway || familyHistory.get('father').get('fatherDie').value == true">
      <mat-label>Which conditions did he die from?</mat-label>
      <mat-select multiple [formControlName]="'deathConditions'">
        <mat-option [value]="condition.code" *ngFor="let condition of fatherDeathConditions">{{condition.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-passed-away animated fadeIn" 
      *ngIf="fatherPassedAway || familyHistory.get('father').get('fatherDie').value == true">
      <mat-label>Age at death</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'deathAge'" />
    </mat-form-field>
  </div>

  <div class="family__container animated fadeIn" *ngSwitchCase="'Mother'" [formGroupName]="'mother'">
    <div class="family__history multiple-radio-btns animated fadeIn">
      <mat-label>Did client's Mother have a diagnosis of Cancer, Diabetes, Heart Disease, or Stroke before age 70?
      </mat-label>
      <mat-radio-group [formControlName]="'motherHistory'">
        <mat-radio-button [value]=true aria-label="Have History">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Doesn't Have History">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="family__conditions"
      *ngIf="motherHistory || familyHistory.get('mother').get('motherHistory').value == true">
      <mat-label>Which of the following conditions did she have?</mat-label>
      <mat-select multiple [formControlName]="'conditions'">
        <mat-option [value]="condition.code" *ngFor="let condition of femaleConditions">{{condition.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('diabetes', 'mother')">
      <mat-label>Age Diagnosed: Diabetes</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'motherDiabetesDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('heartDisease', 'mother')">
      <mat-label>Age Diagnosed: Heart Disease</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'motherCardioVascularDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('stroke', 'mother')">
      <mat-label>Age Diagnosed: Stroke</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'motherStrokeDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasCancer('mother')">
      <mat-label>Age Diagnosed: Cancer</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'motherCancerDiagnosedAge'" />
    </mat-form-field>

    <div class="family__passed-away multiple-radio-btns animated fadeIn" *ngIf="familyHistory.get('mother').get('conditions').value?.length > 0">
      <mat-label>Did client's Mother die due to any conditions?</mat-label>
      <mat-radio-group [formControlName]="'motherDie'">
        <mat-radio-button [value]=true aria-label="Mother Passed Away">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Mother Hasn't Passed Away">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="family__passed-away-conditions animated fadeIn"
      *ngIf="motherPassedAway || familyHistory.get('mother').get('motherDie').value == true">
      <mat-label>Which conditions did she die from?</mat-label>
      <mat-select multiple [formControlName]="'deathConditions'">
        <mat-option [value]="condition.code" *ngFor="let condition of motherDeathConditions">{{condition.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-passed-away animated fadeIn" 
      *ngIf="motherPassedAway || familyHistory.get('mother').get('motherDie').value == true">
      <mat-label>At what age did she die?</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'deathAge'" />
    </mat-form-field>
  </div>

  <div class="family__container animated fadeIn" *ngSwitchCase="'Sister'" [formGroupName]="'sister'">
    <div class="family__history multiple-radio-btns animated fadeIn">
      <mat-label>Did client's Sister have a diagnosis of Cancer, Diabetes, Heart Disease, or Stroke before age 70?
      </mat-label>
      <mat-radio-group [formControlName]="'sisterHistory'">
        <mat-radio-button [value]=true aria-label="Have History">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Doesn't Have History">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="family__conditions"
      *ngIf="sisterHistory || familyHistory.get('sister').get('sisterHistory').value == true">
      <mat-label>Which of the following conditions did she have?</mat-label>
      <mat-select multiple [formControlName]="'conditions'">
        <mat-option [value]="condition.code" *ngFor="let condition of femaleConditions">{{condition.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('diabetes', 'sister')">
      <mat-label>Age Diagnosed: Diabetes</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'sisterDiabetesDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('heartDisease', 'sister')">
      <mat-label>Age Diagnosed: Heart Disease</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'sisterCardioVascularDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('stroke', 'sister')">
      <mat-label>Age Diagnosed: Stroke</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'sisterStrokeDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasCancer('sister')">
      <mat-label>Age Diagnosed: Cancer</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'sisterCancerDiagnosedAge'" />
    </mat-form-field>

    <div class="family__passed-away multiple-radio-btns animated fadeIn" *ngIf="familyHistory.get('sister').get('conditions').value?.length > 0">
      <mat-label>Did client's Sister die due to any conditions?</mat-label>
      <mat-radio-group [formControlName]="'sisterDie'">
        <mat-radio-button [value]=true aria-label="Sister Passed Away">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Sister Hasn't Passed Away">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="family__passed-away-conditions animated fadeIn"
      *ngIf="sisterPassedAway || familyHistory.get('sister').get('sisterDie').value == true">
      <mat-label>Which conditions did she die from?</mat-label>
      <mat-select multiple [formControlName]="'deathConditions'">
        <mat-option [value]="condition.code" *ngFor="let condition of sisterDeathConditions">{{condition.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-passed-away animated fadeIn"
      *ngIf="sisterPassedAway || familyHistory.get('sister').get('sisterDie').value == true">
      <mat-label>At what age did she die?</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'deathAge'" />
    </mat-form-field>
  </div>

  <div class="family__container animated fadeIn" *ngSwitchCase="'Brother'" [formGroupName]="'brother'">
    <div class="family__history multiple-radio-btns animated fadeIn">
      <mat-label>Did client's Brother have a diagnosis of Cancer, Diabetes, Heart Disease, or Stroke before age 70?
      </mat-label>
      <mat-radio-group [formControlName]="'brotherHistory'">
        <mat-radio-button [value]=true aria-label="Have History">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Doesn't Have History">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="family__conditions"
      *ngIf="brotherHistory || familyHistory.get('brother').get('brotherHistory').value == true">
      <mat-label>Which of the following conditions did he have?</mat-label>
      <mat-select multiple [formControlName]="'conditions'">
        <mat-option [value]="condition.code" *ngFor="let condition of maleConditions">{{condition.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('diabetes', 'brother')">
      <mat-label>Age Diagnosed: Diabetes</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'brotherDiabetesDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('heartDisease', 'brother')">
      <mat-label>Age Diagnosed: Heart Disease</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'brotherCardioVascularDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasSpecificCondition('stroke', 'brother')">
      <mat-label>Age Diagnosed: Stroke</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'brotherStrokeDiagnosedAge'" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-diagnosed-condition animated fadeIn"
      *ngIf="hasCancer('brother')">
      <mat-label>Age Diagnosed: Cancer</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'brotherCancerDiagnosedAge'" />
    </mat-form-field>

    <div class="family__passed-away multiple-radio-btns animated fadeIn" *ngIf="familyHistory.get('brother').get('conditions').value?.length > 0">
      <mat-label>Did client's Brother die due to any conditions?</mat-label>
      <mat-radio-group [formControlName]="'brotherDie'">
        <mat-radio-button [value]=true aria-label="Brother Passed Away">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Brother Hasn't Passed Away">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="family__passed-away-conditions animated fadeIn"
      *ngIf="brotherPassedAway || familyHistory.get('brother').get('brotherDie').value == true">
      <mat-label>Which conditions did he die from?</mat-label>
      <mat-select multiple [formControlName]="'deathConditions'">
        <mat-option [value]="condition.code" *ngFor="let condition of brotherDeathConditions">{{condition.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="family__age-passed-away animated fadeIn"
      *ngIf="brotherPassedAway || familyHistory.get('brother').get('brotherDie').value == true">
      <mat-label>At what age did he die?</mat-label>
      <input matInput type="number" min="1" max="70" [formControlName]="'deathAge'" />
    </mat-form-field>
  </div>
</ng-container>