import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmComponent, PersonalizeDialogComponent } from "acc-common";
import { DataService, MixpanelService, SessionService } from "acc-services";
import { Profile } from "acc-models";
import { take } from "rxjs/operators";
import { environment } from 'projects/acc-main/src/environments/environment';

@Component({
	selector: "acc-main-self-service-quoting",
	templateUrl: "./self-service-quoting.component.html",
	styleUrls: ["./self-service-quoting.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class SelfServiceQuotingComponent implements OnInit {
	public enabled: boolean;
	public userId: string;
	public profiles: Array<Profile>;
	public profileId: string;
	public environment = environment;
	
	public sharedURL: string;
	public sharedTitle: string;
	public sharedDescription: string;
	public sharedImage: string;

	private titleText = "Free Term Life Quote - ";
	private descriptionText = "Get your free term life quote today from ";

	constructor(private dialog: MatDialog, private sessionService: SessionService, private snackbar: MatSnackBar, private dataService: DataService, private mixpanelService: MixpanelService) {}

	ngOnInit() {
		this.userId = this.sessionService.getUser().userID;
		this.dataService.getSelfServeEnabled(this.userId).subscribe((resp:any) => {
			this.enabled = resp.enabled;
			this.profileId = resp.profileID;
			if(this.profileId == null) {
				this.profileId = this.sessionService.getUser().activeProfileID;
				this.setProfile(this.profileId);
			}
			this.getShareURL(this.profileId);
		})
		this.dataService.getProfiles(this.userId).subscribe((resp: any) => this.profiles = resp);
	}

	enable() {
		if (!this.enabled) {
			this.enabled = true;
			this.dataService.setSelfServeEnabled(this.userId, true).subscribe((resp:any) => {
				if(resp == null || resp.profileID == null) {
					this.profileId = this.sessionService.getUser().activeProfileID;
				}else{
					this.profileId = resp.profileID;
				}
				this.mixpanelService.trackEnableDTC();
				this.getShareURL(this.profileId);
			});
		}
	}
	disable() {
		if (this.enabled) {
			const deleteDialogRef = this.dialog.open(ConfirmComponent, {
				data: {
					title: "Confirm ",
					message: `Are you sure you want to disable Self Serving Quoting?`,
					yesText: "Disable",
					noText: "Cancel",
				},
			});
			deleteDialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe((confirm) => {
					this.enabled = !confirm;
					if(confirm) {
						this.dataService.setSelfServeEnabled(this.userId, false).subscribe();
					}
				});
		}
	}

	setProfile(e) {
		this.dataService.setSelfServeProfile(this.userId, e).subscribe();
		this.getShareURL(e);
	}

	copyToClipboard() {
		let txt = document.getElementById('copy-to-clipboard') as HTMLInputElement;
		txt.select();
		document.execCommand('copy');
		this.snackbar.open('Link has been copied to your clipboard', 'OK', { duration: 3000 })
	}

	email() {
		this.getCartId$(this.sessionService.getUser().userID).then(cartId => {
			this.dataService.clearCart(cartId).subscribe(() => {
				const dialogRef = this.dialog.open(PersonalizeDialogComponent, { data: {
					message: `Get your free Term Life quote in a matter of minutes.  <a href="${environment.base_url}dtc/${this.userId}">Click here</a> to get started.`,
					mainCartId: cartId
				}, minWidth: 830, maxWidth: 830})
			})
		})
	}

	private getCartId$(userId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			this.dataService.getPreviousCart(userId).subscribe((resp: any) => {
				if (resp.recentSession) {
					resolve(resp.recentSession);
				} else {
					if (!this.sessionService.getCartId()) {
						this.dataService.startSession(userId).subscribe((c: any) => {
							resolve(c.cartSessionID);
						});
					} else {
						resolve(this.sessionService.getCartId());
					}
				}
			});
		});
	}

	getShareURL(profileId){
		var finalUrl = `${environment.base_url}dtc/${this.userId}`;
		var params = finalUrl + "#" + profileId;
		var hiddenParams = btoa(params);
		this.sharedURL = encodeURI(environment.share_dtc_url + "?params=" + hiddenParams);
		this.dataService.getProfileDetail(profileId).subscribe((p:any) => {
			var profile = new Profile(p);
			this.sharedTitle = this.titleText + profile.companyName;
			this.sharedDescription = this.descriptionText + profile.companyName;
			this.sharedImage = profile.logoURLPath;
		});
	}

}
