import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileFormComponent } from "./profile-form/profile-form.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AccCommonModule } from "acc-common";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { SeatsDialogComponent } from "./seats/seats-dialog.component";
import { GettingStartedComponent } from "./getting-started/getting-started.component";
import { FeaturesTableComponent } from "./features-table/features-table.component";
import { OrgProfileComponent } from './org-profile/org-profile.component';
import { DateSelectorComponent } from "./date-selector/date-selector.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";

const maskConfig: Partial<IConfig> = {
	validation: false,
};
@NgModule({
	declarations: [ProfileFormComponent, SeatsDialogComponent, GettingStartedComponent, FeaturesTableComponent, OrgProfileComponent, DateSelectorComponent],
	imports: [
		CommonModule, 
		FormsModule, 
		ReactiveFormsModule, 
		MatCardModule,
		 MatInputModule, 
		 AccCommonModule, 
		 MatDatepickerModule, 
		 MatTabsModule, 
		 NgxMaskModule.forRoot(maskConfig), 
		 MatIconModule, 
		 MatProgressSpinnerModule, 
		 MatProgressBarModule, 
		 MatButtonModule, 
		 MatFormFieldModule, 
		 MatSnackBarModule, 
		 MatDialogModule, 
		 MatTooltipModule,
		 MatSelectModule
		],
	exports: [ProfileFormComponent, SeatsDialogComponent, GettingStartedComponent, FeaturesTableComponent, OrgProfileComponent, DateSelectorComponent],
})
export class SharedModule {}
