declare var require: any

export const environment = {
	appVersion: require('../../../../package.json').version,
	environment_name: "production",
	production: true,
	api_url: "https://api.mbaccelerate.com/api/",
	quote_pdf_output: "http://api.mbaccelerate.com/reports/*id*-lifequote-proposal.pdf",
	quote_video_url: "",
	stripe_key: "",
	share_video_url: "https://api.mbaccelerate.com/Video.aspx",
	bugsnag_api_key: "0d1cb209e1326f1c5f0228db6a310919",
	base_url: "https://mbaccelerate.com/",
	mixpanel_api_token: null,
	mixpanel_api_proxy: null,
	share_dtc_url: "https://api.mbaccelerate.com/Dtc.aspx",
};
