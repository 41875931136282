<p class="campaigns-container">
  <mat-card >
    <mat-card-content>

        <mat-form-field class="date-filter">
          <mat-label>Filter By Delivery Date:</mat-label>
          <mat-select [(ngModel)]="selectedDateFilter" (selectionChange)="onDateFilterChange()">
            <mat-option [value]="1">Last 30 days</mat-option>
            <mat-option [value]="2">Last 90 days</mat-option>
            <mat-option [value]="3">Last 6 months</mat-option>
            <mat-option [value]="4">Last year</mat-option>
            <mat-option [value]="5">Show all</mat-option>
            <mat-option [value]="6">Show unscheduled only</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Filter</mat-label>
          <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="Filter...">
        </mat-form-field>

        
        <div *ngIf="!loading">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="resort()">
            <ng-container matColumnDef="campaignName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell *matCellDef="let element" (click)="showInfo(element)"> {{element.campaignName}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="campaignType">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
              <mat-cell  *matCellDef="let element" (click)="showInfo(element)"> {{element.campaignType}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="deliveryDateTime">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Date </mat-header-cell>
              <mat-cell *matCellDef="let element" (click)="showInfo(element)"> {{element.deliveryDateTime | date:'EEEE, MMMM d, y hh:mm aa'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="campaignStatusDesc">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
              <mat-cell  *matCellDef="let element" (click)="showInfo(element)"> 
                {{element.campaignStatusDesc}} 
                <ng-container [ngSwitch]="element.campaignStatusID">
                  <mat-icon *ngSwitchCase="1">edit</mat-icon>
                  <mat-icon *ngSwitchCase="2" style="transform: rotateY(180deg);">more</mat-icon>
                  <mat-icon *ngSwitchCase="3">call_made</mat-icon>
                </ng-container>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="campaignActions">
              <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
              <mat-cell *matCellDef="let element"> 
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="showInfo(element)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="copyCampaign(element.campaignID)">
                    <mat-icon>file_copy</mat-icon>
                    <span>Copy</span>
                  </button>
                  <button mat-menu-item (click)="deleteCampaign(element)">
                    <mat-icon>delete_forever</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>

						<!-- Group header cells-->
						  <ng-container matColumnDef="groupHeader">
							<td colspan="999" mat-cell *matCellDef="let group">
								<div fxFill fxLayoutAlign="center center">
									<mat-icon *ngIf="!group.reduced">expand_less</mat-icon>
									<mat-icon *ngIf="group.reduced">expand_more</mat-icon>
									<strong>{{group.groupName}}</strong>
								</div>
							</td>
						</ng-container>
		
		
						<!-- Group line -->
						<tr mat-row  class="group-header-row"
							*matRowDef="let row; columns: ['groupHeader']; when: isGroup" 
							(click)="reduceGroup(row)" > 
						</tr>
		
					</table>
        </div>
    </mat-card-content>
  </mat-card>
</p>

