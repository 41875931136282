import { QuoteRequest, HealthProfile, HealthProfileAlt } from 'acc-models';
import { QuoteRecSort } from 'projects/acc-models/src/lib/models/quoteRecFilter';
import { QuoteResponse } from 'projects/acc-models/src/lib/models/quoteResponse';
import { QuoteResult } from 'projects/acc-models/src/lib/models/quoteResult';

export interface IQuoteState {
	request: QuoteRequest;
	healthProfile: HealthProfile;
	quotesCache: Map<string, QuoteResponse>,
	response: QuoteResponse,
	healthProfileAlt: HealthProfileAlt;
	selections: Array<QuoteResult>,
	recSort: Array<QuoteRecSort>,
	selectedPlans: Array<QuoteResponse>,
	sellingPoints: { reason1?: string, reason2?: string, reason3?: string }
}

export const initialQuoteState: IQuoteState = {
	request: null,
	healthProfile: null,
	quotesCache: new Map<string, QuoteResponse>(),
	response: null,
	healthProfileAlt: null,
	selections: null,
	recSort: null,
	selectedPlans: null,
	sellingPoints: null

}
