import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { User } from 'acc-models';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { SubscriptionLike } from 'rxjs';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
    templateUrl: './team-picture.component.html',
	styleUrls: ['./team-picture.component.scss'],
	styles: [`
		mat-chip{
			display:block;
			width:40%;
		} 
		.mat-chip-list-wrapper {
			display:block;
			columns:2;
		}
	`]
})
export class TeamPictureComponent implements OnInit, OnDestroy, AfterViewChecked {

	public allUsers:Array<User>;
	private selectedUsers:Array<User>;
	private leaders: Array<User>;
	private getUsersSubscription:SubscriptionLike;
	private imagePath:string;
	private errorMessage:string;
	private didInitialCheck: boolean = false;
	public btnOptions: MatProgressButtonOptions;
	public form: FormGroup;
	public titleField = new FormControl('', [ Validators.required, Validators.minLength(3)]);
	public nameField = new FormControl('', [ Validators.required]);
	private formStatusSubscription: SubscriptionLike;

	@ViewChild('targets') targets: ElementRef;
	@ViewChild('allUsersList') allUsersList: any;


	constructor(private sessionService:SessionService, private dataService:DataService, private fb: FormBuilder) {
	}

	
	ngOnInit():void {
		let user = this.sessionService.getUser();
		this.getUsersSubscription = this.dataService.getOrgUsers(user.orgID, '00000000-0000-0000-0000-000000000000').subscribe((resp:any) => {
			this.allUsers = this.sortUsers(resp);
			this.selectedUsers = [];
			this.leaders = [];
		})
		this.btnOptions = {
			active: false,
			text: 'Save', 
			raised: true,
			stroked: false,
			flat:false,
			buttonColor: 'primary',
			spinnerColor: 'primary', 
			mode: 'indeterminate',
			fullWidth: false,
			spinnerSize: 20,
			disabled:true
		}
		this.form = this.fb.group({
			title: this.titleField,
			name: this.nameField
		})
		this.formStatusSubscription = this.form.statusChanges.subscribe(status => { this.btnOptions.disabled = (status != 'VALID') })
	}
	ngAfterViewChecked(): void {
		if(!this.didInitialCheck) {
			if (this.allUsersList && this.allUsersList.element.nativeElement) {
				this.allUsersList.element.nativeElement.style.height = this.targets.nativeElement.clientHeight + 'px';
				this.didInitialCheck = true
			}
		}
	}
	ngOnDestroy():void {
		if(this.getUsersSubscription) { this.getUsersSubscription.unsubscribe(); }
		if(this.formStatusSubscription) { this.formStatusSubscription.unsubscribe(); }
	}

	drop(event: CdkDragDrop<User[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			this.allUsers = this.sortUsers(this.allUsers);
		  } else {
			transferArrayItem(event.previousContainer.data,
							  event.container.data,
							  event.previousIndex,
							  event.currentIndex);
		  }
	}
	private sortUsers(arr:Array<User>):Array<User> {
		return arr.sort((a,b) => {
			if(a.lastName > b.lastName) return 1;
			if(a.lastName < b.lastName) return -1;
			return 0;
		});
	}
	saveForm() {
		Object.keys(this.form.controls).forEach(field => {
			this.form.controls[field].markAsTouched();
		})
		if((this.selectedUsers.length > 0 || this.leaders.length > 0) && this.form.valid) {
			this.btnOptions.active = true;
			this.errorMessage = '';
			const collageData = {
				title: this.titleField.value,
				name: this.nameField.value,
				Users: this.selectedUsers,
				Leaders: this.leaders,
			}
			this.dataService.teamPictures(this.sessionService.getUser().userID, collageData).subscribe((resp:any) => {
				this.imagePath = resp.imagePath;
				this.btnOptions.active = false;
			}, err => {
				this.errorMessage = "The collage could not be created due to a processing error."
				this.btnOptions.active = false;
			})
		} else {
			this.errorMessage = "Please provide a title, a name, and select at least one leader/user"
		}
		
	}
}
