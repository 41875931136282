import { IAppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { IMediaState } from '../state/media.state';

const selectMediaState = (state:IAppState) => state.adminMedia;

export const selectMedia = createSelector(
	selectMediaState,
	(state: IMediaState) => state.Medias
)
export const selectAddingMedia = createSelector(
	selectMediaState,
	(state: IMediaState) => state.AddingMedia
)
export const selectEditingMedia = createSelector(
	selectMediaState,
	(state: IMediaState) => state.EditingMedia
)
export const selectDeletingMedia = createSelector(
	selectMediaState,
	(state: IMediaState) => state.DeletingMedia
)
