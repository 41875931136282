
<div mat-dialog-header>
	<h2 mat-dialog-title>New List</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
		<mat-form-field>
			<mat-label>Name</mat-label>
			<input type="text" id="listName" name="listName" matInput [(ngModel)]="data.listName"  />
		</mat-form-field>
		<mat-form-field>
			<mat-label>Description</mat-label>
			<input type="text" id="listDesc" name="listDesc" matInput [(ngModel)]="data.listDesc"  />
		</mat-form-field>
		<mat-form-field *ngIf="groups">
			<mat-label>Group (optional)</mat-label>
			<mat-select id="listGroupId" name="listGroupId" [(ngModel)]="data.groupID">
				<mat-option></mat-option>
				<mat-option *ngFor="let group of groups" [value]="group.groupID">
					{{group.groupName}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	  </div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="createNew(data)" cdkFocusInitial>Create</button>
	<button mat-stroked-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>

