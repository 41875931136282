import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { User, Profile } from 'acc-models';
import { Router } from '@angular/router';
import { SalesforceLoginComponent } from '../shared/salesforce-login/salesforce-login.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit, OnDestroy {
    public user: User;
	public profile: Profile;
	public crmLoggedIn: boolean = false;
	public integrateCRM: boolean = false;
	private salesforceDialogRef: MatDialogRef<SalesforceLoginComponent>
	
    constructor (private dataService:DataService, private sessionService: SessionService, private router:Router, private dialog: MatDialog) {
		this.user = this.sessionService.getUser();
    }
	
    ngOnInit(): void {
		this.user = this.sessionService.getUser();
		this.crmLoggedIn = this.user.hasCRMTokenData;
        this.dataService.getProfile(this.user.userID).subscribe((prof:any) => {
			this.profile = prof;
		});
		this.integrateCRM = this.user.integrateCRM;
	}
	
	ngOnDestroy():void {
	}

	unlinkCrm() {
		this.dataService.unlinkCrm(this.user.userID).subscribe(resp => {
			this.sessionService.setUser({ ...this.user, hasCRMTokenData: false })
			this.crmLoggedIn = false;
		})

	}
	linkCrm() {
		this.salesforceDialogRef = this.dialog.open(SalesforceLoginComponent);
		this.salesforceDialogRef.afterClosed().pipe(take(1)).subscribe((didLogin) => {
			if(!didLogin) {
				this.sessionService.setUser({...this.user, skipCRM: true})
			}
		})
}

	editProfile(){
		this.router.navigate(['/profile/edit']);
	}

	close():void {
		this.router.navigate(['/welcome']);
	}
}
