import { Component, OnInit, HostListener, ElementRef, OnDestroy, ViewEncapsulation } from "@angular/core";
import { SessionService, DataService } from "acc-services";
import { SentItem, SentItemActivity } from 'acc-models';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'acc-main-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityComponent implements OnInit {

	public startDate: Date ;
	public endDate: Date;
	private destroy$: Subject<boolean> = new Subject();
	public activity: any;
	public timePeriods: Map<string, any>;
	public searchTerm: string = '';
	private userId: string;
	private rawData: Array<SentItemActivity>;

	constructor(private sessionService: SessionService, private dataService: DataService, private route: ActivatedRoute, private router:Router) {}

	ngOnInit() {
		this.userId = this.sessionService.getUser().userID;
		this.endDate = new Date();
		this.startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 30);
		this.getActivity();
	}

	getActivity() {
		let startDate = moment(new Date(this.startDate)).utc();
		let endDate = moment(new Date(this.endDate)).add(1, 'days').utc();
		this.dataService.getActivityByDate(this.userId, startDate.format('YYYY-MM-DD HH~mm'), endDate.format('YYYY-MM-DD HH~mm')).subscribe((resp: any) => {
			this.rawData = resp.map(d => { return { ...d, localTime: moment.utc(d.timestamp).local(), date: moment.utc(d.timestamp).local().format("MMM DD, YYYY") } });
			this.groupData();
		})
	}

	groupData() {
		let data = this.rawData.map(d => {
			var desc = d.eventDesc;
			switch (d.eventDesc) {
				case 'open': desc = 'Opened'; break;
				case 'unsubscribe': desc = 'Unsubscribed'; break;
				case 'click': desc = 'Attachment'; break;
			}
			return { ...d, eventDesc: desc };
		});
		if(this.searchTerm) {
			const searchTerms = this.searchTerm.toLowerCase().split(' ');
			searchTerms.forEach(searchTerm => {
				data = data.filter(d => d.toEmail.toLowerCase().includes(searchTerm) || d.emailSubject.toLowerCase().includes(searchTerm) || d.eventDesc.toLowerCase().includes(searchTerm))
			})
		}
		data = data.sort((a,b) => {
			if (a.localTime < b.localTime) return 1;
			if (a.localTime > b.localTime) return -1;
			return 0;
		});
		//first, sort into time periods
		this.timePeriods = data.reduce((t, a) => {
			t.set(a.date,  [ ...t.get(a.date) || [], a]);
			return t;
		}, new Map<string, any>());

		this.timePeriods.forEach((value, key) => {
			this.timePeriods.set(key, value.reduce((a,i) => {
				let key = `${i.cartDeliveryID}|${i.toEmail}`;
				a.set(key, [ ...a.get(key) || [], i])
				return a;
			}, new Map<number, any>())) 
		})
	}

	get timePeriodHeaders() {
		return this.timePeriods.keys();
	}
	getDateFromDaysAgo(daysAgo) {
		return moment().subtract(daysAgo, 'd').format("MMM DD, YYYY")
	}
	getDate(timestamp) {
		return moment(timestamp).format("MMM DD, YYYY");
	}
	getTime(timestamp) {
		return moment(timestamp).format('h:mm a');
	}
	selectMessage(cartDeliveryID) {
		this.router.navigate(['/sent-items', cartDeliveryID], { relativeTo: this.route });
	}
	navigateTo(url: string) {
		this.router.navigate([url]);
	}
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
