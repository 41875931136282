import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Organization, User } from "acc-models";
import { SessionService } from "acc-services";
import { HideGettingStarted } from "../../store/actions/status.actions";
import { IAppState } from "../../store/state/app.state";

@Component({
	selector: "acc-main-getting-started",
	templateUrl: "./getting-started.component.html",
	styleUrls: ["./getting-started.component.scss"],
})
export class GettingStartedComponent implements OnInit {
	org: Organization;
	paidSubscription: Boolean;
	public user: User;
	public isAdmin: Boolean;

	constructor(private store: Store<IAppState>, private router: Router, private sessionService: SessionService) {}

	ngOnInit() {
		this.org = this.sessionService.getItem("organization");
		this.paidSubscription = !this.sessionService.isSubscriptionFreeOrEmpty();
		this.user = this.sessionService.getUser();
		this.isAdmin = this.user.admin;
	}

	close() {
		//hide Getting Started
		this.store.dispatch(new HideGettingStarted());
	}

	goToProfile() {
		this.store.dispatch(new HideGettingStarted());
		this.router.navigateByUrl("/profile/edit");
	}

	upgrade(): void {
		this.store.dispatch(new HideGettingStarted());
		this.router.navigate(["/admin/subscription"]);
	}
}
