
<div class="searchbox">
	<mat-form-field class="search-field" appearance="outline">
		<i class="fa fa-search search-icon" (click)="doSearch()"></i>
		<input type="text" matInput class="search-input"  placeholder="Search" [(ngModel)]="searchTerm" (keyup.enter)="doSearch()">
	</mat-form-field>
</div>
<nav id="docgenNav">
	<div id="categories">
		<div class="current mobile" [ngClass]="{active: categoryMenuOpen}" *ngIf="currentCategory" (click)="expandCategory($event)">{{currentCategory.categoryDesc}}</div>
		<ol *ngIf="categories" class="categories listType{{docOutline?.categoryListTypeID}}">
			<ng-container *ngFor="let category of categories">
				<li [ngClass]="{active: category.categoryID == currentCategory?.categoryID, searchHighlight: category.foundInSearch, open: categoryOpen}">
					<a (click)="tryChangeCategory('category', category)">{{category.categoryDesc}}</a>

					<ol class="subcategories listType{{docOutline?.subCategoryListTypeID}}">
						<ng-container *ngFor="let subcategory of category.SubCategories">
							<li [ngClass]="{active: subcategory.subCategoryID == currentSubcategory?.subCategoryID, searchHighlight: subcategory.foundInSearch}">
								<a (click)="tryChangeCategory('subcategory', subcategory)">{{subcategory.subCategoryDesc}}</a>
							</li>
						</ng-container>
					</ol>
				</li>
			</ng-container>
		</ol>
	</div>
	
	<div id="subcategories">
		<div class="current mobile" [ngClass]="{active: subcategoryMenuOpen}" *ngIf="currentSubcategory" (click)="expandSubcategory($event)">{{currentSubcategory.subCategoryDesc}}</div>
		<ol *ngIf="subcategories">
			<ng-container *ngFor="let subcategory of subcategories">
				<li [ngClass]="{active: subcategory.subCategoryID == currentSubcategory?.subCategoryID, searchHighlight: subcategory.foundInSearch}">
					<a (click)="tryChangeCategory('subcategory', subcategory)">{{subcategory.subCategoryDesc}}</a>
				</li>
			</ng-container>
		</ol>
	</div>
</nav>
