<div mat-dialog-header>
	<h2 mat-dialog-title>{{data.action}} File</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<p>Select a destination folder</p>
		<div class="libraryFolders">
			<div class="error" *ngIf="errorMessage">{{errorMessage}}</div>
			<ul>
				<acc-common-library-content-folders [folders]="data.folders" (onAction)="action($event)" [activeFolder]="selectedFolder" [showFolderActions]="false"></acc-common-library-content-folders>
			</ul>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="copy()">{{data.action}}</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>