/**
 * @prettier
 */
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { DataService, SessionService } from "acc-services";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute } from "@angular/router";
import { CommListData, Button, CommList, CommGroup } from "acc-models";
import { Location } from "@angular/common";
import { SubscriptionLike, Subject } from "rxjs";
import { select, Store } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import {
	SetTitle,
	CommunicationActionTypes,
	SaveListConfirm,
	GetList,
	GetListSuccess,
	GetGroups
} from "../../store/actions/communication.actions";
import { Actions } from "@ngrx/effects";
import { map, takeUntil, tap } from "rxjs/operators";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as CommunicationSelectors from "../../store/selectors/communication.selectors";

@Component({
	selector: "app-list-detail",
	templateUrl: "./list-detail.component.html",
	styleUrls: ["./list-detail.component.scss", "../shared/communication.scss"]
})
export class ListDetailComponent implements OnInit, OnDestroy {
	public list = new CommList();
	private getListSubscription: SubscriptionLike;
	private getListDataSubscription: SubscriptionLike;
	private putListSubscription: SubscriptionLike;
	private deleteListSubscription: SubscriptionLike;
	public confirmDeleteButtons: Array<Button> = new Array<Button>();
	public confirmDeleteItemMessage: string = "Are you sure you want to delete the list?";
	public showDeleteList: boolean = false;
	private records = new MatTableDataSource<CommListData>();
	displayedColumns: string[] = ["firstName", "lastName", "email", "phone"];
	public form: FormGroup;
	public listNameField: FormControl = new FormControl('', [Validators.required]);
	public listDescField: FormControl = new FormControl('', []);
	public listGroupIdField: FormControl = new FormControl('',[]);

	@ViewChild(MatPaginator) paginator: MatPaginator;
	length = 0;
	pageSize = 50;
	private destroy$: Subject<boolean> = new Subject<boolean>();
	public groups: Array<CommGroup>;
	public groups$ = this.store.pipe(
		select(CommunicationSelectors.selectGroups),
		tap((g) => (this.groups = g ? g.sort((a,b) => a.groupName < b.groupName ? -1 : 1) : null)),
		takeUntil(this.destroy$)
	).subscribe();
	constructor(
		private dataService: DataService,
		private snackBar: MatSnackBar,
		private router: Router,
		private route: ActivatedRoute,
		private dialog: MatDialog,
		private _location: Location,
		private store: Store<IAppState>,
		private actions$: Actions,
		private fb: FormBuilder,
		private sessionService: SessionService
	) {
		this.confirmDeleteButtons.push(new Button("Yes", ""));
		this.confirmDeleteButtons.push(new Button("No", ""));

		actions$
			.pipe(
				map(a => {
					switch (a.type) {
						case CommunicationActionTypes.SAVE_LIST:
							//shouldn't be needed anymore, since we moved "save" out of actions in header, but leaving in case we return to it
							this.store.dispatch(new SaveListConfirm(this.list));
							break;
						case CommunicationActionTypes.SAVE_LIST_SUCCESS:
							this.snackBar.open("Your changes have been saved", "OK", { duration: 3000 });
							this.store.dispatch(new SetTitle(this.list.listName));
							break;
						case CommunicationActionTypes.GET_LIST_SUCCESS:
							this.list = (a as GetListSuccess).list;
							this.length = this.list.totalRecords;
							this.records = new MatTableDataSource(this.list.CommListDatas);
							this.records.paginator = this.paginator;
							this.store.dispatch(new SetTitle(this.list.listName));
							this.listNameField.setValue(this.list.listName);
							this.listDescField.setValue(this.list.listDesc);
							this.listGroupIdField.setValue(this.list.groupID)
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.store.dispatch(new GetList(params.listID));
		});
		this.form = this.fb.group({
			listName: this.listNameField,
			listDesc: this.listDescField,
			listGroupId: this.listGroupIdField
		});
		this.store.dispatch(new GetGroups(this.sessionService.getUser().orgID));
	}

	goBack() {
		this._location.back();
	}

	deleteList() {
		this.showDeleteList = true;
	}

	goToImportCSV() {
		this.router.navigate(["/communication/lists/list-import", this.list.listID]);
	}

	closeDeleteItem(idx: number) {
		if (idx === 0) {
			this.deleteListSubscription = this.dataService.deleteList(this.list.listID).subscribe((deleted: any) => {
				let snackBarRef = this.snackBar.open("List Deleted!", "Close", {
					duration: 3000
				});
				this.router.navigate(["/communication/lists"]);
			});
		}
		this.showDeleteList = false;
	}

	public handlePaging(e: any) {
		this.getListDataSubscription = this.dataService
			.getListDataByPage(this.list.listID, e.pageIndex)
			.subscribe((list: any) => {
				this.records = new MatTableDataSource(list.CommListDatas);
			});
	}

	save() {
		if(this.form.valid) {
			this.store.dispatch(new SaveListConfirm({ ...this.list, listName: this.form.controls['listName'].value, listDesc: this.form.controls['listDesc'].value, groupID: this.form.controls['listGroupId'].value}));
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
