
import { Component, OnChanges, SimpleChanges, OnInit, OnDestroy, Output, EventEmitter, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { LibraryItem } from 'acc-models';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { PreviewVideoComponent } from '../../preview-video/preview-video.component';

@Component({
	selector: 'acc-common-library-cart-files',
	templateUrl: './library-cart-files.component.html',
	styleUrls: ['./library-cart-files.component.scss']
  })
  export class LibraryCartFilesComponent implements OnInit, OnDestroy, OnChanges {
	
	@Input() files: Array<LibraryItem>;
	@Input() cartId: string
	@Input() folderType: string;
	@Input() showFileActions: boolean = true;
	@Output() onAction: EventEmitter<{actionName: string, payload: any}> = new EventEmitter();
	@ViewChild('rowLayout', { static: true }) rowLayout: ElementRef;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.getItemsTable();
	}
	public openLibraryItemId:string;
	
	private boxWidth = 225;
	private columnSize: number;
	public itemsTable: Array<LibraryItem[]>;
	private redrawTable: boolean = true;
	private destroy$: Subject<boolean> = new Subject();
	constructor (private dialog: MatDialog) {
	}

	getItemsTable(rowLayout?: Element): LibraryItem[][] {
		rowLayout = rowLayout || this.rowLayout.nativeElement;
		let { width } = rowLayout.getBoundingClientRect();
		//width = 900;
		const columnSize = Math.floor(width / this.boxWidth);
		if(columnSize != this.columnSize || this.redrawTable) {
			this.columnSize = columnSize;
			this.initTable();
			this.redrawTable = false;
		}
		return this.itemsTable;
	}
	initTable() {
		const filtered = this.files
			.filter((_, outerIndex) => outerIndex % this.columnSize == 0);
		const mapped = 	filtered.map((_, rowIndex) => this.files.slice(rowIndex * this.columnSize, rowIndex * this.columnSize + this.columnSize));
		this.itemsTable = mapped;
	}

	setContent(file, evt) {
		evt.target.innerText = file.shortDescription;
	}
	getBackgroundImageUrl(path) {
		return `url('${path}')`;
	}

	showPreview(mediaItemId:string) {
		//TODO: make sure this doesn't get caught by popup blocker
		this.onAction.emit({actionName: 'showPreview', payload: { mediaItemId }})
	}
	action(evt: { actionName: string, payload: any }) {
		switch(evt.actionName) {
			case 'expand':
				this.openLibraryItemId = evt.payload.id;
				break;
			case 'open': 
				this.generate(evt.payload.file);
				evt.actionName = ''; //clear out action, we did what we need to do
				this.openLibraryItemId = null; //close the menu if they perform  an action
				break;
			default:
				this.openLibraryItemId = null; //close the menu if they perform  an action
			}
		this.onAction.emit(evt);
	}
	generate(item) {
		const onVideoGenerated:Subject<{ mediaAsyncID: string, videoURL: string, thumbnailURL: string}> = new Subject();
		const dialogRef = this.dialog.open(PreviewVideoComponent, {
			width: '800px',
			height: '550px',
			data: item.MediaAsyncAttributes.reduce((c, i) => { return { ...c, [i.attributeName]:  i.mediaAsyncAttributeTypeID == 4 ? JSON.parse(i.attributeValue) : i.attributeValue } }, {mediaItem: item, onVideoGenerated})
		  });
		  onVideoGenerated.pipe(take(1), takeUntil(this.destroy$)).subscribe((vid:any) => {
			const item = this.files.filter(i => i.id == vid.mediaAsyncID)[0];
			item.imageFile = vid.thumbnailURL;
			(item as any).mediaUrl = vid.videoURL;
		  });
		  (dialogRef.componentInstance as PreviewVideoComponent).onError.pipe(takeUntil(this.destroy$)).subscribe(err => this.action({actionName: 'error', payload: err}))

	  }
	ngOnInit():void {


	}
	ngOnChanges(changes:SimpleChanges) {
		if(changes['files'] && changes['files'].currentValue) {
			this.files = changes['files'].currentValue;
			this.redrawTable = true;
		}
		if(changes.showFileActions && changes.showFileActions.currentValue) {
			this.showFileActions = changes.showFileActions.currentValue;
		}
		if(changes.cartId) {
			this.redrawTable = true;
		}
	}
	ngOnDestroy():void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	drop(event: CdkDragDrop<LibraryItem[]>) {
		if(event.previousContainer === event.container) {
			// same row/container? => move item in same row
			moveItemInArray( event.container.data, event.previousIndex, event.currentIndex);
		} else {
			// different rows? => transfer item from one to another list
			transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
		}

		// update items after drop: flatten matrix into list
    	// example: [ [1,2,3], [4,5,6] ] => [1,2,3,4,5,6]
		this.files = this.itemsTable.reduce((previous, current) => previous.concat(current), []);

		// re-initialize table - makes sure each row has same numbers of entries
    	// example: [ [1,2], [3,4,5,6] ] => [ [1,2,3], [4,5,6] ]
		this.initTable();

		this.onAction.next({ actionName: 'sortCart', payload: this.files.map((f,i) => { return { ID: f.id, sortOrder: i+1 }; }) })
	}
}