
<div class="actions" #menu>
	<ul>
		<li matTooltip="Edit document title and other details"><div class="btn icon info" (click)="doAction('goBack')"><span></span><div>Edit</div></div></li>
		<li matTooltip="Update categories for this document"><div class="btn icon stream" (click)="doAction('editOutline')"><span></span><div>Outline</div></div></li>
		<li matTooltip="Close this document and return home"><div class="btn icon close" (click)="doAction('close')"><span></span><div>Close</div></div></li>
		<li matTooltip="Preview this document in a new tab"><div class="btn icon preview" (click)="doAction('preview')"><span></span><div>Preview</div></div></li>
		<li matTooltip="Save changes"><div class="btn icon save" (click)="doAction('save')" ><span></span><div>Save</div></div></li>
		<li matTooltip="Save changes and send document"><div class="btn icon send" (click)="doAction('send', quoteId)"><span></span><div>Save and Send</div></div></li>
		<li matTooltip="Return to Quote" *ngIf="quoteId"><div class="btn icon back" (click)="doAction('quote')"><span></span><div>Return to Quote</div></div></li>
		<li class="hasSubmenu">
			<div class="btn">More...</div>
			<input type="checkbox">
			<ul>
				<li matTooltip="Revert changes to default options for each field"><div class="btn icon revert" (click)="doAction('revert')" ><span></span><div>Revert</div></div></li>
				<li matTooltip="Save as template for LifeQuote Proposals" *ngIf="showTemplateLink"><div class="btn icon document" (click)="doAction('template')"><span></span><div>Save as Template</div></div></li>
			</ul>
</li>
	</ul>
</div>
