<div class="container">
	
	
		<div class="moduleSelect" *ngIf="modules$ | async" >
			<p>Choose a module to edit <mat-icon class="info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_categories_3level}}" >info_outline</mat-icon></p>
			<select (change)="moduleSelected()" [(ngModel)]="activeModuleId">
				<option *ngIf="activeModuleId == 0"></option>
				<option *ngFor="let mod of modules" value="{{mod.moduleID}}">{{mod.moduleDesc}}</option>
			</select>
		</div>
	
		<div *ngIf="categories$ | async">
			<div class="grid">
				<div class="col">
					<div class="header row">
						<div class="description">Category Description <mat-icon class="info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_category_description}}" >info_outline</mat-icon></div>
					</div>
					<ul class="row" cdkDropList (cdkDropListDropped)="dropCategory($event)">
						<li class="item" *ngFor="let cat of categories" cdkDrag [cdkDragDisabled]="editingCategory?.categoryID == cat.categoryID" (click)="activateCategory(cat)" [ngClass]="{active: cat.categoryID === activeCategory?.categoryID}">
							<div class="description">
								<span *ngIf="editingCategory?.categoryID != cat.categoryID">{{cat.categoryDesc}}</span>
								<input type="text" [(ngModel)]="cat.categoryDesc" *ngIf="editingCategory?.categoryID == cat.categoryID">
							</div>
							<div class="actions">
								<span class="editCategory" (click)="selectCategoryToEdit(cat)" *ngIf="editingCategory?.categoryID != cat.categoryID"><i class="fa fa-edit"></i></span>
								<span class="editCategory" (click)="saveCategoryEdit(cat)" *ngIf="editingCategory?.categoryID == cat.categoryID"><i class="fa fa-save"></i></span>
								<span class="deleteCategory" (click)="deleteCategory(cat)"><i class="fa fa-times"></i></span>
							</div>
						</li>
						<li class="item">
							<div class="description">
								<input type="text" [(ngModel)]="newCategoryName" (keyup)="checkEnter($event, 'cat')" />
							</div>
							<div class="actions">
								<span class="addCategory" (click)="addCategory()"><i class="fa fa-save"></i></span>
							</div>
						</li>
					</ul>
				</div>
	
	
				<div class="col" *ngIf="subcategories$ | async">
					<div class="header row">
						<div class="description" > Subcategory Description <mat-icon class="info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_subcategory_description}}" >info_outline</mat-icon></div>
					</div>
					<ul class="row" cdkDropList (cdkDropListDropped)="dropSubcategory($event)">
						<li class="item" *ngFor="let sub of subcategories" cdkDrag [cdkDragDisabled]="editingSubcategory?.subCategoryID == sub.subCategoryID" (click)="activateSubcategory(sub)" [ngClass]="{active: sub.subCategoryID === activeSubcategory?.subCategoryID}">
							<div class="description">
								<span *ngIf="editingSubcategory?.subCategoryID != sub.subCategoryID">{{sub.subCategoryDesc}}</span>
								<input type="text" [(ngModel)]="sub.subCategoryDesc" *ngIf="editingSubcategory?.subCategoryID == sub.subCategoryID">
							</div>
							<div class="actions">
								<span class="editCategory" (click)="selectSubcategoryToEdit(sub)" *ngIf="editingSubcategory?.subCategoryID != sub.subCategoryID"><i class="fa fa-edit"></i></span>
								<span class="editCategory" (click)="saveSubcategoryEdit(sub)" *ngIf="editingSubcategory?.subCategoryID == sub.subCategoryID"><i class="fa fa-save"></i></span>
								<span class="deleteCategory" (click)="deleteSubcategory(sub)"><i class="fa fa-times"></i></span>
							</div>
						</li>
						<li class="item">
							<div class="description">
								<input type="text" [(ngModel)]="newSubcategoryName" (keyup)="checkEnter($event, 'subcat')" />
							</div>
							<div class="actions">
								<span class="addCategory" (click)="addSubcategory()"><i class="fa fa-save"></i></span>
							</div>
						</li>
					</ul>
				</div>
	
	
				<div class="col textfields" *ngIf="texts$ | async">
					<div class="header row">
						<div class="description" >Text Fields <mat-icon class="info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_subcategory_text}}" >info_outline</mat-icon></div>
					</div>
					<ul class="row" cdkDropList (cdkDropListDropped)="dropText($event)">
						<li class="item text" *ngFor="let text of texts" cdkDrag [cdkDragDisabled]="editingText?.textID == text.textID" [ngClass]="{editing: text.textID === editingText?.textID}">
							<div class="textView" *ngIf="editingText?.textID != text.textID">
								<div class="description">
									<span class="textPreview">{{text.defaultText}}</span>
								</div>
								<span *ngIf="text.revisionNeededDate" class="revDate {{text.status}}">Revision Needed By: {{text.revisionNeededDate | date:"longDate"}}</span>
							</div>
							<ng-container *ngIf="editingText?.textID == text.textID">
								<div class="description">
									<textarea [(ngModel)]="text.defaultText"></textarea>
									<div class="field">
										<mat-form-field>
											<input matInput [matDatepicker]="picker" [(ngModel)]="text.revisionNeededDate">
											<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
												<mat-label>Revision Needed Date</mat-label>
											<mat-datepicker #picker></mat-datepicker>
										</mat-form-field>
									</div>
								</div>
							</ng-container>
								
							<div class="actions">
								<span class="editCategory" (click)="selectTextToEdit(text)" *ngIf="editingText?.textID != text.textID"><i class="fa fa-edit"></i></span>
								<span class="editCategory" (click)="saveTextEdit(text)" *ngIf="editingText?.textID == text.textID"><i class="fa fa-save"></i></span>
								<span class="deleteCategory" (click)="deleteText(text)"><i class="fa fa-times"></i></span>
							</div>
						</li>
						<li class="item text editing">
							<div class="description">
								<p>Add New</p>
								<textarea [(ngModel)]="newTextCopy" ></textarea>
								<mat-form-field>
									<input matInput [matDatepicker]="picker" [(ngModel)]="newRevisionDate">
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-label>Revision Needed Date</mat-label>
									<mat-datepicker #picker></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="actions">
								<p>&nbsp;</p>
								<span class="addCategory" (click)="addSubcatText()"><i class="fa fa-save"></i></span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	