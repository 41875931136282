import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import * as moment from "moment";
import { TimePeriod } from '../../quote-engine/analytics/analytics.component';
import { Subject } from 'rxjs';

@Component({
	selector: "acc-main-date-selector",
	templateUrl: "./date-selector.component.html",
	styleUrls: ["./date-selector.component.scss"]
})
export class DateSelectorComponent implements OnInit, OnChanges {
	public timePeriods: Array<TimePeriod> = [];
	public startDate: any;
	public endDate: any;
	public dateRangeSelection: TimePeriod;

	@Input() onDateSelected: Subject<TimePeriod>
	@Input() defaultTimePeriod: TimePeriod
	@Input() minDate: Date;
	@Input() maxDate: Date;

	constructor() {}

	ngOnInit() {
		this.getTimePeriods();
		if(this.defaultTimePeriod) {
			this.setTimePeriod(this.defaultTimePeriod);
			this.dateRangeSelection = this.defaultTimePeriod;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes.defaultTimePeriod && changes.defaultTimePeriod.currentValue) {
			this.setTimePeriod(changes.defaultTimePeriod.currentValue);
		}
	}

	setTimePeriod(timePeriod:TimePeriod) {
		this.startDate = timePeriod.start;
		this.endDate = timePeriod.end;
		this.dateRangeSelection = this.timePeriods.find(p => p.name == timePeriod.name); 
		this.onDateSelected.next(timePeriod);

	}
	getTimePeriods() {
		const today = moment();
		this.timePeriods = [];
		this.timePeriods.push({ name: "Custom", start: "", end: "" });
		this.timePeriods.push({ name: "Today", start: moment().startOf('day').toDate(), end: moment().endOf('day').toDate() });
		this.timePeriods.push({
			name: "This Week",
			start: moment()
				.startOf("week")
				.toDate(),
			end: moment()
				.endOf("week")
				.toDate()
		});
		this.timePeriods.push({
			name: "This Month",
			start: moment()
				.startOf("month")
				.toDate(),
			end: moment()
				.endOf("month")
				.toDate()
		});
		this.timePeriods.push({
			name: "This Quarter",
			start: moment()
				.startOf("quarter")
				.toDate(),
			end: moment()
				.endOf("quarter")
				.toDate()
		});
		this.timePeriods.push({
			name: "This Year",
			start: moment()
				.startOf("year")
				.toDate(),
			end: moment()
				.endOf("year")
				.toDate()
		});

		this.timePeriods.push({
			name: "Yesterday",
			start: moment()
				.subtract(1, "day")
				.toDate(),
			end: moment()
				.subtract(1, "day")
				.toDate()
		});
		this.timePeriods.push({
			name: "Last Week",
			start: moment()
				.startOf("week")
				.subtract(1, "day")
				.startOf("week")
				.toDate(),
			end: moment()
				.startOf("week")
				.subtract(1, "day")
				.toDate()
		});
		this.timePeriods.push({
			name: "Last Month",
			start: moment()
				.startOf("month")
				.subtract(1, "day")
				.startOf("month")
				.toDate(),
			end: moment()
				.startOf("month")
				.subtract(1, "day")
				.toDate()
		});
		this.timePeriods.push({
			name: "Last Quarter",
			start: moment()
				.startOf("quarter")
				.subtract(1, "day")
				.startOf("quarter")
				.toDate(),
			end: moment()
				.startOf("quarter")
				.subtract(1, "day")
				.toDate()
		});
		this.timePeriods.push({
			name: "Last Year",
			start: moment()
				.startOf("year")
				.subtract(1, "day")
				.startOf("year")
				.toDate(),
			end: moment()
				.startOf("year")
				.subtract(1, "day")
				.toDate()
		});

		this.timePeriods.push({
			name: "Last 30 Days",
			start: moment()
				.subtract(30, "days")
				.toDate(),
			end: moment().toDate()
		});
		this.timePeriods.push({
			name: "Last 60 Days",
			start: moment()
				.subtract(60, "days")
				.toDate(),
			end: moment().toDate()
		});
		this.timePeriods.push({
			name: "Last 90 Days",
			start: moment()
				.subtract(90, "days")
				.toDate(),
			end: moment().toDate()
		});
		this.timePeriods.push({
			name: "Next 30 Days",
			start: moment()
				.add(30, "days")
				.toDate(),
			end: moment().toDate()
		});
		this.timePeriods.push({
			name: "Next 60 Days",
			start: moment()
				.add(60, "days")
				.toDate(),
			end: moment().toDate()
		});
		this.timePeriods.push({
			name: "Next 90 Days",
			start: moment()
				.add(90, "days")
				.toDate(),
			end: moment().toDate()
		});
		this.timePeriods.push({
			name: "All Time",
			start: moment().subtract(10, "years"),
			end: moment().add(10, "years")
		})

		if(this.minDate) {
			this.timePeriods = this.timePeriods.filter(p => p.end >= this.minDate)
		}
		if(this.maxDate) {
			this.timePeriods = this.timePeriods.filter(p => p.start <= this.maxDate )
		}
	}
	updateDateRange(e) {
		this.startDate = e.value.start;
		this.endDate = e.value.end;
		this.onDateSelected.next(e.value);
	}
	chooseDate() {
		this.dateRangeSelection = this.timePeriods.find(p => p.name == 'Custom'); 
		if(this.startDate && this.startDate.toDate) { this.startDate = this.startDate.toDate(); }
		if(this.endDate && this.endDate.toDate) { this.endDate = this.endDate.toDate(); }
		if(moment.isDate(this.startDate) && moment.isDate(this.endDate)) {
			const selection: TimePeriod = { name: 'Custom', start: this.startDate, end: this.endDate };
			this.onDateSelected.next(selection);
		}
	}
}

