import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Folder } from 'acc-models';

@Component({
	selector: 'acc-common-library-content-folders',
	templateUrl: './library-content-folders.component.html',
	styleUrls: ['./library-content-folders.component.scss']
})

export class LibraryContentFoldersComponent implements OnChanges  {

	@Input() folders: Array<Folder>;
	@Input() activeFolder: Folder;
	@Input() showFolderActions: boolean = true;
	@Input() showCartActions: boolean;
	@Output() onAction: EventEmitter<{actionName: string, payload: any}> = new EventEmitter();
	@Input() showFolderTooltip: boolean = false;
	@Input() folderTooltip: string = "";

	constructor () {
	}
	folderAction(evt) {
		this.onAction.emit(evt);
		if(evt.actionName == 'folderSelected') {
			this.activeFolder = evt.payload.folder;
		}
	}

	ngOnChanges(changes:SimpleChanges) {
		if(changes['folders'] && changes.folders.currentValue) {
			this.folders = changes.folders.currentValue;
		}
	}

}