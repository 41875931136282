import { createSelector } from "@ngrx/store";
import { IAppState } from "../state/app.state";
import { IUserState } from "../state/user.state";

const selectUsers = (state: IAppState) => state.adminUser;

export const selectUserList = createSelector(selectUsers, (state: IUserState) => state.users);

export const selectActiveUser = createSelector(selectUsers, (state: IUserState) => state.activeUser);

export const selectProfile = createSelector(selectUsers, (state: IUserState) => state.profile);

export const selectOrgId = createSelector(selectUsers, (state: IUserState) => state.orgId);

export const selectUserToDelete = createSelector(selectUsers, (state: IUserState) => state.userToDelete);

export const selectProfilePhoto = createSelector(selectUsers, (state: IUserState) => state.profilePhoto);

export const selectAddSeats = createSelector(selectUsers, (state: IUserState) => state.addSeatDialog);