<div mat-dialog-header>
	<h2 mat-dialog-title>Name Template</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<mat-form-field>
			<mat-label>Template Name</mat-label>
			<input matInput [formControl]="templateNameField" (keyup.enter)="submit()" #templateNameInput />
			<mat-error *ngIf="templateNameField.hasError('required')">Template Name is required</mat-error>
		</mat-form-field>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="submit()" [disabled]="!nameForm.valid">Save</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>