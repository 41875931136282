import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { QuotesResultsComponent } from '../../quote-results/quotes-results/quotes-results.component';
import { Injectable } from "@angular/core";

@Injectable()
export class CanDeactivateQuote implements CanDeactivate<QuotesResultsComponent> {
    
    canDeactivate(target: QuotesResultsComponent,
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) 
    {
      return !target.isQuoteResultSaved()?target.canNavigateAway():true;
    }

  }