<div class="container quote-container" id="analytics">
	<h1>Quote Reporting</h1>
	<div class="date-section">
		<acc-main-date-selector [onDateSelected]="dateSelected1" class="date1" [maxDate]="today" [defaultTimePeriod]="defaultTimePeriod"></acc-main-date-selector>
		<acc-main-date-selector [onDateSelected]="dateSelected2" class="date2" [maxDate]="today" *ngIf="doCompare"></acc-main-date-selector>
		<div class="action">
			<button mat-raised-button color="primary" (click)="toggleCompare()">{{ doCompare ? 'Cancel' : ''}} Compare</button>
		</div>
	</div>

	<div class="reportFormat">
		<mat-form-field>
			<mat-label>Metric</mat-label>
			<mat-select (selectionChange)="setMetric($event)" [(ngModel)]="metric">
				<mat-option value="Premium">Premium</mat-option>
				<mat-option value="FaceAmount">Face Amount</mat-option>
				<mat-option value="ItemCount">Count</mat-option>
			</mat-select>
			<mat-icon matSuffix class="an-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.q_metrics}}" >info_outline</mat-icon>
		</mat-form-field>

		<div *ngIf="!doCompare">
			<mat-checkbox [(ngModel)]="showQuoted" (change)="setVisibility()">Quotes</mat-checkbox>
			<mat-checkbox [(ngModel)]="showApplied" (change)="setVisibility()">Applications</mat-checkbox>
		</div>
		<div *ngIf="doCompare">
			<mat-radio-group [value]="showQuoted ? 1 : (showApplied ? 2 : 0)">
				<mat-radio-button [value]="1" (change)="setType($event)">Quotes</mat-radio-button>
				<mat-radio-button [value]="2" (change)="setType($event)">Applications</mat-radio-button>
			</mat-radio-group>
		</div>
	</div>
	<div class="filter-row">
		<div class="filteredOn" *ngIf="filteredOn">{{filteredOn.name}}<span (click)="filterBy(null)">X</span></div>&nbsp;
	</div>
	<div *ngIf="rawData?.Brokers.length > 0" class="export-button">
		<button mat-raised-button color="primary" (click)="exportToExcel()">Export to Excel</button>
	</div>

	<div class="data" *ngIf="rawData?.Brokers.length > 0; else no_results">
		<div class="col broker">
			<div class="table-container">
				<table>
					<thead>
						<tr>
							<th (click)="sort('broker', 'name')">Broker</th>
							<th *ngIf="dataPoint1" (click)="sort('broker', dataPoint1.name)">{{dataPoint1.name}}</th>
							<th *ngIf="dataPoint2" (click)="sort('broker', dataPoint2.name)">{{dataPoint2.name}}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let row of brokerData">
							<td class="filter-field" (click)="filterBy({ name: row.name, id: row.id })">{{row.name}}</td>
							<td *ngIf="dataPoint1">{{metric == 'ItemCount' ? row[dataPoint1.name] : row[dataPoint1.name] | currency }}</td>
							<td *ngIf="dataPoint2">{{metric == 'ItemCount' ? row[dataPoint2.name] : row[dataPoint2.name] | currency }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<acc-main-analytics-chart [data]="brokerData" [showTop]="10" [dataPoint1]="dataPoint1" [dataPoint2]="dataPoint2"></acc-main-analytics-chart>
		</div>

		<div class="col carrier">
			<div class="table-container">
				<table>
					<thead>
						<tr>
							<th (click)="sort('carrier', 'name')">Carrier</th>
							<th *ngIf="dataPoint1" (click)="sort('carrier', dataPoint1.name)">{{dataPoint1.name}}</th>
							<th *ngIf="dataPoint2" (click)="sort('carrier', dataPoint2.name)">{{dataPoint2.name}}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let row of carrierData">
							<td>{{row.name}}</td>
							<td *ngIf="dataPoint1">{{metric == 'ItemCount' ? row[dataPoint1.name] : row[dataPoint1.name] | currency }}</td>
							<td *ngIf="dataPoint2">{{metric == 'ItemCount' ? row[dataPoint2.name] : row[dataPoint2.name] | currency }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<acc-main-analytics-chart [data]="carrierData" [showTop]="10" [dataPoint1]="dataPoint1" [dataPoint2]="dataPoint2"></acc-main-analytics-chart>
		</div>

		<div class="col state">
			<div class="table-container">
				<table>
					<thead>
						<tr>
							<th (click)="sort('timePeriod', 'name')">Time Period</th>
							<th *ngIf="dataPoint1" (click)="sort('timePeriod', dataPoint1.name)">{{dataPoint1.name}}</th>
							<th *ngIf="dataPoint2" (click)="sort('timePeriod', dataPoint2.name)">{{dataPoint2.name}}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let row of timePeriodData">
							<td>{{row.name}}</td>
							<td *ngIf="dataPoint1">{{metric == 'ItemCount' ? row[dataPoint1.name] : row[dataPoint1.name] | currency }}</td>
							<td *ngIf="dataPoint2">{{metric == 'ItemCount' ? row[dataPoint2.name] : row[dataPoint2.name] | currency }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<acc-main-analytics-chart [data]="timePeriodData" [showTop]="10" [dataPoint1]="dataPoint1" [dataPoint2]="dataPoint2"></acc-main-analytics-chart>
		</div>

	</div>

	<ng-template #no_results>
		<div>There is no data to display for the selected date range</div>
	</ng-template>
</div>