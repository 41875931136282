
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { DataService } from 'acc-services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { MustMatch } from '../../../helpers/validators';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'acc-common-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {

	private validateTokenSubscription: SubscriptionLike;
	private watchParams: SubscriptionLike;
	private formStatusSubscription: SubscriptionLike;
	private token: string;
	public org: string;
	public invalidToken:boolean = null;
	public updateSuccess: boolean = false;
	public errorMessage: string;
	public resetForm: FormGroup;
	public passwordField = new FormControl('', [Validators.required]);
	public confirmPasswordField = new FormControl('', [Validators.required]);
	public btnOptions: MatProgressButtonOptions
	
	@Output() onNavigateTo: EventEmitter<string> = new EventEmitter();
	@Output() onReset: EventEmitter<boolean> = new EventEmitter();

	constructor(private dataService: DataService, private route: ActivatedRoute, private router:Router, private fb: FormBuilder) { }

	updatePassword() {
		this.btnOptions.active = true;
		this.errorMessage = '';
		if(this.resetForm.valid) {
			this.dataService.resetPassword(this.token, this.passwordField.value).subscribe((resp: any) => {
				//if(resp.success) {
					this.updateSuccess = true;
					this.btnOptions.active = false;
					this.onReset.next(true);
				//} else {
				//	this.errorMessage = "Your password could not be updated"
				//}
			}, (resp:any) => {
				this.errorMessage = resp.error.Message;
				this.btnOptions.active = false;
				this.onReset.next(false);
			})
		}
	}
	ngOnInit() {
		this.watchParams = this.route.paramMap.subscribe(params => {
			this.token = params.get('code');
			this.org = params.get('resource');
			this.validateTokenSubscription = this.dataService.validateRecoveryToken(this.token).subscribe((resp:any) => {
				if(resp.success == true) {
					this.invalidToken = false;
				} else {
					//invalid token
					this.invalidToken = true;
				}
			})     
		});
		this.btnOptions = {
			active: false,
			text: 'Reset Password', 
			raised: true,
			stroked: false,
			flat:false,
			buttonColor: 'primary',
			spinnerColor: 'primary', 
			mode: 'indeterminate',	
			fullWidth: true,
			spinnerSize: 20,
			disabled: true
		}
		this.resetForm = this.fb.group({
			password: this.passwordField,
			confirmPassword: this.confirmPasswordField
		}, {validator: MustMatch('password', 'confirmPassword')});
		
		this.formStatusSubscription = this.resetForm.statusChanges.subscribe(status => this.btnOptions.disabled = (status != 'VALID'))
	}

	ngOnDestroy():void {
		if(this.watchParams) { this.watchParams.unsubscribe(); }
		if(this.validateTokenSubscription) { this.validateTokenSubscription.unsubscribe(); }
		if(this.formStatusSubscription) { this.formStatusSubscription.unsubscribe(); }
	}

	navigateTo(page):void {
		this.onNavigateTo.next(page);
	}

}
