import { Component, AfterContentInit, ContentChildren, ViewChild, QueryList, ElementRef, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { interval, timer } from "rxjs";
import { take } from "rxjs/operators";
import { SliderItemDirective } from "../../directives/slider-item.directive";

@Component({
	selector: "acc-slider",
	templateUrl: "./slider.component.html",
	styleUrls: ["./slider.component.scss"],
})
export class SliderComponent implements AfterContentInit {
	@ContentChildren(SliderItemDirective, { read: ElementRef }) items: QueryList<ElementRef<HTMLDivElement>>;
	@ViewChild("slides", { static: true }) slidesContainer: ElementRef<HTMLDivElement>;
	@Output() onClose: EventEmitter<any> = new EventEmitter();
	@Input() showDots: boolean = true;

	public slidesIndex = 0;
	public loading = false;

	get currentItem(): ElementRef<HTMLDivElement> {
		return this.items.find((item, index) => index === this.slidesIndex);
	}

	@HostListener("window:resize", [])
	private onResize() {
		this.slidesContainer.nativeElement.style.scrollBehavior = 'unset';
		this.slidesContainer.nativeElement.scrollLeft = this.currentItem.nativeElement.offsetWidth * this.slidesIndex;
		setTimeout(() => this.slidesContainer.nativeElement.style.scrollBehavior = 'smooth', 500)
	}

	ngAfterContentInit() {
	}

	ngAfterViewInit() {

	}

	goToSlide(num: number) {
		if(num < this.slidesIndex) {
			const diff = this.slidesIndex - num;
			timer(0,650).pipe(take(diff)).subscribe(() => this.onClickLeft(true))
		}
		if(num > this.slidesIndex) {
			const diff = num - this.slidesIndex;
			timer(0,650).pipe(take(diff)).subscribe(() => this.onClickRight(true))
		}
	}

	onClickLeft(force: boolean = false) {
		if (!this.loading || force) {
			this.loading = true;
			this.slidesContainer.nativeElement.scrollLeft -= this.currentItem.nativeElement.offsetWidth;

			if (this.slidesIndex > 0) {
				this.slidesIndex--;
			}
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		}
	}

	onClickRight(force: boolean = false) {
		if (!this.loading || force) {
			this.loading = true;
			this.slidesContainer.nativeElement.scrollLeft += this.currentItem.nativeElement.offsetWidth;

			if (this.slidesIndex < this.items.length - 1) {
				this.slidesIndex++;
			}
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		}
	}

	close() {
		this.onClose.emit();
	}
}
