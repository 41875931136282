import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'acc-services';
import { TemplateComponent } from '../components/template/template.component';
import { SubscriptionLevelGuard } from 'projects/acc-services/src/lib/guards/level.guard';
import { MktComponent } from '../mkt/mkt.component';
import { ProgramsSetupComponent } from './programs-setup/programs-setup.component';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { MktReportComponent } from './mkt-report/mkt-report.component';
import { CanDeactivateMktSetup } from '../components/guard/can-deactivate-mkt-setup';

const routes: Routes = [
	{
		path: 'mkt',
		component: TemplateComponent,
		children: [{
			path: '',
			component: MktComponent, 
			children: [
				{path: 'setup', component: ProgramsSetupComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], canDeactivate: [CanDeactivateMktSetup], data: { pageTitle: 'Programs Setup', level: 2} },
				{path: 'contacts', component: ContactsListComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Your Contacts', level: 2} },
				{path: 'sent-emails', component: MktReportComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Sent Emails', level: 2, mode: 'Sent'} },
				{path: 'upcoming-emails', component: MktReportComponent, canActivate: [AuthGuard, SubscriptionLevelGuard], data: { pageTitle: 'Upcoming Emails', level: 2, mode: 'Upcoming'} },
			]
		}]
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MktRoutingModule { }
