export class User {
    public userID: string = '';
    public orgID: number;
    public email: string = '';
    public firstName: string = '';
	public lastName: string = '';
	public admin:boolean = false;
	public integrateCRM :boolean = false;
	public crmOAuthRedirectURL :string = '';
	public hasCRMTokenData:boolean = false;
	public token: string = '';
	public shareList: string = '';
	public profileImage: string;
	
	public password: string = '';

	public skipCRM: boolean = false;

	public activeProfileID: string = '';
	public primaryProfileID: string = '';
	public useClassicView: boolean = false;

	public guest: boolean = false;
	
    constructor (o?:any) {
		Object.assign(this,o);
	}

}