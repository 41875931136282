<div mat-dialog-header>
	<h2 mat-dialog-title>Share File</h2>
</div>

<mat-dialog-content>
	<mat-form-field>
		<mat-label>Select a Consultant</mat-label>
		<mat-select [(ngModel)]="consultant">
			<mat-option *ngFor="let user of data.users" [value]="user.userID">
				{{user.firstName}} {{user.lastName}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="share()">Share</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>