<div id="communication-module">
  <!-- HEADER -->
    <div class="header">
      <div class="actions">
        <acc-main-campaign-actions class="action-button" *ngIf="router.url == '/communication/campaigns'"></acc-main-campaign-actions>
        <acc-main-list-actions  class="action-button" *ngIf="router.url == '/communication/lists'"></acc-main-list-actions>
        <acc-main-reporting-actions  class="action-button" *ngIf="router.url == '/communication/reporting'"></acc-main-reporting-actions>
        <acc-main-campaign-form-actions class="action-button" *ngIf="router.url == '/communication/campaigns/campaign-new'"></acc-main-campaign-form-actions>
        <acc-main-campaign-form-actions class="action-button" *ngIf="router.url.startsWith('/communication/campaigns/campaign-edit')"></acc-main-campaign-form-actions>
        <acc-main-list-detail-actions  class="action-button" *ngIf="router.url.startsWith('/communication/lists/list-detail')"></acc-main-list-detail-actions>
        <acc-main-list-import-actions  class="action-button" *ngIf="router.url.startsWith('/communication/lists/list-import')"></acc-main-list-import-actions>
      </div>
    </div>
  <!-- / HEADER -->

	<div class="comm-container">
		<router-outlet></router-outlet>
	</div>
</div>
