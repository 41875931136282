import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'acc-main-loading-error',
  templateUrl: './loading-error.component.html',
  styleUrls: ['./loading-error.component.scss']
})
export class LoadingErrorComponent implements OnInit {

	public message: string;
  constructor() { }

  ngOnInit() {
	  this.message = "There was a problem generating your document.";
  }

}
