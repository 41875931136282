<div mat-dialog-header>
	<h2 mat-dialog-title>Rename Folder</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<mat-form-field>
			<mat-label>New Folder Name</mat-label>
			<input matInput [formControl]="folderNameField" (keyup.enter)="renameFolder()" />
			<mat-error *ngIf="folderNameField.hasError('required')">Folder Name is required</mat-error>
		</mat-form-field>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="renameFolder()" [disabled]="!nameForm.valid">Rename</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>