
		<div class="bulletContent">
			<input type="checkbox" [(ngModel)]="textField.textSelected" appStylizedCheckbox [ngModelOptions]="{standalone:true}" >
			<div class="editorWrapper">
				
				<ng-container *ngIf="settings.htmlEditor">
					<quill-editor [(ngModel)]="textField.text" [ngModelOptions]="{standalone:true}" [style]="{minHeight:'167px'}" #quill></quill-editor>
					<div class="insert-character-list" (click)="insertCharacter($event)">
						<ul>
							<li data-value="174">&reg;</li>
							<li data-value="169">&copy;</li>
							<li data-value="8482">&trade;</li>
							<li data-value="8480">&#8480;</li>
						</ul>
					</div>
				</ng-container>
				<ng-container *ngIf="!settings.htmlEditor">
					<textarea *ngIf="settings.autosizeTextarea" autosize [minRows]="1" [(ngModel)]="textField.text" [ngModelOptions]="{standalone:true}" [attr.maxlength]="settings.charCount ? textField.maxChars : null" [attr.placeholder]="usePlaceholder ? textField.defaultText : null"></textarea>
					<textarea *ngIf="!settings.autosizeTextarea" [(ngModel)]="textField.text" [ngModelOptions]="{standalone:true}" [attr.maxlength]="settings.charCount ? textField.maxChars : null" [attr.placeholder]="usePlaceholder ? textField.defaultText : null"></textarea>
				</ng-container>
				<div class="searchLibrary btn auto orange" (click)="searchLibrary()" *ngIf="settings.library">Search Library</div>
				<div class="pageBreak" *ngIf="settings.pageBreak">
					<input type="checkbox" [(ngModel)]="textField.pageBreak" id="pageBreak_{{textField.textID}}"><label for="pageBreak_{{textField.textID}}">Page Break</label>
				</div>
				<div class="attachments">
					<div class="errorMessage" *ngIf="textField.hasInvalidAttachment == true">Uploads must be .png, .jpg, .jpeg or .pdf only</div>
					<ul *ngIf="textField.attachments?.length > 0 && false" cdkDropList> 
						<li *ngFor="let att of textField.attachments" cdkDrag>
							<div class="attachmentName"><span><a href="{{att.attachmentFilePathFull }}" target="_blank">{{att.attachmentOrigName || att.mediaDesc}}</a></span><i class="fa fa-trash" (click)="deleteAttachment(att.attachmentID, textField, att.type)"></i></div>
							<div class="attachmentLabel">
								<h6>Label</h6>
								<div>
									<input type="text" [(ngModel)]="att.attachmentLabel" #labelField (blur)="saveAttachmentLabel(att)">
									
								</div>
							</div>
							<div *ngIf="att.attachmentFileExt == '.pdf'">
								<h6>Placement</h6>
								<select (change)="changeAttachmentPlacement(att, $event)">
									<option value="2" [selected]="att.placementTypeID == 2">End of Document</option>
									<option value="1" [selected]="att.placementTypeID == 1">After Cover</option>
									<option value="4" [selected]="att.placementTypeID == 4">After Text</option>
									<option value="3" [selected]="att.placementTypeID == 3">Before Text</option>
									<option value="5" [selected]="att.placementTypeID == 5">Appendix</option>
								</select>
							</div>
							<div *ngIf="att.attachmentFileExt != '.pdf'">
								<h6>Placement</h6>
								<select (change)="changeAttachmentPlacement(att, $event)">
									<option value="4" [selected]="att.placementTypeID == 4">After Text</option>
									<option value="3" [selected]="att.placementTypeID == 3">Before Text</option>
								</select>
							</div>
							<div *ngIf="att.attachmentFileExt != '.pdf'">
								<h6>Image Size</h6>
								<select (change)="changeImageSize(att, $event)">
									<option value="1" [selected]="att.imgSizeID == 1">Auto</option>
									<option value="2" [selected]="att.imgSizeID == 2">Small</option>
									<option value="3" [selected]="att.imgSizeID == 3">Medium</option>
									<option value="4" [selected]="att.imgSizeID == 4">Large</option>
									<option value="5" [selected]="att.imgSizeID == 5">Page Width</option>
								</select>
							</div>
						</li>
					</ul>
					<h4  *ngIf="textField.attachments?.length > 0">Attachments</h4>
					<div>
							<mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
								<div class="panelHolder" cdkDrag  *ngFor="let att of textField.attachments">
									
									<mat-expansion-panel class="field-editor-mat-expansion-panel" >
										
										<mat-expansion-panel-header>
											<div class="handle">
												<mat-icon>drag_handle</mat-icon>
											</div>
											<mat-panel-title><a href="{{att.attachmentFilePathFull }}" target="_blank">{{att.attachmentOrigName || att.mediaDesc}}</a></mat-panel-title>
										</mat-expansion-panel-header>
										<div class="attachmentName"><span><a href="{{att.attachmentFilePathFull }}" target="_blank">View</a></span><i class="fa fa-trash" (click)="deleteAttachment(att.attachmentID, textField, att.type)"></i></div>
										<div class="attachmentLabel">
											<h6>Label</h6>
											<div>
												<input type="text" [(ngModel)]="att.attachmentLabel" #labelField (blur)="saveAttachmentLabel(att)">
												
											</div>
											<div *ngIf="att.attachmentFileExt == '.pdf'">
												<h6>Placement</h6>
												<select (change)="changeAttachmentPlacement(att, $event)">
													<option value="2" [selected]="att.placementTypeID == 2">End of Document</option>
													<option value="1" [selected]="att.placementTypeID == 1">After Cover</option>
													<option value="4" [selected]="att.placementTypeID == 4">After Text</option>
													<option value="3" [selected]="att.placementTypeID == 3">Before Text</option>
													<option value="5" [selected]="att.placementTypeID == 5">Appendix</option>
												</select>
											</div>
											<div *ngIf="att.attachmentFileExt != '.pdf'">
												<h6>Placement</h6>
												<select (change)="changeAttachmentPlacement(att, $event)">
													<option value="4" [selected]="att.placementTypeID == 4">After Text</option>
													<option value="3" [selected]="att.placementTypeID == 3">Before Text</option>
												</select>
											</div>
											<div *ngIf="att.attachmentFileExt != '.pdf'">
												<h6>Image Size</h6>
												<select (change)="changeImageSize(att, $event)">
													<option value="1" [selected]="att.imgSizeID == 1">Auto</option>
													<option value="2" [selected]="att.imgSizeID == 2">Small</option>
													<option value="3" [selected]="att.imgSizeID == 3">Medium</option>
													<option value="4" [selected]="att.imgSizeID == 4">Large</option>
													<option value="5" [selected]="att.imgSizeID == 5">Page Width</option>
												</select>
											</div>
										</div>
									<!--</div>-->
									</mat-expansion-panel>
								</div>
							</mat-accordion>
						
					</div>
					<div class="attachmentUpload" *ngIf="settings.addFile">
						<div class="btn icon add border">
							<button mat-button [matMenuTriggerFor]="attMenu">add file</button>
						</div>
						<mat-menu #attMenu="matMenu">
							<button mat-menu-item class="uploadButton">upload from file<input type="file" (change)="onFileChange($event)" /></button>
							<button mat-menu-item (click)="showSelectFile(textField);">upload from library</button>
						</mat-menu>
					</div>
				</div>
				
			</div>
			<div class="charCount" *ngIf="settings.charCount">
				{{textField.text.length}} / {{textField.maxChars}}
			</div>

		</div>

		<!--
		<library-search *ngIf="showLibrarySearch" (appendLibraryText)="closeSearch($event, 'append')" (replaceWithLibraryText)="closeSearch($event, 'replace')" (closeForm)="closeSearch($event, 'close')"></library-search>
		<file-selector *ngIf="showFileSelector" (closeForm)="doCloseForm($event)" [title]="'Select File'" [textId]="textField.textID" [docId]="documentId"></file-selector>
		-->