<div class="container">
	
	<div class="top" *ngIf="modules">
		<h2>Edit Category Sort Order</h2>
		<select [(ngModel)]="moduleId" (change)="getCategories()">
			<option value="0">Select Module to Edit</option>
			<option *ngFor="let mod of modules" value="{{mod.moduleID}}">{{mod.moduleDesc}}</option>
		</select>
	</div>
	<div class="bottom" *ngIf="categories">
		<p>Drag and drop the categories to re-order the list.  Click on a category name to rearrange that category's subcategories.</p>
		<div class="categoryHolder">
			<ul cdkDropList (cdkDropListDropped)="dropCategory($event)">
				<li *ngFor="let cat of categories" cdkDrag [ngClass]="{active: cat.categoryID == categoryId}" (click)="getSubcategories(cat)">{{cat.categoryDesc}} </li>
			</ul>
		</div>
		<div class="categoryHolder" *ngIf="subcategories">
			<ul cdkDropList (cdkDropListDropped)="dropSubCategory($event)">
				<li *ngFor="let sub of subcategories" cdkDrag >{{sub.subCategoryDesc}} </li>
			</ul>
		</div>
	</div>
</div>