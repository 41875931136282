export class LibraryText {
	public textLibID: number;
	public textLibTitle: string;
	public textLibDesc: string;
	public textLibCategoryID: number;
	public textLibSearchWords: string;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export class LibraryTextCategory {
	public textLibCategoryID: number;
	public textLibCategoryDesc: string;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}

export class LibraryTextSearchCriteria {
	public textLibCategoryId: number;
	public searchString: string;

	constructor(o?:any) {
		Object.assign(this,o);
	}
}