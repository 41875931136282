<div class="profile">

		<!-- HEADER -->
		<header class="profile-header">
	
			<div *ngIf="profile" class="user-info" >
				<!--<div *ngIf="!profile.photo" class="profile-image avatar huge" [style.backgroundImage]="'url(' + profile.photoURLPath + ')'"></div>-->
				<div *ngIf="!!profile.photo" class="profile-image avatar huge" [style.backgroundImage]="'url(' + profile.photo.imageModifiedURLPath + ')'"></div>
				<div class="name">
				   {{profile.firstName + " " + profile.lastName}}
				</div>
			</div>

			<nav class="classic-view-nav" *ngIf="selectedClassicView">
				<ul>
					<!--<li><button (click)="expireToken()">Expire Token</button></li>-->
					<li><a [routerLink]="['/quote']" (click)="newQuote()">New Life Quote</a></li>
					<li><a [routerLink]="['/quote', 'my-quotes']">My Life Quotes</a></li>
				</ul>
			</nav>

			<div class="actions" *ngIf="integrateCRM" >
				<button mat-raised-button color="accent" aria-label="Follow" *ngIf="crmLoggedIn" (click)="unlinkCrm()">Unlink from CRM</button>		
				<button mat-raised-button color="accent"  aria-label="Follow" *ngIf="!crmLoggedIn" (click)="linkCrm()">Link to CRM</button>	
			</div>

		</header>
		<!-- / HEADER -->

	<mat-card >
		<mat-card-header>
		</mat-card-header>
		<mat-card-content>
			<mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChanged($event)" [selectedIndex]="profileSelectedIndex" #tabs>
				<mat-tab *ngFor="let tab of profileTabs" [label]="tab.name">
					<acc-main-profile-form [profile]="tab.profile" #profileForm></acc-main-profile-form>
				</mat-tab>
				<mat-tab *ngIf="profileTabs.length > 0" >
					<ng-template mat-tab-label>
						<mat-icon class="add-tab-icon new-profile-icon">add_circle_outline</mat-icon>
						Add New Profile
					  </ng-template>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
		<mat-card-actions class="page-actions">
			<div class="profile-button-row" *ngIf="profile">
				<button  (click)="saveProfile()"  mat-flat-button color="primary" class="profile-edit-button">
					<span class="mat-button-wrapper">Save</span>
					<div class="mat-button-ripple mat-ripple" matripple=""></div>
					<div class="mat-button-focus-overlay"></div>
				</button>

				<button  (click)="close()" mat-stroked-button color="primary" class="profile-edit-close-button">
					<span class="mat-button-wrapper">Close</span>
					<div class="mat-button-ripple mat-ripple" matripple=""></div>
					<div class="mat-button-focus-overlay"></div>
				</button>

				<button mat-raised-button *ngIf="profile && profile.profileID && profile.profileID != user.primaryProfileID" class="profile-delete-button" (click)="deleteProfile()">
					Delete Profile
				</button>

				<div class="bottom-message">
					<div class="errorMessage" *ngIf="false">
						Please complete all required fields.
					</div>
				</div>
			</div>
		</mat-card-actions>
	</mat-card>
		




