import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { tooltips } from "acc-common";

@Component({
	selector: "acc-main-copy-quote-button",
	templateUrl: "./copy-quote-button.component.html",
	styleUrls: ["./copy-quote-button.component.scss"],
})
export class CopyQuoteButtonComponent implements OnInit {
	@Input() quoteId: string;
	@Input() quoteName: string;
	@Input() disabled: boolean = false;
	@Output() onCopyQuote: EventEmitter<{ quoteId: string; name: string }> = new EventEmitter();
	@ViewChild('copyMenuTrigger', { static: true }) copyMenuTrigger: MatMenuTrigger;
	@ViewChild('nameInput', { static: true }) nameInput: ElementRef

	public copyQuoteName: string = "";
	public tooltips = tooltips;
	constructor() {}

	ngOnInit() {
		this.copyQuoteName = this.quoteName;
	}

	copyQuote() {
		this.onCopyQuote.next({ quoteId: this.quoteId, name: this.copyQuoteName});
		this.copyMenuTrigger.closeMenu();
	}

	selectText() {
		this.nameInput.nativeElement.select();
		this.nameInput.nativeElement.focus();
	}
}
