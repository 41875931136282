import { Component, OnInit } from "@angular/core";

@Component({
	selector: "acc-main-videos",
	templateUrl: "./videos.component.html",
	styleUrls: ["./videos.component.scss"]
})
export class VideosComponent implements OnInit {
	public title: string = "Video Program";
	constructor() {}

	ngOnInit() {}
	setTitle(title) {
		this.title = title;
	}
}
