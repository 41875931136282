import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { SessionService } from "acc-services";
import { selectDaysLeft } from "../../../store/selectors/status.selectors";
import { IAppState } from "../../../store/state/app.state";

@Component({
	selector: "acc-main-trial-feature-popup",
	templateUrl: "./trial-feature-popup.component.html",
	styleUrls: ["./trial-feature-popup.component.scss"],
})
export class TrialFeaturePopupComponent implements OnInit, OnChanges {
	public featureNumber: number;
	
	public daysLeft: number = 30;
	public activeVideoUrl: string = "/assets/videos/acceleratemarketing.mp4";
	constructor(private store: Store<IAppState>, private sessionService: SessionService) {}

	ngOnInit() {
		this.store.pipe(select(selectDaysLeft)).subscribe(resp => {
			this.daysLeft = resp;
		})
		this.featureNumber = Math.floor(Math.random() * 5) + 1;
		this.sessionService.setItem('featureShown', "true");
	}
	ngOnChanges(changes: SimpleChanges) {
		if(changes.featureNumber) {
			this.featureNumber = changes.featureNumber.currentValue;
		}
		if(changes.daysLeft) {
			this.daysLeft = changes.daysLeft.currentValue;
		}
	}
}
