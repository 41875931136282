import { Component, OnInit } from "@angular/core";
import { tooltips } from "acc-common";

@Component({
	selector: "acc-main-features-table",
	templateUrl: "./features-table.component.html",
	styleUrls: ["./features-table.component.scss"],
})
export class FeaturesTableComponent implements OnInit {
	public tooltips = tooltips;
	constructor() {}

	ngOnInit() {}
}
