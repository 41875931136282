import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { LibraryText, LibraryTextCategory, LibraryTextSearchCriteria, Button } from 'acc-models';
import { SessionService } from 'acc-services';
import { IAppState } from '../../store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as TextLibActions from '../../store/actions/textlib.actions';
import * as TextLibSelectors from '../../store/selectors/textlib.selectors';
import { tap, take, takeUntil } from 'rxjs/operators';
import { SetOrgId } from '../../store/actions/users.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditTextlibComponent } from './edit-textlib/edit-textlib.component';
import { ConfirmComponent } from 'acc-common';
import { Subject } from 'rxjs';
import { RootReset } from '../../store/actions/root.actions';

@Component({
  templateUrl: './admin-text-library.component.html',
  styleUrls: ['./admin-text-library.component.scss']
})
export class AdminTextLibraryComponent implements OnInit, OnDestroy {

	private destroy$:Subject<number> = new Subject();

	public textLibCategories$ = this.store$.pipe(
		select(TextLibSelectors.selectCategories),
		tap(r => this.categories = r),
		takeUntil(this.destroy$)
	).subscribe();
	public searchResults$ = this.store$.pipe(
		select(TextLibSelectors.selectSearchResults),
		tap(r => {
			if(r && r.length == 0) {
				Promise.resolve().then(() => { this.snackBar.open('Your search returned no results.', 'OK', { duration: 3000 }) }); //hack to get around change detection error
			}
		})
	)
	public itemToEdit$ = this.store$.pipe(
		select(TextLibSelectors.selectItemToEdit),
		takeUntil(this.destroy$)
	).subscribe();
	public itemToDelete$ = this.store$.pipe(
		select(TextLibSelectors.selectItemToDelete),
		tap(m => { if(m) { this.confirmDeleteItemMessage = `Are you sure you want to delete <b>${m.textLibTitle}?` } })
	)

	public criteria: LibraryTextSearchCriteria = new LibraryTextSearchCriteria({ searchString: '', textLibCategoryId: -1 });
	public categories: Array<LibraryTextCategory>;
	public confirmDeleteButtons: Array<Button> = new Array<Button>();
	public confirmDeleteItemMessage: string = '';
	private editDialogRef: MatDialogRef<EditTextlibComponent>;

	constructor(private store$:Store<IAppState>, private sessionService:SessionService, private dialog: MatDialog, private snackBar: MatSnackBar) {
		this.confirmDeleteButtons.push(new Button("Yes", ""));
		this.confirmDeleteButtons.push(new Button("No", ""));

	}

	ngOnInit():void {
		const user = this.sessionService.getUser();
		this.store$.dispatch(new RootReset());
		this.store$.dispatch(new SetOrgId(user.orgID))
		this.store$.dispatch(new TextLibActions.GetCategories());
	}
	ngOnDestroy():void {
		this.destroy$.next(0);
		this.destroy$.complete();
		this.store$.dispatch(new TextLibActions.Reset());
	}
	search() {
		this.store$.dispatch(new TextLibActions.Search(this.criteria));
	}
	clearSearch() {
		this.store$.dispatch(new TextLibActions.ClearSearch());
	}
	edit(item:LibraryText) {
		this.store$.dispatch(new TextLibActions.EditItem(item));
		this.editDialogRef = this.dialog.open(EditTextlibComponent, {
			width: '45vw',
			minWidth: 500
		});

	}
	addNew() {
		this.store$.dispatch(new TextLibActions.EditItem(new LibraryText({textLibID: -1, textLibCategoryID: 0})))
		this.editDialogRef = this.dialog.open(EditTextlibComponent, {
			width: '45vw',
			minWidth: 500
		});
	}
	delete(item: LibraryText) {
		this.store$.dispatch(new TextLibActions.DeleteItem(item));
		const deleteDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: 'Confirm Delete',
				message: `Are you sure you want to delete ${item.textLibDesc}`
			}
		});
		deleteDialogRef.afterClosed().pipe(take(1)).subscribe(confirm => {
			if(confirm) {
				this.store$.dispatch(new TextLibActions.DeleteItemConfirm());
			} else {
				this.store$.dispatch(new TextLibActions.DeleteItemCancel());
			}
		})
	}


}
