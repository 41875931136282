<acc-main-seats-dialog *ngIf="addSeatsDialog$ | async"></acc-main-seats-dialog> 
<div class="container">
		<div id="profile">
			<ng-container *ngIf="profile">

				
				<mat-card >
					<mat-card-header>
					</mat-card-header>
					<mat-card-content>

						<div class="columns">

							<acc-main-profile-form #profileForm style="width: 100%;" [cssClass]="'admin'" [profile]="profile" [controls]="[{ name: 'password', ctl: passwordField }]">
								<mat-form-field col1 class="password-field">
									<mat-label>Password</mat-label>
									<input matInput [formControl]="passwordField" type="password" class="profile-input-password" placeholder="Min 8 char. One upper, lower, number, special char." />
									<mat-hint>Password must be at least 8 characters long, contain upper and lower case characters, contain a number and at least one special character !@#$%^&*?_~-£().,</mat-hint>
									<mat-error *ngIf="passwordField.hasError('required')">Password is required</mat-error>
								</mat-form-field>
									<mat-checkbox col2 [(ngModel)]="isAdmin" color="primary">Is Admin  <mat-icon class="u-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_users_isAdmin}}" >info_outline</mat-icon></mat-checkbox>
							</acc-main-profile-form>
			
						</div>
						
						<div class="errorMessage" *ngIf="!formValid">
							Please complete all required fields.
						</div>

					</mat-card-content>
					<mat-card-actions class="page-actions">
						<div class="bottom">
							<button  (click)="saveProfile()" mat-flat-button color="primary" class="profile-edit-button">
								<span class="mat-button-wrapper">Save</span>
								<div class="mat-button-ripple mat-ripple" matripple=""></div>
								<div class="mat-button-focus-overlay"></div>
							</button>
			
							<button  (click)="returnToList()" mat-stroked-button color="primary" class="profile-edit-close-button">
								<span class="mat-button-wrapper">Close</span>
								<div class="mat-button-ripple mat-ripple" matripple=""></div>
								<div class="mat-button-focus-overlay"></div>
							</button>
						</div>
					</mat-card-actions>
				</mat-card>
		</ng-container>
			
		</div>
		
