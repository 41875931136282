import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'acc-main-your-family',
  templateUrl: './your-family.component.html',
  styleUrls: ['./your-family.component.scss']
})
export class YourFamilyComponent implements OnInit, OnDestroy {

	public videoId: string;
	public videoUrl: string;
	public destroy$: Subject<number> = new Subject();

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
	this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
		this.videoId = params.get("videoId");
		this.videoUrl = "https://renderqueuev2.consumeroptix.com/api/queue/returnvideo/" + this.videoId;
	});
  }

  getStarted() {
	this.router.navigateByUrl('/dtc/96b495af-65c2-49f3-b4ed-918bcc1eae39');
  }

  ngOnDestroy(): void {
	  this.destroy$.next(0);
	  this.destroy$.complete();
  }

}
