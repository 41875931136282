
<ng-container *ngIf="users$ | async; else loading">
<button mat-flat-button color="accent" (click)="addNew()" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_users_users}}">Add New</button>
<div class="usersContainer">
	<mat-card *ngFor="let user of users$ | async" class="userRow">
		<h2>{{user.firstName}} {{user.lastName}}</h2>
		<h3 class="profile-list-email">{{user.email}}</h3>
		<div class="actions">
			<button mat-flat-button color="primary" *ngIf="user.userID != loggedInUser.userID" (click)="editUser(user)">Edit</button>
			<button mat-stroked-button (click)="deleteUser(user)" *ngIf="user.userID != loggedInUser.userID" [ngClass]="{disabled: user.userID == loggedInUser.userID}">Delete</button>
		</div>
	</mat-card>
</div>
</ng-container>

<ng-template #loading>
	<div class="loading">
		<div class="one"></div>
		<div class="two"></div>
		<div class="three"></div>
	</div>
</ng-template>



