<ng-container [formGroup]="drivingViolations" [ngSwitch]="selectedSection.section">
  <div class="driving-violations__container animated fadeIn" *ngSwitchCase="'DUI / DWI'" [formGroupName]="'duiDwi'">

    <div class="driving-violations__driving-conviction multiple-radio-btns animated fadeIn">
      <mat-label>Did client have a DUI or DWI in the past 5 years?</mat-label>
      <mat-radio-group [formControlName]="'drivingConvictionExists'">
        <mat-radio-button [value]=true aria-label="Have DUI or DWI in the past"
          (change)="onDrivingConvictionChange(true)">Yes</mat-radio-button>
        <mat-radio-button [value]=false aria-label="Never Have DUI or DWI in the past"
          (change)="onDrivingConvictionChange(false)">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="driving-violations__how-many"
      *ngIf="haveDuiOrDwi || drivingViolations.get('duiDwi').get('drivingConvictionExists').value == 'true'">
      <mat-label>How many DUI/DWI?</mat-label>
      <mat-select [formControlName]="'howMany'">
        <mat-option></mat-option>
        <mat-option [value]="number" *ngFor="let number of availableInputs">{{number}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('duiDwi').get('howMany').value">
      <mat-label>Date of DUI/DWI #1</mat-label>
      <input matInput type="date" [formControlName]="'drivingDrunkDrivingDates1'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #drunkDrivingDate1
      (blur)="onViolationDateChange(drunkDrivingDate1.value, 'duiDwi', 'drivingDrunkDrivingDates1')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('duiDwi').get('howMany').value > 1">
      <mat-label>Date of DUI/DWI #2</mat-label>
      <input matInput type="date" [formControlName]="'drivingDrunkDrivingDates2'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #drunkDrivingDate2
      (blur)="onViolationDateChange(drunkDrivingDate2.value, 'duiDwi', 'drivingDrunkDrivingDates2')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('duiDwi').get('howMany').value > 2">
      <mat-label>Date of DUI/DWI #3</mat-label>
      <input matInput type="date" [formControlName]="'drivingDrunkDrivingDates3'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #drunkDrivingDate3
      (blur)="onViolationDateChange(drunkDrivingDate3.value, 'duiDwi', 'drivingDrunkDrivingDates3')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('duiDwi').get('howMany').value > 3 ">
      <mat-label>Date of DUI/DWI #4</mat-label>
      <input matInput type="date" [formControlName]="'drivingDrunkDrivingDates4'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #drunkDrivingDate4
      (blur)="onViolationDateChange(drunkDrivingDate4.value, 'duiDwi', 'drivingDrunkDrivingDates4')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('duiDwi').get('howMany').value == 5">
      <mat-label>Date of DUI/DWI #5</mat-label>
      <input matInput type="date" [formControlName]="'drivingDrunkDrivingDates5'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #drunkDrivingDate5
      (blur)="onViolationDateChange(drunkDrivingDate5.value, 'duiDwi', 'drivingDrunkDrivingDates5')"/>
    </mat-form-field>
  </div>

  <div class="driving-violations__container animated fadeIn" *ngSwitchCase="'License Suspension'"
    [formGroupName]="'licenseSuspension'">
    <div class="driving-violations__driving-conviction multiple-radio-btns animated fadeIn">
      <mat-label>Did client have a License Suspension in the past 5 years?</mat-label>
      <mat-radio-group formControlName="drivingLicenseCurrentlyRevoked">
        <mat-radio-button [value]=true (change)="onDrivingLicenseRevokedChange(true)">Yes</mat-radio-button>
        <mat-radio-button [value]=false (change)="onDrivingLicenseRevokedChange(false)">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="driving-violations__how-many"
      *ngIf="isDrivingLicenseRevoke || drivingViolations.get('licenseSuspension').get('drivingLicenseCurrentlyRevoked').value == 'true'">
      <mat-label>How many License Suspensions?</mat-label>
      <mat-select formControlName="howMany">
        <mat-option></mat-option>
        <mat-option [value]="number" *ngFor="let number of availableInputs">{{number}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('licenseSuspension').get('howMany').value">
      <mat-label>License Suspension #1</mat-label>
      <input matInput type="date" [formControlName]="'drivingLicenseSuspensionDates1'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #licenseSuspensionDate1
      (blur)="onViolationDateChange(licenseSuspensionDate1.value, 'licenseSuspension', 'drivingLicenseSuspensionDates1')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('licenseSuspension').get('howMany').value > 1">
      <mat-label>License Suspension #2</mat-label>
      <input matInput type="date" [formControlName]="'drivingLicenseSuspensionDates2'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #licenseSuspensionDate2
      (blur)="onViolationDateChange(licenseSuspensionDate2.value, 'licenseSuspension', 'drivingLicenseSuspensionDates2')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('licenseSuspension').get('howMany').value > 2">
      <mat-label>License Suspension #3</mat-label>
      <input matInput type="date" [formControlName]="'drivingLicenseSuspensionDates3'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #licenseSuspensionDate3
      (blur)="onViolationDateChange(licenseSuspensionDate3.value, 'licenseSuspension', 'drivingLicenseSuspensionDates3')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('licenseSuspension').get('howMany').value > 3 ">
      <mat-label>License Suspension #4</mat-label>
      <input matInput type="date" [formControlName]="'drivingLicenseSuspensionDates4'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #licenseSuspensionDate4
      (blur)="onViolationDateChange(licenseSuspensionDate4.value, 'licenseSuspension', 'drivingLicenseSuspensionDates4')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('licenseSuspension').get('howMany').value == 5">
      <mat-label>License Suspension #5</mat-label>
      <input matInput type="date" [formControlName]="'drivingLicenseSuspensionDates5'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #licenseSuspensionDate5
      (blur)="onViolationDateChange(licenseSuspensionDate5.value, 'licenseSuspension', 'drivingLicenseSuspensionDates5')"/>
    </mat-form-field>
  </div>

  <div class="driving-violations__container animated fadeIn" *ngSwitchCase="'Motor Vehicle Accident'"
    [formGroupName]="'motorVehicleAccident'">
    <div class="driving-violations__driving-conviction multiple-radio-btns animated fadeIn">
      <mat-label>Did client have a Motor Vehicle Accident in the past 5 years?</mat-label>
      <mat-radio-group formControlName="motorVehicleAccidentExists">
        <mat-radio-button [value]=true (change)="onMotorVehicleAccidentExistsChange(true)">Yes</mat-radio-button>
        <mat-radio-button [value]=false (change)="onMotorVehicleAccidentExistsChange(false)">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field appearance="outline" class="driving-violations__how-many"
      *ngIf="haveMotorVehicleAccident || drivingViolations.get('motorVehicleAccident').get('motorVehicleAccidentExists').value == 'true'">
      <mat-label>How many Motor Vehicle Accidents?</mat-label>
      <mat-select formControlName="howMany">
        <mat-option></mat-option>
        <mat-option [value]="number" *ngFor="let number of availableInputs">{{number}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('motorVehicleAccident').get('howMany').value">
      <mat-label>Date of Accident #1</mat-label>
      <input matInput type="date" [formControlName]="'drivingMotorVehicleAccidentDates1'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #motorVehicleAccidentDate1
      (blur)="onViolationDateChange(motorVehicleAccidentDate1.value, 'motorVehicleAccident', 'drivingMotorVehicleAccidentDates1')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('motorVehicleAccident').get('howMany').value > 1">
      <mat-label>Date of Accident #2</mat-label>
      <input matInput type="date" [formControlName]="'drivingMotorVehicleAccidentDates2'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #motorVehicleAccidentDate2
      (blur)="onViolationDateChange(motorVehicleAccidentDate2.value, 'motorVehicleAccident', 'drivingMotorVehicleAccidentDates2')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('motorVehicleAccident').get('howMany').value > 2">
      <mat-label>Date of Accident #3</mat-label>
      <input matInput type="date" [formControlName]="'drivingMotorVehicleAccidentDates3'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #motorVehicleAccidentDate3
      (blur)="onViolationDateChange(motorVehicleAccidentDate3.value, 'motorVehicleAccident', 'drivingMotorVehicleAccidentDates3')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('motorVehicleAccident').get('howMany').value > 3 ">
      <mat-label>Date of Accident #4</mat-label>
      <input matInput type="date" [formControlName]="'drivingMotorVehicleAccidentDates4'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #motorVehicleAccidentDate4
      (blur)="onViolationDateChange(motorVehicleAccidentDate4.value, 'motorVehicleAccident', 'drivingMotorVehicleAccidentDates4')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('motorVehicleAccident').get('howMany').value == 5">
      <mat-label>Date of Accident #5</mat-label>
      <input matInput type="date" [formControlName]="'drivingMotorVehicleAccidentDates5'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #motorVehicleAccidentDate5
      (blur)="onViolationDateChange(motorVehicleAccidentDate5.value, 'motorVehicleAccident', 'drivingMotorVehicleAccidentDates5')"/>
    </mat-form-field>
  </div>

  <div class="moving-violations__container animated fadeIn" *ngSwitchCase="'Moving Violation'"
    [formGroupName]="'movingViolation'">
    <div class="driving-violations__driving-conviction multiple-radio-btns animated fadeIn">
      <mat-label>Did client have a Moving Violation in the past 5 years?</mat-label>
      <mat-radio-group formControlName="movingViolationExists">
        <mat-radio-button [value]=true (change)="onMovingViolationExistsChange(true)">Yes</mat-radio-button>
        <mat-radio-button [value]=false (change)="onMovingViolationExistsChange(false)">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="driving-violations__how-many"
      *ngIf="haveMovingValidation || drivingViolations.get('movingViolation').get('movingViolationExists').value == 'true'">
      <mat-label>How many Moving Violations?</mat-label>
      <mat-select formControlName="howMany">
        <mat-option></mat-option>
        <mat-option [value]="number" *ngFor="let number of availableInputs">{{number}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('movingViolation').get('howMany').value">
      <mat-label>Date of Moving Violation #1</mat-label>
      <input matInput type="date" [formControlName]="'drivingMovingViolationDates1'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #movingViolationDate1
      (blur)="onViolationDateChange(movingViolationDate1.value, 'movingViolation', 'drivingMovingViolationDates1')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('movingViolation').get('howMany').value > 1">
      <mat-label>Date of Moving Violation #2</mat-label>
      <input matInput type="date" [formControlName]="'drivingMovingViolationDates2'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #movingViolationDate2
      (blur)="onViolationDateChange(movingViolationDate2.value, 'movingViolation', 'drivingMovingViolationDates2')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('movingViolation').get('howMany').value > 2">
      <mat-label>Date of Moving Violation #3</mat-label>
      <input matInput type="date" [formControlName]="'drivingMovingViolationDates3'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #movingViolationDate3
      (blur)="onViolationDateChange(movingViolationDate3.value, 'movingViolation', 'drivingMovingViolationDates3')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('movingViolation').get('howMany').value > 3 ">
      <mat-label>Date of Moving Violation #4</mat-label>
      <input matInput type="date" [formControlName]="'drivingMovingViolationDates4'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #movingViolationDate4
      (blur)="onViolationDateChange(movingViolationDate4.value, 'movingViolation', 'drivingMovingViolationDates4')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('movingViolation').get('howMany').value == 5">
      <mat-label>Date of Moving Violation #5</mat-label>
      <input matInput type="date" [formControlName]="'drivingMovingViolationDates5'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #movingViolationDate5
      (blur)="onViolationDateChange(movingViolationDate5.value, 'movingViolation', 'drivingMovingViolationDates5')"/>
    </mat-form-field>
  </div>

  <div class="reckless-driving__container animated fadeIn" *ngSwitchCase="'Reckless Driving'"
    [formGroupName]="'recklessDriving'">
    <div class="driving-violations__driving-conviction multiple-radio-btns animated fadeIn">
      <mat-label>Did client have a Reckless Driving Charge in the past 5 years?</mat-label>
      <mat-radio-group formControlName="recklessDrivingViolationExists">
        <mat-radio-button [value]=true (change)="onRecklessDrivingViolationExistsChange(true)">Yes</mat-radio-button>
        <mat-radio-button [value]=false (change)="onRecklessDrivingViolationExistsChange(false)">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field appearance="outline" class="driving-violations__how-many"
      *ngIf="haveRecklessDrivingViolation || drivingViolations.get('recklessDriving').get('recklessDrivingViolationExists').value == 'true'">
      <mat-label>How many Reckless Driving Charges?</mat-label>
      <mat-select formControlName="howMany">
        <mat-option></mat-option>
        <mat-option [value]="number" *ngFor="let number of availableInputs">{{number}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('recklessDriving').get('howMany').value">
      <mat-label>Date of Reckless Driving Charge #1</mat-label>
      <input matInput type="date" [formControlName]="'drivingRecklessDrivingDates1'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #recklessDate1
      (blur)="onViolationDateChange(recklessDate1.value, 'recklessDriving', 'drivingRecklessDrivingDates1')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('recklessDriving').get('howMany').value > 1">
      <mat-label>Date of Reckless Driving Charge #2</mat-label>
      <input matInput type="date" [formControlName]="'drivingRecklessDrivingDates2'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #recklessDate2
      (blur)="onViolationDateChange(recklessDate2.value, 'recklessDriving', 'drivingRecklessDrivingDates2')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('recklessDriving').get('howMany').value > 2">
      <mat-label>Date of Reckless Driving Charge #3</mat-label>
      <input matInput type="date" [formControlName]="'drivingRecklessDrivingDates3'" [max]="todaysDate" 
      [min]="fiveYearsAgoDate" #recklessDate3
      (blur)="onViolationDateChange(recklessDate3.value, 'recklessDriving', 'drivingRecklessDrivingDates3')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('recklessDriving').get('howMany').value > 3 ">
      <mat-label>Date of Reckless Driving Charge #4</mat-label>
      <input matInput type="date" [formControlName]="'drivingRecklessDrivingDates4'" [max]="todaysDate"
      [min]="fiveYearsAgoDate" #recklessDate4
      (blur)="onViolationDateChange(recklessDate4.value, 'recklessDriving', 'drivingRecklessDrivingDates4')"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="driving-violations__date animated fadeIn"
      *ngIf="drivingViolations.get('recklessDriving').get('howMany').value == 5">
      <mat-label>Date of Reckless Driving Charge #5</mat-label>
      <input matInput type="date" [formControlName]="'drivingRecklessDrivingDates5'" #recklessDate5 [max]="todaysDate"
        [min]="fiveYearsAgoDate"
        (blur)="onViolationDateChange(recklessDate5.value, 'recklessDriving', 'drivingRecklessDrivingDates5')" />
    </mat-form-field>
  </div>
</ng-container>