import { Component, OnInit } from '@angular/core';
import { MixpanelService, SessionService } from 'acc-services';
import { Router } from '@angular/router';

@Component({
	template: '&nbsp;'
})
export class LogoutComponent implements OnInit {
    public username: string = 'bckipp16@gmail.com';
    public password: string = "aaaaa";
	public org: any;
	
    constructor (private router:Router, private sessionService: SessionService, private mixpanelService: MixpanelService) {
    }
    
    
    ngOnInit():void {
		this.org = this.sessionService.getItem('organization');
		this.sessionService.removeItem('sawWelcomeMessage');
		this.sessionService.removeItem('cartId');
		this.sessionService.removeItem('currentUser');
		this.sessionService.removeItem('featureShown');

		//Unsubscribe from Mixpanel
		this.mixpanelService.unsubscribeTrack();
		this.sessionService.loginAsOriginalInformation = null;
		this.sessionService.carriersProductsFilters = null;
		this.sessionService.iPipelineCredentials = null;

		if(this.org) {
			this.sessionService.removeItem('organization');
			this.router.navigateByUrl('/'+this.org.resource);
		} else {
			this.router.navigateByUrl('/');
		}
		
	}
	
}
