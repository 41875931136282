<div class="container adminContainer">
	<h1>Templates <mat-icon style="font-size: 20px;" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_quote_template}}" >info_outline</mat-icon></h1>
	<ul>
		<li class="item" *ngFor="let template of templates" >
			<div class="description">
				<div class="non-edit-version" *ngIf="templateToEdit?.templateID != template.templateID">
					<span>{{template.templateDesc}}</span>
					<p>{{template.templateDetail}}</p>
				</div>
				<div class="edit-version"  *ngIf="templateToEdit?.templateID == template.templateID">
					<input type="text" [(ngModel)]="template.templateDesc"  (keyup.enter)="renameTemplate(template)">
					<textarea [(ngModel)]="template.templateDetail" placeholder="Detailed Description"></textarea>
				</div>
				
				
			</div>
			<div class="actions" *ngIf="!template.readOnly">
				<span class="renameTemplate" (click)="selectTemplateToEdit(template)" *ngIf="templateToEdit?.templateID != template.templateID" title="Rename Template"><i class="fa fa-edit"></i></span>
				<span class="renameTemplate" (click)="renameTemplate(template)" *ngIf="templateToEdit?.templateID == template.templateID" title="Save Changes"><i class="fa fa-save"></i></span>
				<span class="editTemplate" (click)="editTemplate(template)" title="Edit Template"><i class="fa fa-external-link-alt"></i></span>
				<span class="deleteTemplate" (click)="deleteTemplate(template)"><i class="fa fa-times"></i></span>
			</div>
		</li>
		<li class="add-new-row">
			<div class="description">
				<mat-form-field>
					<input matInput [(ngModel)]="newDocName" required>
					<mat-label>New Template Name</mat-label>
				</mat-form-field>
				<div class="error" *ngIf="nameMissing && newDocName == ''">This field is required</div>
			</div>
			<div class="add-new-action">
				<button mat-raised-button color="primary" (click)="addNew()">Add New Template</button>
			</div>
		</li>
	</ul>
	
</div>