import { Component, EventEmitter, OnInit, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { SessionService } from 'acc-services';
import { LockFeaturesComponent } from '../../lock-features/lock-features.component';
import { tooltips } from 'acc-common';


@Component({
  selector: 'acc-main-send-proposal-button',
  templateUrl: './send-proposal-button.component.html',
  styleUrls: ['./send-proposal-button.component.scss'],
})
export class SendProposalButtonComponent implements OnInit {
	@ViewChild(MatMenuTrigger, { static: true }) menuTrigger: MatMenuTrigger;
	@Input() disabled: boolean
	@Output() sendProposal: EventEmitter<boolean> = new EventEmitter();

	public tooltips = tooltips

  constructor(public sessionService: SessionService, private dialog: MatDialog) { }

  ngOnInit() {
  }

  onClick(setOptions: boolean) {
	  this.sendProposal.next(setOptions);
  }

  validateSubscription(action) {
	if (this.sessionService.canUseSubscriptionLevelFeature(action)) {
		if(this.menuTrigger != null){
			this.menuTrigger.openMenu();
		}
	} else {
		this.dialog.open(LockFeaturesComponent, {
			panelClass: "lock-features",
		});
		this.menuTrigger.closeMenu()
	}
}

}
