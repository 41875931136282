import { Component, OnDestroy, OnInit } from "@angular/core";
import { DataService, MixpanelService, QuoteService, SessionService } from "acc-services";
import { Organization, User } from "acc-models";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { IAppState } from "../../../store/state/app.state";
import { select, Store } from "@ngrx/store";

@Component({
	selector: "acc-main-log-in-as",
	templateUrl: "./log-in-as.component.html",
	styleUrls: ["./log-in-as.component.scss"],
})
export class LogInAsComponent implements OnInit {
	public allUsers: Array<User>;
	public user: User;
	public org: Organization;
	public showDropdown:boolean = false;
	public selectedOrganization;
	public organizations: Array<Organization>;

	public originalUserID: any = null;
	public originalOrgID: any = null;

	constructor(private sessionService: SessionService, private dataService: DataService, private router: Router, 
		public dialogRef: MatDialogRef<LogInAsComponent>, private quoteService: QuoteService, private mixpanelService: MixpanelService) {}

	ngOnInit(): void {
		
		this.user = this.sessionService.getUser();
		this.org = this.sessionService.getItem('organization');

		var originalUserInformation = this.sessionService.loginAsOriginalInformation;
		if(originalUserInformation != null){
			this.originalOrgID = originalUserInformation.orgID;
			this.originalUserID = originalUserInformation.userID;
		}

		if(this.originalOrgID == null || this.originalUserID == null){
			this.showDropdown = this.org.isMasterOrganization;

			if(this.showDropdown){
				
				this.dataService.getChildrenOrganizations(this.org.orgID).subscribe((resp: any) => {
					this.organizations = resp.sort((a,b) => (a.orgDesc.toLowerCase() > b.orgDesc.toLowerCase()) ? 1 : ((b.orgDesc.toLowerCase() > a.orgDesc.toLowerCase()) ? -1 : 0));
					this.selectedOrganization = this.org.orgID;
		
					this.getUsersForSelectedOrganization();
	
				});
			}else{
				this.dataService.getOrgUsers(this.user.orgID, "00000000-0000-0000-0000-000000000000").subscribe((resp: any) => {
					this.allUsers = resp.sort((a, b) => {
						if (a.lastName > b.lastName) return 1;
						if (a.lastName < b.lastName) return -1;
						return 0;
					});
				});
			}
		}else{
			this.allUsers = [];
		}
	}

	close(): void {
		this.dialogRef.close();
	}

	selectUser(usr) {
		var currentToken = this.user.token;
		this.dialogRef.close();

		//Disable Mixpanel
		this.mixpanelService.disable();

		if(this.showDropdown){
			this.sessionService.loginAsOriginalInformation = {
				userID: this.user.userID,
				orgID: this.org.orgID
			}

			this.changeOrganization(this.selectedOrganization).then(()=>{
				this.loginAsUser(usr.userID, currentToken);
			});
			
		}else{
			this.loginAsUser(usr.userID, currentToken);
		}
	}

	redirectTo(uri:string){
		this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
		this.router.navigate([uri]));
	 }

	onSelectedOrganizationChange(){
		this.getUsersForSelectedOrganization();
	}

	getUsersForSelectedOrganization(){
		this.dataService.getOrgUsers(this.selectedOrganization, "00000000-0000-0000-0000-000000000000").subscribe((resp: any) => {
			this.allUsers = resp.sort((a, b) => {
				if (a.lastName > b.lastName) return 1;
				if (a.lastName < b.lastName) return -1;
				return 0;
			});
		});
	}

	backToOriginalUser(){
		var currentToken = this.user.token;
		this.dialogRef.close();

		this.changeOrganization(this.originalOrgID).then(()=>{
			
			//Disable Mixpanel
			this.mixpanelService.enable();

			this.loginAsUser(this.originalUserID, currentToken);

			//Clean the Original User
			this.sessionService.loginAsOriginalInformation = null;
		});
	}

	loginAsUser(userID, currentToken){
		this.dataService.getUser(userID).subscribe((u) => {
			var usr = new User(u);
			usr.token = currentToken;
			this.sessionService.setUser({ ...usr, admin: true });

			//Load Quote Configuration.
			this.quoteService.loadQuoteOrgConfiguration();

			if (this.router.url.indexOf("/welcome") > -1) {
				//Added Refresh user profiles when log in as is selected.
				this.dataService.refreshUsersProfiles(usr.userID);
				if (usr.integrateCRM && !usr.hasCRMTokenData) {
					this.sessionService.salesforceLogin().subscribe((result) => {
						if (result != "login") {
							this.sessionService.setUser({ ...usr, skipCRM: true });
						}
					});
				}
				this.redirectTo("/welcome");
			} else {
				this.router.navigateByUrl("/welcome");
			}

		});
	}

	changeOrganization(orgID){
		const promise = new Promise((resolve, reject) => {
			this.dataService.GetOrgFromID(orgID).subscribe((org) => {
				//CHANGE ORG
				this.sessionService.removeItem('organization');
				if(org.orgID) {
					this.sessionService.setItem('organization', org);
				}
				resolve(null);
			});
		});
		return promise;
	}
}
