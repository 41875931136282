
import { Component, OnInit, Input, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import { SetErrorMessage } from "../../store/actions/status.actions";

@Component({
	selector: "app-campaign-test",
	templateUrl: "./campaign-test.component.html",
	styleUrls: ["./campaign-test.component.css"]
})
export class CampaignTestComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<CampaignTestComponent>,
		private store: Store<IAppState>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {}

	close() {
		this.dialogRef.close({
			email: this.data.email,
			phone: this.data.phone,
			sendText: false
		});
	}

	executeTest() {
		if (!this.data.email || !this.data.phone) {
			this.store.dispatch(
				new SetErrorMessage("Email and phone are required.")
			);
		} else {
			this.dialogRef.close({
				email: this.data.email,
				phone: this.data.phone,
				sendTest: true
			});
		}
	}
}
