<div class="">
	<header>
		<h2>Edit Document Outline</h2>
		<div *ngIf="(categories$ | async)?.length > 0">
			<button mat-flat-button color="accent" (click)="return()">Return</button>
		</div>
	</header>
	<div class="top container" *ngIf="documentOutline$ | async">
		<div *ngIf="(module$ | async)?.moduleTypeID != 1">
			<mat-form-field appearance="outline">
				<mat-label>Outline Depth</mat-label>
				<mat-select [value]="docOutline.outlineDepth" (selectionChange)="updateOutline('outlineDepth', $event)" [compareWith]="compareValues" #outlineDepthSelect>
					<mat-option value="3">3 Levels</mat-option>
					<mat-option value="2">2 Levels</mat-option>
					<mat-option value="1">1 Level</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="container">
		<div class="grid">
			<mat-card *ngIf="currentDepth > 2">
				<mat-card-title>Category Description</mat-card-title>
				<mat-card-content>
					<div class="col">
						<div>
							<div class="header row">
								<div class="description"></div>
								<div class="settings" *ngIf="(module$ | async)?.moduleTypeID != 1">
									<div class="outlineType" *ngIf="listTypes$ | async">
										<mat-form-field appearance="outline">
											<mat-label>List Type</mat-label>
											<mat-select [(value)]="docOutline.categoryListTypeID" (selectionChange)="updateOutline('categoryListTypeID', $event)"  [compareWith]="compareValues">
												<mat-option *ngFor="let type of listTypes" value="{{type.listTypeID}}">{{type.listTypeDescLong}}</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="pageBreak">
										<input type="checkbox" [checked]="docOutline.categoryDivider" (change)="updatePageBreak('categoryDivider', $event)" id="catPageBreak"  [disabled]="docOutline.subCategoryDivider" [attr.title]="docOutline.subCategoryDivider ? 'This must be selected if Subcategory Page Divider is selected' : null"/><label for="catPageBreak">Page Divider</label>
									</div>
								</div>
							</div>
							<ol *ngIf="categories$ | async" class="row listType{{docOutline.categoryListTypeID}}" cdkDropList (cdkDropListDropped)="dropCategory($event)">
								<li class="item" *ngFor="let cat of categories" cdkDrag [cdkDragDisabled]="editingCategory?.categoryID == cat.categoryID" (click)="activateCategory(cat)" [ngClass]="{active: cat.categoryID === activeCategory?.categoryID}">
									<div class="description">
										<span *ngIf="editingCategory?.categoryID != cat.categoryID">{{cat.categoryDesc}}</span>
										<input type="text" [(ngModel)]="cat.categoryDesc" *ngIf="editingCategory?.categoryID == cat.categoryID">
									</div>
									<div class="actions">
										<span class="editCategory" (click)="selectCategoryToEdit(cat)" *ngIf="editingCategory?.categoryID != cat.categoryID"><i class="fa fa-edit"></i></span>
										<span class="editCategory" (click)="saveCategoryEdit(cat)" *ngIf="editingCategory?.categoryID == cat.categoryID"><i class="fa fa-save"></i></span>
										<span class="deleteCategory" (click)="deleteCategory(cat)"><i class="fa fa-times"></i></span>
									</div>
								</li>
								<li class="item">
									<div class="description">
										<input type="text" [(ngModel)]="newCategoryName" (keyup)="checkEnter($event, 'cat')" />
									</div>
									<div class="actions">
										<span class="addCategory" (click)="addCategory()"><i class="fa fa-save"></i></span>
									</div>
								</li>
							</ol>
						</div>
					</div>
				</mat-card-content>
		</mat-card>

			<mat-card>
				<mat-card-title>{{currentDepth > 2 ? 'Subcategory' : ''}} Description</mat-card-title>
				<mat-card-content>
					<div class="col">
						<div *ngIf="currentDepth > 1">
						<div class="header row">
							<div class="description"></div>
							<div class="settings" *ngIf="(module$ | async)?.moduleTypeID != 1">
								<div class="outlineType" *ngIf="listTypes$ | async">
									<mat-form-field appearance="outline">
										<mat-label>List Type</mat-label>
										<mat-select [(value)]="docOutline.subCategoryListTypeID" (selectionChange)="updateOutline('subCategoryListTypeID', $event)" [compareWith]="compareValues">
											<mat-option *ngFor="let type of listTypes" value="{{type.listTypeID}}" >{{type.listTypeDescLong}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="pageBreak">
									<input type="checkbox" [checked]="docOutline.subCategoryDivider" (change)="updatePageBreak('subCategoryDivider', $event)" id="subcatPageBreak" /><label for="subcatPageBreak">Page Divider</label>
								</div>
							</div>
						</div>
						<ol *ngIf="subcategories$ | async" class="row listType{{docOutline.subCategoryListTypeID}}" cdkDropList (cdkDropListDropped)="dropSubcategory($event)">
							<li class="item" *ngFor="let sub of subcategories" cdkDrag [cdkDragDisabled]="editingSubcategory?.subCategoryID == sub.subCategoryID" (click)="activateSubcategory(sub)" [ngClass]="{active: sub.subCategoryID === activeSubcategory?.subCategoryID}">
								<div class="description">
									<span *ngIf="editingSubcategory?.subCategoryID != sub.subCategoryID">{{sub.subCategoryDesc}}</span>
									<input type="text" [(ngModel)]="sub.subCategoryDesc" *ngIf="editingSubcategory?.subCategoryID == sub.subCategoryID">
								</div>
								<div class="actions">
									<span class="editCategory" (click)="selectSubcategoryToEdit(sub)" *ngIf="editingSubcategory?.subCategoryID != sub.subCategoryID"><i class="fa fa-edit"></i></span>
									<span class="editCategory" (click)="saveSubcategoryEdit(sub)" *ngIf="editingSubcategory?.subCategoryID == sub.subCategoryID"><i class="fa fa-save"></i></span>
									<span class="deleteCategory" (click)="deleteSubcategory(sub)"><i class="fa fa-times"></i></span>
								</div>
							</li>
							<li class="item">
								<div class="description">
									<input type="text" [(ngModel)]="newSubcategoryName" (keyup)="checkEnter($event, 'subcat')" />
								</div>
								<div class="actions">
									<span class="addCategory" (click)="addSubcategory()"><i class="fa fa-save"></i></span>
								</div>
							</li>
						</ol>
						</div>
					</div>
				</mat-card-content>
		</mat-card>

			<mat-card>
				<mat-card-title>Text Fields</mat-card-title>
				<mat-card-content>
					<div class="col">
						<div class="header row">
							<div class="settings" *ngIf="(module$ | async)?.moduleTypeID != 1">
								<div class="outlineType" *ngIf="listTypes$ | async">
									<mat-form-field appearance="outline">
										<mat-label>List Type</mat-label>
										<mat-select [(value)]="docOutline.subCatTextListTypeID" (selectionChange)="updateOutline('subCatTextListTypeID', $event)" [compareWith]="compareValues">
											<mat-option *ngFor="let type of listTypes" value="{{type.listTypeID}}">{{type.listTypeDescLong}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
						</div>
						<ol *ngIf="texts$ | async" class="row listType{{docOutline.subCatTextListTypeID}}" cdkDropList (cdkDropListDropped)="dropText($event)">
							<li class="item text" *ngFor="let text of texts" cdkDrag [ngClass]="{editing: text.textID === editingTextCopy?.textID}">
								<div class="description">
									<span *ngIf="editingTextCopy?.textID != text.textID" class="textPreview">{{text.defaultText}}</span>
									<textarea [(ngModel)]="editingTextCopy.defaultText" *ngIf="editingTextCopy?.textID == text.textID"></textarea>
								</div>
								<div class="actions">
									<span class="editCategory" (click)="selectTextToEdit(text)" *ngIf="editingTextCopy?.textID != text.textID"><i class="fa fa-edit"></i></span>
									<span class="editCategory" (click)="saveTextEdit(text)" *ngIf="editingTextCopy?.textID == text.textID"><i class="fa fa-save"></i></span>
									<span class="deleteCategory" (click)="deleteText(text)"><i class="fa fa-times"></i></span>
								</div>
							</li>
							<li class="item text editing">
								<div class="description">
									<textarea [(ngModel)]="newTextCopy" ></textarea>
								</div>
								<div class="actions">
									<span class="addCategory" (click)="addSubcatText()"><i class="fa fa-save"></i></span>
								</div>
							</li>
						</ol>
					</div>
				</mat-card-content>
		</mat-card>
		</div>
	</div>
</div>
