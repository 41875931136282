import { Action } from "@ngrx/store";
import { User, Profile } from "acc-models";

export enum UserActionTypes {
	SET_ORGID = "[USER] - Set Org Id",
	DELETE_USER = "[USER] - Delete",
	SELECT_USER = "[USER] - Select",
	EDIT_USER = "[USER] - Edit",
	ADD_USER = "[USER] - Add",
	SAVE_USER = "[USER] - Save",
	SAVE_USER_SUCCESS = "[USER] - Save Success",
	SAVE_PROGRESS = "[USER] - Save Progress",
	SAVE_PROGRESS_SUCCESS = "[USER] - Save Progress Success",
	SAVE_PHOTO_SUCCESS = "[USER] - Save Profile Photo Success",
	GET_USERS = "[USER] - Get",
	GET_USERS_SUCCESS = "[USER] - Get Success",
	GET_PROFILE = "[USER] - Get Profile",
	GET_PROFILE_SUCCESS = "[USER] - Get Profile Success",
	UPDATE_PROFILE = "[USER] - Update Profile",
	UPDATE_PROFILE_SUCCESS = "[USER] - Update Profile Success",
	RETURN_TO_LIST = "[USER] - Return to List",
	DELETE_USER_CANCEL = "[USER] - Cancel delete",
	DELETE_USER_CONFIRM = "[USER] - Confirm delete",
	DELETE_USER_SUCCESS = "[USER] - Delete Success",
	ADD_SEATS = "[USER] - Add Seats",
	HIDE_ADD_SEATS = "[USER] - Hide Add Seats",

	RESET = "[ROOT] Reset",
}

export class SetOrgId implements Action {
	public readonly type = UserActionTypes.SET_ORGID;
	constructor(public orgId: number) {}
}
export class SelectUser implements Action {
	public readonly type = UserActionTypes.SELECT_USER;
	constructor(public user: User) {}
}

export class DeleteUser implements Action {
	public readonly type = UserActionTypes.DELETE_USER;
	constructor(public user: User) {}
}

export class DeleteUserCancel implements Action {
	public readonly type = UserActionTypes.DELETE_USER_CANCEL;
	constructor() {}
}

export class DeleteUserConfirm implements Action {
	public readonly type = UserActionTypes.DELETE_USER_CONFIRM;
	constructor() {}
}

export class DeleteUserSuccess implements Action {
	public readonly type = UserActionTypes.DELETE_USER_SUCCESS;
	constructor(public userId: string) {}
}

export class AddUser implements Action {
	public readonly type = UserActionTypes.ADD_USER;
	constructor() {}
}

export class EditUser implements Action {
	public readonly type = UserActionTypes.EDIT_USER;
	constructor(public user: User) {}
}

export class SaveUser implements Action {
	public readonly type = UserActionTypes.SAVE_USER;
	constructor(public user: User) {}
}

export class SaveUserSuccess implements Action {
	public readonly type = UserActionTypes.SAVE_USER_SUCCESS;
	constructor(public user: User) {}
}
export class SaveProgress implements Action {
	public readonly type = UserActionTypes.SAVE_PROGRESS;
	constructor(public user: User, public profile: Profile, public profilePhotoToUpload: any) {}
}

export class SaveProgressSuccess implements Action {
	public readonly type = UserActionTypes.SAVE_PROGRESS_SUCCESS;
	constructor(public user: User) {}
}
export class SavePhotoSuccess implements Action {
	public readonly type = UserActionTypes.SAVE_PHOTO_SUCCESS;
	constructor(public fileName: string, public fileNameOnly: string) {}
}
export class GetUsers implements Action {
	public readonly type = UserActionTypes.GET_USERS;
	constructor(public orgId: number) {}
}

export class GetUsersSuccess implements Action {
	public readonly type = UserActionTypes.GET_USERS_SUCCESS;
	constructor(public users: Array<User>) {}
}

export class GetProfile implements Action {
	public readonly type = UserActionTypes.GET_PROFILE;
	constructor(public userId: string) {}
}

export class GetProfileSuccess implements Action {
	public readonly type = UserActionTypes.GET_PROFILE_SUCCESS;
	constructor(public profile: Profile) {}
}

export class UpdateProfile implements Action {
	public readonly type = UserActionTypes.UPDATE_PROFILE;
	constructor(public user: User, public profile: Profile) {}
}

export class UpdateProfileSuccess implements Action {
	public readonly type = UserActionTypes.UPDATE_PROFILE_SUCCESS;
	constructor(public user: User) {}
}

export class ReturnToList implements Action {
	public readonly type = UserActionTypes.RETURN_TO_LIST;
	constructor() {}
}
export class UserReset implements Action {
	public readonly type = UserActionTypes.RESET;
	constructor() {}
}

export class ShowAddSeats implements Action {
	public readonly type = UserActionTypes.ADD_SEATS;
	constructor() {}
}

export class HideAddSeats implements Action {
	public readonly type = UserActionTypes.HIDE_ADD_SEATS;
	constructor() {}
}

export type UserActions = SetOrgId | SelectUser | DeleteUser | DeleteUserCancel | DeleteUserConfirm | DeleteUserSuccess | EditUser | AddUser | SaveUser | SaveUserSuccess | SaveProgress | SaveProgressSuccess | SavePhotoSuccess | GetUsers | GetUsersSuccess | GetProfile | GetProfileSuccess | UpdateProfile | UpdateProfileSuccess | ReturnToList | UserReset | ShowAddSeats | HideAddSeats;
