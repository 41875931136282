import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideosComponent } from './videos/videos.component';
import { VideoComponent } from './video/video.component';
import { CreateVideoComponent } from './create-video/create-video.component';
import { CustVideoParmsComponent } from './cust-video-parms/cust-video-parms.component';
import { AccCommonModule } from 'acc-common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { VideosRoutingModule } from './videos-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatVideoModule } from 'mat-video';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import { EmailFormComponent } from './email-form/email-form.component';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import '../../icons';

@NgModule({
  declarations: [VideosComponent, VideoComponent, CreateVideoComponent, CustVideoParmsComponent, EmailFormComponent],
  imports: [
	CommonModule,
	AccCommonModule,
	FormsModule,
	ReactiveFormsModule,
	VideosRoutingModule,

	MatCardModule,
	MatOptionModule,
	MatSelectModule,
	MatGridListModule,
	MatVideoModule,
	MatCheckboxModule,
	MatProgressBarModule,
	MatInputModule,
	MatButtonModule,
	MatTooltipModule,
	MatFormFieldModule,
	MatIconModule,

	HttpClientModule,       // (Required) For share counts
    HttpClientJsonpModule,  // (Optional) Add if you want tumblr share counts
	ShareModule,
    ShareButtonsModule,

	QuillModule.forRoot({
		modules: {
			toolbar: {
				container: [
				  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
				  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
				  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
				  ['clean'],                                         // remove formatting button
				  ['link'],
				],
				handlers: {'emoji': noop}
			  },
		}
	})
  ],
  entryComponents: [EmailFormComponent]
})
export class VideosModule { }
export function noop() {}
