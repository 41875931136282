import { initialSignUpState, ISignUpState } from "../state/signup.state";
import { SignUpActionTypes, SignUpActions } from "../actions/signup.actions";

export function signUpReducer(state = initialSignUpState, action: SignUpActions): ISignUpState {
	switch (action.type) {
		case SignUpActionTypes.SAVE_SIGN_UP_DATA:
			return { ...state, userData: action.userData };
		case SignUpActionTypes.GET_SIGN_UP_DATA:
			return { ...state };
		default:
			return state;
	}
}
