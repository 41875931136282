
<div mat-dialog-header>
	<h2 mat-dialog-title>Preview List</h2>
</div>

<mat-dialog-content>
	<p>Below is a sample of the first 100 entries in the list {{data.listName}}.</p>

	<div class="list-container">
		<div class="row header">
			<div class="name">Name</div>
			<div class="email">Email</div>
			<div class="phone">Phone</div>
		</div>
		<div class="row" *ngFor="let item of list">
			<div class="name">{{item.firstName}} {{item.lastName}}</div>
			<div class="email">{{item.email}}</div>
			<div class="phone">{{item.phone | phone}}</div>
		</div>	
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button color="primary" (click)="close()">Close</button>
</mat-dialog-actions>