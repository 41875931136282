import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminTemplateComponent } from "./admin-template/admin-template.component";
import { AdminTextLibraryComponent } from "./admin-text-library/admin-text-library.component";
import { CategoriesComponent } from "./categories/categories.component";
import { MediaComponent } from "./media/media.component";
import { UsersComponent } from "./users/users.component";
import { WelcomeMessageComponent } from "./welcome-message/welcome-message.component";
import { EditTextlibComponent } from "./admin-text-library/edit-textlib/edit-textlib.component";
import { EditMediaFormComponent } from "./media/edit-media-form/edit-media-form.component";
import { MediaFolderDisplayComponent } from "./media/media-folder-display/media-folder-display.component";
import { UserDetailsComponent } from "./users/user-details/user-details.component";
import { UserListComponent } from "./users/user-list/user-list.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AccCommonModule } from "acc-common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgDragDropModule } from "ng-drag-drop";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { AdminRoutingModule } from "./admin-routing.module";
import { StoreModule } from "@ngrx/store";
import { appReducers } from "../store/reducers/app.reducers";
import { SharedModule } from "../shared/shared.module";
import { MatProgressButtonsModule } from "mat-progress-buttons";
import { RevisionDatesComponent } from "./revision-dates/revision-dates.component";
import { TextCardComponent } from "./revision-dates/text-card/text-card.component";
import { AccModelsModule } from "acc-models";
import { MediaCardComponent } from "./revision-dates/media-card/media-card.component";
import { TemplatesComponent } from "./templates/templates.component";
import { CompanyComponent } from "./company/company.component";
import { SubscriptionComponent } from "./subscription/subscription.component";
import { QuoteAlertsComponent } from './quote-alerts/quote-alerts.component';
import { ThresholdFormComponent } from './threshold-form/threshold-form.component';
import { CanDeactivateAdminQuoteAlert } from '../components/guard/can-deactivate-admin-quote-alert';
import { DocbuilderAdminComponent } from './docbuilder/docbuilder-admin/docbuilder-admin.component';
import { CarrierProductsFilterComponent } from './carrier-products-filter/carrier-products-filter.component';
@NgModule({
	declarations: [AdminTemplateComponent, AdminTextLibraryComponent, CategoriesComponent, MediaComponent, UsersComponent, WelcomeMessageComponent, EditTextlibComponent, EditMediaFormComponent, MediaFolderDisplayComponent, UserDetailsComponent, UserListComponent, RevisionDatesComponent, TextCardComponent, MediaCardComponent, TemplatesComponent, CompanyComponent, SubscriptionComponent, QuoteAlertsComponent, ThresholdFormComponent, DocbuilderAdminComponent, CarrierProductsFilterComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AdminRoutingModule,
		SharedModule,

		AccCommonModule,
		AccModelsModule,
		//StoreModule.forFeature('admin', appReducers),

		DragDropModule,
		NgDragDropModule,

		MatInputModule,
		MatSelectModule,
		MatProgressButtonsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatCardModule,
		MatDialogModule,
		MatDatepickerModule,
		MatTabsModule,
		MatExpansionModule,
		MatProgressBarModule,
		MatRadioModule,
		MatTooltipModule,
		MatIconModule,
		MatTreeModule,
		MatProgressSpinnerModule,
		MatSlideToggleModule,
	],
	entryComponents: [EditMediaFormComponent, EditTextlibComponent],
	providers: [
		CanDeactivateAdminQuoteAlert,
	]
})
export class AdminModule {}
