import { createSelector } from "@ngrx/store";
import { IAppState } from "../state/app.state";
import { IDtcState } from "../state/dtc.state";


const selectState =  (state:IAppState) => state.dtc;

export const selectBroker = createSelector(selectState, (state: IDtcState) => state.broker);

export const selectApplication = createSelector(selectState, (state: IDtcState) => state.application);

export const selectServiceEnabled = createSelector(selectState, (state: IDtcState) => state.serviceEnabled);

export const selectProgress = createSelector(selectState, (state: IDtcState) => state.progress);

export const selectResults = createSelector(selectState, (state: IDtcState) => state.results);




