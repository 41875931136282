<div class="param-field" [ngClass]="{'textarea-param-field': f.dataType === 'String' && f.maxTextLines != null}" *ngFor="let f of params;let i = index">

    <mat-form-field *ngIf="f.dataType === 'String' && f.maxTextLines === null" appearance="outline">
        <mat-label>{{f.parmDesc}}</mat-label>
        <input matInput [(ngModel)]="f.parmValue" type="text" required ngModel (change)="paramValueChanged($event)"/>
    </mat-form-field>

    <mat-form-field *ngIf="f.dataType === 'String' && f.maxTextLines != null" appearance="outline">
        <mat-label>{{f.parmDesc}}</mat-label>
        <textarea matInput [(ngModel)]="f.parmValue" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="{{f.maxTextLines}}"
        cdkAutosizeMaxRows="{{f.maxTextLines}}"
        (keypress)="keyPress($event, f.maxTextLines)"
        rows="{{f.maxTextLines}}"
        maxlength="{{f.maxTextLines * maxChars}}"
        (change)="paramValueChanged($event)"
        required ngModel></textarea>
    </mat-form-field>

    <mat-form-field *ngIf="f.dataType === 'Number'" appearance="outline">
        <mat-label>{{f.parmDesc}}</mat-label>
        <input matInput [(ngModel)]="f.parmValue" type="number" required ngModel (change)="paramValueChanged($event)" />
    </mat-form-field>

    <mat-checkbox *ngIf="f.dataType === 'Boolean'" [(ngModel)]="f.parmValue" (change)="paramValueChanged($event)">{{f.parmDesc}}</mat-checkbox>

    <section class="file-section" *ngIf="f.dataType === 'File'">
        <h4 class="filter-type-title">{{f.parmDesc}}</h4>
        <div class="fileUploadButton">
            <div class="btn dark btn-upload-file"><i class="fa fa-upload"></i><span>Upload File</span></div>
            <input type="file" #fileInput (change)="onFileChange($event, f.parmName, i)" required ngModel (change)="paramValueChanged($event)" accept="{{imageFileTypes.join(',')}}" >
			<div class="fileToUploadName" *ngIf="fileMessage[i]">{{fileMessage[i]}}</div>
			<div *ngIf="invalidFileType">File must be an image</div>
        </div>
    </section>

</div>