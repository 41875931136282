<button mat-raised-button class="send-proposal-button" #menuTrigger="matMenuTrigger" (click)="validateSubscription('quoteSendProposal')" [matMenuTriggerFor]="sendMenu" color="primary" [disabled]="disabled" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{ tooltips.vq_send_proposal }}">
	Send Quote <mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature('quoteSendProposal')">lock</mat-icon>
</button>
<mat-menu #sendMenu="matMenu" [overlapTrigger]="false">
	<div class="include-proposal-question">
		<p>Send quote only or send enhanced quote?</p>
		<ul>
			<li><div class="send-proposal-option-button" (click)="onClick(true)">Enhanced Quote</div></li>
			<li><div class="send-proposal-option-button" (click)="onClick(false)">Quote Only</div></li>
		</ul>
	</div>
</mat-menu>
