import { Action } from '@ngrx/store';
import { Category, SubCategory, Text, DocumentOutline, ListType, Module } from 'acc-models';

export enum CatManageActionTypes {
	GET_MODULE = '[CATMANAGE] [MODULE] Get',
	GET_MODULE_SUCCESS = '[CATMANAGE] [MODULE] Get Success',
	GET_CATEGORIES = '[CATMANAGE] [CATEGORY] Get',
	GET_CATEGORIES_SUCCESS  = '[CATMANAGE] [CATEGORY] Get Success',

	GET_DOCUMENT_OUTLINE = '[CATMANAGE] [OUTLINE] Get Outline',
	GET_DOCUMENT_OUTLINE_SUCCESS = '[CATMANAGE] [OUTLINE] Get Outline Success',
	GET_LIST_TYPES = '[CATMANAGE] [OUTLINE] Get List Types',
	GET_LIST_TYPES_SUCCESS = '[CATMANAGE] [OUTLINE] Get List Types Success',
	SET_OUTLINE_DEPTH = '[CATMANAGE] [OUTLINE] Set Depth',
	SET_OUTLINE_DEPTH_SUCCESS = '[CATMANAGE]  [OUTLINE] Set Depth Success',
	UPDATE_OUTLINE = '[CATMANAGE]  [OUTLINE] Update ',
	UPDATE_OUTLINE_SUCCESS = '[CATMANAGE]  [OUTLINE] Update Success',

	ACTIVATE_CATEGORY = '[CATMANAGE] [CATEGORY] Activate',
	ADD_CATEGORY = '[CATMANAGE] [CATEGORY] Add',
	ADD_CATEGORY_SUCCESS = '[CATMANAGE] [CATEGORY] Add Success',
	EDIT_CATEGORY = '[CATMANAGE] [CATEGORY] Edit',
	EDIT_CATEGORY_SAVE = '[CATMANAGE] [CATEGORY] Edit Save',
	EDIT_CATEGORY_CANCEL = '[CATMANAGE] [CATEGORY] Edit Cancel',
	EDIT_CATEGORY_SUCCESS = '[CATMANAGE] [CATEGORY] Edit Success',
	DELETE_CATEGORY = '[CATMANAGE] [CATEGORY] Delete',
	DELETE_CATEGORY_CONFIRM = '[CATMANAGE] [CATEGORY] Delete Confirm',
	DELETE_CATEGORY_CANCEL = '[CATMANAGE] [CATEGORY] Delete Cancel',
	DELETE_CATEGORY_SUCCESS = '[CATMANAGE] [CATEGORY] Delete Success',
	SAVE_CATEGORIES = '[CATMANAGE] [CATEGORY] Save All',
	SAVE_CATEGORIES_SUCCESS = '[CATMANAGE] [CATEGORY] Save All Success',
	
	ACTIVATE_SUBCATEGORY = '[CATMANAGE] [SUBCATEGORY] Activate',
	ADD_SUBCATEGORY = '[CATMANAGE] [SUBCATEGORY] Add',
	ADD_SUBCATEGORY_SUCCESS = '[CATMANAGE] [SUBCATEGORY] Add Success',
	EDIT_SUBCATEGORY = '[CATMANAGE] [SUBCATEGORY] Edit',
	EDIT_SUBCATEGORY_SAVE = '[CATMANAGE] [SUBCATEGORY] Edit Save',
	EDIT_SUBCATEGORY_CANCEL = '[CATMANAGE] [SUBCATEGORY] Edit Cancel',
	EDIT_SUBCATEGORY_SUCCESS = '[CATMANAGE] [SUBCATEGORY] Edit Success',
	DELETE_SUBCATEGORY = '[CATMANAGE] [SUBCATEGORY] Delete',
	DELETE_SUBCATEGORY_CONFIRM = '[CATMANAGE] [SUBCATEGORY] Delete Confirm',
	DELETE_SUBCATEGORY_CANCEL = '[CATMANAGE] [SUBCATEGORY] Delete Cancel',
	DELETE_SUBCATEGORY_SUCCESS = '[CATMANAGE] [SUBCATEGORY] Delete Success',
	SAVE_SUBCATEGORIES = '[CATMANAGE] [SUBCATEGORY] Save All',
	SAVE_SUBCATEGORIES_SUCCESS = '[CATMANAGE] [SUBCATEGORY] Save All Success',

	GET_SUBCAT_TEXTS = '[CATMANAGE] [TEXTS] Get',
	GET_SUBCAT_TEXTS_SUCCESS = '[CATMANAGE] [TEXTS] Get Success',
	ACTIVATE_SUBCAT_TEXT = '[CATMANAGE] [TEXTS] Activate',
	ADD_SUBCAT_TEXT = '[CATMANAGE] [TEXTS] Add Text',
	ADD_SUBCAT_TEXT_SUCCESS = '[CATMANAGE] [TEXTS] Add Text Success',
	EDIT_SUBCAT_TEXT = '[CATMANAGE] [TEXTS] Edit Text',
	EDIT_SUBCAT_TEXT_SUCCESS = '[CATMANAGE] [TEXTS] Edit Text Success',
	EDIT_SUBCAT_TEXT_CANCEL = '[CATMANAGE] [TEXTS] Edit Text Cancel',
	EDIT_SUBCAT_TEXT_CONFIRM = '[CATMANAGE] [TEXTS] Edit Text Confirm',
	DELETE_SUBCAT_TEXT = '[CATMANAGE] [TEXTS] Delete Text',
	DELETE_SUBCAT_TEXT_CONFIRM = '[CATMANAGE] [TEXTS] Delete Text Confirm',
	DELETE_SUBCAT_TEXT_CANCEL = '[CATMANAGE] [TEXTS] Delete Text Cancel',
	DELETE_SUBCAT_TEXT_SUCCESS = '[CATMANAGE] [TEXTS] Delete Text Success',
	UPDATE_SUBCAT_TEXTS = '[CATMANAGE] [TEXTS] Save All Texts',
	UPDATE_SUBCAT_TEXTS_SUCCESS = '[CATMANAGE] [TEXTS] Save All Texts Success',

	RESET = '[ROOT] Reset'

}

export class GetModule implements Action {
	public readonly type = CatManageActionTypes.GET_MODULE;
	constructor(public moduleId: number) {}
}
export class GetModuleSuccess implements Action {
	public readonly type = CatManageActionTypes.GET_MODULE_SUCCESS;
	constructor(public module: Module) {}
}
export class GetCategories implements Action {
	public readonly type = CatManageActionTypes.GET_CATEGORIES;
	constructor(public moduleId: number, public userId:string, public docId: string) {}
}
export class GetCategoriesSuccess implements Action {
	public readonly type = CatManageActionTypes.GET_CATEGORIES_SUCCESS;
	constructor(public categories: Array<Category>) {}
}
export class GetDocumentOutline implements Action {
	public readonly type = CatManageActionTypes.GET_DOCUMENT_OUTLINE;
	constructor(public docId: string) {}
}
export class GetDocumentOutlineSuccess implements Action {
	public readonly type = CatManageActionTypes.GET_DOCUMENT_OUTLINE_SUCCESS;
	constructor(public documentOutline: DocumentOutline) {} 
}
export class GetListTypes implements Action {
	public readonly type = CatManageActionTypes.GET_LIST_TYPES;
	constructor () {}
}
export class GetListTypesSuccess implements Action {
	public readonly type = CatManageActionTypes.GET_LIST_TYPES_SUCCESS;
	constructor(public listTypes: Array<ListType>){}
}
export class SetOutlineDepth implements Action {
	public readonly type = CatManageActionTypes.SET_OUTLINE_DEPTH;
	constructor(public depth: number) {}
}
export class SetOutlineDepthSuccess implements Action {
	public readonly type = CatManageActionTypes.SET_OUTLINE_DEPTH_SUCCESS;
	constructor (public depth: number) {}
}
export class UpdateOutline implements Action {
	public readonly type = CatManageActionTypes.UPDATE_OUTLINE;
	constructor(public documentOutline: DocumentOutline) {}
}
export class UpdateOutlineSuccess implements Action {
	public readonly type = CatManageActionTypes.UPDATE_OUTLINE_SUCCESS;
	constructor (public documentOutline: DocumentOutline) {}
}
export class ActivateCategory implements Action {
	public readonly type = CatManageActionTypes.ACTIVATE_CATEGORY;
	constructor(public category:Category) {}
}
export class EditCategory implements Action {
	public readonly type = CatManageActionTypes.EDIT_CATEGORY;
	constructor(public category:Category) {}
}
export class EditCategorySave implements Action {
	public readonly type = CatManageActionTypes.EDIT_CATEGORY_SAVE;
	constructor(public category: Category) {}
}
export class EditCategoryCancel implements Action {
	public readonly type = CatManageActionTypes.EDIT_CATEGORY_CANCEL;
	constructor() {}
}
export class EditCategorySuccess implements Action {
	public readonly type = CatManageActionTypes.EDIT_CATEGORY_SUCCESS;
	constructor() {}
}
export class DeleteCategory implements Action {
	public readonly type = CatManageActionTypes.DELETE_CATEGORY;
	constructor(public category:Category) {}
}
export class DeleteCategoryConfirm implements Action {
	public readonly type = CatManageActionTypes.DELETE_CATEGORY_CONFIRM;
	constructor() {}
}
export class DeleteCategoryCancel implements Action {
	public readonly type = CatManageActionTypes.DELETE_CATEGORY_CANCEL;
	constructor() {}
}
export class DeleteCategorySuccess implements Action {
	public readonly type = CatManageActionTypes.DELETE_CATEGORY_SUCCESS;
	constructor() {}
}
export class AddCategory implements Action {
	public readonly type = CatManageActionTypes.ADD_CATEGORY;
	constructor(public moduleId: number, public category: Category) {}
}
export class AddCategorySuccess implements Action {
	public readonly type = CatManageActionTypes.ADD_CATEGORY_SUCCESS;
	constructor(public category:Category) {}
}
export class SaveCategories implements Action {
	public readonly type = CatManageActionTypes.SAVE_CATEGORIES;
	constructor(public categories:Array<any>) {}
}
export class SaveCategoriesSuccess implements Action {
	public readonly type = CatManageActionTypes.SAVE_CATEGORIES_SUCCESS;
	constructor() {}
}


export class ActivateSubcategory implements Action {
	public readonly type = CatManageActionTypes.ACTIVATE_SUBCATEGORY;
	constructor(public subcategory:SubCategory) {}
}
export class AddSubcategory implements Action {
	public readonly type = CatManageActionTypes.ADD_SUBCATEGORY;
	constructor(public subcategory:SubCategory) {}
}
export class AddSubcategorySuccess implements Action {
	public readonly type = CatManageActionTypes.ADD_SUBCATEGORY_SUCCESS;
	constructor(public subcategory:SubCategory) {}
}
export class EditSubcategory implements Action {
	public readonly type = CatManageActionTypes.EDIT_SUBCATEGORY;
	constructor(public subcategory:SubCategory) {}
}
export class EditSubcategorySave implements Action {
	public readonly type = CatManageActionTypes.EDIT_SUBCATEGORY_SAVE;
	constructor(public subcategory: SubCategory) {}
}
export class EditSubcategoryCancel implements Action {
	public readonly type = CatManageActionTypes.EDIT_SUBCATEGORY_CANCEL;
	constructor() {}
}
export class EditSubcategorySuccess implements Action {
	public readonly type = CatManageActionTypes.EDIT_SUBCATEGORY_SUCCESS;
	constructor() {}
}
export class DeleteSubcategory implements Action {
	public readonly type = CatManageActionTypes.DELETE_SUBCATEGORY;
	constructor(public subcategory:SubCategory) {}
}
export class DeleteSubcategoryConfirm implements Action {
	public readonly type = CatManageActionTypes.DELETE_SUBCATEGORY_CONFIRM;
	constructor() {}
}
export class DeleteSubcategoryCancel implements Action {
	public readonly type = CatManageActionTypes.DELETE_SUBCATEGORY_CANCEL;
	constructor() {}
}
export class DeleteSubcategorySuccess implements Action {
	public readonly type = CatManageActionTypes.DELETE_SUBCATEGORY_SUCCESS;
	constructor() {}
}
export class SaveSubcategories implements Action {
	public readonly type = CatManageActionTypes.SAVE_SUBCATEGORIES;
	constructor(public subcategories: Array<any>) {}
}
export class SaveSubcategoriesSuccess implements Action {
	public readonly type = CatManageActionTypes.SAVE_SUBCATEGORIES_SUCCESS;
	constructor() {}
}

export class GetSubcatTexts implements Action {
	public readonly type = CatManageActionTypes.GET_SUBCAT_TEXTS;
	constructor() {}
}
export class GetSubcatTextsSuccess implements Action {
	public readonly type = CatManageActionTypes.GET_SUBCAT_TEXTS_SUCCESS;
	constructor(public texts: Array<Text>) {}
}
export class ActivateSubcatText implements Action {
	public readonly type = CatManageActionTypes.ACTIVATE_SUBCAT_TEXT;
	constructor(public text: Text) {}
}
export class AddSubcatText implements Action {
	public readonly type = CatManageActionTypes.ADD_SUBCAT_TEXT;
	constructor(public text: Text) {}
}
export class AddSubcatTextSuccess implements Action {
	public readonly type = CatManageActionTypes.ADD_SUBCAT_TEXT_SUCCESS;
	constructor(public text: Text) {}
}
export class EditSubcatText implements Action {
	public readonly type = CatManageActionTypes.EDIT_SUBCAT_TEXT;
	constructor(public text: Text) {}
}
export class EditSubcatTextConfirm implements Action {
	public readonly type = CatManageActionTypes.EDIT_SUBCAT_TEXT_CONFIRM;
	constructor(public editingText: Text) {}
}
export class EditSubcatTextCancel implements Action {
	public readonly type = CatManageActionTypes.EDIT_SUBCAT_TEXT_CANCEL;
	constructor() {}
}
export class EditSubcatTextSuccess implements Action {
	public readonly type = CatManageActionTypes.EDIT_SUBCAT_TEXT_SUCCESS;
	constructor() {}
}
export class DeleteSubcatText implements Action {
	public readonly type = CatManageActionTypes.DELETE_SUBCAT_TEXT;
	constructor(public text: Text) {}
}
export class DeleteSubcatTextConfirm implements Action {
	public readonly type = CatManageActionTypes.DELETE_SUBCAT_TEXT_CONFIRM;
	constructor() {}
}
export class DeleteSubcatTextCancel implements Action {
	public readonly type = CatManageActionTypes.DELETE_SUBCAT_TEXT_CANCEL;
	constructor() {}
}
export class DeleteSubcatTextSuccess implements Action {
	public readonly type = CatManageActionTypes.DELETE_SUBCAT_TEXT_SUCCESS;
	constructor() {}
}
export class UpdateSubcatTexts implements Action {
	public readonly type = CatManageActionTypes.UPDATE_SUBCAT_TEXTS;
	constructor(public texts: Array<any>) {}
}
export class UpdateSubcatTextsSuccess implements Action {
	public readonly type = CatManageActionTypes.UPDATE_SUBCAT_TEXTS_SUCCESS;
	constructor() {}
}
export class CatManageReset implements Action {
	public readonly type = CatManageActionTypes.RESET;
	constructor() {}
}



export type CatManageActions = 
	GetModule |
	GetModuleSuccess |
	GetCategories |
	GetCategoriesSuccess |
	GetListTypes |
	GetListTypesSuccess |
	GetDocumentOutline | 
	GetDocumentOutlineSuccess |
	SetOutlineDepth |
	SetOutlineDepthSuccess |
	UpdateOutline | 
	UpdateOutlineSuccess | 

	ActivateCategory |
	EditCategory |
	EditCategoryCancel |
	EditCategorySave |
	EditCategorySuccess |
	DeleteCategory |
	DeleteCategoryCancel |
	DeleteCategoryConfirm |
	DeleteCategorySuccess |
	AddCategory |
	AddCategorySuccess |
	SaveCategories |
	SaveCategoriesSuccess |
	
	ActivateSubcategory |
	AddSubcategory |
	AddSubcategorySuccess |
	EditSubcategory |
	EditSubcategoryCancel |
	EditSubcategorySave |
	EditSubcategorySuccess |
	DeleteSubcategory |
	DeleteSubcategoryCancel |
	DeleteSubcategoryConfirm |
	DeleteSubcategorySuccess |
	SaveSubcategories |
	SaveSubcategoriesSuccess |
	
	GetSubcatTexts |
	GetSubcatTextsSuccess |
	ActivateSubcatText |
	AddSubcatText |
	AddSubcatTextSuccess |
	EditSubcatText |
	EditSubcatTextCancel |
	EditSubcatTextConfirm |
	EditSubcatTextSuccess |
	DeleteSubcatText |
	DeleteSubcatTextCancel |
	DeleteSubcatTextConfirm |
	DeleteSubcatTextSuccess |
	UpdateSubcatTexts |
	UpdateSubcatTextsSuccess |
	CatManageReset;

