<div class="quote-alerts">
  <mat-card >
    <mat-card-header>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChanged($event)" [selectedIndex]="alertSelectedIndex" #tabs>
        <mat-tab *ngFor="let tab of thresholdTabs" [label]="tab.name">
          <acc-main-threshold-form [alert]="tab" [carriers]="carriers" #thresholdForm> </acc-main-threshold-form>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions class="page-actions">
      <button  (click)="save()" mat-flat-button color="primary" class="save-quote-alerts-button">Save</button>
    </mat-card-actions>
  </mat-card>
</div>

  