<div class="pushButtonsDown">
<div mat-dialog-header>
	<h2 mat-dialog-title>Select a file to attach</h2>
</div>

<mat-dialog-content>
	<acc-common-library-search (onSearch)="search($event)"></acc-common-library-search>
	<div id="fileSelector" class="dialogContent">
		<div class="libraryFolders">
			<ul>
				<acc-common-library-content-folders [folders]="sharedAssets" (onAction)="folderAction($event)" [(activeFolder)]="activeFolder"></acc-common-library-content-folders>
				<acc-common-library-content-folders [folders]="myAssets" (onAction)="folderAction($event)" [(activeFolder)]="activeFolder" [showFolderActions]="false"></acc-common-library-content-folders>
			</ul>
		</div>
		<div class="libraryContents" *ngIf="contents">
			<acc-common-library-content-files [medias]="contents.LibraryItems" [showFileActions]="false" (onAction)="fileAction($event)"></acc-common-library-content-files>
		</div>
	</div>
</mat-dialog-content>

<div class="spacer">&nbsp;</div>

<mat-dialog-actions>
	<button mat-stroked-button (click)="close(0)">Cancel</button>
</mat-dialog-actions>
</div>