import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'acc-common-rename-folder',
  templateUrl: './rename-folder.component.html',
  styleUrls: ['./rename-folder.component.scss']
})
export class RenameFolderComponent implements OnInit {

	public folderNameField = new FormControl('', [Validators.required]);
	public nameForm: FormGroup;
	constructor(public dialogRef: MatDialogRef<RenameFolderComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) { }

	ngOnInit() {
		this.nameForm = this.fb.group({ name: this.folderNameField });
	}

	close(): void {
		this.dialogRef.close();
	}

	renameFolder(): void {
		this.dialogRef.close({ ...this.data.folder, mediaCategoryDesc:  this.folderNameField.value } );
	}

}
