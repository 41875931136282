

<div>
	<div mat-dialog-header>
		<h2 mat-dialog-title>Enhancing Your Quote</h2>
		<div class="close" (click)="close()"><mat-icon>highlight_off</mat-icon></div> 
	</div>

	<mat-dialog-content>
		<div *ngIf="docBuilder" class="doc-builder">
			<p>Check the content you'd like to use to enhance your quote:</p>
			<div *ngFor="let item of docBuilder.docBuilderItems" class="doc-builder-item">
				<div class="desc">{{ item.questionDesc }}</div>
				<div class="check"><mat-checkbox [(ngModel)]="item.checked" color="primary"></mat-checkbox></div>
			</div>
			<div class="doc-builder-item">
				<div class="desc">Include carrier information (where available)</div>
				<div class="check"><mat-checkbox [(ngModel)]="includeCarrierInformation" color="primary"></mat-checkbox></div>
			</div>
		</div>
	</mat-dialog-content>

	<div class="spacer">&nbsp;</div>

	<mat-dialog-actions>
		<button mat-raised-button (click)="continue()" color="primary">Continue</button>
	</mat-dialog-actions>
</div>
