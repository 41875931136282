import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { QuoteService, SessionService } from "acc-services";
import { map, take, takeUntil } from "rxjs/operators";
import { FormBuilder, FormGroup, FormArray, FormControl } from "@angular/forms";
import { IAppState } from "../../store/state/app.state";
import { SetErrorMessage } from "../../store/actions/status.actions";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import * as QuoteSelectors from "../../store/selectors/quote.selectors";
import { HealthProfile, QuoteRequest, ClientInformation, ProductInformation, HealthConditions, PhysicalBuild, TobaccoUse, BloodPressure, FamilyHistory, FatherProfile, MotherProfile, SisterProfile, BrotherProfile, DrivingViolation, DuiDwi, LicenseSuspension, MotorVehicleAccident, MovingViolation, HealthProfileRequest, UnderwritingInformation, Quote, Cholesterol, HealthProfileAlt } from "acc-models";
import { GetHealthProfileAlt, SetHealthProfile, SetQuoteRequest } from "../../store/actions/quote.actions";
import { HealthDetailsAttributes } from "projects/acc-models/src/lib/models/healthDetailsAttributes";
import * as moment from "moment";

@Component({
	selector: "acc-main-health-details",
	templateUrl: "./health-details.component.html",
	styleUrls: ["./health-details.component.scss"],
})
export class HealthDetailsComponent implements OnInit, OnDestroy {
	private quoteRequest: QuoteRequest;
	private destroy$: Subject<boolean> = new Subject();
	private isfamilyHistoryEnabled = true;
	private isHealthConditionsEnabled = true;
	private isDrivingViolationsEnabled = true;
	public healthTypesSections: Array<HealthTypesSection>;
	public healthDetailsForm: FormGroup;
	public selectedSection: CurrentSection;
	public selectedSectionIndex: number = 0;
	public heights: Array<any>;
	public lastUseOptions: Array<any>;
	public typesOfTobacco: Array<any>;
	public maleConditions: Array<any>;
	public femaleConditions: Array<any>;
	public currentSections: Array<CurrentSection> = [];
	public determineHealthClass: string;
	public fatherDeathConditions: Array<any> = [];
	public motherDeathConditions: Array<any> = [];
	public sisterDeathConditions: Array<any> = [];
	public brotherDeathConditions: Array<any> = [];

	public quoteRequestResults$ = this.store.pipe(select(QuoteSelectors.selectQuoteRequest), takeUntil(this.destroy$));

	public healthProfileResults$ = this.store.pipe(select(QuoteSelectors.selectHealthProfile), take(1), takeUntil(this.destroy$));

	public healthProfileAlt$ = this.store.pipe(select(QuoteSelectors.selectHealthProfileAlt), takeUntil(this.destroy$));
	public healthProfileAlt: HealthProfileAlt;

	constructor(private router: Router, private quoteService: QuoteService, private fb: FormBuilder, private store: Store<IAppState>, private sessionService: SessionService) {
		this.createForm();
		this.quoteRequestResults$.subscribe((qr: QuoteRequest) => (this.quoteRequest = qr));
		this.healthProfileResults$.subscribe((hp: HealthProfile) => this.populateForm(hp));
		this.healthProfileAlt$.subscribe((hpalt) => (this.healthProfileAlt = hpalt));
	}

	ngOnInit() {
		this.quoteService
			.getHealthTypes()
			.pipe(map((healthTypes) => healthTypes.map((hp) => <HealthTypesSection>{ ...hp })))
			.subscribe((healthTypes: Array<HealthTypesSection>) => {
				this.healthTypesSections = healthTypes;
				this.healthTypesSections[0].isEnabled = this.isHealthConditionsEnabled;
				this.healthTypesSections[1].isEnabled = this.isfamilyHistoryEnabled;
				this.healthTypesSections[2].isEnabled = this.isDrivingViolationsEnabled;
				this.healthTypesSections
					.filter((item) => item.isEnabled)
					.forEach((item) => {
						item.sections.forEach((section) => this.currentSections.push(<CurrentSection>{ key: item.key, group: item.name, section }));
					});
				this.selectedSection = this.currentSections[0];
			});
		this.getHeightsOptions();
		this.getLastUseOptions();
		this.getTypesOfTobbacoOptions();
		this.getMaleConditions();
		this.getFemaleConditions();
	}

	get form() {
		return this.healthDetailsForm.controls;
	}

	createForm() {
		const healthConditions = new FormGroup({
			physicalBuild: new FormGroup({
				height: new FormControl(),
				weight: new FormControl(),
			}),
			tobaccoUse: new FormGroup({
				everUseTobacco: new FormControl(),
				lastUserTobacco: new FormControl(),
				kindOfTobacco: new FormControl(),
				howMany: new FormControl(),
			}),
			bloodPressure: new FormGroup({
				everUseBloodPressureMedication: new FormControl(),
				bloodPressureSystolic: new FormControl(),
				bloodPressureDiastolic: new FormControl(),
			}),
			cholesterol: new FormGroup({
				everUseCholesterolMedication: new FormControl(),
				cholesterolTotalValue: new FormControl(),
				cholesterolHDLValue: new FormControl(),
				cholesterolHDLRatio: new FormControl(),
			}),
		});

		const familyHistory = new FormGroup({
			father: new FormGroup({
				fatherHistory: new FormControl(),
				conditions: new FormControl([]),
				fatherDiabetesDiagnosedAge: new FormControl(),
				fatherCardioVascularDiagnosedAge: new FormControl(),
				fatherStrokeDiagnosedAge: new FormControl(),
				fatherCancerDiagnosedAge: new FormControl(),
				deathConditions: new FormControl(),
				fatherDie: new FormControl(),
				deathAge: new FormControl(),
			}),
			mother: new FormGroup({
				motherHistory: new FormControl(),
				conditions: new FormControl([]),
				motherDiabetesDiagnosedAge: new FormControl(),
				motherCardioVascularDiagnosedAge: new FormControl(),
				motherStrokeDiagnosedAge: new FormControl(),
				motherCancerDiagnosedAge: new FormControl(),
				deathConditions: new FormControl(),
				motherDie: new FormControl(),
				deathAge: new FormControl(),
			}),
			sister: new FormGroup({
				sisterHistory: new FormControl(),
				conditions: new FormControl([]),
				sisterDiabetesDiagnosedAge: new FormControl(),
				sisterCardioVascularDiagnosedAge: new FormControl(),
				sisterStrokeDiagnosedAge: new FormControl(),
				sisterCancerDiagnosedAge: new FormControl(),
				deathConditions: new FormControl(),
				sisterDie: new FormControl(),
				deathAge: new FormControl(),
			}),
			brother: new FormGroup({
				brotherHistory: new FormControl(),
				conditions: new FormControl([]),
				brotherDiabetesDiagnosedAge: new FormControl(),
				brotherCardioVascularDiagnosedAge: new FormControl(),
				brotherStrokeDiagnosedAge: new FormControl(),
				brotherCancerDiagnosedAge: new FormControl(),
				deathConditions: new FormControl(),
				brotherDie: new FormControl(),
				deathAge: new FormControl(),
			}),
		});

		const drivingViolations = new FormGroup({
			duiDwi: new FormGroup({
				drivingConvictionExists: new FormControl(),
				howMany: new FormControl(),
				drivingDrunkDrivingDates1: new FormControl(),
				drivingDrunkDrivingDates2: new FormControl(),
				drivingDrunkDrivingDates3: new FormControl(),
				drivingDrunkDrivingDates4: new FormControl(),
				drivingDrunkDrivingDates5: new FormControl(),
			}),
			licenseSuspension: new FormGroup({
				drivingLicenseCurrentlyRevoked: new FormControl(),
				howMany: new FormControl(),
				drivingLicenseSuspensionDates1: new FormControl(),
				drivingLicenseSuspensionDates2: new FormControl(),
				drivingLicenseSuspensionDates3: new FormControl(),
				drivingLicenseSuspensionDates4: new FormControl(),
				drivingLicenseSuspensionDates5: new FormControl(),
			}),
			motorVehicleAccident: new FormGroup({
				motorVehicleAccidentExists: new FormControl(),
				howMany: new FormControl(),
				drivingMotorVehicleAccidentDates1: new FormControl(),
				drivingMotorVehicleAccidentDates2: new FormControl(),
				drivingMotorVehicleAccidentDates3: new FormControl(),
				drivingMotorVehicleAccidentDates4: new FormControl(),
				drivingMotorVehicleAccidentDates5: new FormControl(),
			}),
			movingViolation: new FormGroup({
				movingViolationExists: new FormControl(),
				howMany: new FormControl(),
				drivingMovingViolationDates1: new FormControl(),
				drivingMovingViolationDates2: new FormControl(),
				drivingMovingViolationDates3: new FormControl(),
				drivingMovingViolationDates4: new FormControl(),
				drivingMovingViolationDates5: new FormControl(),
			}),
			recklessDriving: new FormGroup({
				recklessDrivingViolationExists: new FormControl(),
				howMany: new FormControl(),
				drivingRecklessDrivingDates1: new FormControl(),
				drivingRecklessDrivingDates2: new FormControl(),
				drivingRecklessDrivingDates3: new FormControl(),
				drivingRecklessDrivingDates4: new FormControl(),
				drivingRecklessDrivingDates5: new FormControl(),
			}),
		});

		this.healthDetailsForm = this.fb.group({ healthConditions, familyHistory, drivingViolations });
	}

	async populateForm(hp: HealthProfile) {
		if (hp) {
			this.determineHealthClass = hp.healthClass;
			if (hp.healthConditions) {
				this.isHealthConditionsEnabled = hp.healthConditions.isSelected;
				if (this.isHealthConditionsEnabled) {
					if (hp.healthConditions.physicalBuild) {
						this.healthDetailsForm.get("healthConditions").get("physicalBuild").get("height").setValue(hp.healthConditions.physicalBuild.height);
						this.healthDetailsForm.get("healthConditions").get("physicalBuild").get("weight").setValue(hp.healthConditions.physicalBuild.weight);
					}
					if (hp.healthConditions.tobaccoUse) {
						this.healthDetailsForm.get("healthConditions").get("tobaccoUse").get("everUseTobacco").setValue(String(hp.healthConditions.tobaccoUse.everUseTobacco));
						this.healthDetailsForm.get("healthConditions").get("tobaccoUse").get("lastUserTobacco").setValue(hp.healthConditions.tobaccoUse.lastuserTobacco);
						this.healthDetailsForm.get("healthConditions").get("tobaccoUse").get("kindOfTobacco").setValue(hp.healthConditions.tobaccoUse.kindOfTobacco);
						this.healthDetailsForm
							.get("healthConditions")
							.get("tobaccoUse")
							.get("howMany")
							.setValue(hp.healthConditions.tobaccoUse.howMany > 0 ? hp.healthConditions.tobaccoUse.howMany : "");
					}
					if (hp.healthConditions.bloodPressure) {
						if (hp.healthConditions.bloodPressure.bloodPressureMedicationCurrentlyUse) {
							this.healthDetailsForm.get("healthConditions").get("bloodPressure").get("everUseBloodPressureMedication").setValue("currently");
						} else if (hp.healthConditions.bloodPressure.bloodPressureMedicationUsedInPast) {
							this.healthDetailsForm.get("healthConditions").get("bloodPressure").get("everUseBloodPressureMedication").setValue("past");
						} else if (hp.healthConditions.bloodPressure.bloodPressureMedicationNeverUsed) {
							this.healthDetailsForm.get("healthConditions").get("bloodPressure").get("everUseBloodPressureMedication").setValue("never");
						}
						this.healthDetailsForm
							.get("healthConditions")
							.get("bloodPressure")
							.get("bloodPressureSystolic")
							.setValue(hp.healthConditions.bloodPressure.bloodPressureSystolic > 0 ? hp.healthConditions.bloodPressure.bloodPressureSystolic : "");
						this.healthDetailsForm
							.get("healthConditions")
							.get("bloodPressure")
							.get("bloodPressureDiastolic")
							.setValue(hp.healthConditions.bloodPressure.bloodPressureDiastolic > 0 ? hp.healthConditions.bloodPressure.bloodPressureDiastolic : "");
					}
					if (hp.healthConditions.cholesterol) {
						if (hp.healthConditions.cholesterol.cholesterolMedicationCurrentlyUse) {
							this.healthDetailsForm.get("healthConditions").get("cholesterol").get("everUseCholesterolMedication").setValue("currently");
						} else if (hp.healthConditions.cholesterol.cholesterolMedicationUsedInPast) {
							this.healthDetailsForm.get("healthConditions").get("cholesterol").get("everUseCholesterolMedication").setValue("past");
						} else if (hp.healthConditions.cholesterol.cholesterolMedicationNeverUsed) {
							this.healthDetailsForm.get("healthConditions").get("cholesterol").get("everUseCholesterolMedication").setValue("never");
						}
						this.healthDetailsForm
							.get("healthConditions")
							.get("cholesterol")
							.get("cholesterolTotalValue")
							.setValue(hp.healthConditions.cholesterol.cholesterolTotalValue > 0 ? hp.healthConditions.cholesterol.cholesterolTotalValue : "");
						this.healthDetailsForm
							.get("healthConditions")
							.get("cholesterol")
							.get("cholesterolHDLValue")
							.setValue(hp.healthConditions.cholesterol.cholesterolHDLValue > 0 ? hp.healthConditions.cholesterol.cholesterolHDLValue : "");
						this.healthDetailsForm
							.get("healthConditions")
							.get("cholesterol")
							.get("cholesterolHDLRatio")
							.setValue(hp.healthConditions.cholesterol.cholesterolHDLRatio > 0 ? hp.healthConditions.cholesterol.cholesterolHDLRatio : "");
					}
				}
			}
			if (hp.familyHistory) {
				this.isfamilyHistoryEnabled = hp.familyHistory.isSelected ? hp.familyHistory.isSelected : false;
				if (this.isfamilyHistoryEnabled) {
					if (hp.familyHistory.father) {
						this.healthDetailsForm.get("familyHistory").get("father").get("fatherHistory").setValue(hp.familyHistory.father.fatherHistory);
						this.healthDetailsForm.get("familyHistory").get("father").get("conditions").setValue(hp.familyHistory.father.conditions);
						this.healthDetailsForm
							.get("familyHistory")
							.get("father")
							.get("fatherDiabetesDiagnosedAge")
							.setValue(hp.familyHistory.father.fatherDiabetesDiagnosedAge > 0 ? hp.familyHistory.father.fatherDiabetesDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("father")
							.get("fatherCardioVascularDiagnosedAge")
							.setValue(hp.familyHistory.father.fatherCardiovascularDiagnosedAge > 0 ? hp.familyHistory.father.fatherCardiovascularDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("father")
							.get("fatherStrokeDiagnosedAge")
							.setValue(hp.familyHistory.father.fatherStrokeDiagnosedAge > 0 ? hp.familyHistory.father.fatherStrokeDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("father")
							.get("fatherCancerDiagnosedAge")
							.setValue(hp.familyHistory.father.fatherCancerDiagnosedAge > 0 ? hp.familyHistory.father.fatherCancerDiagnosedAge : "");
						this.healthDetailsForm.get("familyHistory").get("father").get("fatherDie").setValue(hp.familyHistory.father.fatherDie);
						this.healthDetailsForm
							.get("familyHistory")
							.get("father")
							.get("deathAge")
							.setValue(hp.familyHistory.father.deathAge > 0 ? hp.familyHistory.father.deathAge : "");
						this.healthDetailsForm.get("familyHistory").get("father").get("deathConditions").setValue(hp.familyHistory.father.deathConditions);
						this.fatherDeathConditions = await this.getDeathConditions(hp.familyHistory.father.deathConditions, "male");
					}
					if (hp.familyHistory.mother) {
						this.healthDetailsForm.get("familyHistory").get("mother").get("motherHistory").setValue(hp.familyHistory.mother.motherHistory);
						this.healthDetailsForm.get("familyHistory").get("mother").get("conditions").setValue(hp.familyHistory.mother.conditions);
						this.healthDetailsForm
							.get("familyHistory")
							.get("mother")
							.get("motherDiabetesDiagnosedAge")
							.setValue(hp.familyHistory.mother.motherDiabetesDiagnosedAge > 0 ? hp.familyHistory.mother.motherDiabetesDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("mother")
							.get("motherCardioVascularDiagnosedAge")
							.setValue(hp.familyHistory.mother.motherCardiovascularDiagnosedAge > 0 ? hp.familyHistory.mother.motherCardiovascularDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("mother")
							.get("motherStrokeDiagnosedAge")
							.setValue(hp.familyHistory.mother.motherStrokeDiagnosedAge > 0 ? hp.familyHistory.mother.motherStrokeDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("mother")
							.get("motherCancerDiagnosedAge")
							.setValue(hp.familyHistory.mother.motherCancerDiagnosedAge > 0 ? hp.familyHistory.mother.motherCancerDiagnosedAge : "");
						this.healthDetailsForm.get("familyHistory").get("mother").get("deathConditions").setValue(hp.familyHistory.mother.deathConditions);
						this.healthDetailsForm.get("familyHistory").get("mother").get("motherDie").setValue(hp.familyHistory.mother.motherDie);
						this.healthDetailsForm
							.get("familyHistory")
							.get("mother")
							.get("deathAge")
							.setValue(hp.familyHistory.mother.deathAge > 0 ? hp.familyHistory.mother.deathAge : "");
						this.motherDeathConditions = await this.getDeathConditions(hp.familyHistory.mother.deathConditions, "female");
					}
					if (hp.familyHistory.sister) {
						this.healthDetailsForm.get("familyHistory").get("sister").get("sisterHistory").setValue(hp.familyHistory.sister.sisterHistory);
						this.healthDetailsForm.get("familyHistory").get("sister").get("conditions").setValue(hp.familyHistory.sister.conditions);
						this.healthDetailsForm
							.get("familyHistory")
							.get("sister")
							.get("sisterDiabetesDiagnosedAge")
							.setValue(hp.familyHistory.sister.sisterDiabetesDiagnosedAge > 0 ? hp.familyHistory.sister.sisterDiabetesDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("sister")
							.get("sisterCardioVascularDiagnosedAge")
							.setValue(hp.familyHistory.sister.sisterCardiovascularDiagnosedAge > 0 ? hp.familyHistory.sister.sisterCardiovascularDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("sister")
							.get("sisterStrokeDiagnosedAge")
							.setValue(hp.familyHistory.sister.sisterStrokeDiagnosedAge > 0 ? hp.familyHistory.sister.sisterStrokeDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("sister")
							.get("sisterCancerDiagnosedAge")
							.setValue(hp.familyHistory.sister.sisterCancerDiagnosedAge > 0 ? hp.familyHistory.sister.sisterCancerDiagnosedAge : "");
						this.healthDetailsForm.get("familyHistory").get("sister").get("deathConditions").setValue(hp.familyHistory.sister.deathConditions);
						this.healthDetailsForm.get("familyHistory").get("sister").get("sisterDie").setValue(hp.familyHistory.sister.sisterDie);
						this.healthDetailsForm
							.get("familyHistory")
							.get("sister")
							.get("deathAge")
							.setValue(hp.familyHistory.sister.deathAge > 0 ? hp.familyHistory.sister.deathAge : "");
						this.sisterDeathConditions = await this.getDeathConditions(hp.familyHistory.sister.deathConditions, "female");
					}
					if (hp.familyHistory.brother) {
						this.healthDetailsForm.get("familyHistory").get("brother").get("brotherHistory").setValue(hp.familyHistory.brother.brotherHistory);
						this.healthDetailsForm.get("familyHistory").get("brother").get("conditions").setValue(hp.familyHistory.brother.conditions);
						this.healthDetailsForm
							.get("familyHistory")
							.get("brother")
							.get("brotherDiabetesDiagnosedAge")
							.setValue(hp.familyHistory.brother.brotherDiabetesDiagnosedAge > 0 ? hp.familyHistory.brother.brotherDiabetesDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("brother")
							.get("brotherCardioVascularDiagnosedAge")
							.setValue(hp.familyHistory.brother.brotherCardiovascularDiagnosedAge > 0 ? hp.familyHistory.brother.brotherCardiovascularDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("brother")
							.get("brotherStrokeDiagnosedAge")
							.setValue(hp.familyHistory.brother.brotherStrokeDiagnosedAge > 0 ? hp.familyHistory.brother.brotherStrokeDiagnosedAge : "");
						this.healthDetailsForm
							.get("familyHistory")
							.get("brother")
							.get("brotherCancerDiagnosedAge")
							.setValue(hp.familyHistory.brother.brotherCancerDiagnosedAge > 0 ? hp.familyHistory.brother.brotherCancerDiagnosedAge : "");
						this.healthDetailsForm.get("familyHistory").get("brother").get("deathConditions").setValue(hp.familyHistory.brother.deathConditions);
						this.healthDetailsForm.get("familyHistory").get("brother").get("brotherDie").setValue(hp.familyHistory.brother.brotherDie);
						this.healthDetailsForm
							.get("familyHistory")
							.get("brother")
							.get("deathAge")
							.setValue(hp.familyHistory.brother.deathAge > 0 ? hp.familyHistory.brother.deathAge : "");
						this.brotherDeathConditions = await this.getDeathConditions(hp.familyHistory.brother.deathConditions, "male");
					}
				}
			}
			if (hp.drivingViolation) {
				this.isDrivingViolationsEnabled = hp.drivingViolation.isSelected ? hp.drivingViolation.isSelected : false;
				if (this.isDrivingViolationsEnabled) {
					if (hp.drivingViolation.duiDwi) {
						this.healthDetailsForm.get("drivingViolations").get("duiDwi").get("drivingConvictionExists").setValue(hp.drivingViolation.duiDwi.drivingConvictionExists);
						this.healthDetailsForm
							.get("drivingViolations")
							.get("duiDwi")
							.get("howMany")
							.setValue(hp.drivingViolation.duiDwi.drivingDrunkDrivingDates.length > 0 ? hp.drivingViolation.duiDwi.drivingDrunkDrivingDates.length : "");
						hp.drivingViolation.duiDwi.drivingDrunkDrivingDates.forEach((date: string, index: number) => {
							this.healthDetailsForm
								.get("drivingViolations")
								.get("duiDwi")
								.get(`drivingDrunkDrivingDates${index + 1}`)
								.setValue(hp.drivingViolation.duiDwi.drivingDrunkDrivingDates[index]);
						});
					}
					if (hp.drivingViolation.licenseSuspension) {
						this.healthDetailsForm.get("drivingViolations").get("licenseSuspension").get("drivingLicenseCurrentlyRevoked").setValue(hp.drivingViolation.licenseSuspension.drivingLicenseCurrentlyRevoked);
						this.healthDetailsForm.get("drivingViolations").get("licenseSuspension").get("howMany").setValue(hp.drivingViolation.licenseSuspension.drivingLicenseSuspensionDates.length);
						hp.drivingViolation.licenseSuspension.drivingLicenseSuspensionDates.forEach((date: string, index: number) => {
							this.healthDetailsForm
								.get("drivingViolations")
								.get("licenseSuspension")
								.get(`drivingLicenseSuspensionDates${index + 1}`)
								.setValue(hp.drivingViolation.licenseSuspension.drivingLicenseSuspensionDates[index]);
						});
					}
					if (hp.drivingViolation.motorVehicleAccident) {
						this.healthDetailsForm.get("drivingViolations").get("motorVehicleAccident").get("motorVehicleAccidentExists").setValue(hp.drivingViolation.motorVehicleAccident.motorVehicleAccidentExists);
						this.healthDetailsForm.get("drivingViolations").get("motorVehicleAccident").get("howMany").setValue(hp.drivingViolation.motorVehicleAccident.drivingMotorVehicleAccidentDates.length);
						hp.drivingViolation.motorVehicleAccident.drivingMotorVehicleAccidentDates.forEach((date: string, index: number) => {
							this.healthDetailsForm
								.get("drivingViolations")
								.get("motorVehicleAccident")
								.get(`drivingMotorVehicleAccidentDates${index + 1}`)
								.setValue(hp.drivingViolation.motorVehicleAccident.drivingMotorVehicleAccidentDates[index]);
						});
					}
					if (hp.drivingViolation.movingViolation) {
						this.healthDetailsForm.get("drivingViolations").get("movingViolation").get("movingViolationExists").setValue(hp.drivingViolation.movingViolation.movingViolationExists);
						this.healthDetailsForm.get("drivingViolations").get("movingViolation").get("howMany").setValue(hp.drivingViolation.movingViolation.drivingMovingViolationDates.length);
						hp.drivingViolation.movingViolation.drivingMovingViolationDates.forEach((date: string, index: number) => {
							this.healthDetailsForm
								.get("drivingViolations")
								.get("movingViolation")
								.get(`drivingMovingViolationDates${index + 1}`)
								.setValue(hp.drivingViolation.movingViolation.drivingMovingViolationDates[index]);
						});
					}
					if (hp.drivingViolation.recklessDriving) {
						this.healthDetailsForm.get("drivingViolations").get("recklessDriving").get("recklessDrivingViolationExists").setValue(hp.drivingViolation.recklessDriving.recklessDrivingViolationExists);
						this.healthDetailsForm.get("drivingViolations").get("recklessDriving").get("howMany").setValue(hp.drivingViolation.recklessDriving.drivingRecklessDrivingDates.length);
						hp.drivingViolation.recklessDriving.drivingRecklessDrivingDates.forEach((date: string, index: number) => {
							this.healthDetailsForm
								.get("drivingViolations")
								.get("recklessDriving")
								.get(`drivingRecklessDrivingDates${index + 1}`)
								.setValue(hp.drivingViolation.recklessDriving.drivingRecklessDrivingDates[index]);
						});
					}
				}
			}
		}
	}

	getHeightsOptions(): void {
		this.heights = this.quoteService.getHeightsOptions();
	}
	getLastUseOptions(): void {
		this.lastUseOptions = this.quoteService.getLastUseTobaccoOptions();
	}
	getTypesOfTobbacoOptions(): void {
		this.typesOfTobacco = this.quoteService.getTypesOfTobaccoOptions();
	}
	getMaleConditions(): void {
		this.quoteService.getMaleConditions().subscribe((conditions: any) => (this.maleConditions = conditions));
	}
	getFemaleConditions(): void {
		this.quoteService.getFemaleConditions().subscribe((conditions: any) => (this.femaleConditions = conditions));
	}

	selectHealthType(section: string): void {
		this.selectedSectionIndex = this.currentSections.findIndex((s) => s.section === section);
		this.selectedSection = this.currentSections[this.selectedSectionIndex];
	}

	async onSubmit() {
		await this.getDataFromForm();
		this.router.navigateByUrl("/quote/results");
	}

	async onShowTopTen() {
		await this.getDataFromForm();
		this.router.navigateByUrl("/quote/top-10");
	}

	async getDataFromForm() {
		if (!this.quoteRequest) {
			return;
		}

		const clientInformation = this.mapQuoteRequestToClientInfo(this.quoteRequest);
		//if no dob provided, estimate it from age
		let usingFakeDob: boolean = false;
		if (!clientInformation.birthDate) {
			usingFakeDob = true;
			clientInformation.birthDate = this.estimateDob(this.quoteRequest.inputs[0].actualAge, this.quoteRequest.inputs[0].nearestAge);
		}
		const productInformation = this.mapQuoteRequestToProductInfo(this.quoteRequest);
		const healthProfile = this.mapFormToHealthProfile(this.healthDetailsForm.value);
		const underwritingInformation = await this.mapHealthProfileToUnderwriterInfo(healthProfile, clientInformation, productInformation);
		if (usingFakeDob) {
			clientInformation.birthDate = "";
		}

		const quote = new Quote(this.quoteRequest.inputs[0].quoteID, clientInformation, productInformation, underwritingInformation, healthProfile, this.healthProfileAlt);
		this.store.dispatch(new SetQuoteRequest(quote.getRequest(this.sessionService.carriersProductsFilters)));
		this.store.dispatch(new SetHealthProfile(quote.healthProfileInformation));

		if (this.quoteRequest.inputs[0].quoteID) {
			this.quoteService.saveHealthProfile(this.quoteRequest.inputs[0].quoteID, HealthDetailsAttributes.fromHealthProfile(quote.healthProfileInformation)).subscribe();
		}
		//const healthProfile = this.mapFormToHealthProfile(this.healthDetailsForm.value);
		//this.store.dispatch(new SetHealthProfile(healthProfile));
	}
	estimateDob(age: number, nearestAge: number) {
		const today = new Date();
		let dob: Date;
		if (nearestAge) {
			dob = new Date(today.getFullYear() - age, nearestAge > age ? today.getMonth() - 5 : today.getMonth() + 1, 1);
		} else {
			dob = new Date(today.getFullYear() - age, today.getMonth() + 1, 1);
		}
		return moment(dob).format('YYYY-MM-DD');
	}

	async mapHealthProfileToUnderwriterInfo(healthProfileInfo: HealthProfile, clientInformation: ClientInformation, productInformation: ProductInformation) {
		const healthClasses: Array<string> = [];
		const healthProfileReq: HealthProfileRequest = await healthProfileInfo.getRequest(clientInformation, productInformation);
		const healthProfileRes = await this.getHealthProfileReq(healthProfileReq);
		if (healthProfileRes) {
			const distinctHealthProfiles = healthProfileRes.filter((value, i, arr) => arr.findIndex((val) => val.healthClassCode === value.healthClassCode) === i);
			distinctHealthProfiles.forEach((val) => {
				healthClasses.push(val.healthClassCode);
			});
		}
		const underwritingInfo = this.mapFormToHealthClassInfo({ healthClasses, tableRating: 0, flatExtraAmount: 0, flatExtraYear: 0 });
		return underwritingInfo;
	}

	async getHealthProfileReq(healthProfileReq: HealthProfileRequest) {
		let healthProfileRes: any;
		await this.quoteService
			.getHealthProfile(healthProfileReq)
			.then((data: any) => {
				if (data) {
					healthProfileRes = data.result;
				} else {
					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
				}
			})
			.catch((err: any) => this.store.dispatch(new SetErrorMessage("Could not access pipeline api")));
		return healthProfileRes;
	}

	mapFormToHealthClassInfo(healthClassInfo: any): UnderwritingInformation {
		return <UnderwritingInformation>{
			healthClasses: healthClassInfo.healthClasses,
			tableRating: healthClassInfo.tableRating,
			flatExtraAmount: healthClassInfo.flatExtraAmount,
			flatExtraYearEnd: healthClassInfo.flatExtraYear,
		};
	}

	mapQuoteRequestToClientInfo(quoteRequest: QuoteRequest): ClientInformation {
		return <ClientInformation>{
			clientName: quoteRequest.props.clientName,
			birthDate: quoteRequest.inputs[0].birthDate.toString(),
			age: quoteRequest.inputs[0].actualAge,
			nearestAge: quoteRequest.inputs[0].nearestAge,
			gender: quoteRequest.inputs[0].gender,
			state: quoteRequest.inputs[0].state,
			quoteDesc: quoteRequest.props.quoteDesc,
		};
	}

	mapQuoteRequestToProductInfo(quoteRequest: QuoteRequest): ProductInformation {
		return <ProductInformation>{
			quoteType: quoteRequest.quoteType,
			terms: quoteRequest.inputs[0].terms,
			faceAmounts: quoteRequest.inputs[0].faceAmounts,
			isAccidentalDeathBenefitRider: quoteRequest.inputs[0].props.isAccidentalDeathBenefitRider,
			isChildTermRider: quoteRequest.inputs[0].props.isChildTermRider,
			isReturnOfPremiumRider: quoteRequest.inputs[0].props.isReturnOfPremiumRider,
			isWaiverOfPremiumRider: quoteRequest.inputs[0].props.isWaiverOfPremiumRider,
			childRiderUnit: +quoteRequest.inputs[0].props.cru
		};
	}

	mapFormToHealthProfile(hp: any): HealthProfile {
		const healthProfile = new HealthProfile({
			healthClass: this.determineHealthClass,
			healthConditions: this.mapFormToHealthConditions(hp.healthConditions),
			familyHistory: this.mapFormToFamilyHistory(hp.familyHistory),
			drivingViolation: this.mapFormToDrivingViolations(hp.drivingViolations),
		});
		return healthProfile;
	}

	mapFormToHealthConditions(hc: any): HealthConditions {
		return new HealthConditions({
			isSelected: this.isHealthConditionsEnabled,
			physicalBuild: new PhysicalBuild({
				height: hc.physicalBuild.height ? hc.physicalBuild.height : "",
				weight: hc.physicalBuild.weight,
			}),
			tobaccoUse: new TobaccoUse({
				everUseTobacco: hc.tobaccoUse.everUseTobacco === "true",
				lastuserTobacco: hc.tobaccoUse.lastUserTobacco ? hc.tobaccoUse.lastUserTobacco : "B",
				kindOfTobacco: hc.tobaccoUse.kindOfTobacco ? hc.tobaccoUse.kindOfTobacco : "N",
				howMany: hc.tobaccoUse.howMany ? hc.tobaccoUse.howMany : 0,
			}),
			bloodPressure: new BloodPressure({
				bloodPressureMedicationCurrentlyUse: hc.bloodPressure.everUseBloodPressureMedication === "currently",
				bloodPressureMedicationUsedInPast: hc.bloodPressure.everUseBloodPressureMedication === "past",
				bloodPressureMedicationNeverUsed: hc.bloodPressure.everUseBloodPressureMedication === "never",
				bloodPressureSystolic: hc.bloodPressure.bloodPressureSystolic ? hc.bloodPressure.bloodPressureSystolic : 0,
				bloodPressureDiastolic: hc.bloodPressure.bloodPressureDiastolic ? hc.bloodPressure.bloodPressureDiastolic : 0,
			}),
			cholesterol: new Cholesterol({
				cholesterolMedicationCurrentlyUse: hc.cholesterol.everUseCholesterolMedication === "currently",
				cholesterolMedicationUsedInPast: hc.cholesterol.everUseCholesterolMedication === "past",
				cholesterolMedicationNeverUsed: hc.cholesterol.everUseCholesterolMedication === "never",
				cholesterolTotalValue: hc.cholesterol.cholesterolTotalValue ? hc.cholesterol.cholesterolTotalValue : 0,
				cholesterolHDLValue: hc.cholesterol.cholesterolHDLValue ? hc.cholesterol.cholesterolHDLValue : 0,
				cholesterolHDLRatio: hc.cholesterol.cholesterolHDLRatio ? hc.cholesterol.cholesterolHDLRatio : 0,
			}),
		});
	}

	mapFormToFamilyHistory(fh: any): FamilyHistory {
		return new FamilyHistory({
			isSelected: this.isfamilyHistoryEnabled,
			father: this.mapFamilyHistoryToFatherProfile(fh.father),
			mother: this.mapFamilyHistoryToMotherProfile(fh.mother),
			sister: this.mapFamilyHistoryToSisterProfile(fh.sister),
			brother: this.mapFamilyHistoryToBrotherProfile(fh.brother),
		});
	}

	mapFamilyHistoryToFatherProfile(father: any): FatherProfile {
		return new FatherProfile({
			fatherHistory: father.fatherHistory,
			conditions: father.conditions,
			deathConditions: father.deathConditions,
			deathAge: father.deathAge ? father.deathAge : 0,
			fatherCancer: this.hasCancer("father"),
			fatherCancerDiagnosedAge: father.fatherCancerDiagnosedAge ? father.fatherCancerDiagnosedAge : 0,
			fatherCancerDeath: this.hasDiedOfCancer("father"),
			fatherCancerDeathAge: this.hasDiedOfCancer("father") && father.deathAge ? father.deathAge : 0,
			fatherCancerProstateCancer: this.hasSpecificCondition("prostateCancer", "father"),
			fatherCancerTesticularCancer: this.hasSpecificCondition("testicularCancer", "father"),
			fatherCancerColonCancer: this.hasSpecificCondition("colonCancer", "father"),
			fatherCancerMelanomaCancer: this.hasSpecificCondition("melanoma", "father"),
			fatherCancerPancreaticCancer: this.hasSpecificCondition("pancreaticCancer", "father"),
			fatherCancerStomachCancer: this.hasSpecificCondition("stomachCancer", "father"),
			fatherCancerOtherCancer: this.hasSpecificCondition("otherCancer", "father"),
			fatherCardiovascular: this.hasSpecificCondition("heartDisease", "father"),
			fatherCardiovascularDiagnosedAge: father.fatherCardioVascularDiagnosedAge ? father.fatherCardioVascularDiagnosedAge : 0,
			fatherCardiovascularDeath: this.hasSpecificDeathCondition("heartDisease", "father"),
			fatherCardiovascularDeathAge: this.hasSpecificDeathCondition("heartDisease", "father") && father.deathAge ? father.deathAge : 0,
			fatherDiabetes: this.hasSpecificCondition("diabetes", "father"),
			fatherDiabetesDiagnosedAge: father.fatherDiabetesDiagnosedAge ? father.fatherDiabetesDiagnosedAge : 0,
			fatherDiabetesDeath: this.hasSpecificDeathCondition("diabetes", "father"),
			fatherDiabetesDeathAge: this.hasSpecificDeathCondition("diabetes", "father") && father.deathAge ? father.deathAge : 0,
			fatherStroke: this.hasSpecificCondition("stroke", "father"),
			fatherStrokeDiagnosedAge: father.fatherStrokeDiagnosedAge ? father.fatherStrokeDiagnosedAge : 0,
			fatherStrokeDeath: this.hasSpecificDeathCondition("stroke", "father"),
			fatherStrokeDeathAge: this.hasSpecificDeathCondition("stroke", "father") && father.deathAge ? father.deathAge : 0,
			fatherDie: father.fatherDie,
		});
	}

	mapFamilyHistoryToMotherProfile(mother: any): MotherProfile {
		return new MotherProfile({
			motherHistory: mother.motherHistory,
			conditions: mother.conditions,
			deathConditions: mother.deathConditions,
			deathAge: mother.deathAge ? mother.deathAge : 0,
			motherCancer: this.hasCancer("mother"),
			motherCancerDiagnosedAge: mother.motherCancerDiagnosedAge ? mother.motherCancerDiagnosedAge : 0,
			motherCancerDeath: this.hasDiedOfCancer("mother"),
			motherCancerDeathAge: this.hasDiedOfCancer("mother") && mother.deathAge ? mother.deathAge : 0,
			motherCancerBreastCancer: this.hasSpecificCondition("breastCancer", "mother"),
			motherCancerCervicalCancer: this.hasSpecificCondition("cervicalCancer", "mother"),
			motherCancerEndometrialCancer: this.hasSpecificCondition("endometrialCancer", "mother"),
			motherCancerFallopianTubeCancer: this.hasSpecificCondition("fallopianTubeCancer", "mother"),
			motherCancerOvarianTubeCancer: this.hasSpecificCondition("ovarianTubeCancer", "mother"),
			motherCancerVaginalCancer: this.hasSpecificCondition("vaginalCancer", "mother"),
			motherCancerVulvarCancer: this.hasSpecificCondition("vulvarCancer", "mother"),
			motherCancerColonCancer: this.hasSpecificCondition("colonCancer", "mother"),
			motherCancerMelanomaCancer: this.hasSpecificCondition("melanoma", "mother"),
			motherCancerPancreaticCancer: this.hasSpecificCondition("pancreaticCancer", "mother"),
			motherCancerStomachCancer: this.hasSpecificCondition("stomachCancer", "mother"),
			motherCancerOtherCancer: this.hasSpecificCondition("otherCancer", "mother"),
			motherCardiovascular: this.hasSpecificCondition("heartDisease", "mother"),
			motherCardiovascularDiagnosedAge: mother.motherCardioVascularDiagnosedAge ? mother.motherCardioVascularDiagnosedAge : 0,
			motherCardiovascularDeath: this.hasSpecificDeathCondition("heartDisease", "mother"),
			motherCardiovascularDeathAge: this.hasSpecificDeathCondition("heartDisease", "mother") && mother.deathAge ? mother.deathAge : 0,
			motherDiabetes: this.hasSpecificCondition("diabetes", "mother"),
			motherDiabetesDiagnosedAge: mother.motherDiabetesDiagnosedAge ? mother.motherDiabetesDiagnosedAge : 0,
			motherDiabetesDeath: this.hasSpecificDeathCondition("diabetes", "mother"),
			motherDiabetesDeathAge: this.hasSpecificDeathCondition("diabetes", "mother") && mother.deathAge ? mother.deathAge : 0,
			motherStroke: this.hasSpecificCondition("stroke", "mother"),
			motherStrokeDiagnosedAge: mother.motherStrokeDiagnosedAge ? mother.motherStrokeDiagnosedAge : 0,
			motherStrokeDeath: this.hasSpecificDeathCondition("stroke", "mother"),
			motherStrokeDeathAge: this.hasSpecificDeathCondition("stroke", "mother") && mother.deathAge ? mother.deathAge : 0,
			motherDie: mother.motherDie,
		});
	}

	mapFamilyHistoryToSisterProfile(sister: any): SisterProfile {
		return new SisterProfile({
			sisterHistory: sister.sisterHistory,
			conditions: sister.conditions,
			deathConditions: sister.deathConditions,
			deathAge: sister.deathAge ? sister.deathAge : 0,
			sisterCancer: this.hasCancer("sister"),
			sisterCancerDiagnosedAge: sister.sisterCancerDiagnosedAge ? sister.sisterCancerDiagnosedAge : 0,
			sisterCancerDeath: this.hasDiedOfCancer("sister"),
			sisterCancerDeathAge: this.hasDiedOfCancer("sister") && sister.deathAge ? sister.deathAge : 0,
			sisterCancerBreastCancer: this.hasSpecificCondition("breastCancer", "sister"),
			sisterCancerCervicalCancer: this.hasSpecificCondition("cervicalCancer", "sister"),
			sisterCancerEndometrialCancer: this.hasSpecificCondition("endometrialCancer", "sister"),
			sisterCancerFallopianTubeCancer: this.hasSpecificCondition("fallopianTubeCancer", "sister"),
			sisterCancerOvarianTubeCancer: this.hasSpecificCondition("ovarianTubeCancer", "sister"),
			sisterCancerVaginalCancer: this.hasSpecificCondition("vaginalCancer", "sister"),
			sisterCancerVulvarCancer: this.hasSpecificCondition("vulvarCancer", "sister"),
			sisterCancerColonCancer: this.hasSpecificCondition("colonCancer", "sister"),
			sisterCancerMelanomaCancer: this.hasSpecificCondition("melanoma", "sister"),
			sisterCancerPancreaticCancer: this.hasSpecificCondition("pancreaticCancer", "sister"),
			sisterCancerStomachCancer: this.hasSpecificCondition("stomachCancer", "sister"),
			sisterCancerOtherCancer: this.hasSpecificCondition("otherCancer", "sister"),
			sisterCardiovascular: this.hasSpecificCondition("heartDisease", "sister"),
			sisterCardiovascularDiagnosedAge: sister.sisterCardioVascularDiagnosedAge ? sister.sisterCardioVascularDiagnosedAge : 0,
			sisterCardiovascularDeath: this.hasSpecificDeathCondition("heartDisease", "sister"),
			sisterCardiovascularDeathAge: this.hasSpecificDeathCondition("heartDisease", "sister") && sister.deathAge ? sister.deathAge : 0,
			sisterDiabetes: this.hasSpecificCondition("diabetes", "sister"),
			sisterDiabetesDiagnosedAge: sister.sisterDiabetesDiagnosedAge ? sister.sisterDiabetesDiagnosedAge : 0,
			sisterDiabetesDeath: this.hasSpecificDeathCondition("diabetes", "sister"),
			sisterDiabetesDeathAge: this.hasSpecificDeathCondition("diabetes", "sister") && sister.deathAge ? sister.deathAge : 0,
			sisterStroke: this.hasSpecificCondition("stroke", "sister"),
			sisterStrokeDiagnosedAge: sister.sisterStrokeDiagnosedAge ? sister.sisterStrokeDiagnosedAge : 0,
			sisterStrokeDeath: this.hasSpecificDeathCondition("stroke", "sister"),
			sisterStrokeDeathAge: this.hasSpecificDeathCondition("stroke", "sister") && sister.deathAge ? sister.deathAge : 0,
			sisterDie: sister.sisterDie,
		});
	}

	mapFamilyHistoryToBrotherProfile(brother: any): BrotherProfile {
		return new BrotherProfile({
			brotherHistory: brother.brotherHistory,
			conditions: brother.conditions,
			deathConditions: brother.deathConditions,
			deathAge: brother.deathAge ? brother.deathAge : 0,
			brotherCancer: this.hasCancer("brother"),
			brotherCancerDiagnosedAge: brother.brotherCancerDiagnosedAge ? brother.brotherCancerDiagnosedAge : 0,
			brotherCancerDeath: this.hasDiedOfCancer("brother"),
			brotherCancerDeathAge: this.hasDiedOfCancer("brother") && brother.deathAge ? brother.deathAge : 0,
			brotherCancerProstateCancer: this.hasSpecificCondition("prostateCancer", "brother"),
			brotherCancerTesticularCancer: this.hasSpecificCondition("testicularCancer", "brother"),
			brotherCancerColonCancer: this.hasSpecificCondition("colonCancer", "brother"),
			brotherCancerMelanomaCancer: this.hasSpecificCondition("melanoma", "brother"),
			brotherCancerPancreaticCancer: this.hasSpecificCondition("pancreaticCancer", "brother"),
			brotherCancerStomachCancer: this.hasSpecificCondition("stomachCancer", "brother"),
			brotherCancerOtherCancer: this.hasSpecificCondition("otherCancer", "brother"),
			brotherCardiovascular: this.hasSpecificCondition("heartDisease", "brother"),
			brotherCardiovascularDiagnosedAge: brother.brotherCardioVascularDiagnosedAge ? brother.brotherCardioVascularDiagnosedAge : 0,
			brotherCardiovascularDeath: this.hasSpecificDeathCondition("heartDisease", "brother"),
			brotherCardiovascularDeathAge: this.hasSpecificDeathCondition("heartDisease", "brother") && brother.deathAge ? brother.deathAge : 0,
			brotherDiabetes: this.hasSpecificCondition("diabetes", "brother"),
			brotherDiabetesDiagnosedAge: brother.brotherDiabetesDiagnosedAge ? brother.brotherDiabetesDiagnosedAge : 0,
			brotherDiabetesDeath: this.hasSpecificDeathCondition("diabetes", "brother"),
			brotherDiabetesDeathAge: this.hasSpecificDeathCondition("diabetes", "brother") && brother.deathAge ? brother.deathAge : 0,
			brotherStroke: this.hasSpecificCondition("stroke", "brother"),
			brotherStrokeDiagnosedAge: brother.brotherStrokeDiagnosedAge ? brother.brotherStrokeDiagnosedAge : 0,
			brotherStrokeDeath: this.hasSpecificDeathCondition("stroke", "brother"),
			brotherStrokeDeathAge: this.hasSpecificDeathCondition("stroke", "brother") && brother.deathAge ? brother.deathAge : 0,
			brotherDie: brother.brotherDie,
		});
	}

	mapFormToDrivingViolations(dv: any): DrivingViolation {
		return new DrivingViolation({
			isSelected: this.isDrivingViolationsEnabled ? this.isDrivingViolationsEnabled : false,
			drivingConvictionExists: dv.duiDwi.drivingConvictionExists === "true" || dv.licenseSuspension.drivingLicenseCurrentlyRevoked === "true" || dv.motorVehicleAccident.motorVehicleAccidentExists === "true" || dv.movingViolation.movingViolationExists === "true" || dv.recklessDriving.recklessDrivingViolationExists === "true",
			duiDwi: new DuiDwi({
				drivingConvictionExists: dv.duiDwi.drivingConvictionExists,
				drivingDrunkDrivingDates: this.getDrivingVaiolationsDates("duiDwi", "DrunkDriving"),
			}),
			licenseSuspension: new LicenseSuspension({
				drivingLicenseCurrentlyRevoked: dv.licenseSuspension.drivingLicenseCurrentlyRevoked,
				drivingLicenseSuspensionDates: this.getDrivingVaiolationsDates("licenseSuspension", "LicenseSuspension"),
			}),
			motorVehicleAccident: new MotorVehicleAccident({
				motorVehicleAccidentExists: dv.motorVehicleAccident.motorVehicleAccidentExists,
				drivingMotorVehicleAccidentDates: this.getDrivingVaiolationsDates("motorVehicleAccident", "MotorVehicleAccident"),
			}),
			movingViolation: new MovingViolation({
				movingViolationExists: dv.movingViolation.movingViolationExists,
				drivingMovingViolationDates: this.getDrivingVaiolationsDates("movingViolation", "MovingViolation"),
			}),
			recklessDriving: new MovingViolation({
				recklessDrivingViolationExists: dv.recklessDriving.recklessDrivingViolationExists,
				drivingRecklessDrivingDates: this.getDrivingVaiolationsDates("recklessDriving", "RecklessDriving"),
			}),
		});
	}

	hasSpecificDeathCondition(condition: string, group: string) {
		if (condition && group) {
			const conditions: Array<any> = this.healthDetailsForm.get("familyHistory").get(`${group}`).get("deathConditions").value;
			if (!conditions) {
				return false;
			}
			return conditions.find((c) => c === condition) ? true : false;
		}
	}

	hasSpecificCondition(condition: string, group: string) {
		if (condition && group) {
			const conditions: Array<any> = this.healthDetailsForm.get("familyHistory").get(`${group}`).get("conditions").value;
			if (!conditions) {
				return false;
			}
			return conditions.find((c) => c === condition) ? true : false;
		}
	}

	hasCancer(group: string) {
		if (group) {
			const conditions: Array<any> = this.healthDetailsForm.get("familyHistory").get(`${group}`).get("conditions").value;
			if (!conditions) {
				return false;
			}
			const hasCancer = conditions.find((c) => c === "prostateCancer" || c === "testicularCancer" || c === "colonCancer" || c === "melanoma" || c === "pancreaticCancer" || c === "otherCancer" || c === "stomachCancer" || c === "breastCancer" || c === "cervicalCancer" || c === "endometrialCancer" || c === "fallopianTubeCancer" || c === "ovarianTubeCancer" || c === "vaginalCancer" || c === "vulvarCancer") ? true : false;

			return hasCancer;
		}
	}

	hasDiedOfCancer(group: string) {
		if (group) {
			const conditions: Array<any> = this.healthDetailsForm.get("familyHistory").get(`${group}`).get("deathConditions").value;
			if (!conditions) {
				return false;
			}
			const hasCancer = conditions.find((c) => c === "prostateCancer" || c === "testicularCancer" || c === "colonCancer" || c === "melanoma" || c === "pancreaticCancer" || c === "otherCancer" || c === "stomachCancer" || c === "breastCancer" || c === "cervicalCancer" || c === "endometrialCancer" || c === "fallopianTubeCancer" || c === "ovarianTubeCancer" || c === "vaginalCancer" || c === "vulvarCancer") ? true : false;

			return hasCancer;
		}
	}

	getDrivingVaiolationsDates(group: string, field: string): Array<string> {
		const dates: Array<string> = [];
		const date1 = this.healthDetailsForm.get("drivingViolations").get(`${group}`).get(`driving${field}Dates1`).value;
		const date2 = this.healthDetailsForm.get("drivingViolations").get(`${group}`).get(`driving${field}Dates2`).value;
		const date3 = this.healthDetailsForm.get("drivingViolations").get(`${group}`).get(`driving${field}Dates3`).value;
		const date4 = this.healthDetailsForm.get("drivingViolations").get(`${group}`).get(`driving${field}Dates4`).value;
		const date5 = this.healthDetailsForm.get("drivingViolations").get(`${group}`).get(`driving${field}Dates5`).value;
		if (date1) {
			dates.push(date1);
		}
		if (date2) {
			dates.push(date2);
		}
		if (date3) {
			dates.push(date3);
		}
		if (date4) {
			dates.push(date4);
		}
		if (date5) {
			dates.push(date5);
		}

		return dates;
	}

	onNextSection() {
		this.selectedSectionIndex++;
		this.selectedSection = this.currentSections[this.selectedSectionIndex];
	}

	onBackSection() {
		this.selectedSectionIndex--;
		this.selectedSection = this.currentSections[this.selectedSectionIndex];
	}

	async onBackToHealthTypes() {
		await this.getDataFromForm();
		this.router.navigateByUrl("/quote/health-types");
	}

	currencyToNumber(amount: string): number {
		if (amount) {
			return Number.parseInt(String(amount).replace(/\,/g, ""), 10);
		} else {
			return 0;
		}
	}

	getDeathConditions(conditions: Array<string>, gender: string) {
		const currentConditions: Array<any> = [];
		let currentCondition: any;
		if (conditions) {
			conditions.forEach((condition) => {
				switch (gender) {
					case "male":
						if (!this.maleConditions) {
							this.getMaleConditions();
						}
						currentCondition = this.maleConditions.find((c) => c.code === condition);
						break;
					case "female":
						if (!this.femaleConditions) {
							this.getFemaleConditions();
						}
						currentCondition = this.femaleConditions.find((c) => c.code === condition);
						break;
				}
				if (currentCondition) {
					currentConditions.push(currentCondition);
				}
			});
		}
		return currentConditions;
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}

export interface CurrentSection {
	group: string;
	key: string;
	section: string;
}

export interface HealthTypesSection {
	name: string;
	img: string;
	key: string;
	sections: Array<string>;
	isEnabled?: boolean;
}
