<div class="quote__back-container--flex" *ngIf="fromQuote && !fromMyQuotes">
  <button mat-raised-button type="button" (click)="backToQuote()" >
    <mat-icon class="back-button" >arrow_back_ios</mat-icon> Return</button>
</div>
  <form class="content" [formGroup]="quoteForm">
    <!--<h1 class="page-title">Quote Insurance</h1>-->
    <mat-card formGroupName="clientInfo">
      <mat-card-header>
        <mat-card-title>Client Information</mat-card-title>
      </mat-card-header>

      <mat-card-content class="quote__client-info--grid">

        <mat-form-field appearance="outline" class="client-info__name">
          <mat-label>Name</mat-label>
          <!--<input matInput formControlName="name" [value]="clientInfo.name.value | titlecase" />-->
          <input matInput formControlName="name" [value]="clientInfo.name.value" />
        </mat-form-field>

		<mat-form-field appearance="outline" class="client-info__birth-date">
			<input matInput [textMask]="dateMask" formControlName="birthDate" name="dob" (blur)="onBirthDateChange(birthDate.value)" #birthDate required>
			<mat-label>Date Of Birth</mat-label>
			<mat-error *ngIf="birthDateField.hasError('dobOrAge')">Either DOB or Age must be provided.</mat-error>
			<mat-error *ngIf="birthDateField.hasError('invalidDate')">Please enter valid date</mat-error>
		</mat-form-field>

        <mat-form-field appearance="outline" class="client-info__age">
          <mat-label>Age</mat-label>
          <input matInput type="number" placeholder="Actual" formControlName="age" (blur)="checkAge()" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="client-info__nearest">
          <mat-label>Nearest</mat-label>
		  <input matInput type="number" formControlName="nearestAge" (blur)="checkAge()" />
		  <mat-error *ngIf="nearestAgeField.hasError('lessThanAge')">Nearest age cannot be less than age.</mat-error>
		  <mat-error *ngIf="nearestAgeField.hasError('invalidNearestAge')">Nearest age must be equal to, or one more than, actual age.</mat-error>
        </mat-form-field>

        <div class="client-info__gender-container--flex animated fadeIn quote-form-disable-animation" *ngIf="genders">
          <mat-label [ngClass]="{'invalid-label': isSubmitted && genderField.hasError('required')}">Gender *</mat-label>
          <mat-radio-group aria-label="Gender" class="radio-group-border" name="gender" formControlName="gender"
            [ngClass]="{'radio-group-invalid': isSubmitted && genderField.hasError('required')}" required>
            <mat-radio-button [value]="gender.value" *ngFor="let gender of genders">{{gender.name}}</mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field appearance="outline" class="client-info__states animated fadeIn" *ngIf="states">
          <mat-label>State</mat-label>
          <mat-select formControlName="state" required>
            <mat-option *ngFor="let state of states" [value]="state.code">{{state.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="client-info__description">
			<mat-label>Quote Description</mat-label>
			<input matInput formControlName="quoteDesc" [value]="clientInfo.quoteDesc.value" />
		  </mat-form-field>
	  </mat-card-content>
    </mat-card>

    <mat-card formGroupName="productInfo">
      <mat-card-header>
        <mat-card-title>Product Information</mat-card-title>
      </mat-card-header>

      <mat-card-content class="quote__product-info--grid">
        <mat-form-field appearance="outline" class="product-info__type animated fadeIn" *ngIf="quoteTypes">
          <mat-label>Product Type</mat-label>
          <mat-select formControlName="quoteType" (selectionChange)="onProductTypeChange(productType.value)"
            #productType required>
            <!--<mat-option></mat-option>-->
            <mat-option *ngFor="let quoteType of quoteTypes" [value]="quoteType.code">{{quoteType.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="product-info__terms animated fadeIn" *ngIf="terms">
          <mat-label>Term Length</mat-label>
          <mat-select multiple formControlName="terms" required (selectionChange)="selectTerm()">
            <mat-option *ngFor="let term of terms" [value]="term.value">{{term.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="termsField.invalid">You can only set up to 4 terms.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="product-info__benefits-1">
          <mat-label>Death Benefits</mat-label>
          <span matPrefix class="prefix-custom">$ &nbsp;</span>
          <input matInput formControlName="deathBenefit1" (keydown)="onlyAllowNumbers($event)" (keyup)="transformToCurrency('productInfo', 'deathBenefit1')"
            required />
          <mat-error *ngIf="deathBenefit1Field.hasError('minLimit')">Minimum death benefit is $10,000</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="product-info__benefits-2">
          <span matPrefix class="prefix-custom">$ &nbsp;</span>
          <input matInput formControlName="deathBenefit2"  (keydown)="onlyAllowNumbers($event)"
            (keyup)="transformToCurrency('productInfo', 'deathBenefit2')" />
          <mat-error *ngIf="deathBenefit2Field.hasError('minLimit')">Minimum death benefit is $10,000</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="product-info__benefits-3">
          <span matPrefix class="prefix-custom">$ &nbsp;</span>
          <input matInput formControlName="deathBenefit3" (keydown)="onlyAllowNumbers($event)"
            (keyup)="transformToCurrency('productInfo', 'deathBenefit3')" />
          <mat-error *ngIf="deathBenefit3Field.hasError('minLimit')">Minimum death benefit is $10,000</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="product-info__riders animated fadeIn" *ngIf="riders">
          <mat-label>Riders</mat-label>
          <mat-select multiple formControlName="riders"  (selectionChange)="onRiderSelectionChange($event)">
            <mat-option *ngFor="let rider of riders" [value]="rider.value">{{rider.name}}</mat-option>
          </mat-select>
          <mat-icon matSuffix class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.nq_riders}}" >info_outline</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="product-info__cir animated fadeIn" *ngIf="cirSelected">
          <mat-label>How many Child Rider Units?</mat-label>
          <mat-select formControlName="childRiderUnit">
            <mat-option *ngFor="let unit of childRiderUnits" [value]="unit">{{unit}}</mat-option>
          </mat-select>
        </mat-form-field>

      </mat-card-content>
    </mat-card>

    <mat-card id="healthCard">
      <mat-card-header>
        <mat-card-title>Underwriting Information</mat-card-title>
      </mat-card-header>

      <mat-card-content class="quote__underwriting-info--grid">
        <div class="underwriting__health-class-container--flex">
          <mat-label>How do you want to determine Health Class?</mat-label>
          <mat-radio-group class="radio-group-border">
            <mat-radio-button aria-label="Enter Health Profile" value="healthProfileInfo" #healthProfile
              [checked]="determineHealthClass === 'healthProfileInfo'"
              (change)="onHealthClassChange(healthProfile.value)">
              Enter Health Profile
            </mat-radio-button>
            <mat-radio-button aria-label="Select Health Class" value="healthClassInfo" #healthClass
              [checked]="determineHealthClass === 'healthClassInfo'" (change)="onHealthClassChange(healthClass.value)">
              Select Health Class
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <ng-container *ngIf="determineHealthClass === 'healthClassInfo'" formGroupName="healthClassInfo">

          <mat-form-field appearance="outline" class="underwriting__health-classes animated fadeIn">
            <mat-label>Health Class</mat-label>
            <mat-select multiple formControlName="healthClasses" required>
              <mat-option *ngFor="let healthClass of healthClasses" [value]="healthClass.code">
                {{healthClass?.commonName}}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!productInfo.quoteType.value"><strong>*Select a Product Type first</strong></mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline" class="underwriting__table-ratings animated fadeIn" *ngIf="tableRatings">
            <mat-label>Table Ratings</mat-label>
            <mat-select formControlName="tableRating">
              <mat-option *ngFor="let rating of tableRatings" [value]="rating.code">{{rating.name}}</mat-option>
            </mat-select>
            <mat-icon matSuffix class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.nq_table_ratings}}" >info_outline</mat-icon>
          </mat-form-field>

          <mat-form-field appearance="outline" class="underwriting__flat-extra animated fadeIn" >
            <mat-label>Flat Extra</mat-label>
            <span matPrefix class="prefix-custom">$ &nbsp;</span>
            <input matInput formControlName="flatExtraAmount"
              (blur)="transformToCurrency('healthClassInfo', 'flatExtraAmount')" />
            <mat-icon matSuffix class="q-info-icon suffix-custom" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.nq_flat}}" >info_outline</mat-icon>
            <mat-error *ngIf="flatExtraAmountField.hasError('minLimit') || flatExtraAmountField.hasError('maxLimit')">
              Flat Extra must between 0-999.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="underwriting__years animated fadeIn" *ngIf="flatExtraYears">
            <mat-label>Select Year(s)</mat-label>
            <mat-select formControlName="flatExtraYear">
              <mat-option></mat-option>
              <mat-option *ngFor="let years of flatExtraYears" [value]="years.code">{{years.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="determineHealthClass === 'healthProfileInfo'" formGroupName="healthProfileInfo">

          <mat-form-field appearance="outline" class="underwriting__height animated fadeIn" *ngIf="heights">
            <mat-label>Height</mat-label>
            <mat-select formControlName="height" (openedChange)="onHeightOpened($event)" (selectionChange)="heightWasChosen = true">
              <mat-option></mat-option>
              <mat-option *ngFor="let height of heights" [value]="height.code"><span class="h{{height.code.replace('\'','_')}}">{{height.name}}</span></mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="underwriting__weight animated fadeIn">
            <mat-label>Weight</mat-label>
            <input matInput formControlName="weight" placeholder="lbs" type="number" min="0" max="500" />
          </mat-form-field>

          <div class="underwriting__ever-used-tobacco animated fadeIn">
            <mat-label>Has your client ever used any tobacco product(s)?</mat-label>
            <mat-radio-group formControlName="everUseTobacco">
              <mat-radio-button aria-label="Use Tobacco Products" value="true" (change)="onEverUseTobaccoChange(true)">
                Yes</mat-radio-button>
              <mat-radio-button aria-label="Never Use Tobacco Products" value="false"
                (change)="onEverUseTobaccoChange(false)">No</mat-radio-button>
            </mat-radio-group>
          </div>

          <ng-container *ngIf="everUseTobaccoProducts">
            <mat-form-field appearance="outline" class="underwriting__last-use-tobacco animated fadeIn"
              *ngIf="lastUseOptions">
              <mat-label>When did they last use tobacco?</mat-label>
              <mat-select formControlName="lastUserTobacco">
                <mat-option></mat-option>
                <mat-option *ngFor="let option of lastUseOptions" [value]="option.code">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="underwriting__kind-of-tobacco animated fadeIn"
              *ngIf="typesOfTobacco">
              <mat-label>What kind of tobacco or nicotine?</mat-label>
              <mat-select formControlName="kindOfTobacco">
                <mat-option></mat-option>
                <mat-option *ngFor="let type of typesOfTobacco" [value]="type.code">{{type.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="underwriting__how-many-per-day animated fadeIn"
              *ngIf="kindOfTobaccoField.value">
              <mat-label *ngIf="kindOfTobaccoField.value === 'C'; else elseTemplate">How many per day?</mat-label>
              <ng-template #elseTemplate>
                <mat-label>How many per year?</mat-label>
              </ng-template>
              <input matInput type="number" formControlName="howMany" min="0" max="500" />
            </mat-form-field>
          </ng-container>
          
          <div class="underwriting__add-health-details animated fadeIn">
            <button mat-stroked-button type="submit" (click)="onSubmit('onHealthTypes')"  [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.q_health_details}}" >Add Health Details <mat-icon matSuffix class="q-health-info-icon" >info_outline</mat-icon></button>
          </div>
        </ng-container>

        <ng-container *ngIf="hasMarijuanaAlertConfiguration" formGroupName="healthProfileAlt">
          <div class="underwriting__ever-used-marijuana animated fadeIn">
            <mat-label>Does your client use marijuana?</mat-label>
            <mat-radio-group formControlName="everUsedMarijuana">
              <mat-radio-button aria-label="Use Marijuana" value="true" (change)="onEverUseMarijuanaChange(true)">Yes</mat-radio-button>
              <mat-radio-button aria-label="Never Use Marijuana" value="false" (change)="onEverUseMarijuanaChange(false)">No</mat-radio-button>
            </mat-radio-group>
          </div>
          
          <mat-form-field appearance="outline" class="underwriting__marijuana-type animated fadeIn" *ngIf="everUseMarijuanaSelected">
            <mat-label>What type of marijuana usage?</mat-label>
            <mat-select formControlName="typeMarijuanaUsage">
              <mat-option *ngFor="let type of marijuanaUseTypes" [value]="type.code">{{type.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="underwriting__marijuana-often animated fadeIn" *ngIf="everUseMarijuanaSelected">
            <mat-label>How often do they use marijuana?</mat-label>
            <mat-select formControlName="marijuanaFrequence">
              <mat-option *ngFor="let freq of marijuanaFrequencies" [value]="freq.rank">{{freq.thresholdDesc}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </mat-card-content>
    </mat-card>

    <div class="quote__submit-container--flex">
      <button mat-raised-button type="button" type="submit" (click)="onShowTopTen()">Show Top 10</button>
      <button mat-raised-button color="primary" type="submit" (click)="onSubmit('onResults')">Get Quotes</button>
    </div>
  </form>
