import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import { SetActiveCampaign } from '../../../store/actions/communication.actions';

@Component({
  selector: 'acc-main-campaign-actions',
  templateUrl: './campaign-actions.component.html',
  styleUrls: ['../../shared/actions.scss']
})
export class CampaignActionsComponent implements OnInit {

	constructor(private router: Router, private store: Store<IAppState>) { }

	ngOnInit() {
	}

	addNewCampaign(){
		this.store.dispatch(new SetActiveCampaign(''));
		this.router.navigate(['/communication/campaigns/campaign-new']);
	}
}
