import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { of, Observable, forkJoin } from "rxjs";
import { mergeMap, take, catchError, map } from "rxjs/operators";
import { environment } from "../../../../acc-main/src/environments/environment"; // not sure if it's ok to get it from here, what about acc-flow?
import { SessionService } from "./session.service";

@Injectable()
export class EmailEditorService {
	constructor(private http: HttpClient, private sessionService: SessionService) {}

	getTemplates() {
		return this.get('https://api.unlayer.com/v1/templates');
	}
	
	
	
	get(url: string, headers: any = {}, isRetry: boolean = false) {
		const hdrs = this.getHeaders({ ...headers, authorization: `Basic ${this.sessionService.settings.unlayer.authCode}` });
		return this.http.get(url, hdrs).pipe(
			map((r: any) => r.data),
		);
	}
	private getHeaders(hdrs?: any): any {
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
		headers.set('Access-Control-Allow-Origin', '*');
		if (hdrs) {
			Object.keys(hdrs).forEach(h => {
				headers = headers.set(h, hdrs[h]);
			});
		}
		return {headers: headers};
	}
}
