
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { Media } from 'acc-models';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'acc-main-module-asset-library',
	templateUrl: './module-asset-library.component.html',
	styleUrls: ['./module-asset-library.component.scss']
})


export class ModuleAssetLibraryComponent implements OnDestroy, OnChanges  {

	private userId: string;
	public mediaAssets: Array<Media>;
	public moduleId: number;
	//@Output() closeDialog:EventEmitter<Media> = new EventEmitter<Media>();


	constructor(private dataService: DataService, private sessionService: SessionService,
		public dialogRef: MatDialogRef<ModuleAssetLibraryComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.userId = sessionService.getUser().userID;
		this.moduleId = data.moduleId;
		this.getAssets();
	}

	getAssets() {
		this.dataService.getModuleContents(this.userId, this.moduleId).subscribe(resp => {
			this.mediaAssets = resp.Medias;
		})
	}
	ngOnDestroy():void {

	}

	ngOnChanges(changes:SimpleChanges) {
		if(changes.moduleId) {
			this.moduleId = changes.moduleId.currentValue;
			this.getAssets();
		}
		
	}
	selectMedia(media) {
		this.dialogRef.close(media);
	}
	close() {
		this.dialogRef.close(null);
	}
}
