

<acc-main-doc-details moduleID="{{moduleID}}" [document]="document" [documentId]="documentID" *ngIf="!detailsSaved" (onDocSaved)="docSaved()"></acc-main-doc-details>

<div class="docGen">	
	<ng-container *ngIf="detailsSaved">
		<div class="title">{{document.docDesc}}</div>
		<acc-main-module-navigation moduleID="{{moduleID}}" [documentId]="documentID" [docOutline]="docOutline" (subCatSelected)="subCatSelected($event)"></acc-main-module-navigation>
		<div class="actionsAndForm">
			<acc-main-module-actions (onAction)="doAction($event)" [quoteId]="document.quoteID"></acc-main-module-actions>
			<acc-main-module-form moduleID="{{moduleID}}" [document]="document" [docOutline]="docOutline" [subCategory]="currSubCategory" (goBack)="detailsSaved = false" (nextCategory)="goToNextCategory($event)"></acc-main-module-form>
		</div>
	</ng-container>
</div>