import { IAppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { IQuoteState } from '../state/quote.state';

const selectQuoteState = (state:IAppState) => state.quote;

export const selectQuoteRequest = createSelector(
	selectQuoteState,
	(state: IQuoteState) => state.request
);

export const selectHealthProfile = createSelector(
	selectQuoteState,
	(state: IQuoteState) => state.healthProfile
);

export const selectQuoteResponse = createSelector(
	selectQuoteState,
	(state: IQuoteState) => state.response
);

export const selectHealthProfileAlt = createSelector(
	selectQuoteState,
	(state: IQuoteState) => state.healthProfileAlt
);

export const selectSelections = createSelector(
	selectQuoteState,
	(state: IQuoteState) => state.selections
);

export const selectRecSort = createSelector(
	selectQuoteState,
	(state: IQuoteState) => state.recSort
)

export const selectSelectedPlans = createSelector(
	selectQuoteState,
	(state: IQuoteState) => state.selectedPlans
)

export const selectSellingPoints = createSelector(
	selectQuoteState,
	(state: IQuoteState) => state.sellingPoints
)