import { ActionReducerMap, combineReducers } from "@ngrx/store";
import { userReducer } from "./users.reducers";
import { statusReducer } from "./status.reducers";
import { categoriesReducer } from "./categories.reducers";
import { mediaReducer } from "./media.reducers";
import { IAppState } from "../state/app.state";
import { catManageReducer } from "./catmanage.reducers";
import { textLibReducer } from "./textlib.reducers";
import { revisionDatesReducer } from "./revisiondates.reducers";
import { communicationReducer } from "./communication.reducers";
import { quoteReducer } from "./quote.reducers";
import { signUpReducer } from "./signup.reducers";
import { dtcReducer } from "./dtc.reducers";

export const appReducers: ActionReducerMap<IAppState, any> = {
	adminUser: userReducer,
	status: statusReducer,
	adminCategories: categoriesReducer,
	adminMedia: mediaReducer,
	catmanage: catManageReducer,
	adminTextLib: textLibReducer,
	revisionDates: revisionDatesReducer,
	communication: communicationReducer,
	quote: quoteReducer,
	signUp: signUpReducer,
	dtc: dtcReducer
};
//make sure all reducers have a reset
