import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { Organization } from 'acc-models';
import { QuoteService, SessionService } from 'acc-services';
import { SetErrorMessage } from '../../store/actions/status.actions';
import { IAppState } from '../../store/state/app.state';
import { forkJoin, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { QuoteCarrier } from 'projects/acc-models/src/lib/models/quoteCarrier';
import { ThresholdFormComponent } from '../threshold-form/threshold-form.component';
import { ConfirmComponent } from 'acc-common';
import { QuoteThresholdAlertAdmin } from 'projects/acc-models/src/lib/models/quoteThresholdAlertAdmin';
import { QuoteThresholdAlertAdminThreshold } from 'projects/acc-models/src/lib/models/quoteThresholdAlertAdminThreshold';

@Component({
  selector: 'acc-main-quote-alerts',
  templateUrl: './quote-alerts.component.html',
  styleUrls: ['./quote-alerts.component.scss']
})
export class QuoteAlertsComponent implements  OnInit {

  @ViewChildren('thresholdForm') thresholdFormComponent: QueryList<ThresholdFormComponent>;
  @ViewChild('tabs', { static: true }) tabs: MatTabGroup;
  public alertSelectedIndex;
  
  private org: Organization;
  private quoteAlertsSettings: Map<number, any[]> = new Map<number, any[]>();
  public thresholdTabs: any[] = [];
  private messages: string[] = []
  private favorableUpTo: any[] = []
  public carriers: QuoteCarrier[] = [];
  private tempTabs = [];
  private guid: string;
  private changesDialogRef: MatDialogRef<ConfirmComponent>;
  
  constructor(private quoteService: QuoteService, private sessionService: SessionService, private store: Store<IAppState>, private changeDetectorRef: ChangeDetectorRef, private dialog: MatDialog,  private snackBar: MatSnackBar) { 
    this.org = this.sessionService.getItem("organization") as Organization;
    this.guid = this.createGuid();
  }
  
  ngOnInit() {
    this.sessionService.showLoader(this.guid);
    this.getCarriersList();
    this.tabs._handleClick = this.interceptTabChange.bind(this);
  }
  
	async confirmTabChange(): Promise<boolean> {
		this.changesDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `Are you sure you want to exit without saving?`
			}
		});
	
		return this.changesDialogRef.afterClosed()
		.toPromise() // here you have a Promise instead an Observable
		.then(confirm => {
			return Promise.resolve(confirm); // will return a Promise here
		});
	}

  async interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
		var originSelectedIndex = tabHeader.selectedIndex;
		var tabChange = true;
		var thresholdFormComp = this.thresholdFormComponent.toArray()[originSelectedIndex];
		if(originSelectedIndex != idx && !!thresholdFormComp.alertForm.dirty){
			tabChange = await this.confirmTabChange(); // waiting here
		}
		return this.checkIfCanChange(tab, tabHeader, idx, tabChange);
  }
  
  checkIfCanChange(tab: MatTab, tabHeader: MatTabHeader, idx: number, tabChange:boolean){
		return tabChange && MatTabGroup.prototype._handleClick.apply(this.tabs, arguments);
	}

  onTabChanged($event) {
		this.alertSelectedIndex = $event.index;
  }
  
  getOrganizationAlertData(){
    this.tempTabs = [];
    this.thresholdTabs = [];
    let observableBatch = [];

    this.quoteService.getQuoteAlertThresholdsTypes().subscribe(
      (types: any[]) => {
        this.quoteAlertsSettings = new Map<number, any[]>();
        //Build Types
        types.forEach(type => {

          observableBatch.push(this.getQuoteAlertData(type.thresholdTypeID));
          this.quoteAlertsSettings.set(type.thresholdTypeID, []);
          this.tempTabs.push({
            name: type.thresholdTypeDesc,
            typeID: type.thresholdTypeID,
            message: [],
            thresholds: [],
            favorableUpTo:[]
          });
        });

        //Configure Data.
        forkJoin(observableBatch).subscribe((resp:any) => {
          this.quoteService.getQuoteAlertThresholds(this.org.orgID).subscribe(
            (resp: any[]) => {
              this.buildAlertData(resp);
            },
            (err: any) => {
              this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
            }
          );
        });

      },
      (err: any) => {
        this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
      }
    );

  }

  buildAlertData(resp){

    //Configure Data
    resp.forEach(element => {
      var alert ={
        carrierID: element["carrierID"],
        thresholdID: element["thresholdID"],
      }

      var tID = element["thresholdTypeID"];

      if(!this.quoteAlertsSettings.has(tID)){
        this.quoteAlertsSettings.set(tID, []);
      }
      let arr = this.quoteAlertsSettings.get(tID);
      arr.push(alert);
      this.quoteAlertsSettings.set(tID, arr);
    });

    //Get Types
    this.tempTabs.forEach(t => {
      let thresholdsArray = this.quoteAlertsSettings.get(t.typeID).sort((a, b) => this.findCarrier(a.carrierID).localeCompare(this.findCarrier(b.carrierID)));
      t.message = this.messages[t.typeID-1];
      t.thresholds = thresholdsArray;
      t.favorableUpTo = this.favorableUpTo[t.typeID-1];
    });

    this.thresholdTabs = this.tempTabs;
    this.sessionService.hideLoader(this.guid);
  }

  findCarrier(id):string{
    var carrier = this.carriers.find(c => c.id === id);
    return (carrier != null)? carrier.name: "";
  }

  getQuoteAlertData(thresholdTypeID: number){
   return this.quoteService.getQuoteAlertData(this.org.orgID,thresholdTypeID).pipe(
    tap((result: any) => {
      this.favorableUpTo[thresholdTypeID - 1] = result["Thresholds"];
      this.messages[thresholdTypeID - 1] = result["Message"];

    })
    ) 
  }

  getCarriersList(){
		this.quoteService.getCarriers().subscribe(
			(resp: Array<QuoteCarrier>) => {
        this.carriers = resp.sort((a, b) => a.name.localeCompare(b.name));

        this.getOrganizationAlertData();

			},
			(err: any) => {
				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
			}
    );
  }

  mapThresholdData(selectedAlertComponent:ThresholdFormComponent): QuoteThresholdAlertAdmin {
    var alertData = new QuoteThresholdAlertAdmin();
    alertData.orgID = this.org.orgID;
    alertData.thresholdTypeID = selectedAlertComponent.alert["typeID"];
    alertData.message = selectedAlertComponent.alertForm.controls["message"].value;
    alertData.thresholds = [];
    let tarray = selectedAlertComponent.alertForm.controls["alerts"]["controls"];
    tarray.forEach(element => {
      let t = new QuoteThresholdAlertAdminThreshold();
      t.carrierID = element.controls["carrierID"].value;
      t.thresholdID = element.controls["thresholdID"].value;
      alertData.thresholds.push(t);
    });
    return alertData;
  }

  save(){
    this.changeDetectorRef.detectChanges();
    var selectedAlertComponent = this.thresholdFormComponent.toArray()[this.alertSelectedIndex];
    var alertData = this.mapThresholdData(selectedAlertComponent);

    this.quoteService.saveQuoteAlertThresholds(alertData).subscribe(resp => {
					
      let snackBarRef = this.snackBar.open('Quote Alert Thresholds Saved!', 'Close', {
        duration: 3000
      });

      this.getOrganizationAlertData();
    },
    (err: any) => {
      this.store.dispatch(new SetErrorMessage("Error saving the alerts, please try again."));
    }
    );

  }

  isQuoteAlertSaved() {
		var thresholdFormComp = this.thresholdFormComponent.toArray()[this.alertSelectedIndex];
		return !thresholdFormComp.alertForm.dirty;
  }

  canNavigateAway(): Observable<boolean> | boolean {
		this.changesDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `Are you sure you want to exit without saving?`,
			},
		});
		return this.changesDialogRef.afterClosed().pipe(take(1));
  }
  
  createGuid() {
		var dt = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = (dt + Math.random()*16)%16 | 0;
			dt = Math.floor(dt/16);
			return (c=='x' ? r :(r&0x3|0x8)).toString(16);
		});
		return uuid;
	}

}
