import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'acc-services';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'acc-main-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  private signUpKey: string;
  private resource:string;

  constructor(
    private route: ActivatedRoute, 
    private router:Router,
    private dataService: DataService,
    private snackBar: MatSnackBar
  ) { 

    this.route.paramMap.subscribe(params => {
      this.signUpKey = params.get('signUpKey');
      this.route.queryParamMap.subscribe(query => {
        this.resource = query.get('resource');
      });
		});

  }

  ngOnInit() {
    this.verifyAccount();
  }

  verifyAccount(){
    this.dataService.verifyAccount(this.signUpKey).subscribe((resp:any) => {
      this.snackBar.open('Your account has been activated!', 'OK', { duration: 5000 });
      this.router.navigateByUrl('/'+this.resource);
    },
    (err: any) => {
      this.snackBar.open('Your account cannot be activated.', 'OK', { duration: 5000 });
      this.router.navigateByUrl('/');
    });
  }

}
