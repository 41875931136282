import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataService } from 'acc-services';
import * as CommunicationActions from '../actions/communication.actions';
import { switchMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IMediaState } from '../state/media.state';
import { of } from 'rxjs';
import * as StatusActions from '../actions/status.actions';
import { Media, CommCampaignDetail } from 'acc-models';


@Injectable()
export class CommunicationEffects {

	@Effect()
	getLists$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.GET_LISTS),
		switchMap((action:CommunicationActions.GetLists) => this.dataService.getLists(action.orgId)
			.pipe(
				map((resp:any) => new CommunicationActions.GetListsSuccess(resp)),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	getList$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.GET_LIST),
		switchMap((action:CommunicationActions.GetList) => this.dataService.getList(action.listId)
			.pipe(
				map((resp:any) => new CommunicationActions.GetListSuccess(resp)),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	addList$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.NEW_LIST),
		switchMap((action:CommunicationActions.NewList) => this.dataService.newList(action.list.orgID, action.list.listName, action.list.listDesc, action.list.groupID)
			.pipe(
				map((resp:any) => new CommunicationActions.NewListSuccess(resp)),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	deleteList$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.DELETE_LIST_CONFIRM),
		withLatestFrom(this.store$.select('communication', 'listIdToDelete')),
		switchMap(([action, listIdToDelete]) => this.dataService.deleteList(listIdToDelete)
			.pipe(
				map((resp:any) => new CommunicationActions.DeleteListSuccess),
				catchError(((resp:any) => { 
					this.store$.dispatch(new CommunicationActions.DeleteListCancel()); //hide the form
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	saveList$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.SAVE_LIST_CONFIRM),
		withLatestFrom(this.store$.select('communication', 'list')),
		switchMap(([action, list]) => this.dataService.updateList(list.listID, list)
			.pipe(
				map((resp:any) => new CommunicationActions.SaveListSuccess(resp)),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)
/*
	@Effect()
	activateCampaign$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.ACTIVATE_CAMPAIGN),
		withLatestFrom(this.store$.select('communication', 'activeCampaignId')),
		switchMap(([action, activeCampaignId]) => this.dataService.getCampaign(activeCampaignId)
			.pipe(
				map((resp:any) => new CommunicationActions.ActivateCampaignSuccess(resp)),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)
*/
	@Effect()
	deleteCampaign$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.DELETE_CAMPAIGN_CONFIRM),
		withLatestFrom(this.store$.select('communication', 'campaignIdToDelete')),
		switchMap(([action, campaignIdToDelete]) => this.dataService.deleteCampaign(campaignIdToDelete)
			.pipe(
				map((resp:any) => new CommunicationActions.DeleteCampaignSuccess),
				catchError(((resp:any) => { 
					this.store$.dispatch(new CommunicationActions.DeleteCampaignCancel()); //hide the form
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	runReport$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.RUN_REPORT_CONFIRM),
		//withLatestFrom(this.store$.select('communication', 'activeCampaignId')),
		withLatestFrom(this.store$.select('communication', 'reportData')),
		switchMap(([action, reportData]) => this.dataService.getCampaignDetail(reportData.campaignID, reportData.groupID, reportData.lastName, reportData.phoneNumber, reportData.email, reportData.statusCode, reportData.communicationType)
			.pipe(
				map((resp:any) => new CommunicationActions.RunReportSuccess(new CommCampaignDetail({ totalRecords: resp[0].totalRecords, totalPages: resp[0].totalPages, CommCampaignDetailData: resp[1]}))),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect() 
	getGroups$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.GET_GROUPS),
		switchMap((action:CommunicationActions.GetGroups) => this.dataService.getCommGroups(action.orgId)
			.pipe(
				map((resp:any) => new CommunicationActions.GetGroupsSuccess(resp)),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	addGroup$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.ADD_GROUP),
		switchMap((action:CommunicationActions.AddGroup) => this.dataService.addCommGroup(action.group)
			.pipe(
				map((resp:any) => new CommunicationActions.AddGroupSuccess(resp)),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	editGroup$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.EDIT_GROUP_CONFIRM),
		withLatestFrom(this.store$.select('communication', 'group')),
		switchMap(([action, group]) => this.dataService.editCommGroup(group)
			.pipe(
				map((resp:any) => new CommunicationActions.EditGroupSuccess()),
				catchError(((resp:any) => { 
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	@Effect()
	deleteGroup$ = this.actions$.pipe(
		ofType(CommunicationActions.CommunicationActionTypes.DELETE_GROUP_CONFIRM),
		withLatestFrom(this.store$.select('communication', 'groupToDelete')),
		switchMap(([action, group]) => this.dataService.deleteCommGroup(group)
			.pipe(
				map((resp:any) => new CommunicationActions.DeleteGroupSuccess()),
				catchError(((resp:any) => { 
					this.store$.dispatch(new CommunicationActions.DeleteGroupCancel()); //hide the form
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)

		)
	)

	constructor(private actions$:Actions, private store$:Store<IMediaState>, private dataService:DataService) {}
}
