<h1>Tell us about your health.</h1>

<div class="form">
	<div class="general-health" [ngClass]="{error: ratingField.hasError('required') && ratingField.touched}">
		<h2>How healthy are you?</h2>
		<div class="hearts">
			<div class="fair" [ngClass]="{active: rating == 'A' || rating == 'B' || rating == 'C' || rating == 'D'}">
				<div class="box">
					<div class="tip"><span>Fair</span></div>
					<div class="heart" (click)="setRating('D')">
						<mat-icon class="filled">favorite</mat-icon>
						<mat-icon class="outline">favorite_border</mat-icon>
					</div>
				</div>
				
				<div class="good" [ngClass]="{active: rating == 'A' || rating == 'B' || rating == 'C'}">
					<div class="box">
						<div class="tip"><span>Good</span></div>
						<div class="heart" (click)="setRating('C')">
							<mat-icon class="filled">favorite</mat-icon>
							<mat-icon class="outline">favorite_border</mat-icon>
						</div>
					</div>
					<div class="verygood" [ngClass]="{active: rating == 'A' || rating == 'B'}">
						<div class="box">
							<div class="tip"><span>Very Good</span></div>
							<div class="heart" (click)="setRating('B')">
								<mat-icon class="filled">favorite</mat-icon>
								<mat-icon class="outline">favorite_border</mat-icon>
							</div>
						</div>
						<div class="excellent" [ngClass]="{active: rating == 'A'}">
							<div class="box">
								<div class="tip"><span>Excellent</span></div>
								<div class="heart" (click)="setRating('A')">
									<mat-icon class="filled">favorite</mat-icon>
									<mat-icon class="outline">favorite_border</mat-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="smoker" [ngClass]="{error: smokerField.hasError('required') && smokerField.touched}">
		<h2>Do you smoke?</h2>
		<div class="smoker-icons">
			<mat-icon class="smoker-no" [ngClass]="{active: smoker == 'no'}" (click)="setSmoker('no')">smoke_free</mat-icon>
			<mat-icon class="smoker-yes" [ngClass]="{active: smoker == 'yes'}" (click)="setSmoker('yes')">smoking_rooms</mat-icon>
		</div>
	</div>

</div>
<div class="actions">
	<button mat-raised-button color="primary" (click)="back()">Back</button>
	<button mat-raised-button color="primary" (click)="next()">Next</button>
</div>

