<acc-main-error-dialog *ngIf="errorMessage$ | async" [message]="errorMessage$ | async"></acc-main-error-dialog>

<acc-main-getting-started *ngIf="showGettingStartedDialog$ | async"></acc-main-getting-started>

<ng-progress [spinner]="false" [meteor]="false" [trickleSpeed]="1000000" [min]="0"></ng-progress>
<acc-main-loader *ngIf="showLoader"></acc-main-loader>

<div class="trial-info" *ngIf="trialEnds">Trial Ends in {{trialEnds}} day{{trialEnds == 1 ? '' : 's'}}. <ng-container *ngIf="isAdmin"><a [routerLink]="'/admin/subscription'">Sign up</a> today.</ng-container> </div>
<div class="content-container" [ngClass]="{'nav-open': expandNav, 'loaded': loaded && !selectedClassicView, 'loaded-classic': loaded && !!selectedClassicView, 'is-trial': trialEnds}">
	<div class="logoContainer">
		<div class="logo">
		</div>
	</div>


	<header id="mainHeader">
		<div class="container">
			<mat-toolbar class="p-0">				
				<div class="toolbarContainer">
					<div class="pageTitle">{{pageTitle}}&nbsp;
						<mat-icon *ngIf="showTooltip" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltipMessage}}" >info_outline</mat-icon>
					</div>
					
					<div class="menuContainer">
	
						<span (click)="upgradeToPro()" class="help-icon" *ngIf="sessionService.isSubscriptionFreeOrEmpty() && isAdmin">
							&nbsp;&nbsp;<mat-icon class="help-icon" fontSet="material-icons-outlined">verified</mat-icon>&nbsp;
							<span>Upgrade to PRO&nbsp;&nbsp;</span>
						</span>

						<button hide-in-classic-view mat-button [matMenuTriggerFor]="tutorialsMenu" *ngIf="showTutorial" class="getting-started-tutorial">
							<div class="accountButton">
								<mat-icon class="help-icon" fontSet="material-icons-outlined" >ondemand_video</mat-icon>&nbsp;
								<span>Tutorials</span>
								<mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
							</div>
						</button>

						<mat-menu #tutorialsMenu="matMenu" [overlapTrigger]="false">
							<button (click)="gettingStarted()" mat-menu-item >
								<span>Getting Started</span>
							</button>

							<button (click)="videoTutorials()" mat-menu-item >
								<span>Video Tutorials</span>
							</button>
						</mat-menu>

						<div hide-in-classic-view class="notifications-container"><mat-icon class="notifications" (click)="showWelcomeMessage()">notifications_outline</mat-icon></div>
						
						<button mat-button [matMenuTriggerFor]="userMenu" class="user-button greeting hasSubnav">
							<div class="accountButton">
								<div class="avatar mr-0 mr-sm-16" [style.backgroundImage]="'url(' + user.profileImage +')'"></div>
								<span class="username mr-12" fxHide fxShow.gt-sm>{{user.firstName}} {{user.lastName}} - {{activeProfileName}}</span>
								<mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
							</div>
						</button>
			
						<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
							<button (click)="goToProfile()" mat-menu-item  class="user-menu-item">
								<mat-icon>account_circle</mat-icon>
								<span><a>My Profile{{profiles?.length == 1 ? "" : "s" }}</a></span>
							</button>

							<button (click)="openPreferencesMenu()" *ngIf="showPreferences" mat-menu-item  class="user-menu-item" >
								<mat-icon>settings</mat-icon>
								<span>Preferences</span>
							</button>

							<button mat-menu-item  class="user-menu-item set-active-profile" [matMenuTriggerFor]="profileMenu">
								<mat-icon>assignment_ind</mat-icon>
								<span>Set Active Profile</span>
							</button>

							<mat-menu #profileMenu="matMenu" [overlapTrigger]="false">
								<button *ngFor="let p of profiles" (click)="selectProfile(p.profileID, p.profileName)" mat-menu-item class="user-menu-item profile-menu-item">
									<mat-icon>arrow_right_alt</mat-icon>
									<span><a >{{p.profileName}} <mat-icon class="check-icon" *ngIf="(p.profileID === activeProfileID)">done</mat-icon></a></span>
								</button>		
							</mat-menu>
			
							<button (click)="changePassword()" mat-menu-item class="user-menu-item">
								<mat-icon>lock</mat-icon>
								<span><a >Change Password</a></span>
							</button>
			
							<button (click)="logout()" mat-menu-item class="user-menu-item">
								<mat-icon>exit_to_app</mat-icon>
								<span><a >Logout</a></span>
							</button>

						</mat-menu>

					</div>
			
				</div>
			
			</mat-toolbar>
		</div>
	</header>

	<nav id="main-nav" [ngClass]="{open: expandNav}" >
		<div class="hamburger" (click)="toggleMenu()">
			<div class="hamburger-inner"></div>
		</div>
		<div hide-in-classic-view class="org-name"><span>{{(sessionService.getItem("organization"))?sessionService.getItem("organization")["orgDesc"]:""}}</span>&nbsp;</div>
		<div hide-in-classic-view class="menu" *ngIf="user.userID && sessionService.settings" #menuItems>
			<div *ngFor="let link of navigationItems" [class]="link.class" [ngClass]="{ hasSubnav: link.children && link.children.length > 0, subnavOpen: sessionService.navIsOpen[link.name] && expandNav }">
				<div [ngClass]="{'nav-item': true, 'hasNoLock':!!sessionService.canUseSubscriptionLevelFeature(link.path) || !!sessionService.canUseSubscriptionLevelFeature(link.action) || !!sessionService.canUseSubscriptionLevelFeature(link.name)}"  [routerLinkActive]="'active'" (click)="sessionService.navIsOpen[link.name] = !sessionService.navIsOpen[link.name]">
					<a class="h3 main-item {{link.class}}" *ngIf="link.path && link.type != 'button'" [routerLinkActive]="'active'"  (click)="validateSubscription(link)"  ><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(link.path)" >lock</mat-icon> <mat-icon fontSet="material-icons-outlined" [style.color]="link.iconColor" >{{link.icon}}&nbsp;</mat-icon><i>{{link.name}}</i></a>
					<a class="h3 main-item {{link.class}}" *ngIf="link.action && link.type != 'button'" (click)="validateSubscription(link)"><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(link.action)" >lock</mat-icon><mat-icon fontSet="material-icons-outlined" [style.color]="link.iconColor" >{{link.icon}}&nbsp;</mat-icon><i>{{link.name}}</i></a>
					<span class="h3 main-item {{link.class}}" *ngIf="!link.path && !link.action && link.type != 'button'" (click)="expandNav = true" ><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(link.name)" >lock</mat-icon><mat-icon fontSet="material-icons-outlined" [style.color]="link.iconColor" >{{link.icon}}&nbsp;</mat-icon><i>{{link.name}}</i></span>
					<button mat-raised-button color="primary" *ngIf="link.type == 'button'" (click)="validateSubscription(link)"><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(link.action)" >lock</mat-icon>{{link.name}}</button>
				</div>
				<ul *ngIf="link.children">
					<li *ngFor="let sub of link.children" [ngClass]="{hasSubnav: sub.children?.length > 0, subnavOpen: !!sub.isOpen, 'subHasNoLock':!!sessionService.canUseSubscriptionLevelFeature(sub.path) || !!sessionService.canUseSubscriptionLevelFeature(sub.action) || !!sessionService.canUseSubscriptionLevelFeature(sub.name)}">
						<div (click)="sub.isOpen = !sub.isOpen" [class]="sub.class">
							<a class="h3 main-item {{link.class}}" *ngIf="sub.path" (click)="validateSubscription(sub)" ><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(sub.path)" >lock</mat-icon>{{sub.name}}</a>
							<a class="h3 main-item {{link.class}}" *ngIf="sub.action" (click)="validateSubscription(sub)" ><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(sub.action)" >lock</mat-icon>{{sub.name}}</a>
							<span class="h3 main-item {{link.class}}" *ngIf="!sub.path && !sub.action"><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(sub.name)" >lock</mat-icon>{{sub.name}}</span>
						</div>
						<ul *ngIf="sub.children">
							<li *ngFor="let third of sub.children" [class]="third.class">
								<a class="h3 main-item" *ngIf="third.path" (click)="validateSubscription(third)" ><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(third.path)" >lock</mat-icon>{{third.name}}</a>
								<a class="h3 main-item" *ngIf="third.action" (click)="validateSubscription(third)"><mat-icon class="lock-icon" *ngIf="!sessionService.canUseSubscriptionLevelFeature(third.action)" >lock</mat-icon>{{third.name}}</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
		<div hide-in-classic-view class="spacer"></div>
		<div hide-in-classic-view class="nav-toggle-button" (click)="toggleMenu()"><mat-icon fontSet="material-icons-outlined">double_arrow</mat-icon></div>
	</nav>

	<acc-main-help-dialog *ngIf="helpVisible" (onClose)="helpVisible = false"></acc-main-help-dialog>

	<main >
		<header>

		</header>
		<router-outlet></router-outlet>
	</main>
			

	<footer id="main-footer">
	
	</footer>
</div>