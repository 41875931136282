/**
 * @prettier
 */
import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, NgZone } from "@angular/core";
import { User, CommCampaign, CommList, CommCampaignListParm, CommWavFile, UnlayerTemplate } from "acc-models";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DataService, SessionService, EmailEditorService } from "acc-services";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import * as moment from "moment";
import { CampaignTestComponent } from "../campaign-test/campaign-test.component";
import { SubscriptionLike, forkJoin, Observable, Subject, of } from "rxjs";
import { skip } from 'rxjs/operators';
import { Store } from "@ngrx/store";
import { IAppState } from "../../store/state/app.state";
import { SetErrorMessage } from "../../store/actions/status.actions";
import { SetTitle, SetActiveCampaign } from "../../store/actions/communication.actions";
import { communicationReducer } from "../../store/reducers/communication.reducers";
import { ConfirmComponent, FileSelectorComponent } from "acc-common";
import { take } from "rxjs/operators";
import { CommMediaAttachment } from "projects/acc-models/src/lib/models/commMediaAttachment";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ListParamsComponent } from "../list-params/list-params.component";
import { EmailEditorComponent } from 'angular-email-editor';
import { ListPreviewComponent } from "./list-preview/list-preview.component";
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
	selector: "app-campaign-form",
	templateUrl: "./campaign-form.component.html",
	styleUrls: ["./campaign-form.component.scss", "../shared/communication.scss", "../../components/module/editor/form/form.component.scss"],
	//encapsulation: ViewEncapsulation.None
})
export class CampaignFormComponent implements OnInit, AfterViewInit {
	
	@ViewChild("listParams", { static: true }) listParams: ListParamsComponent;
	@ViewChild(EmailEditorComponent, { static: true }) private emailEditor: EmailEditorComponent;
	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
	public campaign = new CommCampaign();
	public campaignID: string;
	public user: User;
	public confirmDeleteItemMessage: string = "Are you sure you want to delete the campaign?";
	public showDeleteCampaign: boolean = false;
	public phoneSources: Array<{
		sourceID: number;
		commTypeID: number;
		source: string;
	}>;
	public smsSources: Array<{
		sourceID: number;
		commTypeID: number;
		source: string;
	}>;
	public emailSources: Array<{
		sourceID: number;
		commTypeID: number;
		source: string;
	}>;
	public lists: Array<CommList>;
	public users: Array<User>;
	public filters: Array<CommCampaignListParm> = new Array<CommCampaignListParm>();
	private getCampaignSubscription: Observable<any>;
	private deleteCampaignSubscription: SubscriptionLike;
	private getListsSubscription: SubscriptionLike;
	private newCampaignSubscription: SubscriptionLike;
	private editCampaignSubscription: SubscriptionLike;
	private uploadFileSubscription: SubscriptionLike;
	private executeCampaignSubscription: SubscriptionLike;
	private persistTestData: { email: string; phone: string } = { email: "", phone: "" };
	public wavMessage: string;
	public wavPresent: boolean;
	wavFile: any = null;
	public isEdit = false;
	public allValid: boolean;
	public campaignForm = this.formBuilder.group({
		adminUserID: ["", Validators.required],
		campaignName: ["", Validators.required],
		deliveryDateTime: [null, []],
		listID: ["", []],
		campaignDesc: ["", []],
		email: [false, []],
		sms: [false, []],
		phone: [false, []],
		smsText: ["", []],
		emailSubject: ["", []],
		emailContent: ["", []],
		emailTemplate: ["", []],
		phoneWavFileName: ["", []],
		filterTypeID: ["1", Validators.required],
		orgID: ["", [Validators.required]],
		phoneSourceID: ["", []],
		smsSourceID: ["", []],
		emailSourceID: ["", []],
		maxTypesToSend:[1,[]]
	});
	maxTypesToSendList = [1,2,3];
	private PHONE = "Phone";
	private SMS = "SMS";
	private EMAIL = "Email";
	communicationWays = [
		this.SMS,
		this.EMAIL,
		this.PHONE
	  ];



	public templates: Array<UnlayerTemplate>;
	public emailEditorOptions: any;
	public editorLoadedSubject: Subject<boolean> = new Subject();

	public badSmsUrlPlacement: boolean = false;

	public listControl = new FormControl();
	public filteredLists: Observable<CommList[]>;

	// Add this property to track selection state
	private isSelecting = false;

	constructor(private dataService: DataService, 
		private snackBar: MatSnackBar, 
		private router: Router, 
		private route: ActivatedRoute, 
		private dialog: MatDialog, 
		private _location: Location, 
		private formBuilder: FormBuilder, 
		private sessionService: SessionService, 
		private store: Store<IAppState>,
		private matDialog: MatDialog,
		private zone: NgZone) 
		{
			this.user = this.sessionService.getUser();
	}

	ngOnInit() {
		this.campaignForm.controls["campaignName"].untouched;
		this.getLists();
		this.route.params.subscribe((params) => {
			if (!!params.campaignID) {
				this.initEditCampaign(params.campaignID);
				forkJoin(this.getCampaignSubscription, this.editorLoadedSubject.asObservable()).subscribe((resp) => {
					this.campaign = resp[0];
					if(this.campaign.emailTemplate) {
						this.emailEditor.loadDesign(JSON.parse(this.campaign.emailTemplate))
					}
					this.populateEditForm(this.campaign);
					this.store.dispatch(new SetTitle(this.campaign.campaignName));
		
				});
			} else {
				this.initNewCampaign();
				this.editorLoadedSubject.asObservable().subscribe(() => {
					if(this.campaign.emailTemplate) {
						this.emailEditor.loadDesign(JSON.parse(this.campaign.emailTemplate))
					}
				});
			}
		});
		this.dataService.getCampaignSources(this.user.orgID).subscribe((resp: any) => {
			this.phoneSources = resp.filter((s) => s.commTypeID == Communication.Type.PHONE);
			this.smsSources = resp.filter((s) => s.commTypeID == Communication.Type.SMS);
			this.emailSources = resp.filter((s) => s.commTypeID == Communication.Type.EMAIL);
		});
		this.dataService.getUsers(this.sessionService.getUser().orgID).subscribe((users: any) => {
			this.users = users.filter((u) => u.admin);
		});

		this.emailEditorOptions = {
			projectId: this.sessionService.settings.unlayer.projectId,
		};
		this.getTemplates(this.user.orgID);

		// Setup list autocomplete with simple filtering
		this.listControl.valueChanges.subscribe(value => {
			this.filteredLists = of(this._filterLists(
				typeof value === 'string' ? value : value?.listName
			));
		});

		// Watch for list selection changes and update form control
		this.listControl.valueChanges.subscribe(selectedList => {
			if (selectedList && selectedList.listID) {
				this.campaignForm.patchValue({
					listID: selectedList.listID
				});
				this.autocompleteTrigger.closePanel();
			}
		});
	}
	ngAfterViewInit(): void {

	}

	editorLoaded(e) {
		this.editorLoadedSubject.next(true);
		this.editorLoadedSubject.complete();
	}

	initNewCampaign() {
		this.isEdit = false;
		this.campaignForm = this.formBuilder.group({
			adminUserID: ["", Validators.required],
			campaignName: ["", Validators.required],
			deliveryDateTime: ["", []],
			listID: ["", []],
			campaignDesc: ["", []],
			email: [false, []],
			sms: [false, []],
			phone: [false, []],
			smsText: ["", []],
			emailSubject: ["", []],
			emailContent: ["", []],
			emailTemplate: ["", []],
			phoneWavFileName: ["", []],
			filterTypeID: ["1", Validators.required],
			orgID: [this.user.orgID, [Validators.required]],
			phoneSourceID: ["", []],
			smsSourceID: ["", []],
			emailSourceID: ["", []],
			maxTypesToSend:[1,[]],
			phonePriority:[1,[]],
			smsPriority:[2,[]],
			emailPriority:[3,[]]
		});
		this.watchToggles();
	}

	initEditCampaign(campaignID) {
		this.isEdit = true;
		this.campaignID = campaignID;
		this.getCampaign(campaignID);
	}

	getTemplates(orgID) {
		const authKey = this.sessionService.settings.unlayer.authCode;
		const isShared = this.sessionService.settings.unlayer.isShared;
		this.dataService.getUnlayerTemplates(orgID, authKey, isShared).subscribe((resp: any) => this.templates = resp)
	}

	loadTemplate(id:number) {
		if(id) {
			this.emailEditor.editor.loadDesign(this.templates.find(t => t.templateID == id).design);
		}
	}

	goBack() {
		this._location.back();
	}

	getCampaign(campaignID) {
		this.getCampaignSubscription = this.dataService.getCampaign(campaignID);
	}

	populateEditForm(campaign) {
		let date = campaign.deliveryDateTime ? moment.utc(campaign.deliveryDateTime).toDate() : null;
		this.campaignForm = this.formBuilder.group({
			adminUserID: [campaign.adminUserID, Validators.required],
			campaignName: [campaign.campaignName, Validators.required],
			deliveryDateTime: [date, []],
			listID: [campaign.listID, []],
			campaignDesc: [campaign.campaignDesc, []],
			email: [campaign.email, []],
			sms: [campaign.sms, []],
			phone: [campaign.phone, []],
			smsText: [campaign.smsText, []],
			emailSubject: [campaign.emailSubject, []],
			emailContent: [campaign.emailContent, []],
			emailTemplate: [campaign.emailTemplate, []],
			phoneWavFileName: [campaign.phoneWavFileName, []],
			filterTypeID: [campaign.filterTypeID.toString(), Validators.required],
			orgID: [campaign.orgID, Validators.required],
			campaignID: [campaign.campaignID, Validators.required],
			phoneSourceID: [campaign.phoneSourceID, []],
			smsSourceID: [campaign.smsSourceID, []],
			emailSourceID: [campaign.emailSourceID, []],
			maxTypesToSend:[campaign.maxTypesToSend,[]],
			phonePriority:[campaign.phonePriority,[]],
			smsPriority:[campaign.smsPriority,[]],
			emailPriority:[campaign.emailPriority,[]]
		});
		if (campaign.phone) {
			this.campaignForm.controls["phoneSourceID"].setValidators(Validators.required);
		}
		if (campaign.sms) {
			this.campaignForm.controls["smsSourceID"].setValidators(Validators.required);
		}
		if (campaign.email) {
			this.campaignForm.controls["emailSourceID"].setValidators(Validators.required);
		}
		this.watchToggles();
		this.filters = campaign.ListParms;
		this.wavPresent = campaign.phoneWavFileName && campaign.phoneWavFileName != "";
		this.orderPriority(campaign);
		this.checkSmsForUrl();

		// Set the list control value if there's a selected list - with retry
		if (campaign.listID) {
			const selectedList = this.lists.find(l => l.listID === campaign.listID);
			if (selectedList) {
				this.listControl.setValue(selectedList, { emitEvent: false });
			}
		}
	}

	orderPriority(campaign){
		this.arrayMove(this.communicationWays, this.communicationWays.indexOf(this.PHONE), campaign.phonePriority - 1);
		this.arrayMove(this.communicationWays, this.communicationWays.indexOf(this.SMS), campaign.smsPriority - 1);
		this.arrayMove(this.communicationWays, this.communicationWays.indexOf(this.EMAIL), campaign.emailPriority - 1);
	}

	arrayMove(arr, old_index, new_index) {
		if (new_index >= arr.length) {
			var k = new_index - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr;
	};

	watchToggles(): void {
		this.campaignForm.controls["phone"].valueChanges.subscribe((value) => {
			if (value) {
				this.campaignForm.controls["phoneSourceID"].setValidators(Validators.required);
			} else {
				this.campaignForm.controls["phoneSourceID"].clearValidators();
			}
			this.campaignForm.controls["phoneSourceID"].updateValueAndValidity();
		});
		this.campaignForm.controls["sms"].valueChanges.subscribe((value) => {
			if (value) {
				this.campaignForm.controls["smsSourceID"].setValidators(Validators.required);
				this.campaignForm.controls["smsText"].setValidators(Validators.required);
			} else {
				this.campaignForm.controls["smsSourceID"].clearValidators();
				this.campaignForm.controls["smsText"].clearValidators();
			}
			this.campaignForm.controls["smsSourceID"].updateValueAndValidity();
			this.campaignForm.controls["smsText"].updateValueAndValidity();
		});
		this.campaignForm.controls["email"].valueChanges.subscribe((value) => {
			if (value) {
				this.campaignForm.controls["emailSourceID"].setValidators(Validators.required);
				this.campaignForm.controls["emailSubject"].setValidators(Validators.required);
				this.campaignForm.controls["emailContent"].setValidators(Validators.required);
			} else {
				this.campaignForm.controls["emailSourceID"].clearValidators();
				this.campaignForm.controls["emailSubject"].clearValidators();
				this.campaignForm.controls["emailContent"].clearValidators();
			}
			this.campaignForm.controls["emailSourceID"].updateValueAndValidity();
			this.campaignForm.controls["emailSubject"].updateValueAndValidity();
			this.campaignForm.controls["emailContent"].updateValueAndValidity();
		});
	}
	deleteCampaign() {
		this.showDeleteCampaign = true;
	}

	getLists() {
		this.getListsSubscription = this.dataService.getLists(this.user.orgID).subscribe((lists: any) => {
			this.lists = lists.sort((a,b) => {
				if(a.groupName == null && b.groupName != null) return 1;
				if(a.groupName != null && b.groupName == null) return -1;
				if(a.groupName > b.groupName) return 1;
				if(a.groupName < b.groupName) return -1;
				if(a.listName > b.listName) return 1;
				if(a.listName < b.listName) return -1;
				return 0;
			});
			
			// Initialize filteredLists after lists are loaded
			this.filteredLists = of(this.lists);

			// Now that the lists are loaded, set the selected list if we are editing
			if (this.isEdit && this.campaign && this.campaign.listID) {
				const selectedList = this.lists.find(l => l.listID === this.campaign.listID);
				if (selectedList) {
					this.listControl.setValue(selectedList, { emitEvent: false });
				}
			}
		});
	}

	previewList(e) {
		e.preventDefault();
		const listID = this.campaignForm.get('listID').value;
		this.matDialog.open(ListPreviewComponent, { data: { listID, listName:  this.lists.find(l => l.listID == listID).listName} });
	}
	onFileChange(event) {
		if (event.target.files[0]) {
			this.wavFile = event.target.files[0];
			let filename = event.target.files[0].name.split("/").reverse()[0];
			this.wavMessage = `${filename} will be uploaded.`;
		}
	}

	removeFile() {
		this.campaignForm.value.phoneWavFileName = null;
		this.wavMessage = null;
		this.wavPresent = false;
	}

	executeCampaign() {
		this.onSubmit(true).then((resp) => {
			if (this.campaign.campaignStatusID == Communication.CampaignStatus.SENT) {
				let confirmDialogRef = this.dialog.open(ConfirmComponent, {
					data: {
						title: "Confirm",
						message: "Campaign has already been sent. Resending will clear all history data for the campaign and send it again. Do you want to continue?",
					},
				});
				confirmDialogRef.afterClosed().subscribe((confirm) => {
					if (confirm) {
						this.doExecuteCampaign();
					}
				});
			} else {
				this.doExecuteCampaign();
			}
		});
	}
	doExecuteCampaign() {
		let confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: "Campaign will be delivered immediately to all specified List members.  Do you want to continue? ",
			},
		});
		confirmDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((resp) => {
				if (resp) {
					this.executeCampaignSubscription = this.dataService.executeCampaign(this.campaignID, this.user.userID).subscribe(
						(resp: any) => {
							this.router.navigateByUrl("/communication/campaigns");
						},
						(err) => {
							this.store.dispatch(new SetErrorMessage("Execute Campaign Failed: " + err.error.Message));
						}
					);
				}
			});
	}

	showTestDialog() {
		const dialogRef = this.dialog.open(CampaignTestComponent, {
			data: { email: this.persistTestData.email, phone: this.persistTestData.phone },
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.persistTestData = { email: result.email, phone: result.phone };
			}
			if (result.sendTest) {
				this.testCampaign(result.email, result.phone);
			}
		});
	}

	testCampaign(email: string, phone: string) {
		this.onSubmit(true)
			.then((resp) => {
				this.dataService.testCampaign(this.campaignID, email, phone).subscribe(
					(result: any) => {
						const snackBarRef = this.snackBar.open("Campaign Test Success!", "Close", {
							duration: 3000,
						});
					},
					(err) => {
						this.showError(err, "Campaign test error");
					}
				);
			})
			.catch((resp) => {
				this.store.dispatch(new SetErrorMessage("Correct validation errors before sending test"));
			});
	}

	attachFile(type, e) {
		e.preventDefault();
		const attachDialogRef = this.dialog.open(FileSelectorComponent, {
			data: { hideMyAssets: true },
			height: "75vh",
			width: "60vw",
		});
		attachDialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((resp) => {
				if (resp) {
					switch (type) {
						case "email":
							this.campaign.EmailMediaAttachments.push(new CommMediaAttachment(resp));
							break;
						case "sms":
							this.campaign.SMSMediaAttachments.push(new CommMediaAttachment(resp));
							break;
					}
				}
			});
	}
	removeMediaFile(type, id) {
		switch (type) {
			case "email":
				this.campaign.EmailMediaAttachments = this.campaign.EmailMediaAttachments.filter((m) => m.mediaID !== id);
				break;
			case "sms":
				this.campaign.SMSMediaAttachments = this.campaign.SMSMediaAttachments.filter((m) => m.mediaID != id);
				break;
		}
	}

	onSubmit(silent = false): Promise<boolean> {
		return new Promise((resolve, reject) => {
			Object.keys(this.campaignForm.controls).forEach((c) => this.campaignForm.controls[c].markAsTouched());
			this.emailEditor.editor.exportHtml((data) => {
				//remove html encoding in links
				let html = data.html.replace(/&amp;/ig, '&');
				this.campaignForm.controls["emailContent"].setValue(html);
				this.campaignForm.controls["emailTemplate"].setValue(JSON.stringify(data.design));
				if (this.campaignForm.valid && this.listParams.validate()) {
					let campaign = new CommCampaign(this.campaignForm.value);
					//Get Priority
					campaign.phonePriority = this.communicationWays.indexOf(this.PHONE) + 1;
					campaign.smsPriority = this.communicationWays.indexOf(this.SMS) + 1;
					campaign.emailPriority = this.communicationWays.indexOf(this.EMAIL) + 1;
					campaign.EmailMediaItemIDs = this.campaign.EmailMediaAttachments.map((m) => m.mediaID);
					campaign.SMSMediaItemIDs = this.campaign.SMSMediaAttachments.map((m) => m.mediaID);
					campaign.ListParms = this.listParams.getFilters();
					// Use listControl value if available, otherwise fall back to form value
					campaign.listID = this.listControl.value?.listID || this.campaignForm.get('listID').value;
					if (this.wavFile) {
						this.uploadFileToAPI(this.wavFile, campaign, silent);
					} else {
						this.continueSubmittingCampaign(campaign, silent);
						resolve(true);
					}
				} else {
					this.store.dispatch(new SetErrorMessage("Check required fields"));
					reject(false);
				}
			});
		});
	}

	continueSubmittingCampaign(campaign, silent: boolean) {
		if (!this.isEdit) {
			this.createNewCampaign(campaign, silent);
		} else {
			this.editCampaign(campaign, silent);
		}
	}

	campaignInfoTabValid(): boolean {
		return this.campaignForm.controls["campaignName"].touched && this.campaignForm.controls["campaignName"].invalid;
	}

	listFilterTabValid(): boolean {
		return (this.campaignForm.controls["listID"].touched && this.campaignForm.controls["listID"].invalid) || !this.listParams.validate();
	}
	createNewCampaign(campaign, silent: boolean) {
		this.newCampaignSubscription = this.dataService.newCampaign(campaign).subscribe(
			(newCampaign: any) => {
				this.campaign.campaignID = newCampaign.campaignID;
				this.campaignID = newCampaign.campaignID;
				this.isEdit = true;
				this.store.dispatch(new SetActiveCampaign(this.campaignID));
				this._location.replaceState("/communication/campaigns/campaign-edit/" + this.campaignID);

				if (!silent) {
					this.zone.run(() => {
						let snackBarRef = this.snackBar.open("Campaign Created!", "Close", {
							duration: 3000,
						});
					});    
				}

			},
			(err) => {
				this.showError(err, "Campaign create error");
			}
		);
	}

	editCampaign(campaign, silent: boolean) {
		this.editCampaignSubscription = this.dataService.editCampaign(this.campaignID, campaign).subscribe(
			(c: any) => {
				if (!silent) {
					this.zone.run(() => {
						let snackBarRef = this.snackBar.open("Campaign Edited!", "Close", {
							duration: 3000,
						});
					})
				}
			},
			(err) => {
				this.showError(err, "Campaign edit error");
			}
		);
	}

	uploadFileToAPI(file, campaign, silent: boolean) {
		this.uploadFileSubscription = this.dataService.uploadWavFile(file).subscribe(
			(f: CommWavFile) => {
				campaign.phoneWavFileName = f.wavFileName;
				this.continueSubmittingCampaign(campaign, silent);
			},
			(err) => {
				this.store.dispatch(new SetErrorMessage("WAV Upload Failure: " + err.error.Message));
			}
		);
	}

	showError(err, title) {
		if (Array.isArray(err.error)) {
			err.error.forEach((e) => {
				this.store.dispatch(new SetErrorMessage(e.error));
			});
		} else {
			this.store.dispatch(new SetErrorMessage(err.error.ExceptionMessage));
		}
	}
	toggleValue(field: string, evt) {
		this.campaignForm.controls[field].setValue(evt.checked);
	}

	isEmailValid() {
		return (this.campaignForm.controls["emailSourceID"].untouched || this.campaignForm.controls["emailSourceID"].valid) && (this.campaignForm.controls["emailSubject"].untouched || this.campaignForm.controls["emailSubject"].valid) && (this.campaignForm.controls["emailContent"].untouched || this.campaignForm.controls["emailContent"].valid);
	}
	isSmsValid() {
		return (this.campaignForm.controls["smsSourceID"].untouched || this.campaignForm.controls["smsSourceID"].valid) && (this.campaignForm.controls["smsText"].untouched || this.campaignForm.controls["smsText"].valid);
	}
	isPhoneValid() {
		return this.campaignForm.controls["phoneSourceID"].untouched || this.campaignForm.controls["phoneSourceID"].valid;
	}
	priorityListDrop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.communicationWays, event.previousIndex, event.currentIndex);
	}

	checkSmsForUrl() {
		this.badSmsUrlPlacement = /(?!^)https?:\S*\s\S/.test(this.campaignForm.get('smsText').value);
	}

	private _filterLists(value: string): CommList[] {
		if (!value || value === '') return this.lists || [];
		const filterValue = value.toLowerCase();
		return this.lists?.filter(list => 
			list.listName.toLowerCase().includes(filterValue) || 
			(list.groupName && list.groupName.toLowerCase().includes(filterValue))
		);
	}

	displayListFn(list: CommList): string {
		return list ? `${list.groupName ? list.groupName + ' - ' : ''}${list.listName}` : '';
	}

	openAutoComplete() {
		if (!this.isSelecting) {
			// Keep the original filtering setup
			this.filteredLists = this.listControl.valueChanges.pipe(
				startWith(this.listControl.value),
				map(value => {
					const name = typeof value === 'string' ? value : value?.listName;
					return this._filterLists(name);
				})
			);
			
			setTimeout(() => {
				this.autocompleteTrigger.openPanel();
			});
		}
	}

	onOptionSelected(event: any) {
		this.listControl.setValue(event.option.value);
		this.campaignForm.patchValue({
			listID: event.option.value.listID
		});
		// Force blur to close panel
		const activeElement = document.activeElement as HTMLElement;
		if (activeElement) {
			activeElement.blur();
		}
	}
}

export namespace Communication {
	export enum Type {
		PHONE = 1,
		SMS = 2,
		EMAIL = 3,
	}
	export enum CampaignStatus {
			IN_EDIT = 1,
			SENDING = 2,
			SENT = 3,
	}
}

