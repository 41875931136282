
export class ProfilePhoto {
	public imageID: string;
	public imageFile: string;
	public imageURLPath: string;
	public imageModifiedURLPath: string;
	topLeftX: number;
	topLeftY: number;
	bottomRightX: number;
	bottomRightY: number;
	zoom: number;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}