<div class="container adminContainer">
		<div class="categories" *ngIf="mediaCategories$ | async" #categoriesListing [appSticky]="39" [appStickyStyle]="{overflow: 'auto', height: 'to-bottom', top: '118px', width: '330px'}">
			<ul>
				<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" #tree>
					<mat-tree-node *matTreeNodeDef="let cat" matTreeNodeToggle matTreeNodePadding>
						<li [draggable]="cat.readOnly ? null : true" (dragstart)="handleDragStart($event, cat);"
						(dragover)="handleDragOver($event, cat);"
						(drop)="handleDrop($event, cat);"
						(dragend)="handleDragEnd($event);" [ngClass]="{'drop-above': dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === cat, 'drop-below': dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === cat, 'drop-center': dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === cat}">
						<div class="mediaCategory" [ngClass]="{active: cat.mediaCategoryID == (activeCategory$ | async)?.mediaCategoryID, readonly: cat.readOnly}">
								<button mat-icon-button disabled></button> <!-- used to provide padding -->
								<div class="folder" (click)="activateCategory(cat)">
									<span *ngIf="cat.mediaCategoryID != (editingCategory$ | async)?.mediaCategoryID">{{cat.mediaCategoryDesc}}</span>
									<input *ngIf="cat.mediaCategoryID == (editingCategory$ | async)?.mediaCategoryID" [(ngModel)]="cat.mediaCategoryDesc" />
								</div>
								<div class="actions" *ngIf="!cat.readOnly">
									<span (click)="editCategory(cat)" *ngIf="cat.mediaCategoryID != (editingCategory$ | async)?.mediaCategoryID"><i class="fa fa-edit"></i></span>
									<span (click)="editCategoryConfirm()" *ngIf="cat.mediaCategoryID == (editingCategory$ | async)?.mediaCategoryID"><i class="fa fa-save"></i></span>
									<span (click)="deleteCategory(cat)"><i class="fa fa-times"></i></span>
								</div>
							</div>
						</li>
					</mat-tree-node>
					<mat-nested-tree-node *matTreeNodeDef="let cat; when: hasChild">
						<li>
							<div class="mat-tree-node mediaCategory" [ngClass]="{active: cat.mediaCategoryID == (activeCategory$ | async)?.mediaCategoryID, readonly: cat.readOnly}">
								<button mat-icon-button matTreeNodeToggle (click)="toggleOpen(cat)">
									<mat-icon cass="mat-icon-rtl-mirror">
										{{treeControl.isExpanded(cat) ? 'expand_more' : 'chevron_right'}}
									</mat-icon>
								</button>
								<div class="folder" (click)="activateCategory(cat)" [draggable]="cat.readOnly ? null : true" (dragstart)="handleDragStart($event, cat);"
									(dragover)="handleDragOver($event, cat);"
									(drop)="handleDrop($event, cat);"
									(dragend)="handleDragEnd($event);" 
									[ngClass]="{'drop-above': dragNodeExpandOverArea === 'above' && dragNodeExpandOverNode === cat, 'drop-below': dragNodeExpandOverArea === 'below' && dragNodeExpandOverNode === cat, 'drop-center': dragNodeExpandOverArea === 'center' && dragNodeExpandOverNode === cat}">
									<ng-container *ngIf="cat.mediaCategoryID != (editingCategory$ | async)?.mediaCategoryID">{{cat.mediaCategoryDesc}}</ng-container>
									<ng-container *ngIf="cat.mediaCategoryID == (editingCategory$ | async)?.mediaCategoryID"><input [(ngModel)]="cat.mediaCategoryDesc" /></ng-container>
								</div>
								<div class="actions" *ngIf="!cat.readOnly">
									<span (click)="editCategory(cat)" *ngIf="cat.mediaCategoryID != (editingCategory$ | async)?.mediaCategoryID"><i class="fa fa-edit"></i></span>
									<span (click)="editCategoryConfirm()" *ngIf="cat.mediaCategoryID == (editingCategory$ | async)?.mediaCategoryID"><i class="fa fa-save"></i></span>
									<span (click)="deleteCategory(cat)"><i class="fa fa-times"></i></span>
								</div>
							</div>
							<ul [class.open]="treeControl.isExpanded(cat)" >
								<ng-container matTreeNodeOutlet></ng-container>
							</ul>
						</li>
					</mat-nested-tree-node>
				</mat-tree>
				<li>
					<div class="mediaCategory addNew">
						<div class="folder">
							<input [(ngModel)]="newCategoryName" placeholder="Add New Folder" (keyup.enter)="addCategory()" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_media_text}}">
						</div>
						<div class="actions">
							<span (click)="addCategory()"><i class="fa fa-save"></i></span>
						</div>
					</div>
				</li>
			</ul>
 
		<!--
			<ul cdkDropList>
				<li>
					<div droppable class="mediaCategory topLevel" (onDrop)="drop($event)">Top Level</div>
				</li>
	
				<media-category-display [mediaCategories]="mediaCategories$ | async"></media-category-display>
	
				<li>
					<div class="mediaCategory addNew">
						<div class="folder">
							<input [(ngModel)]="newCategoryName"  [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_media_text}}">
						</div>
						<div class="actions">
							<span (click)="addCategory()"><i class="fa fa-save"></i></span>
						</div>
					</div>
				</li>
			</ul>
		-->
		</div>
	
		<div class="medias" *ngIf="medias$ | async">
			<button mat-flat-button color="accent" id="add-new-media" (click)="addMedia()" *ngIf="!activeMediaCategory.readOnly" >Add New</button><mat-icon class="media-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_media_new}}" >info_outline</mat-icon>
			<div class="mediasContainer">
				<div class="media" *ngFor="let media of medias$ | async" draggable="true" (dragstart)="handleMediaDragStart($event, media)" (dragend)="handleMediaDragEnd($event)" [ngClass]="{readonly: activeMediaCategory.readOnly}">
					<mat-card>
						<mat-card-title>{{media.mediaDesc}}</mat-card-title>
						<mat-card-content>
							<a [href]="media.savedFileName" target="_blank">
								<div class="image" [ngStyle]="{'background-image': 'url(' + media.imageFile + '?' + cachebust + ')'}"></div>
							</a>
						</mat-card-content>
						<mat-card-actions>
							<div class="mediaActions" *ngIf="!activeMediaCategory.readOnly">
								<button mat-flat-button color="primary" (click)="editMedia(media)">Edit</button>
								<button mat-stroked-button (click)="deleteMedia(media)" >Delete</button>
							</div>
						</mat-card-actions>
					</mat-card>
				</div>
			</div>
		</div>
	
	</div>
	