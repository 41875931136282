import { Component, OnInit, Input } from '@angular/core';
import { Text } from 'acc-models';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import * as RevisionDatesSelectors from '../../../store/selectors/revisiondates.selectors';
import * as RevisionDatesActions from '../../../store/actions/revisiondates.actions';

@Component({
  selector: 'acc-main-text-card',
  templateUrl: './text-card.component.html',
  styleUrls: ['./text-card.component.scss']
})
export class TextCardComponent implements OnInit {

	@Input() text: Text

	public editingText$ = this.store.pipe(
		select(RevisionDatesSelectors.selectEditingText)
	);

	constructor(private store: Store<IAppState>) { }

	ngOnInit() {
	}

	edit() {
		this.store.dispatch(new RevisionDatesActions.EditText(this.text));
	}
	save() {
		this.store.dispatch(new RevisionDatesActions.EditTextConfirm(this.text.revisionNeededDate));

	}
	cancel() {
		this.store.dispatch(new RevisionDatesActions.EditTextCancel());
	}
}
