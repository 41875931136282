
import { Component, OnChanges, SimpleChanges, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { LibraryItem } from 'acc-models';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'acc-common-library-content-files',
	templateUrl: './library-content-files.component.html'
})

export class LibraryContentFilesComponent implements OnInit, OnDestroy, OnChanges {
	
	//files: Array<LibraryItem>;
	@Input() folderType: string;
	@Input() showFileActions: boolean = true;
	@Output() onAction: EventEmitter<{actionName: string, payload: any}> = new EventEmitter();

	@Input() medias: Array<LibraryItem>;
	public openLibraryItemId:string;
	
	constructor () {
	}

	setContent(file, evt) {
		evt.target.innerText = file.shortDescription;
	}
	renameFile(file: LibraryItem, elem, highlightNext = false) {
		if(elem.target.innerText != file.shortDescription) {
			elem.target.classList.remove('error');
			elem.target.classList.add('saving');
			const saveComplete = new Subject();
			saveComplete.asObservable().pipe(
				take(1)
			).subscribe((resp:any) => {
				if(resp.success == true) {
					elem.target.classList.remove('saving');
					file.shortDescription = elem.target.innerText;
					elem.target.innerText = resp.name;
				} else {
					this.resetFile(file, elem);
					elem.target.classList.remove('saving');
					elem.target.classList.add('error');
				}
			})
			this.onAction.emit({ actionName: 'rename', payload: { file, newName: elem.target.innerText, onComplete: saveComplete }})
			if(highlightNext) {
				const nextOne = elem.target.parentElement.parentElement.nextElementSibling;
				if(nextOne) {
					const nextTitle = nextOne.querySelectorAll('div[contenteditable]')[0];
					setTimeout(() => { nextTitle.focus(); }, 1);
				}
			}			
		}
		elem.target.blur();
	}
	resetFile(file: LibraryItem, elem) {
		elem.target.innerText = file.description;
		elem.target.blur();
	}
	getBackgroundImageUrl(path) {
		return `url('${path}')`;
	}

	showPreview(mediaItemId:string) {
		//TODO: make sure this doesn't get caught by popup blocker
		this.onAction.emit({actionName: 'showPreview', payload: { mediaItemId }})
	}
	action(evt: { actionName: string, payload: any }) {
		if(evt.actionName == 'expand') {
			this.openLibraryItemId = evt.payload.id;
		} else {
			this.openLibraryItemId = null; //close the menu if they perform  an action
		}
		this.onAction.emit(evt);
	}
	routeTo(path:Array<string>) {
		this.onAction.emit({actionName: 'routeTo', payload: path })
	}
	ngOnInit():void {

	}
	ngOnChanges(changes:SimpleChanges) {
		if(changes['medias'] && changes['medias'].currentValue) {
			this.medias = changes['medias'].currentValue;
		}
		if(changes.showFileActions && changes.showFileActions.currentValue) {
			this.showFileActions = changes.showFileActions.currentValue;
		}
	}
	ngOnDestroy():void {

	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.medias, event.previousIndex, event.currentIndex);
		//const newOrder = this.medias.map((c,i) => { return { ID: c.id, sortOrder:  i + 1 }; });
		const newOrder = this.medias.map(c => c.description);
	}
}