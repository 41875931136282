import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { DataService, SessionService } from "acc-services";
import { OrgProfileComponent } from "../../shared/org-profile/org-profile.component";
import { ConfirmComponent, ProfilePhotoComponent, tooltips } from "acc-common";
import { SignupCompany } from "projects/acc-models/src/lib/models/signUp";
import { ProfilePhoto, User } from "acc-models";
import { take, tap } from "rxjs/operators";
import { defer, forkJoin, Observable, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

@Component({
	selector: "acc-main-programs-setup",
	templateUrl: "./programs-setup.component.html",
	styleUrls: ["./programs-setup.component.scss"],
})
export class ProgramsSetupComponent implements OnInit {
	@ViewChild("stepper") stepper: MatStepper;
	@ViewChild("orgProfile") orgProfile: OrgProfileComponent;
	@ViewChild("logoPhoto") logoPhoto: ProfilePhotoComponent;

	public mktPrograms: any[] = [];
	public origState: Array<{ id: number; days: number; selected: boolean }>;
	public orgID: number;
	private user: User;

	private origProfile: any;
	private newProfile: any;
	public imageFileTypes = [".jpg", ".jpeg", ".png", ".gif"];
	public logo;
	public tooltips = tooltips;

	public companyName: string;
	public company: SignupCompany;

	constructor(private sessionService: SessionService, private snackBar: MatSnackBar, private ngZone: NgZone, private dataService: DataService, private dialog: MatDialog) {}

	ngOnInit() {
		this.user = this.sessionService.getUser();
		this.orgID = this.user.orgID;
		this.dataService.getCompanyInformation(this.orgID).subscribe((resp: any) => {
			this.company = resp;
			this.companyName = resp.orgDesc;
			this.logo = resp.photo;
		});
	}

	setOriginalProgramsState(programs) {
		this.origState = programs.map((p) => ({ id: p.id, days: p.days, selected: p.selected }));
	}
	setOriginalProfile(profile) {
		if(!!profile){
			this.origProfile = profile;
			this.origProfile.companyName = this.companyName;
		}
	}

	finishProfile(){
		this.doSaveProfile().then( value => {
			this.stepper.next();
			this.setOriginalProfile(this.newProfile);
		});
	}

	saveProfile(){
		this.doSaveProfile().then( value => {
			this.snackBar.open("Your company information has been saved", "OK", { duration: 3000 });
		});
	}

	private doSaveProfile() {
		return new Promise((resolve, reject) => {
			let observableBatch = [];
			observableBatch.push(of(true));
			const logoUpload = this.logoPhoto.getData();
			if (logoUpload.file) {
				//save logo image file
				observableBatch.push(
					this.dataService.uploadProfileImage(logoUpload.file, logoUpload.photo).pipe(
						tap((img: ProfilePhoto) => {
							this.company.photoID = img.imageID;
							this.company.photo = img;
						})
					)
				);
			} else {
				if (logoUpload.photo) {
					observableBatch.push(this.dataService.updateProfileImage(logoUpload.photo));
				} else {
					this.company.photoID = null;
				}
			}
			observableBatch.push(defer(() => this.orgProfile.save()));
			forkJoin(observableBatch).subscribe(
				() => {
					this.dataService.saveCompanyInformation(this.user.orgID, { ...this.company, orgDesc: this.companyName }).subscribe(() => {
						resolve(true);
					}, () => {
						this.snackBar.open("There was a problem saving your data", "OK", { duration: 3000 });
					});
				},
				() => {
					this.snackBar.open("There was a problem saving your data", "OK", { duration: 3000 });
				}
			);
		});
	}

	saveSelectingPrograms(){
		this.dataService.saveMktPrograms(this.mktPrograms).subscribe((x: any) => {
			this.setOriginalProgramsState(this.mktPrograms); //reset programs
			this.snackBar.open("Your programs information has been saved", "OK", { duration: 3000 });
		});
	}

	finishSelectingPrograms() {
		this.dataService.saveMktPrograms(this.mktPrograms).subscribe((x: any) => {
			this.setOriginalProgramsState(this.mktPrograms); //reset programs
			this.ngZone.run(() => {
				this.stepper.selected.completed = true;
				//this.stepper.selected.editable = false;
				this.stepper.next();
			});
		});
	}

	isDirty() {
		switch (this.stepper.selectedIndex) {
			case 0:
				return this.programsChanged();
			case 1:
				return this.profileChanged();
			default:
				return false;
		}
	}
	programsChanged() {
		return !this.mktPrograms.reduce((a, p) => {
			return a && this.origState.some((o) => o.id == p.id && o.days == p.days && o.selected == p.selected);
		}, true);
	}
	profileChanged() {
		this.newProfile = {
			email: this.orgProfile.emailField.value,
			phone: this.orgProfile.phoneField.value,
			website: this.orgProfile.websiteField.value,
			address1: this.orgProfile.address1Field.value,
			address2: this.orgProfile.address2Field.value,
			city: this.orgProfile.cityField.value,
			state: this.orgProfile.stateField.value,
			zip: this.orgProfile.zipField.value,
			companyName: this.companyName
		}
		return !(this.company.orgDesc == this.companyName
			&& this.origProfile.email == this.newProfile.email
			&& this.origProfile.phone == this.newProfile.phone
			&& this.origProfile.website == this.newProfile.website
			&& this.origProfile.address1 == this.newProfile.address1
			&& this.origProfile.address2 == this.newProfile.address2
			&& this.origProfile.city == this.newProfile.city
			&& this.origProfile.state == this.newProfile.state
			&& this.origProfile.zip == this.newProfile.zip)
	}


	canNavigateAway(): Observable<boolean> | boolean {

		const confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `You have unsaved changes.  Are you sure you want to exit without saving?`,
			},
		});
		return confirmDialogRef.afterClosed().pipe(
			take(1)
	   );
	}

  backToFirstStep(e){
    this.stepper.selectedIndex = 0;
  }
}
