
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { DataService, SessionService, DocGenService } from 'acc-services';
import { ModuleField, SubCategory, Document, Button, DocumentOutline, Attachment } from 'acc-models';
import { Subject, Observable, forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store/state/app.state';
import { SubscriptionLike } from 'rxjs';
import { SetErrorMessage } from '../../../../store/actions/status.actions';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmComponent } from 'acc-common';

@Component({
	selector: 'acc-main-module-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss', '../../../library/library.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FormComponent implements OnInit, OnDestroy {
	@Input() public moduleID: number;
	@Input() public document: Document;
	@Input() public subCategory: SubCategory;
	@Input() public docOutline: DocumentOutline;
	@Output() public nextCategory:EventEmitter<SubCategory> = new EventEmitter<SubCategory>();
	@Output() goBack:EventEmitter<boolean> = new EventEmitter();
	@Output() canNavigateAway:Subject<boolean> = new Subject<boolean>();
	
	public activeField:ModuleField;

	@ViewChild('form', { static: true }) formFields:NgForm;
	@ViewChild('newSubcatNameField') newSubcatNameField:ElementRef;


	public moduleFields: Array<ModuleField>;
	public showSavePrompt: boolean = false;
	public showConfirmRevertPrompt: boolean = false;
	public saving: boolean = false;
	public continuing: boolean = false;
	public closing: boolean = false;
	public errorMessage:string = '';
	public categoryCount:number = 0;
	public isTemplate: boolean = false;
	private confirmDialogRef: MatDialogRef<ConfirmComponent>;
	//public buttons:Array<string> = new Array<string>();
	public buttons:Array<Button> = new Array<Button>();
	private subscriptions: Array<SubscriptionLike> = new Array<SubscriptionLike>();

	constructor (private dataService:DataService, private sessionService: SessionService, private docgenService: DocGenService, private router: Router, private route: ActivatedRoute, private store: Store<IAppState>, private dialog:MatDialog, public snackbar: MatSnackBar) {

	}

	public get dirty() { return window.document.getElementsByClassName('ng-dirty').length > 0; }
	
	ngOnInit(): void {
		this.subscriptions.push(this.docgenService.activeCategoryObservable.subscribe(cat => {
			this.subscriptions.push(this.dataService.getText(this.document.docID, cat).subscribe((txt:any) => {
				this.moduleFields = txt.map(t => new ModuleField(t));
				this.moduleFields.forEach(f => {
					forkJoin({
						textAtt: this.dataService.getTextAttachments(this.document.docID, f.textID),
						mediaAtt: this.dataService.getTextAttachmentsMedia(this.document.docID, f.textID)
					}).subscribe({
						next: (value: any) => {
							f.attachments = f.attachments.concat(value.textAtt.map(a => { return {...a, type: 'file'}}));
							f.attachments = f.attachments.concat(value.mediaAtt.map(a => { return {...a, type: 'media'}}));
							f.attachments.sort((a,b) => {
								if(a.sortOrder > b.sortOrder) return 1;
								if(a.sortOrder < b.sortOrder) return -1;
								return 0;
							})
						}
					})
					/*
					this.dataService.getTextAttachments(this.document.docID, f.textID).subscribe((att:any) => {
						f.attachments = f.attachments.concat(att.map(a => { return {...a, type: 'file'}}));
					})
					this.dataService.getTextAttachmentsMedia(this.document.docID, f.textID).subscribe((att:any) => {
						f.attachments = f.attachments.concat(att.map(a => { return {...a, type: 'media'}}));
					})
					*/
				})
			}))
		}))
		this.subscriptions.push(this.docgenService.tryChangeCategory$.subscribe(res => {
			this.tryChangingCategory();
		}))
		this.subscriptions.push(this.docgenService.categoryCount$.subscribe(count => this.categoryCount = count));
		this.buttons.push(new Button('Yes', 'orange'));
		this.buttons.push(new Button('No', 'orange'));
		this.route.paramMap.subscribe(params => {
			if(params.get('mode') == 'template') {
				this.isTemplate = true
			}
		})
	}

	close():void {
		if(this.dirty) {
			this.closing = true;
			let observableBatch = [];
			this.moduleFields.forEach(f => {
				observableBatch.push(this.dataService.saveText(f, this.document.docID));
			});

			this.subscriptions.push(forkJoin(observableBatch).subscribe(resp => {
				this.closing = false;
				this.showSavePrompt = false;
				this.router.navigateByUrl('/welcome');
			}))
		} else {
			this.router.navigateByUrl('/welcome');
		}
	}
	revert(): void {
		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				message: 'Your content in all text boxes in the current category will be removed and replaced with the default text.  Proceed?',
				title: 'Confirm',
				buttons: this.buttons
			}
		});
		this.confirmDialogRef.afterClosed().subscribe(resp => {
			if(resp) {
				this.doRevert();
			}
		})
	}
	doRevert():void {
		if(this.categoryCount == 0) return;
		this.moduleFields.forEach(f => {
			f.text = f.defaultText;
		})
		this.save(true);
	}

	/*
	save(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
				data: {
					message: 'Are you sure you want to save your changes?',
					title: 'Confirm',
					buttons: this.buttons
				}
			});
			this.confirmDialogRef.afterClosed().subscribe(resp => {
				if(resp) {
					this.doSave().then(resp => resolve());
				} else {
					reject();
				}
			})
		})
	}*/
	save(silent: boolean = false): Promise<any> {
		return new Promise((resolve, reject) => {
			if(this.categoryCount == 0) { resolve(); }
			if(this.moduleFields.length == 0) { resolve(); }
			this.saving = true;
			let observableBatch = [];
			this.moduleFields.forEach(f => {
				observableBatch.push(this.dataService.saveText(f, this.document.docID));
			});
	
			this.subscriptions.push(forkJoin(observableBatch).subscribe(resp => {
				this.saving = false;
	
				//mark dirty elements as clean.
				let dirty = window.document.getElementsByClassName('ng-dirty');
				[].forEach.call(dirty, el => {
					el.classList.remove('ng-dirty');
					el.classList.add('ng-pristine');
				})
				if(!silent) {
					this.snackbar.open('Your changes have been saved', 'OK', { duration: 3000 })
				}
				resolve();
			}))
		})

	}
	continue(): void {
		if(this.categoryCount == 0) return;
		if(this.dirty) {
			this.continuing = true;
			let observableBatch = [];
			this.moduleFields.forEach(f => {
				observableBatch.push(this.dataService.saveText(f, this.document.docID));
			});

			this.subscriptions.push(forkJoin(observableBatch).subscribe(resp => {
				this.continuing = false;
				this.showSavePrompt = false;
				this.nextCategory.emit(this.subCategory);
			}))
		} else {
			this.nextCategory.emit(this.subCategory);
		}
	}

	preview() {
		if(this.categoryCount == 0) return;
		let previewWindow = window.open('/loading');
		if(this.dirty) {
			this.saving = true;
			let observableBatch = [];
			this.moduleFields.forEach(f => {
				observableBatch.push(this.dataService.saveText(f, this.document.docID));
			});

			this.subscriptions.push(forkJoin(observableBatch).subscribe(resp => {
				this.showSavePrompt = false;
				this.saving = false;
				this.showPdf(previewWindow);

			}))
		} else {
			this.showPdf(previewWindow);
		}
	}

	showPdf(previewWindow) {
		this.dataService.createPdf(this.document.docID).subscribe((pdf:any) => {
			if(previewWindow) {
				this.errorMessage = '';
				previewWindow.location.href = pdf.url;
			} else {
				this.errorMessage = 'A popup blocker may have prevented showing the document.  Please disable popup blockers and try again.';
			}
			
		}, error => {
			let errorMessage = 'There was a problem generating the Preview file: ' +error.error.Message;
			this.store.dispatch(new SetErrorMessage(errorMessage))
			if(previewWindow) {
				previewWindow.close();
			}
		})

	}
	editOutline() {
		this.router.navigate(['/edit', this.moduleID, this.document.docID])
	}
	tryChangingCategory():void {
		if(this.dirty) {
			this.continuing = true;
			let observableBatch = [];
			this.moduleFields.forEach(f => {
				observableBatch.push(this.dataService.saveText(f, this.document.docID));
			});

			this.subscriptions.push(forkJoin(observableBatch).subscribe(resp => {
				this.continuing = false;
				this.showSavePrompt = false;
				this.docgenService.canChangeCategory(true);
			}))
		} else {
			this.docgenService.canChangeCategory(true);
		}
	}	
	public tryNavigatingAway(): void {
		if(this.dirty) {
			this.closing = true;
			let observableBatch = [];
			this.moduleFields.forEach(f => {
				observableBatch.push(this.dataService.saveText(f, this.document.docID));
			});

			this.subscriptions.push(forkJoin(observableBatch).subscribe(resp => {
				this.closing = false;
				this.showSavePrompt = false;
				this.canNavigateAway.next(true);
				this.canNavigateAway.complete();
			}))
		} else {
			this.showSavePrompt = false;
			this.closing = false;
			this.canNavigateAway.next(true);
			this.canNavigateAway.complete();
		}

	}
	onGoBack() {
		this.goBack.emit(true);
	}

	closeDialog(value) {
		this.showSavePrompt = false;
	}

	closeConfirmRevertDialog(value) {
		if(value == 0) {
			this.doRevert();
		}
		this.showConfirmRevertPrompt = false;
	}
	ngOnDestroy():void {
		for(let sub of this.subscriptions) {
			sub.unsubscribe();
		}
	}

	changeSubcatName() {
		this.dataService.updateSubcatName(this.document.docID, this.subCategory.subCategoryID, this.newSubcatNameField.nativeElement.value).subscribe(resp => {
			if(this.newSubcatNameField) {
				//need this, because if they blur by navigating away, this won't exist any more when they api finishes
				this.subCategory.subCategoryDesc = this.newSubcatNameField.nativeElement.value;
			}
			this.docgenService.renameSubcat(this.subCategory)
		})
	}

	focusSubcatName(): void {
		this.newSubcatNameField.nativeElement.focus();
	}

}
