import { Directive, ElementRef, Output, EventEmitter, AfterViewInit, Renderer2 } from "@angular/core";

@Directive({
	selector: "[appPopup]"
})
export class PopupDirective implements AfterViewInit {
	@Output() close: EventEmitter<number> = new EventEmitter();
	
	constructor(private renderer: Renderer2, private el: ElementRef) {
	}
	ngAfterViewInit() {
		let elem = this.el.nativeElement;
		elem.classList.add('popup');
		let headerWrapper = this.renderer.createElement("div");
		this.renderer.addClass(headerWrapper, 'popup-header-wrapper');
		let closeButton = this.renderer.createElement("div");
		this.renderer.addClass(closeButton, "popup-close");
		closeButton.innerHTML = "X";
		closeButton.addEventListener("click", () => this.close.next(0));
		this.renderer.appendChild(headerWrapper, closeButton);
		this.renderer.appendChild(elem, headerWrapper);

		let wrapper = this.renderer.createElement("div");
		this.renderer.addClass(wrapper, "popup-content");
		elem.append(wrapper);
		this.renderer.appendChild(wrapper, this.el.nativeElement.firstChild);
		this.renderer.appendChild(elem, wrapper);
	}
}
