<form [formGroup]="alertForm" class="quote-alert-form">

  <div class="quote-alert-form--message">
    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea matInput placeholder="" formControlName="message"></textarea>
    </mat-form-field>
  </div>

  <div class="quote-alert-form--list" formArrayName="alerts">
    <div *ngFor="let a of alertForm.get('alerts')['controls']; let j=index" >
      <div class="quote-alert-threshold-row"  [formGroupName]="j">
        <mat-form-field appearance="outline" class="quote-alert-form--list-carrier">
          <mat-label>Carrier</mat-label>
          <mat-select formControlName="carrierID" (selectionChange)="selectedCarrierChanged($event)">
            <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">{{carrier.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field appearance="outline" class="quote-alert-form--list-threshold">
          <mat-label>Favorable up to</mat-label>
          <mat-select formControlName="thresholdID" >
            <mat-option *ngFor="let favUpTo of alert.favorableUpTo" [value]="favUpTo.thresholdID">{{favUpTo.thresholdDesc}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-flat-button class="delete-button" (click)="delete(j)"><i class="material-icons">clear</i></button>
      </div>
    </div>
    <div class="add-new-button-container">
      <button (click)="add()" mat-flat-button class="add-new-button">Add New</button>
    </div>
  </div>
</form>
