
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { DataService, SessionService, DocGenService } from 'acc-services';
import { User, DocumentOutline, Category, SubCategory } from 'acc-models';
import { Router } from '@angular/router';
import { SubscriptionLike } from 'rxjs';

@Component({
	selector: 'acc-main-module-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit, OnDestroy {
	@Input() public moduleID: number;
	@Input() public documentId: string;
	@Input() public docOutline: DocumentOutline;
	@Output() public subCatSelected:EventEmitter<SubCategory> = new EventEmitter<SubCategory>();
	@HostListener('document:click', ['$event']) onbodyclick($event) {
		this.categoryMenuOpen = false;
		this.subcategoryMenuOpen = false;
	}
	public categories: Array<Category>;
	public subcategories: Array<SubCategory>;
	public currentCategory:Category;
	public currentSubcategory: SubCategory;
	public categoryOpen:boolean = true;
	private getCategoriesForModuleSubscription:SubscriptionLike;
	private canChangeCategorySubscription:SubscriptionLike;
	private getDocOutlineSubscription:SubscriptionLike;
	private categoryMenuOpen:boolean = false;
	private subcategoryMenuOpen:boolean = false;
	public searchTerm:string;
	private user:User;

    constructor (private dataService:DataService, private sessionService: SessionService, private docgenService:DocGenService, private router:Router) {
        this.categories = this.sessionService.getItem<Array<Category>>('categories');
	}
	
	private tryChangeCategory(type:string, cat:any) {
		if(this.currentCategory.categoryID == cat.categoryID) {
			this.categoryOpen = !this.categoryOpen;
		} else {
			this.canChangeCategorySubscription = this.docgenService.canChangeCategory$.subscribe(res => {
				if(res == true) {
					this.categoryOpen = true;
					if(type == 'category') {
						this.showSubcategories(cat);
					} else {
						this.getItems(cat);
					}
					window.scroll(0,0);
				}
				this.canChangeCategorySubscription.unsubscribe();
			});
			this.docgenService.tryChangeCategory();
		}
	}
	private showSubcategories(category:Category) {
		this.currentCategory = category;
		this.subcategories = this.categories.filter(c => c.categoryID == category.categoryID)[0].SubCategories;
		if(this.subcategories.length > 0) {
			this.currentSubcategory = this.subcategories[0];
			this.getItems(this.subcategories[0]);
		}
	}
    private getItems(subCategory) {
		this.currentSubcategory = subCategory;
		this.docgenService.selectCategory(this.currentSubcategory.subCategoryID);
		this.subCatSelected.emit(this.subcategories.filter(s => s.subCategoryID == subCategory.subCategoryID)[0]);
	}
	goToNextCategory(subCat:SubCategory):void {
		let sIdx = this.subcategories.findIndex(i => i.subCategoryID == subCat.subCategoryID);
		if(sIdx < this.subcategories.length -1) {
			this.getItems(this.subcategories[sIdx+1])
		} else {
			let cIdx = this.categories.findIndex(i => i.categoryID == this.currentCategory.categoryID);
			if(cIdx < this.categories.length - 1){
				this.showSubcategories(this.categories[cIdx+1]);
			}
		}
	}
	expandCategory(e) {
		this.categoryMenuOpen = !this.categoryMenuOpen;
		e.cancelBubble = true;
	}
	expandSubcategory(e) {
		this.subcategoryMenuOpen = !this.subcategoryMenuOpen;
		e.cancelBubble = true;
	}

	doSearch() {
		if(this.searchTerm.trim().length == 0) {
			this.categories.forEach(c => {
				c.SubCategories.forEach(s => {
					s.foundInSearch = false;
				})
				c.foundInSearch = false;
			});			
			return;
		}
		this.categories.forEach(c => {
			c.SubCategories.forEach(s => {
				s.foundInSearch = s.subCategoryDesc.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
			})
			c.foundInSearch = (c.categoryDesc.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) || (c.SubCategories.filter(s => s.foundInSearch).length > 0);
		});
	}
	checkIfSearch(event) {
		if(event.keyCode == 13) {
			this.doSearch();
		}
	}
    ngOnInit(): void {
		this.user = this.sessionService.getUser();
        this.getCategoriesForModuleSubscription = this.dataService.getCategoriesForModule(this.moduleID, this.user.userID, this.documentId).subscribe((cats:any) => {
			this.categories = cats;
			this.docgenService.setCategoryCount(cats.length);
			if(cats.length > 0) {
				this.sessionService.setItem('categories', cats);
				this.currentCategory = this.categories[0];
				this.subcategories = cats[0].SubCategories;
				this.currentSubcategory = this.subcategories[0];
				this.subCatSelected.emit(this.subcategories[0]);
				if(this.subcategories.length > 0) {
					this.docgenService.selectCategory(this.subcategories[0].subCategoryID);
				}
			} else {
				this.documentId = this.documentId || this.docgenService.document.docID;
				this.router.navigate(['/edit', this.moduleID, this.documentId]); 
			}
		})

		this.docgenService.subcatRenamed$.subscribe(subcat => {
			const renamedSubcat = this.findSubcat(subcat.subCategoryID);
			if(renamedSubcat) {
				renamedSubcat.subCategoryDesc = subcat.subCategoryDesc;
			}
		})
	}
	findSubcat(id:number):SubCategory {
		this.categories.forEach(c => {
			c.SubCategories.forEach( s => {
				if(s.subCategoryID == id) { return s; }
			})
		})
		return null;
	}

	ngOnDestroy():void {
		if(this.getCategoriesForModuleSubscription) { this.getCategoriesForModuleSubscription.unsubscribe(); }
		if(this.canChangeCategorySubscription) { this.canChangeCategorySubscription.unsubscribe();}
		if(this.getDocOutlineSubscription) { this.getDocOutlineSubscription.unsubscribe(); }
	}

}
