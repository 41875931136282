import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CurrentSection } from '../health-details.component';

@Component({
  selector: 'acc-main-health-conditions-form',
  templateUrl: './health-conditions-form.component.html',
  styleUrls: ['./health-conditions-form.component.scss']
})
export class HealthConditionsFormComponent implements OnInit {
  public everUseTobaccoProducts: boolean;
  @Input() healthConditions: FormGroup;
  @Input() selectedSection: CurrentSection;
  @Input() heights: Array<any>;
  @Input() lastUseOptions: Array<any>;
  @Input() typesOfTobacco: Array<any>;

  private heightWasChosen: boolean = false;

  constructor() { }

  ngOnInit() {
    this.watchEverUseTobaccoChange();
  }

  watchEverUseTobaccoChange() {
    this.healthConditions.get('tobaccoUse').get('everUseTobacco').valueChanges.subscribe(value => {
      this.everUseTobaccoProducts = value === 'true';
      if (!this.everUseTobaccoProducts) {
        this.healthConditions.get('tobaccoUse').get('lastUserTobacco').setValue('');
        this.healthConditions.get('tobaccoUse').get('kindOfTobacco').setValue('');
        this.healthConditions.get('tobaccoUse').get('howMany').setValue('');
      }
    });
  }
  onHeightOpened(opened) {
	if(opened && !this.healthConditions.get('physicalBuild').get('height').value) {
		document.querySelector('span.h5_6').scrollIntoView();
	}
	if(!opened && !this.heightWasChosen) {
		this.healthConditions.get('physicalBuild').get('height').setValue("");
	} 
}
}
