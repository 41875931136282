import { BasicHealthProfile } from './basicHealthProfile';

export class BasicHealthProfileResponse {
    public result: Array<BasicHealthProfileResult>;
	public input: BasicHealthProfile;
    public error: any;
    public requestId: string;

    constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class BasicHealthProfileResult {
    faceAmount: number;
    carrierId: number;
    healthClass: number;
    tableRating: number;
    healthClassCode: string;

    constructor(o?: any) {
		Object.assign(this, o);
	}
}
