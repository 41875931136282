import { Action } from "@ngrx/store";

export enum StatusActionTypes {
	SET_ERROR_MESSAGE = "[STATUS] Set Error",
	CLEAR_ERROR_MESSAGE = "[STATUS] Clear Error",
	SET_SUCCESS_MESSAGE = "[STATUS] Set Success",
	CLEAR_SUCCESS_MESSAGE = "[STATUS] Clear Success",

	SHOW_GETTING_STARTED = "[STATUS] - Show Getting Started",
	HIDE_GETTING_STARTED = "[STATUS] - Hide Getting Started",

	SHOW_TRIAL_FEATURE = "[STATUS] - Show Trial Feature",
	HIDE_TRIAL_FEATURE = "[STATUS] - Hide Trial Feature",
	SET_DAYS_LEFT = "[STATUS] - Set Days Left",
	SET_CC_ENTERED = "[STATUS] CC Entered",

	SHOW_CLASSIC_VIEW = "[STATUS] - Show Classic View",
	SHOW_DEFAULT_VIEW = "[STATUS] - Show Default View",

	RESET = "[ROOT] Reset",
}

export class SetErrorMessage implements Action {
	public readonly type = StatusActionTypes.SET_ERROR_MESSAGE;
	constructor(public errorMessage: string) {}
}

export class ClearErrorMessage implements Action {
	public readonly type = StatusActionTypes.CLEAR_ERROR_MESSAGE;
	constructor() {}
}

export class SetSuccessMessage implements Action {
	public readonly type = StatusActionTypes.SET_SUCCESS_MESSAGE;
	constructor(public successMessage: string) {}
}

export class ClearSuccessMessage implements Action {
	public readonly type = StatusActionTypes.CLEAR_SUCCESS_MESSAGE;
	constructor() {}
}
export class StatusReset implements Action {
	public readonly type = StatusActionTypes.RESET;
	constructor() {}
}

export class ShowGettingStarted implements Action {
	public readonly type = StatusActionTypes.SHOW_GETTING_STARTED;
	constructor() {}
}

export class HideGettingStarted implements Action {
	public readonly type = StatusActionTypes.HIDE_GETTING_STARTED;
	constructor() {}
}
export class ShowTrialFeature implements Action {
	public readonly type = StatusActionTypes.SHOW_TRIAL_FEATURE;
	constructor() {}
}

export class HideTrialFeature implements Action {
	public readonly type = StatusActionTypes.HIDE_TRIAL_FEATURE;
	constructor() {}
}
export class SetDaysLeft implements Action {
	public readonly type = StatusActionTypes.SET_DAYS_LEFT;
	constructor(public daysLeft: number) {}
}

export class SetCcEntered implements Action {
	public readonly type = StatusActionTypes.SET_CC_ENTERED;
	constructor(public ccEntered: boolean) {}
}

export class ShowClassicView implements Action {
	public readonly type = StatusActionTypes.SHOW_CLASSIC_VIEW;
	constructor() {}
}

export class ShowDefaultView implements Action {
	public readonly type = StatusActionTypes.SHOW_DEFAULT_VIEW;
	constructor() {}
}


export type StatusActions = 
	SetErrorMessage | 
	ClearErrorMessage | 
	SetSuccessMessage | 
	ClearSuccessMessage | 
	StatusReset | 
	ShowGettingStarted | 
	HideGettingStarted | 
	ShowTrialFeature | 
	HideTrialFeature | 
	SetDaysLeft | 
	SetCcEntered |
	ShowClassicView |
	ShowDefaultView;
