<ng-container [formGroup]="healthConditions" [ngSwitch]="selectedSection.section">
  <div class="physical-build__container" *ngSwitchCase="'Physical Build'" [formGroupName]="'physicalBuild'">
    <mat-form-field appearance="outline" class="physical-build__height animated fadeIn" *ngIf="heights">
      <mat-label>Height</mat-label>
      <mat-select [formControlName]="'height'" (openedChange)="onHeightOpened($event)" (selectionChange)="heightWasChosen = true">
        <mat-option></mat-option>
        <mat-option *ngFor="let height of heights" [value]="height.code"><span class="h{{height.code.replace('\'','_')}}">{{height.name}}</span></mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="physical-build__weight animated fadeIn">
      <mat-label>Weight</mat-label>
      <input matInput [formControlName]="'weight'" placeholder="lbs" type="number" min="0" max="500" />
    </mat-form-field>
  </div>

  <div class="tobacco-use__container animated fadeIn" *ngSwitchCase="'Tobacco Use'" [formGroupName]="'tobaccoUse'">
    <div class="tobacco-use__ever-used-tobacco animated fadeIn">
      <mat-label>Has your client ever used any tobacco product(s)?</mat-label>
      <mat-radio-group [formControlName]="'everUseTobacco'">
        <mat-radio-button aria-label="Use Tobacco Products" value="true">Yes</mat-radio-button>
        <mat-radio-button aria-label="Never Use Tobacco Products" value="false">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <ng-container *ngIf="everUseTobaccoProducts|| healthConditions.get('tobaccoUse').get('everUseTobacco').value == 'true'">
      <mat-form-field appearance="outline" class="tobacco-use__last-use-tobacco animated fadeIn" *ngIf="lastUseOptions">
        <mat-label>When did they last use tobacco?</mat-label>
        <mat-select [formControlName]="'lastUserTobacco'">
          <mat-option></mat-option>
          <mat-option *ngFor="let option of lastUseOptions" [value]="option.code">{{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="tobacco-use__kind-of-tobacco animated fadeIn" *ngIf="typesOfTobacco">
        <mat-label>What kind of tobacco or nicotine?</mat-label>
        <mat-select [formControlName]="'kindOfTobacco'">
          <mat-option></mat-option>
          <mat-option *ngFor="let type of typesOfTobacco" [value]="type.code">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="tobacco-use__how-many-per-day animated fadeIn"
        *ngIf="healthConditions.get('tobaccoUse').get('kindOfTobacco').value">
        <mat-label
          *ngIf="healthConditions.get('tobaccoUse').get('kindOfTobacco').value === 'C'; else elseTemplate">
          How many per day?</mat-label>
        <ng-template #elseTemplate>
          <mat-label>How many per year?</mat-label>
        </ng-template>
        <input matInput type="number" [formControlName]="'howMany'" min="0" max="500" />
      </mat-form-field>
    </ng-container>
  </div>

  <div class="blood-pressure__container animated fadeIn" *ngSwitchCase="'Blood Pressure'" [formGroupName]="'bloodPressure'">
    <div class="blood-pressure__ever-use-medications multiple-radio-btns">
      <mat-label>Does client take blood pressure medications?</mat-label>
      <mat-radio-group [formControlName]="'everUseBloodPressureMedication'">
        <mat-radio-button aria-label="Currently Use Blood Pressure Medication" value="currently">
          Currently Use</mat-radio-button>
        <mat-radio-button aria-label="Used in past Blood Pressure Medication" value="past">
          Used In Past</mat-radio-button>
        <mat-radio-button aria-label="Never Use Blood Pressure Medication" value="never">
          Never Prescribed or Taken</mat-radio-button>
      </mat-radio-group>
    </div>
    <span class="blood-pressure__label">Last Readings:</span>
    <mat-form-field appearance="outline" class="blood-pressure__systolic">
      <mat-label>Systolic</mat-label>
      <input matInput placeholder="SYS" type="number" [formControlName]="'bloodPressureSystolic'">
    </mat-form-field>
    <mat-form-field appearance="outline" class="blood-pressure__diastolic">
      <mat-label>Diastolic</mat-label>
      <input matInput placeholder="DIA" type="number" [formControlName]="'bloodPressureDiastolic'">
    </mat-form-field>
  </div>

  <div class="cholesterol__container animated fadeIn" *ngSwitchCase="'Cholesterol'" [formGroupName]="'cholesterol'">
    <div class="cholesterol__ever-use-medications multiple-radio-btns">
      <mat-label>Does client take cholesterol medications?</mat-label>
      <mat-radio-group [formControlName]="'everUseCholesterolMedication'">
        <mat-radio-button aria-label="Currently Use Cholesterol Medication" value="currently">
          Currently Use</mat-radio-button>
        <mat-radio-button aria-label="Used in past Cholesterol Medication" value="past">
          Used In Past</mat-radio-button>
        <mat-radio-button aria-label="Never Use Cholesterol Medication" value="never">
          Never Prescribed or Taken</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-form-field appearance="outline" class="cholesterol__total">
      <mat-label>Total Cholesterol</mat-label>
      <input matInput type="number" [formControlName]="'cholesterolTotalValue'">
      <span matSuffix>(mg/dl)</span>
    </mat-form-field>
    <mat-form-field appearance="outline" class="cholesterol__hdl-reading">
      <mat-label>HDL Reading</mat-label>
      <input matInput type="number" [formControlName]="'cholesterolHDLValue'">
      <span matSuffix>(mg/dl)</span>
    </mat-form-field>
    <mat-form-field appearance="outline" class="cholesterol__hdl-total">
      <mat-label>Total/HDL</mat-label>
      <input matInput type="number" placeholder="Ratio" [formControlName]="'cholesterolHDLRatio'">
    </mat-form-field>
  </div>
</ng-container>
