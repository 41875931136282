import { LibraryText, LibraryTextSearchCriteria, LibraryTextCategory } from 'acc-models';

export interface ITextLibState {
	categories: Array<LibraryTextCategory>,
	searchCriteria: LibraryTextSearchCriteria,
	results: Array<LibraryText>,
	itemToEdit: LibraryText,
	itemToDelete: LibraryText
}

export const initialTextLibState:ITextLibState = {
	categories: null,
	searchCriteria: null,
	results: null,
	itemToEdit: null,
	itemToDelete: null
}