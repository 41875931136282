import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { QuotesResultsComponent } from '../../quote-results/quotes-results/quotes-results.component';
import { Injectable } from "@angular/core";
import { ISaveChanges } from '../../interfaces/save-changes.interface';
import { ICanNavigateAway } from '../../interfaces/can-navigate-away.interface';

@Injectable()
export class CanNavigateAwayGuard implements CanDeactivate<ICanNavigateAway> {
    
    canDeactivate(target: ICanNavigateAway,
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) 
    {
      return target.isDirty()?target.canNavigateAway():true;
    }

  } 