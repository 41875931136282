import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { DataService } from "acc-services";
import { switchMap, map, catchError, withLatestFrom } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import * as UserActions from "../actions/users.actions";
import * as StatusActions from "../actions/status.actions";
import { IAppState } from "../state/app.state";
import { Store } from "@ngrx/store";

@Injectable()
export class UserEffects {
	@Effect()
	getUsers$ = this.actions$.pipe(
		ofType(UserActions.UserActionTypes.GET_USERS),
		switchMap((action: UserActions.GetUsers) =>
			this.dataService.getUsers(action.orgId, "00000000-0000-0000-0000-000000000000").pipe(
				map((resp: any) => new UserActions.GetUsersSuccess(resp)),
				catchError(() => EMPTY)
			)
		)
	);

	@Effect()
	getProfile$ = this.actions$.pipe(
		ofType(UserActions.UserActionTypes.GET_PROFILE),
		switchMap((action: UserActions.GetProfile) =>
			this.dataService.getProfile(action.userId).pipe(
				map((resp: any) => {
					return new UserActions.GetProfileSuccess(resp);
				}),
				catchError((resp: any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message));
				})
			)
		)
	);

	@Effect()
	saveProfile$ = this.actions$.pipe(
		ofType(UserActions.UserActionTypes.UPDATE_PROFILE),
		switchMap((action: UserActions.UpdateProfile) =>
			this.dataService.saveUser(action.user, action.profile).pipe(
				map((resp: any) => new UserActions.UpdateProfileSuccess(resp)),
				catchError((resp: any) => {
					if (resp.status == 426) {
						return of(new UserActions.ShowAddSeats());
					} else {
						return of(new StatusActions.SetErrorMessage(resp.error.Message));
					}
				})
			)
		)
	);

	@Effect()
	saveProgress$ = this.actions$.pipe(
		ofType(UserActions.UserActionTypes.SAVE_PROGRESS),
		switchMap((action: UserActions.SaveProgress) =>
			this.dataService.saveUser(action.user, action.profile).pipe(
				map((resp: any) => new UserActions.SaveProgressSuccess(resp)),
				catchError((resp: any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message));
				})
			)
		)
	);

	@Effect()
	saveProgressSuccess$ = this.actions$.pipe(
		ofType(UserActions.UserActionTypes.SAVE_PROGRESS_SUCCESS),
		withLatestFrom(this.store$.select("adminUser", "profilePhotoToUpload")),
		switchMap(([action, photo]) =>
			this.dataService.uploadProfileImage(photo, (<any>action).user.userID).pipe(
				map((resp: any) => {
					return new UserActions.SavePhotoSuccess(resp.photoFile, resp.photoFileOnly);
				})
			)
		)
	);
	@Effect()
	deleteUser$ = this.actions$.pipe(
		ofType(UserActions.UserActionTypes.DELETE_USER_CONFIRM),
		withLatestFrom(this.store$.select("adminUser", "userToDelete")),
		switchMap(([action, user]) => {
			if (user) {
				return this.dataService.deleteUser(user.userID).pipe(map((resp: any) => new UserActions.DeleteUserSuccess(user.userID)));
			}
		})
	);

	constructor(private actions$: Actions, private store$: Store<IAppState>, private dataService: DataService) {}
}
