import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'acc-common-library-search',
  templateUrl: './library-search.component.html',
  styleUrls: ['./library-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LibrarySearchComponent implements OnInit {

	@Output() onSearch: EventEmitter<string> = new EventEmitter();
	public searchTerm: string;
	constructor() { }

	ngOnInit() {
	}

	search(): void {
		if(this.searchTerm != '') {
			this.onSearch.emit(this.searchTerm)
		}
	}


}
