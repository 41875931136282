<!--<acc-main-loader *ngIf="showLoader"></acc-main-loader>-->
<mat-spinner *ngIf="showLoader"></mat-spinner>

<div class="dtc-container">
	<header *ngIf="broker">
		<div class="org"></div>
		<div class="logo" *ngIf="broker.logoFile" [style.backgroundImage]="'url(' + broker.logoFile + ')'"></div>
		<div class="profile-photo" [ngClass]="{hasProfilePhoto: !!broker.profilePhoto}" [style.backgroundImage]="'url(' + broker.profilePhoto + ')'"></div>
		<div class="broker-info">
			<span>{{broker.firstName}} {{broker.lastName}}</span>
			<span>{{broker.title}}</span>
			<span>{{broker.organizationName}}</span>
		</div>
	</header>

	<main *ngIf="serviceEnabled !== false">
		<div class="progress-bar" [attr.data-content]="progressLabel" [ngStyle]="{'background': progressBackground}"><span *ngIf="progress &lt; 100" [ngStyle]="{left: progress + '%'}">{{progress}}%</span></div>
		<router-outlet></router-outlet>
	</main>

	<main *ngIf="serviceEnabled === false">
		<p>This service is currently disabled</p>
	</main>

	<footer *ngIf="broker != null" id="dtc-main-footer">
		<div class="secure">
			<mat-icon>lock</mat-icon>&nbsp;Secure Application
		</div>
		<div class="slogan">
			Instant quotes from&nbsp;<b>top rated carriers</b><img *ngIf="broker.parentLogo" src="{{broker.parentLogo}}">
		</div>
		<div class="contact">
			<div class="email"><a href="mailto:{{broker.email}}">{{broker.email}}</a></div>
			<div class="phone tel-mobile"><a [href]="'tel:'+(broker.phone | phone)">{{broker.phone | phone}}</a></div>
			<div class="phone tel-desktop">{{broker.phone | phone}}</div>
		</div>
	</footer>
</div>
