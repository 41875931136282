import { AbstractControl, ValidatorFn, NgControlStatus } from "@angular/forms";
import * as moment from "moment";

export class DOBValidators {
	public static dateDisplayFormat = "MM/DD/YYYY";
	static dobOrAge(control: AbstractControl) {
		const dob: string = control.get("birthDate").value;
		const age: string = control.get("age").value;

		if (!(dob || age)) {
		} else {
			control.get("birthDate").setErrors(null);
		}
	}
	static validDob(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const dob: string = control.get("birthDate").value;
			const age = control.get("age").value;
			if (age && !dob) {
				//doesn't matter if dob is blank if you entered an age
				return null;
			}
			if (!age && !dob) {
				control.get("birthDate").setErrors({ dobOrAge: true });
				return { dobOrAge: true };
			}
			if (!age && dob) {
				var momentDate = moment(dob, DOBValidators.dateDisplayFormat, true);
				if (momentDate.isValid()) {
					control.get("birthDate").setErrors(null);
					return null;
				} else {
					control.get("birthDate").setErrors({ invalidDate: true });
					return { invalidDate: true };
				}
			}
			control.get("birthDate").setErrors(null);
			return null;
		};
	}
}
