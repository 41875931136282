import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'acc-main-list-import-actions',
  templateUrl: './list-import-actions.component.html',
  styleUrls: ['../../shared/actions.scss']
})
export class ListImportActionsComponent implements OnInit {

	constructor(private location: Location) { }

	ngOnInit() {
	}
	goBack() {
		this.location.back();
	}

}
