<main class="lists-container">
  <h1>Update your contacts</h1>
  <mat-card >
    <mat-card-content>
        <button mat-raised-button color="primary" *ngIf="!showImportWizard" (click)="toogleImportWizard(true)">Import CSV File</button>
        <acc-main-contacts-list *ngIf="!showImportWizard" ></acc-main-contacts-list>
        <button mat-raised-button color="primary" *ngIf="showImportWizard" (click)="toogleImportWizard(false)">Show Contacts list</button>
        <mat-horizontal-stepper *ngIf="showImportWizard" linear="true" (selectionChange)="onStepChange($event)" #stepper>
          <!--Step 1 -->
            <mat-step label="Upload CSV" editable="true" [completed]="step1completed">

                <div
                    *ngIf="file == null"
                    class="uploadfilecontainer"
                    (click)="($event.target.id != 'sample') ? fileInput.click() : true"
                    fileDrop
                    (onFileDropped)="uploadFile($event)">
                  <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
                  <div class="file-text">In preparing your csv data file, please be sure to include the required fields firstName, lastName and email and any date fields needed for your programs including birthDate, autoRenewalDate, pirDate (Personal Insurance Review),  lifeTerminationDate (Date LIfe Policy Expires).
                    <a target="_blank" id='sample' href="../../../assets/contacts-sample-data.csv">Click here to download a sample csv import file</a>. This can be used as a starting template.
                  </div>
                </div>

                <div  class="files-list" *ngIf="file != null">
                    <p class="file-name"> {{ file.name }} </p>
                    <button class="delete-file" (click)="deleteFile(i)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>

              <div>
                <button mat-raised-button color="primary" matStepperNext>Next: Custom Fields</button>
              </div>

            </mat-step>
            <!--Step 2 -->
            <mat-step editable="false" label="Assign Fields" [completed]="step2completed">
			<p class="directions">Below you will see the columns in the file you uploaded, along with the first few rows of data. For each column, click "Field Name" to assign the appropriate field to your data. If the field name is left blank, that column will not be imported.</p>
			<mat-error *ngIf="!checkForRequiredMappingFields()">
				Check the required fields for mapping
			</mat-error>
              <mat-card class="csv-field-card" *ngFor="let f of uploadedFileInfo.CSVFields">
                 <div class="nameRow multifieldRow">
                    <mat-form-field>
                        <input matInput placeholder="CSV Column {{f.columnNumber + 1}}" value="{{f.columnName}}" readonly />
                    </mat-form-field>
                    <mat-form-field style="width: 300px;" [ngClass]="{'field-error': this.submitted && this.missingFields.length > 0 && !commDataFileMap.Mappings[f.columnNumber].fieldName}">

                      <input matInput type="text" placeholder="Field Name"  [(ngModel)]="commDataFileMap.Mappings[f.columnNumber].fieldName"  [matAutocomplete]="auto" (ngModelChange)="fieldNameChange(f.columnNumber, $event)">
                      <button mat-button *ngIf="commDataFileMap.Mappings[f.columnNumber].fieldName" matSuffix mat-icon-button aria-label="Clear" (click)="clearTargetFieldsFiltered(f.columnNumber)">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let target of targetFieldsFiltered[f.columnNumber]" [value]="target.fieldName">
                          {{target.fieldName}} {{ (target.required)?'- required':''}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <mat-list>
                    <mat-list-item *ngFor="let s of f.sampleValues"> {{s}} </mat-list-item>
                  </mat-list>
              </mat-card>

              <div>
                <button mat-raised-button matStepperPrevious color="primary" style="margin-right: 20px;">Back: Custom Fields</button>
                <button mat-raised-button color="primary" style="margin-left: 20px;" (click)="finishAssignFields()">Next: Add Contacts</button>
              </div>
            </mat-step>
            <mat-step label="Add Contacts"  editable="false" [completed]="step2completed">
                <mat-card class="import-contacts" >
                  <div class="">
                      <mat-icon class="clock-icon">query_builder</mat-icon>
                      <div class="finish-title">Contacts processed successfully</div>
                      <button mat-raised-button color="primary" (click)="backToFirstPage()">Done</button>
                  </div>
                </mat-card>
            </mat-step>
          </mat-horizontal-stepper>
    </mat-card-content>
  </mat-card>
</main>
