import { Directive, Injectable } from "@angular/core";
import { ShepherdService } from "angular-shepherd";

@Directive()
@Injectable()
export class TourService extends ShepherdService {

	private stepOptions;
	private defaultSteps: Array<any>;
	private _preventTour: boolean = false;
	public isCancelled: boolean = false;

	init(stepOptions, steps, isProtractor = false) {
		if(isProtractor) {
			this._preventTour;
			return;
		}
		this.stepOptions = stepOptions;
		this.defaultSteps = steps;
	}
	reset() {
		if(this._preventTour) { return; }
		this.defaultStepOptions = this.stepOptions;
		this.modal = true;
		this.steps = [];
		this.addSteps((this.defaultSteps ?? []).filter(s => this.shouldDisplayStep(s)));
		this.start();
		this.tourObject?.steps.forEach(step => {
			step.on('show', (s) => {
				const history = JSON.parse(window.localStorage.getItem('tour') || "{}");
				window.localStorage.setItem('tour', JSON.stringify({ ...history, [this.tourObject.getCurrentStep().id]: new Date()}));

				document.getElementsByClassName('shepherd-target')[0].addEventListener('click', (s) => this.tourObject.complete())
			});
		});
		if(document.getElementsByClassName('shepherd-modal-overlay-container').length > 0) {
			document.getElementsByClassName('shepherd-modal-overlay-container')[0].addEventListener('click', () => this.complete())
		}

	}
	
	close() {
		this.tourObject?.complete();
	}

	cancel() {
		this._preventTour = true;
		this.isCancelled = true;
		if(this.tourObject) {
			this.complete();
		}
	}
	uncancel(pause = false) {
		this._preventTour = false;
		this.isCancelled = false;

	}

	private shouldDisplayStep(step) {
		//is it for this page?
		if(!this.pathMatch(step.page, window.location.pathname)) {
			return false;
		}
		//check to see if it's already been show
		const history = JSON.parse(window.localStorage.getItem('tour') || "{}");
		if(history[step.id]) {
			let prev = new Date(history[step.id]);
			prev = new Date(prev.setDate(prev.getDate() + (step.daysUntilRedisplay || 10)));
			if (new Date() < prev) {
				return false;
			}
		}
		
		return true;
	}

	private pathMatch(template: string, target: string): boolean {
		const tempArray = template.split('/');
		const targArray = target.split('/');
		if(tempArray.length != targArray.length) {
			return false;
		}
		for(let i = 0; i < tempArray.length; i++) {
			if(tempArray[i] !== '*' && tempArray[i] !== targArray[i]) {
				return false;
			}
		}
		return true;
	}
}