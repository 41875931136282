import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgProgress } from "@ngx-progressbar/core";
import { Injectable } from "@angular/core";
import { environment } from "../../../../acc-main/src/environments/environment";

@Injectable()
export class CheckoutService {
	constructor(private http: HttpClient, private ngProgress: NgProgress) {}

	private API_ROOT = environment.api_url;
	//private API_ROOT = 'http://localhost:43392/api/';

	private getHeaders(hdrs?: any): any {
		let headers = new HttpHeaders().set("Content-Type", "application/json");
		if (hdrs) {
			Object.keys(hdrs).forEach((h) => {
				headers = headers.set(h, hdrs[h]);
			});
		}
		return { headers: headers };
	}

	getProducts() {
		return this.http.get(`${this.API_ROOT}checkout/products`, this.getHeaders());
	}

	getPlans() {
		return this.http.get(`${this.API_ROOT}checkout/plans`, this.getHeaders());
	}

	createSubscription(subscription: any) {
		return this.http.post(`${this.API_ROOT}checkout/create-subscription`, subscription, this.getHeaders());
	}

	createCustomer(subscription: any) {
		return this.http.post(`${this.API_ROOT}checkout/create-customer`, subscription, this.getHeaders());
	}

	getSubscriptions(orgID) {
		return this.http.get(`${this.API_ROOT}checkout/subscription?org=` + orgID, this.getHeaders());
	}

	cancelSubscription(subscription: any) {
		return this.http.post(`${this.API_ROOT}checkout/cancel-subscription`, subscription, this.getHeaders());
	}

	updateSubscription(card: any) {
		return this.http.post(`${this.API_ROOT}checkout/update-subscription`, card, this.getHeaders());
	}

	updateSubscriptionPlan(subscriptionPlan: any) {
		return this.http.post(`${this.API_ROOT}checkout/update-subscription-plan`, subscriptionPlan, this.getHeaders());
	}

	renewSubscription(subscription: any) {
		return this.http.post(`${this.API_ROOT}checkout/renew-subscription`, subscription, this.getHeaders());
	}
}
