
import { Component, Input, Output, OnInit, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs/';

@Component({
	selector: 'acc-common-lightbox',
	templateUrl: './lightbox.component.html',
	styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit {
	@Input() public title: string;
	@Input() public content: string;
	
	@Output() closeLightbox:EventEmitter<number> = new EventEmitter();

	@HostListener('document:click', ['$event']) onbodyclick($event) {
		let target = $event.target;
		let isInside: boolean = false;
		while(target && target.classList && !isInside) {
			if(target.classList.contains('popup')) {
				isInside = true;
			}
			target = target.parentNode;
		}
		if(!isInside) {
			this.close();
		}
	}


	private modalSubject:Subject<number> = new Subject<number>();
	
	constructor() {}
	
	ngOnInit():void {
		
	}
	public modal():Observable<number> {
		return this.modalSubject.asObservable();
	}
	close():void {
		this.closeLightbox.next()
	}

}
