<form autocomplete="off">
	<div *ngIf="invalidToken === false">
		<h5>Please enter your new password</h5>
		<div class="errorMessage" *ngIf="errorMessage">{{errorMessage}}</div>
		<ul>
			<li class="password">
				<mat-form-field appearance="outline">
					<mat-label>Password</mat-label>
					<input matInput [formControl]="passwordField" type="password" placeholder="Min 8 char. One upper, lower, number, special char.">
					<mat-error *ngIf="passwordField.hasError('required')">Password is required</mat-error>
				</mat-form-field>
			</li>
			<li class="password">
				<mat-form-field appearance="outline">
					<mat-label>Confirm Password</mat-label>
					<input matInput [formControl]="confirmPasswordField" type="password">
					<mat-error *ngIf="confirmPasswordField.hasError('required')">Confirm password is required</mat-error>
					<mat-error *ngIf="confirmPasswordField.hasError('mustMatch')">Passwords do not match</mat-error>
				</mat-form-field>
			</li>
			<li>
				<button (click)="updatePassword()" mat-flat-button color="primary">Reset Password</button>
			</li>
		</ul>
	</div>

	<div class="reset-message" *ngIf="org && invalidToken === true">
		<p>The link you click is invalid, or may have expired.  Please return to the <a (click)="navigateTo('recover')">recover password page</a>, to generate another</p>
		<p>&nbsp;</p>
		<p><a (click)="navigateTo('recover')">Return to Login Page</a></p>
	</div>

	<div class="reset-message" *ngIf="org && updateSuccess">
		<p>Your password was successsfully updated.  <a (click)="navigateTo('login')">Return to the Login Page</a> and log in with your new password.</p>
		<p>&nbsp;</p>
		<p></p>
	</div>

</form>
