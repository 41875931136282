import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { ConfirmComponent } from "acc-common";
import { Application } from "acc-models";
import { MixpanelService, QuoteService } from "acc-services";
import { PhonePipe } from "projects/acc-common/src/lib/pipes/phone.pipe";
import { Broker } from "projects/acc-models/src/lib/models/Dtc/broker";
import { DtcQuoteRequest } from "projects/acc-models/src/lib/models/Dtc/dtc-quote-request";
import { DtcResults } from "projects/acc-models/src/lib/models/Dtc/dtc-results";
import { QuoteCarrier } from "projects/acc-models/src/lib/models/quoteCarrier";
import { take } from "rxjs/operators";
import { SetProgress, SetResults, UpdateApplication } from "../../store/actions/dtc.actions";
import { selectApplication, selectBroker } from "../../store/selectors/dtc.selectors";
import { IAppState } from "../../store/state/app.state";
import { DtcRouteService } from "../dtc.route.service";

@Component({
	selector: "acc-main-contact-info",
	templateUrl: "./contact-info.component.html",
	styleUrls: ["./contact-info.component.scss"],
})
export class ContactInfoComponent implements OnInit {
	public form: FormGroup;
	public firstNameField: FormControl;
	public lastNameField: FormControl;
	public emailField: FormControl;
	public phoneField: FormControl;
	private broker: Broker;
	public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

	constructor(private router: Router, private store: Store<IAppState>, private fb: FormBuilder, private quoteService: QuoteService, 
		private dialog: MatDialog, private mixpanelService: MixpanelService, private dtcRouteService: DtcRouteService) {}

	ngOnInit() {
		this.firstNameField = new FormControl("", [Validators.required]);
		this.lastNameField = new FormControl("", []);
		this.emailField = new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);
		this.phoneField = new FormControl("", []);
		this.form = this.fb.group({
			firstName: this.firstNameField,
			lastName: this.lastNameField,
			email: this.emailField,
			phone: this.phoneField,
		});

		this.dtcRouteService.setPage('contact-info');

		this.store.pipe(take(1), select(selectBroker)).subscribe(b => this.broker = b)
		this.store.dispatch(new SetProgress(90));
		window.scrollTo(0,0);
	}

	next() {
		if (this.form.valid) {

			//Mixpanel Track
			this.mixpanelService.trackDTCQuoteRun();

			const app = new Application({ firstName: this.form.controls["firstName"].value, lastName: this.form.controls["lastName"].value, email: this.form.controls["email"].value.trim(), phone: this.form.controls["phone"].value });
			this.store.dispatch(new UpdateApplication(app));
			this.store.dispatch(new SetProgress(100));
			this.store.pipe(take(1), select(selectApplication)).subscribe((app: Application) => {
				this.quoteService.saveDtcQuote(DtcQuoteRequest.FromApplication(app, this.broker.userID)).subscribe((resp:any) => {
					
					if(resp.resultCount == 0) {
						this.showNoResults();
					}

					console.log(resp.quoteResult);

					const results = new DtcResults({ results: resp.quoteResult, monthAmount: this.getMinMonthValue(resp.quoteResult) });
					this.store.dispatch(new SetResults(results));
					this.router.navigateByUrl("/dtc/thank-you");

				}, (err) => {
					if(err && err.error && err.error.Message == "No quote results found.") {
						this.showNoResults();
					}else {
						if(err && err.error && err.error.Message) {
							this.showNoResults({message: err.error.Message});
						} else {
							this.showNoResults({message: `We were unable to complete your request.  Please contact your agent${ this.broker.phone.trim().length > 0 ? ' at ' + new PhonePipe().transform(this.broker.phone) : ''}.`})
						}
					}
				})
			})
		} else {
			Object.keys(this.form.controls).forEach((c) => this.form.get(c).markAsTouched());
		}
	}

	back() {
		this.router.navigateByUrl('/dtc/coverage');
	}

	showNoResults(data?: {title?: string, message?: string, yesText?: string, noText?: string}) {
		const defaults = {
			title: "No Results",
			message: `No results were found, but we may still be able to match you with a policy!  Click "Start Over" to modify your inputs OR contact your agent${ this.broker.phone.trim().length > 0 ? ' at ' + new PhonePipe().transform(this.broker.phone) : ''}.`,
			yesText: "Start Over",
			noText: "Cancel"
		};
		const dialogRef = this.dialog.open(ConfirmComponent, { 
			data: {...defaults, ...data},
			panelClass: 'no-results-dialog'
		});

		dialogRef.afterClosed().pipe(take(1)).subscribe(conf => {
			if(conf) {
				this.router.navigateByUrl("/dtc/"+this.broker.userID);
			}
		})
	}

	getMinMonthValue(quoteResult){

		if(quoteResult == null){
			return 0;
		}

		let min = 0;
		quoteResult.forEach(q => {
			if(min == 0 || q.extensionValues.quoteAmountMonthly < min){
				min = q.extensionValues.quoteAmountMonthly;
			}
		});
		return min;
	}
}
