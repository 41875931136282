import { Directive, ElementRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectClassicView } from 'projects/acc-main/src/app/store/selectors/status.selectors';
import { IAppState } from 'projects/acc-main/src/app/store/state/app.state';

@Directive({
  selector: '[hide-in-classic-view]'
})
export class ClassicViewDirective {

  public showClassicView$ = this.store.pipe(select(selectClassicView));

  constructor(private el: ElementRef, private store: Store<IAppState> ) {

    this.showClassicView$.subscribe((result)=> {
      if(this.el.nativeElement.className == "menu ng-star-inserted" || this.el.nativeElement.className == "menu" || this.el.nativeElement.className == "org-name" || this.el.nativeElement.className == "edit-video-button" ){
        this.el.nativeElement.style.display = (result)?'none' : 'block';
      }else if(this.el.nativeElement.className == "send-proposal"){
        this.el.nativeElement.style.display = (result)?'none' : 'inline';
      }else{
        this.el.nativeElement.style.display = (result)?'none' : 'flex';
      }
    });
    
  }

}
