
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { User, Profile, ProfilePhoto } from 'acc-models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ProfileFormComponent } from '../../../shared/profile-form/profile-form.component';
import { of, forkJoin, Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { ConfirmComponent } from 'acc-common';
import { SalesforceLoginComponent } from '../../shared/salesforce-login/salesforce-login.component';
import { IAppState } from '../../../store/state/app.state';
import { select, Store } from '@ngrx/store';
import { selectClassicView } from '../../../store/selectors/status.selectors';
import { Location } from '@angular/common';

@Component({
	selector: 'acc-main-edit-profile',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss', '../profile.component.scss'],
	encapsulation: ViewEncapsulation.None
  })
export class ProfileEditComponent implements OnInit, OnDestroy, AfterViewInit {
	public user: User;
	public profile: Profile;
	public integrateCRM: boolean;

	public crmLoggedIn: boolean = false;
	private mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

	@ViewChildren('profileForm') profileFormComponent: QueryList<ProfileFormComponent>;
	
	public profileTabs = [];
	@ViewChild('tabs', { static: true }) tabs: MatTabGroup;
	public profileSelectedIndex;
	private deleteDialogRef: MatDialogRef<ConfirmComponent>;
	private changesDialogRef: MatDialogRef<ConfirmComponent>;
	private savedSelectedProfileName = "";
	private selfServiceProfileId: string;

	public showClassicView$ = this.store.pipe(select(selectClassicView));
	public selectedClassicView = false;

	constructor (private sessionService: SessionService, private dataService:DataService, private fb: FormBuilder,  private router:Router, private snackBar: MatSnackBar, private dialog: MatDialog,  private changeDetectorRef: ChangeDetectorRef, private store: Store<IAppState>, private location: Location) {
		this.user = this.sessionService.getUser();
		this.integrateCRM = this.user.integrateCRM;
	}

	ngAfterViewInit(): void {
		this.tabs._handleClick = this.interceptTabChange.bind(this);
		this.user = this.sessionService.getUser();
		this.crmLoggedIn = this.user.hasCRMTokenData;

		this.refreshProfileTabs();

		this.showClassicView$.subscribe((result)=> {
			this.selectedClassicView = result;
		});
	}

  	saveProfile() {
		this.changeDetectorRef.detectChanges();
		var selectedProfileComponent = this.profileFormComponent.toArray()[this.profileSelectedIndex];
		const controls = selectedProfileComponent.profileForm.controls;
		selectedProfileComponent.onFormSubmit();
		if(selectedProfileComponent.profileForm.valid) { //do validation
			this.profile.title = controls['title'].value;
            this.profile.firstName = controls['firstName'].value;
            this.profile.lastName = controls['lastName'].value;
            this.profile.companyName = controls['companyName'].value;
            this.profile.address1 = controls['address1'].value;
            this.profile.address2 = controls['address2'].value;
            this.profile.city = controls['city'].value;
            this.profile.state = controls['state'].value;
            this.profile.zip = controls['zip'].value;
            this.profile.mainPhone = controls['mainPhone'].value.replace(/\D/g, '');
            this.profile.cellPhone = controls['cellPhone'].value.replace(/\D/g, '');
            this.profile.directWorkPhone = controls['directPhone'].value.replace(/\D/g, '');
            this.profile.email = controls['email'].value;
            this.profile.website = controls['website'].value;
            this.profile.facebook = controls['facebook'].value;
            this.profile.twitter = controls['twitter'].value;
            this.profile.instagram = controls['instagram'].value;
			this.profile.youtube = controls['youtube'].value;
			this.profile.linkedIn = controls['linkedin'].value;
			this.profile.profileName = controls['profileName'].value;

			let profilePhoto = selectedProfileComponent.getPhoto();
			let profileLogo = selectedProfileComponent.getLogo();

			let observableBatch = [];
			observableBatch.push(of(true)); //just get something in there, in case there are no images to save
			if(!!profilePhoto){
				const photoUpload = profilePhoto.getData();
				if(photoUpload.file) {
					//save logo image file
					observableBatch.push(this.dataService.uploadProfileImage(photoUpload.file, photoUpload.photo).pipe(
						tap((img: ProfilePhoto) => {
							this.profile.photoID = img.imageID;
							this.profile.photo = img;
							this.profile.photoURLPath = img.imageModifiedURLPath;
						})
					))
				} else {
					if(photoUpload.photo) {
						observableBatch.push(this.dataService.updateProfileImage(photoUpload.photo))
					} else {
						this.profile.photoID = null;
					}
				}
			}			  
			if(!!profileLogo){
				const logoUpload = profileLogo.getData();
				if(logoUpload.file) {
					//save logo image file
					observableBatch.push(this.dataService.uploadProfileImage(logoUpload.file, logoUpload.photo).pipe(
						tap((img: ProfilePhoto) => {
							this.profile.logoID = img.imageID;
							this.profile.logo = img;
							this.profile.logoURLPath = img.imageModifiedURLPath;
						})
					))
				} else {
					if(logoUpload.photo) {
						observableBatch.push(this.dataService.updateProfileImage(logoUpload.photo))
					} else {
						this.profile.logoID = null;
					}
				}
			}			  
			forkJoin(observableBatch).subscribe((resp:any) => {

				this.dataService.saveProfile(this.profile).subscribe(resp => {
					
					if(this.profile.profileID == this.user.primaryProfileID){
						var token = this.user.token;
						this.dataService.getUser(this.user.userID).subscribe(resp => {
							resp["token"] = token;
							this.sessionService.setUser(new User(resp));
						});
					}
					this.savedSelectedProfileName = this.profile.profileName;
					let snackBarRef = this.snackBar.open('Profile Saved!', 'Close', {
						duration: 3000
					});

					this.refreshProfileTabs();
				});
			  
			});
		} 
	}

	ngOnInit(): void {
		this.dataService.getSelfServeEnabled(this.user.userID).subscribe((resp:any) => this.selfServiceProfileId = resp.profileID );
	}
	
	buildProfileTabs(profiles){
		this.profileTabs = [];
		const profilesCalls$: Observable<any>[] = [];

		profiles.forEach(profile => {
			profilesCalls$.push(this.dataService.getProfileDetail(profile.profileID)
				.pipe(tap(p => {
					if(p.profileID == this.user.primaryProfileID){
						var mainProfile = {
							name: profile.profileName,
							profile: p
						};
	
						this.profileTabs.splice(0, 0, mainProfile);
						this.profile = p;
					}else{
						this.profileTabs.push({
							name: profile.profileName,
							profile: p
						});
					}
				}
			)));

		});

		forkJoin(profilesCalls$).subscribe(() => {
			var index = this.profileTabs.findIndex(x => x.name == this.savedSelectedProfileName);

			setTimeout(() => { 	
				this.tabs.selectedIndex = (index > -1)?index:0; 
			}, 500);
		});
	}

	ngOnDestroy():void {
	}

	unlinkCrm() {
		this.dataService.unlinkCrm(this.user.userID).subscribe(resp => {
			this.sessionService.setUser({ ...this.user, hasCRMTokenData: false })
			this.crmLoggedIn = false;
		})

	}
	linkCrm() {
		const salesforceDialogRef = this.dialog.open(SalesforceLoginComponent);
		salesforceDialogRef.afterClosed().pipe(take(1)).subscribe((didLogin) => {
			if(!didLogin) {
				this.sessionService.setUser({...this.user, skipCRM: true})
			}
		})
	}

  	close():void {
		if(!!this.selectedClassicView){
			this.location.back();
		}else{
			this.router.navigate(['/welcome']);
		}
	}  

	async confirmTabChange(): Promise<boolean> {
		this.changesDialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				title: "Confirm",
				message: `Are you sure you want to exit without saving?`
			}
		});
	
		return this.changesDialogRef.afterClosed()
		.toPromise() // here you have a Promise instead an Observable
		.then(confirm => {
			return Promise.resolve(confirm); // will return a Promise here
		});
	}

	async interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
		var originSelectedIndex = tabHeader.selectedIndex;
		var tabChange = true;
		var profileFormComp = this.profileFormComponent.toArray()[originSelectedIndex];
		if(originSelectedIndex != idx && !!profileFormComp.profileForm.dirty){
			tabChange = await this.confirmTabChange(); // waiting here
		}
		return this.checkIfAddIsClicked(tab, tabHeader, idx, tabChange);
	}

	checkIfAddIsClicked(tab: MatTab, tabHeader: MatTabHeader, idx: number, tabChange:boolean){
		var lastIndex = this.profileTabs.length;
		if(idx == lastIndex){
	
			var newProfile = new Profile();
			newProfile.userID = this.user.userID;
			newProfile.profileName = "New Profile";

			//Create new tab
			this.profileTabs.push(
				{
					name: newProfile.profileName,
					profile: newProfile
				}
			);	

			this.profileSelectedIndex = lastIndex;
			this.selectCurrentProfile();
			return false;
		}

		return tabChange && MatTabGroup.prototype._handleClick.apply(this.tabs, arguments);
	}

	onTabChanged($event) {
		this.profileSelectedIndex = $event.index;
		this.selectCurrentProfile();
	}

	selectCurrentProfile(){
		if(this.profileFormComponent != null && this.profileFormComponent.toArray().length > 0 && this.profileFormComponent.toArray()[this.profileSelectedIndex] != null){
			this.profileFormComponent.toArray()[this.profileSelectedIndex].refreshImages();
			if(this.profileSelectedIndex >= 0 && this.profileTabs.length > 0){
				this.profile = this.profileTabs[this.profileSelectedIndex].profile;
			}
		}
	}

	deleteProfile(){

		//first check if this is the self-service profile
		if(this.selfServiceProfileId == this.profile.profileID) {
			this.dialog.open(ConfirmComponent, {
				data: {
					title: "Error",
					message: "You cannot delete this profile because it is being used for Self-Service Quoting.",
					yesText: "Ok",
					noText: ''
				}
			});
		} else {

			this.deleteDialogRef = this.dialog.open(ConfirmComponent, {
				data: {
					title: "Confirm",
					message: `Are you sure you want to delete this profile?`
				}
			});
			this.deleteDialogRef
				.afterClosed()
				.pipe(take(1))
				.subscribe(confirm => {
					if (confirm) {

						//Change Active Profile
						this.user = this.sessionService.getUser();
						if(this.profile.profileID == this.user.activeProfileID){
							this.dataService.setActiveProfile(this.user.userID, this.user.primaryProfileID).subscribe((result:any) => {
								this.dataService.getUser(this.user.userID).subscribe(resp => {
									this.sessionService.setUser(new User(resp));
									this.user = this.sessionService.getUser();
									this.dataService.refreshActiveProfileData(this.user.activeProfileID);
								});
							});
						}

						this.dataService.deleteProfile(this.profile.profileID).subscribe(resp => {
							let snackBarRef = this.snackBar.open('Profile Deleted!', 'Close', {
								duration: 3000
							});
							this.refreshProfileTabs();
						})
					}
				});

		}
	}

	refreshProfileTabs(){
		this.dataService.getProfiles(this.user.userID).subscribe((profiles:any) => {
			this.buildProfileTabs(profiles);
		});
		//Refresh Menu
		this.dataService.refreshUsersProfiles(this.user.userID);
	}
}
