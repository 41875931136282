import { Media, Text } from 'acc-models';

export interface IRevisionDatesState {
	orgId: number,
	medias: Array<Media>,
	pastMedias: Array<Media>,
	soonMedias: Array<Media>,
	futureMedias: Array<Media>,
	noDateMedias: Array<Media>,
	editingMedia: Media,
	texts: Array<Text>,
	pastTexts: Array<Text>,
	soonTexts: Array<Text>,
	futureTexts: Array<Text>,
	noDateTexts: Array<Text>,
	editingText: Text
}

export const initialRevisionDateState: IRevisionDatesState = {
	orgId: 0,
	medias: [],
	pastMedias: [],
	soonMedias: [],
	futureMedias: [],
	noDateMedias: [],
	editingMedia: null,
	texts: [],
	pastTexts: [],
	soonTexts: [],
	futureTexts: [],
	noDateTexts: [],
	editingText: null
}