import { ICatManageState, initialCatManageState } from '../state/catmanage.state';
import { CatManageActions, CatManageActionTypes } from '../actions/catmanage.actions';
import { Category, SubCategory } from 'acc-models';
import { initialCategoriesState } from '../state/categories.state';
 
export function catManageReducer(state:ICatManageState = initialCatManageState, action:CatManageActions):ICatManageState {
	switch (action.type) {
		case CatManageActionTypes.GET_MODULE:
			return { ...state, Categories: null, Subcategories: null, Texts: null }; //clear out categories etc when landing on the page
		case CatManageActionTypes.GET_MODULE_SUCCESS:
			return { ...state, Module: action.module };
		case CatManageActionTypes.GET_CATEGORIES:
			return state;
		case CatManageActionTypes.GET_CATEGORIES_SUCCESS:
			let activeCategory = null, activeSubcategory = null, subcategories: null; //when lowering the outline level, activate first cat/subcat so it's options show in UI	
			if(state.DocOutline.outlineDepth < 3) { 
				activeCategory = action.categories[0]; 
				subcategories = activeCategory.SubCategories.map((s,i) => { return { ...s, categoryID: activeCategory.categoryID, sortOrder: i }})
			}
			if(state.DocOutline.outlineDepth < 2 && subcategories != null) { 
				activeSubcategory = subcategories == null ? null : (subcategories as Array<SubCategory>)[0]; 
			}
			return { ...state, Categories: action.categories, Subcategories: subcategories, ActiveCategory: activeCategory, ActiveSubcategory: activeSubcategory, Texts: null };

		case CatManageActionTypes.GET_LIST_TYPES:
			return state;
		case CatManageActionTypes.GET_LIST_TYPES_SUCCESS:
			return { ...state, ListTypes: action.listTypes };
		case CatManageActionTypes.GET_DOCUMENT_OUTLINE: 
			return state;
		case CatManageActionTypes.GET_DOCUMENT_OUTLINE_SUCCESS:
			return { ...state, DocOutline: action.documentOutline };

		case CatManageActionTypes.SET_OUTLINE_DEPTH:
			return state;
		case CatManageActionTypes.SET_OUTLINE_DEPTH_SUCCESS:
			return { ...state, Depth: action.depth, DocOutline: { ...state.DocOutline, outlineDepth: action.depth }};
		case CatManageActionTypes.UPDATE_OUTLINE:
			return state;
		case CatManageActionTypes.UPDATE_OUTLINE_SUCCESS: 
			return { ...state, DocOutline: action.documentOutline };

		case CatManageActionTypes.ACTIVATE_CATEGORY:
			return { ...state, ActiveCategory: action.category, ActiveSubcategory: null, Texts: null, Subcategories: action.category.SubCategories.map((s,i) => { return { ...s, categoryID: action.category.categoryID, sortOrder: i }}) };
		case CatManageActionTypes.EDIT_CATEGORY:
			return { ...state, EditingCategory: action.category };
		case CatManageActionTypes.EDIT_CATEGORY_CANCEL:
			return { ...state, EditingCategory: null };
		case CatManageActionTypes.EDIT_CATEGORY_SAVE: 
			return { ...state, EditingCategory: action.category }; //this will have category with revised name
		case CatManageActionTypes.EDIT_CATEGORY_SUCCESS:
			return { ...state, EditingCategory: null, Categories: state.Categories.map(c => c.categoryID == state.EditingCategory.categoryID ? state.EditingCategory : c )};
		case CatManageActionTypes.DELETE_CATEGORY:
			return { ...state, CategoryToDelete: action.category };
		case CatManageActionTypes.DELETE_CATEGORY_CANCEL: 
			return { ...state, CategoryToDelete: null }
		case CatManageActionTypes.DELETE_CATEGORY_CONFIRM:
			return state;
		case CatManageActionTypes.DELETE_CATEGORY_SUCCESS: 
			return { 
				...state, 
				Categories: state.Categories.filter(c => c.categoryID != state.CategoryToDelete.categoryID), 
				CategoryToDelete: null, 
				Subcategories: state.CategoryToDelete.categoryID == state.ActiveCategory.categoryID ? null : state.Subcategories //if you deleted the active category, clear subcategories
			 };
		case CatManageActionTypes.SAVE_CATEGORIES:
			return state;
		case CatManageActionTypes.SAVE_CATEGORIES_SUCCESS:
			return state;
		case CatManageActionTypes.ADD_CATEGORY:
			return state;
		case CatManageActionTypes.ADD_CATEGORY_SUCCESS:
			return { ...state, Categories: [...state.Categories, action.category] }

		case CatManageActionTypes.ACTIVATE_SUBCATEGORY:
			return { ...state, ActiveSubcategory: action.subcategory };
		case CatManageActionTypes.EDIT_SUBCATEGORY:
			return { ...state, EditingSubcategory: action.subcategory };
		case CatManageActionTypes.EDIT_SUBCATEGORY_CANCEL:
			return { ...state, EditingSubcategory: null };
		case CatManageActionTypes.EDIT_SUBCATEGORY_SAVE: 
			return { ...state, EditingSubcategory: action.subcategory }; //this will have subcategory with revised name
		case CatManageActionTypes.EDIT_SUBCATEGORY_SUCCESS:
			return { 
				...state, 
				EditingSubcategory: null, 
				Subcategories: state.Subcategories.map(s => s.subCategoryID == state.EditingSubcategory.subCategoryID ? state.EditingSubcategory : s ),
				Categories: state.Categories.map(c => {
					return c.categoryID == state.EditingSubcategory.categoryID ?
						{ ...c, SubCategories: c.SubCategories.map(s => s.subCategoryID == state.EditingSubcategory.subCategoryID ? { ...s, subCategoryDesc: state.EditingSubcategory.subCategoryDesc } : s )} :
						c
				})
			};
		case CatManageActionTypes.DELETE_SUBCATEGORY:
			return { ...state, SubcategoryToDelete: action.subcategory };
		case CatManageActionTypes.DELETE_SUBCATEGORY_CANCEL: 
			return { ...state, SubcategoryToDelete: null }
		case CatManageActionTypes.DELETE_SUBCATEGORY_CONFIRM:
			return state;
		case CatManageActionTypes.DELETE_SUBCATEGORY_SUCCESS: 
			return { 
				...state, 
				Subcategories: state.Subcategories.filter(s => s.subCategoryID != state.SubcategoryToDelete.subCategoryID), 
				SubcategoryToDelete: null,
				Texts: state.SubcategoryToDelete.subCategoryID == state.ActiveSubcategory.subCategoryID ? null : state.Texts 
			};
		case CatManageActionTypes.SAVE_SUBCATEGORIES:
			return state;
		case CatManageActionTypes.SAVE_SUBCATEGORIES_SUCCESS:
		return { ...state, Categories: state.Categories.map(c => c.categoryID == state.Subcategories[0].categoryID ? new Category({ ...c, SubCategories: state.Subcategories }) : c )};
		case CatManageActionTypes.ADD_SUBCATEGORY:
			return state;
		case CatManageActionTypes.ADD_SUBCATEGORY_SUCCESS:
			return { ...state, Subcategories: [ ...state.Subcategories, action.subcategory ], Categories: state.Categories.map(c => c.categoryID == action.subcategory.categoryID ? { ...c, SubCategories: [ ...c.SubCategories, action.subcategory] } : c ) }

		case CatManageActionTypes.GET_SUBCAT_TEXTS: 
			return { ...state, ActiveSubcategory: state.ActiveSubcategory || (state.Subcategories ? state.Subcategories[0] : null) }; //if we don't have active subcat, select the first one
		case CatManageActionTypes.GET_SUBCAT_TEXTS_SUCCESS:
			return { ...state, Texts: action.texts };
		case CatManageActionTypes.ACTIVATE_SUBCAT_TEXT:
			return { ...state, ActiveText: action.text};
		case CatManageActionTypes.ADD_SUBCAT_TEXT:
			return { ...state, ActiveText: action.text };
		case CatManageActionTypes.ADD_SUBCAT_TEXT_SUCCESS:
			return { ...state, ActiveText: null, Texts: [...state.Texts, action.text] };
		case CatManageActionTypes.EDIT_SUBCAT_TEXT:
			return { ...state, EditingText: action.text };
		case CatManageActionTypes.EDIT_SUBCAT_TEXT_CANCEL:
			return { ...state, EditingText: null };
		case CatManageActionTypes.EDIT_SUBCAT_TEXT_CONFIRM:
			return { ...state, EditingText: action.editingText};
		case CatManageActionTypes.EDIT_SUBCAT_TEXT_SUCCESS:
			return { ...state, Texts: state.Texts.map(t => t.textID === state.EditingText.textID ? state.EditingText : t), EditingText: null };
		case CatManageActionTypes.DELETE_SUBCAT_TEXT:
			return { ...state, TextToDelete: action.text };
		case CatManageActionTypes.DELETE_SUBCAT_TEXT_CANCEL:
			return { ...state, TextToDelete:null };
		case CatManageActionTypes.DELETE_SUBCAT_TEXT_CONFIRM:
			return state;
		case CatManageActionTypes.DELETE_SUBCAT_TEXT_SUCCESS:
			return { ...state, Texts: state.Texts.filter(t => t.textID !== state.TextToDelete.textID), TextToDelete: null };
		case CatManageActionTypes.UPDATE_SUBCAT_TEXTS:
			return state;
		case CatManageActionTypes.UPDATE_SUBCAT_TEXTS_SUCCESS:
			return state;
		case CatManageActionTypes.ADD_SUBCAT_TEXT:
			return state;
		case CatManageActionTypes.ADD_SUBCAT_TEXT_SUCCESS:
			return { ...state, Texts: [ ...state.Texts, action.text ] }

		case CatManageActionTypes.RESET:
			return initialCatManageState
		default: 
			return state;

	}
}