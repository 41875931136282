<li class="data-block">
	<h1 *ngIf="block.link">
		<ng-container *ngIf="block.name == recentActivityName && !sessionService.canUseSubscriptionLevelFeature('/recent-activity'); else block_link">
			<span (click)="showLockFeatureDialog()">{{block.name}} <mat-icon class="lock-icon lock-icon-white" >lock</mat-icon></span>
		</ng-container>
		<ng-template #block_link>
			<a [routerLink]="block.link">{{block.name}}</a>
		</ng-template>
		
	</h1>
	<h1 *ngIf="!block.link">{{block.name}} </h1>
	<div class="cols">
		<div class="col" *ngFor="let req of block.data">
			<h2 *ngIf="req.title">{{req.title}}</h2>
			<ul>
				<li *ngFor="let item of apiCalls.get(req.api)">
					<ng-container *ngIf="item">
						<mat-icon *ngIf="item.icon !== undefined" [title]="item.icon_title">{{item.icon}}</mat-icon>
						<div class="fake-link" *ngIf="item && item.url" (click)="navigateTo(item.url, $event)" [innerHTML]="item.text"></div>
						<span *ngIf="!item.url" [innerText]="item.text || item"></span>
					</ng-container>
				</li>
			</ul>
		</div>
	</div>
	<!--<div class="spacer"></div>-->
	<div class="update-container" (click)="loadData()" [ngClass]="{ fetching: isFetching }">
		<mat-icon>cached</mat-icon>
		<h3>Update Now</h3>
	</div>
</li>