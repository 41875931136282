export class DrivingViolation {
    public isSelected: boolean;
    //public drivingConvictionExists: boolean;
    public duiDwi: DuiDwi = new DuiDwi();
    public licenseSuspension: LicenseSuspension = new LicenseSuspension();
    public motorVehicleAccident: MotorVehicleAccident = new MotorVehicleAccident();
    public movingViolation: MovingViolation = new MovingViolation();
    public recklessDriving: RecklessDriving = new RecklessDriving();

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class DuiDwi {
  public drivingConvictionExists: boolean | null = null;
  public drivingDrunkDrivingDates: Array<string> = [];

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class LicenseSuspension {
  public drivingLicenseCurrentlyRevoked: boolean | null = null;
  public drivingLicenseSuspensionDates: Array<string> = [];

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class MotorVehicleAccident {
  public motorVehicleAccidentExists: boolean | null = null;
  public drivingMotorVehicleAccidentDates: Array<string> = [];

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class MovingViolation {
  public movingViolationExists: boolean | null = null;
  public drivingMovingViolationDates: Array<string> = [];

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class RecklessDriving {
  public recklessDrivingViolationExists: boolean | null = null;
  public drivingRecklessDrivingDates: Array<string> = [];

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export interface DrivingViolationDate {
    month: number;
    year: number;
}
