export class Application {
	public birthdate: Date;
	public state: string;
	public gender: 'M' | 'F';
	public healthRating: 'A' | 'B' | 'C' | 'D';
	public smoker: boolean;
	public amount: number;
	public duration: number;
	public firstName: string;
	public lastName: string;
	public email: string; 
	public phone: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}