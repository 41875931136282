
import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DataService, SessionService } from 'acc-services';
import { ICatManageState } from '../state/catmanage.state';
import * as CatManageActions from '../actions/catmanage.actions';
import * as StatusActions from '../actions/status.actions';
import { catchError, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { SubCategory } from 'acc-models';

@Injectable()
export class CatManageEffects {

	@Effect()
	getModule$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.GET_MODULE),
		switchMap((action:CatManageActions.GetModule) => this.dataService.getModules(this.sessionService.getUser().orgID)
			.pipe(
				map((resp: any) => new CatManageActions.GetModuleSuccess(resp.filter(m => m.moduleID == action.moduleId)[0])),
				catchError(() => EMPTY)
			)
		)
	)
	@Effect()
	getCategories$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.GET_CATEGORIES),
		switchMap((action:CatManageActions.GetCategories) => this.dataService.getCategoriesForModule(action.moduleId, action.userId, action.docId)
			.pipe(
				map((resp: any) => new CatManageActions.GetCategoriesSuccess(resp)),
				catchError(() => EMPTY)
			)
		)
	)
	@Effect()
	getListTypes$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.GET_LIST_TYPES),
		switchMap((action:CatManageActions.GetListTypes) => this.dataService.getListTypes()
			.pipe(
				map((resp: any) => new CatManageActions.GetListTypesSuccess(resp)),
				catchError(() => EMPTY)
			)
		)
	)
	@Effect()
	getDocumentOutline$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.GET_DOCUMENT_OUTLINE),
		switchMap((action:CatManageActions.GetDocumentOutline) => this.dataService.getDocumentOutline(action.docId)
			.pipe(
				map((resp: any) => new CatManageActions.GetDocumentOutlineSuccess(resp)),
				catchError(() => EMPTY)
			)
		)
	)
	@Effect()
	setOutlineDepth$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.SET_OUTLINE_DEPTH),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.setDocumentDepth(docId, (action as CatManageActions.SetOutlineDepth).depth)
			.pipe(
				map((resp: any) => new CatManageActions.SetOutlineDepthSuccess((action as CatManageActions.SetOutlineDepth).depth)),
				catchError(() => EMPTY)
			)
		)
	)
	@Effect() //if the outline depth is changed, the categories will be changed too most likely
	setOutlineDepthSuccess$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.SET_OUTLINE_DEPTH_SUCCESS),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline')),
		switchMap(([action, docOutline]) => this.dataService.getCategoriesForModule(docOutline.moduleID, this.sessionService.getUser().userID, docOutline.docID)
			.pipe(
				map((resp: any) => new CatManageActions.GetCategoriesSuccess(resp)),
				catchError(() => EMPTY)
			)
		)
	)
	@Effect()
	updateOutline$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.UPDATE_OUTLINE),
		switchMap((action:CatManageActions.UpdateOutline) => this.dataService.updateDocumentOutline(action.documentOutline)
			.pipe(
				map((resp: any) => new CatManageActions.UpdateOutlineSuccess(action.documentOutline)),
				catchError(() => EMPTY)
			)
		)
	)
	@Effect()
	editCategory$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.EDIT_CATEGORY_SAVE),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.updateCategoryAdmin((action as CatManageActions.EditCategorySave).category, docId)
			.pipe(
				map((resp:any) => new CatManageActions.EditCategorySuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	deleteCategory$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.DELETE_CATEGORY_CONFIRM),
		withLatestFrom(this.store$.select('catmanage', 'CategoryToDelete')),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([[action, category], docId]) => {
			return this.dataService.deleteCategory(category.categoryID, docId)
				.pipe(
					map((resp:any) => new CatManageActions.DeleteCategorySuccess()),
					catchError(((resp:any) => {
						return of(new StatusActions.SetErrorMessage(resp.error.Message))
					}))
				)
			}
		)
	)
	@Effect()
	reorderCategories$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.SAVE_CATEGORIES),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.updateCategories((action as CatManageActions.SaveCategories).categories, docId)
			.pipe(
				map((resp:any) => new CatManageActions.SaveCategoriesSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	addCategory$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.ADD_CATEGORY),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.insertCategory((action as CatManageActions.AddCategory).moduleId, (action as CatManageActions.AddCategory).category, docId)
			.pipe(
				map((resp: any) => new CatManageActions.AddCategorySuccess( { ...resp, SubCategories: new Array<SubCategory>() } )),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	editSubategory$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.EDIT_SUBCATEGORY_SAVE),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.updateSubcategoryAdmin((action as CatManageActions.EditSubcategory).subcategory, docId)
			.pipe(
				map((resp:any) => new CatManageActions.EditSubcategorySuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	deleteSubcategory$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.DELETE_SUBCATEGORY_CONFIRM),
		withLatestFrom(this.store$.select('catmanage', 'SubcategoryToDelete')),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([[action, subcategory], docId]) => {
			return this.dataService.deleteSubcategory(subcategory.subCategoryID, docId)
				.pipe(
					map((resp:any) => new CatManageActions.DeleteSubcategorySuccess()),
					catchError(((resp:any) => {
						return of(new StatusActions.SetErrorMessage(resp.error.Message))
					}))
				)
			}
		)
	)
	@Effect()
	reorderSubcategories$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.SAVE_SUBCATEGORIES),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.updateSubcategories((action as CatManageActions.SaveSubcategories).subcategories, docId)
			.pipe(
				map((resp:any) => new CatManageActions.SaveSubcategoriesSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	addSubcategory$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.ADD_SUBCATEGORY),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.insertSubcategory((action as CatManageActions.AddSubcategory).subcategory.categoryID, (action as CatManageActions.AddSubcategory).subcategory, docId)
			.pipe(
				map((resp: any) => new CatManageActions.AddSubcategorySuccess( resp )),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	selectSubcategory$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.ACTIVATE_SUBCATEGORY),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.getSubcategoryTexts((action as CatManageActions.ActivateSubcategory).subcategory.subCategoryID, docId)
			.pipe(
				map((resp:any) => new CatManageActions.GetSubcatTextsSuccess(resp)),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	editSubcatText$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.EDIT_SUBCAT_TEXT_CONFIRM),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.updateSubcatText((action as CatManageActions.EditSubcatTextConfirm).editingText, docId)
			.pipe(
				map((resp:any) => new CatManageActions.EditSubcatTextSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	deleteSubcatText$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.DELETE_SUBCAT_TEXT_CONFIRM),
		withLatestFrom(this.store$.select('catmanage', 'TextToDelete')),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([[action, text], docId]) => this.dataService.deleteSubcatText(text.textID, docId)
			.pipe(
				map((resp:any) => new CatManageActions.DeleteSubcatTextSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	reorderSubcatTexts$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.UPDATE_SUBCAT_TEXTS),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.updateSubcatTexts((action as CatManageActions.UpdateSubcatTexts).texts, docId)
			.pipe(
				map((resp:any) => new CatManageActions.UpdateSubcatTextsSuccess()),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect()
	getSubcatTexts$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.GET_SUBCAT_TEXTS),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		withLatestFrom(this.store$.select('catmanage', 'ActiveSubcategory')),
		switchMap(([[action, docId], subcat]) => this.dataService.getSubcategoryTexts(subcat.subCategoryID, docId)
			.pipe(
				map((resp:any) => new CatManageActions.GetSubcatTextsSuccess(resp)),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)
	@Effect() 
	addText$ = this.actions$.pipe(
		ofType(CatManageActions.CatManageActionTypes.ADD_SUBCAT_TEXT),
		withLatestFrom(this.store$.select('catmanage', 'DocOutline', 'docID')),
		switchMap(([action, docId]) => this.dataService.insertSubcatText((action as CatManageActions.AddSubcatText).text.subCategoryID, (action as CatManageActions.AddSubcatText).text.defaultText, (action as CatManageActions.AddSubcatText).text.revisionNeededDate, docId)
			.pipe(
				map((resp: any) => new CatManageActions.AddSubcatTextSuccess(resp)),
				catchError(((resp:any) => {
					return of(new StatusActions.SetErrorMessage(resp.error.Message))
				}))
			)
		)
	)

	constructor(private actions$: Actions, private store$:Store<ICatManageState>, private dataService:DataService, private sessionService:SessionService) {}
}

