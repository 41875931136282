import { QuoteInputProp } from './quoteInputProp';
import { QuoteRecFilter } from './quoteRecFilter';

export class QuoteInput {

	public quoteID: string;
	public products: Array<string>;
	public birthDate: Date | string;
	public actualAge: number;
	public nearestAge: number;
	public gender: string;
	public healthClasses: Array<string>;
	public state: string;
  	public faceAmounts: Array<String> = [''];
	public terms: Array<String>;
	public paymentOptions: Array<string>;
	public solves: string;
	public illustrateYear: number;
	public recFilters: Array<QuoteRecFilter>;
	public props: QuoteInputProps = new QuoteInputProps();
	public clientName: string;
	public quoteDesc: string;

	constructor(o?:any) {
		Object.assign(this, o);
	}
}

export class QuoteInputProps {
	public illustratedRate: string;
	public isAccidentalDeathBenefitRider: boolean;
	public isReturnOfPremiumRider: boolean;
	public isChildTermRider: boolean;
	public isWaiverOfPremiumRider: boolean;
	public cru: string;
	public loanType: string;
	public tableRating: number;
	public isGuaranteed: boolean;
	public showTesting: boolean;
	public simplifiedIssue: boolean;
	public flatExtraAmount: number;
	public flatExtraYearStart: number;
	public flatExtraYearEnd: number;
	public returnAllProductVerions = true;
	public returnExtensionValues = false;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

