<div class="container">
	<div class="top">
		<p><button mat-flat-button color="accent" (click)="addNew()">Add New</button></p>
		<div class="form" *ngIf="categories">
			<ul>
				<li>
					<mat-form-field appearance="outline">
						<mat-label>Category</mat-label>
						<mat-select [(value)]="criteria.textLibCategoryId" (selectionChange)="clearSearch()" [ngClass]="tall">
							<mat-option value="-1"></mat-option>
							<mat-option *ngFor="let category of categories" value="{{category.textLibCategoryID}}">{{category.textLibCategoryDesc}}</mat-option>
						</mat-select>
					</mat-form-field>
				</li>
				<li>
					<mat-form-field appearance="outline">
						<mat-label>Search Term</mat-label>
						<input matInput (keyup)="clearSearch()" [(ngModel)]="criteria.searchString" [ngClass]="tall">
					</mat-form-field>
				</li>
			</ul>
			<button mat-flat-button color="primary" (click)="search()">Search</button>
		</div>
		<div class="results" *ngIf="searchResults$ |async">
			<ul>
				<li *ngFor="let result of searchResults$ | async" [ngClass]="{active: activeResult?.textLibID == result.textLibID}">
					<h3>{{result.textLibTitle}}</h3>
					<p>{{result.textLibDesc}}</p>
					<div class="actions">
						<button mat-flat-button color="primary" (click)="edit(result)"  mat-flat-button>
							<span class="mat-button-wrapper">Edit</span>
							<div class="mat-button-ripple mat-ripple" matripple=""></div>
							<div class="mat-button-focus-overlay"></div>
						</button>

						<button  (click)="delete(result)" mat-stroked-button color="primary">
							<span class="mat-button-wrapper">Delete</span>
							<div class="mat-button-ripple mat-ripple" matripple=""></div>
							<div class="mat-button-focus-overlay"></div>
						</button>

					</div>
				</li>
			</ul>
		</div>
	</div>
</div>

