import { ProfilePhoto } from './profilePhoto';


export class SignupInput {
	public company: SignupCompany;
	public user: SignupUser;
	public activationURL: string;
	public masterOrgID: string;
	public stripeCustomerId: string;

	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class SignupCompany {
	public orgDesc: string;
	public resource: string;
	public photoID: string;
	public photo: ProfilePhoto;
	public requireSubscription: boolean;
	public subscriptionLevelID : number;
	public stripeCustomerID : string;
	public subscriptionLevelDesc : string;
	
	constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class SignupUser {
	public title: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public password: string;
	public photoID: string;
	constructor(o?: any) {
		Object.assign(this, o);
	}
}