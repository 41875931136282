import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuoteDetailsRequest } from 'projects/acc-models/src/lib/models/quoteDetailsRequest';
import { QuoteService } from 'projects/acc-services/src/lib/services/quote.service';
import { SetErrorMessage } from '../../store/actions/status.actions';
import { IAppState } from '../../store/state/app.state';
import { Store } from '@ngrx/store';
import { QuoteResult } from 'projects/acc-models/src/lib/models/quoteResult';

export interface DialogData {
  productDetail: QuoteDetailsRequest[];
  result: QuoteResult;
  image: string;
}

@Component({
  selector: 'acc-main-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.scss']
})
export class QuoteDetailComponent implements OnInit {

  productDetail: any;

	constructor(
    public dialogRef: MatDialogRef<QuoteDetailComponent>,
    private quoteService: QuoteService,
    private store: Store<IAppState>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {}

  ngOnInit() {
    this.getData();
  }

  getData(){
    this.quoteService.getProductDetail(this.data.productDetail).subscribe((resp:any) => {
		  if(resp) {
       
        if(resp != null && resp.result != null && resp.result.length > 0){
          this.productDetail = resp.result[0];
        }
           
		  } else {
			  this.store.dispatch(new SetErrorMessage('Could not access pipeline api'));
		  }
		  
	  }, (err: any) => {
		    this.store.dispatch(new SetErrorMessage('Could not access pipeline api'));
	  });
  }

  onNoClick(): void {
		this.dialogRef.close();
	}

}
