<div mat-dialog-header>
	<h2 mat-dialog-title>Edit Media</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<form [formGroup]="editForm">
		<mat-form-field>
			<mat-label>Description</mat-label>
			<input matInput [formControl]="mediaDescField" class="media-edit-description">
			<mat-error *ngIf="mediaDescField.hasError('required')">Description is required</mat-error>
		</mat-form-field>
		<div class="uploadWithThumbnail">
			<div class="thumbnail">&nbsp;</div>
			<div class="uploadField">
				<span for="name">File</span>
				<acc-common-file-upload formControlName="file"></acc-common-file-upload>
				<div class="error" *ngIf="editForm.controls['file'].touched && editForm.controls['file'].hasError('requiredIfNew')">File is required</div>
			</div>
		</div>
		<div class="uploadWithThumbnail">
			<div class="thumbnail" [ngStyle]="{'background-image':'url(' + data.media.imageFile + '?' + cachebust + ')'}"></div>
			<div class="uploadField">
				<span for="name">Thumbnail</span>
				<acc-common-file-upload formControlName="thumbnail" [accept]="imageFileTypes" [errorMessage]="'File must be an image'"></acc-common-file-upload>
				<div class="error" *ngIf="editForm.controls['thumbnail'].touched && editForm.controls['thumbnail'].hasError('requiredIfNew')">Thumbnail is required</div>
			</div>
		</div>
		<mat-form-field>
			<mat-label>Media Type</mat-label>
			<mat-select [formControl]="mediaTypeField" [compareWith]="compareValues" class="media-edit-type">
				<mat-option value="1">General Media</mat-option>
				<mat-option value="2">Personalized PDF</mat-option>
				<mat-option value="3">Personalized Video</mat-option>
			</mat-select>
			<mat-icon matSuffix class="info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.admin_media_type}}" >info_outline</mat-icon> 
		</mat-form-field>
		<mat-form-field>
			<mat-label>Search Keywords</mat-label>
			<input matInput [formControl]="keywordsField" />
			<mat-hint>Multiple keywords can be separated by commas</mat-hint>
			<mat-error *ngIf="keywordsField.hasError('required')">At least one search keyword is required</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Revision Needed Date</mat-label>
			<input matInput [matDatepicker]="picker" [formControl]="revDateField" />
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
		<div *ngIf="errorMessage" class="errorMessage">{{errorMessage}}</div>
	</form>
	</div>
</mat-dialog-content>


<mat-dialog-actions>
	<button mat-raised-button color="primary" *ngIf="data.media.mediaItemID" (click)="close(0)">Save</button>
	<button mat-raised-button color="primary" *ngIf="!data.media.mediaItemID" (click)="close(2)">Add</button>
	<button mat-stroked-button (click)="close(1)">Cancel</button>
</mat-dialog-actions>

