import { ICategoriesState, initialCategoriesState } from '../state/categories.state';
import { CategoriesActions, CategoryActionTypes } from '../actions/categories.actions';
import { Category } from 'acc-models';
import { ROOT_RESET } from '../actions/root.actions';

export function categoriesReducer(state:ICategoriesState = initialCategoriesState, action:CategoriesActions):ICategoriesState {
	switch (action.type) {
		case CategoryActionTypes.GET_MODULES: 
			return { ...state, Categories: null, Subcategories: null, Texts: null }; //clear out categories etc when landing on the page
			//return state;
		case CategoryActionTypes.GET_MODULES_SUCCESS:
			return { ...state, Modules: action.modules };
		case CategoryActionTypes.GET_CATEGORIES:
			return state;
		case CategoryActionTypes.GET_CATEGORIES_SUCCESS:
			return { ...state, Categories: action.categories, Subcategories: null, Texts: null };
		case CategoryActionTypes.ACTIVATE_CATEGORY:
			return { ...state, ActiveCategory: action.category, ActiveSubcategory: null, Texts: null, Subcategories: action.category.SubCategories.map((s,i) => { return { ...s, categoryID: action.category.categoryID, sortOrder: i }}) };
			//return { ...state, ActiveCategory: action.category, Texts: null };
		case CategoryActionTypes.EDIT_CATEGORY:
			return { ...state, EditingCategory: action.category };
		case CategoryActionTypes.EDIT_CATEGORY_CANCEL:
			return { ...state, EditingCategory: null };
		case CategoryActionTypes.EDIT_CATEGORY_SAVE: 
			return { ...state, EditingCategory: action.category }; //this will have category with revised name
		case CategoryActionTypes.EDIT_CATEGORY_SUCCESS:
			return { ...state, EditingCategory: null, Categories: state.Categories.map(c => c.categoryID == state.EditingCategory.categoryID ? state.EditingCategory : c )};
		case CategoryActionTypes.DELETE_CATEGORY:
			return { ...state, CategoryToDelete: action.category };
		case CategoryActionTypes.DELETE_CATEGORY_CANCEL: 
			return { ...state, CategoryToDelete: null }
		case CategoryActionTypes.DELETE_CATEGORY_CONFIRM:
			return state;
		case CategoryActionTypes.DELETE_CATEGORY_SUCCESS: 
			return { 
				...state, 
				Categories: state.Categories.filter(c => c.categoryID != state.CategoryToDelete.categoryID), 
				CategoryToDelete: null, 
				Subcategories: state.CategoryToDelete.categoryID == state.ActiveCategory.categoryID ? null : state.Subcategories //if you deleted the active category, clear subcategories
			 };
		case CategoryActionTypes.SAVE_CATEGORIES:
			return state;
		case CategoryActionTypes.SAVE_CATEGORIES_SUCCESS:
			return state;
		case CategoryActionTypes.ADD_CATEGORY:
			return state;
		case CategoryActionTypes.ADD_CATEGORY_SUCCESS:
			return { ...state, Categories: [...state.Categories, action.category] }

		case CategoryActionTypes.ACTIVATE_SUBCATEGORY:
			return { ...state, ActiveSubcategory: action.subcategory };
		case CategoryActionTypes.EDIT_SUBCATEGORY:
			return { ...state, EditingSubcategory: action.subcategory };
		case CategoryActionTypes.EDIT_SUBCATEGORY_CANCEL:
			return { ...state, EditingSubcategory: null };
		case CategoryActionTypes.EDIT_SUBCATEGORY_SAVE: 
			return { ...state, EditingSubcategory: action.subcategory }; //this will have subcategory with revised name
		case CategoryActionTypes.EDIT_SUBCATEGORY_SUCCESS:
			return { 
				...state, 
				EditingSubcategory: null, 
				Subcategories: state.Subcategories.map(s => s.subCategoryID == state.EditingSubcategory.subCategoryID ? state.EditingSubcategory : s ),
				Categories: state.Categories.map(c => {
					return c.categoryID == state.EditingSubcategory.categoryID ?
						{ ...c, SubCategories: c.SubCategories.map(s => s.subCategoryID == state.EditingSubcategory.subCategoryID ? { ...s, subCategoryDesc: state.EditingSubcategory.subCategoryDesc } : s )} :
						c
				})
			};
		case CategoryActionTypes.DELETE_SUBCATEGORY:
			return { ...state, SubcategoryToDelete: action.subcategory };
		case CategoryActionTypes.DELETE_SUBCATEGORY_CANCEL: 
			return { ...state, SubcategoryToDelete: null }
		case CategoryActionTypes.DELETE_SUBCATEGORY_CONFIRM:
			return state;
		case CategoryActionTypes.DELETE_SUBCATEGORY_SUCCESS: 
			return { 
				...state, 
				Subcategories: state.Subcategories.filter(s => s.subCategoryID != state.SubcategoryToDelete.subCategoryID), 
				SubcategoryToDelete: null,
				Texts: state.SubcategoryToDelete.subCategoryID == state.ActiveSubcategory.subCategoryID ? null : state.Texts 
			};
		case CategoryActionTypes.SAVE_SUBCATEGORIES:
			return state;
		case CategoryActionTypes.SAVE_SUBCATEGORIES_SUCCESS:
		return { ...state, Categories: state.Categories.map(c => c.categoryID == state.Subcategories[0].categoryID ? new Category({ ...c, SubCategories: state.Subcategories }) : c )};
		case CategoryActionTypes.ADD_SUBCATEGORY:
			return state;
		case CategoryActionTypes.ADD_SUBCATEGORY_SUCCESS:
			return { ...state, Subcategories: [ ...state.Subcategories, action.subcategory ], Categories: state.Categories.map(c => c.categoryID == action.subcategory.categoryID ? { ...c, SubCategories: [ ...c.SubCategories, action.subcategory] } : c ) }

		case CategoryActionTypes.GET_SUBCAT_TEXTS_SUCCESS:
			return { ...state, Texts: action.texts };
		case CategoryActionTypes.ACTIVATE_SUBCAT_TEXT:
			return { ...state, ActiveText: action.text};
		case CategoryActionTypes.ADD_SUBCAT_TEXT:
			return { ...state, ActiveText: action.text };
		case CategoryActionTypes.ADD_SUBCAT_TEXT_SUCCESS:
			return { ...state, ActiveText: null, Texts: [...state.Texts, action.text] };
		case CategoryActionTypes.EDIT_SUBCAT_TEXT:
			return { ...state, EditingText: action.text };
		case CategoryActionTypes.EDIT_SUBCAT_TEXT_CANCEL:
			return { ...state, EditingText: null };
		case CategoryActionTypes.EDIT_SUBCAT_TEXT_CONFIRM:
			return state;
		case CategoryActionTypes.EDIT_SUBCAT_TEXT_SUCCESS:
			return { ...state, Texts: state.Texts.map(t => t.textID === state.EditingText.textID ? state.EditingText : t), EditingText: null };
		case CategoryActionTypes.DELETE_SUBCAT_TEXT:
			return { ...state, TextToDelete: action.text };
		case CategoryActionTypes.DELETE_SUBCAT_TEXT_CANCEL:
			return { ...state, TextToDelete:null };
		case CategoryActionTypes.DELETE_SUBCAT_TEXT_CONFIRM:
			return state;
		case CategoryActionTypes.DELETE_SUBCAT_TEXT_SUCCESS:
			return { ...state, Texts: state.Texts.filter(t => t.textID !== state.TextToDelete.textID), TextToDelete: null };
		case CategoryActionTypes.UPDATE_SUBCAT_TEXTS:
			return state;
		case CategoryActionTypes.UPDATE_SUBCAT_TEXTS_SUCCESS:
			return state;
		case CategoryActionTypes.ADD_SUBCAT_TEXT:
			return state;
		case CategoryActionTypes.ADD_SUBCAT_TEXT_SUCCESS:
			return { ...state, Texts: [ ...state.Texts, action.text ] }

		case CategoryActionTypes.RESET:
			return initialCategoriesState;
		default: 
			return state;

	}
}