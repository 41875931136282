import { ICatManageState } from "../state/catmanage.state";
import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';

const selectState =  (state:IAppState) => state.catmanage;

export const selectModule = createSelector(
	selectState,
	(state: ICatManageState) => state.Module
)
export const selectCategories = createSelector(
	selectState,
	(state: ICatManageState) => state.Categories
)
export const selectEditingCategory = createSelector(
	selectState,
	(state:ICatManageState) => state.EditingCategory
)
export const selectCategoryToDelete = createSelector(
	selectState,
	(state:ICatManageState) => state.CategoryToDelete
)
export const selectActiveCategory = createSelector(
	selectState,
	(state: ICatManageState) => state.ActiveCategory
)
export const selectSubcategories = createSelector(
	selectState,
	(state: ICatManageState) => state.Subcategories
)
export const selectEditingSubcategory = createSelector(
	selectState,
	(state:ICatManageState) => state.EditingSubcategory
)
export const selectSubcategoryToDelete = createSelector(
	selectState,
	(state:ICatManageState) => state.SubcategoryToDelete
)
export const selectActiveSubcategory = createSelector(
	selectState,
	(state: ICatManageState) => state.ActiveSubcategory
)
export const selectSubcatTexts = createSelector(
	selectState,
	(state: ICatManageState) => state.Texts
)
export const selectEditingSubcatText = createSelector(
	selectState,
	(state: ICatManageState) => state.EditingText
)
export const selectSubcatTextToDelete = createSelector(
	selectState,
	(state: ICatManageState) => state.TextToDelete
)
export const selectDepth = createSelector(
	selectState,
	(state: ICatManageState) => state.Depth
)
export const selectListTypes = createSelector(
	selectState,
	(state: ICatManageState) => state.ListTypes
)
export const selectDocOutline = createSelector(
	selectState,
	(state: ICatManageState) => state.DocOutline
)
