<mat-dialog-content class="started-container">
  <div class="dialogContent">
    <div class="blocks-section">
      <div class="div-section">
        <div class="signup-title">
          <h1> 
            Getting started!
          </h1>
        </div>
        <!--
        <div class="getting-started-left" ></div>
        <div class="getting-started-right"></div>
        -->
        <div class="container">
          <acc-slider class="slider" (onClose)="this.close()">

            <div appSliderItem >
              <mat-card class="example-card">
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <div class="thanks-container">
                        <div class="thanks-text">
                          Thanks for using:
                        </div>
                        <div class="thanks-logo"></div>
                      </div>
                      <div class="welcome-quote">
                        "We are introducing a whole new way to sell life insurance..."
                      </div>
                      <div class="signature-container">
                        <div class="testimony-signature">
                          <div>- Tony Wilson</div>
                          <div class="testimony-signature-title">
                          EVP, One Resource Group
                          </div>
                        </div>
                        <div class="org-logo">
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

            <div appSliderItem>
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title class="black-title">Ready to Accelerate?  We’d like to share some information to get you on your way…</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                 <div class="content-container">
                   <div class="content">
                    <div class="blue-subtitle" >Where do I access the Accelerate system?</div>
                     <!--<ul>
                       <li>
                        Just as in the past, click on "Run a Term Quote" on ORG website:
                        <p>
                          <img src="../../../assets/images/started/slide1.png" />
                        </p>
                       </li>
                       <li>
                        <b>Or</b> by using the custom URL established in the Company screen within Accelerate:
                        <p>
                          <a href="{{ this.org.organizationUrl}}">{{ this.org.organizationUrl}}</a>
                        </p>
                       </li>
                     </ul>-->
                     <ul>
                      <li>
                        <a href="{{ this.org.organizationUrl}}">{{ this.org.organizationUrl}}</a>
                      </li>
                    </ul>

                   </div>
                   <div class="rocketman-image first-rocketman"></div>
                 </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

            <div appSliderItem *ngIf="paidSubscription">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Complete your Accelerate user profile</mat-card-title>
                  <mat-card-subtitle></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <ul>
                        <li>
                          Your profile information is used to personalize term life proposals, emails and videos with your brand!
                        </li>
                        <li>
                          To complete your profile information, select <b>My Profile(s)</b> from the menu shown below
                          <p>
                            <img src="../../../assets/images/started/slide2.png" />
                          </p>
                        </li>
                        <li>
                          <b>Or </b><a (click)="goToProfile()">click here</a> to update your profile now
                        </li>
                      </ul>
                    </div>
                    <div class="rocketman-image"></div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

            <div appSliderItem >
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Run a new term life quote</mat-card-title>
                  <mat-card-subtitle></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <ul>
                        <li [ngClass]="{removeBullet: !paidSubscription}">
                          Build a term life quote, in just a few minutes, with access to over 50 carriers. Select <b>New Life Quote</b> from the left sidebar menu to get started.
                          <p>
                            <img src="../../../assets/images/started/slide3.png" />
                          </p>
                        </li>
                        <li *ngIf="paidSubscription">
                          Send a personalized proposal to help educate your client & sell your quote.
                        </li>
                      </ul>
                    </div>
                    <div class="rocketman-image"></div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

            <div appSliderItem *ngIf="paidSubscription">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Analytics & Reporting</mat-card-title>
                  <mat-card-subtitle></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <ul>
                        <li>
                          Use “Analytics & Reporting” to view all your quoting & application activity on one page. Customize the data and graphical outputs by: Premium, Face Amount, Count, Date, & Carrier
                        </li>
                        <li>
                          Business Analytics are available from the Home screen.
                        </li>
                        <li>
                          Select <b>Analytics & Reporting</b> from the left sidebar menu for more detail.
                          <p>
                            <img class="analytics-image" src="../../../assets/images/started/slide4.png" />
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rocketman-image"></div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

            <div appSliderItem *ngIf="paidSubscription">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Self Service Quoting</mat-card-title>
                  <mat-card-subtitle></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <ul>
                        <li>
                          With a click of a button, activate your own consumer-direct quoting website. Select <b>Self Service Quoting</b> from the Term Life Quote drop-down menu on the Accelerate left side menu bar to get started.
                          <p>
                            <img  class="medium-image" src="../../../assets/images/started/slide8.png" />
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rocketman-image"></div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

            <div appSliderItem *ngIf="paidSubscription">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Leverage the Accelerate Asset Library</mat-card-title>
                  <mat-card-subtitle></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <ul>
                        <li>
                          The Asset Library, built by your ADMIN user, is a centralized storage location for all insurance-based media (PDFs, Videos, Podcasts) which you can choose to send to your clients... with or without a term quote proposal.
                          <p>
                            <img  class="medium-image" src="../../../assets/images/started/slide5.png" />
                          </p>
                        </li>
                        <li>
                          Select <b>Asset Library</b> from the left sidebar menu to get started.
                        </li>
                      </ul>
                    </div>
                    <div class="rocketman-image"></div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

            <div appSliderItem *ngIf="paidSubscription">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Personalized Video</mat-card-title>
                  <mat-card-subtitle></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <ul>
                        <li>
                          You can select insurance video templates from the Accelerate Personalized Video Library and personalize them with your logo, brokerage name and/or headshot. You can also add text overlays with call to actions and contact information! You can send them out to clients and prospects or even post on social media!
                          <p>
                            <img class="pvideo-image" src="../../../assets/images/started/slide6.png" />
                          </p>
                        </li>
                        <li>
                          Select <b>Personalized Video</b>  from the left sidebar menu to get started.
                        </li>
                      </ul>
                    </div>
                    <div class="rocketman-image"></div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

            <div appSliderItem *ngIf="paidSubscription">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Do you need to add more Accelerate users from your Organization?</mat-card-title>
                  <mat-card-subtitle></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <ul>
                        <li>
                          Share Accelerate with the rest of your team!
                        </li>
                        <li>
                          Create new users by selecting <b>Users</b> from the <b>Admin</b> menu shown below, then click <b>Add User.</b> 
                          <p>
                            <img class="small-image" src="../../../assets/images/started/slide7.png" />
                          </p>
                        </li>
                        <li>
                          Once complete, your new user can access the system at the same URL you use. Just provide them with the password you setup for them and URL:
                          <p>
                            <a href="{{ this.org.organizationUrl}}">{{ this.org.organizationUrl}}</a>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rocketman-image"></div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>


            <div appSliderItem *ngIf="!paidSubscription">
              <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title>Why ORG brokers go Pro?</mat-card-title>
                  <mat-card-subtitle></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <div class="content-container">
                    <div class="content">
                      <ul>
                        <li>Send term quotes and proposals to clients.</li>
                        <li>Client/recipient engagement insight.</li>
                        <li>Send personalized and branded videos.</li>
                        <li>Access to rich, insurance-based media.</li>
                        <li>Consumer Self-Quoting Platform allows your prospects and customers to generate term life quotes 24/7 on a site branded for your brokerage.</li>
                      </ul>
                      <div class="signup-button-container">
                        <button *ngIf="isAdmin" mat-raised-button class="signup-button" color="primary" (click)="upgrade()" >Upgrade today</button>
                        <span *ngIf="!isAdmin" class="signup-features-title" >Contact an Administrator in your organization and ask them to Go Pro today!</span>
                      </div>                    </div>
                    <div class="rocketman-image"></div>
                  </div>
                </mat-card-content>
                <mat-card-actions>
                </mat-card-actions>
              </mat-card>
            </div>

          </acc-slider>


        </div>

      </div>
      <div class="close" (click)="close()"><mat-icon>highlight_off</mat-icon></div> 
    </div>

  </div>
</mat-dialog-content>
