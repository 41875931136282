<div class="container company-container">
  <mat-card >
    <mat-card-header>
      <mat-card-title>Company Information</mat-card-title>
    </mat-card-header>

    <mat-card-content >
      <form [formGroup]="companyFormGroup" class="company__company--grid">
        <mat-form-field appearance="outline" class="company__name" >
          <mat-label>Company Name *</mat-label>
          <input matInput type="text" formControlName="CompanyName" (keyup)="formIsDirty = true" />
        </mat-form-field>
  
        <div class="company__resource">
          <span>https://accelerate.consumeroptix.com/</span> 
          <mat-form-field appearance="outline" class="resource-mat-field" >
            <mat-label>Custom URL where you will access your site *</mat-label>
            <input matInput type="text" formControlName="ResourceName" (change)="showCompanyUrlMessage()"  (keypress)="preventChars($event)" (keyup)="formIsDirty = true" />
            <mat-error *ngIf="companyFormGroup.controls.ResourceName.hasError('maxlength')">Url must have a maximum of 100 characters</mat-error>
          </mat-form-field>
        </div>

        <div class="company__logo">
          <acc-flow-profile-photo type="Logo" [tooltip]="tooltips.company_logo_signup"  [photo]="logo" [buttonText]="'Upload Logo'" [defaultTitle]="'Company Logo'" [cssClass]="'signup signup-logo'" #logoPhoto [accept]="imageFileTypes" [errorMessage]="'File must be an image'"></acc-flow-profile-photo>
        </div>

      </form>

    </mat-card-content>
  </mat-card>

  <acc-main-org-profile [orgID]="user.orgID" #orgProfile></acc-main-org-profile>

  <div class="company__next">
		<button mat-raised-button class="company-button" color="primary"  (click)="submitForm()" >Save</button>
	</div>

</div>
