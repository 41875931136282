import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit, OnDestroy, ChangeDetectorRef, Output } from "@angular/core";
import { ProfilePhoto } from "acc-models";
import * as Croppie from "croppie";
import { Subject } from "rxjs";
import { tooltips } from "../../../tooltips/tooltips";

@Component({
	selector: "acc-flow-profile-photo",
	templateUrl: "./profile-photo.component.html",
	styleUrls: ["./profile-photo.component.scss"],
})
export class ProfilePhotoComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	@Input() type: "Logo" | "Individual" | "Team" | "Partner" | "";
	@Input() photo: ProfilePhoto;
	@Input() buttonText: string = "Upload Photo";
	@Input() defaultTitle: string = "Image";
	@Input() defaultDescription: string = "*optional";
	@Input() caption: string = "";
	@Input() accept: Array<string>;
	@Input() errorMessage: string;
	@Input() cssClass: string = "";
	@Input() tooltip: string = "";
	@ViewChild("fileInput", { static: true }) fileInput: ElementRef;
	@ViewChild("croppieImage") croppieImage: ElementRef;
	@Output() onFileSelected: Subject<File> = new Subject();
	private croppie: Croppie;
	private file: File;
	private dimensions: any = {
		Logo: { width: 200, height: 130 },
		Individual: { width: 207, height: 277 },
		Team: { width: 207, height: 277 },
		Partner: { width: 207, height: 277 },
		Profile: { width: 207, height: 207 },
	};
	public displayError: boolean = false;
	public tooltips = tooltips;
	public selectedTooltip: string;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
		//window['EXIF'] = EXIF;
	}

	ngOnInit() {
		this.selectedTooltip = (this.tooltip!= "")?this.tooltip: this.tooltips.upload_files;
	}

	ngAfterViewInit(): void {
		if (this.photo) {
			this.changeDetectorRef.detectChanges();
			this.initCroppie();
		}
	}

	ngOnChanges(changes:SimpleChanges) {
		if(changes.photo && changes.photo.currentValue) {
			this.changeDetectorRef.detectChanges();
			this.initCroppie();
		}
	}

	initCroppie() {
		const dim = this.dimensions[this.type] || { width: 150, height: 150 };
		let croppieOpts: Croppie.CroppieOptions = {
			viewport: { width: dim.width, height: dim.height, type: "square" },
			enforceBoundary: false,
		};
		this.croppie && this.croppie.destroy();

		this.croppie = new Croppie(this.croppieImage.nativeElement, croppieOpts);
		if (this.photo) {
			var photo: ProfilePhoto = this.photo;
			this.croppie.bind({
				url: photo.imageURLPath,
				points: [photo.topLeftX, photo.topLeftY, photo.bottomRightX, photo.bottomRightY],
				zoom: photo.zoom,
			});
		}
		if(this.croppieImage){
			this.croppieImage.nativeElement.addEventListener("update", () => this.onFileSelected.next(null));
		}
	}
	upload(e) {
		if(!e.target.files[0]) { return; }
		this.file = e.target.files[0];
		const ext = this.file.name.substring(this.file.name.lastIndexOf(".")).toLowerCase();
		if (this.accept && !this.accept.some((e) => e.toLowerCase() == ext)) {
			this.displayError = true;
			this.fileInput.nativeElement.value = "";
			this.errorMessage = this.errorMessage || "File must be one of these types: " + this.accept.join(", ");
			return;
		}
		this.displayError = false;
		const dim = this.dimensions[this.type] || { width: 150, height: 150 };
		let croppieOpts: Croppie.CroppieOptions = {
			viewport: { width: dim.width, height: dim.height, type: "square" },
			enforceBoundary: false,
		};
		this.photo = new ProfilePhoto();
		this.croppie && this.croppie.destroy();
		this.changeDetectorRef.detectChanges();
		this.croppie = new Croppie(this.croppieImage.nativeElement, croppieOpts);
		this.croppie.bind({
			url: URL.createObjectURL(e.target.files[0]),
		});
		this.onFileSelected.next(this.file);
	}
	delete() {
		this.photo = null;
		this.croppie.destroy();
		this.croppie = null;
	}
	public getData(): { photo: ProfilePhoto; file: File } {
		if (this.croppie && this.photo) {
			const croppieData = this.croppie.get();
			this.photo.topLeftX = croppieData.points[0];
			this.photo.topLeftY = croppieData.points[1];
			this.photo.bottomRightX = croppieData.points[2];
			this.photo.bottomRightY = croppieData.points[3];
			this.photo.zoom = croppieData.zoom;
			return { photo: this.photo, file: this.file };
		} else {
			return { photo: null, file: null };
		}
	}

	ngOnDestroy(): void {
		if (this.croppie) {
			this.croppie.destroy();
		}
	}

	public update() {
		if (this.photo) {
			this.changeDetectorRef.detectChanges();
			this.initCroppie();
		}
	}
}
