export class HealthConditions {
  public isSelected: boolean;
  public physicalBuild: PhysicalBuild = new PhysicalBuild();
  public tobaccoUse: TobaccoUse = new TobaccoUse();
  public bloodPressure: BloodPressure = new BloodPressure();
  public cholesterol: Cholesterol = new Cholesterol();

  constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class PhysicalBuild {
  public height: string;
  public weight: number;

  constructor(o?: any) {
		Object.assign(this, o);
	}
}

export class TobaccoUse {
  public everUseTobacco: boolean;
  public lastuserTobacco: string;
  public kindOfTobacco: string;
  public howMany: number;

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class BloodPressure {
  public bloodPressureMedicationCurrentlyUse: boolean;
  public bloodPressureMedicationUsedInPast: boolean;
  public bloodPressureMedicationNeverUsed: boolean;
  public bloodPressureSystolic: number;
  public bloodPressureDiastolic: number;

  constructor(o?: any) {
    Object.assign(this, o);
	}
}

export class Cholesterol {
  public cholesterolMedicationCurrentlyUse: boolean;
  public cholesterolMedicationUsedInPast: boolean;
  public cholesterolMedicationNeverUsed: boolean;
  public cholesterolTotalValue: number;
  public cholesterolHDLValue: number;
  public cholesterolHDLRatio: number;

  constructor(o?: any) {
    Object.assign(this, o);
	}
}
