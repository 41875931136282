
<div class="docDetailsTop">
	<div class="intro">
		<p>Welcome <span>{{user.firstName}}</span>,</p>
		<p>Let's get started.</p>
	</div>
</div>
<div class="docDetailsBottom" *ngIf="docDetails">
	<div class="formContainer">
		<ul>
			<li class="editBox">
				<mat-form-field appearance="outline">
					<mat-label>Name your File</mat-label>
					<input matInput type="text" autocomplete="off" [formControl]="docDetails.controls['docDesc']" placeholder="Name your file" tabindex="1"/>
					<mat-error *ngIf="docDetails.controls['docDesc'].hasError('required')">Name of file is required</mat-error>
				</mat-form-field>
			</li>
			<li class="editBox">
				<acc-common-account-selector (AccountSelected)="handleAccountSelected($event)" [hasCrmToken]="user.hasCRMTokenData" [label]="'Client name'" [(value)]="account" [control]="docDetails.controls['prospectName']" #accountSelector [tabindex]="2"></acc-common-account-selector>
			</li>
			<li *ngIf="document && document.prospectLogoFile">
				<div class="prospectLogo" [style.backgroundImage]="'url('+document.prospectLogoFile+')'"></div>
				
			</li>
			<li class="actions">
				<div class="buttons">
					<div class="btn red centered" *ngIf="document && document.prospectLogoFile" (click)="removeLogo()"><i class="fa fa-minus-circle"></i><span>Remove</span></div>
					<div class="fileUploadButton">
						<input type="file" autocomplete="off" #fileInput (change)="onFileChange($event)" tabindex="3">
						<div class="btn dark centered"><i class="fa fa-upload"></i><span>{{document && document.prospectLogoFile ? 'Update' : 'Upload company logo'}}</span></div>
						<span class="errorMessage" *ngIf="showFileTypeError"></span>
					</div>
				</div>
				<div class="fileToUploadName" *ngIf="message">{{message}}</div>
			</li>
			<li class="select">
				<mat-form-field appearance="outline">
					<mat-select [formControl]="docDetails.controls['consultant2']" tabindex="4">
						<mat-option value="" >Add Consultant</mat-option>
						<mat-option *ngFor="let consultant of consultants" value="{{consultant.userID}}">{{consultant.firstName}} {{consultant.lastName}}</mat-option>
					</mat-select>
				</mat-form-field>
			</li>
			<li class="editBox">
				<mat-form-field appearance="outline">
					<mat-label>Display Date</mat-label>
					<input matInput autocomplete="off" [formControl]="docDetails.controls['displayDate']" tabindex="5">	
				</mat-form-field>
			</li>
			<li>
				<div class="btn orange centered" (click)="save()" tabindex="6"><span>continue</span></div>
			</li>
		</ul>
	</div>
</div>
