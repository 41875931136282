
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
	selector: 'acc-main-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {
	@ViewChild('videoPlayer') videoPlayer;
	private moduleItemId:string;
	private routeParamsSubscription: SubscriptionLike;
	private playlist:Array<any>;
	private playlistCounter:number = 0;
	private playlistLength:number;
	public activeVideoUrl:string = '';
	 
    constructor (private dataService: DataService, private router:ActivatedRoute, private sessionService: SessionService) {
    }
    
    playNextVideo() {
		this.activeVideoUrl = this.playlist[++this.playlistCounter % this.playlistLength].videoPath;
		this.videoPlayer.nativeElement.load();
		this.videoPlayer.nativeElement.play();
	}
    ngOnInit():void {
		this.routeParamsSubscription = this.router.queryParamMap.subscribe(params => {
			this.moduleItemId = params.get('mediaItemid') || params.get('mediaItemId') || params.get('mediaItemID');
			let userId = params.get('userid') || params.get('userId') || params.get('userID');
			let profileId = params.get('profileid') || params.get('profileId') || params.get('profileID');
			let personalize = params.get('personalize');
			this.dataService.getPlaylist(userId, this.moduleItemId, personalize, profileId).subscribe((list:any) => {
				this.playlist = list;
				this.playlistLength = this.playlist.length;
				this.activeVideoUrl = this.playlist[0].videoPath;
			})
		});
	}
 
	ngOnDestroy():void {
		if(this.routeParamsSubscription) { this.routeParamsSubscription.unsubscribe(); }
	}
}
