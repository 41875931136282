<div mat-dialog-header>
	<h2 mat-dialog-title>Preferences</h2>
</div>

<mat-dialog-content>
	<div class="dialogContent">
		<ul class="preferences">
			<li>
        <div class="preferences-dropdown">
          <mat-form-field appearance="outline">
            <mat-label>Set View</mat-label>
            <select matNativeControl  [(ngModel)]="selectedClassicView">
              <option [value]="0">Standard View</option>
              <option [value]="1" >Classic View</option>
            </select>
          </mat-form-field>
        </div>
			</li>
			<li>
        <div class="preferences-dropdown">
          <mat-form-field appearance="outline" >
            <mat-label>Color Palette</mat-label>
            <select matNativeControl  [(ngModel)]="colorPalette">
              <option [value]="1">Blue</option>
            </select>
          </mat-form-field>
        </div>
			</li>
		</ul>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="savePreferences()" >Save Preferences</button>
	<button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>
