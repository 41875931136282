import { Directive, ElementRef, Input, Output, OnChanges, SimpleChanges, HostListener, EventEmitter, OnInit } from '@angular/core';
import { Media, Folder } from 'acc-models';

@Directive({
  selector: '[appCartActions]'
})
export class CartActionDirective implements OnInit, OnChanges {

	@Input('appCartActions') showCartActions: string;
	@Input() folder: Folder
	@HostListener('click', ['$event']) onclick($event) {
		[].forEach.call(document.getElementsByClassName('folderMenu'), c => c.classList.remove('open'));  //close any that are already open
		this.handleClick($event);
		$event.cancelBubble = true;
		$event.stopPropagation();
	}
	
	@HostListener('document:click', ['$event']) onbodyclick($event) {
		if(this.menu) { this.menu.classList.remove('open'); }
	}
	
	@Output() actionClick: EventEmitter<{actionName: string, payload: any }> = new EventEmitter();
	private el:ElementRef;
	private menu:HTMLElement;
	
  	constructor(el:ElementRef) {
		  this.el = el;
	  }

	ngOnInit(): void {
		if(!this.showCartActions) { return }
				
		let wrapper = document.createElement('div');
		wrapper.className = "folderActions";
		wrapper.setAttribute('data-action', 'expandCart');
		wrapper.innerHTML = '<div data-action=\"expandCart\"></div><div data-action=\"expandCart\"></div><div data-action=\"expandCart\"></div>';
		this.el.nativeElement.appendChild(wrapper);

		let menuHolder = document.createElement('div');
		menuHolder.className = "folderMenu";
		let list = document.createElement('ul');
		let actions: Array<string> = [];
		if(this.folder.mediaCategoryDesc == 'Cart') { //wish there was a better way to identify the main cart
			actions.push('Clear');
			actions.push('Save');
		} else {
			actions.push('Delete');
			actions.push('Rename');
			actions.push('Activate');
		}
		actions.forEach(p => {
			let item = document.createElement('li');
			item.classList.add("action"+p, 'folderAction');
			switch(p) {
				case 'Clear': item.innerHTML = 'Clear'; item.setAttribute('data-action', 'clearCart'); break;
				case 'Delete': item.innerHTML = 'Delete'; item.setAttribute('data-action', 'deleteCart'); break;
				case 'Save': item.innerHTML = 'Save'; item.setAttribute('data-action', 'saveCart');break;
				case 'Rename': item.innerHTML = 'Rename'; item.setAttribute('data-action', 'renameCart');break;
				case 'Activate': item.innerHTML = 'Activate'; item.setAttribute('data-action', 'activateCart');break;
			}
			list.appendChild(item);
		});
		menuHolder.appendChild(list);
		this.el.nativeElement.appendChild(menuHolder);
		this.menu = menuHolder;
   }

	handleClick(event) {
		let action = event.target.getAttribute('data-action');
		switch (action) {
			case 'expandCart':
				this.actionClick.emit({ actionName: 'expandCart', payload: {id: this.folder.mediaCategoryID} })
				this.menu.classList.add('open');
				break;
			case 'deleteCart':
				this.actionClick.emit({ actionName: 'deleteCart', payload: {folder: this.folder} });
				this.menu.classList.remove('open');
				break;
			case 'saveCart':
				this.actionClick.emit({ actionName: 'saveCart', payload: {folder: this.folder} });
				this.menu.classList.remove('open');
				break;
			case 'renameCart':
				this.actionClick.emit({ actionName: 'renameCart', payload: {folder: this.folder} });
				this.menu.classList.remove('open');
				break;
			case 'activateCart':
				this.actionClick.emit({ actionName: 'activateCart', payload: {folder: this.folder} });
				this.menu.classList.remove('open');
				break;
			case 'clearCart':
				this.actionClick.emit({ actionName: 'clearCart', payload: {folder: this.folder} });
				this.menu.classList.remove('open');
				break;

		}
	}
	ngOnChanges(changes:SimpleChanges) {
		if(changes['currentFolder'] && changes['currentFolder'].currentValue) {
			this.folder = changes['currentFolder'].currentValue;
		}
	}

}
