import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation } from "@angular/core";
import { DataService, SessionService, QuoteService, MixpanelService, TourService } from "acc-services";
import { Organization, Module, User, Settings, WelcomeBlock } from "acc-models";
import { NavigationEnd, Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogComponent, PersonalizeDialogComponent } from "acc-common";
import { SubscriptionLike, Observable } from "rxjs";
import { SalesforceLoginComponent } from "../shared/salesforce-login/salesforce-login.component";
import { take, map } from "rxjs/operators";
import { ModuleAssetLibraryComponent } from "./module-asset-library/module-asset-library.component";
import * as moment from "moment";
import { NewQuote } from "../../store/actions/quote.actions";
import { IAppState } from "../../store/state/app.state";
import { select, Store } from "@ngrx/store";
import { LockFeaturesComponent } from "../lock-features/lock-features.component";
import { TrialFeaturePopupComponent } from "../shared/trial-feature-popup/trial-feature-popup.component";
import { HideTrialFeature, ShowTrialFeature } from "../../store/actions/status.actions";
import { selectDaysLeft, selectShouldShowTrialFeature, selectTrialFeature } from "../../store/selectors/status.selectors";
@Component({
	templateUrl: "./welcome.component.html",
	styleUrls: ["./welcome.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class WelcomeComponent implements OnInit, OnDestroy, AfterViewInit {
	private CHART_LIMIT = 5;
	private organization: Organization;
	public settings: Settings;
	public user: User;
	private dialogRef: MatDialogRef<DialogComponent>;
	private salesforceDialogRef: MatDialogRef<SalesforceLoginComponent>;
	public showModuleAssetLibrary: boolean = false;
	public selectedModuleId: number;
	private getModulesSubscription: SubscriptionLike;
	public modules$: Observable<Module[]>;
	public dataPoint1: { name: string; field: string };
	public dataPoint2: { name: string; field: string };
	public rawData: { Brokers: Array<any>; Carriers: Array<any>; TimePeriods: Array<any> };
	public brokerData: Array<any>;
	public carrierData: Array<any>;
	public timePeriodData: Array<any>;
	public showCharts: boolean = false;
	public lockedFeaturesDialogRef: MatDialogRef<LockFeaturesComponent>;
	public visibleWelcomeBlocks: Array<WelcomeBlock>;

	public showTrialFeature: boolean = false;
	public featureNumber: number = 4;
	private featureSubscription: SubscriptionLike;
	private daysLeftSubscription: SubscriptionLike;

	constructor(private dataService: DataService, private sessionService: SessionService, private router: Router, 
		private dialog: MatDialog, private store: Store<IAppState>, private quoteService: QuoteService, private tourService: TourService) {}

	ngOnInit(): void {
		this.user = this.sessionService.getUser();
		this.settings = this.sessionService.settings;
		this.visibleWelcomeBlocks = this.settings.welcomeBlocks.filter((b) => !b.permission || this.user.admin);
		this.organization = this.sessionService.getItem<Organization>("organization");
		this.modules$ = this.dataService.getModules(this.organization.orgID).pipe(map((mods: any) => mods.map((m) => new Module(m))));
		if (this.user.integrateCRM && !this.user.hasCRMTokenData && !this.user.skipCRM) {

			setTimeout(() => {
				this.salesforceDialogRef = this.dialog.open(SalesforceLoginComponent);
				this.salesforceDialogRef
					.afterClosed()
					.pipe(take(1))
					.subscribe((didLogin) => {
						if (!didLogin) {
							this.sessionService.setUser({ ...this.user, skipCRM: true });
						}
					});
			}, 200);

		}
		this.sessionService.onUserSaved.subscribe((u) => {
			this.user = u;
		});
		this.dataPoint1 = { name: "Quoted", field: "QuotedPremium" };
		this.dataPoint2 = { name: "Applied", field: "AppliedPremium" };
		this.showCharts = this.settings.welcomeBlocks.some((b) => b.type == "analytics");
		if (this.showCharts) {
			this.getChartData();
		}

		this.featureSubscription = this.store.pipe(select(selectTrialFeature)).subscribe(resp => {
			this.showTrialFeature = resp;
		})

		this.daysLeftSubscription = this.store.pipe(select(selectShouldShowTrialFeature)).subscribe(resp => {
			if(resp) {
				let featureShown = this.sessionService.getItem('featureShown') === "true";
				if(!featureShown) {
					this.tourService.cancel();
					this.store.dispatch(new ShowTrialFeature());
				}
			}
		});
	}

	closeDialog(media): void {
		this.dialogRef.close();
		this.showModuleAssetLibrary = false;
		if (media && media.docID) {
			//emitted by selecting media item
			this.router.navigate(["/module", media.moduleID, media.docID]);
		}
	}
	showDocuments(moduleId) {
		this.selectedModuleId = moduleId;
		const moduleAssetLibraryDialogRef = this.dialog.open(ModuleAssetLibraryComponent, {
			data: { moduleId: this.selectedModuleId },
		});

		moduleAssetLibraryDialogRef.afterClosed().subscribe((media) => {
			if (media && media.docID) {
				//emitted by selecting media item
				this.router.navigate(["/module", media.moduleID, media.docID]);
			}
		});
	}
	startNewQuote() {
		if(this.router.url.includes("/quote") && !this.router.url.includes("/quote/")){
			this.store.dispatch(new NewQuote());
		}
		this.router.navigateByUrl("/quote");
	}
	getChartData() {
		this.quoteService.getAnalytics({ orgId: this.sessionService.getUser().orgID, start: moment().startOf("year").format("MM/DD/YYYY"), end: moment().endOf("year").format("MM/DD/YYYY"), userID: this.sessionService.getUser().userID }).subscribe((resp: any) => {
			this.rawData = resp;
			this.selectData();
		});
	}
	private selectData() {
		this.brokerData = this.rawData.Brokers.map((b) => {
			return { name: b.Name, id: b.id, [this.dataPoint1.name]: b[this.dataPoint1.field] || 0, [this.dataPoint2.name]: b[this.dataPoint2.field] || 0 };
		});
		this.carrierData = this.rawData.Carriers.map((c) => {
			return { name: c.Name, [this.dataPoint1.name]: c[this.dataPoint1.field] || 0, [this.dataPoint2.name]: c[this.dataPoint2.field] || 0 };
		});
		this.timePeriodData = this.rawData.TimePeriods.map(s => { return { name: s.Name, [this.dataPoint1.name]: s[this.dataPoint1.field] || 0, [this.dataPoint2.name]: s[this.dataPoint2.field] || 0}});
	}


	personalizeAndSend() {
		this.dataService.getPreviousCart(this.user.userID).subscribe((resp: any) => {
			if (resp.recentSession) {
				this.dialog.open(PersonalizeDialogComponent, { data: { mainCartId: resp.recentSession }, minWidth: 675 });
			} else {
				this.dataService.startSession(this.user.userID).subscribe((cart: any) => {
					this.dialog.open(PersonalizeDialogComponent, { data: { mainCartId: cart.cartSessionID }, minWidth: 675 });
				});
			}
		});
	}

	validateSubscription(action) {
		if (this.sessionService.canUseSubscriptionLevelFeature(action)) {
			this.router.navigateByUrl(action);
		} else {
			this.lockedFeaturesDialogRef = this.dialog.open(LockFeaturesComponent, {
				panelClass: "lock-features",
			});
		}
	}

	ngOnDestroy(): void {
		if (this.getModulesSubscription) {
			this.getModulesSubscription.unsubscribe();
		}
		if(this.daysLeftSubscription) { this.daysLeftSubscription.unsubscribe(); }
		if(this.featureSubscription) { this.featureSubscription.unsubscribe(); }
	}
	ngAfterViewInit(): void {
		const event = document.createEvent('Event');
		event.initEvent('welcomeMessageClosed', false, true); //this is needed to fire the shepherd tour *after* closing welcome message
		if (this.organization.whatsNew && this.sessionService.getItem("sawWelcomeMessage") != "true") {
			setTimeout(() => {
				this.dialogRef = this.dialog.open(DialogComponent, {
					data: {
						messages: [this.organization.whatsNew, this.user.shareList].filter((m) => !!m).map((m) => `<pre>${m}</pre>`), //filter out nulls
						title: this.organization.whatsNewTitle,
					},
					panelClass: "welcome-dialog-container",
				});
				this.sessionService.setItem("sawWelcomeMessage", "true");
				this.dialogRef.afterClosed().pipe(take(1)).subscribe(() => { if(!this.tourService.isCancelled) { document.getElementById('welcomeContainer').dispatchEvent(event) } })
			}, 200);
		} else {
			//if we're not displaying welcome message, still fire event to show shepherd tour (unless it's been cancelled)
			if(!this.tourService.isCancelled) {
				setTimeout(() => { document.getElementById('welcomeContainer').dispatchEvent(event); }, 500);
			}
		}
	}

	closeTrialFeature() {
		this.store.dispatch(new HideTrialFeature());
	}
	get hasModules() {
		return this.settings.welcomeBlocks.some((b) => b.type == "modules");
	}
}
