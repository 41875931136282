import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'acc-services';
@Component({
  selector: 'acc-main-programs-selection',
  templateUrl: './programs-selection.component.html',
  styleUrls: ['./programs-selection.component.scss']
})
export class ProgramsSelectionComponent implements OnInit {

  @Input() public mktPrograms: any[];
  @Output() programsLoaded: EventEmitter<any> = new EventEmitter();
  public selectedMktProgram = null;
  public days = [];

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.dataService.getMktPrograms().subscribe((programs:any) => {
      this.buildProgramsSelectionList(programs);
	  this.programsLoaded.next(programs);
    });
    this.days = this.getDaysToSelect();
  }

  private buildProgramsSelectionList(programs){
    programs.forEach(p => {
		let dateField: string;
		switch(p.dateField) {
			case "birthdate": dateField = 'Birthdate'; break;
			case "autoRenewalDate": dateField = "Auto Policy Renewal Date"; break;
			case "homeRenewalDate": dateField = "Home Policy Renewal Date"; break;
			case "pirDate": dateField = "Personal Insurance Review Date"; break;
			case "lifeTerminationDate": dateField = "Life Policy Term Expiration"; break;
			default: dateField = p.dateField; break;
		}
      this.mktPrograms.push({ ...p, dateField });
    });
  }

  selectMktProgram(program){
    this.selectedMktProgram = program;
  }

  getDaysToSelect(){
    const days: Array<any> = [];
		days.push({ name: "On Date", id: 0 });
		days.push({ name: "1 day", id: 1 });
		for (let index = 2; index <= 60; index++) {
			days.push({ name: `${index} days`, id: index });
		}
		return days;
  }

}
