import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Organization, User, Settings } from 'acc-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CheckoutService, SessionService, DataService } from 'acc-services';
import { Router } from '@angular/router';
import * as moment from "moment";
import { environment } from '../../../environments/environment'; 
import { loadStripe } from '@stripe/stripe-js';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { SetErrorMessage } from '../../store/actions/status.actions';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'acc-main-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  selectedPlan = 0;
  plans = [{
    id:0,
    nickname:"Free",
    amount: 0
  },
  {
    id:1,
    nickname:"Monthly",
    amount: 2500
  }];

  STRIPE_KEY = environment.stripe_key;
  @ViewChild('cardElement') cardElement: ElementRef;
  stripe;
  card;
  cardErrors;
  loading = false;
  confirmation;

  isPaidPlanSelected = 0;
  paidSelectedPlan = null;
  subscription = null;
  subscriptionAmount = 0;
  selectedSeats = 1;

  public org: Organization;
  private user: User;
  private getOrgSubscription:SubscriptionLike;
  
  constructor(
    private snackBar: MatSnackBar,
    private checkoutService: CheckoutService,
    private router:Router, 
    private sessionService: SessionService, 
    private store: Store<IAppState>,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.org = this.sessionService.getItem<Organization>('organization');
    this.getPlans();
    this.getSubscriptionInformation();
  }

  subscriptionChange(event) {
    this.isPaidPlanSelected = +event.value;
    if(!!this.isPaidPlanSelected){
      setTimeout(() => {
        this.initStripe();
      }, 1000);
    }
  }

  async initStripe(){
    //this.stripe = Stripe(this.STRIPE_KEY);
    this.stripe = await loadStripe(this.STRIPE_KEY);
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener('change', ({error}) => {
       this.cardErrors = error && error.message;
    });
  }

  getSubscriptionInformation(){

    this.checkoutService.getSubscriptions(this.org.orgID).subscribe((resp:any) => {
      if(resp.subscription != null){
        this.subscription = resp.subscription;
        this.selectedSeats = this.subscription.quantity;
      }
    },
    (err: any) => {
      if(err.error){
        this.snackBar.open(err.error.Message, 'Close', { duration: 5000 });
      }else{
        this.store.dispatch(new SetErrorMessage("Could not access to checkout service"));
      }
    });

  }

  getPlans(){
    this.checkoutService.getPlans().subscribe((resp:any) => {
      var stripePlans = resp.data;

      //Select the first plan:
      this.paidSelectedPlan = stripePlans[0];
      this.subscriptionAmount = this.paidSelectedPlan.tiers[0].unit_amount_decimal;

    },
    (err: any) => {
      if(err.error){
        this.snackBar.open(err.error.Message, 'Close', { duration: 5000 });
      }else{
        this.store.dispatch(new SetErrorMessage("Could not access to SignUp service"));
      }
    });
  }
  
  async submitForm(){
    
    if(!!this.isPaidPlanSelected){
      const { source, error } = await this.stripe.createSource(this.card);
      if(error){
        this.cardErrors = error.message;
      }
      if(!this.cardErrors){
        this.loading = true;
  
        //Create Token and Update
        this.stripe.createToken(this.card).then((cardToken) => {
  
          var subscriptionRenew = {
            token: cardToken.token.id,
            subscriptionId : (this.subscription)?this.subscription.id:0,
            plan: this.paidSelectedPlan.id,
            orgId: this.org.orgID,
            quantity: this.selectedSeats
          }
  
          this.checkoutService.renewSubscription(subscriptionRenew).subscribe((subsc) =>{
  
            this.loading = false;

            this.goToWelcome();
  
          },
          (err) => {
            this.loading = false;
            if(err.error){
              this.snackBar.open(err.error.Message, 'Close', { duration: 5000 });
            }else{
              this.snackBar.open("Error renewing the subscription, please contact the administrator", 'Close', { duration: 5000 });
            }
          });
  
        });
  
      }
    }else{

      //FREE SUBSCRIPTION

      var subscriptionRenew = {
        token: "",
        subscriptionId : "",
        plan: this.selectedPlan.toString(),
        orgId: this.org.orgID
      }

      this.checkoutService.renewSubscription(subscriptionRenew).subscribe((subsc) =>{

        this.loading = false;

        this.goToWelcome();

      },
      (err) => {
        this.loading = false;
        if(err.error){
          this.snackBar.open(err.error.Message, 'Close', { duration: 5000 });
        }else{
          this.snackBar.open("Error renewing the subscription, please contact the administrator", 'Close', { duration: 5000 });
        }
      });


    }
  }

  goToWelcome(){
    var u = new User(this.sessionService.getUser());
    this.sessionService.setUser(u);
    this.sessionService.authToken = u.token;
    
    this.getOrgSubscription = this.dataService.GetOrgFromID(u.orgID).subscribe(org => {
      this.sessionService.removeItem('organization');
      if(org.orgID) {
          this.sessionService.setItem('organization', org);
      }

      //get settings
      this.dataService.getSettings(u.orgID).subscribe(resp => {
        this.sessionService.settings = new Settings(resp);

        this.router.navigate(['/welcome']);
      }, error => {
        //if a problem, set defaults
        this.sessionService.settings = new Settings();

        this.router.navigate(['/welcome']);
      });

    });

  }

}
