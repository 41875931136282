import { CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { QuotesResultsComponent } from '../../quote-results/quotes-results/quotes-results.component';
import { Injectable } from "@angular/core";
import { ProgramsSetupComponent } from '../../mkt/programs-setup/programs-setup.component';

@Injectable()
export class CanDeactivateMktSetup implements CanDeactivate<ProgramsSetupComponent> {
    
    canDeactivate(target: ProgramsSetupComponent,
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) 
    {
      return target.isDirty()?target.canNavigateAway():true;
    }

  }