
<div class="dialog-container">
	<div class="dialog-content">
		<div class="dialogContent">
			<div mat-dialog-header>
				<h2 class="acc-dialog-title" mat-dialog-title>{{data.title}}</h2>
			</div>
			<div class="content-container">
				<div class="content pre-text" [innerHTML]="message" *ngIf="message"></div>
				<div class="content pre-text" *ngFor="let msg of data.messages" >
					<p [innerHTML]="msg"></p>
					
				</div>
			</div>
			<div class="buttons" *ngIf="data.buttons; else defaultButtons">
				<div *ngFor="let button of data.buttons; let i = index" class="btn {{button.color || 'dark'}} dialog-button" (click)="close(i)"><span>{{button.text}}</span></div>
			</div>
		
			<ng-template #defaultButtons>
				<mat-dialog-actions >
					<button class="dialog-button" mat-stroked-button (click)="close(0)">Close</button>
				</mat-dialog-actions>
			</ng-template>
		</div>
	</div>
	<div class="bottom-branding">
		<div class="branding-neg-slant"></div>
		<div class="branding-pos-slant"></div>
	</div>
</div>


	
