<p class="my-quotes-container" id="quote-list-container">


  <mat-card>
    <mat-card-content>
      <div class="global-actions">
        <div hide-in-classic-view class="filter-container">
          <div class="filter-button" (click)="setSourceFilter(0)" [ngClass]="{active: sourceId == 0}">All</div>
          <div class="filter-button" (click)="setSourceFilter(1)" [ngClass]="{active: sourceId == 1}">Agent</div>
          <div class="filter-button" (click)="setSourceFilter(2)" [ngClass]="{active: sourceId == 2}">Self Service</div>
        </div>
        <div class="sort-container">
            <div class="sort-actions">
              <div class="sort-title">Sort quotes by</div>
              <div class="sort-select">
                <mat-form-field appearance="outline">
                  <mat-select [(ngModel)]="sortCriteria" (selectionChange)="sortingChanged($event)">
                    <mat-option value="10" >Date Modified - Descending</mat-option>
                    <mat-option value="20">Date Modified - Ascending</mat-option>
                    <mat-option value="30">Face Amount - Descending</mat-option>
                    <mat-option value="40">Face Amount - Ascending</mat-option>
                    <mat-option value="50">Name - Descending</mat-option>
                    <mat-option value="60">Name - Ascending</mat-option>
                  </mat-select>
                 </mat-form-field>
              </div>
            </div>
        </div>
		<div class="search-container">
			<input matInput type="search" (ngModelChange)="clearFilter($event)" (keyup.enter)="searchQuotes()" class="search-field" [(ngModel)]="searchFilter" placeholder="Search quotes..."/>
			<button mat-raised-button class="search-button" color="primary" (click)="searchQuotes()">Search</button>
			<div class="search-desc">Search by Name, Face Amount (ex. 100,000) or Date Modified (ex. 3/5/2020)</div>
		</div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="quote-list-card">
    <mat-card-content class="quote-rows-container">
        <div  *ngIf="myDisplayQuotes != null && myDisplayQuotes.length > 0">
          <div class="quote-row" *ngFor="let element of myDisplayQuotes" >
            <div class="quote-data-container">
              <div class="quote-title">
				  <mat-icon *ngIf="element.sourceID == 2" title="Self Service Quote">supervisor_account</mat-icon>
				  {{element.clientName}}
				</div>
              <div class="quote-details">{{ element.quoteDesc }}</div>
              <div class="quote-details">{{element.birthdate ? getFormat(element.birthdate) + ', ' : element.actualAge + ' years old,'}} {{element.state}}</div>
              <div class="quote-boxes">
                <div class="quote-box" *ngFor="let v of getBoxes(element)">{{v}}</div>
              </div> 
              <div class="quote-fromNow">{{ fromNow(element.dateEdited) }}</div>
            </div>
            <div class="quote-buttons-container">
				<button mat-raised-button class="quote-button view-quote" color="primary" [routerLink]="['/quote/view-quote/', element.quoteID]" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.q_view_quote}}">View Quote</button>
				<button mat-raised-button class="quote-button edit-quote" color="primary" (click)="editQuote(element.quoteID)"  [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.q_edit_quote}}">Edit Quote</button>
				<acc-main-send-proposal-button hide-in-classic-view (sendProposal)="sendProposal(element.quoteID, $event)"></acc-main-send-proposal-button>
              
				<button mat-raised-button class="quote-button quote-delete-button" (click)="deleteQuote(element.quoteID)"><i class="material-icons">delete</i></button>
           </div>
          </div>
        </div>
        <div class="quote-row no-quote-row" *ngIf="myDisplayQuotes == null || myDisplayQuotes.length <= 0" >
          <div class="no-quotes">
            <div class="quote-title">No quotes found.</div>
          </div>
        </div>
    </mat-card-content>
  </mat-card>
</p>