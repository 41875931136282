
export class CommGroup {
	public groupID: number;
	public orgID: number;
	public groupName: string;


	constructor(o?: any) {
		Object.assign(this, o);
	}
}