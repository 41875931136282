
 import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from "@angular/core";
 import { SessionService, DataService, MixpanelService } from "acc-services";
 import { SetErrorMessage } from "../../store/actions/status.actions";
 import { Store, select } from "@ngrx/store";
 import { IAppState } from "../../store/state/app.state";
 import { QuoteResult } from "projects/acc-models/src/lib/models/quoteResult";
 import { QuoteResponse } from "projects/acc-models/src/lib/models/quoteResponse";
 import { QuoteInput } from "projects/acc-models/src/lib/models/quoteInput";
 import { QuoteCarrier } from "projects/acc-models/src/lib/models/quoteCarrier";
 import { Router, ActivatedRoute } from "@angular/router";
 import { SelectionModel } from "@angular/cdk/collections";
 import { CdkDragDrop, moveItemInArray, CdkDrag } from "@angular/cdk/drag-drop";
 import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSnackBar } from "@angular/material/snack-bar";
 import { QuoteSave } from "projects/acc-models/src/lib/models/quoteSave";
 import { QuoteSaveProps } from "projects/acc-models/src/lib/models/quoteSaveProps";
 import { QuoteResponseInput } from "projects/acc-models/src/lib/models/quoteResponseInput";
 import * as QuoteSelectors from "../../store/selectors/quote.selectors";
 import { tap, take, takeUntil, flatMap, switchMap, mergeMap, concatMap } from "rxjs/operators";
 import { Subject, forkJoin, iif, Observable, fromEvent, SubscriptionLike, timer } from "rxjs";
 import { QuoteRequest } from "projects/acc-models/src/lib/models/quoteRequest";
 import { QuoteService } from "projects/acc-services/src/lib/services/quote.service";
 import { QuoteDetailComponent } from "../quote-detail/quote-detail.component";
 import { QuoteDetailsRequest } from "projects/acc-models/src/lib/models/quoteDetailsRequest";
 import { SetQuoteRequest, SetHealthProfile, GetQuoteFromCache, AddQuoteToCache, SetHealthProfileAlt, GetHealthProfile, SetSelections, SetRecSort, SetSelectedPlans, SetSellingPoints, ClearTemporaryQuoteData } from "../../store/actions/quote.actions";
 import { ProposalTemplate, HealthProfile, Organization, HealthProfileAlt } from "acc-models";
 import { QuoteRecFilter, QuoteRecSort } from "projects/acc-models/src/lib/models/quoteRecFilter";
 import { HealthDetailsAttributes } from "projects/acc-models/src/lib/models/healthDetailsAttributes";
 import { QuoteIgoRequest } from "projects/acc-models/src/lib/models/quoteIgoRequest";
 import { PersonalizeDialogComponent, tooltips, ConfirmComponent, DialogComponent } from "acc-common";
 import { LockFeaturesComponent } from "../../components/lock-features/lock-features.component";
 import { CookieService } from "ngx-cookie-service";
 import { environment } from "../../../../../acc-main/src/environments/environment";
 import { DocbuilerOptionsComponent } from "../../components/shared/docbuiler-options/docbuiler-options.component";
 import { selectClassicView } from "../../store/selectors/status.selectors";
import { ApplyDialogComponent } from "../apply-dialog/apply-dialog.component";
 
 @Component({
 	selector: "acc-main-quotes-results",
 	templateUrl: "./quotes-results.component.html",
 	styleUrls: ["./quotes-results.component.scss"],
 	//encapsulation: ViewEncapsulation.None,
 })
 export class QuotesResultsComponent implements OnInit {
 	terms: Map<String, any> = new Map<String, any>();
 	termsKeys;
 	faceAmountsKeys;
 	logos: Map<number, string> = new Map<number, string>();
 	responseInput: QuoteResponseInput;
 	currentQuoteId: string;
 	displayedColumns: string[] = ["select", "image", "product", "amount", "actions"];
 	selection = new SelectionModel<QuoteResult>(true, []);
 	fromMyQuotes = false;
 	fromTop10 = false;
 	recIdMaps: Map<String, any> = new Map<String, Map<String, Array<any>>>();
 	private GAID;
 	private carriers: Map<number, string> = new Map<number, string>();
 
 	public templates: Array<ProposalTemplate>;
 	private destroy$: Subject<boolean> = new Subject();
 
 	public quoteRequest$ = this.store.pipe(select(QuoteSelectors.selectQuoteRequest), takeUntil(this.destroy$));
 	public quoteRequest: QuoteRequest;
 	public docId: string;
 
 	public tooltips = tooltips;
 	private confirmDialogRef: MatDialogRef<ConfirmComponent>;
 
 	public selectionChanged = false;
 	private noSelectionBack = false;
 	private canDrop: boolean = true;
 
 	public paymentOption = "1";
 	public paymentOptions = ["1", "2", "4", "12"];
 	public PAYMENT_OPTION_COOKIE = "selected-payment-option";
 
 	public selectedPlans: Array<any> = [];
 	public isCustomizeOpen: boolean = false;
 	public sellingPoints: { reason1?: string; reason2?: string; reason3?: string } = { reason1: "Accelerated Underwriting", reason2: "Cost", reason3: "Health Compatibility" };
 	//public sellingPoints: { reason1: string; reason2: string; reason3: string } = { reason1: "Cost", reason2: "Financial Strength", reason3: "Health Compatibility" };
 	private recFiltersVideos: Array<{ recID: string; faceAmount: string; sortOrder?: number }>;
 
 	public tableRatings: Array<any>;
 
 	public lockedFeaturesDialogRef: MatDialogRef<LockFeaturesComponent>;
 	@Input("matMenuTrigger") menuTrigger: MatMenuTrigger;
 
 	public quoteResponse$ = this.store.pipe(select(QuoteSelectors.selectQuoteResponse), takeUntil(this.destroy$));
 	public quoteResponse: QuoteResponse;
 	public forceQuoteUpdate;
 
 	public healthProfileAlt$ = this.store.pipe(select(QuoteSelectors.selectHealthProfileAlt), takeUntil(this.destroy$));
 	public healthProfileAlt: HealthProfileAlt;
 
 	public healthProfileResults$ = this.store.pipe(select(QuoteSelectors.selectHealthProfile), takeUntil(this.destroy$));
 	public healthProfile: HealthProfile;
 
 	private allProducts: Array<any> = [];
 	public editedQuote = false;

	private allowNavigateAway: boolean = false;
 
 	private dragging$ = fromEvent(window, 'mousemove').pipe(
 		tap((e:MouseEvent) => {
			 //don't do any of this if the customize video panel is open
			 if(this.isCustomizeOpen) return;

 			const card = document.getElementById('results-card');
 			const elem = (document.getElementsByClassName("cdk-drag-preview")[0] as any);
 
 			if(e.y > window.innerHeight * 0.9) {
 				if(!this.scrolling) {
 					this.scrollSubscription = timer(0, 30).pipe(
 						//takeUntil(this.stopScrolling$),
 						tap(i => {
 							window.scrollTo(0, window.scrollY + 10);
 							//var newTop = parseFloat(elem.style.top) - 10;
 							//elem.style.top = newTop + "px";
 						})
 					).subscribe();
 					this.scrolling = true;
 				}
 				return;
 			}
 			if(e.y < (window.innerHeight * 0.1) + 110) {  //110 to compensate for fixed header
 				if(!this.scrolling) {
 					this.scrollSubscription = timer(0, 30).pipe(
 						//takeUntil(this.stopScrolling$),
 						tap(i => {
 							window.scrollTo(0, window.scrollY - 10);
 							//var newTop = parseFloat(elem.style.top) + 10;
 							//elem.style.top = newTop + "px";
 						})
 					).subscribe();
 					this.scrolling = true;
 				}
 				return;
 			}
 			
 			if(e.y < window.innerHeight * 0.9 && e.y > window.innerHeight * 0.1) {
 				this.stopScrolling();
 			}
 			
 		})
 	);
 	private draggingSubscription: SubscriptionLike;
 	private scrollSubscription: SubscriptionLike;
 	private stopScrolling$: Subject<boolean> = new Subject();
 	private scrolling: boolean = false;

	private recSort: Array<QuoteRecSort>;
	private sellingPointsFromStore: boolean = false;
	private recSort$ = this.store.pipe(select(QuoteSelectors.selectRecSort), takeUntil(this.destroy$));
	private selectedPlans$ = this.store.pipe(select(QuoteSelectors.selectSelectedPlans), takeUntil(this.destroy$));
	private sellingPoints$ = this.store.pipe(select(QuoteSelectors.selectSellingPoints), takeUntil(this.destroy$));
 
	public quoteDesc = null;
	
 	constructor(private quoteService: QuoteService, private store: Store<IAppState>, private router: Router, private snackBar: MatSnackBar, public sessionService: SessionService, private dialog: MatDialog, 
 		private dataService: DataService, private route: ActivatedRoute, private cookieService: CookieService, private mixpanelService: MixpanelService) {
 		this.quoteRequest$.subscribe((qr) => (this.quoteRequest = qr));
 		this.healthProfileAlt$.subscribe((hpalt) => (this.healthProfileAlt = hpalt));
 		this.healthProfileResults$.subscribe((hp) => this.healthProfile = hp);
		this.recSort$.subscribe(rs => this.recSort = rs);
		this.selectedPlans$.subscribe(sp => { if(sp) this.selectedPlans = sp });
		this.sellingPoints$.subscribe(sp => { if(sp) { this.sellingPoints = sp; this.sellingPointsFromStore = true; } });
 		this.GAID = +this.sessionService.iPipelineCredentials.gaid;
 	}
 
 	ngOnInit() {
 		this.route.data.subscribe((data) => {
 			this.fromTop10 = !!data.fromTopTen;
 			this.fromMyQuotes = !!data.fromMyQuotes;
 			this.forceQuoteUpdate = (!!history.state.data)?!!history.state.data.force:false;
 			this.editedQuote = (!!history.state.data)?!!history.state.data.editedQuote:false;
 
 			this.paymentOption = this.cookieService.get(this.PAYMENT_OPTION_COOKIE) || "1";
 
 			this.route.params.pipe(take(1), takeUntil(this.destroy$)).subscribe((data) => {
 				if (data["quoteId"]) {
 					this.loadQuote(data["quoteId"]);
 				} else {

					//Mixpanel Event
 					this.mixpanelService.trackNewLifeQuote();

 					this.getData();
 				}
 			});
 		});
 		this.stopScrolling$.pipe(
 			tap(() => {
 				this.scrolling = false;
 				this.scrollSubscription.unsubscribe();
 			})
 		)
 	}
 
 	loadQuote(quoteId: string) {
 		let qr: QuoteRequest;
 		this.quoteService
 			.getIPipelineJson(quoteId)
 			.pipe(
 				tap((resp: QuoteRequest) => (qr = resp)),
 				mergeMap((resp) => this.quoteService.getHealthProfileAttributes(qr.inputs[0].quoteID)),
 				tap((attr: HealthDetailsAttributes) => {
 					if (attr) {
 						this.store.dispatch(new SetHealthProfile(HealthProfile.fromHealthDetailsAttributes(attr)));
 					}
 				})
 			)
 			.subscribe(
 				(resp: any) => {
 
 					//Get Health Profile Alt
 					this.quoteService.getHealthProfileAlt(quoteId).subscribe((hpAlt: HealthProfileAlt) =>{
 						this.store.dispatch(new SetHealthProfileAlt(hpAlt));
 					
 						if (qr) {
 							this.store.dispatch(new SetQuoteRequest(qr));
 							this.getData();
 						} else {
 							this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
 						}
 
 					},
 					(err: any) => {
 						this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
 					});
 				},
 				(err: any) => {
 					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
 				}
 			);
 	}
 
 	getData() {
 		this.getTableRatingsOptions();
 		this.quoteService.getCarriers().subscribe(
 			(resp: Array<QuoteCarrier>) => {
 				if (resp) {
 					resp.forEach((r) => this.carriers.set(r.id, r.name));
 					this.buildQuoteCarrierLogos(resp);
 					this.getQuoteData(this.quoteRequest);
 				} else {
 					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
 				}
 			},
 			(err: any) => {
 				this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
 			}
 		);
 		this.quoteService.getTemplates(this.sessionService.getUser().orgID).subscribe((resp: any) => (this.templates = resp));
 		
 		if(this.quoteRequest){
 			this.quoteService.getProductsForState(this.quoteRequest.inputs[0].state).subscribe(resp => this.allProducts = resp );
 		}
 	}
 
 	getQuoteData(quoteInitialRequest: QuoteRequest) {
 		if (quoteInitialRequest == null) {
			//don't have a quote, get out of here
			this.allowNavigateAway = true;
			this.router.navigate(['/quote', 'my-quotes']);
 			return;
 		}
 
 		//Get Inputs
 		var requestInputs: Array<QuoteInput> = quoteInitialRequest.inputs;
 		//var recFilters: Array<QuoteRecFilter>;
		let recFilters; //start with what's in the store
		this.store.select(QuoteSelectors.selectSelections).pipe(take(1)).subscribe(v => recFilters = v);
		if(recFilters == null) {
			if(requestInputs[0].recFilters) {
				recFilters = requestInputs[0].recFilters;
			}

		}
 		this.currentQuoteId = requestInputs[0].quoteID;
 		this.selection = new SelectionModel<QuoteResult>(true, []);
 
 		//Set Payment Options
 		quoteInitialRequest.inputs[0].paymentOptions = this.paymentOptions;
 
 		//Add Stored quote healthProfileAlt
 		var masterOrgID = this.sessionService.getMasterOrgID();
 		var orgID = (masterOrgID == null || masterOrgID <=0)? this.sessionService.getUser().orgID : masterOrgID;
 		var quoteFullRequest = {
 			orgID: orgID,
 			healthProfileAlt: this.healthProfileAlt,
 			healthProfile: this.healthProfile,
 			request: quoteInitialRequest
 		};
 
 		this.store.dispatch(new GetQuoteFromCache(quoteFullRequest));
 		this.quoteResponse$.subscribe((qr) => (this.quoteResponse = qr));
 
 		//this.healthProfileResults$.subscribe((hp) => this.healthProfile = hp);
 
 		if (!this.quoteResponse) {
 			var isDirty = this.fromTop10 || this.forceQuoteUpdate;
 
 			this.quoteService.getQuoteData(quoteFullRequest, isDirty).subscribe(
 				(resp: QuoteResponse) => {
 					if (resp) {
 						if (!resp.error) {
 							//Add to Cache
 							this.store.dispatch(new AddQuoteToCache(quoteFullRequest, resp));
 
 							this.quoteResponse = resp;
 
 							this.showQuoteResponse(recFilters);
 						} else {
 							var msg = resp.error.Message; //resp.error.substring(resp.error.lastIndexOf("]") + 2);
 							this.store.dispatch(new SetErrorMessage(msg));
 							this.backToSearch();
 						}
 					} else {
 						this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
 					}
 				},
 				(err: any) => {
 					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
 				}
 			);
 		} else {
 			this.showQuoteResponse(recFilters);
 		}
 	}
 
 	showQuoteResponse(recFilters) {
 		if (this.currentQuoteId) {
 			this.quoteService.getQuote(this.currentQuoteId).subscribe((resp: any) => {
 				this.docId = resp.docID;
 				if (resp.videoPrefs) {
 					this.sellingPoints = this.sellingPointsFromStore ? this.sellingPoints : resp.videoPrefs;
 				}
 				if (resp.recFiltersVideo) {
 					this.recFiltersVideos = resp.recFiltersVideo.sort((a, b) => a.sortOrder - b.sortOrder);
 				}
 
 				this.buildPlansForVideos(recFilters);
 			});
 		} else {
 			this.buildPlansForVideos(recFilters);
 		}
 
 		this.responseInput = this.quoteResponse.input;
		this.quoteDesc = this.quoteRequest.inputs[0].quoteDesc; 
		if(!this.quoteDesc) {
			this.quoteDesc = this.responseInput != null && this.responseInput.props != null ? this.responseInput.props.quoteDesc : this.quoteRequest.inputs[0].quoteDesc;
		}
 
 		//Build display Data with the information from input.
 		this.buildDisplayInputData(this.responseInput.inputs[0]);
 
 		this.quoteResponse.result.forEach((q) => {
 			this.buildResults(q, recFilters);
 		});
 
 		this.sortResults();

		 const event = document.createEvent('Event');
		 event.initEvent('quoteLoaded', false, true); //this is needed to fire the shepherd tour *after* quote is loaded
		 setTimeout(() => document.getElementsByTagName('body')[0].dispatchEvent(event), 1500);
 	}
 
 	buildPlansForVideos(recFilters) {
		if(!this.selectedPlans || this.selectedPlans.length == 0) {
			this.selectedPlans = [];
			if (recFilters) {
				//if recFilters is null, then you're running (or re-running) the quote.  In that case, don't set selected plans
				//find 3 plans for video:
				if (this.recFiltersVideos && this.selectedPlans.length == 0) {
					this.recFiltersVideos = this.recFiltersVideos.concat([
						{ recID: "", faceAmount: "" },
						{ recID: "", faceAmount: "" },
						{ recID: "", faceAmount: "" },
					]);
					this.recFiltersVideos.length = 3; //fill with default values in case they aren't all filled
					this.selectedPlans.push(this.quoteResponse.result.find((r) => r.recId == this.recFiltersVideos[0].recID && this.recFiltersVideos[0].faceAmount == r.faceAmount));
					this.selectedPlans.push(this.quoteResponse.result.find((r) => r.recId == this.recFiltersVideos[1].recID && this.recFiltersVideos[1].faceAmount == r.faceAmount));
					this.selectedPlans.push(this.quoteResponse.result.find((r) => r.recId == this.recFiltersVideos[2].recID && this.recFiltersVideos[2].faceAmount == r.faceAmount));
				}
			}
		}
 	}
 
 	sortResults() {

 		this.termsKeys.forEach((term) => {
 			this.faceAmountsKeys.forEach((faceAmounts) => {
 				faceAmounts.forEach((faceAmount) => {
 					var amountsMap: Map<String, any> = this.terms.get(term);
 					var array = amountsMap.get(faceAmount);
					 if(this.recSort) {
						let sort = this.recSort.filter(r => r.term == term && r.faceAmount == faceAmount);
						if(sort) {
							array.forEach(i => { 
								let o = sort.find(e => e.recId == +i.recId);
								i.sortOrder = o ? o.sortOrder : i.sortOrder
								return i;
							})
						}
					}
 					var hasSortOrder = array.length > 0 ? array[0].sortOrder != null : false;
 					var sortedArray = [];
 					if (!!this.fromMyQuotes || hasSortOrder) {
 						var orderArray = array.filter((a) => a.sortOrder != 999);
 						var noOrderArray = array.filter((a) => a.sortOrder === 999);
 
 						sortedArray = orderArray.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)).concat(noOrderArray.sort((a, b) => (a.extensionValues.quoteAmount > b.extensionValues.quoteAmount ? 1 : -1)));
 					} else {
 						sortedArray = array.sort((a, b) => (this.getPaymentOptionAmount(a) > this.getPaymentOptionAmount(b) ? 1 : -1));
 					}
 					if (this.fromTop10) {
 						sortedArray = sortedArray.slice(0, 10);
 					}
 					this.terms.get(term).set(faceAmount, sortedArray);
 				});
 			});
 		});
 	}
 
 	buildDisplayInputData(input: QuoteInput) {
 		this.faceAmountsKeys = new Map();
 
 		input.terms.forEach((t) => {
 			let map = new Map();
 
 			input.faceAmounts.forEach((amount) => {
 				map.set(amount, new Array<QuoteResult>());
 			});
 
 			this.terms.set(t, map);
 
 			//Set the keys to prevent calling keys method.
 			this.faceAmountsKeys.set(t, Array.from(map.keys()));
 		});
 
 		this.termsKeys = Array.from(this.terms.keys());
 	}
 
 	getTabTermLabel(tab) {
 		return tab === "YRT" ? "1 Year" : tab + " Years";
 	}
 
 	buildResults(quoteResult: QuoteResult, recFilters: Array<QuoteRecFilter>) {
 		let term = quoteResult.input.term;
 		let faceAmount = quoteResult.input.faceAmount;
 		let array: Array<QuoteResult> = this.terms.get(term).get(faceAmount);
 		array.push(quoteResult);
 
 		//Set Selection
 		if (recFilters != null) {
 			var res = recFilters.filter((o:any) => +o.faceAmount == +quoteResult.faceAmount && +(o.recId || o.recID) == +quoteResult.recId);
 			if (res.length > 0) {
 				this.selection.select(quoteResult);
 				quoteResult.sortOrder = res[0].sortOrder;
 			} else {
 				quoteResult.sortOrder = 999;
 			}
 		}
 	}
 
 	buildRecFiltersFromSelection(selection: SelectionModel<QuoteResult>, oldFilters: QuoteRecFilter[]) {
 		var recFilters: Array<QuoteRecFilter> = new Array<QuoteRecFilter>();
 		if (!oldFilters) {
 			oldFilters = [];
 		}
 		selection.selected.forEach((qr) => {
 			var filter = !!oldFilters ? oldFilters.find((o) => o.recID == +qr.recId) : null;
 			var rec = new QuoteRecFilter();
 			rec.faceAmount = +qr.faceAmount;
 			rec.recID = +qr.recId;
 			rec.sortOrder = !!filter ? filter.sortOrder : 999;
 			recFilters.push(rec);
 		});
 		return recFilters;
 	}
 
 	buildQuoteCarrierLogos(carriers: Array<QuoteCarrier>) {
 		carriers.forEach((c) => {
 			this.logos.set(c.supplierId, c.logoPath);
 		});
 	}
 
 	getProductName(result) {
 		if(result == null || result.input == null){
 			return "";
 		}
 		var id = result.input.productId;
 		var product = this.allProducts.find((p) => p.id == +id);
 		if (!product) {
 			return "";
 		}
 		return product.name;
 		
 	}
 
 	viewByChanged(change) {
 		if (this.cookieService.check(this.PAYMENT_OPTION_COOKIE)) {
 			this.cookieService.delete(this.PAYMENT_OPTION_COOKIE, "/");
 		}
 		this.cookieService.set(this.PAYMENT_OPTION_COOKIE, this.paymentOption, 365, "/", undefined, true);
 	}
 
 	getAmountViewBy() {
 		var result;
 
 		switch (this.paymentOption) {
 			case "1":
 				result = "/yr";
 				break;
 			case "2":
 				result = "/sa";
 				break;
 			case "4":
 				result = "/qtly";
 				break;
 			case "12":
 				result = "/mth";
 				break;
 			default:
 				result = "/yr";
 				break;
 		}
 
 		return result;
 	}
 
 	getPaymentOptionAmount(element) {
 		var result;
 
 		switch (this.paymentOption) {
 			case "1":
 				result = element.extensionValues.quoteAmount;
 				break;
 			case "2":
 				result = element.extensionValues.quoteAmountSemiAnnual;
 				break;
 			case "4":
 				result = element.extensionValues.quoteAmountQuarterly;
 				break;
 			case "12":
 				result = element.extensionValues.quoteAmountMonthly;
 				break;
 			default:
 				result = element.extensionValues.quoteAmount;
 				break;
 		}
 		this.getAmountViewBy();
 		return result;
 	}
 
 	getAdbAmount(element) {
 		var result;
 
 		switch (this.paymentOption) {
 			case "1":
 				result = element.adb;
 				break;
 			case "2":
 				result = element.extensionValues.adbAmountSemiAnnual;
 				break;
 			case "4":
 				result = element.extensionValues.adbAmountQuarterly;
 				break;
 			case "12":
 				result = element.extensionValues.adbAmountMonthly;
 				break;
 			default:
 				result = element.adb;
 				break;
 		}
 		return result;
 	}
 
 	getCrAmount(element) {
 		var result;
 
 		switch (this.paymentOption) {
 			case "1":
 				result = element.cr;
 				break;
 			case "2":
 				result = element.extensionValues.crAmountSemiAnnual;
 				break;
 			case "4":
 				result = element.extensionValues.crAmountQuarterly;
 				break;
 			case "12":
 				result = element.extensionValues.crAmountMonthly;
 				break;
 			default:
 				result = element.cr;
 				break;
 		}
 		return result;
 	}
 
 	getWopAmount(element) {
 		var result;
 
 		switch (this.paymentOption) {
 			case "1":
 				result = element.wp;
 				break;
 			case "2":
 				result = element.extensionValues.wopAmountSemiAnnual;
 				break;
 			case "4":
 				result = element.extensionValues.wopAmountQuarterly;
 				break;
 			case "12":
 				result = element.extensionValues.wopAmountMonthly;
 				break;
 			default:
 				result = element.wp;
 				break;
 		}
 		return result;
 	}
 
 	applyToProduct(quoteResult: QuoteResult) {
 		this.checkIfEditedQuote( () => {
 			if (!this.selection.isSelected(quoteResult)) {
 				this.selectionToggle(quoteResult);
 			}
 			this.saveSelection({}).then((quoteId) => {
 				var logo = this.logos.get(+quoteResult.input.companyId);
 				var clientName = this.responseInput.props != null ? this.responseInput.props.clientName : this.quoteRequest.inputs[0].clientName;
 				this.quoteService.apply(this.currentQuoteId, { recID: +quoteResult.recId, faceAmount: +quoteResult.faceAmount }).subscribe();
 				
 				//var igoRequest = QuoteIgoRequest.fromQuote(quoteResult, clientName, logo, this.GAID);
 				//set payment option 
 				quoteResult.input.paymentOption = this.paymentOption;
 				quoteResult.premium = this.getPaymentOptionAmount(quoteResult);
 				quoteResult.totalPremium = this.getPaymentOptionAmount(quoteResult);
 				quoteResult.crAmount = this.getCrAmount(quoteResult);
 				quoteResult.adbAmount = this.getAdbAmount(quoteResult);
 				quoteResult.wp = this.getWopAmount(quoteResult);
 				const buttonTypes = this.getButtonType(quoteResult);
 				if(buttonTypes == null) {
 					this.store.dispatch(new SetErrorMessage("Could not access pipeline api"));
 					return;
 				}
				var agentName = this.sessionService.getUser().firstName + " " + this.sessionService.getUser().lastName;
 				var igoRequests = QuoteIgoRequest.fromQuote(quoteResult, clientName, logo, this.GAID, buttonTypes, agentName);
				this.showApplyOptionsDialog(igoRequests, buttonTypes, quoteResult);

 			});
 		});
 	}
  
 	private getButtonType(quoteResult: QuoteResult): string[] {
 		const companyId = +quoteResult.input.companyId;
 		const productId = +quoteResult.input.productId;
 		const prod = this.allProducts.find(p => p.carrierId == companyId && p.id == productId);
 		if(!prod) { return null; }
 		//let buttonType = prod.applyButtonTypes.filter(abt => abt.avaliable).reduce((a,b) => Math.max(a, b.type), 0);
		 let buttonType = prod.applyButtonTypes.filter(abt => abt.avaliable);
 		return buttonType;
	}
 
 	productDetail(row) {
 		let product: QuoteDetailsRequest[] = this.buildProductDetailsRequest(row);
 		let image = this.logos.get(+row.input.companyId);
 		const dialogRef = this.dialog.open(QuoteDetailComponent, {
 			width: "800px",
 			height: "600px",
 			data: { productDetail: product, result: row, image: image },
 		});
 
 		dialogRef.afterClosed().subscribe((result) => {});
 	}
 
 	buildProductDetailsRequest(row): QuoteDetailsRequest[] {
 		let products: QuoteDetailsRequest[] = [];
 		let p = new QuoteDetailsRequest();
 
 		p.gaid = this.GAID;
 		p.productId = +row.input.productId;
 		p.carrierId = +row.input.companyId;
 		p.age = +row.productVersionInfo.useAgeNearest ? row.input.nearestAge : row.input.actualAge;
 		p.faceAmount = +row.input.faceAmount;
 		p.healthClass = row.input.healthClass;
 
 		products.push(p);
 		return products;
 	}
 
 	/** Whether the number of selected elements matches the total number of rows. */
 
 	selectionToggle(element) {
 		this.selectionChanged = true;
 		this.selection.toggle(element);
 		//remove it if it's a selected plan for the video
 		const idx = this.selectedPlans.findIndex((p) => p && p.recId == element.recId && p.faceAmount == element.faceAmount);
 		if (idx > -1) {
 			this.selectedPlans[idx] = null;
 		}
 	}
 
 	isAllSelected(tab, panel) {
 		const numSelected = this.getSelectionCount(tab, panel);
 		const numRows = this.terms.get(tab).get(panel).length;
 		return numSelected === numRows;
 	}
 
 	getSelectionCount(tab, panel) {
 		let count = 0;
 		this.selection.selected.forEach((element) => {
 			if (element.input.faceAmount === panel && element.input.term === tab) {
 				count++;
 			}
 		});
 		return count;
 	}
 
 	selectionHasValue(tab, panel) {
 		let hasValue = this.selection.hasValue();
 		let hasSelection = false;
 		this.selection.selected.forEach((element) => {
 			if (!hasSelection) {
 				hasSelection = element.input.faceAmount === panel && element.input.term === tab;
 			}
 		});
 		return hasValue && hasSelection;
 	}
 
 	clearSelection(tab, panel) {
 		this.selection.selected.forEach((element) => {
 			if (element.input.faceAmount === panel && element.input.term === tab) {
 				this.selection.deselect(element);
 			}
 			//also remove any matching plans selected for the video
 			const idx = this.selectedPlans.findIndex((p) => p && p.recId == element.recId && p.faceAmount == element.faceAmount);
 			if (idx > -1) {
 				this.selectedPlans[idx] = null;
 			}
 		});
 	}
 	/** Selects all rows if they are not all selected; otherwise clear selection. */
 	masterToggle(tab, panel) {
 		this.selectionChanged = true;
 		this.isAllSelected(tab, panel)
 			? this.clearSelection(tab, panel)
 			: this.terms
 					.get(tab)
 					.get(panel)
 					.forEach((row) => this.selection.select(row));
 	}
 
 	/** The label for the checkbox on the passed row */
 	checkboxLabel(row, tab, panel): string {
 		if (!row) {
 			return `${this.isAllSelected(tab, panel) ? "select" : "deselect"} all`;
 		}
 		return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
 	}
 
 	drop(event: CdkDragDrop<string[]>, tab, panel) {
 		if (this.canDrop) {
 			this.selectionChanged = true;
 			moveItemInArray(this.terms.get(tab).get(panel), event.previousIndex, event.currentIndex);
 		}
 		this.draggingSubscription.unsubscribe();
 		this.stopScrolling();
 	}
 
 	dragMoved(e) {
 		this.canDrop = e.event.target.classList.contains("plan") || ((e.event.path!= null)? e.event.path.find((p) => p.classList && p.classList.contains("plan-list")):true);
 	}
 	stopScrolling() {
 		if(this.scrollSubscription) { this.scrollSubscription.unsubscribe(); }
 		this.stopScrolling$.next(true);
 		this.scrolling = false;
 
 	}
 	buildQuoteSave(quotes: QuoteResult[]): QuoteSave {
 		let save = new QuoteSave();
 
 		save.userID = this.sessionService.getUser().userID;
 		save.quoteID = this.currentQuoteId != null ? this.currentQuoteId : null;
 
 		//set the Doc ID
 		if (this.docId != null) {
 			save.docID = this.docId;
 		}
 
 		//if (quotes.length > 0) {
 			save.birthDate = this.quoteRequest.inputs[0].birthDate; //taking this one from input, bc it could be a dob calculated by the api from an age, but not an actual dob
 			save.actualAge = this.quoteRequest.inputs[0].actualAge;
 			save.nearestAge = this.quoteRequest.inputs[0].nearestAge;
 			save.state = this.quoteRequest.inputs[0].state;
 			save.clientName = this.responseInput.props != null ? this.responseInput.props.clientName : this.quoteRequest.inputs[0].clientName;
 			save.quoteDesc = this.responseInput.props != null ? this.responseInput.props.quoteDesc : this.quoteRequest.inputs[0].quoteDesc;
 			save.faceAmounts = this.responseInput.inputs[0].faceAmounts;
 			save.terms = this.replaceYRTTerms(this.responseInput.inputs[0].terms);
 			save.healthClasses = this.responseInput.inputs[0].healthClasses;
 			save.gender = this.quoteRequest.inputs[0].gender;
 			save.quoteType = this.responseInput.quoteType;
 			save.videoPrefs = this.sellingPoints;
 			save.recFiltersVideo = this.selectedPlans
 				.filter((p) => p)
 				.map((p) => {
 					return { recID: p.recId, faceAmount: +p.faceAmount };
 				});
 
 			let prop = new QuoteSaveProps();
 
 			prop.flatExtraAmount = this.responseInput.inputs[0].props.flatExtraAmount;
 			prop.flatExtraYearEnd = +this.responseInput.inputs[0].props.flatExtraYearEnd;
 			prop.flatExtraYearStart = this.responseInput.inputs[0].props.flatExtraYearStart;
 			prop.isAccidentalDeathBenefitRider = this.responseInput.inputs[0].props.isAccidentalDeathBenefitRider;
 			prop.isChildTermRider = this.responseInput.inputs[0].props.isChildTermRider;
 			prop.isGuaranteed = this.responseInput.inputs[0].props.isGuaranteed;
 			prop.isWaiverOfPremiumRider = this.responseInput.inputs[0].props.isWaiverOfPremiumRider;
 			prop.isReturnOfPremiumRider = this.responseInput.inputs[0].props.isReturnOfPremiumRider;
 			prop.simplifiedUsage = this.responseInput.inputs[0].props.simplifiedIssue;
 			prop.tableRating = this.responseInput.inputs[0].props.tableRating;
 			prop.vraeUsage = 0;
 			prop.childRiderUnit = +this.responseInput.inputs[0].props.cru;
 			save.props = prop;
 		//}
 
 		let recFilters = this.buildSaveRecFilters(quotes);
 		save.recFilters = recFilters;
 
 		//Add current result JSON to QuoteSave
 		save.quoteResponse = JSON.stringify(this.quoteResponse);
 
 		return save;
 	}
 
 	replaceYRTTerms(terms) {
 		var intTerms = [];
 
 		terms.forEach((t) => {
 			intTerms.push(t === "YRT" ? "1" : t);
 		});
 
 		return intTerms;
 	}
 
 	buildSaveRecFilters(quotes): Array<QuoteRecFilter> {
 		let recFilters = new Array<QuoteRecFilter>();
 		this.buildRecFilterMap(quotes);
 		recFilters = this.sortRecFilterArray();
 		return recFilters;
 	}
 
 	buildRecFilterMap(quotes) {
 		var index = 0;
 		var array = [];
 		this.recIdMaps = new Map<String, Map<String, Array<any>>>();
 		quotes.forEach((q) => {
 			array = this.terms.get(q.input.term).get(q.faceAmount);
 			index = array.indexOf(q);
 
 			if (!this.recIdMaps.has(q.input.term)) {
 				this.recIdMaps.set(q.input.term, new Map<String, Array<any>>());
 			}
 			var faceAmountMap = this.recIdMaps.get(q.input.term);
 			//Group by faceAmount
 			if (!faceAmountMap.has(q.faceAmount)) {
 				faceAmountMap.set(q.faceAmount, new Array<any>());
 			}
 			faceAmountMap.get(q.faceAmount).push({
 				index: index,
 				data: q,
 			});
 		});
 	}
 
 	sortRecFilterArray() {
 		let recFilters = new Array<QuoteRecFilter>();
 		this.termsKeys.forEach((term) => {
 			var faceAmountMap = this.recIdMaps.get(term);
 			var faceAmountKeys;
 			if (!!faceAmountMap) {
 				faceAmountKeys = Array.from(faceAmountMap.keys());
 			} else {
 				faceAmountKeys = [];
 			}
 			faceAmountKeys.forEach((faceAmount) => {
 				var recArray = faceAmountMap.get(faceAmount);
 				if (recArray != null) {
 					var sortedArray = recArray.sort((a, b) => (a.index > b.index ? 1 : -1));
 
 					sortedArray.forEach((element) => {
 						let filter = new QuoteRecFilter();
 						filter.recID = +element.data.recId;
 						filter.faceAmount = +element.data.faceAmount;
 						filter.companyID = +element.data.input.companyId;
 						filter.term = term;
 						filter.productID = +element.data.input.productId;
 						filter.productName = element.data.productVersionInfo.name;
 						filter.carrierDesc = this.carriers.get(+element.data.input.companyId);
 						filter.annualPremium = element.data.extensionValues.quoteAmount;
 						//filter.annualPremium = this.annualRecIDMap.get(+element.data.recId);
 						recFilters.push(filter);
 					});
 				}
 			});
 		});
 		return recFilters;
 	}
 
 	saveSelection({silent, isSaveAs}: { silent?: boolean, isSaveAs?: boolean}): Promise<string> {
 		return new Promise((resolve, reject) => {
 			let healthProfile: HealthProfile;
 			this.store.pipe(select("quote", "healthProfile"), take(1)).subscribe((hp) => (healthProfile = hp));
 			if (this.currentQuoteId != null) {
 				this.quoteService.updateQuotes(this.buildQuoteSave(this.selection.selected)).subscribe(
 					(result: any) => {
						this.editedQuote = false;
 						if (healthProfile) {
 							this.quoteService.saveHealthProfile(this.currentQuoteId, HealthDetailsAttributes.fromHealthProfile(healthProfile)).subscribe(
 								(result: any) => {
 
 									//Save HealthProfileAlt 
 									this.saveHealthProfileAlt(this.currentQuoteId, this.healthProfileAlt);
 
 									this.processQuoteUpdateResult(result, silent);
									this.store.dispatch(new ClearTemporaryQuoteData());
 									resolve(this.currentQuoteId);
 								},
 								(err) => {
 									this.showError(err, "Quote Save Error");
 									reject();
 								}
 							);
 						} else {
 							this.processQuoteUpdateResult(result, silent);
							this.store.dispatch(new ClearTemporaryQuoteData());
 							resolve(this.currentQuoteId);
 						}
 					},
 					(err) => {
 						this.showError(err, "Quote Save Error");
 						reject();
 					}
 				);
 			} else {
 				let newQuote;
 
 				if (!this.selection.hasValue() && !isSaveAs) {	
 					this.store.dispatch(new SetErrorMessage("You should select a product to send."));
 					reject();
 				}else{
 
 					this.quoteService
 					.saveQuotes(this.buildQuoteSave(this.selection.selected))
 					.pipe(
 						tap((q) => (newQuote = q)),
 						flatMap((q: any) => this.quoteService.saveHealthProfile(q.quoteID, HealthDetailsAttributes.fromHealthProfile(healthProfile)))
 					)
 					.subscribe(
 						(result: any) => {
 							this.currentQuoteId = newQuote.quoteID;
              this.editedQuote = false; // Quote Saved!
 							this.selectionChanged = false;
 							this.quoteRequest.inputs[0].quoteID = this.currentQuoteId;
 							//this.store.dispatch(new SetQuoteRequest(this.quoteRequest));
 
 							//Save HealthProfileAlt 
 							this.saveHealthProfileAlt(this.currentQuoteId, this.healthProfileAlt);
							this.store.dispatch(new ClearTemporaryQuoteData());
 							if (!silent) {
 								const snackBarRef = this.snackBar.open("Quote Saved", "Close", {
 									duration: 3000,
 								});
 							}
 							resolve(newQuote.quoteID);
 						},
 						(err) => {
 							this.showError(err, "Quote Save Error");
 							reject();
 						}
 					);
 
 				}
 			}
 		});
 	}
 
 	saveHealthProfileAlt(quoteID, healthProfileAlt: HealthProfileAlt){
 		this.quoteService.saveHealthProfileAlt(quoteID, healthProfileAlt).subscribe(
 			(result: any) => {
 			},
 			(err) => {
 				this.showError(err, "Quote Save Error");
 			}
 		);
 	}
 
 	processQuoteUpdateResult(result: any, silent) {
 		this.selectionChanged = false;
 		if (!silent) {
 			const snackBarRef = this.snackBar.open("Quote Saved", "Close", {
 				duration: 3000,
 			});
 		}
 		if (!!result) {
 			this.quoteRequest.inputs[0].recFilters = result.recFilters; //update with current selections
 		}
 		this.store.dispatch(new SetQuoteRequest(this.quoteRequest));
 	}
 
 	downloadSelection() {
 		this.checkIfEditedQuote( () => {
 			let wdw = window.open("/loading");
 			this.saveSelection({}).then(
 				(quoteId) => {
 					this.quoteService.buildPdf(quoteId).subscribe((res: any) => {
 						var elem = wdw.document.getElementById("loadingContent");
 						if(elem){
 							elem.classList.add("loaded");
 						}
 						wdw.location.href = res.url;
 					});
 				},
 				(err) => {
 					wdw.close();
 					this.store.dispatch(new SetErrorMessage("Your report could not be generated."));
 				}
 			);
 		});
 	}
 
 	downloadDetailSelection() {
 		this.checkIfEditedQuote( () => {
 			let wdw = window.open("/loading");
 			this.saveSelection({}).then((quoteId) => {
 				this.quoteService.buildDetailPdf(quoteId).subscribe(
 					(res: any) => {
 						if (res.success) {
 							var elem = wdw.document.getElementById("loadingContent");
 							if(elem){
 								elem.classList.add("loaded");
 							}
 							wdw.location.href = res.url;
 						} else {
 							wdw.close();
 							this.store.dispatch(new SetErrorMessage("Your report could not be generated."));
 						}
 					},
 					(err) => {
 						wdw.close();
 						this.store.dispatch(new SetErrorMessage("Your report could not be generated."));
 					}
 				);
 			});
 		});
 	}
 
 	downloadCompareSelection() {
 		this.checkIfEditedQuote( () => {
 			let wdw = window.open("/loading");
 			this.saveSelection({}).then((quoteId) => {
 				this.quoteService.buildComparePdf(quoteId).subscribe(
 					(res: any) => {
 						if (res.success) {
 							var elem = wdw.document.getElementById("loadingContent");
 							if(elem){
 								elem.classList.add("loaded");
 							}
 							wdw.location.href = res.url;
 						} else {
 							wdw.close();
 							this.store.dispatch(new SetErrorMessage("Your report could not be generated."));
 						}
 					},
 					(err) => {
 						wdw.close();
 						this.store.dispatch(new SetErrorMessage("Your report could not be generated."));
 					}
 				);
 			});
 		});
 	}
 
 	showError(err, title) {
 		if (Array.isArray(err.error)) {
 			err.error.forEach((e) => {
 				this.store.dispatch(new SetErrorMessage(e.error));
 			});
 		} else {
 			this.store.dispatch(new SetErrorMessage(err.message || err.error.ExceptionMessage));
 		}
 	}
 
 	backToSearch() {
		//just go back, no need to save
		this.allowNavigateAway = true;
		let ordered = [];
		this.terms.forEach((val, term) => {
			let i = 1;
			val.forEach((items, faceAmount) => {
				ordered = ordered.concat(items.map(e => { return { term, faceAmount, recId: e.recId, sortOrder: i++ }}));
			}) 
		});

		this.store.dispatch(new SetRecSort(ordered));
		this.store.dispatch(new SetSelections(this.selection.selected));
		this.store.dispatch(new SetSelectedPlans(this.selectedPlans));
		this.store.dispatch(new SetSellingPoints(this.sellingPoints))
		this.router.navigateByUrl("/quote?vq=true");
 
 	}
 
  	sendProposal(includeProposal: boolean) {
 		this.checkIfEditedQuote( () => {
 			this.saveSelection({silent: true}).then((quoteId) => {
 				const userId = this.sessionService.getUser().userID;
 				let cartId: string;
 				this.dataService.getPreviousCart(userId).pipe( //get cart id
 					tap((c: any) => (cartId = c.recentSession)),
 					flatMap((c: any) =>
 						iif(
 							() => cartId !== "", //if you have a cart id,
 							this.dataService.clearCart(cartId), //clear it out
 							this.dataService.startSession(userId).pipe(tap((c: any) => (cartId = c.cartSessionID))) //otherwise, start new session
 						)
 					),
 				)
 				.subscribe((resp) => {
 					if(includeProposal) {
 						this.dialog.open(DocbuilerOptionsComponent, { data: { mainCartId: cartId, quoteId }, minWidth: 830, maxWidth: 830 })
 					} else {
 						this.dataService.saveLifeQuoteDocBuilderOptions(quoteId, [], false, true).pipe(
 							concatMap(v1 => this.dataService.createDocBuildDoc(quoteId)),
 							concatMap(v2 => this.dataService.addToCart(cartId, quoteId, 4)))
 						.subscribe(resp => {
 							this.dialog.open(PersonalizeDialogComponent, { data: { mainCartId: cartId, quoteId }, minWidth: 830, maxWidth: 830 })
 						})
 				
 					}
 				});
 			});
 		});
 	}
 
 	ngOnDestroy(): void {
 		this.destroy$.next(true);
 		this.destroy$.complete();
 	}
 
 	isQuoteResultSaved() {
		if(this.hasUnsavedData) {
			return false;
		}
 		if (this.noSelectionBack == true) {
 			return true;
 		}
 
 		if (this.currentQuoteId == null) {
 			return false;
 		} else {
 			return !this.selectionChanged;
 		}
 	}
 
 	canNavigateAway(): Observable<boolean> | boolean {
		 if(this.allowNavigateAway) {
			 return true;
		 }
 		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
 			data: {
 				title: "Confirm",
 				message: `Are you sure you want to exit without saving?`,
 			},
 		});
 		return this.confirmDialogRef.afterClosed().pipe(
			 tap(r => {
				 if(r === true) {
					this.store.dispatch(new ClearTemporaryQuoteData())
			 	}
			}),
			 take(1)
		);
 	}
 
 	onDragStart(e) {
 		if (!e.source.data.selected) {
 			[].forEach.call(document.getElementsByClassName("plan"), (p) => p.classList.add("disabled"));
 		}
 		this.draggingSubscription = this.dragging$.subscribe();
 		setTimeout(() => {
 			const preview: HTMLElement = document.getElementsByClassName('cdk-drag-preview')[0] as HTMLElement;
			if(preview != null){
				preview.style.top = String(window.scrollY) + 'px';
			}
 		}, 50)
 	}
 	onDragEnd(e) {
 		[].forEach.call(document.getElementsByClassName("plan"), (p) => p.classList.remove("disabled"));
		 this.draggingSubscription.unsubscribe();
 	}
 	videoDrop(elem, idx) {
 		if (elem.item.data.selected) {
 			const existingIdx = this.selectedPlans.findIndex((p) => p && p.recId == elem.item.data.plan.recId && p.faceAmount == elem.item.data.plan.faceAmount);
 			if (existingIdx == -1) {
 				this.selectionChanged = true;
 				this.selectedPlans[idx] = elem.item.data.plan;
 			} else {
 				this.snackBar.open("This plan is already included", "Close", { duration: 3000 });
 			}
 		}
 	}
 	onDragMoved(e) {
 		const elem = document.getElementsByClassName("cdk-drag-preview")[0];
 		if (this.isCustomizeOpen && e.event.clientY > window.innerHeight - 180) {
 			if (!elem.classList.contains("small")) {
 				(elem as any).style.marginLeft = e.event.clientX - elem.getBoundingClientRect().left - 85 + "px";
 			}
 			elem.classList.add("small");
 		} else {
 			elem.classList.remove("small");
 			(elem as any).style.marginLeft = 0;
 		}
 	}
 	clearPlan(idx) {
 		this.selectedPlans[idx] = null;
 	}
 
 	getTableRatingsOptions() {
 		this.tableRatings = this.quoteService.getTableRatingsOptions();
 	}
 
 	getTableRating(rating) {
 		var rat = this.tableRatings.find((x) => x.code == rating);
 		return rat.name;
 	}
 
 	validateSubscription(action) {
 		if (this.sessionService.canUseSubscriptionLevelFeature(action)) {
 			if(this.menuTrigger != null){
 				this.menuTrigger.openMenu();
 			}
 		} else {
 			this.lockedFeaturesDialogRef = this.dialog.open(LockFeaturesComponent, {
 				panelClass: "lock-features",
 			});
 		}
 	}
 
 	validateSubscriptionAndOpenVideo(action) {
 		if (this.sessionService.canUseSubscriptionLevelFeature(action)) {
 			this.isCustomizeOpen = !this.isCustomizeOpen;
 		} else {
 			this.lockedFeaturesDialogRef = this.dialog.open(LockFeaturesComponent, {
 				panelClass: "lock-features",
 			});
 		}
 	}
 
 	copyQuote({quoteId, name}) {

		this.currentQuoteId = null;
		if(this.responseInput.props != null) {
			this.responseInput.props.quoteDesc = name;
		} else {
			this.quoteRequest.inputs[0].quoteDesc = name;
		} 
		this.saveSelection({silent: true, isSaveAs: true}).then((id) => {
			this.currentQuoteId = id;
			this.snackBar.open("Quote has been copied.", "Close", { duration: 3000 });
			if(this.fromTop10){
				this.router.navigate(['/quote', 'view-quote', id, 'top-10', { relativeTo: this.route }]);
			}else{
				this.router.navigate(['/quote', 'view-quote', id, { relativeTo: this.route }]);
			}
		});
 	}
 
 	public getAlertMessages(quoteResult):string {
 		var s = "";
 		for (let index = 0; index < quoteResult.alerts.length; index++) {
 			const element = quoteResult.alerts[index];
 			if(index > 0){
 				s += "\n\n";
 			}
 			s += element;
 		}
 		return s;
 	}
 
 	checkIfEditedQuote(callback: () => any) {
 		if(!this.editedQuote){
			 if(!this.isQuoteResultSaved()){
				this.canOverrideQuote().subscribe((override) =>{
					if(override){
						callback();
					}
				});
			 }else{
				callback();
			 }
 		}else{
 			this.canOverrideQuote().subscribe((override) =>{
 				if(override){
 					this.editedQuote = false;
 					callback();
 				}
 			});
 		}
 	}
 
 	canOverrideQuote(): Observable<boolean>{
 		this.confirmDialogRef = this.dialog.open(ConfirmComponent, {
 			data: {
 				title: "Confirm",
 				message: `Quote must be saved to continue. Save Quote now?`,
 			},
 		});
 		return this.confirmDialogRef.afterClosed().pipe(take(1));
 	}

	 get hasUnsavedData() {
		let selections;
		this.store.select(QuoteSelectors.selectSelections).pipe(take(1)).subscribe(v => selections = v);
		return (!!selections || !!this.recSort)
	 }


	validateSendProposal(enhancedQuote: boolean){
		if (this.sessionService.canUseSubscriptionLevelFeature('quoteSendProposal')) {
			this.sendProposal(enhancedQuote);
		} else {
			this.dialog.open(LockFeaturesComponent, {
				panelClass: "lock-features",
			});
			this.menuTrigger.closeMenu()
		}
	}

	showApplyOptionsDialog(igoRequest:QuoteIgoRequest[], buttonTypes: any[], quoteResult: QuoteResult) {

		var buttons = [];
		buttonTypes.forEach(button => {
			var request = igoRequest.find(x => x.buttonType == button.type);
			button.request = request;
			buttons.push(button);
		});
		
		var programName = this.getProductName(quoteResult);
		var image = this.logos.get(+quoteResult.input.companyId);

		var dialog = this.dialog.open(ApplyDialogComponent, {
			data: {
				buttons: buttons,
				programName: programName,
				image: image
			},
			minWidth: 675
		});
	}
 }
