import { IRevisionDatesState, initialRevisionDateState } from '../state/revisiondates.state';
import { RevisionDatesActions, RevisionDatesActionTypes } from '../actions/revisiondates.actions';
import { Media, Text } from 'acc-models';

export function revisionDatesReducer(state:IRevisionDatesState = initialRevisionDateState, action:RevisionDatesActions):IRevisionDatesState {
	switch(action.type) {
		case RevisionDatesActionTypes.GET_MEDIAS:
			return { ...state, orgId: action.orgId };
		case RevisionDatesActionTypes.GET_MEDIAS_SUCCESS: 
			const newMedias = action.medias.map(o => new Media(o));
			return { 
				...state, 
				medias: newMedias,
				pastMedias: newMedias.filter(m => m.status == 'past'),
				soonMedias: newMedias.filter(m => m.status == 'soon'),
				futureMedias: newMedias.filter(m => m.status == 'future'),
				noDateMedias: newMedias.filter(m => m.status == '')
			};
		case RevisionDatesActionTypes.EDIT_MEDIA:
			return { ...state, editingMedia: action.media };
		case RevisionDatesActionTypes.EDIT_MEDIA_CONFIRM: 
			return { ...state, editingMedia: new Media({ ...state.editingMedia, revisionNeededDate: action.revisionDate }) };
		case RevisionDatesActionTypes.EDIT_MEDIA_CANCEL: 
			return { ...state, editingMedia: null };
		case RevisionDatesActionTypes.EDIT_MEDIA_SUCCESS: 
			const medias = state.medias.map(m => m.mediaID == state.editingMedia.mediaID ? state.editingMedia : m).map(o => new Media(o));
			return { 
				...state, 
				medias: medias, 
				pastMedias: medias.filter(m => m.status == 'past'),
				soonMedias: medias.filter(m => m.status == 'soon'),
				futureMedias: medias.filter(m => m.status == 'future'),
				noDateMedias: medias.filter(m => m.status == ''),
				editingMedia: null 
			}

		case RevisionDatesActionTypes.GET_TEXTS:
			return { ...state, orgId: action.orgId };
		case RevisionDatesActionTypes.GET_TEXTS_SUCCESS: 
			const texts = action.texts.map(o => new Text(o));
			return { 
				...state, 
				texts: texts,
				pastTexts: texts.filter(t => t.status == 'past'),
				soonTexts: texts.filter(t => t.status == 'soon'),
				futureTexts: texts.filter(t => t.status == 'future'),
				noDateTexts: texts.filter(t => t.status == '')
			 };
		case RevisionDatesActionTypes.EDIT_TEXT:
			return { ...state, editingText: action.text };
		case RevisionDatesActionTypes.EDIT_TEXT_CONFIRM:
			return { ...state, editingText: new Text({ ...state.editingText, revisionNeededDate: action.revisionDate }) };
		case RevisionDatesActionTypes.EDIT_TEXT_CANCEL:
			return { ...state, editingText: null };
		case RevisionDatesActionTypes.EDIT_TEXT_SUCCESS:
			const newTexts =  state.texts.map(t => t.textID == state.editingText.textID ? state.editingText: t).map(o => new Text(o));
			return { 
				...state, 
				texts: newTexts, 
				pastTexts: newTexts.filter(t => t.status == 'past'),
				soonTexts: newTexts.filter(t => t.status == 'soon'),
				futureTexts: newTexts.filter(t => t.status == 'future'),
				noDateTexts: newTexts.filter(t => t.status == ''),
				editingText: null 
			};
		case RevisionDatesActionTypes.RESET:
			return initialRevisionDateState;
		default: 
			return state;
	}
}