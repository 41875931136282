import { Application, Broker } from "acc-models";
import { Action } from "@ngrx/store";
import { DtcResults } from "projects/acc-models/src/lib/models/Dtc/dtc-results";

export enum DtcActionTypes  {
	SET_BROKER = '[DTC] Set Broker',
	UPDATE_APPLICATION = '[DTC] Update Application',
	SET_PROGRESS = '[DTC] Set Progress',
	SET_SERVICE_ENABLED = '[DTC] Set Service Enabled',
	SET_RESULTS = '[DTC] Set Quote Results',
}

export class SetBroker implements Action {
	public readonly type = DtcActionTypes.SET_BROKER
	constructor(public broker: Broker) {}
}

export class UpdateApplication implements Action {
	public readonly type = DtcActionTypes.UPDATE_APPLICATION;
	constructor(public application: Application) {}
}

export class SetProgress implements Action {
	public readonly type = DtcActionTypes.SET_PROGRESS;
	constructor(public progress: number) {}
}

export class SetServiceEnabled implements Action {
	public readonly type = DtcActionTypes.SET_SERVICE_ENABLED;
	constructor(public enabled: boolean) {}
}

export class SetResults implements Action {
	public readonly type = DtcActionTypes.SET_RESULTS;
	constructor(public results: DtcResults) {}
}

export type DtcActions =
	SetBroker |
	UpdateApplication |
	SetProgress |
	SetServiceEnabled |
	SetResults
;