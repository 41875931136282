<div class="emailAddresses selections" (click)="focusInput($event)" [ngClass]="{hasText: contacts.length > 0}">
	<mat-form-field appearance="outline">
		<input matInput type="search" autocomplete="off" [(ngModel)]="filter" [formControl]="emailField" (keyup)="filterOptions($event)" (keydown)="checkTab($event)" (focus)="contactOptionsToShow.length == 0 ? filterOptions() : true" #input>
		<mat-label>{{label}}</mat-label>
	</mat-form-field>
	<div *ngFor="let contact of contacts" class="emailAddress selection">{{contact.Name || contact.Email}}<span (click)="removeContact(contact.Email)">X</span></div>
	<div *ngFor="let email of invalidEmails" class="emailAddress invalid">{{email}}<span (click)="removeInvalid(email)">X</span></div>
</div>
<ul class="emailOptionsList optionList" [ngClass]="{open: contactOptionsToShow.length > 0}" #contactOptionList>
	<li *ngFor="let contact of contactOptionsToShow; let i = index" (mouseup)="addContact(contact)" [innerHTML]="(contact.Name || contact.Email) | highlight:filter" [ngClass]="{active: currentHighlight == i}"><li>
</ul>
