import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuoteComponent } from './quote/quote.component';
import { HealthDetailsComponent } from './health-details/health-details.component';
import { QuoteEngineRoutingModule } from './quote-engine-routing.module';
import { QuoteService } from 'acc-services';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectHealthTypeComponent } from './select-health-type/select-health-type.component';
import { QuoteFormComponent } from './quote-form/quote-form.component';
import { DrivingViolationsFormComponent } from './health-details/driving-violations-form/driving-violations-form.component';
import { HealthConditionsFormComponent } from './health-details/health-conditions-form/health-conditions-form.component';
import { FamilyHistoryFormComponent } from './health-details/family-history-form/family-history-form.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AnalyticsChartComponent } from './analytics/analytics-chart/analytics-chart.component';
import { QuoteVideoComponent } from './quote-video/quote-video.component';
import { TextMaskModule } from 'angular2-text-mask';
import { SelfServiceQuotingComponent } from './self-service-quoting/self-service-quoting.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [QuoteComponent, HealthDetailsComponent, SelectHealthTypeComponent, QuoteFormComponent, DrivingViolationsFormComponent, HealthConditionsFormComponent, FamilyHistoryFormComponent, AnalyticsComponent, AnalyticsChartComponent, QuoteVideoComponent, SelfServiceQuotingComponent],
  imports: [
	CommonModule,
	SharedModule,
	QuoteEngineRoutingModule,
	FormsModule,
	ReactiveFormsModule,
	MatCardModule,
	MatSelectModule,
	MatInputModule,
	MatRadioModule,
	MatButtonModule,
	MatCheckboxModule,
	MatTooltipModule,
	MatDatepickerModule,
	NgxChartsModule,
	MatTabsModule,
	MatNativeDateModule,
	MatIconModule,
	TextMaskModule,
	ShareModule,
	ShareButtonsModule,

	NgxYoutubePlayerModule.forRoot()

  ],
  providers: [QuoteService, CurrencyPipe],
  exports: [ AnalyticsChartComponent]
})
export class QuoteEngineModule { }
