<div class="form-container signup-stripe-bg">
  <div class="section-title">Accelerate User Set-up</div>
  <div class="section-message" (click)="showFeaturesDialog()">Why am I being asked for this info?</div>
  <form [formGroup]="signUpForm">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Company Information</mat-card-title>
      </mat-card-header>

      <mat-card-content  class="signup__company--grid" >
          <mat-form-field appearance="outline" class="company__name" >
            <mat-label>Company Name *</mat-label>
            <input matInput type="text" formControlName="CompanyName" (keyup)="setDefaultResource($event)" />
          </mat-form-field>
    
          <div class="company__resource">
            <span>https://accelerate.consumeroptix.com/{{signUpForm.controls.ResourceName.value}}</span><br><br>
            <mat-form-field appearance="outline" class="resource-mat-field" >
              <mat-label>Custom URL where you will access your Accelerate site *</mat-label>
              <input matInput type="text" formControlName="ResourceName" onPaste="return false;" (keypress)="preventChars($event)" (keyup)="urlEdited = true" (blur)="checkResource($event.target.value)" />
              <mat-error *ngIf="signUpForm.controls.ResourceName.hasError('maxlength')">Url must have a maximum of 100 characters</mat-error>
              <mat-error *ngIf="signUpForm.controls.ResourceName.hasError('exist')">Url already exists.</mat-error>
              <mat-icon matSuffix class="q-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{tooltips.signup_resource}}" >info_outline</mat-icon>
            </mat-form-field>
          </div>

          <div class="company__logo">
            <acc-flow-profile-photo type="Logo" [tooltip]="tooltips.company_logo_signup" [photo]="logo" [buttonText]="'Upload Logo'" [defaultTitle]="'Company Logo'" [cssClass]="'signup signup-logo'" [accept]="imageFileTypes" #logoPhoto></acc-flow-profile-photo>
          </div>
      </mat-card-content>
    </mat-card>

    <mat-card >
      <mat-card-header>
        <mat-card-title>User Information</mat-card-title>
      </mat-card-header>
    
      <mat-card-content class="signup__user--grid" >
        <mat-form-field appearance="outline" class="user__title" >
          <mat-label>Title</mat-label>
          <input  matInput type="text" formControlName="Title" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="user__name" >
          <mat-label>First Name *</mat-label>
          <input matInput type="text"  formControlName="FirstName" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="user__lastname" >
          <mat-label>Last name *</mat-label>
          <input matInput type="text"  formControlName="LastName"/>
        </mat-form-field>

        <mat-form-field appearance="outline" class="user__email">
          <mat-label>Email *</mat-label>
          <input matInput type="email" formControlName="Email" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="user__password" >
          <mat-label>Password *</mat-label>
          <input matInput type="password" formControlName="Password" placeholder="Min 8 char. One upper, lower, number, special char." />
        </mat-form-field>

        <mat-form-field appearance="outline" class="user__confirm" >
          <mat-label>Confirm Password *</mat-label>
          <input matInput type="password" formControlName="ConfirmPassword"  />
          <mat-error *ngIf="signUpForm.controls.ConfirmPassword.hasError('NoPassswordMatch')">Password does not match</mat-error>
        </mat-form-field>
        
        <div class="user__photo">
          <acc-flow-profile-photo type="Profile" [tooltip]="tooltips.profile_image"  [photo]="photo" [buttonText]="'Upload Photo'" [defaultTitle]="'User Photo'" [cssClass]="'signup'" [accept]="imageFileTypes" #userPhoto></acc-flow-profile-photo>
        </div>

      </mat-card-content>
    </mat-card>
    <div>
      <mat-progress-bar class="signup-progress" *ngIf="!!this.loading" mode="indeterminate"></mat-progress-bar>
      <button mat-raised-button class="signup-button" color="primary" [disabled]="!!this.loading" (click)="back()" >Back</button>
      <button mat-raised-button class="signup-button" color="primary" [disabled]="!!this.loading" (click)="submitForm()" >Submit</button>
    </div>
  </form>
</div>
