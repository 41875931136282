<li *ngFor="let folder of folders" [ngClass]="{open: folder && folder.isOpen, noactions: !actions}" >
	<ng-container *ngIf="folder">
		<div class="folder" [ngClass]="{active: activeFolder && (activeFolder.mediaCategoryID == folder.mediaCategoryID && activeFolder.categoryTypeId == folder.categoryTypeId), hasChildren:folder?.MediaCategories?.length}" (dblclick)="expandFolder(folder)">
			<u class="fa fa-caret-right" (click)="expandFolder(folder)"></u>
			<i class="fa fa-folder-open"></i>
			<span *ngIf="folder?.type!=2 || folder?.mediaCategoryID==-1" (click)="toggleFolder(folder)"  [appCartActions]="showCartActions" [folder]="folder" (actionClick)="raiseAction($event)"><span>{{folder.mediaCategoryDesc}}</span></span>
			<span *ngIf="folder?.type==2 && folder?.mediaCategoryID!=-1" [appFolderActions]="showFolderActions" [folder]="folder" (click)="toggleFolder(folder)" (actionClick)="raiseAction($event)"><span>{{folder.mediaCategoryDesc}}</span></span>
			<mat-icon *ngIf="showFolderTooltip" class="asset-info-icon" [matTooltipShowDelay]="tooltips.delay" [matTooltipPosition]="tooltips.position" matTooltip="{{folderTooltip}}" >info_outline</mat-icon>
		</div>
		<ul *ngIf="folder.MediaCategories?.length" class="nestedFolder">
			<acc-common-folder-structure [folders]="folder.MediaCategories.sort(sortFn)" [(activeFolder)]="activeFolder" [actions]="actions" (onAction)="raiseAction($event)" [showFolderActions]="showFolderActions" [showCartActions]="showCartActions"></acc-common-folder-structure>
		</ul>
	</ng-container>
</li>
	