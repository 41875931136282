<div mat-dialog-header>
	<h2 *ngIf="itemToEdit" mat-dialog-title>{{itemToEdit.textLibID == -1 ? 'Add' : 'Edit'}} Library Item</h2>
</div>

<mat-dialog-content>
		<div class="dialogContent">
			<div class="form" *ngIf="textLibCategories">
				<div class="field">
					<mat-form-field>
						<input matInput [(ngModel)]="itemToEdit.textLibTitle" />
						<mat-label>Title</mat-label>
					</mat-form-field>
				</div>
				<div class="field">
					<mat-form-field>
						<mat-label>Category</mat-label>
						<mat-select [(value)]="itemToEdit.textLibCategoryID" [compareWith]="compareValues">
							<mat-option [value]="0" *ngIf="itemToEdit.textLibCategoryID == 0">Select One</mat-option>
							<mat-option *ngFor="let category of textLibCategories" [value]="category.textLibCategoryID">{{category.textLibCategoryDesc}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="field">
					<mat-form-field appearance="outline">
						<textarea matInput [(ngModel)]="itemToEdit.textLibDesc"></textarea>
						<mat-label>Description</mat-label>
					</mat-form-field>
				</div>
				<div class="field">
					<mat-form-field>
						<input matInput [(ngModel)]="itemToEdit.textLibSearchWords" />
						<mat-label>Search Words</mat-label>
						<mat-hint>Multiple keywords can be separated by commas</mat-hint>
					</mat-form-field>
				</div>
			</div>
		</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="save()">Save</button>
	<button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
