import { Attachment } from './attachment';

export class Text {
	public textID: number;
	public categoryDesc: string;
	public subCategoryID: number;
	public subCategoryDesc: string;
	public defaultText: string;
	public sortOrder: number;
	public revisionNeededDate: Date;
	public pageBreak: boolean;
	public categorySortOrder: number;
	public subCategorySortOrder: number;
	public subCategoryTextSortOrder: number;
	public attachments: Array<Attachment> = new Array<Attachment>();

	constructor(o?:any) {
		Object.assign(this,o);
	}

	get status() {
		if(!this.revisionNeededDate) { return ''; }
		if(new Date(this.revisionNeededDate) < new Date()) { return 'past'; }
		let today = new Date();
		let weekOut = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
		if(new Date(this.revisionNeededDate) < weekOut) { return 'soon'; }
		return 'future';
	}

}