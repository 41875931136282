import { Application, Broker } from "acc-models";
import { DtcResults } from "projects/acc-models/src/lib/models/Dtc/dtc-results";

export interface IDtcState {
	broker: Broker;
	application: Application;
	progress: number;
	serviceEnabled: boolean;
	results: DtcResults;
}

export const initialDtcState: IDtcState = {
	broker: null,
	application: null,
	progress: 0,
	serviceEnabled: null,
	results: null,
} 