import { Component, OnInit, Input } from '@angular/core';
import { Media } from 'acc-models';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../../store/state/app.state';
import * as RevisionDatesSelectors from '../../../store/selectors/revisiondates.selectors';
import * as RevisionDatesActions from '../../../store/actions/revisiondates.actions';
@Component({
  selector: 'acc-main-media-card',
  templateUrl: './media-card.component.html',
  styleUrls: ['./media-card.component.scss']
})
export class MediaCardComponent implements OnInit {

	@Input() media: Media

	public editingMedia$ = this.store.pipe(
		select(RevisionDatesSelectors.selectEditingMedia)
	);

	constructor(private store: Store<IAppState>) { }

	ngOnInit() {
	}

	edit() {
		this.store.dispatch(new RevisionDatesActions.EditMedia(this.media));
	}
	save() {
		this.store.dispatch(new RevisionDatesActions.EditMediaConfirm(this.media.revisionNeededDate));

	}
	cancel() {
		this.store.dispatch(new RevisionDatesActions.EditMediaCancel());
	}
}
