<p class="lists-container">
     <mat-card >
      <mat-card-content>
			<mat-form-field>
				<mat-label>Filter</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Filter..." [(ngModel)]="filterValue">
			</mat-form-field>

			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="resort()">

				<!-- Name Column -->
				<ng-container matColumnDef="listName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let list" (click)="showInfo(list)">
						<div class='mat-ripple-wrapper' matRipple>
							<div class="name">{{ list.listName }}</div>
							<div class="description">{{ list.listDesc }}</div>
						</div>
					</td>
				</ng-container>

				<!-- Created Column -->
				<ng-container matColumnDef="createDateTime">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
					<td mat-cell *matCellDef="let list" (click)="showInfo(list)">{{ list.createDateTime | date: 'longDate' }}</td>
				</ng-container>
			
				<!-- Record Column -->
				<ng-container matColumnDef="totalRecords">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Record Count</th>
					<td mat-cell *matCellDef="let list" (click)="showInfo(list)">
						{{ list.totalRecords }}
						<div class="unsubs" *ngIf="list.unsubscribes > 0">({{list.unsubscribes}} unsubscribed)</div>
					</td>
				</ng-container>
			
				<!-- Action Column -->
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>&nbsp;</th>
					<td mat-cell *matCellDef="let list">
						<button mat-icon-button [matMenuTriggerFor]="menu">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item (click)="showInfo(list)">
								<mat-icon>edit</mat-icon>
								<span>Edit</span>
							</button>
							<button mat-menu-item (click)="import(list)">
								<mat-icon>cloud_upload</mat-icon>
								<span>Import</span>
							</button>
							<button mat-menu-item (click)="delete(list)">
								<mat-icon>delete_forever</mat-icon>
								<span>Delete</span>
							</button>
						</mat-menu>
					</td>
				</ng-container>
			
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			
				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
				  <td class="mat-cell" colspan="4">No data matching the filter "{{filterValue}}"</td>
				</tr>

				<!-- Group header cells-->
				<ng-container matColumnDef="groupHeader">
					<td colspan="999" mat-cell *matCellDef="let group">
						<div fxFill fxLayoutAlign="center center">
							<mat-icon *ngIf="!group.reduced">expand_less</mat-icon>
							<mat-icon *ngIf="group.reduced">expand_more</mat-icon>
							<strong>{{group.groupName}}</strong>
						</div>
					</td>
				</ng-container>


				<!-- Group line -->
				<tr mat-row  class="group-header-row"
					*matRowDef="let row; columns: ['groupHeader']; when: isGroup" 
					(click)="reduceGroup(row)" > 
				</tr>
			</table>
      </mat-card-content>
    </mat-card>
</p>
