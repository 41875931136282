import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'acc-services';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'acc-main-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ContactsListComponent implements OnInit {

  public showLoader = false;
  public contacts;
  public filterValue: string;
  public dataSource: MatTableDataSource<Array<any>>; 
  public displayedColumns: string[] = ['firstName', 'lastName', 'email', 'actions'];
  public selectedContact: any;
  public selectedContactDetails: any

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.loadContacts();
  }

  loadContacts(){
    this.showLoader = true;
    this.dataService.getMktContactsSummary().subscribe((c) => {
      this.contacts = c;
      this.buildDataSource();
      this.showLoader = false;
    });
  }

  
  applyFilter(event: Event) {
    this.buildDataSource();
  }

  buildDataSource() {
    var contactsArray = this.contacts;
    if(this.filterValue) {
      contactsArray = this.contacts.filter(c => c.firstName.toLowerCase().includes(this.filterValue.toLocaleLowerCase())
      || c.lastName.toLowerCase().includes(this.filterValue.toLocaleLowerCase()) 
      || c.email.toLowerCase().includes(this.filterValue.toLocaleLowerCase()) );
    }
	  this.dataSource = new MatTableDataSource(contactsArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  includeInProgramsChange(value, contactID){
    this.dataService.updateIncludeInPrograms(value, contactID).subscribe();
  }

  expandContact(contact) {
	this.selectedContactDetails = null;
	  if(contact == this.selectedContact) {
		  this.selectedContact = null;
	  } else {
		this.selectedContact = contact;
		this.dataService.getMktContact(contact.orgID, contact.contactID).subscribe(resp => this.selectedContactDetails = resp);
	}
  }

}
