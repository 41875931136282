
import { Component, Output, Input, EventEmitter, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { DataService, SessionService, QuoteService, MixpanelService } from 'acc-services';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CrmContact, CrmAccount, User, MediaContents, LibraryItem, Media, UnlayerTemplate } from 'acc-models';
import { EmailSelectorComponent } from '../../../salesforce/email-selector/email-selector.component';
import { Subject, Observable, of, forkJoin } from 'rxjs';
import { switchMap, tap, takeUntil, map, take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VideoDialogComponent } from '../../../dialog/video-dialog/video-dialog.component';
import { tooltips } from "../../../../tooltips/tooltips";
import { Store } from '@ngrx/store';
import { IAppState } from 'projects/acc-main/src/app/store/state/app.state';
import { SetErrorMessage } from 'projects/acc-main/src/app/store/actions/status.actions';
import { environment } from 'projects/acc-main/src/environments/environment';
import { InfoDialogComponent } from '../../../dialog/info-dialog/info-dialog.component';
import { PreviewVideoComponent } from '../../../preview-video/preview-video.component';

@Component({
	selector: 'acc-common-personalize',
	templateUrl: './personalize.component.html',
	styleUrls: ['./personalize.component.scss']
})
export class PersonalizeComponent implements OnInit, OnDestroy {
	@ViewChild('emailSelector', { static: true }) emailSelector: EmailSelectorComponent;
	@ViewChild('bccSelector', { static: true }) bccSelector: EmailSelectorComponent;

	@Input() mainCartId: string;
	@Input() quoteId: string;
	@Input() defaultMessage: string;
	@Input() onFileAdded: Observable<LibraryItem>;
	@Output() onClose: Subject<string | undefined> = new Subject();
	@Output() onShowSelectFile: Subject<string | undefined> = new Subject();

	public user:User;
	private subject:string;
	private exchangeEmail: string ;
	private exchangePassword: string;
	public displayCredentialFields: boolean = true;
	private message:string;
	private clearAfterSend:boolean = true;
	private concatVideo: boolean = false;
	private personalize:boolean;
	private templateId: number = 0;
	public personalizeForm:FormGroup;
	public defaultAccount:CrmAccount;
	public contents: MediaContents;
	private cartId: string;
	public videoUrl: string;
	public integrateCRM: boolean = false;
	
	public emailAddresses: Array<CrmContact> = new Array<CrmContact>();
	public bccEmailAddresses: Array<CrmContact> = new Array<CrmContact>();
	public emailOptions:Array<CrmContact>;
	public bccEmailOptions:Array<CrmContact>;

	public templates: Array<UnlayerTemplate>;

	private destroy$: Subject<boolean> = new Subject();
	
	public tooltips = tooltips;

	constructor(
		private sessionService:SessionService, 
		private dataService:DataService, 
		private fb: FormBuilder, 
		private quoteService: QuoteService, 
		private dialog: MatDialog,
		private store: Store<IAppState>,
		private snackBar: MatSnackBar,
		private mixpanelService: MixpanelService) {}
	
	sendMail() {
		//mark all fields as touched, so they get validated
		Object.keys(this.personalizeForm.controls).forEach(field => { 
			const control = this.personalizeForm.get(field);            
			control.markAsTouched({ onlySelf: true });       
		});
		this.emailSelector.emailAddressForm.controls.emailAddress.markAsTouched({ onlySelf: true });
		this.emailSelector.emailAddressForm.controls.emailAddress.updateValueAndValidity();
		this.emailAddresses = this.emailSelector.getContacts();
		this.bccEmailAddresses = this.bccSelector.getContacts();
		if(this.emailAddresses.length > 0 && this.personalizeForm.valid) {
			let userId = this.user.userID;
			let cartSessionID = this.mainCartId;
			let doPersonalize = this.personalizeForm.get('personalize').value ? "true" : "false"
			this.subject = this.personalizeForm.get('subject').value;
			this.exchangeEmail = this.personalizeForm.get('exchangeEmail').value;
			this.exchangePassword = this.personalizeForm.get('exchangePassword').value;
			this.message = this.personalizeForm.get('message').value;
			this.clearAfterSend = this.personalizeForm.get('clearAfterSend').value;
			this.concatVideo = this.personalizeForm.get('concatVideo').value;
			this.templateId = this.personalizeForm.get('templateId').value;
			
			this.dataService.sendMail(userId, cartSessionID, this.subject, this.emailAddresses, this.bccEmailAddresses, this.exchangeEmail, this.exchangePassword, this.message, doPersonalize, this.clearAfterSend, this.concatVideo, this.quoteId, this.templateId).subscribe(resp => {
				
				//Mixpanel Track
				this.mixpanelService.trackPersonalizeAndSendEmail();
				
				if(this.clearAfterSend) {
					this.onClose.next(cartSessionID);
				} else {
					this.onClose.next();
				}
				this.snackBar.open("Your email has been sent.", 'OK', { duration: 3000 })
			}, error => {
			})

		}
	}
	close():void {
		this.onClose.next();
	}

	updateEmailList(list:Array<CrmContact>, type: string) {
		switch(type) {
			case 'to':
				this.emailAddresses = list;
				if(list.length > 0) {
					this.emailSelector.emailAddressForm.controls.emailAddress.setErrors(null);
				}
				break;
			case 'bcc':
				this.bccEmailAddresses = list;
				break;
		}
	}

	ngOnInit():void {
		this.user = this.sessionService.getUser();
		this.integrateCRM = this.user.integrateCRM;
		this.dataService.getLastCredentials(this.user.userID).subscribe((creds:any) => {
			this.exchangeEmail = creds.userID || (creds.display ? '' : 'default');
			this.exchangePassword = creds.password || (creds.display ? '' : 'default');
			this.displayCredentialFields = creds.display;
			if(this.personalizeForm) {
				this.personalizeForm.patchValue({exchangeEmail: this.exchangeEmail, exchangePassword: this.exchangePassword}); 
			}
			
			if(!creds.display) {
				if(!this.emailAddresses.some(c => c.Email == userEmail) && !this.bccEmailAddresses.some(c => c.Email == userEmail)) {
					//if you're not in the "to" or "bcc", add to bcc
					this.bccEmailAddresses.push(new CrmContact({ Email: userEmail }));
				}
		
			}
			
		})
		this.dataService.getDefaultAccount(this.mainCartId).subscribe(acct => {
			this.defaultAccount = new CrmAccount(acct);
			this.handleAccountSelected(this.defaultAccount);
		})
		const userEmail: string = this.user.email;
		this.personalizeForm = this.fb.group({
			'personalize': [true],
			'emailTo': [this.emailAddresses],
			'bcc': [this.bccEmailAddresses],
			'subject': [this.subject, Validators.required],
			'exchangeEmail': [this.exchangeEmail, Validators.required],
			'exchangePassword': [this.exchangePassword, Validators.required],
			'message': [this.message || this.defaultMessage, Validators.required],
			'clearAfterSend': [this.clearAfterSend],
			'accountName': [''],
			'concatVideo': this.concatVideo,
			'templateId': this.templateId
		})
		this.dataService.getPreviousCart(this.user.userID).pipe(
			tap((cartId: any) => this.cartId = cartId.recentSession)
		).subscribe((resp:any) => {
			forkJoin([this.dataService.getCart(resp.recentSession), this.dataService.getAsyncCart(resp.recentSession)]).subscribe((cartData:any) => {
				let items = cartData[0].Medias.map(i => new Media({...i, type: 'sync'}));
				items = [ ...items, ...cartData[1].Medias.map(i => new Media({...i, type: 'async'}))];
				this.contents = new MediaContents(items.MediaCategories, items, items.mediaCategoryID, 5);
				this.contents.LibraryItems.sort((a,b) => {
					if(a.sortOrder > b.sortOrder) { return 1; }
					if(a.sortOrder < b.sortOrder) { return -1; }
					return 0;
				})
			})
		});

		if(this.onFileAdded) {
			this.onFileAdded.pipe(takeUntil(this.destroy$)).subscribe(file => {
				this.contents.LibraryItems.push(file);
			});
		}
		if(this.quoteId) {
			this.quoteService.getQuote(this.quoteId).subscribe((resp:any) => {
				this.videoUrl = resp.videoURL;
			})
		}

		this.getTemplates(this.user.orgID);

	}

	getTemplates(orgID) {
		const authKey = this.sessionService.settings.unlayer.authCode;
		const isShared = this.sessionService.settings.unlayer.isShared;
		this.dataService.getUnlayerTemplates(orgID, authKey, isShared).subscribe((resp: any) => this.templates = resp)
	}

	getTemplatePreview() {
		const templateId = this.personalizeForm.get('templateId').value;
		let previewUrl = '';
		if(templateId > 0) {
			const config = new MatDialogConfig();
			config.width = "0px";
			config.height = "0px";
			config.data = {
				width: '580px',
				height: '518px',
				title: '',
				message: 'Please wait while your preview is loading'
			}
			const dialogRef = this.dialog.open(InfoDialogComponent, config);
			this.dataService.getUnlayerTemplateImage(templateId, this.personalizeForm.get('message').value || '').subscribe((resp:any) => {
				previewUrl = resp;
				dialogRef.componentInstance.data = { ...dialogRef.componentInstance.data, message: `<img src="${previewUrl}" width="500">`}
			})
		}
	}
	deleteItem(item:LibraryItem) {
		if(item.type == 'async') {
			this.dataService.removeFromCartAsync(this.cartId, item.id).subscribe(resp => {
				this.contents.LibraryItems = this.contents.LibraryItems.filter(i => i.id != item.id);
			});
		} else {
			this.dataService.removeFromCart(this.cartId, item.id).subscribe(resp => {
				this.contents.LibraryItems = this.contents.LibraryItems.filter(i => i.id != item.id);
			});
		}
	}

	deleteQuote() {
		this.quoteId = null;
	}

	showSelectFile() {
		this.onShowSelectFile.next();
	}

	open(file) {
		//open in new tab 
		window.open(file.savedFileName);
	}
	showPreview(fileId: string) {
		//generate preview and show in new tab
		let wdw = window.open('/loading');
		this.dataService.createPdf(fileId).subscribe((res:any) => {
			wdw.location.href = res.url;
		}, (err) => {
			wdw.close();
			this.store.dispatch(new SetErrorMessage("Your document could not be generated."));
			//wdw.location.href = '/loading/error';
		});
	}
	generate(item) {
		const onVideoGenerated:Subject<{ mediaAsyncID: string, videoURL: string, thumbnailURL: string}> = new Subject();
		const dialogRef = this.dialog.open(PreviewVideoComponent, {
			width: '800px',
			height: '550px',
			data: item.MediaAsyncAttributes.reduce((c, i) => { return { ...c, [i.attributeName]:  i.mediaAsyncAttributeTypeID == 4 ? JSON.parse(i.attributeValue) : i.attributeValue } }, {mediaItem: item, onVideoGenerated})
		  });
		  onVideoGenerated.pipe(take(1), takeUntil(this.destroy$)).subscribe(vid => {
			const item = this.contents.LibraryItems.filter(i => i.id == vid.mediaAsyncID)[0];
			item.imageFile = vid.thumbnailURL;
			(item as any).mediaUrl = vid.videoURL;
		  });
		  (dialogRef.componentInstance as PreviewVideoComponent).onError.pipe(takeUntil(this.destroy$)).subscribe(err => this.store.dispatch(new SetErrorMessage(err)))
	  }

	handleAccountSelected(acct:CrmAccount) {
		if(acct.Id) {
			 this.dataService.getContacts(this.user.userID, acct.Id).subscribe((resp:any) => {
				this.emailOptions = resp;
				this.bccEmailOptions = resp;
			})
		}
	}

	showVideo() {
		if(this.videoUrl) {
			this.dialog.open(VideoDialogComponent, { data: { videoUrl$: of(this.videoUrl), title: 'Your LifeQuote Video' }});
		} else {
			const videoUrl$ = this.quoteService.makeVideo(this.quoteId).pipe(tap((v:any) => this.videoUrl = v));
			this.dialog.open(VideoDialogComponent, { data: { videoUrl$, title: 'Your LifeQuote Video' }});
		}
	}

	getBackgroundImageUrl(path) {
		return `url('${path}')`;
	}

	getVideoBackgroundImage(video) {
		let base = environment.api_url;
		let index = base.lastIndexOf("/api");
		
		let videoPhotoUrl =  base.slice(0, index)  + '/defaultimages/Video-play-button.png';
		let videoImage = (video ?  video.replace('returnvideo', 'returnthumbnail') : videoPhotoUrl);
		return `url('${videoImage}')`;
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
 }
