
export class CommMediaAttachment {
	public mediaID: string;
	public mediaDesc: string;
	public mediaTypeID: number;
	public savedfileName: string;
	public imageFile: string;

	constructor(o?: any) {
		if(o) {
			o.mediaID = o.mediaID || o.mediaItemID;
		}
		Object.assign(this, o);
	}
}
