import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService, SessionService } from 'acc-services';
import { Organization } from 'acc-models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit, OnDestroy {

	public org: Organization;
	
	constructor(private dataService: DataService, private sessionService: SessionService, private snackBar: MatSnackBar) { }

	ngOnInit() {
		this.dataService.getOrgDetails(this.sessionService.getUser().orgID).subscribe(resp => {
			this.org = new Organization(resp);
		})
	}

	ngOnDestroy(): void {
	}

	save(): void {
		this.dataService.saveWhatsNew(this.org).subscribe(resp => {
			this.sessionService.setItem("organization", this.org);
			this.snackBar.open(`Your changes have been saved.`, 'OK', { duration: 3000 });
		})
	}

}
